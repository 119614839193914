import { combineReducers } from 'redux'
import contactReducer from "./contactReducer";
import tagReducer from "./tagReducer";
import virtualNumberReducer from "./virtualNumberReducer";
import dripBatchReducer from "./dripBatchReducer";
import rightsideReducer from "../components/contact/rightSide/redux/rightsideReducer"
import calendarReducer from './calendarReducer';
import importContactSlice from './importContact/importContactSlice';
import importSpreadsheetContactSlice from "./importSpreadsheetContact/importSpreadsheetContactSlice";
export default combineReducers({
    contactReducer: contactReducer,
    tagReducer: tagReducer,
    virtualNumberReducer: virtualNumberReducer,
    dripBatchReducer: dripBatchReducer,
    rightsideReducer: rightsideReducer,
    calendarReducer: calendarReducer,
    importContact: importContactSlice,
    importSpreadsheetContact: importSpreadsheetContactSlice
})