import React, { useState } from "react";
import Utils from "../contact/rightSide/Helpers/Utils";
import moment from "moment-timezone";
import FileListSkeleton from "./FileListSkeleton";
import { Button, makeStyles, TablePagination } from "@material-ui/core";
import { updateImportContactOptionTab } from "../../reducers/importContact/importContactSlice";
import { FILE_IMPORT_TAB } from "../../constants/CoreConstants";
import { useDispatch } from "react-redux";
import AssignToCampaignModal from "./AssignToCampaignModal";
import { AddOutlined, DeleteForeverRounded } from "@material-ui/icons";
import BootstrapTooltip from "../globals/BootstrapTooltip";
import { DownloadIcon } from "../../constants/IconList";

const userTimezone = Utils.getAccountData("userTimezoneRaw");
const useStyles = makeStyles((theme) => ({
  circleButton: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid var(--light_blue)',
    marginRight: '15px',
    cursor: 'pointer'
  },
  circleDeleteButton: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid var(--red)',
    marginRight: '15px',
    cursor: 'pointer'
  },
  pagination: {
    backgroundColor: "#FFFFFF",
    height: "52px",
    marginBottom: "20px",
    "& .MuiTablePagination-actions": {
      display: "flex",
      "& button": {
        padding: "2px",
      },
    },
  },
  refreshButton: {
    color: 'var(--light_blue)',
    textTransform: 'capitalize',
    borderColor: 'var(--light_blue)',
    marginRight: '12px',
    "&:focus,&:hover": {
      background: 'transparent',
    },
  },
}));

const HeaderRight = () => {
  return (
    <div>
      <h6>File List</h6>
    </div>
  );
};

const HeaderBody = (props) => {
  const classes = useStyles();
  return (
    <div className='main_content_topbar'>
      <div className='main_content_topbar_content d-flex justify-content-between align-items-center'>
        <HeaderRight />

        <div style={{ display: "flex", flexDirection: "row", gap: "5px" }}>
          <Button onClick={props.onRefresh} variant='outlined' className={classes.refreshButton} startIcon={
            <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.274 10.7a7.618 7.618 0 0 1 .256-3.025c.92-3.139 3.807-5.447 7.23-5.488V.135c0-.114.143-.175.237-.106l4.222 3.103c.07.053.07.158 0 .207l-4.218 3.103c-.098.069-.236.008-.236-.106V4.288a5.527 5.527 0 0 0-5 3.387 5.453 5.453 0 0 0 .778 5.566 1.05 1.05 0 0 1-.2 1.506 1.06 1.06 0 0 1-1.457-.203A7.528 7.528 0 0 1 2.274 10.7zm12.19-3.94a5.437 5.437 0 0 1 1.204 3.146 5.49 5.49 0 0 1-.427 2.419c-.826 1.962-2.748 3.355-5 3.387v-2.048c0-.114-.142-.175-.236-.106l-4.222 3.103c-.069.053-.069.159 0 .207l4.218 3.103c.098.069.236.008.236-.106v-2.052c3.424-.037 6.315-2.345 7.231-5.488a7.693 7.693 0 0 0 .256-3.025 7.49 7.49 0 0 0-1.608-3.844 1.06 1.06 0 0 0-1.457-.203 1.054 1.054 0 0 0-.196 1.506z"
                fill="var(--light_blue)"
              />
            </svg>
          }>
            Refresh
          </Button>
          <a
            href='javascript:void(0)'
            onClick={props.onClick}
            class='main_content_container_button white-text d-flex justify-content-center align-items-center import_file_list_back_button'
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              class='main_content_container_button_icon'
            >
              <path
                d='M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z'
                fill='white'
              />
            </svg>
            <span>Back</span>
          </a>
        </div>
      </div>
    </div>
  );
};

const TableHead = () => {
  return (
    <thead>
      <tr>
        <th>
          <div class='d-flex align-items-center table_head_1'>File Name</div>
        </th>
        <th>
          <div class='d-flex align-items-center justify-content-center table_head_2'>Status</div>
        </th>
        <th>
          <div class='d-flex align-items-center justify-content-center table_head_3'>
            <span class='color_blue'>Total</span>
            <span class='table_head_slash'>/</span>
            <span class='color_green'>Imported</span>
          </div>
        </th>
        <th>
          <div class='table_head_4'>
            Import Errors
            <br />
            <p>
              Duplicate Email <span class='table_head_slash'>/</span>Duplicate Number{" "}
              <span class='table_head_slash'>/</span> Invalid Contact
            </p>
          </div>
        </th>
        <th>
          <div class='d-flex align-items-center justify-content-center table_head_5'>Action</div>
        </th>
      </tr>
    </thead>
  );
};

const RenderStatus = (props) => {
  let statusSVG = "";
  let text = "";
  let classText = "";

  if (parseInt(props.status) === 1) {
    statusSVG = (
      <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.274 10.7a7.618 7.618 0 0 1 .256-3.025c.92-3.139 3.807-5.447 7.23-5.488V.135c0-.114.143-.175.237-.106l4.222 3.103c.07.053.07.158 0 .207l-4.218 3.103c-.098.069-.236.008-.236-.106V4.288a5.527 5.527 0 0 0-5 3.387 5.453 5.453 0 0 0 .778 5.566 1.05 1.05 0 0 1-.2 1.506 1.06 1.06 0 0 1-1.457-.203A7.528 7.528 0 0 1 2.274 10.7zm12.19-3.94a5.437 5.437 0 0 1 1.204 3.146 5.49 5.49 0 0 1-.427 2.419c-.826 1.962-2.748 3.355-5 3.387v-2.048c0-.114-.142-.175-.236-.106l-4.222 3.103c-.069.053-.069.159 0 .207l4.218 3.103c.098.069.236.008.236-.106v-2.052c3.424-.037 6.315-2.345 7.231-5.488a7.693 7.693 0 0 0 .256-3.025 7.49 7.49 0 0 0-1.608-3.844 1.06 1.06 0 0 0-1.457-.203 1.054 1.054 0 0 0-.196 1.506z'
          fill='#F2994A'
        />
      </svg>
    );
    text = "Queued";
    classText = "color_orange";
  } else if (parseInt(props.status) === 2) {
    statusSVG = (
      <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.274 10.7a7.618 7.618 0 0 1 .256-3.025c.92-3.139 3.807-5.447 7.23-5.488V.135c0-.114.143-.175.237-.106l4.222 3.103c.07.053.07.158 0 .207l-4.218 3.103c-.098.069-.236.008-.236-.106V4.288a5.527 5.527 0 0 0-5 3.387 5.453 5.453 0 0 0 .778 5.566 1.05 1.05 0 0 1-.2 1.506 1.06 1.06 0 0 1-1.457-.203A7.528 7.528 0 0 1 2.274 10.7zm12.19-3.94a5.437 5.437 0 0 1 1.204 3.146 5.49 5.49 0 0 1-.427 2.419c-.826 1.962-2.748 3.355-5 3.387v-2.048c0-.114-.142-.175-.236-.106l-4.222 3.103c-.069.053-.069.159 0 .207l4.218 3.103c.098.069.236.008.236-.106v-2.052c3.424-.037 6.315-2.345 7.231-5.488a7.693 7.693 0 0 0 .256-3.025 7.49 7.49 0 0 0-1.608-3.844 1.06 1.06 0 0 0-1.457-.203 1.054 1.054 0 0 0-.196 1.506z'
          fill='#F2994A'
        />
      </svg>
    );
    text = "Processing";
    classText = "color_green";
  } else if (parseInt(props.status) === 3) {
    statusSVG = (
      <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10 20c5.523 0 10-4.477 10-10S15.523 0 10 0 0 4.477 0 10s4.477 10 10 10zM6.6 9.602a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm7.8-1a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-4.4 7c2.72 0 4.066-2.4 4.4-3.6H5.6c.333 1.2 1.68 3.6 4.4 3.6z'
          fill='#27AE60'
        />
      </svg>
    );
    text = "Processed";
    classText = "color_green";
  } else if (parseInt(props.status) === 4) {
    statusSVG = (
      <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.047 2.954A9.966 9.966 0 1 0 2.954 17.047 9.967 9.967 0 1 0 17.047 2.954zm-3.844 4.91a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zm-6.406 0a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zM10 11.425c2.02 0 3.726 1.312 4.258 3.106.045.157.104.453.104.453H5.64s.058-.298.104-.453c.524-1.795 2.233-3.106 4.256-3.106z'
          fill='#FF264A'
        />
      </svg>
    );
    text = "Failed";
    classText = "color_red";
  } else if (parseInt(props.status) === 5) {
    statusSVG = (
      <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.047 2.954A9.966 9.966 0 1 0 2.954 17.047 9.967 9.967 0 1 0 17.047 2.954zm-3.844 4.91a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zm-6.406 0a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zM10 11.425c2.02 0 3.726 1.312 4.258 3.106.045.157.104.453.104.453H5.64s.058-.298.104-.453c.524-1.795 2.233-3.106 4.256-3.106z'
          fill='#FF264A'
        />
      </svg>
    );
    text = "Deleting";
    classText = "color_orange";
  } else if (parseInt(props.status) === 6) {
    statusSVG = (
      <svg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.047 2.954A9.966 9.966 0 1 0 2.954 17.047 9.967 9.967 0 1 0 17.047 2.954zm-3.844 4.91a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zm-6.406 0a1.068 1.068 0 1 1 0 2.136 1.068 1.068 0 0 1 0-2.135zM10 11.425c2.02 0 3.726 1.312 4.258 3.106.045.157.104.453.104.453H5.64s.058-.298.104-.453c.524-1.795 2.233-3.106 4.256-3.106z'
          fill='#FF264A'
        />
      </svg>
    );
    text = "Deleted";
    classText = "color_red";
  }

  return (
    <div class='d-flex justify-content-center'>
      <div>{statusSVG}</div>
      <span className={classText}>{text}</span>
    </div>
  );
};

const FileListTableBody = (props) => {
  const [showCampaignAssignModal, setShowCampaignAssignModal] = useState(false);
  const [fileId, setFileId] = useState(null);
  const classes = useStyles();
  const dispatch = useDispatch();

  const deleteFiles = (id) => {
    props.deleteFiles(id);
  };

  const prevStepList = (e) => {
    e.preventDefault();
    dispatch(updateImportContactOptionTab({ importContactOptionTab: FILE_IMPORT_TAB.TAB_DEFAULT }));
  };

  const handleShowCampaignAssignModal = (fileId) => {
    setShowCampaignAssignModal(true);
    setFileId(fileId);
  };

  const renderFileUrl = (item) => {
    if (item.aws3_info !== undefined && item.aws3_info != null && item.aws3_info !== "") {
      if (item.aws3_info === "\\") {
        return "";
      } else {
        let object_data_string = item.aws3_info;

        object_data_string = object_data_string.replace(/\\/g, "").replace(/&quot;/g, '"');
        try {
          object_data_string = JSON.parse(object_data_string).fileUrl;
        } catch (error) {
          console.log(error);
        }
        return object_data_string;
      }
    }
    return "";
  };

  const renderTableRows = () => {
    return (
      <tbody>
        {props.rows.map((elem, index) => {
          return (
            <tr key={index}>
              <td class='table_data_1'>
                <div className='d-flex items-center'>
                  <div>
                    <p className='truncate'>{elem.name_original}</p>

                    <span> {window.globalTimezoneConversionToDifferentTimezone(elem.created_at, "UTC", userTimezone, "MMM D, YYYY h:mm A", false)}</span>
                  </div>
                </div>
              </td>
              <td class='table_data_2'>
                <RenderStatus status={elem.status} />
              </td>
              <td class='table_data_3'>
                <div class='d-flex justify-content-center align-items-center'>
                  <div>
                    <span class='color_blue' style={{ fontSize: 14 }}>
                      {elem.summary == null ? 0 : JSON.parse(elem.summary).totalContact}/
                    </span>
                    <span class='color_green' style={{ fontSize: 14 }}>
                      {elem.total_contact_uploaded}
                    </span>
                  </div>
                </div>
              </td>
              <td class='table_data_4'>
                <div class='d-flex justify-content-around align-items-center'>
                  <div class='email_box color_dark_blue'>
                    <h6>{elem.summary == null ? 0 : JSON.parse(elem.summary).emailDuplicateCount}</h6>
                    <p>Email</p>
                  </div>
                  <div class='number_box color_purple'>
                    <h6 style={{ color: "var(--purple)" }}>
                      {elem.summary == null ? 0 : JSON.parse(elem.summary).numberDuplicateCount}
                    </h6>
                    <p style={{ color: "var(--purple)" }}>Number</p>
                  </div>
                  <div class='contacts_box color_red'>
                    <h6 style={{ color: "var(--red)" }}>
                      {elem.summary == null ? 0 : JSON.parse(elem.summary).invalidCount}
                    </h6>
                    <p style={{ color: "var(--red)" }}>Contact</p>
                  </div>
                </div>
              </td>
              <td class='table_data_5'>
                <div className='d-flex gap-5 justify-content-center align-items-center'>
                  <button
                    onClick={(e) => props.showReportView(elem.id)}
                    class='outline_button cursor-pointer'
                    style={{ minWidth: 95, marginRight: 8, border: "2px solid #6c6c6c", color: "#6c6c6c" }}
                  >
                    View Log
                  </button>

                  {renderFileUrl(elem) !== "" ? (
                    <BootstrapTooltip title="Download File" arrow placement="top">
                      <a className={classes.circleButton}
                        style={{ marginLeft: '15px' }}
                        target='_blank'
                        rel="noreferrer"
                        href={renderFileUrl(elem)}
                      >
                        <DownloadIcon />

                      </a>
                    </BootstrapTooltip>
                  ) : (
                    ""
                  )}
                  <div class=' d-flex justify-content-end align-items-center'>
                    <BootstrapTooltip title="Assign To Campaign" arrow placement="top">
                      <div className={classes.circleButton}
                        onClick={(e) => handleShowCampaignAssignModal(elem.id)}
                      >

                        <AddOutlined fontSize="14px" style={{ color: 'var(--light_blue)' }} />
                      </div>
                    </BootstrapTooltip>
                    <BootstrapTooltip title="Delete File" arrow placement="top">
                      <div className={classes.circleDeleteButton}
                        onClick={(e) => deleteFiles(elem, e)}
                      >
                        <DeleteForeverRounded fontSize="14px" style={{ color: 'var(--red)' }} />
                      </div>
                    </BootstrapTooltip>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    );
  };

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
    props.setTrigger(props.trigger + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
    props.setTrigger(props.trigger + 1);
  };
  return (
    <>
      <div className='import_content_container main_content_container_import_list'>
        <div className='main_content_inner_container white'>
          <HeaderBody onClick={prevStepList} onRefresh={props.onRefresh} />

          <div class='divider main_content_container_divider'></div>

          <div style={{ padding: "unset !important", paddingTop: "40px" }} className='main_content_main_section'>
            <div className='import_file_list_section'>
              <div className='import_list_table'>
                {props.loading ? (
                  <FileListSkeleton />
                ) : (
                  <div className={classes.tableBody}>
                    <table class='centered'>
                      <TableHead />

                      {renderTableRows()}
                    </table>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      component='div'
                      count={props.total}
                      rowsPerPage={props.rowsPerPage}
                      page={props.page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      className={classes.pagination}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCampaignAssignModal && (
        <AssignToCampaignModal
          open={showCampaignAssignModal}
          onClose={() => setShowCampaignAssignModal(false)}
          fileId={fileId}
        />
      )}
    </>
  );
};

export default FileListTableBody;
