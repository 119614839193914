import React, { useState, useEffect } from 'react';
import { fetchContactFiles, removeFile } from '../../../api/contactApi.js';
import File from "./File.js";
import ModalFileUploadForm from './ModalFileUploadForm';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
   buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
    marginBottom: "20px"
   },
   addFileButton: {
       "&.MuiButtonBase-root": {
           padding: "6px 10px !important"
       },
       "& .MuiButton-label": {
           padding: "0px !important"
       }
   },
});

const FileList = (props) => {
    const classes = useStyles();

    let count = 0;

    const [showModal, setShowModal] = useState(false)
    const [files, setFiles] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)
    const [loadingData, setLoadingData] = useState(true)

    useEffect(() => {

        fetchContactFiles({ id: props.contactId })
            .then(res => {
                setFiles(res.data.data)
            })
            .catch(e => {
                console.log('file data fetch problem')
            }).finally(() =>
                setLoadingData(false)
            )
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const modalToggle = () => {
        setShowModal(!showModal)
    }

    const loadAgainAllFiles = (file) => {
        if (files != null) {
            let allfiles = [...files];
            allfiles.push(file);
            setFiles(allfiles)
            setShowModal(false)
        } else {
            let allfiles = [];
            allfiles[0] = file;
            setFiles(allfiles)
            setShowModal(false)
        }

    }

    const removeAndLoadAgainAllFiles = (index) => {

        if (files != null) {
            let allfiles = [...files];

            allfiles.splice(index, 1);

            setFiles(allfiles)
        }

    }

    const deleteFile = (fileId, contactId, file, index) => {

        if (!isDeleting) {
            setIsDeleting(true)

            removeFile({
                id: fileId,
                contact_id: contactId,
                file: file
            }).then(res => {

                setIsDeleting(false)
                if (res.data.status === 'success') {
                    window.showNotification("SUCCESS", "File successfully removed")
                    removeAndLoadAgainAllFiles(index);
                }

            });
        } else {
            window.showNotification('ERROR', 'Already in progress')
        }

    }

    // render() {

    const fileShow = () => {
        if (files && Array.isArray(files) && files[0]) {
            return files.map((data, index) => {
                if (data !== undefined && data != null) {
                    count = 0;
                    count++;
                    return <File deleteFile={deleteFile} key={data.id} data={data} index={index} contactId={props.contactId} />;
                }
                else
                    return null

            })
        } else {

        }

    }

    const loadingSkeleton = () => {
        let skeletons = [];
        for (let i = 0; i < 5; i++) {
            skeletons.push(
                <div
                    key={i}
                    className={`skeleton_each_note`}
                >
                    <div className="skeleton_note_details">
                        <Skeleton variant="rect" width={500} height={80} />
                        <Skeleton
                            className="skeleton_note_edit_icon"
                            variant="circle"
                            width={30}
                            height={30}
                        />
                    </div>
                </div>
            );
        }
        return skeletons;
    };



    return (
        <React.Fragment>

            <div id="files_tab" className="files_tab_content col s12 awesome__scroll_bar">
                {
                    files && Array.isArray(files) && files[0] &&
                    <div className={classes.buttonWrapper}>
                        <Button className={`accent--bg--color globalAddNoteButton ${classes.addFileButton}`} variant="contained" color="primary" onClick={modalToggle}><AddIcon /> Add File</Button>
                    </div>
                }
                {!loadingData ?

                    (files && Array.isArray(files) && files[0]) ?
                        fileShow() :
                        <div className="notItemsWrap">
                            <div style={{ display: 'flex', flexDirection: 'column' }} className="notItemsFound">
                                <Button className={`accent--bg--color globalAddNoteButton ${classes.addFileButton}`} variant="contained" color="primary" onClick={modalToggle}><AddIcon /> Add File</Button>
                                <p>No File Available</p>
                            </div>
                        </div>
                    :
                    loadingSkeleton()
                }
            </div>

            <ModalFileUploadForm
                count={count}
                title={"Contact file upload form"}
                isOpen={showModal}
                url={'/user/contact/update/email'}
                callBack={loadAgainAllFiles}
                defaultParams={{ contact_id: props.contactId }}
                modalToggle={modalToggle}
            />
        </React.Fragment>
    );
    // }
}

export default FileList;
