import React from "react";
import {
  Chip,
  Paper,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {Table} from "reactstrap";
import Box from "@material-ui/core/Box";
import {green, red} from "@material-ui/core/colors";

const getDncStatusLabelAndColor = (label) => {
  if (label === "VERIFIED_OK") return ["DNC Inactive", green[500]];
  else if (label === "DO_NOT_CALL") return ["DNC Active", red[500]];
  else if (label === "NOT_VERIFIED") return ["DNC Not Verified", "default"];
  else return ["", "error"];
};

const TopBarStyle = styled(Box)({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between",
  marginBottom: "10px",
});

const Result = ({ data }) => {
  const newData = data.filter((eachData) => eachData.request_data !== true || eachData.number !== undefined || eachData.email !== undefined);
  return (
    <div>
      <TopBarStyle>
        <Typography variant="h6">Search Results:</Typography>
      </TopBarStyle>
      <Paper
        variant={"outlined"}
        style={{ maxHeight: "400px", borderBottom: "none" }}
      >
        <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Phone validation status</TableCell>
              <TableCell>phone validate</TableCell>
              <TableCell>email validation status</TableCell>
              <TableCell>email validate</TableCell>
              <TableCell>dnc status</TableCell>
              <TableCell>source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newData.map((eachData, eachDataIndex) => (
              <TableRow key={eachDataIndex}>
                <TableCell>
                  {(
                    (eachData.firstName || "") +
                    " " +
                    (eachData.lastName || "")
                  ).trim() || "-"}
                </TableCell>
                <TableCell>
                  {eachData["number-validation-status"] ? (
                    <Chip
                      size={"small"}
                      variant={"outlined"}
                      color={
                        eachData["number-validation-status"] === "VALID"
                          ? "primary"
                          : "secondary"
                      }
                      label={eachData["number-validation-status"]}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{eachData.number ?? "-"}</TableCell>
                <TableCell>
                  {eachData["email-validation-status"] ? (
                    <Chip
                      size={"small"}
                      variant={"outlined"}
                      color={
                        eachData["email-validation-status"] === "VALID"
                          ? "primary"
                          : "secondary"
                      }
                      label={eachData["email-validation-status"]}
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{eachData.email ?? "-"}</TableCell>
                <TableCell>
                  {eachData["dnc-state"] ? (
                    <Chip
                      size={"small"}
                      color={"primary"}
                      style={{
                        backgroundColor: getDncStatusLabelAndColor(
                          eachData["dnc-state"]
                        )[1],
                      }}
                      label={
                        getDncStatusLabelAndColor(eachData["dnc-state"])[0]
                      }
                    />
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>{eachData.source ?? "-"}</TableCell>
              </TableRow>
            ))}
            {newData.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align={"center"}>
                    No Data Found!
                  </TableCell>
                </TableRow>
            )}
          </TableBody>
        </Table>
        </TableContainer>
      </Paper>
      <Typography variant={"subtitle1"} style={{ marginBottom: "10px" }}>
        Note: More options will be available on Report section.
      </Typography>
    </div>
  );
};

export default Result;
