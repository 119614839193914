import React from "react";
import { Link } from "react-router-dom";
import GlobalModal from "../../../globals/Modal/GlobalModal";
import UtilsHelper from "../../../../helpers/Utils";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const ShowMorePipelineInfo = (props) => {

  const specificPipelines = [];

  return (
    <>
      <GlobalModal
        open={props.open}
        onClose={props.close}
        title={`Show All Pipelines & Stages`}
        className={"show__all__campaigns global-small-modal"}
        hideFooter={true}
      >
        <div className="contact__all__campaigns__wr">
          {props.allContactPipelines && Array.isArray(props.allContactPipelines) &&
            props.allContactPipelines.forEach((stageInfo, stageIndex) => {
              if (stageInfo.stage && stageInfo.stage.stage) {
                let pipelineTitle = "";
                let pipeLength = props.pipelineList.length;

                for(let i=0; i < pipeLength; i++) {
                    if(props.pipelineList[i].id === stageInfo.stage.pipeline_id ){
                        pipelineTitle = props.pipelineList[i].title;
                        break;
                    }
                }
                specificPipelines.push(
                    
                      <tr key={`stageDropdown${stageInfo?.stage?.id}${stageIndex}`}>
                        <td
                          className="link-text-design campaign-list-in-contact-list contact__all__campaigns"
                        >
                          <BootstrapTooltip
                            title={pipelineTitle}
                            arrow
                            placement="top"
                          >
                            <Link to={`/deal-stages?id=${stageInfo?.stage?.pipeline_id}`} target="_blank">
                              <span>
                                  {UtilsHelper.reduceTextLength(
                                      pipelineTitle,
                                      20
                                  )}
                              </span>
                            </Link>

                          </BootstrapTooltip>
                        </td>
                        <td>
                          <BootstrapTooltip
                            title={stageInfo.stage.stage}
                            arrow
                            placement="top"
                          >
                            <Link to={`/deal-stages?id=${stageInfo?.stage?.pipeline_id}`} target="_blank">
                              <span>
                                  {UtilsHelper.reduceTextLength(
                                      stageInfo.stage.stage,
                                      20
                                  )}
                              </span>
                            </Link>

                          </BootstrapTooltip>
                        </td>

                        <td>
                          <button className="update__stage__btn" onClick={()=>props.openStageChangeModal(stageInfo, stageIndex, props.row, props.index)}>Update Stage</button>
                        </td>
                      </tr>
                )
              }
            })}

          <div className="campaign__show__all__table__header">
            <table>
              <thead>
                <tr>
                  <th>Pipeline Name</th>
                  <th>Stage Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              {specificPipelines}
              { specificPipelines.length === 0 &&
                <div className="no__tag__added">
                  <span>No Pipeline added in this contact yet</span>
              </div>
              }
            </table>
          </div>
        </div>
      </GlobalModal>
    </>
  );
};

export default ShowMorePipelineInfo;

