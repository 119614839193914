import { Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useState, useEffect, useRef } from "react";
import { Mention, MentionsInput } from 'react-mentions';
import { connect } from "react-redux";
import styled from 'styled-components';
import { reloadNoteList } from "../../../../actions/contactAction";
import { addNote, getAllTeamUsers } from '../../../../api/contactApi';
import GlobalModal from "../../../globals/Modal/GlobalModal";
import defaultStyle from './defaultStyle';
import './note.css';
import { Skeleton } from '@material-ui/lab';

const mentionStyle = {
    backgroundColor: '#cee4e5',
};

const Wrapper = styled.div`
    margin: 10px;
    max-width: 500px;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E8EF;
`;

const NoteComponent = (props) => {
    const [submittingNote, setSubmittingNote] = useState(false);
    const [note, setNote] = useState('');
    const [allTeamUser, setAllTeamUser] = useState([]);
    const [loading, setLoading] = useState(false);
    const textInputRef = useRef(null);

    useEffect(() => {
        loadTeamUserList();
    }, []);
    
    const changeNote = (event) => {
        setNote(event.target.value || '');
    };

    const handleNoteSubmit = () => {
        setSubmittingNote(true);

        addNote({
            note: note,
            contact_id: props.contact.id,
            origin: 4
        }).then(res => {
            if (res.data.status === 'success') {
                window.showNotification("SUCCESS", "Add Note Successfully");
                props.onClose();
                props.reloadNeedForNoteList(true);
            }
        }).finally(() => {
            setSubmittingNote(false);
        });
    };

    const loadTeamUserList = () => {
        setLoading(true);
        getAllTeamUsers({}).then(res => {
            if (res.data.users) {
                const users = res.data.users.map(data => ({
                    id: data.value,
                    display: data.label,
                }));
                setAllTeamUser(users);
                setLoading(false);
            }
        });
    };



    return (
        <React.Fragment>
            <GlobalModal
                {...props}
                title={"Note"}
                buttonText={"Save"}
                buttonIcon={<AddIcon />}
                onSubmit={handleNoteSubmit}
                hideFooter={false}
                modalClass={'manage-tag-modal create-collaborate-modal update singleContactNoteModal'}
            >
                {submittingNote ? (
                    <div style={{ width: '100%' }}>
                        <Skeleton variant="rect" width={"100%"} height={"300px"} />
                    </div>
                ) : (
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="modal-content p-0">
                                <div className="chat__bottom_wrapper update">
                                    <div className="tiny-mce">
                                        <MentionsInput
                                            cols={30}
                                            rows={5}
                                            className="control-overflow mentionClass"
                                            value={note}
                                            onChange={changeNote}
                                            style={defaultStyle}
                                            markup="@[__display__](__id__) "
                                            placeholder={" Add notes and mentions (@username)"}
                                        >
                                            <Mention
                                                type="user"
                                                trigger="@"
                                                data={allTeamUser}
                                                renderSuggestion={(suggestion, search, highlightedDisplay) => (
                                                    <div className="user">{highlightedDisplay}</div>
                                                )}
                                                style={mentionStyle}
                                            />
                                        </MentionsInput>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                )}
            </GlobalModal>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    contact: state.contactReducer.contact,
    showModal: state.contactReducer.showNoteModal,
});

const mapDispatchToProps = dispatch => ({
    reloadNeedForNoteList: (params) => dispatch(reloadNoteList(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(NoteComponent);
