import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Backdrop, CircularProgress, Divider, makeStyles } from "@material-ui/core";
import { BasicTabs } from "../commons/BasicTabs";
import TabPanel from "../commons/TabPanel";
import FileReport from "../../report/FileReport";
import SkipTracingForm from "./SkipTracingForm";
import Result from "./Result";
import { carrierLookupCleanDataApi } from "../../../../api/cleanDataApi";
import {
  LOOKUP_TYPE_BY_PAYLOAD_CONST,
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
  LOOKUP_TYPE_EMAIL_LOOKUP,
  LOOKUP_TYPE_SKIP_TRACING_LOOKUP
} from "../../../../constants/CoreConstants";
import { getAddressLines } from "../../../../helpers/getAddressLines";
import ModalAssignToCampaign from "../../report/actions/ModalAssignToCampaign";
import ModalAssignToPowerDialer from "../../report/actions/ModalAssignToPowerDialer";
import SkipTracingBulk from "./SkipTracingBulk";
import FileUploadHeader from "../emailPhoneVerification/FileUploadHeader";
import FileUpload from "../../fileUpload";
import { DataCleanContext } from "../../StateProvider";
import numberToDollar from "../../../../helpers/numberToDollar";
import { changeQueryParams } from "../../../../helpers/changeQueryParams";
import { getPageIndexByParams, getTabPath } from "../../../../helpers/tabPaths";
import PricingConfirmAlert from "../../../common/new-alert/PricingConfirmAlert";
import AddressGroupedReportDetails from "../../report/addressGrouped/AddressGroupedReportDetails";
import AddressGroupedFileReportDetails from "../../report/addressGrouped/AddressGroupedFileReportDetails";
import {StyledTab} from "../EnhanceDataStyles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const SkipTracing = () => {
  const classes = useStyles();
  const { state } = useContext(DataCleanContext);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [openCampaignDrawer, setOpenCampaignDrawer] = useState(false);
  const [openPowerDialerDrawer, setOpenPowerDialerDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState([]);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [cleanContactId, setCleanContactId] = useState("");
  const [viewDetailsReport, setViewDetailsReport] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get("page");
    setTabIndex(getPageIndexByParams(param));
  }, []);

  const handleFinishUpload = () => {
    setIsFileSelected(false);
    handleTabChange(null, 1);
  };

  const handleTabChange = (event, newValue) => {
    changeQueryParams(getTabPath(3, newValue));
    setTabIndex(newValue);
  };

  const submitHandler = async (value) => {
    PricingConfirmAlert({
      onSubmit: async () => {
        setLoading(true);
        try {
          const addressLines = await getAddressLines(value.address);

          const lookupArray = [];
          if (value.checkEmailAddress) {
            lookupArray.push(LOOKUP_TYPE_EMAIL_LOOKUP);
          }
          if (value.checkPhoneNumber) {
            lookupArray.push(LOOKUP_TYPE_CARRIER_LOOKUP);
          }
          if (value.checkDNC) {
            lookupArray.push(LOOKUP_TYPE_DNC_LOOKUP);
          }

          lookupArray.push(LOOKUP_TYPE_SKIP_TRACING_LOOKUP);

          const response = await carrierLookupCleanDataApi({
            lookupType: lookupArray,
            requestModule: "CLEAN_DATA",
            searchCategory: [value.find === "owner" ? "HOUSE_CURRENT_OWNER" : "HOUSE_CURRENT_RESIDENT"],
            contact: {
              ...addressLines,
            },
          });

          if (response && response["skip-trace-lookup-status"] === "REQUEST_DONE"){
            setResult(response.data);
          }else {
            if (response && response["error"] && response["error"]["skip-trace-lookup-error"]){
              window.showNotification("error", response["error"]["skip-trace-lookup-error"]);
            }else {
              window.showNotification("error", "Sorry! Unable to complete skip trace");
            }
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
        setLoading(false);
      },
      title: `Total Cost ${numberToDollar(
        state.carrierLookupPricesObject[LOOKUP_TYPE_BY_PAYLOAD_CONST[LOOKUP_TYPE_SKIP_TRACING_LOOKUP]]
      )}`,
      description: "These credits will be deducted from your balance",
      checkPayload: {
        action: [LOOKUP_TYPE_SKIP_TRACING_LOOKUP],
        address: value.address,
      },
    });
  };

  return (
    <Box>
      <Box p={2}>
        <Typography variant='h5'>Skip Tracing</Typography>
        <Typography variant='body2'>Track down contact info, property records, and more.</Typography>
      </Box>
      <Divider light />

      {isFileSelected ? (
        <>
          <FileUploadHeader title='Import CSV file for skip tracing' onBack={() => setIsFileSelected(false)} />
          <Divider style={{ marginBottom: "20px" }} light />
          <FileUpload onSelectFile={handleFinishUpload} />
        </>
      ) : (
        <>
          <Box>
            <BasicTabs value={tabIndex} onChange={handleTabChange}>
              <StyledTab label='New Search' value={0} />
              <StyledTab label='View Past Imports' value={1} />
              <StyledTab value={2} label='Results' />
            </BasicTabs>
            <Divider light />
          </Box>

          <TabPanel value={tabIndex} index={0}>
            <>
              <SkipTracingForm
                submitHandler={submitHandler}
                hasBack={result.length !== 0}
                onBack={() => setResult([])}
              />

              {result.length !== 0 && (
                <Result
                  result={result}
                  formHandler={submitHandler}
                  onClickCampaignButton={(cleanContactId) => {
                    setOpenCampaignDrawer(true);
                    setCleanContactId(cleanContactId);
                  }}
                  onClickDialerButton={() => {
                    setOpenPowerDialerDrawer(true);
                    setCleanContactId(cleanContactId);
                  }}
                />
              )}

              {result.length === 0 && <SkipTracingBulk onSelectFile={() => setIsFileSelected(true)} />}
            </>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>

            {/*in-case of fire add comment to this section ## current-section-start*/}
              {viewDetailsReport ? (
                  <AddressGroupedFileReportDetails
                      onChangeTab={() => setViewDetailsReport(false)}
                      fileSource="SKIP_TRACE"
                      filter={[
                        "valid-emails",
                        "invalid-emails",
                        "valid-numbers",
                        "invalid-numbers",
                        "dnc-on",
                        "dnc-off",
                        "landline",
                        "mobile",
                        "skip-trace",
                      ]}
                  />
              ) : (
                  <FileReport fileSource='SKIP_TRACE' onChangeTab={() => setViewDetailsReport(true)} />
              )}
            {/*in-case of fire add comment to this section ## current-section-end*/}


            {/*in-case of fire remove comment from this section ## backup-section-start*/}

            {/*{viewDetailsReport ? (*/}
            {/*    Utils.getAccountData("userId") === 1 ?*/}
            {/*        <AddressGroupedFileReportDetails*/}
            {/*            onChangeTab={() => setViewDetailsReport(false)}*/}
            {/*            fileSource="SKIP_TRACE"*/}
            {/*            filter={[*/}
            {/*              "valid-emails",*/}
            {/*              "invalid-emails",*/}
            {/*              "valid-numbers",*/}
            {/*              "invalid-numbers",*/}
            {/*              "dnc-on",*/}
            {/*              "dnc-off",*/}
            {/*              "skip-trace",*/}
            {/*              "landline",*/}
            {/*              "mobile"*/}
            {/*            ]}*/}
            {/*        />:*/}
            {/*        <FileReportDetails*/}
            {/*            onChangeTab={() => setViewDetailsReport(false)}*/}
            {/*            filter={[*/}
            {/*              "valid-emails",*/}
            {/*              "invalid-emails",*/}
            {/*              "valid-numbers",*/}
            {/*              "invalid-numbers",*/}
            {/*              "dnc-on",*/}
            {/*              "dnc-off",*/}
            {/*              "landline",*/}
            {/*              "mobile",*/}
            {/*              "skip-trace",*/}
            {/*            ]}*/}
            {/*            tab="SKIP_TRACE"*/}
            {/*        />*/}
            {/*) : (*/}
            {/*  <FileReport fileSource='SKIP_TRACE' onChangeTab={() => setViewDetailsReport(true)} />*/}
            {/*)}*/}

            {/*in-case of fire remove comment from this section ## backup-section-end*/}

          </TabPanel>

          <TabPanel value={tabIndex} index={2}>
            {/*in-case of fire add comment to this section ## current-section-start*/}

              <AddressGroupedReportDetails
                  fileSource="SKIP_TRACE"
                  filter={[
                    "valid-emails",
                    "invalid-emails",
                    "valid-numbers",
                    "invalid-numbers",
                    "dnc-on",
                    "dnc-off",
                    "landline",
                    "mobile",
                    "skip-trace",
                  ]}
              />

            {/*in-case of fire add comment to this section ## current-section-end*/}



            {/*in-case of fire remove comment from this section ## backup-section-start*/}

            {/*{*/}
            {/*  Utils.getAccountData("userId") === 1 ?*/}
            {/*      <AddressGroupedReportDetails*/}
            {/*          fileSource="SKIP_TRACE"*/}
            {/*          filter={[*/}
            {/*            "valid-emails",*/}
            {/*            "invalid-emails",*/}
            {/*            "valid-numbers",*/}
            {/*            "invalid-numbers",*/}
            {/*            "dnc-on",*/}
            {/*            "dnc-off",*/}
            {/*            "skip-trace",*/}
            {/*            "landline",*/}
            {/*            "mobile"*/}
            {/*          ]}*/}
            {/*      />:*/}
            {/*      <FileReportDetailsSingle*/}
            {/*          fileSource='SKIP_TRACE'*/}
            {/*          filter={[*/}
            {/*            "valid-emails",*/}
            {/*            "invalid-emails",*/}
            {/*            "valid-numbers",*/}
            {/*            "invalid-numbers",*/}
            {/*            "dnc-on",*/}
            {/*            "dnc-off",*/}
            {/*            "landline",*/}
            {/*            "mobile",*/}
            {/*            "skip-trace",*/}
            {/*          ]}*/}
            {/*          tab="SKIP_TRACE"*/}
            {/*      />*/}
            {/*}*/}

            {/*in-case of fire remove comment from this section ## backup-section-end*/}
          </TabPanel>
        </>
      )}

      <ModalAssignToCampaign
        open={openCampaignDrawer}
        onClose={() => setOpenCampaignDrawer(false)}
        selectedFileContactIds={[cleanContactId]}
      />

      <ModalAssignToPowerDialer
        open={openPowerDialerDrawer}
        onClose={() => setOpenPowerDialerDrawer(false)}
        selectedFileContactIds={[cleanContactId]}
      />

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  );
};

export default SkipTracing;
