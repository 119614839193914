import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import GlobalModal from "../../../globals/Modal/GlobalModal"
import { fetchPowerDialerList } from "../../../../actions/contactAction"
import NewLoader from "../../../common/NewLoader"
import { Save } from "@material-ui/icons"
import { cleanContactsActionsApi } from "../../../../api/cleanDataApi"
import { icons } from "./icon"
import "./assign_to_campaign_new.css"
import DynamicSkeleton from "../../../common/DynamicSkeleton"
import { InputAdornment } from "@material-ui/core"
import SearchIcon from '@material-ui/icons/Search';
import BasicInput from "../../../contact/middleSide/custom/custom/BasicInput"


const EachCampaign = (props) => {
  const checkSelect = (id) => {
    return !!props.selectedCampaigns.includes(id)
  }

  return (
    <li>
      <div className='custom-checkbox-wrapper'>
        <span
          className='custom-checkbox-icon'
          onClick={() => {
            props.selectCampaign(props.data.id)
          }}
          key={`id_${props.data.id};`}
        >
          {checkSelect(props.data.id) ? icons.check : icons.unCheck}
        </span>
        <span>{props.data.title}</span>
      </div>
    </li>
  )
}

const ModalAssignToPowerDialer = (props) => {
  const [powerDialerList, setPowerDialerList] = useState([])
  const [submitEnable, setSubmitEnable] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedCampaigns, setSelectedCampaigns] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [hasMore, setHaMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [searchText, setSearchText] = useState("")
  const powerDialers = useRef([])

  useEffect(() => {
    getCampaignData()
    if (powerDialers.current.length > 0) setPowerDialerList(powerDialers)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {}, [searchText])

  const getCampaignData = (page = currentPage) => {
    setLoading(true);
    props.fetchStorePowerDialerList(
      { page: page, from: "contact_list", searchText: searchText },
      (callback) => {
        setLoading(false);
        if (page === 0) {
          setPowerDialerList(callback.data)
          powerDialers.current = callback.data
        } else {
          let data = [...powerDialerList, ...callback.data]
          setPowerDialerList(data)
          powerDialers.current = callback.data
        }
        if (callback.data.length >= 20) {
          setHaMore(true)
          setCurrentPage(page + 1)
        } else {
          setHaMore(false)
        }
        setLoadingMore(false)
      }
    )
  }

  const handleRadio = (value) => {
    setSubmitEnable(true)
    let tempSelectedCampaigns = [...selectedCampaigns]
    if (tempSelectedCampaigns.includes(value)) {
      tempSelectedCampaigns.splice(tempSelectedCampaigns.indexOf(value), 1)
    } else {
      tempSelectedCampaigns.push(value)
    }

    setSelectedCampaigns(tempSelectedCampaigns)
  }

  const changeSearch = (e) => {
    const searchString = e.target.value.toLowerCase()
    const filteredPowerDialerList = powerDialers.current.filter(
      (powerDialer) => {
        const condition = powerDialer.title.toLowerCase().includes(searchString)
        if (condition) return true
        return false
      }
    )
    setSearchText(searchString)
    setPowerDialerList(filteredPowerDialerList)
  }

  const handleScroll = (e) => {
    if (
      hasMore &&
      !loadingMore &&
      Math.round(e.target.scrollTop + e.target.clientHeight) >=
        Math.round(e.target.scrollHeight)
    ) {
      setLoadingMore(true)
      getCampaignData()
    }
  }

  const addToPowerDialer = () => {
    const payload = {
      cleanContactIds: props.selectedFileContactIds,
      filterType: props.selectedFileContactIds[0] ? "SEGMENT" : "ALL",
      action: "ADD_TO_POWER_DIALER_LIST",
      powerDialers: selectedCampaigns,
      pageSource: props.tab
    }

    if (props.fileId){
      payload.fileId = props.fileId;
    }

    if (props.filter){
      if (props.filter.validNumbers || props.filter.invalidNumbers) {
        let numberStatus = [];

        if (props.filter.validNumbers){
          numberStatus.push("VALID");
        }

        if (props.filter.invalidNumbers){
          numberStatus.push("INVALID");
        }

        payload.numberStatus = numberStatus;
        payload.filterType = "SEGMENT";
      }

      if (props.filter.validEmails || props.filter.invalidEmails) {
        let emailStatus = [];

        if (props.filter.validEmails){
          emailStatus.push("VALID");
        }

        if (props.filter.invalidEmails){
          emailStatus.push("INVALID");
        }

        payload.emailStatus = emailStatus;
        payload.filterType = "SEGMENT";
      }

      if (props.filter.dncOn || props.filter.dncOff) {
        let dncStatus = [];

        if (props.filter.dncOn){
          dncStatus.push("DO_NOT_CALL");
        }

        if (props.filter.dncOff){
          dncStatus.push("VERIFIED_OK");
        }

        payload.dncStatus = dncStatus;
        payload.filterType = "SEGMENT";
      }

      if (props.filter.skipTrace){
        payload.skipTraceStatus = ["TRACED"];
        payload.filterType = "SEGMENT";
      }

      if (props.filter.landline || props.filter.mobile){
        let numberType = [];

        if (props.filter.landline){
          numberType.push("landline");
        }

        if (props.filter.mobile){
          numberType.push("mobile");
        }

        payload.numberType = numberType;
        payload.filterType = "SEGMENT";
      }
    }

    cleanContactsActionsApi(payload)
      .then((res) => {
        if (res && res.success) {
          window.showNotification("success", res.message)
          props.onClose()
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          )
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleSubmit = () => {
    if (selectedCampaigns.length === 0) {
      window.showNotification("error", "Please select a power dialer")
      return
    }
    if (selectedCampaigns) {
      setLoading(true)
      addToPowerDialer()
    } else {
      window.showNotification("ERROR", "No campaign selected")
    }
  }

  return (
    <GlobalModal
      {...props}
      title='Assign To Power Dialer'
      buttonText='Add'
      buttonIcon={<Save />}
      onSubmit={handleSubmit}
      hideFooter={false}
      modalClass='manage-tag-modal modal-assign-campaign update'
      className='manage-tag-modal modal-assign-campaign create-new-list update scroll_v2 global-medium-modal'
      submitButtonDisable={loading || !submitEnable}
    >
      {loading ? (
        <DynamicSkeleton count={12} />
      ) : (
        <div className='row'>
          <div className='col-md-12 contact-to-campaign'>
            <div className='campaign-search-list'>
              <div className='campaign-search form' style={{ paddingLeft: "10px" }}>
                <div className='form-group m-0'>
                  <div className='m-input-icon m-input-icon--right'>
                    <BasicInput
                      onChange={changeSearch}
                      value={searchText}
                      placeholder='Type and search'
                      fullWidth
                      endAdornment={
                        <InputAdornment position='end'>
                          <SearchIcon style={{ color: "#656565", marginRight: "13px" }} />
                        </InputAdornment>
                      }
                    />
                  </div>
                </div>
              </div>
              <div className='upload-single-item-main2 upload-single-item-main'>
                {Array.isArray(powerDialerList) && powerDialerList.length > 0 ? (
                  <ul className='awesome__scroll_bar' onScroll={handleScroll}>
                    {powerDialerList.map((data, index) => {
                      return (
                        <EachCampaign
                          selectCampaign={handleRadio}
                          data={data}
                          key={index}
                          selectedCampaigns={selectedCampaigns}
                        />
                      );
                    })}
                  </ul>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingTop: "20px",
                    }}
                  >
                    No Data Available
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </GlobalModal>
  );
}

const mapStateToProps = (state) => {
  return {
    storedCampaignList: state.contactReducer.powerDialerList,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchStorePowerDialerList: (params, callback) =>
      dispatch(fetchPowerDialerList(params, callback)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalAssignToPowerDialer))
