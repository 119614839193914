import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import {
  AGENCY_CARRIER_LOOKUP_CREDIT_TYPE,
  CARRIER_INFO_TYPES,
} from "../../constants/CoreConstants";
import FileUploader from "./FileUploader";
import { DataCleanContext } from "./StateProvider";
import ActionType from "./ActionTypes";

const VerifyBulkSearch = ({ onSelectFile }) => {
  const { state, dispatch } = useContext(DataCleanContext);

  const handleCheckboxChange = (e) => {
    dispatch({
      type: ActionType.UPDATE_STATE,
      payload: {
        carrierInfos: {
          ...state.carrierInfos,
          [e.target.name]: e.target.checked
            ? CARRIER_INFO_TYPES[e.target.name]
            : null,
        },
      },
    });
  };

  return (
    <Box>
      <Box sx={{ textAlign: "center", py: 3 }}>
        <Typography variant="body1" style={{ marginBottom: "10px" }}>Upload a list of contacts</Typography>
        <FormControl>
          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                name="emailVerificationType"
                checked={!!state.carrierInfos.emailVerificationType}
                onChange={handleCheckboxChange}
              />
            }
            label={`Validate Email Address ($${
              state.carrierLookupPricesObject[
                AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.VERIFY_EMAIL
              ] ?? "x.xx"
            })`}
          />
          <FormControlLabel
            control={
              <Checkbox
                color={"primary"}
                name="phoneVerificationType"
                checked={!!state.carrierInfos.phoneVerificationType}
                onChange={handleCheckboxChange}
              />
            }
            label={`Validate Phone Number ($${
              state.carrierLookupPricesObject[
                AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.CARRIER
              ] ?? "x.xx"
            })`}
          />
        </FormControl>
      </Box>
      <FileUploader center onSelectFile={onSelectFile} />
    </Box>
  );
};

export default VerifyBulkSearch;
