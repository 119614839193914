import React, {useEffect, useState} from "react"
import {getPipelineList, getStageListWithPagination} from "../../../../api/contactApi";
import {DropdownHelper} from "../../../globals/DropdownHelper";
import {cleanContactsActionsApi} from "../../../../api/cleanDataApi";
import ModalWithHeader from "../../enhanceData/commons/ModalWithHeader";


const ModalListForStageAdd = (props) => {
    const [selectedPipelineId, setSelectedPipelineId] = useState("");
    const [selectedStageId, setSelectedStageId] = useState("");
    const [submittingData, setSubmittingData] = useState(false);
    const [stageListForAction, setStageListForAction] = useState([]);
    const [pipelineList, setPipelineList] = useState([]);

    useEffect(() => {
        getPipelineList()
            .then((res)=>{
                if (res && res.data && res.data.data){
                    setPipelineList(res.data.data);
                }else {
                    setPipelineList([]);
                }
            }).catch((err)=>{
            setPipelineList([]);
        });
    }, []);

    const updatePipeline = async (pipelineId) => {
        setSelectedPipelineId(pipelineId)
        if (setSelectedStageId !== "") {
            setSelectedStageId("")
        }
        await getStageListForDropdown(pipelineId, "action");
    };

    const updateStage = (stageId) => {
        setSelectedStageId(stageId);
    };

    const submitContactInStage = async () => {
        if(selectedPipelineId === "") {
            window.showNotification("ERROR","Please select pipeline");
            return;
        }

        if(selectedStageId === ""){
            window.showNotification("ERROR","Please select stage");
            return;
        }

        const payload = {
            cleanContactIds: props.selectedFileContactIds,
            filterType: props.selectedFileContactIds[0] ? "SEGMENT" : "ALL",
            action: "ADD_TO_PIPELINE_STAGE",
            pipelineStageIds: [parseInt(selectedStageId)],
            pageSource: props.tab
        };

        if (props.fileId){
            payload.fileId = props.fileId;
        }

        if (props.filter){
            if (props.filter.validNumbers || props.filter.invalidNumbers) {
                let numberStatus = [];

                if (props.filter.validNumbers){
                    numberStatus.push("VALID");
                }

                if (props.filter.invalidNumbers){
                    numberStatus.push("INVALID");
                }

                payload.numberStatus = numberStatus;
                payload.filterType = "SEGMENT";
            }

            if (props.filter.validEmails || props.filter.invalidEmails) {
                let emailStatus = [];

                if (props.filter.validEmails){
                    emailStatus.push("VALID");
                }

                if (props.filter.invalidEmails){
                    emailStatus.push("INVALID");
                }

                payload.emailStatus = emailStatus;
                payload.filterType = "SEGMENT";
            }

            if (props.filter.dncOn || props.filter.dncOff) {
                let dncStatus = [];

                if (props.filter.dncOn){
                    dncStatus.push("DO_NOT_CALL");
                }

                if (props.filter.dncOff){
                    dncStatus.push("VERIFIED_OK");
                }

                payload.dncStatus = dncStatus;
                payload.filterType = "SEGMENT";
            }

            if (props.filter.skipTrace){
                payload.skipTraceStatus = ["TRACED"];
                payload.filterType = "SEGMENT";
            }

            if (props.filter.landline || props.filter.mobile){
                let numberType = [];

                if (props.filter.landline){
                    numberType.push("landline");
                }

                if (props.filter.mobile){
                    numberType.push("mobile");
                }

                payload.numberType = numberType;
                payload.filterType = "SEGMENT";
            }
        }

        setSubmittingData(true)
        cleanContactsActionsApi(payload).then((res)=>{
            if (res && res.success){
                window.showNotification("success", res.message);
                props.onClose();
            }else {
                window.showNotification("error", "Something went wrong try again later");
            }
        }).finally(()=>{
            setSubmittingData(false)
        });
    };

    const getStageListForDropdown = async (pipelineId, type = "" , perPage = 20, pageNo = 1, scroll= false) => {
        let params = {"pipeline_id": pipelineId };
        if(scroll){
            params["perPage"]= perPage
            params["pageNo"] = pageNo
        }
        try{
            const response = await getStageListWithPagination(params);
            if (response && response.data && response.data.stages && type === "action") {
                setStageListForAction(response.data.stages);
            }
        }catch (err){
            setStageListForAction([]);
        }
    }

    return (
        <ModalWithHeader
            {...props}
            header="Assign To Stage"
            buttonText={submittingData ? "Adding..." : "Add"}
            onSubmit={submitContactInStage}
            saving={submittingData}
        >
            <div className={"de_modal_content"}>
            <DropdownHelper
                datalist={pipelineList}
                noneFieldRequired={true}
                noneFieldTitle="Select Pipeline"
                className=""
                mapping={{ title: "title" }}
                updateSelectedData={updatePipeline}
                selectedValue={selectedPipelineId}
                dropdownHeader="pipleineChange"
                parentDivClassName="ppc__select_owner"
            />
            {selectedPipelineId !== "" &&
                <DropdownHelper
                    datalist={stageListForAction}
                    noneFieldRequired={true}
                    noneFieldTitle="Select Stage"
                    className=""
                    mapping={{ title: "stage" }}
                    updateSelectedData={updateStage}
                    selectedValue={selectedStageId}
                    dropdownHeader="stageChange-contact"
                    parentDivClassName="ppc__select_owner"
                />
            }
            </div>
        </ModalWithHeader>
    )
}

export default ModalListForStageAdd