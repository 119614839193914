
import { memo, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { createAdditionalContactApi, deleteAdditionalContactApi, getAllAdditionalContactApi, makeNumberDefaultAPI, updateAdditionalContactApi } from "../../../api/contactApi";
import { updateMultipleData } from "../../../actions/contactAction";
import SelectContactEmail from "./SelectContactEmail";
import CardContent from '@material-ui/core/CardContent';
import { Card } from "@material-ui/core";
import CreateIcon from '@material-ui/icons/Create';
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import DoneIcon from '@material-ui/icons/Done';
import Button from "@material-ui/core/Button";
import { confirmAlert } from "react-confirm-alert";
import Utils from "../../../helpers/Utils";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import { IconList } from "../../globals/IconList";

const CONTACT_RELATION = [
    {
        id: "HOME",
        relation_type: "HOME",
    },
    {
        id: "WORK",
        relation_type: "WORK",
    },
    {
        id: "OTHERS",
        relation_type: "OTHERS",
    },
];
const AdditionContactList = (props) => {

    const perPage = 4;

    const [scrollLoading, setScrollLoading] = useState(false);
    const [extraEmailList, setExtraEmailList] = useState(null);
    const [errors, setIsErrors] = useState({ email: '' });
    const [validationText, setValidationText] = useState("");
    const [editItemId, setEditItemid] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [validationNumberText, setValidationNumberText] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [validation, setValidation] = useState(null);
    const [updateIndex, setUpdateIndex] = useState(null);
    const [page, setPage] = useState(1);

    useEffect(() => {

        setPage(1)
        getAdditionalContactList(1)
    }, [props.contact]);

    useEffect(() => {
        if (page !== 1) getAdditionalContactList(page)
    }, [page])

    const getAdditionalContactList = (currentPage = page) => {
        getAllAdditionalContactApi({
            page_no: currentPage,
            per_page: perPage,
            contact_id: parseInt(props.contact.id),
        }).then((response) => {
            if (response.data.success === true) {
                if (response.data.data) {
                    if (Array.isArray(response.data.data)) {

                        if (currentPage === 1 || !extraEmailList) {
                            setExtraEmailList(response.data.data);
                        } else {
                            setExtraEmailList((prevData) => [...prevData, ...response.data.data]);
                        }
                        if (response.data.data.length < perPage) {
                            setScrollLoading(null)
                        } else {
                            setScrollLoading(false)
                        }
                    }
                }
            }

        });
    };

    const makeDefault = (item, index) => {

        confirmAlert({
            title: 'Are you sure?',
            message: `Do you want to make this number default?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        makeNumberDefaultAPI({
                            "contact_id": props.contact.id,
                            "additional_contact_id": item.id,
                            "change": "REQUIRED",
                        })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    defaultProcess(item, index)
                                    // 	// props.refreshData();

                                    // 	window.showNotification('SUCCESS', response.data.message);
                                    // 	//   window.location.reload();
                                    // } else {
                                    // 	window.showNotification('ERROR', response.data.message);
                                    // }
                                }
                            })
                            .catch(error => {
                                window.showNotification('ERROR', 'Something went wrong');
                                console.log(error)
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...props.inputList];
        list.splice(index, 1);
        props.setInputList(list);
        setEditItemid(null)
        setIsEdit(false)
        setIsErrors({ first_name: '', email: '', contact_relation: '' })
        setValidationText("")
    };

    const handleValidation = () => {
        let formInputList = [...props.inputList]
        let errors = {}
        if (formInputList.length > 0) {

            if (formInputList[0]["email"] === "" && formInputList[0]["number"] === "") {
                errors.email = "email or number is required";
                errors.number = "email or number is required";
            }

            if (formInputList[0]["label"] && formInputList[0]["label"].length > 50) {
                errors.label = "label must be less than or equal 50 character";
            }

        }
        if (Object.keys(errors).length > 0) {
            setIsErrors(errors)
            return false
        } else {
            return true
        }
    }

    const handleSubmitForm = (e) => {
        e.preventDefault();
        if (
            handleValidation() &&
            validationText === "" &&
            validationNumberText === ""
        ) {
            setIsLoading(true);
            let obj = {
                contact_id: parseInt(props.contact.id),
            };
            let inputData = [...props.inputList];
            obj.first_name = inputData[0]["first_name"];
            obj.last_name = inputData[0]["last_name"];
            obj.email = inputData[0]["email"];
            obj.label = inputData[0]["label"];
            obj.number = inputData[0]["number"];
            obj.contact_relation = inputData[0]["contact_relation"];
            if (inputData[0]["id"] !== undefined) {
                obj.id = inputData[0]["id"];
            }

            if (!isEdit) {
                createAdditionalContactApi(obj)
                    .then((response) => {
                        console.log(response)
                        if (response.data.status === "success") {
                            setIsLoading(false);
                            setValidation(null);
                            setIsEdit(false);
                            props.setInputList([]);
                            if (extraEmailList) {
                                setExtraEmailList([response.data.data, ...extraEmailList]);
                            } else {
                                setExtraEmailList([response.data.data]);

                            }
                            props.setToggle(true);

                            window.showNotification("SUCCESS", response.data.html);
                        } else {
                            console.log(obj);
                            setIsLoading(false);
                            // setValidation("The email has already been taken");
                            if (response.data.status === "validation-error") {
                                if (response.data.html.email) {
                                    setIsErrors({ email: response.data.html.email[0] })
                                }
                                if (response.data.html.number) {
                                    setIsErrors({ number: response.data.html.number[0] })

                                }
                                console.log(errors);
                            }

                        }
                    })
                    .catch((error) => { });
            } else {
                updateAdditionalContactApi(obj)
                    .then((response) => {
                        // console.log(response);
                        if (response.data.status === "success") {
                            setIsLoading(false);
                            setIsEdit(false);
                            setEditItemid(null);
                            setValidation(null);
                            if (extraEmailList) {
                                let newData = [...extraEmailList];
                                newData[updateIndex] = props.inputList[0];
                                setExtraEmailList(newData)
                            }
                            //   setUpdateIndex(-1);
                            props.setInputList([]);

                            window.showNotification("SUCCESS", response.data.html);
                        } else {
                            setIsLoading(false);
                            // setValidation("The email has already been taken");
                            if (response.data.status === "validation-error") {
                                if (response.data.html.email) {
                                    setIsErrors({ email: response.data.html.email[0] })
                                }
                                if (response.data.html.number) {
                                    setIsErrors({ number: response.data.html.number[0] })

                                }
                            }
                        }
                    })
                    .catch((error) => { });
            }
        }
    };


    const defaultProcess = (item) => {

        let tmpContact = { ...props.contact }
        if (item.email) {
            tmpContact.email = item.email;
        }
        if (item.number) {
            if (item.number.length === 10) {
                tmpContact.number = '1' + item.number;

            } else {
                tmpContact.number = item.number;

            }
        }
        props.updateMultipleData({ contact: tmpContact })
        setPage(1)
        getAdditionalContactList(1)
        // window.location.reload();
        // let newData = [...extraEmailList];
        // newData.splice(index, 1)
        // setExtraEmailList(newData);
    }

    // Edit Extra Email

    const editExtraEmail = (oldItem, index) => {
        let obj = {
            first_name: oldItem.first_name,
            last_name: oldItem.last_name,
            email: oldItem.email,
            number: oldItem.number,
            label: oldItem.label,
            contact_relation: oldItem.contact_relation,
            id: oldItem.id
        }
        setUpdateIndex(index)
        setEditItemid(oldItem.id)
        setIsEdit(true)
        props.setInputList([{ ...obj }])
        setValidation(null)
    }

    const handleScroll = (event) => {
        const container = event.target;
        const distanceToBottom =
            container.scrollHeight - (container.scrollTop + container.clientHeight);
        const loadThreshold = 100;
        if (distanceToBottom < loadThreshold && scrollLoading === false) {
            setScrollLoading(true);
            setPage((prevPage) => prevPage + 1);
        }
    };



    // handle click event of the Add button
    const handleAddClick = () => {
        if (validation != null) {
            setValidation(null)
        }
        props.setInputList([...props.inputList, { first_name: "", last_name: "", email: "", contact_relation: CONTACT_RELATION[0].id, label: "", number: "" }]);
        props.setToggle(!props.toggle);
    };

    const removeExtraEmails = async (itemId, dataIndex) => {
        confirmAlert({
            title: "Confirm to delete",
            message: "Are you sure to delete contact email?",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        await deleteAdditionalContactApi({
                            contact_id: props.contact.id,
                            id: itemId,
                        })
                            .then((response) => {
                                if (response.data.status === "error") {
                                    window.showNotification("ERROR", "Something went wrong");
                                } else {

                                    let oldData = [...extraEmailList];

                                    oldData.splice(dataIndex, 1);
                                    setExtraEmailList(oldData);
                                    window.showNotification("SUCCESS", response.data.html);
                                }
                            })
                            .catch((error) => {
                                window.showNotification("ERROR", "Something went wrong");
                            });
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        if (name === "email") {
            let invalid = "";
            if (!Utils.validateEmail(value) && value !== "") {
                invalid = "Invalid email";
            }
            setValidationText(invalid)
            setValidation("")
        }
        if (name === "number") {
            let invalidNumber = "";
            if (!Utils.validateMobileNumber(value) && value !== "") {
                invalidNumber = "Invalid number";
            }
            setValidationNumberText(invalidNumber);
            setValidation("");
        }
        const list = [...props.inputList];
        list[index][name] = value;
        props.setInputList(list);
        setIsErrors({
            ...errors, [name]: ''
        })
    };

    const renderName = (first_name, last_name) => {
        let firstName = Utils.isEmpty(first_name)
        let lastName = Utils.isEmpty(last_name)

        if (!firstName && !lastName) {
            return first_name += " " + last_name
        }
        else if (!firstName) {
            return first_name
        }
        else if (!lastName) {
            return last_name
        }
        else {
            return null
        }
    }

    return (
        <div>
            <div>
                {editItemId == null && !isEdit && !props.loadingData && props.inputList.map((item, i) => {
                    return (
                        <div key={i} className="email-box addtional__email__input" >
                            <form className="additional__email__input__form" onSubmit={handleSubmitForm}>
                                <SelectContactEmail
                                    // className="addtional__email__select__type"
                                    label="Type"
                                    name="contact_relation"
                                    value={item.contact_relation}
                                    data={CONTACT_RELATION}
                                    onTypeChange={(e) => handleInputChange(e, i)}
                                    formType="add"

                                />
                                <input
                                    name="first_name"
                                    placeholder="Enter First Name"
                                    value={item.first_name}
                                    onChange={e => handleInputChange(e, i)}
                                />

                                <input
                                    name="last_name"
                                    placeholder="Enter Last Name"
                                    value={item.last_name}
                                    onChange={e => handleInputChange(e, i)}
                                />

                                <input
                                    className="ml10"
                                    name="email"
                                    placeholder="Enter Email"
                                    value={item.email}
                                    onChange={e => handleInputChange(e, i)}
                                    style={{ marginBottom: 0 }}
                                />
                                {errors.email !== '' && <span style={{ color: "red", marginBottom: 2 }}>{errors.email}</span>}

                                {
                                    validation &&
                                    <p style={{ color: "red", marginBottom: 2 }}>{validation}</p>
                                }
                                {
                                    errors.email === '' && validationText !== "" &&
                                    <small className="text-danger" style={{ marginBottom: 2 }}>{validationText}</small>
                                }
                                <input
                                    className="ml10 mt-2"
                                    name="number"
                                    placeholder="Enter Number"
                                    value={item.number}
                                    onChange={(e) => handleInputChange(e, i)}
                                />
                                {errors.number !== '' && <span style={{ color: "red", marginBottom: 2 }}>{errors.number}</span>}

                                {
                                    validation &&
                                    <p style={{ color: "red", marginBottom: 2 }}>{validation}</p>
                                }
                                {
                                    errors.number === '' && validationNumberText !== "" &&
                                    <small className="text-danger" style={{ marginBottom: 2 }}>{validationNumberText}</small>
                                }


                                <input
                                    className="ml10 mt-2"
                                    name="label"
                                    placeholder="Enter Label"
                                    value={item.label}
                                    onChange={e => handleInputChange(e, i)}
                                />
                                {
                                    errors.label !== "" &&
                                    <small className="text-danger" style={{ marginBottom: 2 }}>{errors.label}</small>
                                }
                                <div className="btn-box addtional__email__sent__btn__group">
                                    {!isLoading && props.inputList.length !== 0 && <button
                                        className="mr10 addtional__email__sent__btn__remove"
                                        onClick={() => handleRemoveClick(i)}>Cancel</button>}


                                    {!isLoading && props.inputList.length - 1 === i &&
                                        <button
                                            className="mr10 addtional__email__sent__btn__save" type='submit'
                                        >Save
                                        </button>}
                                    {
                                        isLoading && <p>Submitting...</p>
                                    }
                                </div>
                            </form>
                        </div>
                    );
                })}
            </div>
            <div>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                        backgroundColor: "#133159",
                        color: "white",
                        padding: "10px",
                        borderRadius: "5px",
                    }}
                >
                    Additional Contact Info
                    <span
                        className="small-round-icon"
                        onClick={() => props.setToggle(!props.toggle)}
                    >
                        {props.toggle ? (
                            <KeyboardArrowUpIcon fontSize="small" />
                        ) : (
                            <KeyboardArrowDownIcon fontSize="small" />
                        )}
                    </span>
                </div>
                {props.toggle && (!extraEmailList || extraEmailList.length === 0) ? (
                    <Card style={{ marginTop: "10px" }}>
                        <CardContent className="custom__card__content">
                            No Data Available
                        </CardContent>
                        {props.inputList.length === 0 &&
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ margin: "10px" }}
                                onClick={handleAddClick}
                            >
                                Add Additional Information
                            </Button>
                        }

                    </Card>
                ) : (
                    <div
                        onScroll={handleScroll}
                        style={{ maxHeight: "375px", overflow: "auto", paddingBottom: "10px", marginTop: "10px" }}
                        className="awesome__scroll_bar"
                    >
                        {props.toggle &&
                            !props.loadingData && Array.isArray(extraEmailList) &&
                            extraEmailList.length > 0 &&
                            extraEmailList.map((item, index) => {
                                return (
                                    <div>
                                        <div
                                            key={`${item.first_name} ${index}`}
                                            className="additional__email__field__wr"
                                            style={
                                                item.id === editItemId
                                                    ? { display: "none" }
                                                    : { display: "" }
                                            }
                                        >
                                            <Card>
                                                <CardContent className="custom__card__content">
                                                    <div className="custom__card__content__left">
                                                        {
                                                            <h6 className="additional__email__field__title">
                                                                {" "}
                                                                <span>
                                                                    {" "}
                                                                    Name :{" "}
                                                                    {renderName(
                                                                        item.first_name ?? '',
                                                                        item.last_name ?? ''
                                                                    ) ?? "N/A"}{" "}
                                                                </span>
                                                            </h6>
                                                        }

                                                        {item.contact_relation && (
                                                            <p className="additional__email__field__type">
                                                                Type:{" "}
                                                                <span>
                                                                    {" "}
                                                                    {item.contact_relation ?? "HOME"}
                                                                </span>
                                                            </p>
                                                        )}

                                                        {item.email ? (
                                                            <p className="additional__email__field__content">
                                                                Email :{" "}
                                                                <span>{item.email ?? ''}</span>
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {item.number ? (
                                                            <p className="additional__email__field__content">
                                                                Number :{" "}
                                                                <span>{item.number ?? ''}</span>
                                                            </p>
                                                        ) : (
                                                            ""
                                                        )}

                                                        {item.label ? (
                                                            <p className="additional__email__field__content">
                                                                Label :{" "}
                                                                <span>{item.label}</span>
                                                            </p>
                                                        ) : (
                                                            <div />
                                                        )}
                                                    </div>
                                                    <div className="custom__card__content__right">

                                                        <BootstrapTooltip arrow title={`Make this number Default`}>
                                                            <span
                                                                className="small-round-icon"
                                                                onClick={() => {
                                                                    makeDefault(item, index)
                                                                }}
                                                            >
                                                                {<DoneIcon fontSize="small" />}
                                                            </span>
                                                        </BootstrapTooltip>

                                                        {/* <BootstrapTooltip arrow title={`Update Additional Contacts`}> */}
                                                        <span
                                                            className="small-round-icon"
                                                            onClick={() =>
                                                                editExtraEmail(item, index)
                                                            }
                                                        >
                                                            {<CreateIcon fontSize="small" />}
                                                        </span>
                                                        {/* </BootstrapTooltip> */}
                                                        {/* <BootstrapTooltip arrow title={'Remove Additional Contacts'}> */}
                                                        <span
                                                            onClick={() =>
                                                                removeExtraEmails(
                                                                    item.id,
                                                                    index
                                                                )
                                                            }
                                                            style={{
                                                                float: "right",
                                                                cursor: "pointer",
                                                            }}
                                                        >
                                                            {IconList.redDeleteIcon}
                                                            {/* {<DeleteIcon fontSize="small"/>} */}
                                                        </span>
                                                        {/* </BootstrapTooltip> */}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>

                                        {item.id === editItemId && isEdit && props.inputList.map((item, i) => {
                                            return (
                                                <div key={item.contact_relation} className="email-box addtional__email__input" >
                                                    <form className="additional__email__input__form" onSubmit={handleSubmitForm}>
                                                        <SelectContactEmail
                                                            // className="addtional__email__select__type"
                                                            label="Type"
                                                            name="contact_relation"
                                                            value={item.contact_relation}
                                                            data={CONTACT_RELATION}
                                                            onTypeChange={(e) => handleInputChange(e, i)}
                                                            formType="edit"

                                                        />
                                                        <input
                                                            name="first_name"
                                                            placeholder="Enter First Name"
                                                            value={item.first_name}
                                                            onChange={e => handleInputChange(e, i)}
                                                        />

                                                        <input
                                                            name="last_name"
                                                            placeholder="Enter Last Name"
                                                            value={item.last_name}
                                                            onChange={e => handleInputChange(e, i)}
                                                        />

                                                        <input
                                                            className="ml10"
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            value={item.email}
                                                            onChange={e => handleInputChange(e, i)}
                                                            style={{ marginBottom: 0 }}
                                                        />
                                                        {errors.email !== '' && <span style={{ color: "red", marginBottom: 2 }}>{errors.email}</span>}

                                                        {
                                                            validation &&
                                                            <p style={{ color: "red", marginBottom: 2 }}>{validation}</p>
                                                        }
                                                        {
                                                            errors.email === '' && validationText !== "" &&
                                                            <small className="text-danger" style={{ marginBottom: 2 }}>{validationText}</small>
                                                        }
                                                        <input
                                                            className="ml10"
                                                            name="number"
                                                            placeholder="Enter Number"
                                                            value={item.number}
                                                            onChange={e => handleInputChange(e, i)}
                                                            style={{ marginBottom: 0 }}
                                                        />
                                                        {errors.number !== '' && <span style={{ color: "red", marginBottom: 2 }}>{errors.number}</span>}

                                                        {
                                                            validation &&
                                                            <p style={{ color: "red", marginBottom: 2 }}>{validation}</p>
                                                        }
                                                        {
                                                            errors.number === '' && validationNumberText !== "" &&
                                                            <small className="text-danger" style={{ marginBottom: 2 }}>{validationNumberText}</small>
                                                        }

                                                        <input
                                                            className="ml10 mt-2"
                                                            name="label"
                                                            placeholder="Enter Label"
                                                            value={item.label}
                                                            onChange={e => handleInputChange(e, i)}
                                                        />
                                                        {
                                                            errors.label !== "" &&
                                                            <small className="text-danger" style={{ marginBottom: 2 }}>{errors.label}</small>
                                                        }
                                                        <div className="btn-box addtional__email__sent__btn__group mb-1">
                                                            {!isLoading && props.inputList.length !== 0 && <button
                                                                className="mr10 addtional__email__sent__btn__remove"
                                                                onClick={() => handleRemoveClick(i)}>Cancel</button>}

                                                            {
                                                                !isLoading &&
                                                                <button
                                                                    className="mr10 addtional__email__sent__btn__save" type='submit'
                                                                >Update
                                                                </button>
                                                            }
                                                            {
                                                                isLoading && <p>Updating...</p>
                                                            }
                                                        </div>
                                                    </form>
                                                </div>
                                            );
                                        })}
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(memo(AdditionContactList));
