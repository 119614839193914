import {Backdrop, Box, CircularProgress, Divider, Grid, InputLabel, makeStyles, Typography} from "@material-ui/core";
import React, { useState } from "react";
import { CoreButton } from "./enhanceData/commons/CoreButton";
import Utils from "../../helpers/Utils";
import {
  CONTACT_VALIDATION_TYPE,
  LOOKUP_PROVIDER_TYPE_EMAIL,
  LOOKUP_PROVIDER_TYPE_NUMBER,
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_EMAIL_LOOKUP,
} from "../../constants/CoreConstants";
import NewConfirmAlert from "../common/new-alert/NewConfirmAlert";
import { carrierLookupCleanDataBulkApi, carrierLookupCleanDataSingleApi } from "../../api/cleanDataApi";
import ValidationResponse from "./ValidationResponse";
import { EachInputBox, StyledInput } from "./CommonCss";
import Styles from "./Verify.module.css";
import numberToDollar from "../../helpers/numberToDollar";
import PricingConfirmAlert from "../common/new-alert/PricingConfirmAlert";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const VerifySingleSearch = ({ state }) => {
  const classes = useStyles();
  const [emailAddress, setEmailAddress] = useState("");
  const [emailAddresses, setEmailAddresses] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [loading, setLoading] = useState(false);
  const creditSettingType = { CALLER_NAME: 63, CARRIER: 64, VERIFY_EMAIL: 65 };

  const submitSingleVerification = (validateType, providerType) => {
    let validationData = {};

    if (providerType === LOOKUP_PROVIDER_TYPE_EMAIL) {
      if (!emailAddress) {
        window.showNotification("error", "Email address is required");
        return;
      }

      if (!Utils.validateEmail(emailAddress)) {
        window.showNotification("error", "Invalid email address");
        return;
      }

      validationData = {
        lookupType: LOOKUP_TYPE_EMAIL_LOOKUP,
        contact: {
          email: emailAddress,
        },
        categoryName: CONTACT_VALIDATION_TYPE.VERIFY_EMAIL,
      };
    } else if (providerType === LOOKUP_PROVIDER_TYPE_NUMBER) {
      const phonePattern = /^(\+1|1)?\d{10}$/;
      if (!phoneNumber) {
        window.showNotification("error", "Phone number is required");
        return;
      }

      if (phonePattern.test(phoneNumber) === false) {
        window.showNotification("error", "Invalid phone number");
        return;
      }

      validationData = {
        lookupType: LOOKUP_TYPE_CARRIER_LOOKUP,
        contact: {
          number: phoneNumber,
        },
        categoryName: CONTACT_VALIDATION_TYPE.CARRIER,
      };
    }

    let price = state.carrierLookupPrices
      ? state.carrierLookupPrices.find(({ type }) => type === creditSettingType[validateType])
      : null;

    PricingConfirmAlert({
      onSubmit: () => {
        setLoading(true);
        carrierLookupCleanDataSingleApi(validationData)
          .then((response) => {
            if (response) {
              if (response.success || (response.message && response.message.includes("Invalid number :"))) {
                ValidationResponse({
                  response: response,
                  validationData: validationData,
                });
              } else {
                window.showNotification("error", response.message ?? "Failed to validate");
              }
            } else {
              window.showNotification("error", "Failed to validate");
            }
          })
          .catch((err) => {
            console.log(err.message);
            window.showNotification("error", "Failed to validate");
          })
          .finally(() => {
            setLoading(false);
          });
      },
      title: `Validating each ${providerType === LOOKUP_PROVIDER_TYPE_EMAIL ? "email" : "number"} will cost: ${
        price ? numberToDollar(price.value) : 0
      }`,
      description: "These credits will be deducted from your balance",
      checkPayload: {
        action: [validationData.lookupType],
        ...validationData.contact,
      },
    });
  };

  const submitBulkVerification = (validateType, providerType) => {
    let validationData = {};

    if (providerType === LOOKUP_PROVIDER_TYPE_EMAIL) {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const emails = emailAddresses.split("\n");
      let isAllEmailsValid = true;

      if (emails.length === 0) {
        window.showNotification("error", "Email address is required");
        return;
      }

      emails.forEach((email) => {
        if (emailPattern.test(email) === false) isAllEmailsValid = false;
      });

      if (isAllEmailsValid === false) {
        window.showNotification("error", "Invalid email addresses");
        return;
      }

      let contacts = emailAddresses.split("\n").map((email) => ({ email: email }));

      validationData = {
        lookupType: LOOKUP_TYPE_EMAIL_LOOKUP,
        contacts: contacts,
        categoryName: CONTACT_VALIDATION_TYPE.VERIFY_EMAIL,
      };
    } else if (providerType === LOOKUP_PROVIDER_TYPE_NUMBER) {
      const phonePattern = /^(\+1|1)?\d{10}$/;
      const phones = phoneNumbers.split("\n");
      let isAllPhonesValid = true;

      if (phones.length === 0) {
        window.showNotification("error", "Phone numbers are required");
        return;
      }

      phones.forEach((phone) => {
        if (phonePattern.test(phone) === false) isAllPhonesValid = false;
      });

      if (isAllPhonesValid === false) {
        window.showNotification("error", "Invalid phone numbers");
        return;
      }

      let contacts = phoneNumbers.split("\n").map((number) => ({ number: number }));

      validationData = {
        lookupType: LOOKUP_TYPE_CARRIER_LOOKUP,
        contacts: contacts,
        categoryName: CONTACT_VALIDATION_TYPE.CARRIER,
      };
    }

    let price = state.carrierLookupPrices
      ? state.carrierLookupPrices.find(({ type }) => type === creditSettingType[validateType])
      : null;

    NewConfirmAlert({
      onSubmit: () => {
        try {
          setLoading(true);
          carrierLookupCleanDataBulkApi(validationData)
            .then((response) => {
              if (response) {
                if (response.success) {
                  if (response.status) {
                    window.showNotification(
                      "success",
                      "Your request has been received. Click 'View Past Search Results' to check the outcomes."
                    );
                    let divElement = document.getElementById("email_phone_verification_Results");

                    if (divElement) {
                      divElement.click();
                      let currentURL = window.location.href;
                      let updatedURL = currentURL + '&redirect=processing';
                      window.history.pushState({path: updatedURL}, '', updatedURL);
                    }
                    // let currentURL = window.location.href;
                    // let newURL = currentURL + "&page=results&redirect=processing";
                    // window.location.replace(newURL);
                  } else {
                    window.showNotification("error", "Failed to validate");
                  }
                } else {
                  window.showNotification("error", response.message ?? "Failed to validate");
                }
              } else {
                window.showNotification("error", "Failed to validate");
              }
            })
            .catch((err) => {
              console.log(err.message);
              window.showNotification("error", "Failed to validate");
            })
            .finally(() => {
              setLoading(false);
            });
        } catch (err) {
          window.showNotification("error", "Failed to validate");
          console.log(err);
        }
      },
      title: `Validating each ${providerType === LOOKUP_PROVIDER_TYPE_EMAIL ? "email" : "number"} will cost: ${
        price ? numberToDollar(price.value) : 0
      }`,
      description: "These credits will be deducted from your balance",
      cancelText: "Cancel",
      submitText: "Validate",
      width: "480px",
    });
  };

  return (
    <Box style={{ display: "flex", gap: "8px", padding: "8px" }} mb={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EachInputBox>
            <Typography variant='body1'>Verify Single Email Address</Typography>
            <div className={Styles.formControl}>
              <StyledInput
                variant={"outlined"}
                value={emailAddress}
                fullWidth
                onChange={(e) => {
                  setEmailAddress(e.target.value);
                }}
                placeholder='Email Address'
              />
              <CoreButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  submitSingleVerification(CONTACT_VALIDATION_TYPE.VERIFY_EMAIL, LOOKUP_PROVIDER_TYPE_EMAIL);
                }}
              >
                Verify
              </CoreButton>
            </div>
          </EachInputBox>
        </Grid>
        <Grid item xs={12}>
          <EachInputBox>
            <Typography variant='body1'>Verify Multiple Email Addresses</Typography>
            <div className={`${Styles.formControl} ${Styles.flexAlignEnd}`}>
              <StyledInput
                size={"small"}
                variant={"outlined"}
                fullWidth
                multiline
                minRows={3}
                maxRows={4}
                value={emailAddresses}
                onChange={(e) => {
                  setEmailAddresses(e.target.value);
                }}
                placeholder={"Copy/Paste your emails here.\n1 email address per line."}
              />
              <CoreButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  submitBulkVerification(CONTACT_VALIDATION_TYPE.VERIFY_EMAIL, LOOKUP_PROVIDER_TYPE_EMAIL);
                }}
              >
                Verify
              </CoreButton>
            </div>
          </EachInputBox>
        </Grid>
      </Grid>
      <Divider light orientation={"vertical"} flexItem />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <EachInputBox>
            <Typography variant='body1'>Verify Single Phone Number</Typography>
            <div className={Styles.formControl}>
              <StyledInput
                variant={"outlined"}
                value={phoneNumber}
                fullWidth
                onChange={(e) => {
                  setPhoneNumber(e.target.value);
                }}
                placeholder='Phone Number'
              />
              <CoreButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  submitSingleVerification(CONTACT_VALIDATION_TYPE.CARRIER, LOOKUP_PROVIDER_TYPE_NUMBER);
                }}
              >
                Verify
              </CoreButton>
            </div>
          </EachInputBox>
        </Grid>
        <Grid item xs={12}>
          <EachInputBox>
            <Typography variant='body1'>Verify Multiple Phone Numbers</Typography>
            <div className={`${Styles.formControl} ${Styles.flexAlignEnd}`}>
              <StyledInput
                size={"small"}
                variant={"outlined"}
                fullWidth
                multiline
                minRows={3}
                maxRows={4}
                value={phoneNumbers}
                onChange={(e) => {
                  setPhoneNumbers(e.target.value);
                }}
                placeholder={"Copy/Paste your phone numbers here.\n1 phone number per line."}
              />
              <CoreButton
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                  submitBulkVerification(CONTACT_VALIDATION_TYPE.CARRIER, LOOKUP_PROVIDER_TYPE_NUMBER);
                }}
              >
                Verify
              </CoreButton>
            </div>
          </EachInputBox>
        </Grid>
      </Grid>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  );
};

export default VerifySingleSearch;
