import { createContext, useReducer } from "react";
import ActionType from "./ActionTypes";
import { FILE_IMPORT_STEP } from "./Constants";
import { getAccountData } from "../../helpers/Utils";

export const DataCleanContext = createContext({});

const statesForFileUpload = {
  activeStep: FILE_IMPORT_STEP.STEP_IMPORT_MAPPING,
  data: [],
  validateData: [],
  file: null,
  total: 0,
  current: 0,
  success: 0,
  failed: 0,
  isExistFailed: 0,
  totalUpload: 0,
  validateFailed: 0,
  checkedData: 0,
  maxFileSize: "100M",
  carrierInfos: {
    emailVerificationType: null,
    phoneVerificationType: null,
    doNotCall: null,
    skipTrace: null
  }
};

const dataCleanStates = {
  carrierLookupPrices: null,
  carrierLookupPricesObject: {},
  cleanDataRequestId: null,
  cleanDataRequest: null,
  enabledServices : {
    carrierLookup: !!getAccountData("carrierLookup"),
    dnc: !!getAccountData("dnc"),
    skipTrace: !!getAccountData("skipTrace"),
  },
  ...statesForFileUpload,
};

const dataCleanReducer = (state, action) => {
  switch (action.type) {
    case ActionType.UPDATE_STATE:
      return { ...state, ...action.payload };
    case ActionType.RESET_FILE_UPLOAD_STATE:
      return {
        ...state,
        ...statesForFileUpload,
      };
    default:
      return state;
  }
};

export const DataCleanProvider = ({ children }) => {
  const [state, dispatch] = useReducer(dataCleanReducer, dataCleanStates);
  return (
    <DataCleanContext.Provider value={{ state, dispatch }}>
      {children}
    </DataCleanContext.Provider>
  );
};
