import React from 'react';
import { Skeleton } from '@material-ui/lab';

const AssignToListSkeleton = () => {
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="campaign-search-list">
                    <div className="campaign-search form">
                        <Skeleton variant="rect" height={45} style={{ marginBottom: '15px' }} />
                    </div>
                    <div className="campaign-search-results">
                        <ul className="m-nav height200px awesome__scroll_bar update__list_item">
                            {Array.from({ length: 5 }).map((_, index) => (
                                <li key={index} style={{ marginBottom: '5px' }}>
                                    <Skeleton variant="rect" height={40} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssignToListSkeleton;
