import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddIcon from "@material-ui/icons/Add";
import CustomModal from "../common/CustomModal/CustomModal";
import PrimaryRoundedButton from "../common/button/PrimaryRoundedButton";
import ContactSearch from "./ContactSearch";
import { BasicFormLabel, BasicInput, BasicInputSelect } from "./RelatedContactsModalStyles";
import { LeftSideV2Styles } from "../LeftSideV2Styles";
import Utils from "../../../../helpers/Utils";
import { US_STATE_LIST } from "../helper/coreConstant";
import { addContactRelationship, getZipCodeByPlace, updateContactRelationship } from "../../../../api/contactApi";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useSelector } from "react-redux";

const GOOGLE_API_KEY = process.env.REACT_APP_MAP_KEY;

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
    "&.Mui-selected": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
  },
  dialogContentWidth: {
    width: "700px",
  },
  errorMessage: {
    position: "absolute",
    bottom: "-12px",
  },
  phoneNumberInput: {
    position: "relative",
    color: "var(--dark_blue)",
    backgroundColor: "white !important",
    fontSize: "14px !important",
    margin: "0 !important",
    height: "2.5rem !important",
    padding: "10px 12px 10px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },
  },
  addButtonStyle: {
    backgroundColor: "#00ff91",
    borderRadius: "4px",
    height: "37px",
    width: "37px",
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
  },
  googlePlacesAutocomplete: {
    width: "100%",
    marginTop: theme.spacing(2),
    "& .my-google-places-autocomplete__value-container": {
      padding: "0px 8px",
    },
    "& .my-google-places-autocomplete__input": {
      height: "16px !important",
      textAlign: "left !important",
      margin: "0 !important",
      border: "none !important",
      outline: "none !important",
      boxShadow: "none !important",
    },
    "& .my-google-places-autocomplete__input:focus": {
      border: "none !important",
      outline: "none !important",
      boxShadow: "none !important",
    },
    "& .my-google-places-autocomplete__control": {
      fontSize: "14px !important",
    },
    "& .my-google-places-autocomplete__input input": {
      height: "unset !important",
    },
  },
  manualEntryButton: {
    backgroundColor: "#316aff !important",
    color: "white !important",
    marginBottom: "10px",
    fontWeight: "700px",
    "&:hover,&:focus": {
      backgroundColor: "#316aff !important",
      color: "white !important",
    },
  },
}));

const checkLetterExistInText = (text) => {
  var regExp = /[^0-9]+/g;
  let result = false;
  if (regExp.test(text)) {
    result = true;
  }
  return result;
};

const AddOrEditRelatedContactsModal = ({ open, onClose, edit, setRelatedContacts }) => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [disableStatus,setDisableStatus]=useState(edit ? false : true)
  const [formData, setFormData] = useState({
    relationship_to_contact: edit?.relationship || "",
    lead_type: "",
    first_name: edit?.firstName || "",
    last_name: edit?.lastName || "",
    numbers: [{ number: "", number_state: "mobile" }],
    emails: [{ email: "" }],
    number: edit?.number || "",
    email: edit?.email || "",
    company_name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });

  const [showAddress, setShowAddress] = useState(false);

  const [errors, setErrors] = useState({
    contact: "",
    relationship: "",
  });

  const contact = useSelector((state) => state.contactReducer.contact);

  useEffect(() => {
    if (selectedContact) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        first_name: selectedContact?.firstName || "",
        last_name: selectedContact?.lastName || "",
        email: selectedContact?.email || "",
        number: selectedContact?.number || "",
        address: selectedContact?.address || "",
        city: selectedContact?.city || "",
        state: selectedContact?.state || "",
        country: selectedContact?.country || "",
        zip: selectedContact?.zip || "",
      }));

      setErrors((prevErrors) => ({
        ...prevErrors,
        contact: "",
      }));
    }
  }, [selectedContact]);

  const { primaryButton, closeButton, alignJustifyCenter } = LeftSideV2Styles();

  const makeAddress = () => {
    let address = "";

    address = formData.address;

    if (formData.city) {
      if (address) {
        address += " ";
      }
      address += formData.city;
    }

    if (formData.state) {
      if (address) {
        address += " ";
      }
      address += formData.state;
    }

    if (formData.country) {
      if (address) {
        address += " ";
      }
      address += formData.country;
    }

    if (formData.zip) {
      if (address) {
        address += " ";
      }
      address += formData.zip;
    }

    return address;
  };

  const handleInputField = (field, value) => {
    let tempContactForm = { ...formData };
    tempContactForm[field] = value;
    setFormData(tempContactForm);
  };

  const handleAddress = async (event) => {
    let placeId = event.place_id !== undefined && event.place_id != null ? event.place_id : null;
    let zip = "";
    let road = "";
    let streetNumber = "";
    let state = "";
    let city = "";
    let country = "";
    let county = "";
    if (placeId != null) {
      try {
        await getZipCodeByPlace({
          placeId: placeId,
          googleAPIKey: GOOGLE_API_KEY,
        }).then((res) => {
          try {
            if (
              res.data.data.result.formatted_address !== undefined &&
              res.data.data.result.formatted_address != null
            ) {
              let data_array = res.data.data.result.formatted_address.split(",");
              if (data_array.length === 4) {
                let state_zip = data_array[2].trim().split(" ");

                setFormData({
                  ...formData,
                  address: data_array[0].trim(),
                  city: data_array[1].trim(),
                  state: state_zip[0],
                  country: data_array[3],
                  zip: state_zip[1],
                });
              } else {
                zip = res?.data?.zip;
                if (res.data.data.result.address_components && Array.isArray(res.data.data.result.address_components)) {
                  res.data.data.result.address_components.map((item, index) => {
                    if (item.types[0] === "street_number") {
                      streetNumber = item.long_name;
                    } else if (item.types[0] === "route") {
                      road = item.long_name;
                    } else if (item.types[0] === "administrative_area_level_1") {
                      state = item.short_name;
                    } else if (item.types[0] === "administrative_area_level_2") {
                      city = item.long_name;
                    } else if (item.types[0] === "country") {
                      country = item.long_name;
                    } else if (item.types[0] === "postal_code") {
                      zip = item.long_name;
                    } else if (item.types[0] === "administrative_area_level_3") {
                      county = item.long_name;
                    }
                    setFormData({
                      ...formData,
                      address: streetNumber + " " + road,
                      city: county !== "" ? county : city,
                      state: state,
                      country: country,
                      zip: zip,
                    });
                  });
                } else {
                  updateContactInfo(event, zip);
                }
              }
            } else {
              zip = res?.data?.zip;
              if (res.data.data.result.address_components && Array.isArray(res.data.data.result.address_components)) {
                res.data.data.result.address_components.map((item, index) => {
                  if (item.types[0] === "street_number") {
                    streetNumber = item.long_name;
                  } else if (item.types[0] === "route") {
                    road = item.long_name;
                  } else if (item.types[0] === "administrative_area_level_1") {
                    state = item.short_name;
                  } else if (item.types[0] === "administrative_area_level_2") {
                    city = item.long_name;
                  } else if (item.types[0] === "country") {
                    country = item.long_name;
                  } else if (item.types[0] === "postal_code") {
                    zip = item.long_name;
                  } else if (item.types[0] === "administrative_area_level_3") {
                    county = item.long_name;
                  }
                  setFormData({
                    ...formData,
                    address: streetNumber + " " + road,
                    city: county !== "" ? county : city,
                    state: state,
                    country: country,
                    zip: zip,
                  });
                });
              } else {
                updateContactInfo(event, zip);
              }
            }
          } catch (error) {
            zip = res?.data?.zip;
            if (res.data.data.result.address_components && Array.isArray(res.data.data.result.address_components)) {
              res.data.data.result.address_components.map((item, index) => {
                if (item.types[0] === "street_number") {
                  streetNumber = item.long_name;
                } else if (item.types[0] === "route") {
                  road = item.long_name;
                } else if (item.types[0] === "administrative_area_level_1") {
                  state = item.short_name;
                } else if (item.types[0] === "administrative_area_level_2") {
                  city = item.long_name;
                } else if (item.types[0] === "country") {
                  country = item.long_name;
                } else if (item.types[0] === "postal_code") {
                  zip = item.long_name;
                } else if (item.types[0] === "administrative_area_level_3") {
                  county = item.long_name;
                }
                setFormData({
                  ...formData,
                  address: streetNumber + " " + road,
                  city: county !== "" ? county : city,
                  state: state,
                  country: country,
                  zip: zip,
                });
              });
            } else {
              updateContactInfo(event, zip);
            }
          }
        });
      } catch (e) {
        updateContactInfo(event, "");
        console.log(e);
      }
    }
  };

  const updateContactInfo = (event, zip) => {
    let fullAddressArr = event.description.split(",");
    let address = fullAddressArr[0] !== undefined ? fullAddressArr[0].trim() : "";
    let city = fullAddressArr[1] !== undefined ? fullAddressArr[1].trim() : "";
    let fullState = fullAddressArr[2] !== undefined ? fullAddressArr[2].trim() : "";
    let country = fullAddressArr[3] !== undefined ? fullAddressArr[3].trim() : "";

    let stateArr = fullState.split(" ");
    let state = stateArr[0] !== undefined ? stateArr[0].trim() : "";

    setFormData({
      ...formData,
      address: address,
      city: city,
      state: state,
      country: country,
      zip: zip,
    });
  };

  const handleChangeFormData = (key, value, index = null) => {
    setFormData((prevState) => ({ ...prevState, [key]: value }));
  };

  const callbackSelectContact = (relatedContact) => {
    if (relatedContact) {
      if(relatedContact.id !== contact.id){
        handleChangeFormData("contact_id", relatedContact.id);
        setSelectedContact(relatedContact);
        setDisableStatus(false)
      }else{
        window.showNotification("error", "you can't connect same contact");
      }
    }
  };

  const handleSubmit = async () => {
    if (!selectedContact?.id && !edit) {
      setErrors({
        ...errors,
        contact: "Contact is required, Please search and select contact",
      });
      return;
    } else if (selectedContact?.id === contact.id) {
        setErrors({
          ...errors,
          contact: "Relationship beetween same contact is not possible",
        });
        return;
    } else if (!formData.relationship_to_contact || formData.relationship_to_contact === "") {
      setErrors({
        ...errors,
        relationship: "Relationship is required",
      });
      return;
    } else if (formData.relationship_to_contact.length > 30) {
      setErrors({ relationship: "Relationship should not exceed 30 characters" });
      return;
    } else if (!formData.number && !formData.email) {
      window.showNotification("error", "Email or phone number at least one is required");
      return;
    } else {
      setErrors({ contact: "", relationship: "" });
    }
    const payload = {
      contactId: contact?.id,
      relatedContactId: selectedContact?.id,
      relationship: formData.relationship_to_contact,
      firstName: formData.first_name,
      lastName: formData.last_name,
      email: formData.email,
      number: formData.number,
    };

    const updatePayload = {
      contactRelationshipId: edit?.contactRelationshipId,
      relatedContactId: selectedContact?.id || edit.relatedContactId,
      relationship: formData.relationship_to_contact,
      firstName: formData.first_name,
      lastName: formData.last_name,
      email: formData.email,
      number: formData.number,
    };

    try {
      if (edit) {
        const response = await updateContactRelationship(updatePayload);

        if (response && response.success) {
          const updatedStatePayload = {
            ...edit,
            ...updatePayload,
          };
          setRelatedContacts((prevStat) =>
            prevStat.map((item) =>
              item.contactRelationshipId === edit?.contactRelationshipId ? updatedStatePayload : item
            )
          );
          window.showNotification("success", response.message);
          onClose();
        } else {
          window.showNotification("error", response.message);
        }
      } else {
        const response = await addContactRelationship(payload);

        if (response && response.success) {
          const updatedStatePayload = {
            ...selectedContact,
            ...payload,
            contactRelationshipId: response.data,
          };          
          setRelatedContacts((prevStat) => [...prevStat, updatedStatePayload]);
          window.showNotification("success", response.message);
          onClose();
        } else {
          window.showNotification("error", response.message);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const classes = useStyles();
  const getState = (value) => US_STATE_LIST.find((state) => state.value === value)?.label;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={1200}
      disableEnforceFocus
    >
      <CustomModal icon={<AccountCircleIcon />} title={"Manage Related Contacts"} open={open} onClose={onClose} />
      <DialogContent className={classes.dialogContentWidth}>
        {!edit && (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", marginTop: 16 }}>
            <PrimaryRoundedButton
              startIcon={<AddIcon />}
              onClick={() => {
                window.globalAddContactHelper(true, {
                  callback: (data) => {
                    const dataFormat = {
                      id: data?.id,
                      firstName: data?.first_name,
                      lastName: data?.last_name,
                      email: data?.emails[0]?.email,
                      number: data?.numbers[0]?.number,
                      address: data?.address,
                      city: data?.city,
                      state: data?.state,
                      country: data?.country,
                      zip: data?.zip,
                    };

                    setSelectedContact(dataFormat);
                  },
                });
              }}
            >
              Add New
            </PrimaryRoundedButton>
          </div>
        )}

        <div
          style={{ marginTop: 16 }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <ContactSearch callback={callbackSelectContact} />
              {errors.contact && (
                <Typography variant='body2' color='error'>
                  {errors.contact}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={12}>
              <BasicFormLabel variant='body1'>Relationship to Contact</BasicFormLabel>
              <BasicInput
                fullWidth
                placeholder='Relationship to Contact'
                value={formData.relationship_to_contact}
                onChange={(e) => {
                  handleChangeFormData("relationship_to_contact", e.target.value);
                }}
                required
                error={!!errors.relationship}
                style={{}}
              />

              {errors.relationship && (
                <Typography variant='body2' color='error'>
                  {errors.relationship}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6}>
              <BasicFormLabel variant='body1'>First Name</BasicFormLabel>
              <BasicInput
                name='first_name'
                placeholder='First Name'
                value={formData.first_name}
                onChange={(e) => {
                  handleChangeFormData("first_name", e.target.value);
                }}
                fullWidth
                disabled={disableStatus}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <BasicFormLabel variant='body1'>Last Name</BasicFormLabel>
              <BasicInput
                name='last_name'
                placeholder='Last Name'
                value={formData.last_name}
                onChange={(e) => {
                  handleChangeFormData("last_name", e.target.value);
                }}
                fullWidth
                disabled={disableStatus}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicFormLabel variant='body1'>Phone Number</BasicFormLabel>

              <BasicInput
                // name='last_name'
                placeholder='Phone Number'
                value={formData?.number}
                onChange={(e) => {
                  handleChangeFormData("number", e.target.value);
                }}
                fullWidth
                disabled={disableStatus}
              />
            </Grid>

            <Grid item xs={6}>
              <BasicFormLabel variant='body1'>Email Address</BasicFormLabel>
              <BasicInput
                fullWidth
                placeholder='Email Address'
                value={formData?.email}
                onChange={(e) => {
                  handleChangeFormData("email", e.target.value);
                }}
                disabled={disableStatus}
              />
            </Grid>
            {showAddress ? (
              <Fragment>
                <Grid item xs={12} style={{ paddingTop: "0px" }}>
                  <BasicFormLabel variant='body1'>Address</BasicFormLabel>

                  <BasicInput
                    fullWidth
                    placeholder='Enter Address'
                    value={formData.address}
                    onChange={(event) => handleInputField("address", event.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BasicFormLabel variant='body1'>City</BasicFormLabel>

                  <BasicInput
                    fullWidth
                    placeholder='Enter City'
                    value={formData.city}
                    onChange={(event) => handleInputField("city", event.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 1,
                    }}
                  >
                    <BasicFormLabel variant='body1'>State</BasicFormLabel>

                    <Select
                      fullWidth
                      className={classes.select}
                      name='state'
                      value={getState(formData.state) ? formData.state : ""}
                      displayEmpty
                      onChange={(event) => handleInputField("state", event.target.value)}
                      input={<BasicInputSelect />}
                      inputProps={{ "aria-label": "Without label" }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem className={`dropdownhelper-menuitem-class ${classes.menuItem}`} value=''>
                        Select State
                      </MenuItem>

                      {US_STATE_LIST.map((state) => (
                        <MenuItem
                          key={state.value}
                          className={`dropdownhelper-menuitem-class ${classes.menuItem}`}
                          value={state.value}
                        >
                          {state.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <BasicFormLabel variant='body1'>Country</BasicFormLabel>

                  <BasicInput
                    fullWidth
                    placeholder='Enter Country'
                    value={formData.country}
                    onChange={(event) => handleInputField("country", event.target.value)}
                  />
                </Grid>

                <Grid item xs={6}>
                  <BasicFormLabel variant='body1'>Zip Code:</BasicFormLabel>

                  <BasicInput
                    fullWidth
                    placeholder='Enter Zip Code'
                    value={formData.zip}
                    onChange={(event) => handleInputField("zip", event.target.value)}
                  />
                </Grid>
              </Fragment>
            ) : (
              !edit && (
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <BasicFormLabel variant='body1'>Address</BasicFormLabel>

                  <BasicInput
                    fullWidth
                    placeholder='Address'
                    value={makeAddress()}
                    // onChange={(event) => {
                    //   handleChangeDynamicInput("emails", "email", event.target.value, index);
                    // }}
                    readOnly
                    disabled
                  />

                  {/* <div style={{ fontSize: "12px" }}>{makeAddress()}</div> */}
                </Grid>
              )
            )}
          </Grid>
        </div>

        <div className={alignJustifyCenter} style={{ gap: "10px", marginTop: 24, marginBottom: 16 }}>
          <Button
            variant='contained'
            color='primary'
            disableElevation
            className={primaryButton}
            onClick={handleSubmit}
            startIcon={<AddCircleIcon />}
            // disabled={isLoading}
          >
            {edit ? "Update" : "Create"} Contact Relationship
          </Button>
          <Button className={closeButton} variant='contained' color='secondary' disableElevation onClick={onClose}>
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddOrEditRelatedContactsModal;
