import { useEffect, useState } from "react";
import { getAllBatchFramesApi } from "../../../api/dripBatchApi";
import GlobalModal from "../../contact/rightSide/Helpers/Modal/GlobalModal";
import FrameWiseContactReport from "./FrameWiseContactReport";

let sleepTimerDripReport = null;

const FrameWiseDripReport = (props) => {

    const[isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [data, setData] = useState([])
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [selectedFrame, setSelectedFrame] = useState(null)

    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false)

    useEffect(() => {
        if(props.batch != null){
            sleepTimerDripReport = setTimeout(() => {
                clearTimeout(sleepTimerDripReport)
                getBatchFramesData()
            }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getBatchFramesData = () => {
        getAllBatchFramesApi({
            batch_id: props.batch.id,
            current_page: currentPage,
            per_page: perPage
        }).then(res => {
            let response = res.data;
            if(response.status === "success"){
                setData([...data, ...response.html])
                if(response.html.length >= perPage){
                    setCurrentPage(currentPage + 1)
                    setHasMore(true)
                }
                else{
                    setHasMore(false)
                }
                setIsLoadingMore(false)
                setIsLoading(false)
            }
        }).catch(error => {
            setIsLoading(false)
            console.log(error)
        })
    }

    const handleOnClick = (item) => {
        setSelectedFrame(item)
        setIsShowDetail(true)
        // alert(`${item.id} ${item.batch_id}`)
    }

    const renderTableBody = () => {
        if(isLoading){
            return(
                <tr>
                    <td colSpan={3} className="empty__table__container">
                        <span>Please wait...</span>
                    </td>
                </tr>
            )
        }
        if(!isLoading && data.length === 0){
            return(
                <tr>
                    <td colSpan={3} className="empty__table__container">
                        <span>No data found !</span>
                    </td>
                </tr>
            )
        }
        let view = [];
        data.forEach((item, index) => {
            view.push(
                <tr key={index}>
                    <td className="total__count">
                        <span>{item.total_contact}</span>
                    </td>
                    <td className="created___at">
                        {/* <span>{item.created_at}</span> */}
                        <span>{window.globalTimezoneConversionToDifferentTimezone(item.created_at, 'UTC',"",'MM/DD/YYYY hh:mm a')}</span>
                    </td>
                    <td className="action___field">
                        <span className="drip__report__action__btn" onClick={() => {handleOnClick(item)}}>Details</span>
                    </td>
                </tr>
            )
        })
        return view;
    }

    const handleOnScroll = (e) => {
        if(!isLoading && hasMore && !isLoadingMore){
            if(Math.round((e.target.scrollTop + e.target.clientHeight), 10) >= Math.round(e.target.scrollHeight, 10)){
                setIsLoadingMore(true)
                getBatchFramesData()
            }
        }
    }

    return(
        <GlobalModal 
            open={props.isOpen}
            onClose={props.onClose}
            title={`Frame wise drip report`}
            className={"frame__wise__drip__report global-medium-modal"}
            hideFooter={true}
        >
            <div className="drip__frame__table" onScroll={handleOnScroll}>
                <table>
                    <thead>
                        <tr>
                            <th>Total Contact</th>
                            <th>Created Date</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableBody()}
                        {isLoadingMore && <tr><td colSpan={3} className="empty__table__container">Loading more...</td></tr>}
                    </tbody>
                </table>

                {isShowDetail &&
				<FrameWiseContactReport 
					isOpen={isShowDetail}
					onClose={() => {
						setIsShowDetail(false)
						setSelectedFrame(null)
					}}
					frame={selectedFrame}
				/>
			}
            </div>
        </GlobalModal>
    )
}
export default FrameWiseDripReport;