import * as ACTION from "../constants/contactActionTypes";
import {FETCH_CONTACT_ADDITIONAL_CONTACTS} from "../constants/contactActionTypes";

export const setContactDetails = payload => ({
    type: ACTION.SET_CONTACT_DETAILS,
    payload: payload
});

export const updateContactInfo = payload => ({
    type: ACTION.UPDATE_CONTACT,
    payload: payload
});

export const updateContactInfoWithCallback = (payload, callback) => ({
    type: ACTION.UPDATE_CONTACT,
    payload: {payload, callback}
});

export const addExtraEmailContact = payload => ({
    type: ACTION.ADD_EXTRA_EMAIL,
    payload: payload
});

export const getContactTagList = payload =>({
    type: ACTION.GET_CONTACT_TAG,
    payload: payload
})

export const getCategoryList = (payload, callback) =>({
    type: ACTION.GET_MAIL_CATEGORY,
    payload: {payload, callback}
})

export const CreateCardTemplate = (payload,callback)=>({
    type: ACTION.CREATE_POSTCARD_TEMPLATE,
    payload: {payload, callback}
})

export const toggleNoteModal = payload =>({
    type: ACTION.SHOW_NOTE_MODAL,
    payload: payload
})
export const toggleTaskModal = payload =>({
    type: ACTION.SHOW_TASK_MODAL,
    payload: payload
})

export const getUserVirtualNumberList = payload =>({
    type: ACTION.GET_VIRTUAL_NUMBER_LIST,
    payload: payload
})

export const getVideoFolders = () => ({
    type:ACTION.FETCH_VIDEO_FOLDERS,
    payload: {}
});
export const getVideos = payload => ({
    type:ACTION.FETCH_VIDEOS,
    payload: payload
});
export const deleteVideo = (payload, callback)=> ({
    type:ACTION.DELETE_VIDEOS,
    payload: {payload, callback}
});
export const updateFolderId = payload => ({
    type:ACTION.UPDATE_FOLDER_ID,
    payload: payload
});
export const getMessageTemplates = payload => ({
    type:ACTION.FETCH_MESSAGE_TEMPLATES,
    payload: payload
});
export const getAllContacts =  (payload, callback) => ({
    type:ACTION.GET_ALL_CONTACTS,
    payload: {payload, callback}
});

export const fetchTasks =  (payload, callback) => ({
    type:ACTION.FETCH_TASKS,
    payload: {payload, callback}
});

export const followupActivitySave = (payload, callback) => ({
    type: ACTION.SAVE_ACTIVITY_FOLLOWUP,
    payload: {payload, callback}
});

export const getSubUserList = (payload,callback)=>({
    type: ACTION.GET_SUBUSER_LIST,
    payload: {payload, callback}
})
export const setSubUserListFromAction = payload => ({
    type: ACTION.SET_SUBUSER_LIST,
    payload: payload
});
export const getUserTagList = (payload,callback)=>({
    type: ACTION.GET_USERTAG_LIST,
    payload: {payload, callback}
});
export const setUserTagList = (payload)=>({
    type: ACTION.SET_USERTAG_LIST,
    payload: {payload}
});
export const getUserSourceList = (payload,callback)=>({
    type: ACTION.GET_USERSOURCE_LIST,
    payload: {payload, callback}
});
export const setUserSourceList = (payload)=>({
    type: ACTION.SET_USERSOURCE_LIST,
    payload: {payload}
});

export const getUserCustomFieldList = (payload,callback)=>({
    type: ACTION.GET_USERCUSTOMFIELD_LIST,
    payload: {payload, callback}
});
export const setUserCustomFieldList = (payload)=>({
    type: ACTION.SET_USERCUSTOMFIELD_LIST,
    payload: {payload}
});
export const getPipelineList = (payload,callback)=>({
    type: ACTION.GET_PIPELINE_LIST,
    payload: {payload,callback}
});
export const setPipelineList = (payload)=>({
    type: ACTION.SET_PIPELINE_LIST,
    payload: {payload}
});
export const fetchCampaignList = (payload,callback)=>({
    type: ACTION.GET_CAMPAIGN_LIST,
    payload: {payload,callback}
});
export const fetchPowerDialerList = (payload,callback)=>({
    type: ACTION.GET_POWER_DIALER_LIST,
    payload: {payload,callback}
});
export const setCampaignList = (payload)=>({
    type: ACTION.SET_CAMPAIGN_LIST,
    payload: {payload}
});

export const reloadNoteList = (payload)=>({
    type: ACTION.RELOAD_NOTE_LIST,
    payload: payload
});

export const reloadConversationList = (payload)=>({
    type: ACTION.RELOAD_CONVERSATION,
    payload: payload
});

export const storeContactListGroup = (payload)=>({
    type: ACTION.SET_ALL_CONTACTLISTGROUP,
    payload: payload
});

export const contactListFirstLoading = (payload)=>({
    type: ACTION.CONTACT_LIST_FIRSTLOADING,
    payload: payload
});

export const storeContactListSelectedSource = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDSOURCE,
    payload: payload
});
export const storeContactListSelectedOwner = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDOWNER,
    payload: payload
});
export const storeContactListSearchTextInTop = (payload)=>({
    type: ACTION.CONTACT_LIST_SEARCHTEXTINTOP,
    payload: payload
});
export const storeContactListSelectedTag = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDTAG,
    payload: payload
});
export const storeContactListSelectedStage = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDSTAGE,
    payload: payload
});
export const storeContactListSelectedFile = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDFILE,
    payload: payload
});
export const storeContactListSelectedSubUser = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDSUBUSER,
    payload: payload
});
export const storeContactListSelectedCampaign = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDCAMPAIGN,
    payload: payload
});
export const storeContactListSelectedOperationList = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDOPERATIONLIST,
    payload: payload
});
export const storeContactListSelectedOmitList = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDOMITLIST,
    payload: payload
});
export const storeContactListSelectedContactIds = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDCONTACTIDS,
    payload: payload
});
export const storeContactListSelectedDateRangeInfo = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDDATERANGEINFO,
    payload: payload
});
export const storeContactListSelectedFromDate = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDFROMDATE,
    payload: payload
});
export const storeContactListSelectedEndDate = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDENDDATE,
    payload: payload
});
export const storeContactListSelectedColumnFilterData = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDCOLUMNFILTERDATA,
    payload: payload
});
export const storeContactListSelectedPage = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDPAGE,
    payload: payload
});
export const storeContactListSelectedPageSize = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDPAGESIZE,
    payload: payload
});
export const storeContactListSelectedDatePickerActive = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTEDDATEPICKERACTIVE,
    payload: payload
});
export const storeContactListMultipleSelectedData = (payload)=>({
    type: ACTION.CONTACT_LIST_COLUMNFILTERMULTIPLESELECTEDDATA,
    payload: payload
});
export const storeContactListMultipleSelectedDataLabel = (payload)=>({
    type: ACTION.CONTACT_LIST_COLUMNFILTERMULTIPLESELECTEDDATALABEL,
    payload: payload
});
export const storeContactListStageListForPipeline = (payload)=>({
    type: ACTION.CONTACT_LIST_STAGELIST_FOR_PIPELINE,
    payload: payload
});
export const storeContactListSelectedSavedContactList = (payload)=>({
    type: ACTION.CONTACT_LIST_SELECTED_SAVED_CONTACT_LIST,
    payload: payload
});

export const storeContactActivityList = (payload)=>({
    type: ACTION.SET_CONTACT_DETAILS_ACTIVITY_LIST,
    payload: payload
});

export const updateContactTags = (payload)=>({
    type: ACTION.SET_CONTACT_TAG,
    payload: payload
});

export const updateMultipleData = (payload)=>({
    type: ACTION.UPDATE_MULTIPLE_DATA,
    payload: payload
});

export const fetchContactAdditionalContacts = (payload)=>({
    type: ACTION.FETCH_CONTACT_ADDITIONAL_CONTACTS,
    payload: payload
});

export const fetchContactPolicies = (payload)=>({
    type: ACTION.GET_CONTACT_POLICIES,
    payload: payload
});

export const getUserProductActiveList = ()=>({
    type: ACTION.GET_USER_PRODUCT_ACTIVE_LIST,
});

export const getUserProductActiveListSuccess = (payload)=>({
    type: ACTION.GET_USER_PRODUCT_ACTIVE_LIST_SUCCESS,
    payload: payload
});

export const getUserProductActiveListFail = ()=>({
    type: ACTION.GET_USER_PRODUCT_ACTIVE_LIST_FAIL,
});