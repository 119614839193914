import React, { useEffect, useState } from 'react';
import CoreConstants from '../../../constants/CoreConstants'
import TimezoneConversation from '../../globals/TimezoneConversion';
import AudioPlayer from '../../globals/AudioPlayer';
import Utils from '../../../helpers/Utils';
import Helper from '../rightSide/Helpers/Helper';
import { IconList } from '../../../constants/IconList';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import PreviewImageFile from './imageLoadComponent/PreviewImageFile';
import { formBuilderAWSPreSignedUrl } from '../../../api/contactApi';
import axis from 'axis.js';
import { icons } from "../Icons";
import EmptyInbox from '../leftSideV2/contacts/contactBody/EmptyInbox';

const classForItem = {
    [CoreConstants.Timeline.CONTENT_TYPE_EMAIL]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_SMS]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_MMS]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_NOTE]: "bg-yellow-10",
    [CoreConstants.Timeline.CONTENT_TYPE_FORM_RESPONSE]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_CALL]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_LETTER]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_POST_CARD]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_GIFT]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_CALL_RECORD]: "bg-light-blue-10",
    [CoreConstants.Timeline.CONTENT_TYPE_VOICE]: "bg-light-blue-10"
}

let user_image = Utils.getAccountData('profileImage');
let user_name = Utils.getAccountData('fullName');
const userTimezone = Utils.getAccountData('userTimezoneRaw');

const getHtmlText = (message) => {
    if (message) {
        let regix = /\@.*?\)/g; // eslint-disable-line
        let matchStrign = message.match(regix);
        if (!axis.isNull(matchStrign) && axis.isArray(matchStrign)) {
            matchStrign.map((str) => { // eslint-disable-line array-callback-return
                let eachStr = str.match(/@\[(.*)\]\(.*\)/);
                if (eachStr != null)
                    message = message.replace(str, "<b>" + "@" + eachStr[1] + "</b>")
            });
        }
        return message.replace(/\n/g, "<br />");
    }
    return '';
}

const getErrorMessage = (contentType, error) => {
    if (contentType === CoreConstants.Timeline.CONTENT_TYPE_VOICE) {
        return (
            <div>
                <p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>
                    Reason for Failure!
                </p>
                <p style={{ textAlign: "center", margin: "2px" }}>

                    {error ? <><span style={{ fontWeight: "bold" }}>Reason :</span> {error.error_message}</> : "Error"}
                </p>
            </div>
        );
    }
    else if (contentType === CoreConstants.Timeline.CONTENT_TYPE_EMAIL || contentType === CoreConstants.Timeline.CONTENT_TYPE_CHAT ||
        contentType === CoreConstants.Timeline.CONTENT_TYPE_SMS || contentType === CoreConstants.Timeline.CONTENT_TYPE_MMS ||
        contentType === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL || contentType === CoreConstants.Timeline.CONTENT_TYPE_POST_CARD ||
        contentType === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD || contentType === CoreConstants.Timeline.CONTENT_TYPE_LETTER ||
        contentType === CoreConstants.Timeline.CONTENT_TYPE_GIFT) {
        return (
            <div>
                {error ?
                    <><p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>

                        <span> Message Failed!</span> Error Code: {error.error_code}
                    </p>
                        <p style={{ textAlign: "center", margin: "2px" }}>
                            <span style={{ fontWeight: "bold" }}>Reason:</span> {error.error_message}
                        </p></>

                    :
                    <><p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>

                        Error
                    </p></>
                }
            </div>
        );
    } else {
        return (
            <div>
                {error ?
                    <><p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>
                        <span style={{ fontWeight: "bold" }}>Reason :</span> {error.error_message}
                    </p>
                    </>
                    :
                    <p style={{ fontWeight: "bold", textAlign: "center", margin: "2px" }}>
                        Error
                    </p>
                }
            </div>
        );
    }
};

const ContactTimelineLog = ({ contact, contactTimelineList, contentTypeList, contactNumber, scheduleTimelineList, contactInfo,
    removeSchedule, reloadNeedForConversation, contactFullName, contactEmail
}) => {

    const getContentTypeIcon = (contentTypeIcon, message = "") => {
        if (contentTypeIcon === CoreConstants.inboxThread.CONTENT_TYPE_SMS) {
            return 'sms';
        } else if (contentTypeIcon === CoreConstants.inboxThread.CONTENT_TYPE_EMAIL) {
            return 'mail';
        } else if (contentTypeIcon === CoreConstants.inboxThread.CONTENT_TYPE_MMS) {
            return 'sms';
        } else if (contentTypeIcon === CoreConstants.inboxThread.CONTENT_TYPE_CALL) {
            if (message === "Missed Call") {
                return 'phone_missed';
            }
            return 'call';
        } else if (contentTypeIcon === CoreConstants.inboxThread.CONTENT_TYPE_VOICE_MAIL) {
            return 'voicemail';
        } else if (contentTypeIcon === CoreConstants.Timeline.CONTENT_TYPE_CALL_BRIGDE) {
            return 'call';
        }
    }

    const viewSMS = (timeline) => {

        if (contentTypeList[0] !== 'scheduled') {
            return (
                <div className="message_body p-3 text-gray">
                    {timeline.message_url && timeline.message_url.split(",").map((url, index) => (
                        url.trim() !== '' &&
                        <PreviewImageFile key={index} link={url.trim()} />
                    ))}
                    <div dangerouslySetInnerHTML={{ __html: getHtmlText(timeline.message) }} />
                </div>
            )
        } else {
            return (
                <div className="message_body p-3 text-gray">

                    {(timeline.broadcast.file_url !== undefined && timeline.broadcast.file_url != null && timeline.broadcast.file_url !== '') ?
                        // <img className="circle right-side" src={timeline.message_url} alt="" /> : ''
                        <img className="mms-image-in-details" src={timeline.broadcast.file_url} alt="" /> : ''
                    }
                    {timeline.broadcast.message}
                </div>
            )
        }

    }

    const viewCallVoice = (timeline, type, inOut) => {
        if (contentTypeList[0] !== 'scheduled') {
            return (
                <>
                    {inOut === CoreConstants.Timeline.INOUT_TYPE_OUT &&
                        <>
                            {type === CoreConstants.Timeline.CONTENT_TYPE_CALL && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>Outgoing Call</span>}
                            {type === CoreConstants.Timeline.CONTENT_TYPE_VOICE && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>Outgoing Voicemail</span>}
                            {type === CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>Outgoing Call</span>}
                        </>
                    }
                    {inOut === CoreConstants.Timeline.INOUT_TYPE_IN &&
                        <>
                            {(type === CoreConstants.Timeline.CONTENT_TYPE_CALL && timeline.message !== "Missed Call") && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>Incoming Call</span>}
                            {(type === CoreConstants.Timeline.CONTENT_TYPE_CALL && timeline.message === "Missed Call") && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>You have a Missed Call</span>}
                            {type === CoreConstants.Timeline.CONTENT_TYPE_VOICE && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>Incoming Voicemail</span>}
                            {type === CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL && <span style={{
                                width: '100%',
                                textAlign: 'right',
                                float: 'right',
                                paddingBottom: '6px',
                                paddingRight: '25px'
                            }}>Incoming Call</span>}
                        </>
                    }
                    <div className="message_body p-3 text-right">
                        {(timeline.message_url !== undefined && timeline.message_url !== '' && timeline.message_url !== null) ?
                            <AudioPlayer src={timeline.message_url} /> : ''
                        }
                    </div>
                </>
            )
        } else {
            return (
                <div className="message_body p-3 text-right">
                    {(timeline.broadcast.file_path !== undefined && timeline.broadcast.file_path !== '') ?
                        <AudioPlayer src={timeline.broadcast.file_path} /> : ''
                    }
                </div>
            )
        }
    }

    const processFileUrl = (messageUrl) => {
        let fileDataOutput = [];
        try {
            let fileData = JSON.parse(messageUrl)
            fileDataOutput.push(<label style={{ fontSize: "18px" }}>Attachments: </label>)
            if (Array.isArray(fileData)) {
                for (let i = 0; i < fileData.length; i++) {
                    if (fileData[i].file_url && fileData[i].file_name) {
                        fileDataOutput.push(<p>
                            <a href={fileData[i].file_url} className="cursor" target="_blank" rel="noreferrer">
                                {fileData[i].file_name}
                            </a>
                        </p>)
                    }

                }
                return fileDataOutput

            } else {
                return null;
            }

        } catch (error) {
            console.log(error)
            return null;
        }

    }

    const viewEmail = (timeline) => {

        if (contentTypeList[0] !== 'scheduled') {

            let styleData = '<style type="text/css"';
            let messageBody = (timeline.message !== undefined && timeline.message != null) ?
                timeline.message : '';

            let iof = messageBody.indexOf(styleData);

            if (iof > 0) {

                do {
                    let anotherof = messageBody.indexOf('</style>', iof);
                    if (anotherof > 0) {
                        messageBody = messageBody.slice(0, iof) + ' ' + messageBody.slice(anotherof)

                    }
                    iof = messageBody.indexOf(styleData);


                } while (iof > 0)
            }

            let nextStyleSheet = "<style";
            iof = messageBody.indexOf(nextStyleSheet);
            if (iof > 0) {

                do {
                    let anotherof = messageBody.indexOf('</style>', iof);
                    if (anotherof > 0) {
                        let index = messageBody.indexOf('div', iof);
                        if (index > 0) {
                            messageBody = messageBody.slice(0, index) + '.contact_timeline_email_body div' + messageBody.slice(index + 3)
                        }
                        index = messageBody.indexOf('html', iof);
                        if (index > 0) {
                            messageBody = messageBody.slice(0, index) + '.contact_timeline_email_body html' + messageBody.slice(index + 3)
                        }
                        // messageBody = messageBody.replaceAll('html','');

                    }
                    iof = messageBody.indexOf(styleData);


                } while (iof > 0)
            }

            let messageUrl = ''
            try {
                messageUrl = !Helper.isEmpty(timeline.message_url) ? timeline.message_url : ''
                // messageUrl = JSON.parse(timeline.message_url)
                if (messageUrl.includes('[') && messageUrl.includes(']')) {
                    messageUrl = messageUrl.replace('[', '');
                    messageUrl = messageUrl.replace(']', '');
                    messageUrl = messageUrl.split(',');
                }

            } catch (e) {

            }

            return (

                <div class="message_body p-3">
                    <p class="text-gray"><b>Subject : </b>{timeline.message_subject !== undefined ? timeline.message_subject : ''}</p>


                    <div class="text-gray contact_timeline_email_body" dangerouslySetInnerHTML={{ __html: messageBody }} />
                    {!Helper.isEmpty(messageUrl) && Array.isArray(messageUrl) && messageUrl.length > 0 && messageUrl[0].trim() !== '' &&
                        <div className="email-attachments-contactmodule">
                            {/* <p className="no-margin-p">Attachments : </p> */}
                            {
                                processFileUrl(timeline.message_url)
                            }
                            {/* <ul>
                                {messageUrl.map((item, index)=>{
                                    return <li><a href={item.trim()} rel="noreferrer" className="text-decoration-none" target='_blank'>{`attachment(${index+1})`}</a></li>
                                    })
                                }
                            </ul> */}
                        </div>
                    }
                </div>
            )
        } else {
            let messageBody = (timeline.broadcast.message !== undefined && timeline.broadcast.message != null) ?
                timeline.broadcast.message : '';


            return (

                <div class="message_body p-3">
                    <p class="text-gray"><b>Subject : </b>{timeline.broadcast.subject !== undefined ? timeline.broadcast.subject : ''}</p>


                    <div class="text-gray" dangerouslySetInnerHTML={{ __html: messageBody }} />

                </div>
            )
        }
    }

    const viewVideoEmail = (timeline) => {

        if (contentTypeList[0] !== 'scheduled') {

            let messageBody = (timeline.message !== undefined && timeline.message != null) ?
                timeline.message : '';


            return (

                <div class="message_body p-3 text-right">
                    <p class="text-gray"><b>Subject : </b>{timeline.message_subject !== undefined ? timeline.message_subject : ''}</p>


                    <div class="text-gray" dangerouslySetInnerHTML={{ __html: messageBody }} />
                    {(timeline.video_link !== undefined && timeline.video_link != null && timeline.video_link !== '') ?
                        <video width="225" height="180" controls>
                            <source src={timeline.video_link.video_link} type='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                        :
                        (timeline.email_video !== undefined &&
                            timeline.email_video != null &&
                            timeline.email_video.video_link !== undefined &&
                            timeline.email_video.video_link != null &&
                            timeline.email_video.video_link !== ''
                        ) ?
                            <video width="225" height="180" controls>
                                <source src={timeline.email_video.video_link} type='video/mp4' />
                                Your browser does not support the video tag.
                            </video>
                            :
                            ''
                    }
                </div>
            )
        } else {
            let messageBody = (timeline.broadcast.message !== undefined && timeline.broadcast.message != null) ?
                timeline.broadcast.message : '';


            return (

                <div class="message_body p-3 text-right">
                    <p class="text-gray"><b>Subject : </b>{timeline.broadcast.subject !== undefined ? timeline.broadcast.subject : ''}</p>


                    <div class="text-gray" dangerouslySetInnerHTML={{ __html: messageBody }} />

                    {(timeline.video_link !== undefined && timeline.video_link != null && timeline.video_link !== '') &&
                        <video width="225" height="180" controls>
                            <source src={timeline.video_link} type='video/mp4' />
                            Your browser does not support the video tag.
                        </video>
                    }
                    {/* {timeline.message != undefined ? timeline.message : ''} */}
                    {/* </div> */}
                    {/* <button class="show_more_btn waves-effect btn bg-light-blue">Show
                        more</button> */}

                    {/* <button className="show_more_btn waves-effect btn bg-yellow">Show more</button> */}

                </div>
            )
        }
    }

    const viewCardPDF = (timeline) => {
        if (contentTypeList[0] !== 'scheduled') {
            let cardPdf = (timeline.message_url !== undefined && timeline.message_url != null && timeline.message_url !== '') ?
                timeline.message_url : (timeline.file_url !== undefined && timeline.file_url != null) ? timeline.file_url : ''

            let front_image = (timeline.template_thumbnail_path !== undefined && timeline.template_thumbnail_path != null && timeline.template_thumbnail_path !== '') ?
                timeline.template_thumbnail_path : '';


            if (cardPdf === '') {
                return (
                    <div class="message_body p-3"></div>
                );
            }
            else {
                if (cardPdf.split('.').pop() === "pdf" || cardPdf.split('.').pop() === "PDF") {
                    return (
                        <div class="message_body p-3">
                            <iframe src={cardPdf} frameborder="0"
                                title="Postcard" loading={'eager'} name={'Postcard'}
                                allowFullScreen
                            ></iframe>
                        </div>
                    )
                }
                else {
                    return (
                        <div class="message_body p-3">
                            <div>
                                {front_image !== "" &&
                                    <img
                                        alt={front_image}
                                        src={front_image}
                                        height={200}
                                        width={200}
                                        style={{ marginRight: '5px' }}
                                    />
                                }
                                <img
                                    alt={cardPdf}
                                    src={cardPdf}
                                    height={200}
                                    width={200}
                                    style={{ background: timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ? '#fff' : 'none' }}
                                />
                            </div>
                            <span>
                                <small>Message: </small>
                                {timeline.message}
                            </span>
                        </div>
                    )
                }
            }

        } else {
            let cardPdf = (timeline.broadcast.file_path !== undefined && timeline.broadcast.file_path != null && timeline.broadcast.file_path !== "") ?
                timeline.broadcast.file_path : (timeline.broadcast.file_url !== undefined && timeline.broadcast.file_url != null && timeline.broadcast.file_url !== "" ? timeline.broadcast.file_url : "")

            let front_image_schedule = (timeline.broadcast.gif_path !== undefined && timeline.broadcast.gif_path != null && timeline.broadcast.gif_path !== "") ?
                timeline.broadcast.gif_path : ""

            if (cardPdf === '') {
                return (
                    <div class="message_body p-3"></div>
                );
            }
            if (cardPdf.split('.').pop() === "pdf" || cardPdf.split('.').pop() === "PDF") {
                return (
                    <div class="message_body p-3">
                        <iframe src={cardPdf} frameborder="0"
                            title="Card" loading={'eager'} name={'Card'}
                            allowFullScreen
                        ></iframe>
                    </div>
                )
            }
            else {
                return (
                    <div class="message_body p-3">
                        <div>
                            {front_image_schedule !== "" &&
                                <img
                                    alt={front_image_schedule}
                                    src={front_image_schedule}
                                    height={200}
                                    width={200}
                                    style={{ marginRight: '5px' }}
                                />
                            }
                            <img
                                alt={cardPdf}
                                src={cardPdf}
                                height={200}
                                width={200}
                                style={{ background: timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ? '#fff' : 'none' }}
                            />
                        </div>
                        <span>
                            <small>Message: </small>
                            {timeline.broadcast.message}
                        </span>
                    </div>
                )
            }
        }
    }

    const viewCallBridge = (timeline) => {
        return (
            <div className="message_body p-3 text-gray">
                CallBridge
                {(!Helper.isEmpty(timeline.message_url)) &&
                    <AudioPlayer src={timeline.message_url} />
                }

            </div>
        )


    }

    const showToNumberOrEmnail = (data) => {
        try {
            const modifyData = data.replace(/\\/g, '');
            const obj = JSON.parse(modifyData);
            if (obj.to !== undefined) {
                return <p style={{
                    margin: 0,
                    paddingLeft: '15px',
                    fontSize: '14px',
                    fontWeight: 500
                }}>Sent to: {obj.to}</p>
            }
        } catch (error) {
            console.log("can not parse data", data)
        }
        return "";
    }

    const renderStatus = (status, content_type, error_message) => {
        /*
            0 = submitted,
            1, 3, 5 = success
            2 = failed
        */
        // case for pending 0,2 and error_message null, undefined
        if ((status === 0 || status === 2) && !error_message) {
            return <p className='virtual_number_message_middleside' style={{ display: 'flex' }}><CheckCircleIcon fontSize='small' style={{ color: '#28a745' }} /> Submitted</p>
        }
        // case for failed 2,0,4 and error_message
        else if ((status === 2 || status === 0 || status === 4) && error_message) {
            return <BootstrapTooltip arrow placement={"top"} title={getErrorMessage(parseInt(content_type), error_message)}><p className='virtual_number_message_middleside' style={{ display: 'flex', gap: '4px', alignItems: 'baseline' }}>{IconList.warningBoard} Failed</p></BootstrapTooltip>
        }
        // case for undelivered 4
        else if ((status === 4)) {
            return <p className='virtual_number_message_middleside' style={{ display: 'flex', gap: '4px', alignItems: 'baseline' }}>
                {IconList.warningBoard} Failed</p>
        }
        // case for success 1,2,5
        else {
            return <p className='virtual_number_message_middleside' style={{ display: 'flex' }}><CheckCircleIcon fontSize='small' style={{ color: '#28a745' }} /> Sent</p>
        }
    }

    const renderMessageForPdf = (timeline) => {
        if (timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_LETTER ||
            timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ||
            timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GIFT ||
            timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_POST_CARD) {
            if (timeline.campaign_send_response !== undefined && timeline.campaign_send_response !== null) {
                if (timeline.campaign_send_response.message !== undefined && timeline.campaign_send_response.message != null && timeline.campaign_send_response.message !== "") {
                    if (timeline.campaign_send_response.message.includes("\\") || timeline.campaign_send_response.message.includes(":") || timeline.campaign_send_response.message.includes("{")) {
                        return null
                    }
                    return (<span style={{ marginLeft: '10px' }}>{timeline.campaign_send_response.message}</span>);
                }
            }
        }
        return null
    }

    /* for form response */
    const showFormResponse = message => {
        let messageValues = JSON.parse(message);
        let formValues = '';
        for (const [key, value] of Object.entries(messageValues)) {
            if (typeof value !== 'object' &&
                !Array.isArray(value) &&
                value !== null) {
                formValues += `${key}: ${value}<br />`;
            }
        }
        return formValues;
    }
    const renderFormResponse = (timeline) => {
        try {
            return (
                <div class="message_body p-3">
                    <p class="text-gray">A form submission from <a target='_blank' href={'https://' + timeline.message_url} rel="noreferrer">{timeline.message_subject}</a></p>
                    <div class="text-gray contact_timeline_email_body" dangerouslySetInnerHTML={{ __html: showFormResponse(timeline.message) }} />
                </div>
            )
        }
        catch (error) { return null }
    }
    const submitPreSignedUrl = (payload) => {
        formBuilderAWSPreSignedUrl(payload)
            .then((res) => {
                if (res.status == 200 && res.data) {
                    let data = res.data;
                    let bucketUrl = JSON.parse(data.bucketUrl);
                    if (bucketUrl[0]) {
                        window.open(bucketUrl[0].bucketUrl, '_blank');
                    }
                }
            })
    }
    const smartFormResponseHtml = (response) => {
        let formResponse = JSON.parse(response);
        let messageBody = [];

        try {
            if (formResponse.submission) {
                formResponse.submission.forEach((item, index) => {
                    const innerMessageBody = [];
                    if (item.value && (item.type != CoreConstants.FORM_BUILDER_V3_COMPONENT_TYPE.HIDDEN_FIELD)) {
                        innerMessageBody.push(<span dangerouslySetInnerHTML={{ __html: Utils.urlify(item.value) }} />);
                    } else if (item.firstName) {
                        if (item.middleName) {
                            innerMessageBody.push(<span>{item.firstName + ' ' + item.middleName + ' ' + item.lastName}</span>);
                        } else {
                            innerMessageBody.push(<span>{item.firstName + ' ' + item.lastName}</span>);
                        }
                    } else if (item.city || item.zip || item.state) {
                        if (item.streetAddress1) {
                            innerMessageBody.push(<span>{item.streetAddress1}</span>);
                            if (item.streetAddress2 || item.city || item.state || item.zip) {
                                innerMessageBody.push(<span>, </span>);
                            }
                        }
                        if (item.streetAddress2) {
                            innerMessageBody.push(<span>{item.streetAddress2}</span>);
                            if (item.city || item.state || item.zip) {
                                innerMessageBody.push(<span>, </span>);
                            }
                        }
                        if (item.city) {
                            innerMessageBody.push(<span>{item.city}</span>);
                            if (item.state || item.zip) {
                                innerMessageBody.push(<span>, </span>);
                            }
                        }
                        if (item.state) {
                            innerMessageBody.push(<span>{item.state}</span>);
                            if (item.zip) {
                                innerMessageBody.push(<span>, </span>);
                            }
                        }
                        if (item.zip) {
                            innerMessageBody.push(<span>{item.zip}</span>);
                        }
                    } else if (item.files) {
                        const fileMessageBody = [];
                        const files = item.files;
                        if (files && files.length > 0) {
                            files.forEach((file, index) => {
                                let payload = {
                                    fileId: [file.id],
                                    action: 0, // 0 for form file view from s3 pre-signed
                                    formApiKey: formResponse.apiKey,
                                    agencyId: formResponse.agencyId
                                };
                                fileMessageBody.push(<div>
                                    <span onClick={() => { submitPreSignedUrl(payload) }} style={{ color: "#6631e7", cursor: "pointer" }}>
                                        {file.name}
                                    </span>
                                </div>)
                            });
                        }
                        innerMessageBody.push(<span>{fileMessageBody}</span>);
                    }

                    messageBody.push(
                        <div>
                            {
                                (item.type != CoreConstants.FORM_BUILDER_V3_COMPONENT_TYPE.HIDDEN_FIELD) && (<div style={{ fontWeight: 700 }}>{item.label}</div>)
                            }
                            <div>{innerMessageBody}</div>
                            {formResponse.submission.length > (index + 1) && <hr />}
                        </div>);
                })
            }
        } catch (e) {
            messageBody = [];
        }

        return messageBody;
    }
    const renderSmartFormResponse = (timeline) => {
        try {
            return (
                <div className="message_body p-3">
                    {smartFormResponseHtml(timeline.message)}
                </div>
            );
        }
        catch (error) { return null }
    }

    const getContactEmail = () => {
        if (contact.email !== undefined && contact.email !== null) {
            return contact.email;
        }
        return "";
    };

    const sendEmailToThread = (threadData) => {
        if (window.sendCommunication !== undefined) {
            window.sendCommunication({
                open: true,
                onClose: () => { },
                id: contact.id,
                from: "contact-details",
                primaryEmail: getContactEmail(),
                fetchContactDetails: false,
                threadData: threadData,
                sendSuccessCallBack: () => {
                    reloadNeedForConversation(true);
                }
            }, "email");
        }
    };

    const viewContactTimelineLog = () => {

        let dataAvailable = false;

        if (contactTimelineList[0]) {
            return (
                contactTimelineList.map((timeline, index) => {
                    let contentTypeForIcon = getContentTypeIcon(timeline.content_type, timeline.message);
                    if (contentTypeList.length === 1 && contentTypeList[0] == 5) {
                        dataAvailable = true;
                        if (timeline.in_out == CoreConstants.Timeline.INOUT_TYPE_IN) {
                            return (
                                <div className="contact-middleside-conversation-area">
                                    <div className="single_message_box right" key={`timeline=${timeline.id}`}>
                                        <div className="single_message_box__right__inner__v1">
                                            <span className="single_message_box__right__user__name">{Helper.getIconTextFromContact(contactInfo)}</span>
                                            <div className={`single_message p-2 incoming-message-color`}>
                                                <div className="message_header row px-2">
                                                    <div className="d-flex align-items-center col s12 text-right">
                                                        <div>
                                                            <h5 className="d-flex align-items-center m-0 mr-2">
                                                                {contactFullName !== '' ? contactFullName : contactEmail !== '' ? contactEmail : '+' + contactNumber}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                {viewCallVoice(timeline, timeline.content_type, timeline.in_out)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="activation-time">
                                        <span className="right">
                                            {TimezoneConversation(timeline.created_at, 'UTC', userTimezone, 'lll')}
                                            <i class="material-icons bg-icons" style={{ marginLeft: 10 }}>
                                                {contentTypeForIcon}
                                            </i>
                                            {(timeline.virtual_number !== undefined && timeline.virtual_number != null
                                                && timeline.virtual_number.virtual_number !== undefined && timeline.virtual_number.virtual_number != null) &&
                                                timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_EMAIL && timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&
                                                <p className='virtual_number_message_middleside'>Sent to: {timeline.virtual_number.virtual_number}</p>
                                            }
                                            {(timeline.from !== undefined && timeline.from != null) &&
                                                (timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL) &&
                                                <p className='virtual_number_message_middleside'>Sent from: {timeline.from}</p>
                                            }
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return null;
                        }
                    }
                    else if (contentTypeList.length === 1 && contentTypeList[0] == 7) {
                        dataAvailable = true;
                        if (timeline.in_out === CoreConstants.Timeline.INOUT_TYPE_OUT) {
                            return (
                                <div className="contact-middleside-conversation-area">
                                    <div className="single_message_box left" key={`timeline=${timeline.id}`}>
                                        <div className="single_message_box__right__inner__v1">
                                            <div className={`single_message p-2 bg-light-blue-10`}>
                                                <div className="message_header row px-2">
                                                    <div className="d-flex  align-items-center col w-100 justify-content-end">
                                                        <div className="message-user-info pl-2">
                                                            <h5 className="d-flex align-items-center m-0 mr-2">
                                                                {timeline.user.full_name}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                {showToNumberOrEmnail(timeline.user_info)}
                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CALL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VOICE || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL) &&
                                                    viewCallVoice(timeline, timeline.content_type, timeline.in_out)
                                                }
                                            </div>
                                            {user_image === undefined || user_image == null || user_image === '' ?
                                                <span className="single_message_box__right__user__name">{Utils.getInitial(user_name)}</span>
                                                :
                                                <img class="circle left-side" src={user_image} alt="" />
                                            }
                                        </div>
                                    </div>
                                    <div className="activation-time">
                                        <span className="left__box">
                                            <i class="material-icons bg-icons">
                                                {contentTypeForIcon}
                                            </i>
                                            {TimezoneConversation(timeline.created_at, 'UTC', userTimezone, 'lll')}
                                            {(timeline.virtual_number !== undefined && timeline.virtual_number != null && timeline.virtual_number !== undefined && timeline.virtual_number.virtual_number != null) &&
                                                timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_EMAIL && timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&
                                                <p className='virtual_number_message_middleside'>Sent from: {timeline.virtual_number.virtual_number}</p>
                                            }
                                            {(timeline.from !== undefined && timeline.from != null) &&
                                                (timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL) &&
                                                <p className='virtual_number_message_middleside'>Sent from: {timeline.from}</p>
                                            }
                                            {renderStatus(timeline.status, timeline.content_type, timeline.error_message ?? null)}
                                            {renderMessageForPdf(timeline)}
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return null;
                        }
                    }
                    else if (contentTypeList.includes(timeline.content_type)) {
                        dataAvailable = true;
                        if (timeline.in_out === CoreConstants.Timeline.INOUT_TYPE_OUT) {
                            return (

                                <div className="contact-middleside-conversation-area">
                                    <div className="single_message_box left" key={`timeline=${timeline.id}`}>

                                        <div className="single_message_box__right__inner__v1">

                                            <div className={`single_message p-2 bg-light-blue-10`}>
                                                <div className="message_header row px-2">
                                                    <div className="d-flex  align-items-center col w-100 justify-content-end">

                                                        <div className="message-user-info pl-2">

                                                            <h5 className="d-flex align-items-center m-0 mr-2">

                                                                {timeline.user.full_name}
                                                            </h5>
                                                        </div>

                                                    </div>

                                                </div>
                                                {showToNumberOrEmnail(timeline.user_info)}

                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_SMS || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_MMS || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CHAT) &&
                                                    viewSMS(timeline)
                                                }
                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CALL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VOICE || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_OUTGOING_CALL) &&

                                                    viewCallVoice(timeline, timeline.content_type, timeline.in_out)
                                                }
                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL &&

                                                    viewEmail(timeline)
                                                }

                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&

                                                    viewVideoEmail(timeline)
                                                }

                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_LETTER ||
                                                    timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ||
                                                    timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GIFT ||
                                                    timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_POST_CARD) &&

                                                    viewCardPDF(timeline)
                                                }

                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CALL_BRIGDE &&

                                                    viewCallBridge(timeline)
                                                }

                                            </div>

                                            {user_image === undefined || user_image == null || user_image === '' ?
                                                <span className="single_message_box__right__user__name">{Utils.getInitial(user_name)}</span>

                                                :
                                                <img class="circle left-side" src={user_image} alt="" />

                                            }
                                        </div>
                                    </div>
                                    <div className="activation-time">

                                        <span className="left__box">
                                            <i class="material-icons bg-icons">
                                                {contentTypeForIcon}
                                            </i>
                                            {TimezoneConversation(timeline.created_at, 'UTC', userTimezone, 'lll')}
                                            {(timeline.virtual_number !== undefined && timeline.virtual_number != null && timeline.virtual_number !== undefined && timeline.virtual_number.virtual_number != null) &&
                                                timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_EMAIL && timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&
                                                <p className='virtual_number_message_middleside'>Sent from: {timeline.virtual_number.virtual_number}</p>
                                            }
                                            {(timeline.from !== undefined && timeline.from != null) &&
                                                (timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL) &&
                                                <p className='virtual_number_message_middleside'>Sent from: {timeline.from}</p>
                                            }
                                            {renderStatus(timeline.status, timeline.content_type, timeline.error_message ?? null)}
                                            {renderMessageForPdf(timeline)}
                                        </span>

                                    </div>
                                </div>
                            )
                        }
                        else if (timeline.in_out == CoreConstants.Timeline.INOUT_TYPE_IN) {


                            return (
                                <div className="contact-middleside-conversation-area">

                                    <div className="single_message_box right" key={`timeline=${timeline.id}`}>

                                        <div className="single_message_box__right__inner__v1">
                                            {/* {user_image === '' ?

                                                <img class="circle left-side" src={process.env.REACT_APP_BASE_URL + 'upload/user/' + Utils.getAccountData('userId') + '/' + user_image} alt="" />
                                                : */}
                                            <span className="single_message_box__right__user__name">{Helper.getIconTextFromContact(contactInfo)}</span>
                                            {/* } */}
                                            {/* : */}
                                            {/* // <img class="circle right-side" src={process.env.REACT_APP_BASE_URL+'upload/user/'+Utils.getAccountData('userId')+'/'+user_image} alt=""/>
                                        // } */}
                                            <div className={`single_message p-2 incoming-message-color`}>
                                                {timeline.content_type != CoreConstants.Timeline.CONTENT_TYPE_FORM_RESPONSE && timeline.content_type != CoreConstants.Timeline.CONTENT_TYPE_SMART_FORM_RESPONSE &&
                                                    <div className="message_header row px-2">
                                                        <div className="d-flex align-items-center col s12 text-right">
                                                            <div>
                                                                <h5 className="d-flex align-items-center m-0 mr-2">
                                                                    {contactFullName !== '' ? contactFullName : contactEmail !== '' ? contactEmail : '+' + contactNumber}
                                                                    {/* <i className="material-icons">
                                                                    navigate_next
                                                                </i>
                                                                {timeline.user.full_name} */}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    timeline?.nylas_thread_id &&
                                                    <div className="chat__reply" title="Reply" onClick={() => { sendEmailToThread({ message_subject: timeline?.message_subject, from: timeline?.from }) }}>
                                                        {icons.reply}
                                                        Reply
                                                    </div>
                                                }
                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_SMS || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_MMS || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CHAT) &&

                                                    viewSMS(timeline)

                                                }
                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CALL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VOICE) &&

                                                    viewCallVoice(timeline, timeline.content_type, timeline.in_out)

                                                }

                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL &&

                                                    viewEmail(timeline)
                                                }

                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&

                                                    viewVideoEmail(timeline)
                                                }
                                                {(timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_LETTER ||
                                                    timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ||
                                                    timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_GIFT ||
                                                    timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_POST_CARD) &&

                                                    viewCardPDF(timeline)
                                                }
                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_CALL_BRIGDE &&

                                                    viewCallBridge(timeline)
                                                }

                                                {/* for form response */}
                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_FORM_RESPONSE &&
                                                    renderFormResponse(timeline)
                                                }
                                                {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_SMART_FORM_RESPONSE &&
                                                    renderSmartFormResponse(timeline)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="activation-time">

                                        <span className="right">

                                            {TimezoneConversation(timeline.created_at, 'UTC', userTimezone, 'lll')}
                                            <i class="material-icons bg-icons" style={{ marginLeft: 10 }}>
                                                {contentTypeForIcon}
                                            </i>
                                            {(timeline.virtual_number !== undefined && timeline.virtual_number != null
                                                && timeline.virtual_number.virtual_number !== undefined && timeline.virtual_number.virtual_number != null) &&
                                                timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_EMAIL && timeline.content_type !== CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&

                                                <p className='virtual_number_message_middleside'>Sent to: {timeline.virtual_number.virtual_number}</p>
                                            }
                                            {(timeline.from !== undefined && timeline.from != null) &&
                                                (timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL || timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL) &&

                                                <p className='virtual_number_message_middleside'>Sent from: {timeline.from}</p>
                                            }
                                        </span>
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return null;
                        }
                    } else if ((index === contactTimelineList.length - 1) && !dataAvailable) {
                        return (
                            <EmptyInbox />
                        )
                    }
                })
            )
        } else {
            return (
                <EmptyInbox />
            )
        }

    }

    const viewContactScheduleTimelineLog = () => {

        let dataAvailable = false;

        if (scheduleTimelineList.length > 0) {
            return scheduleTimelineList.map((timeline, index) => {

                let contentTypeForIcon = getContentTypeIcon(timeline.content_type);

                if (timeline !== undefined && timeline != null && timeline.status === 0 && timeline.broadcast !== undefined && timeline.broadcast != null) {
                    dataAvailable = true
                    return (
                        <div className="contact-middleside-conversation-area">

                            <div className="single_message_box right" key={`schedule${timeline.id}${index}`}>
                                {/* <img className="circle right-side" src="./images/single-contact/current_user.png" alt="" /> */}
                                <img className="circle right-side" src="https://stafforgserv.com.au/wp-content/uploads/2018/09/user-img.png" alt="" />

                                <div className={classForItem[timeline.broadcast.message_type] !== undefined ? `single_message p-2 ${classForItem[timeline.broadcast.message_type]}` : "single_message p-2 bg-yellow-10"}>
                                    <div className="message_header row px-2">
                                        <div className="d-flex align-items-center col s12 text-right middle-side-schedule-list-items">
                                            <div className="filter-icon-contact-list filter-icon-contact-list___alt schedule-items-single-contact-header cursor-pointer"
                                                onClick={() => removeSchedule(timeline.id, index)}>

                                                <svg
                                                    className="mr-2"
                                                    width="16"
                                                    height="20"
                                                    viewBox="0 0 16 20"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M14.2356 2.42308H10.9038V1.81731C10.9038 0.813624 10.0902 0 9.08654 0H6.66346C5.65978 0 4.84615 0.813624 4.84615 1.81731V2.42308H1.51442C0.678045 2.42308 0 3.10112 0 3.9375V5.14904C0 5.48361 0.271195 5.75481 0.605769 5.75481H15.1442C15.4788 5.75481 15.75 5.48361 15.75 5.14904V3.9375C15.75 3.10112 15.072 2.42308 14.2356 2.42308ZM6.05769 1.81731C6.05769 1.48338 6.32953 1.21154 6.66346 1.21154H9.08654C9.42047 1.21154 9.69231 1.48338 9.69231 1.81731V2.42308H6.05769V1.81731Z"
                                                        fill="#FF5858"
                                                    />
                                                    <path
                                                        d="M1.15023 6.96637C1.04214 6.96637 0.956008 7.05671 0.961157 7.16468L1.46092 17.6537C1.50711 18.6244 2.30445 19.3846 3.27595 19.3846H12.4753C13.4468 19.3846 14.2442 18.6244 14.2904 17.6537L14.7901 7.16468C14.7953 7.05671 14.7091 6.96637 14.601 6.96637H1.15023ZM10.2987 8.48079C10.2987 8.14611 10.5698 7.87502 10.9045 7.87502C11.2392 7.87502 11.5103 8.14611 11.5103 8.48079V16.3558C11.5103 16.6905 11.2392 16.9616 10.9045 16.9616C10.5698 16.9616 10.2987 16.6905 10.2987 16.3558V8.48079ZM7.26987 8.48079C7.26987 8.14611 7.54095 7.87502 7.87563 7.87502C8.21032 7.87502 8.4814 8.14611 8.4814 8.48079V16.3558C8.4814 16.6905 8.21032 16.9616 7.87563 16.9616C7.54095 16.9616 7.26987 16.6905 7.26987 16.3558V8.48079ZM4.24102 8.48079C4.24102 8.14611 4.5121 7.87502 4.84679 7.87502C5.18148 7.87502 5.45256 8.14611 5.45256 8.48079V16.3558C5.45256 16.6905 5.18148 16.9616 4.84679 16.9616C4.5121 16.9616 4.24102 16.6905 4.24102 16.3558V8.48079Z"
                                                        fill="#FF5858"
                                                    />
                                                </svg>
                                                {/* Delete Contact */}
                                            </div>
                                            =

                                            <span className="3333333333333333 single_message_type_icon note bg-yellow-10 circle d-flex justify-content-center align-items-center">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.0965 3.17859H13.3555V13.3571C13.3555 13.6413 13.4684 13.9138 13.6693 14.1148C13.8702 14.3157 14.1427 14.4286 14.4269 14.4286C14.7111 14.4286 14.9836 14.3157 15.1845 14.1148C15.3854 13.9138 15.4983 13.6413 15.4983 13.3571V3.58037C15.4983 3.47381 15.456 3.37162 15.3806 3.29627C15.3053 3.22092 15.2031 3.17859 15.0965 3.17859Z" fill="#F2C94C"></path>
                                                    <path d="M13.8644 15.4246C13.4112 15.3003 13.0114 15.0307 12.7262 14.6573C12.441 14.2838 12.2863 13.827 12.2857 13.3571V0.901785C12.2857 0.795225 12.2434 0.693029 12.168 0.61768C12.0927 0.542331 11.9905 0.5 11.8839 0.5H0.901786C0.795226 0.5 0.69303 0.542331 0.61768 0.61768C0.542331 0.693029 0.5 0.795225 0.5 0.901785V13.625C0.5 14.1223 0.697544 14.5992 1.04917 14.9508C1.40081 15.3024 1.87772 15.5 2.375 15.5H13.8544C13.8638 15.5003 13.8731 15.4971 13.8804 15.491C13.8877 15.4849 13.8924 15.4763 13.8937 15.4669C13.8949 15.4575 13.8926 15.4479 13.8872 15.4401C13.8818 15.4323 13.8737 15.4268 13.8644 15.4246ZM2.64286 6.39285V3.17857H5.85714V6.39285H2.64286ZM10.1429 12.8214H2.64286V11.75H10.1429V12.8214ZM10.1429 10.6786H2.64286V9.60713H10.1429V10.6786ZM10.1429 8.5357H2.64286V7.46427H10.1429V8.5357ZM10.1429 6.39285H6.92857V5.32142H10.1429V6.39285ZM10.1429 4.24999H6.92857V3.17857H10.1429V4.24999Z" fill="#F2C94C"></path>
                                                </svg>

                                            </span>

                                        </div>
                                    </div>
                                    {(timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_SMS || timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_MMS) &&

                                        viewSMS(timeline)

                                    }
                                    {(timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_VOICE) &&

                                        viewCallVoice(timeline, timeline.content_type, 2)

                                    }

                                    {timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_EMAIL &&

                                        viewEmail(timeline)
                                    }

                                    {timeline.content_type === CoreConstants.Timeline.CONTENT_TYPE_VIDEO_EMAIL &&

                                        viewVideoEmail(timeline)
                                    }
                                    {(timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_LETTER ||
                                        timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_GREETINGS_CARD ||
                                        timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_GIFT ||
                                        timeline.broadcast.message_type === CoreConstants.Timeline.CONTENT_TYPE_POST_CARD) &&

                                        viewCardPDF(timeline)
                                    }
                                </div>
                            </div>
                            {/* <p className="col pr-0 message--time--left"></p> */}
                            <div className="activation-time">

                                <span className="right">

                                    Scheduled at {TimezoneConversation(timeline.schedule_time, 'UTC', userTimezone, 'lll')}
                                    <i class="material-icons bg-icons" style={{ marginLeft: 10 }}>
                                        {contentTypeForIcon}
                                    </i>
                                </span>
                            </div>
                        </div>
                    )
                }
                else if ((index === scheduleTimelineList.length - 1) && !dataAvailable) {
                    return (
                        <EmptyInbox />
                    )
                }


            })
        } else {
            return (
                <EmptyInbox />
            )
        }

    }

    return (
        <React.Fragment>
            {contentTypeList[0] !== undefined && contentTypeList[0] !== 'scheduled' &&
                viewContactTimelineLog()
            }
            {contentTypeList[0] !== undefined && contentTypeList[0] === 'scheduled' &&
                viewContactScheduleTimelineLog()
            }
        </React.Fragment>
    )


}

export default ContactTimelineLog;
