import React from "react";
import { Box, Typography } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";
import { ContactsStyles } from "../ContactsStyles";
import { connect } from "react-redux";

const ContactNavigation = ({ assignedContactListInfo, goToContactDetails }) => {
  let content = null;

  const { flexCenterBetween } = LeftSideV2Styles();
  const { contactNavIcon, contactNavigationTitle, contactNavigationText } = ContactsStyles();

  if (!assignedContactListInfo) {
    content = (
      <Typography variant='body1' className='ml-2 contact_details_top__for__pagination_info'>
        Contact Information
      </Typography>
    );
  } else {
    content = (
      <Box sx={{ ml: 1, flex: 1 }} className={flexCenterBetween}>
        <Typography variant='body1' className={contactNavigationTitle}>
          Contacts
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: 4, color: "var(--gray)" }}>
          {assignedContactListInfo.currentIndex !== 0 && (
            <span
              className={contactNavIcon}
              onClick={() => {
                goToContactDetails("previous");
              }}
            >
              <NavigateBeforeIcon />
            </span>
          )}

          <Typography variant='body1' className={contactNavigationText}>
            {assignedContactListInfo.currentIndex + 1} of {assignedContactListInfo.totalContacts} contacts
          </Typography>

          {assignedContactListInfo.currentIndex + 1 < assignedContactListInfo.totalContacts && (
            <span
              className={contactNavIcon}
              onClick={() => {
                goToContactDetails("next");
              }}
            >
              <NavigateNextIcon />
            </span>
          )}
        </Box>
      </Box>
    );
  }

  return content;
};

const mapStateToProps = (state) => {
  return {
    assignedContactListInfo: state.contactReducer.assignedContactListInfo,
  };
};
export default connect(mapStateToProps)(ContactNavigation);
