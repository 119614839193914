import React, { useMemo, useState } from "react";
import { Paper, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import Utils from "../../../helpers/Utils";
import {
  LOOKUP_TYPE_BY_PAYLOAD_CONST,
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
  LOOKUP_TYPE_EMAIL_LOOKUP,
} from "../../../constants/CoreConstants";
import Styles from "./style.module.css";
import numberToDollar from "../../../helpers/numberToDollar";

const convertDateToString = (date) => {
  const rawTimezone = Utils.getAccountData("userTimezoneRaw");
  return window.globalTimezoneConversionToDifferentTimezone(date, "UTC", rawTimezone, "MM/DD/YYYY hh:mm a");
};

const getDescriptionString = (type, date) => {
  return `You previously validated this ${type} on ${convertDateToString(date)}\nWould you still like to proceed?`;
};

const displayDate = (title, date) => {
  return (
    <TableRow>
      <TableCell align='right'>{title}</TableCell>
      <TableCell>{date}</TableCell>
    </TableRow>
  );
};

const PricingAlertExistingContent = ({
  checkPayload = {},
  responseData = {},
  prices = {},
  onSubmit = () => {},
  onClose = () => {},
}) => {
  const [totalPrice, setTotalPrice] = useState(0);

  const title = useMemo(() => {
    const { action } = checkPayload;

    if (totalPrice) {
      return "Total Price : " + numberToDollar(totalPrice);
    }

    if (action.length > 1) {
      return "Notice";
    }

    let titleString = "";
    switch (action[0]) {
      case LOOKUP_TYPE_DNC_LOOKUP:
        titleString = "DNC";
        break;
      case LOOKUP_TYPE_EMAIL_LOOKUP:
        titleString = "Email";
        break;
      default:
        titleString = "Phone";
        break;
    }
    return `Notice - ${titleString} Validation`;
  }, [checkPayload, totalPrice]);

  const description = useMemo(() => {
    const { action } = checkPayload;

    //If single
    if (action.length === 1) {
      if (responseData.error_dnc_check) {
        return getDescriptionString("phone number", responseData.dnc_check_date);
      } else if (responseData.error_number_validation) {
        return getDescriptionString("phone number", responseData.number_validation_date);
      } else if (responseData.error_email_validation) {
        return getDescriptionString("email address", responseData.email_validation_date);
      }
    }

    //If multiple
    let returnData = [responseData.error_message + "\nWould you still like to proceed?"];
    let validatedData = [],
      nonValidatedData = [],
      totalPrice = 0;

    if (responseData.error_email_validation) {
      validatedData.push(displayDate("Email validated on", convertDateToString(responseData.email_validation_date)));
    } else if (action.includes(LOOKUP_TYPE_EMAIL_LOOKUP)) {
      let price = parseFloat(prices[LOOKUP_TYPE_BY_PAYLOAD_CONST[LOOKUP_TYPE_EMAIL_LOOKUP]]);
      totalPrice += price;
      nonValidatedData.push(displayDate("Email validation ", numberToDollar(price)));
    }

    if (responseData.error_number_validation) {
      validatedData.push(displayDate("Phone validated on ", convertDateToString(responseData.number_validation_date)));
    } else if (action.includes(LOOKUP_TYPE_CARRIER_LOOKUP)) {
      let price = parseFloat(prices[LOOKUP_TYPE_BY_PAYLOAD_CONST[LOOKUP_TYPE_CARRIER_LOOKUP]]);
      totalPrice += price;
      nonValidatedData.push(displayDate("Phone validation ", numberToDollar(price)));
    }

    if (responseData.error_dnc_check) {
      validatedData.push(displayDate("DNC validated on ", convertDateToString(responseData.dnc_check_date)));
    } else if (action.includes(LOOKUP_TYPE_DNC_LOOKUP)) {
      let price = parseFloat(prices[LOOKUP_TYPE_BY_PAYLOAD_CONST[LOOKUP_TYPE_DNC_LOOKUP]]);
      totalPrice += price;
      nonValidatedData.push(displayDate("DNC validation ", numberToDollar(price)));
    }

    setTotalPrice(totalPrice);

    returnData.push(
      <Paper elevation={0} style={{ marginTop: "10px", borderRadius: "8px" }}>
        <Table className={Styles.noticeTable} style={{ width: "70%" }}>
          <TableBody>
            {validatedData} {nonValidatedData}
          </TableBody>
        </Table>
      </Paper>
    );

    return returnData;
  }, [responseData, checkPayload, prices]);

  return (
    <>
      <div className={`${Styles.bodyWrp} `}>
        <span className={Styles.bodyTitle}>{title}</span>
        <span className={`${Styles.descriptionText} ${Styles.multiline}`}>{description}</span>
      </div>
      <div className={Styles.footerWrp}>
        <span onClick={onSubmit} className={`${Styles.button} ${Styles.submitButton}`}>
          YES
        </span>
        <span onClick={onClose} className={`${Styles.button} ${Styles.cancelButton}`}>
          NO
        </span>
      </div>
    </>
  );
};

export default PricingAlertExistingContent;
