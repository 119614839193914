import React from "react";
import ImportContactsDefaultTab from "./ImportContactsDefaultTab";
import { FILE_IMPORT_TAB } from "../../constants/CoreConstants";
import FileList from "./FileList";
import { useSelector } from "react-redux";
import { selectImportContactState } from "../../reducers/importContact/importContactSelector";
import ImportGoogleSheet from "./ImportGoogleSheet/ImportGoogleSheet";

const ImportContactsV2 = () => {
  const { importContactOptionTab} = useSelector(selectImportContactState);
 
  const renderStep = () => {
    switch (importContactOptionTab) {
      case FILE_IMPORT_TAB.TAB_FILE_LIST:
        return <FileList  />;
      case FILE_IMPORT_TAB.TAB_GOOGLE_SHEET:
        return <ImportGoogleSheet/>;
      default:
        return <ImportContactsDefaultTab />;
    }
  };
  return <div style={{ width: "100%", height: "100%" }}>{renderStep()}</div>;
};

export default ImportContactsV2;
