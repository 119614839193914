import React, { useMemo } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Divider } from "@material-ui/core";
import EmailPhoneVerifyTabContent from "./EmailPhoneVerifyTabContent";
import { getAccountData } from "../../../../helpers/Utils";

const EmailPhoneVerifyTab = () => {
  const name = useMemo(() => getAccountData("fullName"), []);

  return (
    <Box>
      <Box p={2}>
        <Typography variant="h5">
          Hey <b>{name}</b>, your data is dirty. Let's clean it up!
        </Typography>
        <Typography variant="body2">
          Bad data leads to bad results, Don't worry, we've got you covered.
        </Typography>
      </Box>
      <Divider light />
      <Box mt={1}>
        <EmailPhoneVerifyTabContent />
      </Box>
    </Box>
  );
};

export default EmailPhoneVerifyTab;
