import React, { useEffect, useState } from 'react';
import 'react-day-picker/lib/style.css';
import GlobalModal from '../globals/Modal/GlobalModal';
import Loading from '../common/Loading.js';
import If from 'if-else-react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getUserTagList } from '../../actions/contactAction';
import { Add } from '@material-ui/icons';

const ErroMsg = (props) => {
    return (
        <div className="text-red">
            {props.msg}
        </div>
    )
}

const CreateNewTag = (props) => {
    return (
        <div>
            <div className="create-new">
                <a onClick={()=>{props.createNew(props.searchKey)}} href="javascript:void(0)">
                    <span className="icon"><i className="la la-plus"></i></span>
                    <span className="text">Create new tag "<strong>{props.searchKey}</strong>"</span>
                </a>
            </div>
            <div className="no-results">
                <span className="icon text-danger"><i className="la la-exclamation-triangle"></i></span>
                <span className="text">Sorry no results found</span>
            </div>
        </div>

    )
}

const EachTag = (props) => {
    return (
        <li>
             <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox  value={props.data.id} onChange={props.selectTag} name={props.data.name} />}
                    label={props.data.name}
                  />
                </FormGroup>
          </FormControl>
            
        </li>
    )
}

const ModalTags = props =>  {

    const dispatch = useDispatch();

    const [isOpen,setIsOpen] = useState(props.isOpen);
    const [error,setError] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');
    const [allTags,setAllTags] = useState([]);
    const [submitEnable,setSubmitEnable] = useState(false);
    const [loading,setLoading] = useState(false);
    const [selectedTags,setSelectedTags] = useState([]);
    const [createNew,setCreateNew] = useState(false);
    const [searchKey,setSearchKey] = useState(null);

    const { userTagList } = useSelector(state => state.contactReducer);

    useEffect(() => {
        // if(userTagList == null || userTagList.data.length === 0) {
            setLoading(true);
            dispatch(getUserTagList({paginate: 'true'},(data) => {
                setAllTags(data.data);
                setLoading(false);
            }))
        // } else {
        //     setAllTags(userTagList);
        // }
    },[])


    const createNewTag = (tag) =>{
        props.handleChange("newTags", tag);
        toggle();
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let allTagsFilter = allTags.filter(
            function(e) {
                return this.indexOf(e.id.toString()) >= 0;
            },
            selectedTags
        );

        if(allTagsFilter.length === 0) {
            setErrorMsg('Please select at least one tag');
            setError(true);
        } else {
            props.handleChange("oldTags", allTagsFilter);
            toggle();   
        }
    }


    const toggle = () => {
        setIsOpen(false);
        setTimeout(() => {
            props.hideModal();
        }, 200);
    }

    const handelRadio = (e) => {

        let alltags = [...selectedTags];
        if (e.target.checked) {
            alltags.push(e.target.value)
        } else {
            alltags.splice(alltags.indexOf(e.target.value), 1);
        }

        setSelectedTags(alltags);
        setSubmitEnable(alltags.length > 0);
    }

    const changeSearch = (e) => {

        var searchKey = e.target.value.toUpperCase().trim();

        const tagData = allTags.filter((data)=> {
            if (data.name.toUpperCase().indexOf(searchKey) > -1) {
                return data;
            }
        })

        if (tagData.length == 0) {
            if(searchKey.length != 0 && searchKey.length < 30) {
                setCreateNew(true);
                setSearchKey(searchKey.replace(new RegExp(" ", 'g'), ''));
            }
        } else {
            setAllTags(tagData);
            setSearchKey(searchKey);
            setCreateNew(false);
        }
    }

    const renderList = () => {
        let runningList = allTags.map((data, index)=> {
            return (<EachTag selectTag={handelRadio} data={data} key={index}/>)
        });
        if (createNew) {
            runningList = (<CreateNewTag createNew={createNewTag} searchKey={searchKey}/>)
        }

        return runningList;
    }

    return (
        <GlobalModal 
            open={isOpen} 
            onSubmit={handleSubmit}
            title={props.title}
            onClose={toggle}
            buttonText={'Assign Tag'}
            buttonIcon={<Add/>}
            className="global-small-modal">
            
            <If condition={loading}>
                <div className="text-center w-100">
                    <Loading/>
                </div>
            </If>

            <If condition={!loading}>

                <If condition={error}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group m-form__group">
                                <div className="col-md-12">
                                    <div className="form-group m-form__group">
                                        <ErroMsg msg={errorMsg}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </If>

                <div className="row">
                    <div className="col-md-12">
                        <div className="campaign-search-list">
                            <div className="campaign-search form">
                                <div className="form-group m-0">
                                    <div className="m-input-icon m-input-icon--right">
                                        <input onChange={changeSearch} type="text"
                                            className="form-control m-input customFormControl"
                                            placeholder="Type and search"/>
                                        <span className="m-input-icon__icon m-input-icon__icon--right">
                                            <span>
                                                <i className="flaticon-search-1"></i>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="campaign-search-results alt awesome__scroll_bar">
                                <ul className="m-nav">
                                    {renderList()}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </If>
        </GlobalModal>
    );
}

export default ModalTags;
