import React from "react";

const DynamicEmptyPage = ({ title = "Looks like the list is empty", subTitle = "" }) => (
  <div
    style={{
      height: "60%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "120px",
      color: "var(--dark_blue)",
    }}
  >
    <img
      src={`${process.env.REACT_APP_CDN_LINK}assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png`}
      alt=''
      style={{ width: "40%", maxWidth: "250px", margin: "unset", marginBottom: "20px" }}
    />
    <div className='d-flex'>
      <h2 style={{ fontSize: "24px", margin: 0 }}>{title}</h2>
    </div>

    <p style={{ fontSize: "18px" }}>{subTitle}</p>
  </div>
);

export default DynamicEmptyPage;
