import React, { useState } from "react";
import { Box, Button, Dialog, DialogContentText, Divider, Typography } from "@material-ui/core";
import { BasicInput, LeftSideV2Styles } from "../LeftSideV2Styles";
import CustomModal from "../common/CustomModal/CustomModal";
import HomeIcon from "@material-ui/icons/Home";
import { updateContactDetailsV2 } from "../../../../api/contactApi";
import { updateMultipleData } from "../../../../actions/contactAction";
import { connect } from "react-redux";

const EditContactsModal = ({ open, onClose, contact, updateMultipleData }) => {
  const { primaryButton, closeButton, alignJustifyCenter, flexColumn } = LeftSideV2Styles();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [formData, setFormData] = useState({
    first_name: contact.first_name,
    last_name: contact.last_name,
    company_name: contact.company_name,
  });

  const [validationErrors, setValidationErrors] = useState({
    first_name: "",
    last_name: "",
    company_name: "",
  });

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));

    // Clear validation error when the user starts typing
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [field]: "",
    }));
  };

  const handleUpdateContact = () => {
    let param = {
      contactId: contact.id,
      params: {
        first_name: formData["first_name"],
        last_name: formData["last_name"],
        company_name: formData["company_name"],
      },
    };
    try {
      setIsSubmitting(true);
      updateContactDetailsV2(param)
        .then((res) => {
          if (res.success) {
            let oldContacts = { ...contact, ...param.params };

            updateMultipleData({ contact: oldContacts });

            window.showNotification("SUCCESS", "Contact information updated successfully.");

            onClose(true, param);
          } else {
            window.showNotification("ERROR", res.message);
          }
        })
        .catch((error) => {
          console.error("Error updating contact:", error);
          window.showNotification("ERROR", "Something went wrong!");
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } catch (error) {
      console.error("Error updating contact:", error);
      // Handle error as needed
    }
  };

  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    maxWidth={1200}
  >
    <CustomModal icon={<HomeIcon />} title={"Update Contact"}  open={open} onClose={onClose} />
      <DialogContentText id='alert-dialog-description'>
        <Box width={550} px={3}>
          <Box className={flexColumn} sx={{ gap: 15 }} py={3}>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                First Name
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter First Name'
                value={formData.first_name}
                onChange={(e) => handleInputChange("first_name", e.target.value)}
              />
              <Typography variant='body2' color='error'>
                {validationErrors.first_name}
              </Typography>
            </Box>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                Last Name
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter Last Name'
                value={formData.last_name}
                onChange={(e) => handleInputChange("last_name", e.target.value)}
              />
              <Typography variant='body2' color='error'>
                {validationErrors.last_name}
              </Typography>
            </Box>
            <Box className={flexColumn} sx={{ gap: 5 }}>
              <Typography variant='body1' color='textPrimary'>
                Company
              </Typography>
              <BasicInput
                fullWidth
                placeholder='Enter Company Name'
                value={formData.company_name}
                onChange={(e) => handleInputChange("company_name", e.target.value)}
              />
              <Typography variant='body2' color='error'>
                {validationErrors.company_name}
              </Typography>
            </Box>
          </Box>
          <Divider light />

          <Box className={alignJustifyCenter} sx={{ gap: "10px" }} mt={2}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              className={primaryButton}
              onClick={handleUpdateContact}
              disabled={isSubmitting}
            >
              Update Contact
            </Button>
            <Button className={closeButton} variant='contained' color='secondary' disableElevation onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </DialogContentText>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditContactsModal);
