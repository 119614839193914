import React, { createContext } from "react";
import './App.css';
import Routes from "./routes/Routes";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router } from 'react-router-dom';
import { createGenerateClassName, StylesProvider } from "@material-ui/styles";
import Utils from "./helpers/Utils";

const defaultHistory = createBrowserHistory();
export const HistoryProvider = createContext({});
const generateClassName = createGenerateClassName({
    productionPrefix: 'cntct_' + Utils.generateRandomString(5),
});

function App({ history = defaultHistory }) {
    return (
        <HistoryProvider.Provider value={history}>
            <StylesProvider generateClassName={generateClassName}>
                <Router history={history}>
                    <Routes history={history} />
                </Router>
            </StylesProvider>
        </HistoryProvider.Provider>

    );
}

export default App;
