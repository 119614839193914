/* eslint-disable no-useless-escape */
import React, { useContext, useEffect, useState } from "react";
import Styles from "./FileReportDetails.module.css";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  withStyles,
} from "@material-ui/core";
import { ArrowBack, InfoRounded, KeyboardArrowDown } from "@material-ui/icons";
import { STATUS, TAB_CLEAN_LIST } from "../Constants";
import { DataCleanContext } from "../StateProvider";
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../../globals/Dropdown/GlobalDropdown";
import { IconList } from "../../globals/IconList";
import Pagination from "./Pagination";
import { cleanContactsActionsApi, cleanDataFilesDetailsApi } from "../../../api/cleanDataApi";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import ModalAssignToList from "./actions/ModalAssignToList";
import ModalAssignToCampaign from "./actions/ModalAssignToCampaign";
import ModalAssignToTag from "./actions/ModalAssignToTag";
import ModalListForStageAdd from "./actions/ModalListForStageAdd";
import NewConfirmAlert from "../../common/new-alert/NewConfirmAlert";
import ValidationResponse from "./ValidationResponse";
import { CoreButton } from "../enhanceData/commons/CoreButton";
import {
  getDncStatusLabelAndColor,
  getEmailValidationStatusLabelAndColor,
  getLandlineMobileStatus,
  getNumberValidationStatusLabelAndColor,
  getSkipTraceStatusLabelAndColor,
  validateFilterAction,
} from "./FileReportDetailsSingle";
import numberToDollar from "../../../helpers/numberToDollar";
import ModalAssignToPowerDialer from "./actions/ModalAssignToPowerDialer";
import { renderSearchType } from "./FileReport";
import ModalExportContact from "./actions/ModalExportContact";
import DynamicSkeleton from "../../common/DynamicSkeleton";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    fontSize: "14px !important",
    borderRadius: "4px !important",
    position: "relative",
    color: "#3a414a !important",
    border: "1px solid #ced4da !important",
    padding: "10px 26px 10px 12px !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
    },
    backgroundColor: "#ffffff",
  },
}))(InputBase);

const FileReportDetails = ({ onChangeTab, filter = [], tab }) => {
  const { state } = useContext(DataCleanContext);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [fileContacts, setFileContacts] = useState([]);
  const [filterBy, setFilterBy] = useState({
    invalidNumbers: false,
    validNumbers: false,
    invalidEmails: false,
    validEmails: false,
    dncOn: false,
    dncOff: false,
    skipTrace: false,
    landline: false,
    mobile: false,
  });
  const [selectedFileContactIds, setSelectedFileContactIds] = useState([]);
  const [showListModal, setShowListModal] = useState(false);
  const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [openAddStageToContactModal, setOpenAddStageToContactModal] = useState(false);
  const [openExportFileModal, setOpenExportFileModal] = useState(false);
  const [showAddToPowerDialerModal, setShowAddToPowerDialerModal] = useState(false);

  const handleActiveTab = (tabValue) => {
    if (onChangeTab) {
      onChangeTab(tabValue);
    }
  };

  const getTotalRecord = (file) => {
    if (!file.file_statistics) {
      return 0;
    }

    try {
      return JSON.parse(file.file_statistics).total_record;
    } catch (e) {
      return 0;
    }
  };

  useEffect(() => {
    if (!state.cleanDataRequestId && !state.cleanDataRequest) {
      window.showNotification("error", "No clean data file found");
      handleActiveTab(TAB_CLEAN_LIST.REPORTS);
      return;
    }

    if (page !== "") {
      setLoading(true);
      cleanDataFilesDetailsApi({
        page: page,
        limit: limit,
        totalPage: totalPage,
        cleanDataRequestId: state.cleanDataRequestId,
        filterBy: Object.values(filterBy).every((value) => value === false) ? null : filterBy,
      })
        .then((res) => {
          res = res.data;
          if (res.success) {
            setFileContacts(res.data);
            setTotalPage(res.totalPage);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, state.cleanDataRequestId, filterBy]);

  const handleFilterChange = (name, value) => {
    setFilterBy({ ...filterBy, [name]: value });
  };

  const renderFileUrl = (file) => {
    if (file && file.aws3_info) {
      if (file.aws3_info && file.aws3_info === "\\") {
        return "";
      } else {
        let aws3Info = file.aws3_info.replace(/\\/g, "").replace(/&quot;/g, '"');
        try {
          aws3Info = JSON.parse(aws3Info).fileUrl;
        } catch (error) {
          console.log(error);
        }
        return aws3Info;
      }
    }
    return "";
  };

  const getChipColor = (file) => {
    switch (file.status) {
      case STATUS.ACTIVE:
        return Styles.active;
      case STATUS.PROCESSING:
        return Styles.processing;
      case STATUS.QUEUED:
        return Styles.queued;
      case STATUS.COMPLETE:
        return Styles.complete;
      case STATUS.FAILED:
        return Styles.failed;
      default:
        return Styles.default;
    }
  };

  const handleAddAllContactToCRM = () => {

    if (!fileContacts[0]){
      window.showNotification("ERROR", "No contact available");
      return;
    }

    NewConfirmAlert({
      onSubmit: () => {
        const payload = {
          cleanContactIds: [],
          filterType: "ALL",
          action: "ADD_TO_CRM",
          pageSource: tab
        };

        if (state.cleanDataRequestId){
          payload.fileId = state.cleanDataRequestId;
        }

        if (filterBy){
          if (filterBy.validNumbers || filterBy.invalidNumbers) {
            let numberStatus = [];

            if (filterBy.validNumbers){
              numberStatus.push("VALID");
            }

            if (filterBy.invalidNumbers){
              numberStatus.push("INVALID");
            }

            payload.numberStatus = numberStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.validEmails || filterBy.invalidEmails) {
            let emailStatus = [];

            if (filterBy.validEmails){
              emailStatus.push("VALID");
            }

            if (filterBy.invalidEmails){
              emailStatus.push("INVALID");
            }

            payload.emailStatus = emailStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.dncOn || filterBy.dncOff) {
            let dncStatus = [];

            if (filterBy.dncOn){
              dncStatus.push("DO_NOT_CALL");
            }

            if (filterBy.dncOff){
              dncStatus.push("VERIFIED_OK");
            }

            payload.dncStatus = dncStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.skipTrace){
            payload.skipTraceStatus = ["TRACED"];
            payload.filterType = "SEGMENT";
          }

          if (filterBy.landline || filterBy.mobile){
            let numberType = [];

            if (filterBy.landline){
              numberType.push("landline");
            }

            if (filterBy.mobile){
              numberType.push("mobile");
            }

            payload.numberType = numberType;
            payload.filterType = "SEGMENT";
          }
        }

        cleanContactsActionsApi(payload)
          .then((res) => {
            if (res && res.success) {
              window.showNotification("success", res.message);
            } else {
              window.showNotification("error", "Something went wrong try again later");
            }
          })
          .finally(() => {});
      },
      title: "Are your sure?",
      description: "You are about to add all contacts to CRM",
      cancelText: "Cancel",
      submitText: "Add",
      width: "480px",
    });
  };

  const handleAddSelectedContactToCRM = () => {

    if (!fileContacts[0]){
      window.showNotification("ERROR", "No contact available");
      return;
    }

    if (selectedFileContactIds.length === 0 && !filterBy.validNumbers && !filterBy.invalidNumbers && !filterBy.validEmails && !filterBy.invalidEmails && !filterBy.dncOn && !filterBy.dncOff && !filterBy.skipTrace) {
      window.showNotification("ERROR", "Check at least one contact or any filter");
      return;
    }

    NewConfirmAlert({
      onSubmit: () => {
        const payload = {
          cleanContactIds: selectedFileContactIds,
          filterType: "SEGMENT",
          action: "ADD_TO_CRM",
          pageSource: tab
        };

        if (state.cleanDataRequestId){
          payload.fileId = state.cleanDataRequestId;
        }

        if (filterBy){
          if (filterBy.validNumbers || filterBy.invalidNumbers) {
            let numberStatus = [];

            if (filterBy.validNumbers){
              numberStatus.push("VALID");
            }

            if (filterBy.invalidNumbers){
              numberStatus.push("INVALID");
            }

            payload.numberStatus = numberStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.validEmails || filterBy.invalidEmails) {
            let emailStatus = [];

            if (filterBy.validEmails){
              emailStatus.push("VALID");
            }

            if (filterBy.invalidEmails){
              emailStatus.push("INVALID");
            }

            payload.emailStatus = emailStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.dncOn || filterBy.dncOff) {
            let dncStatus = [];

            if (filterBy.dncOn){
              dncStatus.push("DO_NOT_CALL");
            }

            if (filterBy.dncOff){
              dncStatus.push("VERIFIED_OK");
            }

            payload.dncStatus = dncStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.skipTrace){
            payload.skipTraceStatus = ["TRACED"];
            payload.filterType = "SEGMENT";
          }

          if (filterBy.landline || filterBy.mobile){
            let numberType = [];

            if (filterBy.landline){
              numberType.push("landline");
            }

            if (filterBy.mobile){
              numberType.push("mobile");
            }

            payload.numberType = numberType;
            payload.filterType = "SEGMENT";
          }
        }

        cleanContactsActionsApi(payload)
          .then((res) => {
            if (res && res.success) {
              window.showNotification("success", res.message);
            } else {
              window.showNotification("error", "Something went wrong try again later");
            }
          })
          .finally(() => {});
      },
      title: "Are your sure?",
      description: "You are about to add selected contacts to CRM",
      cancelText: "Cancel",
      submitText: "Add",
      width: "480px",
    });
  };

  const handleDeleteSelectedContactToCRM = () => {

    if (!fileContacts[0]){
      window.showNotification("ERROR", "No contact available");
      return;
    }

    if (selectedFileContactIds.length === 0 && !filterBy.validNumbers && !filterBy.invalidNumbers && !filterBy.validEmails && !filterBy.invalidEmails && !filterBy.dncOn && !filterBy.dncOff && !filterBy.skipTrace) {
      window.showNotification("ERROR", "Check at least one contact or any filter");
      return;
    }

    NewConfirmAlert({
      onSubmit: () => {
        const payload = {
          cleanContactIds: selectedFileContactIds,
          filterType: "SEGMENT",
          action: "DELETE_CONTACT",
          pageSource: tab
        };

        if (state.cleanDataRequestId){
          payload.fileId = state.cleanDataRequestId;
        }

        if (filterBy){
          if (filterBy.validNumbers || filterBy.invalidNumbers) {
            let numberStatus = [];

            if (filterBy.validNumbers){
              numberStatus.push("VALID");
            }

            if (filterBy.invalidNumbers){
              numberStatus.push("INVALID");
            }

            payload.numberStatus = numberStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.validEmails || filterBy.invalidEmails) {
            let emailStatus = [];

            if (filterBy.validEmails){
              emailStatus.push("VALID");
            }

            if (filterBy.invalidEmails){
              emailStatus.push("INVALID");
            }

            payload.emailStatus = emailStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.dncOn || filterBy.dncOff) {
            let dncStatus = [];

            if (filterBy.dncOn){
              dncStatus.push("DO_NOT_CALL");
            }

            if (filterBy.dncOff){
              dncStatus.push("VERIFIED_OK");
            }

            payload.dncStatus = dncStatus;
            payload.filterType = "SEGMENT";
          }

          if (filterBy.skipTrace){
            payload.skipTraceStatus = ["TRACED"];
            payload.filterType = "SEGMENT";
          }

          if (filterBy.landline || filterBy.mobile){
            let numberType = [];

            if (filterBy.landline){
              numberType.push("landline");
            }

            if (filterBy.mobile){
              numberType.push("mobile");
            }

            payload.numberType = numberType;
            payload.filterType = "SEGMENT";
          }
        }

        cleanContactsActionsApi(payload)
          .then((res) => {
            if (res && res.success) {
              window.showNotification("success", res.message);
            } else {
              window.showNotification("error", "Something went wrong try again later");
            }
          })
          .finally(() => {});
      },
      title: "Are your sure?",
      description: "You are about to delete selected contacts",
      cancelText: "Cancel",
      submitText: "Yes",
      width: "480px",
    });
  };

  const filterArray = filter.map((value) => {
    const result = validateFilterAction.find((filterValue) => filterValue.props === value);
    if (!result) throw Error("invalid filterBy type passed to component");
    return result;
  });

  return (
    <div className={Styles.pageWrapper}>
      <div className={Styles.pageHeader}>
        <div style={{ display: "grid" }}>
          <Typography variant={"h6"}>File Validation Details</Typography>
          <Typography variant={"caption"}>
            Check the status of contacts of the files you've uploaded for verifications
          </Typography>
        </div>
        <div className={Styles.pageHeaderAction}>
          <FormControl className={Styles.formControl}>
            <Select
              labelId='per-page-select-label'
              id='per-page-select'
              name='perPage'
              value={limit}
              displayEmpty
              onChange={(e) => {
                setLimit(e.target.value);
              }}
              input={<BootstrapInput />}
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem className='dropdownhelper-menuitem-class' value={""} disabled>
                # of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={5}>
                5 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={10}>
                10 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={25}>
                25 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={50}>
                50 of Results
              </MenuItem>
              <MenuItem className='dropdownhelper-menuitem-class' value={100}>
                100 of Results
              </MenuItem>
            </Select>
          </FormControl>
          <Button
            className={Styles.backButton}
            startIcon={<ArrowBack />}
            variant='contained'
            color='primary'
            onClick={() => {
              handleActiveTab(TAB_CLEAN_LIST.REPORTS);
            }}
          >
            Back
          </Button>
        </div>
      </div>
      <div className={Styles.pageBody}>
        <table>
          <thead className={Styles.tableHead}>
            <tr>
              <th style={{ width: "30%" }}>File Name</th>
              <th className={Styles.textAlignCenter}>Date Uploaded</th>
              <th className={Styles.textAlignCenter}>Total Records</th>
              <th className={Styles.textAlignCenter}>Total Cost</th>
              <th className={Styles.textAlignCenter}>Status</th>
              <th className={Styles.textAlignCenter}>Failed Reason</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={{ width: "30%" }}>
                <ul>
                  <ul>
                    <li className={Styles.fileTitle}>{state.cleanDataRequest.file_name_original}</li>
                    <li className={Styles.validationType}>
                      <span className={Styles.validationTypeLabel}>Search type:</span>{" "}
                      {renderSearchType(state.cleanDataRequest)}
                    </li>
                  </ul>
                </ul>
              </td>
              <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                {window.globalTimezoneConversionToDifferentTimezone(state.cleanDataRequest.created_at)}
              </td>
              <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                {getTotalRecord(state.cleanDataRequest)}
              </td>
              <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                {numberToDollar(state.cleanDataRequest.total_cost)}
              </td>
              <td className={Styles.textAlignCenter}>
                <span className={`${Styles.statusChip} ${getChipColor(state.cleanDataRequest)}`}>
                  {state.cleanDataRequest.status}
                </span>
              </td>
              <td className={Styles.textAlignCenter}>
                {state.cleanDataRequest.failed_reason && (
                  <BootstrapTooltip arrow title={<span>{state.cleanDataRequest.failed_reason}</span>}>
                    <div className={`${Styles.truncate} ${Styles.failedReason}`}>
                      {state.cleanDataRequest.failed_reason}
                    </div>
                  </BootstrapTooltip>
                )}
              </td>
              <td>
                <div className={Styles.fileActions}>
                  <span
                    className={`${Styles.viewDetails} ${Styles.cursorPointer}`}
                    onClick={() => {
                      handleActiveTab(TAB_CLEAN_LIST.REPORTS);
                    }}
                  >
                    Hide Details
                  </span>
                  {renderFileUrl(state.cleanDataRequest) !== "" ? (
                    <CoreButton
                      target='_blank'
                      href={renderFileUrl(state.cleanDataRequest)}
                      backgroundColor={"#316AFF"}
                      color={"primary"}
                      variant={"contained"}
                    >
                      Download
                    </CoreButton>
                  ) : (
                    <div />
                  )}
                </div>
              </td>
            </tr>
            <tr>
              <td colSpan={7}>
                <div className={Styles.detailsPane}>
                  <div className={Styles.detailsPaneHeader}>
                    <div className={Styles.detailsPaneHeaderLeft} style={{ flexDirection: "column" }}>
                      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        {/* <span className={Styles.fontBold}>
                          Validation Results:
                        </span> */}
                        <span>
                          Total Contacts -&nbsp;
                          {getTotalRecord(state.cleanDataRequest)}
                        </span>
                      </div>
                      <div className={Styles.filterWrapper}>
                        <Typography variant={"subtitle1"}>Filter Results: </Typography>
                        {filterArray.map((value, index) => {
                          return (
                            <div key={index} className={Styles.dFlexAlignCenter}>
                              <Checkbox
                                size={"small"}
                                checked={filterBy[value.key]}
                                onClick={() => {
                                  handleFilterChange(value.key, !filterBy[value.key]);
                                }}
                                color='primary'
                              />
                              <Typography variant={"body2"}>{value.title}</Typography>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className={Styles.detailsPaneHeaderRight}>
                      <div>
                        <GlobalDropdown className='add__action_dropdown' placement='bottomLeft'>
                          <GlobalDropDownButton>
                            <CoreButton variant='contained' color='primary' endIcon={<KeyboardArrowDown />}>
                              as Actions
                            </CoreButton>
                          </GlobalDropDownButton>
                          <GlobalDropDownItem
                            onClick={handleAddAllContactToCRM}
                            title='Add All Contact to CRM'
                            onClickHide={true}
                            icon={IconList.addToContactSVG}
                          />
                          <GlobalDropDownItem
                            onClick={handleAddSelectedContactToCRM}
                            title='Add Selected Contact to CRM'
                            onClickHide={true}
                            icon={IconList.addToContactSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {
                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }
                              setShowListModal(true);
                            }}
                            title='Add To List'
                            onClickHide={true}
                            icon={IconList.addToListSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {
                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }
                              setShowAddToCampaignModal(true);
                            }}
                            title='Add To Campaign'
                            onClickHide={true}
                            icon={IconList.addToContactSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {

                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }

                              if (selectedFileContactIds.length === 0) {
                                window.showNotification("ERROR", "Check at least one contact");
                                return;
                              }
                              setShowAddToPowerDialerModal(true);
                            }}
                            title='Add To Power Dialer'
                            onClickHide={true}
                            icon={IconList.addToContactSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {
                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }
                              setShowTagModal(true);
                            }}
                            title='Assign Tag'
                            onClickHide={true}
                            icon={IconList.addTagSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {
                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }
                              setOpenAddStageToContactModal(true);
                            }}
                            title='Add to Pipeline Stage'
                            onClickHide={true}
                            icon={IconList.addToPipelineSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {
                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }

                              setOpenExportFileModal(true);
                            }}
                            title='Export All Contacts'
                            onClickHide={true}
                            icon={IconList.exportContactSVG}
                          />
                          <GlobalDropDownItem
                            onClick={() => {
                              if (!fileContacts[0]){
                                window.showNotification("ERROR", "No contact available");
                                return;
                              }

                              if (selectedFileContactIds.length === 0 && !filterBy.validNumbers && !filterBy.invalidNumbers && !filterBy.validEmails && !filterBy.invalidEmails && !filterBy.dncOn && !filterBy.dncOff && !filterBy.skipTrace) {
                                window.showNotification("ERROR", "Check at least one contact or any filter");
                                return;
                              }

                              setOpenExportFileModal(true);
                            }}
                            title='Export Selected Contacts'
                            onClickHide={true}
                            icon={IconList.exportContactSVG}
                          />
                          <GlobalDropDownItem
                            onClick={handleDeleteSelectedContactToCRM}
                            title='Delete Selected Contacts'
                            onClickHide={true}
                            icon={IconList.deleteSelectedContactSVG}
                          />
                        </GlobalDropdown>
                      </div>
                    </div>
                  </div>
                  <div className={Styles.detailsPaneBody}>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className={Styles.dFlexAlignCenter}>
                              <Checkbox
                                color='primary'
                                inputProps={{
                                  "aria-label": "secondary checkbox",
                                }}
                                checked={
                                  fileContacts.length === selectedFileContactIds.length &&
                                  selectedFileContactIds.length !== 0
                                }
                                indeterminate={
                                  fileContacts.length > selectedFileContactIds.length &&
                                  selectedFileContactIds.length !== 0
                                }
                                onClick={() => {
                                  if (fileContacts.length === selectedFileContactIds.length) {
                                    setSelectedFileContactIds([]);
                                  } else {
                                    setSelectedFileContactIds(fileContacts.map((fileContact) => fileContact.id));
                                  }
                                }}
                              />
                              Name
                            </div>
                          </th>
                          <th className={Styles.textAlignCenter}>Email</th>
                          <th className={Styles.textAlignCenter}>Email Validation Status</th>
                          <th className={Styles.textAlignCenter}>Number</th>
                          <th className={Styles.textAlignCenter}>Number Validation Status</th>
                          <th className={Styles.textAlignCenter}>Landline/Mobile</th>
                          <th className={Styles.textAlignCenter}>DNC Status</th>
                          <th className={Styles.textAlignCenter}>Skip Trace Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan={8}>
                                <DynamicSkeleton count={10}/>
                            </td>
                          </tr>
                        ) : fileContacts[0] ? (
                          fileContacts.map((fileContact, index) => (
                            <tr>
                              <td>
                                <div className={Styles.dFlexAlignCenter}>
                                  <Checkbox
                                    color='primary'
                                    inputProps={{
                                      "aria-label": "secondary checkbox",
                                    }}
                                    checked={selectedFileContactIds.includes(fileContact.id)}
                                    onClick={() => {
                                      if (selectedFileContactIds.includes(fileContact.id)) {
                                        setSelectedFileContactIds(
                                          selectedFileContactIds.filter(
                                            (selectedFileContactId) => selectedFileContactId !== fileContact.id
                                          )
                                        );
                                      } else {
                                        setSelectedFileContactIds([...selectedFileContactIds, fileContact.id]);
                                      }
                                    }}
                                  />
                                  {`${fileContact.first_name ?? ""} ${fileContact.last_name ?? ""}`}
                                </div>
                              </td>
                              <td className={Styles.textAlignCenter}>{fileContact.email}</td>
                              <td className={Styles.textAlignCenter}>
                                <Chip
                                  size={"small"}
                                  variant={"default"}
                                  color='primary'
                                  style={{
                                    backgroundColor: getEmailValidationStatusLabelAndColor(
                                      fileContact.email_valid_status
                                    )[1],
                                  }}
                                  label={getEmailValidationStatusLabelAndColor(fileContact.email_valid_status)[0]}
                                />
                                {fileContact.email_validation_infos && (
                                  <BootstrapTooltip arrow title='Click for details'>
                                    <span
                                      onClick={() => {
                                        ValidationResponse({
                                          response: fileContact.email_validation_infos,
                                        });
                                      }}
                                    >
                                      <InfoRounded
                                        style={{
                                          color: "green",
                                          fontSize: "14px",
                                          marginLeft: "6px",
                                        }}
                                      />
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </td>
                              <td className={Styles.textAlignCenter}>{fileContact.number}</td>
                              <td className={Styles.textAlignCenter}>
                                <Chip
                                  size={"small"}
                                  variant={"default"}
                                  color='primary'
                                  style={{
                                    backgroundColor: getNumberValidationStatusLabelAndColor(
                                      fileContact.number_valid_status
                                    )[1],
                                  }}
                                  label={getNumberValidationStatusLabelAndColor(fileContact.number_valid_status)[0]}
                                />
                                {fileContact.number_validation_infos && (
                                  <BootstrapTooltip arrow title='Click for details'>
                                    <span
                                      onClick={() => {
                                        ValidationResponse({
                                          response: fileContact.number_validation_infos,
                                        });
                                      }}
                                    >
                                      <InfoRounded
                                        style={{
                                          color: "green",
                                          fontSize: "14px",
                                          marginLeft: "6px",
                                        }}
                                      />
                                    </span>
                                  </BootstrapTooltip>
                                )}
                              </td>
                              <td className={Styles.textAlignCenter}>
                                <Chip
                                  size={"small"}
                                  variant={"default"}
                                  color='primary'
                                  label={getLandlineMobileStatus(fileContact.number_state)}
                                />
                              </td>
                              <td className={Styles.textAlignCenter}>
                                <Chip
                                  size={"small"}
                                  variant={"default"}
                                  color='primary'
                                  style={{
                                    backgroundColor: getDncStatusLabelAndColor(fileContact.dnc_status)[1],
                                  }}
                                  label={getDncStatusLabelAndColor(fileContact.dnc_status)[0]}
                                />
                              </td>
                              <td className={Styles.textAlignCenter}>
                                {fileContact.skip_trace_status && (
                                  <Chip
                                    size={"small"}
                                    variant={"default"}
                                    color='primary'
                                    style={{
                                      backgroundColor: getSkipTraceStatusLabelAndColor(
                                        fileContact.skip_trace_status
                                      )[1],
                                    }}
                                    label={getSkipTraceStatusLabelAndColor(fileContact.skip_trace_status)[0]}
                                  />
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8}>
                              <div className={Styles.loaderWrapper}>
                                <h5 className={Styles.marginTop}>No contact found!</h5>
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    <Box display='flex' justifyContent='center'>
                      <Box display='flex' alignItems='center'>
                        <FormControl className={Styles.formControl}>
                          <Select
                            style={{ width: "200px" }}
                            name='perPage'
                            value={limit}
                            displayEmpty
                            onChange={(e) => {
                              setPage(1);
                              setLimit(e.target.value);
                            }}
                            input={<BootstrapInput />}
                            inputProps={{ "aria-label": "Without label" }}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              getContentAnchorEl: null,
                            }}
                          >
                            <MenuItem className='dropdownhelper-menuitem-class' value={""} disabled>
                              # of Results
                            </MenuItem>
                            <MenuItem className='dropdownhelper-menuitem-class' value={5}>
                              5 of Results
                            </MenuItem>
                            <MenuItem className='dropdownhelper-menuitem-class' value={10}>
                              10 of Results
                            </MenuItem>
                            <MenuItem className='dropdownhelper-menuitem-class' value={25}>
                              25 of Results
                            </MenuItem>
                            <MenuItem className='dropdownhelper-menuitem-class' value={50}>
                              50 of Results
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <Pagination
                          page={page}
                          totalPage={totalPage}
                          callback={(uPage) => {
                            setPage(uPage);
                          }}
                        />
                      </Box>
                    </Box>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {showListModal && (
        <ModalAssignToList
          open={showListModal}
          selectedFileContactIds={selectedFileContactIds}
          onClose={() => setShowListModal(false)}
          filter={filterBy}
          fileId={state.cleanDataRequestId}
          tab={tab}
        />
      )}

      {showAddToCampaignModal && (
        <ModalAssignToCampaign
          open={showAddToCampaignModal}
          onClose={() => setShowAddToCampaignModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          fileId={state.cleanDataRequestId}
          tab={tab}
        />
      )}

      {showAddToPowerDialerModal && (
        <ModalAssignToPowerDialer
          open={showAddToPowerDialerModal}
          onClose={() => setShowAddToPowerDialerModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          fileId={state.cleanDataRequestId}
          tab={tab}
        />
      )}

      {showTagModal && (
        <ModalAssignToTag
          open={showTagModal}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          onClose={() => setShowTagModal(false)}
          fileId={state.cleanDataRequestId}
          tab={tab}
        />
      )}

      {openAddStageToContactModal && (
        <ModalListForStageAdd
          open={openAddStageToContactModal}
          onClose={() => setOpenAddStageToContactModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          filter={filterBy}
          fileId={state.cleanDataRequestId}
          tab={tab}
        />
      )}

      {openExportFileModal && (
        <ModalExportContact
          openExportFileModal={openExportFileModal}
          onClose={() => setOpenExportFileModal(false)}
          selectedFileContactIds={selectedFileContactIds}
          fileId={state.cleanDataRequestId}
          filter={filterBy}
          tab={tab}
        />
      )}
    </div>
  );
};

export default FileReportDetails;
