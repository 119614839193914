import { makeStyles } from "@material-ui/core";

export const ContactsStyles = makeStyles((theme) => ({
  contactAvatar: {
    background: "rgba(60, 126, 243, 0.1)",
    color: "var(--light_blue)",
  },

  contactNavIcon: {
    color: " var(--light_blue)",
    display: "inline-flex",
    alignItems: "center",
    cursor: "pointer",
  },

  contactNavigationTitle: {
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 12,
    },
  },

  contactNavigationText: {
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: 10,
    },
  },

  shareIcon: {
    background: "#E5E5E5",
    color: "var(--light_blue)",
    cursor: "pointer",

    "&:focus": {
      background: "#E5E5E5",
    },
    "& .MuiSvgIcon-root": {
      fontSize: 14,
    },
  },

  editIcon: {
    cursor: "pointer",

    "&:focus": {
      background: "transparent",
    },
  },

  actionIconButton: {
    background: " #316aff",
    border: "none !important",

    "&:hover, &:focus": {
      background: "#316aff !important",
    },

    "& path": {
      fill: "white",
    },
  },
}));
