import { getZipCodeByPlace } from "../api/contactApi";

export const getAddressLines = async (placeId) => {
  if (!placeId) {
    return {
      addressLine1: undefined,
      addressLine2: undefined,
    };
  }

  const googleAPIKey = process.env.REACT_APP_MAP_KEY;
  let city = "",
    state = "",
    streetNumber = "",
    road = "",
    county = "",
    address = "";
  await getZipCodeByPlace({ placeId, googleAPIKey }).then((res) => {
    try {
      if (res.data.data.result.formatted_address) {
        let data_array = res.data.data.result.formatted_address.split(",");
        if (data_array.length === 4) {
          let state_zip = data_array[2].trim().split(" ");
          address = data_array[0].trim();
          city = data_array[1].trim();
          state = state_zip[0];
        } else {
          if (
            res.data.data.result.address_components &&
            Array.isArray(res.data.data.result.address_components)
          ) {
            res.data.data.result.address_components.forEach((item) => {
              if (item.types[0] === "street_number") {
                streetNumber = item.long_name;
              } else if (item.types[0] === "route") {
                road = item.long_name;
              } else if (item.types[0] === "administrative_area_level_1") {
                state = item.short_name;
              } else if (item.types[0] === "administrative_area_level_2") {
                city = item.long_name;
              } else if (item.types[0] === "administrative_area_level_3") {
                county = item.long_name;
              }
            });

            address = (streetNumber + " " + road).trim();
            city = county !== "" ? county : city;
          }
        }
      }
    } catch (error) {}
  });

  let addressLine2 = [city, state]
    .filter((value) => value !== "")
    .join(",")
    .trim();

  return {
    addressLine1: [address].filter((value) => value !== "").join(","),
    addressLine2: addressLine2,
  };
};
