import React, { useState, useContext, useEffect} from 'react';
import CsvParse from './lib';
import If from 'if-else-react';
import StepProgressBar from './StepProgressBar';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import MapContact from './MapContact/MapContact';
import { Publish } from '@material-ui/icons';
import {contactLimitRequest} from "../../api/contactApi";
import utils from "../../helpers/Utils";


const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}

const formatFileSize = (bytes, decimalPoint) =>{
    if(bytes === 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const validationForm = (data) => {
    return true;
}

const submitContactLimitRequest = () => {
    contactLimitRequest()
        .then(response => {
            if (response.data.success) {
                window.showNotification('Success', response.data.message)
            } else {
                window.showNotification('Error', response.data.message)
            }
        })
        .catch(error => {
            utils.handleException(error);
        })

}

class Wrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.state.data,
            validateData: props.state.validateData,
            file: props.state.file,
            total :  props.state.total,
            current : 0,
            progressBarOpen : false,
            success : 0,
            failed : 0,
            isExistFailed : 0,
            totalUpload : 0,
            validateFailed : 0,
            checkedData : props.state.checkedData,
            maxFileSize: props.maxFileSize ? props.maxFileSize : '100M',
            submitData : []
        }

        this.handleData = this.handleData.bind(this);
        this.handleError = this.handleError.bind(this);
        this.callBack = this.callBack.bind(this);

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
       }

        handleData = (data, file) => {
        let rowLength = data.length;
        if(rowLength > 20001){
            window.showNotification('Error', 'CSV file row length must be less than 20000');
            return false;
        }
            let validateData = [];
            let checkedData = [];
            if (rowLength > 0) {
                let dataLength = data[0].length;
                for (let i = 0; i < dataLength; i++) {
                    validateData['id' + i] = false;
                    validateData['skipId' + i] = false;
                    checkedData[i]  = {};
                }
            }

            this.setState({data, file, validateData, total : data.length, progressBarOpen : false, checkedData });
        }

        handleError = () => {

        }

        callBack = (data) =>
        {
            this.setState({
                submitData : data
            });
        }



        onFormSubmit = (e) =>
        {
            e.preventDefault();
            const { data,file, total,submitData, validateData } = this.state;
            if (validationForm(validateData) === true) {
                this.props.handleChange("data", data);
                this.props.handleChange("submitData", submitData);
                this.props.handleChange("file", file);
                this.props.handleChange("total", total);
                this.props.nextStep();
            }
        }

        onChange =(e) =>
        {
            this.setState({file:e.target.files[0]})
        }

        render()
        {

            return (
                <React.Fragment>
                    <div className="import_content_container">
                        <form onSubmit={this.onFormSubmit}>
                        <div className="main_content_inner_container white">
                            <div className="main_content_topbar">
                                <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                                    <HeaderRight />
                                    {/*<div className="d-flex justify-content-end">*/}
                                    {/*    <button type="submit" className="btn btn-sm btn-danger importButton" disabled={*/}
                                    {/*        (this.state.data.length && this.props.contactLimit >= this.props.currentContact+this.state.total && this.state.file && this.state.file.size<this.state.maxFileSize) ? false : true }>*/}
                                    {/*        <Publish/>*/}
                                    {/*        Upload & Next*/}
                                    {/*    </button>*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div class="divider main_content_container_divider"></div>

                            <div class="main_content_main_section">
                                <StepProgressBar fromCampaign={this.props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_UPLOAD}/>
                            </div>

                            <div class="content_divider"><div class="content_divider_inner"></div></div>

                        
                            <If condition = {this.state.data.length !==0 && this.props.contactLimit < this.props.currentContact+this.state.total}>
                                <div className="m-portlet-sub bg-danger text-center">
                                    <p className="file-warning text-white m-0 mt-2 red-text">
                                        <i className="la la-warning pr-2"></i>
                                        <b>To request a contact limit increase from our team click submit. <br/>Our team will review your request and get back to you ASAP.</b>
                                        <a className={"btn radius-5 white-text text-center accent--bg--color ml-2"} onClick={submitContactLimitRequest}>Submit</a>
                                    </p>
                                </div>
                            </If>
                            <If condition = {this.state.file && this.state.file.size>this.state.maxFileSize}>
                                <div className="m-portlet-sub bg-danger text-center">
                                    <p className="file-warning text-white m-0 mt-2 red-text">
                                        <i className="la la-warning pr-2"></i> File Size Limit Exceeded
                                    </p>
                                </div>
                            </If>
                            {
                                (this.state.data.length !== 0) &&
                                <div className="uploaded_file_details_section">
                                    <div className="uploaded_file_details_content d-flex justify-content-start">
                                        <div class="uploaded_file_details d-flex align-items-center">
                                            <div class="uploaded_file_icon justify-content-start">
                                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)" fill="#133159"><path d="M17.083 20h-10A2.086 2.086 0 0 1 5 17.917v-12.5c0-1.15.935-2.083 2.083-2.083h10c1.149 0 2.084.934 2.084 2.083v12.5c0 1.149-.935 2.083-2.084 2.083zm-10-15.833c-.689 0-1.25.56-1.25 1.25v12.5c0 .689.561 1.25 1.25 1.25h10c.69 0 1.25-.561 1.25-1.25v-12.5c0-.69-.56-1.25-1.25-1.25h-10z"/><path d="M3.75 17.5h-.834a2.086 2.086 0 0 1-2.083-2.083V2.083C.833.934 1.768 0 2.916 0h10C14.065 0 15 .934 15 2.083a.417.417 0 0 1-.834 0c0-.689-.56-1.25-1.25-1.25h-10c-.689 0-1.25.561-1.25 1.25v13.334c0 .689.561 1.25 1.25 1.25h.834a.417.417 0 0 1 0 .833zM15.416 14.167H8.75a.417.417 0 0 1 0-.833h6.666a.417.417 0 0 1 0 .833zM15.416 17.5H8.75a.417.417 0 0 1 0-.834h6.666a.417.417 0 0 1 0 .834z"/><path d="M15.416 10.833H8.75a.417.417 0 0 1 0-.833h6.666a.417.417 0 0 1 0 .833zM15.416 7.5H8.75a.417.417 0 0 1 0-.833h6.666a.417.417 0 0 1 0 .833z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>											
                                            </div>
                                            <p>File Name : <span>{ this.state.file.name }</span></p>
                                        </div>
                                        <div class="uploaded_file_details d-flex align-items-center justify-content-start">
                                            <div class="uploaded_file_icon">
                                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)" fill="#133159"><path d="M10 3.478a3.047 3.047 0 0 0-3.043 3.044A3.047 3.047 0 0 0 10 9.565a3.047 3.047 0 0 0 3.043-3.043A3.047 3.047 0 0 0 10 3.478zm0 5.217a2.177 2.177 0 0 1-2.174-2.173c0-1.199.976-2.174 2.174-2.174s2.174.975 2.174 2.174A2.177 2.177 0 0 1 10 8.695zm6.087-1.738a2.177 2.177 0 0 0-2.174 2.173c0 1.199.976 2.174 2.174 2.174a2.177 2.177 0 0 0 2.174-2.174 2.177 2.177 0 0 0-2.174-2.173zm0 3.478a1.305 1.305 0 1 1 .002-2.61 1.305 1.305 0 0 1-.002 2.61zM3.975 6.957c-1.2 0-2.174.975-2.174 2.173 0 1.199.975 2.174 2.174 2.174A2.177 2.177 0 0 0 6.149 9.13a2.176 2.176 0 0 0-2.174-2.173zm0 3.478a1.306 1.306 0 0 1 0-2.609 1.306 1.306 0 0 1 0 2.609zm6.025 0a5.659 5.659 0 0 0-5.652 5.652.435.435 0 0 0 .87 0A4.788 4.788 0 0 1 10 11.304a4.788 4.788 0 0 1 4.782 4.783.435.435 0 0 0 .87 0A5.659 5.659 0 0 0 10 10.435z"/><path d="M16.087 12.174c-.716 0-1.417.196-2.027.565a.435.435 0 0 0 .451.743 3.047 3.047 0 0 1 4.62 2.604.435.435 0 0 0 .869 0 3.918 3.918 0 0 0-3.913-3.912zm-10.147.564A3.915 3.915 0 0 0 0 16.087a.435.435 0 0 0 .87 0 3.047 3.047 0 0 1 4.618-2.605.435.435 0 0 0 .451-.744z"/></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>											
                                            </div>
                                            <p>Leads: <span>{ this.state.total }</span></p>
                                        </div>
                                        <div class="uploaded_file_details d-flex align-items-center justify-content-start">
                                            <div class="uploaded_file_icon">
                                                <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.383 3.895H11.17L9.733 2.193a.248.248 0 0 0-.19-.088H3.726c-.893 0-1.617.724-1.617 1.617v.472h-.493C.725 4.194.001 4.917 0 5.81v10.468c0 .893.724 1.617 1.617 1.617h14.656c.893 0 1.617-.724 1.617-1.617v-.472h.493c.893 0 1.617-.724 1.617-1.618V5.512c0-.893-.724-1.617-1.617-1.617zm-.994 11.424v.97a1.12 1.12 0 0 1-1.119 1.118H1.617A1.12 1.12 0 0 1 .5 16.288V5.82a1.12 1.12 0 0 1 1.118-1.119h5.715l1.436 1.702a.248.248 0 0 0 .191.088h7.328a1.12 1.12 0 0 1 1.102 1.11v7.718zm2.11-1.118a1.12 1.12 0 0 1-1.118 1.118h-.493V7.602c0-.894-.724-1.618-1.618-1.618H9.06L7.623 4.283a.248.248 0 0 0-.19-.089H2.61v-.472a1.12 1.12 0 0 1 1.118-1.118h5.707l1.436 1.701a.248.248 0 0 0 .191.089h7.328a1.12 1.12 0 0 1 1.118 1.118l-.01 8.689z" fill="#133159"/></svg>											
                                            </div>
                                            <p>Size: <span>{ formatFileSize(this.state.file.size) }</span></p>
                                        </div>
                                    </div>
                                    <p className="campaign-warn m-0">

                                    </p>
                                </div>
                            }

                            
                            {
                                (!this.state.file) && 
                                <div className="main_contain_detail_section">
                                    <div>
                                        <h6 class="justify-content-center main_content_main_section_heading center">Upload your contacts CSV file.</h6>
                                    </div>
                                    <div className="upload_section_container center">
                                            <div className="upload_section">
                                                <div className="upload_inner_section d-flex justify-content-center align-items-center">
                                                    <div className="upload_inner_section_content center">

                                                        <div class="upload_logo">
                                                            <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M31.2546 29.834H24.7895H23.0486H22.6727V21.1622H25.5088C26.228 21.1622 26.653 20.3449 26.228 19.7564L20.7111 12.1225C20.3596 11.6322 19.6322 11.6322 19.2808 12.1225L13.7638 19.7564C13.3388 20.3449 13.7556 21.1622 14.483 21.1622H17.3192V29.834H16.9432H15.2023H7.7074C3.41643 29.597 0 25.5839 0 21.2357C0 18.2362 1.62648 15.6207 4.0376 14.2067C3.81692 13.6101 3.70249 12.9726 3.70249 12.3024C3.70249 9.23738 6.17899 6.76088 9.24397 6.76088C9.90601 6.76088 10.5435 6.87531 11.1402 7.09598C12.9138 3.33628 16.7389 0.729004 21.1851 0.729004C26.9391 0.737177 31.6796 5.14257 32.219 10.7576C36.6408 11.5177 40 15.6125 40 20.2468C40 25.1998 36.1422 29.4908 31.2546 29.834Z" fill="#3C7EF3"/>
                                                            </svg>                                        
                                                        </div>

                                                        <div class="details">
                                                            <span>Drag & Drop files here OR</span>
                                                        </div>
                                                    
                                                        <div className="file-field input-field">
                                                            <div className="browse_file_button">
                                                                <span>Choose CSV file</span>
                                                                <CsvParse
                                                                    onDataUploaded={this.handleData}
                                                                    onError={this.handleError}
                                                                    render={onChange => <input type="file" onChange={onChange}
                                                                                            className="custom-file-input"
                                                                                            id="customFile" accept=".csv"/>}
                                                                />
                                                                {/* <label className="custom-file-label" htmlFor="customFile">Choose CSV file</label> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="attention_text">
                                                <span>Supports any .CSV file.</span>
                                            </div>
                                    </div>
                                </div>
                            }

                            
                            {
                                (this.state.data && this.state.data.length > 0) &&
                                <MapContact
                                    onSubmit={this.onFormSubmit}
                                    data={  this.state.data }
                                    callBack={ this.callBack }
                                />
                            }
                        </div>
                        </form>
                    </div>

                </React.Fragment>
            );
        }
    }


export default Wrapper
