import React, { useEffect } from "react";
import { Box, Checkbox, FormControl, FormControlLabel, Radio, Typography } from "@material-ui/core";
import { AGENCY_CARRIER_LOOKUP_CREDIT_TYPE, CARRIER_INFO_TYPES } from "../../../../constants/CoreConstants";
import { useForm, Controller } from "react-hook-form";
import FileUploader from "../../FileUploader";
import ActionType from "../../ActionTypes";
import numberToDollar from "../../../../helpers/numberToDollar";

const BulkSearch = ({ state, dispatch, onSelectFile }) => {
  const { control, watch, getValues } = useForm({
    mode: "onChange",
    values: {
      checkEmailAddress: false,
      checkPhoneNumber: false,
      checkDNC: false,
      skipTrace: false,
      find: "owner",
    },
  });

  useEffect(() => {
    const subscription = watch((value, { type }) => {
      if (type === "change") {
        console.log(value);
        dispatch({
          type: ActionType.UPDATE_STATE,
          payload: {
            carrierInfos: {
              emailVerificationType: value.checkEmailAddress ? CARRIER_INFO_TYPES.emailVerificationType : null,
              phoneVerificationType: value.checkPhoneNumber ? CARRIER_INFO_TYPES.phoneVerificationType : null,
              doNotCall: value.checkDNC ? CARRIER_INFO_TYPES.doNotCall : null,
              skipTrace: value.skipTrace
                ? value.find === "owner"
                  ? CARRIER_INFO_TYPES.skipTraceCurrentOwner
                  : CARRIER_INFO_TYPES.skipTraceCurrentResident
                : null,
            },
          },
        });
      }
    });
    return () => subscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  watch(["skipTrace"]);

  return (
    <Box p={2}>
      <Box style={{ marginBottom: "10px" }}>
        <Typography variant='body1'>Upload a list of contacts</Typography>
        <Typography variant='caption'>Select an option below uploading your list</Typography>
      </Box>
      <FormControl>
          {
              state.enabledServices.carrierLookup &&
              <Controller
                  control={control}
                  name='checkEmailAddress'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          control={<Checkbox color={"primary"} />}
                          label={`Validate Email Address (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.VERIFY_EMAIL]) ??
                              "x.xx"
                          })`}
                      />
                  )}
              />
          }

          {
              state.enabledServices.carrierLookup &&
              <Controller
                  control={control}
                  name='checkPhoneNumber'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          control={<Checkbox color={"primary"} />}
                          label={`Validate Phone Number (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.CARRIER]) ?? "x.xx"
                          })`}
                      />
                  )}
              />
          }

          {
              state.enabledServices.dnc &&
              <Controller
                  control={control}
                  name='checkDNC'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          control={<Checkbox color={"primary"} />}
                          label={`Check Do-Not-Call(DNC) status (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.DNC]) ?? "x.xx"
                          })`}
                      />
                  )}
              />
          }

          {
              state.enabledServices.skipTrace &&
              <Controller
                  control={control}
                  name='skipTrace'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          control={<Checkbox color={"primary"} />}
                          label={`Skip Trace (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.SKIP_TRACING]) ??
                              "x.xx"
                          })`}
                      />
                  )}
              />
          }
      </FormControl>
      {getValues("skipTrace") && (
        <Box px={4}>
          <FormControl style={{ flexDirection: "row" }}>
            <Controller
              control={control}
              name='find'
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  value='owner'
                  checked={field.value === "owner"}
                  control={<Radio color='primary' />}
                  label='Find current owner'
                />
              )}
            />
            <Controller
              control={control}
              name='find'
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  value='resident'
                  checked={field.value === "resident"}
                  control={<Radio color='primary' />}
                  label='Find current resident'
                />
              )}
            />
          </FormControl>
        </Box>
      )}

      <Box mt={2} mb={1}>
        <FileUploader onSelectFile={onSelectFile} />
      </Box>
    </Box>
  );
};

export default BulkSearch;
