import React, { useState, useEffect } from "react";
import Styles from "./style.module.css";
import Icons from "./icons";
import BootstrapTooltip from "./BootstrapTooltip";
import NewConfirmAlert from "../common/new-alert/NewConfirmAlert";
import { carrierLookupVerifyContactApi, getCarrierLookupCreditSettingsApi } from "../../api/contactApi";
import { connect } from "react-redux";
import { updateMultipleData } from "../../actions/contactAction";
import {
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
  LOOKUP_TYPE_EMAIL_LOOKUP,
} from "../../constants/CoreConstants";

const cleanNumber = (number) => {
  try{
    if(isNaN(number)){
      return "x.xx";
    }

    if (typeof number === "string") {
      number = parseFloat(number);
    }

    const parts = number.toString().split('.');
    if (parts.length === 1) {
      return number.toFixed(2).replace(/\.0*$/, '') + '.00';
    }
    const decimalPart = parts[1].length < 2 ? parts[1] + '0' : parts[1];
    return `${parts[0]}.${decimalPart}`;
  }catch(err){
    return "x.xx";
  }
};

const padZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

const App = (props) => {
  const statusCheckArray = {
    EMAIL: "email_validation_status",
    NUMBER: "number_validation_status",
    DNC_STATUS: "dnc_status",
  };
  const infoCheckArray = {
    EMAIL: "email_validation_infos",
    NUMBER: "number_validation_infos",
    DNC_STATUS: "dnc_validation_infos",
  };
  const defaultCategoryName = { EMAIL: "verify-email", NUMBER: "carrier", DNC_STATUS: "dnc" };
  const creditSettingType = { CALLER_NAME: 63, CARRIER: 64, VERIFY_EMAIL: 65, DNC: 66 };
  const [isValid, inValid, notVerified, verifying, doNotCall, verifiedOk] = [
    "VALID",
    "INVALID",
    "NOT_VERIFIED",
    "VERIFYING",
    "DO_NOT_CALL",
    "VERIFIED_OK",
  ];
  const [deliverable, undeliverable, risky, unknown] = ["deliverable", "undeliverable", "risky", "unknown"];
  const buttonTextArray = { NOT_VERIFIED: "Validate", VERIFYING: "Validating..." };
  const [validationInfo, setValidationInfo] = useState({ status: null, data: null });

  useEffect(() => {
    let contactAdditionalInformation = props.contact.contact_additional_informations;
    if (contactAdditionalInformation && contactAdditionalInformation[statusCheckArray[props.validationType]]) {
      let infos = { status: contactAdditionalInformation[statusCheckArray[props.validationType]], data: null };

      if (contactAdditionalInformation[infoCheckArray[props.validationType]]) {
        infos["data"] = contactAdditionalInformation[infoCheckArray[props.validationType]];
      }
      setValidationInfo(infos);
    } else {
      setValidationInfo({ status: notVerified, info: null });
    }
  }, [
    props.contact.id,
    props.contact.contact_additional_informations &&
      props.contact.contact_additional_informations[statusCheckArray[props.validationType]],
  ]); // eslint-disable-line

  const getLookupLabel = {
    EMAIL: "Email Lookup",
    NUMBER: "Number Lookup",
    DNC_STATUS: "Do-not-call Lookup",
  };
  const carrierLookupVerifyContact = async () => {
    if (validationInfo.status !== verifying) {
      if (!props.carrierLookupPrices) {
        getCarrierLookupCreditSettingsApi()
          .then((response) => {
            if (response.data && response.data.status === "success") {
              if (response.data && response.data.data) {
                props.updateMultipleData({ carrierLookupPrices: response.data.data });
                alertToVerify(response.data.data);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        alertToVerify(props.carrierLookupPrices);
      }
    } else {
      window.showNotification("ERROR", "Validating still in progress");
    }
  };

  const alertToVerify = (pricingInfo) => {
    let price;
    if (pricingInfo) {
      if (props.validationType === "EMAIL") {
        price = pricingInfo.find(({ type }) => type === creditSettingType["VERIFY_EMAIL"]);
        if (price) {
          price = price.value;
        }
      } else if (props.validationType === "NUMBER") {
        price = pricingInfo.find(({ type }) => type === creditSettingType["CARRIER"]);

        if (price) {
          price = price.value;
        }
      } else if (props.validationType === "DNC_STATUS") {
        price = pricingInfo.find(({ type }) => type === creditSettingType["DNC"]);

        if (price) {
          price = price.value;
        }
      }
    }

    if (price) {
      let validationParams;
      if (props.validationType === "EMAIL") {
        validationParams = {
          lookupType: LOOKUP_TYPE_EMAIL_LOOKUP,
          contact: {
            email: props.contact.email,
            id: props.contact.id,
          },
          categoryName: defaultCategoryName[props.validationType],
        };
      } else if (props.validationType === "NUMBER") {
        validationParams = {
          lookupType: LOOKUP_TYPE_CARRIER_LOOKUP,
          contact: {
            number: props.contact.number,
            id: props.contact.id,
          },
          categoryName: defaultCategoryName[props.validationType],
        };
      } else if (props.validationType === "DNC_STATUS") {
        validationParams = {
          lookupType: LOOKUP_TYPE_DNC_LOOKUP,
          contact: {
            number: props.contact.number,
            id: props.contact.id,
          },
          categoryName: defaultCategoryName[props.validationType],
        };
      }

      NewConfirmAlert({
        onSubmit: () => {
          setValidationInfo({ status: verifying, data: null });
          carrierLookupVerifyContactApi(validationParams)
            .then((response) => {
              if (response) {
                if (response.success) {
                  if (props.validationType !== "DNC_STATUS") {
                    if (response.status) {
                      if (response.data && response.data.carrierResponse) {
                        updateValidDataInGlobal(props.validationType, isValid, response.data.carrierResponse);
                        setValidationInfo({ status: isValid, data: response.data.carrierResponse });
                      } else {
                        updateValidDataInGlobal(props.validationType, isValid, null);
                        setValidationInfo({ status: isValid, data: null });
                      }
                      window.showNotification("SUCCESS", `${props.validationType} validated successfully`);
                    } else {
                      updateValidDataInGlobal(props.validationType, inValid, null);

                      setValidationInfo({ status: inValid, data: null });
                      window.showNotification("ERROR", `${props.validationType} validated successfully`);
                    }
                  } else {
                    if (response.status) {
                      if (response.data && response.data.dncStatus === "NO") {
                        updateValidDataInGlobal(props.validationType, verifiedOk, null);

                        setValidationInfo({ status: verifiedOk, data: null });
                      } else {
                        updateValidDataInGlobal(props.validationType, doNotCall, null);

                        setValidationInfo({ status: doNotCall, data: null });
                      }
                      window.showNotification("SUCCESS", `${props.validationType} validated successfully`);
                    } else {
                      updateValidDataInGlobal(props.validationType, notVerified, null);

                      setValidationInfo({ status: notVerified, data: null });
                      window.showNotification("ERROR", `${props.validationType} validated successfully`);
                    }
                  }
                } else {
                  updateValidDataInGlobal(props.validationType, notVerified, null);

                  setValidationInfo({ status: notVerified, data: null });
                  window.showNotification(
                    "ERROR",
                    response.message ?? `Something went wrong while validate ${props.validationType}`
                  );
                }
              } else {
                updateValidDataInGlobal(props.validationType, notVerified, null);

                setValidationInfo({ status: notVerified, data: null });

                window.showNotification("ERROR", response.message ?? `${props.validationType} Invalid`);
              }
            })
            .catch((err) => {
              updateValidDataInGlobal(props.validationType, notVerified, null);

              setValidationInfo({ status: notVerified, data: null });

              window.showNotification("ERROR", `Something went wrong while validate ${props.validationType}`);
            });
        },
        title: `${getLookupLabel[props.validationType]} will cost: ${cleanNumber(price)}`,
        description: "These credits will be deducted from your balance.",
        cancelText: "Cancel",
        submitText: "Validate",
        width: "480px",
      });
    } else window.showNotification("ERROR", "Please contact with system administrator");
  };

  const updateValidDataInGlobal = (type, status, data) => {
    let tmpContact = { ...props.contact };
    if (!tmpContact.contact_additional_informations){
      tmpContact.contact_additional_informations = {};
    }

    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${padZero(currentDate.getMonth() + 1)}-${padZero(currentDate.getDate())} ${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;

    if (type === "NUMBER") {
      if (tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations.number_validation_status = status;
        tmpContact.contact_additional_informations.number_validation_infos = data;
        if (status !== notVerified){
          tmpContact.contact_additional_informations.number_validation_date = formattedDate;
        }
      }
    } else if (type === "DNC_STATUS") {
      if (tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations.dnc_status = status;
        tmpContact.contact_additional_informations.dnc_validation_infos = data;
        if (status !== notVerified){
          tmpContact.contact_additional_informations.dnc_validation_date = formattedDate;
        }
      }
    } else if (type === "EMAIL") {
      if (tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations.email_validation_status = status;
        tmpContact.contact_additional_informations.email_validation_infos = data;
        if (status !== notVerified){
          tmpContact.contact_additional_informations.email_validation_date = formattedDate;
        }
      }
    }
    props.updateMultipleData({ contact: tmpContact });
  };

  const renderStatusChip = () => {
    switch (validationInfo.status) {
      case isValid:
        return props.validationType === "EMAIL" ? (
          validationInfo.data &&
          typeof validationInfo.data === "object" &&
          validationInfo.data.state !== undeliverable ? (
            <div
              className='d-flex justify-content-between align-items-center w-100 px-2 mt-2'
              style={{ minWidth: "200px" }}
            >
              <span>Status:</span>
              <span className={`${Styles.statusChip} ${Styles.statusChipValid}`}>Valid</span>
            </div>
          ) : (
            <div
              className='d-flex justify-content-between align-items-center w-100 px-2 mt-2'
              style={{ minWidth: "200px" }}
            >
              <span>Status:</span>
              <span className={`${Styles.statusChip} ${Styles.statusChipInvalid}`}>Invalid</span>
            </div>
          )
        ) : (
          <div
            className='d-flex justify-content-between align-items-center w-100 px-2 mt-2'
            style={{ minWidth: "200px" }}
          >
            <span>Status:</span>
            <span className={`${Styles.statusChip} ${Styles.statusChipValid}`}>Valid</span>
          </div>
        );
      case inValid:
        return (
          <div
            className='d-flex justify-content-between align-items-center w-100 px-2 mt-2'
            style={{ minWidth: "200px" }}
          >
            <span>Status:</span>
            <span className={`${Styles.statusChip} ${Styles.statusChipInvalid}`}>Invalid</span>
          </div>
        );
      default:
        return <div />;
    }
  };

  const renderMessage = () => {
    if (validationInfo.data && typeof validationInfo.data === "object") {
      try {
        return (
          <div>
            {(props.validationType === "EMAIL" || props.validationType === "NUMBER") && renderStatusChip()}
            <ul className='px-2'>
              {Object.entries(validationInfo.data).map(([key, value]) => {
                const result = key.replace(/([A-Z])/g, " $1");
                const title = result.charAt(0).toUpperCase() + result.slice(1);

                return (
                  <li>
                    <span style={{ fontWeight: 700 }}>{title}</span>: {value}
                  </li>
                );
              })}
            </ul>
          </div>
        );
      } catch (e) {
        return "No Data";
      }
    } else return "No Data";
  };

  const renderView = () => {
    switch (validationInfo.status) {
      case isValid:
        return (
          <BootstrapTooltip arrow placement='top-center' title={renderMessage()}>
            <span className='d-flex align-items-center' {...props}>
              {props.validationType === "EMAIL"
                ? validationInfo.data &&
                  typeof validationInfo.data === "object" &&
                  validationInfo.data.state !== undeliverable
                  ? Icons.valid
                  : Icons.invalid
                : Icons.valid}
            </span>
          </BootstrapTooltip>
        );

      case inValid:
        return (
          <BootstrapTooltip arrow placement='top-center' title={inValid}>
            <span className='d-flex align-items-center' {...props}>
              {Icons.invalid}
            </span>
          </BootstrapTooltip>
        );
      case doNotCall:
        return (
          <BootstrapTooltip arrow placement='top-center' title={"DNC Enabled"}>
            <span className='d-flex align-items-center' {...props}>
              {Icons.doNotCall}
            </span>
          </BootstrapTooltip>
        );
      case verifiedOk:
        return (
          <BootstrapTooltip arrow placement='top-center' title={"DNC Verified OK"}>
            <span className='d-flex align-items-center' {...props}>
              {Icons.verifiedOk}
            </span>
          </BootstrapTooltip>
        );
      default:
        return (
          <span>
            <div className={Styles.button} onClick={carrierLookupVerifyContact}>
              {props.validationType === "DNC_STATUS"
                ? validationInfo.status === "NOT_VERIFIED"
                  ? "Check DNC"
                  : "Validating..."
                : buttonTextArray[validationInfo.status]}
            </div>
          </span>
        );
    }
  };

  return renderView();
};
const mapStateToProps = (state) => {
  return {
    carrierLookupPrices: state.contactReducer.carrierLookupPrices,
    contact: state.contactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
