import { FILE_IMPORT_STEP } from "../Constants";

const StepProgressBar = (props) => {
  return (
    <div className="process_number_section">
      <div className="process_number_inner_section">
        <div className="process_number_box d-flex justify-content-start align-items-center center-align">
          <div
            className={`number_box number_box_2 ${
              props.currentStep >= FILE_IMPORT_STEP.STEP_IMPORT_MAPPING
                ? "number_checked"
                : ""
            }`}
          >
            <div className="done_step">
              <svg
                width="28"
                height="22"
                viewBox="0 0 28 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.4913 3.75459C27.6895 3.94933 27.691 4.26832 27.4945 4.4648L10.4478 21.5115C10.2531 21.7062 9.93745 21.7068 9.74198 21.5127L0.511607 12.3483C0.313919 12.1521 0.314762 11.8321 0.513482 11.6369L3.8493 8.35962C4.04421 8.16814 4.35674 8.16857 4.55111 8.36061L9.74199 13.489C9.93759 13.6822 10.2525 13.6813 10.447 13.4869L23.4525 0.481302C23.6466 0.287259 23.9607 0.285869 24.1565 0.478189L27.4913 3.75459Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="number">1</div>
            <div className="number_name">
              <span
                className={
                  props.currentStep >= FILE_IMPORT_STEP.STEP_IMPORT_MAPPING
                    ? "name_checked"
                    : ""
                }
              >
                Mapping
              </span>
            </div>
          </div>

          <hr
            className={`line_2 ${
              props.currentStep >= FILE_IMPORT_STEP.STEP_IMPORT_MAPPING
                ? "line_checked"
                : ""
            }`}
          />

          {/* <div
            className={`number_box number_box_6 ${
              props.currentStep >= FILE_IMPORT_STEP.STEP_UPLOAD
                ? "number_checked"
                : ""
            }`}
          >
            <div className="done_step">
              <svg
                width="28"
                height="22"
                viewBox="0 0 28 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.4913 3.75459C27.6895 3.94933 27.691 4.26832 27.4945 4.4648L10.4478 21.5115C10.2531 21.7062 9.93745 21.7068 9.74198 21.5127L0.511607 12.3483C0.313919 12.1521 0.314762 11.8321 0.513482 11.6369L3.8493 8.35962C4.04421 8.16814 4.35674 8.16857 4.55111 8.36061L9.74199 13.489C9.93759 13.6822 10.2525 13.6813 10.447 13.4869L23.4525 0.481302C23.6466 0.287259 23.9607 0.285869 24.1565 0.478189L27.4913 3.75459Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="number">2</div>
            <div className="number_name6 last-item">
              <span
                className={
                  props.currentStep >= FILE_IMPORT_STEP.STEP_UPLOAD
                    ? "name_checked"
                    : ""
                }
              >
                Upload
              </span>
            </div>
          </div> */}

          {/* <hr
            className={`line_2 ${
              props.currentStep >= FILE_IMPORT_STEP.STEP_UPLOAD
                ? "line_checked"
                : ""
            }`}
          /> */}

          <div
            className={`number_box number_box_6 ${
              props.currentStep >= FILE_IMPORT_STEP.STEP_SUCCESS
                ? "number_checked"
                : ""
            }`}
          >
            <div className="done_step">
              <svg
                width="28"
                height="22"
                viewBox="0 0 28 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M27.4913 3.75459C27.6895 3.94933 27.691 4.26832 27.4945 4.4648L10.4478 21.5115C10.2531 21.7062 9.93745 21.7068 9.74198 21.5127L0.511607 12.3483C0.313919 12.1521 0.314762 11.8321 0.513482 11.6369L3.8493 8.35962C4.04421 8.16814 4.35674 8.16857 4.55111 8.36061L9.74199 13.489C9.93759 13.6822 10.2525 13.6813 10.447 13.4869L23.4525 0.481302C23.6466 0.287259 23.9607 0.285869 24.1565 0.478189L27.4913 3.75459Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className="number">2</div>
            <div className="number_name6 last-item">
              <span
                className={
                  props.currentStep >= FILE_IMPORT_STEP.STEP_SUCCESS
                    ? "name_checked"
                    : ""
                }
              >
                Complete
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepProgressBar;
