import { Box, Checkbox, Chip, FormControlLabel, TableCell, TableRow } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import Utils from "../../../../helpers/Utils";
import Styles from "../FileReportDetails.module.css";
import {
    getDncStatusIcon,
    getEmailValidationStatusIcon,
    getLandlineMobileStatus, getNumberValidationStatusIcon,
    getSkipTraceStatusLabelAndColor
} from "../FileReportDetailsSingle";
const userTimezone = Utils.getAccountData('userTimezoneRaw');

const NonAddressComplexItem = ({ contacts, isSelected, onSelect, collectIds, allSelected, setResetSelect, resetSelect }) => {
    const [selectedSubContactIds, setSelectedSubContactIds] = useState([]);

    useEffect(() => {
        if (contacts) {
            Object.entries(contacts).forEach(([id, iContact]) => {
                collectIds(id);
                if (iContact && iContact.additional_contacts) {
                    iContact.additional_contacts.forEach((additionalContact) => {
                        collectIds(additionalContact.id);
                    });
                }
            });
        }
    }, [contacts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (allSelected) {
            if (selectedSubContactIds[0]) {
                setSelectedSubContactIds([]);
            }
        } else if (!allSelected) {
            if (selectedSubContactIds[0]) {
                setSelectedSubContactIds([]);
            }
        }
    }, [allSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (resetSelect) {
            setSelectedSubContactIds([]);
            setResetSelect(false);
        }
    }, [resetSelect]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleInternalIds = (id) => {
        onSelect(id);
        if (selectedSubContactIds.includes(id)) {
            setSelectedSubContactIds(
                selectedSubContactIds.filter(
                    (selectedFileContactId) => selectedFileContactId !== id
                )
            );
        } else {
            setSelectedSubContactIds([...selectedSubContactIds, id]);
        }
    };

    return (
        <Fragment>
            {
                Object.entries(contacts).map(([id, contact]) => (
                    <SkipTraceComplexInnerItem
                        contact={contact}
                        index={id}
                        isSelected={isSelected}
                        onSelect={handleInternalIds}
                    />
                ))
            }
        </Fragment>
    );
};

export default NonAddressComplexItem;

const SkipTraceComplexInnerItem = ({ contact, index, onSelect, isSelected }) => {
    let enhanceCreatedAt = 0;
    if (contact['enhance_data_search_clean_contact_history'] && contact['enhance_data_search_clean_contact_history']['created_at']) {
        enhanceCreatedAt = contact['enhance_data_search_clean_contact_history']['created_at'];
        enhanceCreatedAt = window.globalTimezoneConversionToDifferentTimezone(enhanceCreatedAt, 'UTC', userTimezone, 'M-D-Y', false);
    }

    return (
        <Fragment>
            <TableRow key={index} className={"stt-address-row"}>
                <TableCell>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size={"small"}
                                    color={"primary"}
                                />
                            }
                            checked={isSelected(contact.id)}
                            onChange={(event, checked) => {
                                onSelect(contact.id)
                            }}
                        />
                        {contact.first_name} {contact.last_name}
                    </Box>
                </TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell className={Styles.textAlignCenter}>
                    {getEmailValidationStatusIcon(contact.email_valid_status)}
                </TableCell>
                <TableCell>{contact.number}</TableCell>
                <TableCell className={Styles.textAlignCenter}>
                    {getNumberValidationStatusIcon(contact.number_valid_status)}
                </TableCell>
                <TableCell className={Styles.textAlignCenter}>
                    <Chip
                        label={getLandlineMobileStatus(contact.number_state)}
                        className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                        size={"small"}
                    />
                </TableCell>
                <TableCell className={Styles.textAlignCenter}>
                    {getDncStatusIcon(contact.dnc_status)}
                </TableCell>
                <TableCell className={Styles.textAlignCenter}>
                    {
                        enhanceCreatedAt !== 0 &&
                        <Chip
                            size={"small"}
                            variant={"default"}
                            className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                            style={{
                                backgroundColor: getSkipTraceStatusLabelAndColor("TRACED")[1],
                            }}
                            label={enhanceCreatedAt !== 0 ? enhanceCreatedAt : ''}
                        />
                    }

                </TableCell>
            </TableRow>
            {
                contact.additional_contacts &&
                contact.additional_contacts.map((additionalContact, iindex) => (
                    <SkipTraceComplexInnerItem
                        contact={additionalContact}
                        index={iindex}
                        isSelected={isSelected}
                        onSelect={onSelect}
                    />
                ))
            }
        </Fragment>
    );
};