import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
    Box,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    makeStyles,
    Modal,
    Paper,
    Typography
} from "@material-ui/core";
import {Close as CloseIcon} from "@material-ui/icons";
import Styles from "./EnhanceDataAlert.module.css";
import Utils from "../../../../../helpers/Utils";
import {LOOKUP_TYPE} from "../../../../../constants/CoreConstants";
import {getCarrierLookupCreditSettingsApi} from "../../../../../api/contactApi";
import {updateMultipleData} from "../../../../../actions/contactAction";
import {EnhancedDataIcon} from "../icon";
import {cleanContactsActionsV1} from "../../../../../api/cleanDataApi";
import LoadingButton from "../../../../common/LoadingButton";

const useStyles = makeStyles((theme) => {
    return {
        root: {
            "&.MuiButton-root": {
                backgroundColor: "#008945",
                padding: "10px 20px",
                borderRadius: "4px"
            },
            "&.MuiButton-text": {
                color: "grey"
            },
            "&.MuiButton-contained": {
                color: "white"
            },
            "&.MuiButton-outlined": {
                color: "brown"
            }
        }
    };
});

const cleanNumber = (number) => {
    try{
        if(isNaN(number)){
            return "x.xx";
        }

        if (typeof number === "string") {
            number = parseFloat(number);
        }

        const parts = number.toString().split('.');
        if (parts.length === 1) {
            return number.toFixed(2).replace(/\.0*$/, '') + '.00';
        }
        const decimalPart = parts[1].length < 2 ? parts[1] + '0' : parts[1];
        return `${parts[0]}.${decimalPart}`;
    }catch(err){
        return "x.xx";
    }
};

const EnhancedData = ({ isOpen, onClose, tab, carrierLookupPrices, updateMultipleData, selectedFileContactIds, filter }) => {
    const classes = useStyles();
    const LOOKUP_CREDIT_TYPE_MAP = {
        CARRIER_LOOKUP: 64,
        EMAIL_LOOKUP: 65,
        DNC_LOOKUP: 66
    };
    const [submitting, setSubmitting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedLookupType, setSelectedLookupType] = useState([]);

    useEffect(() => {
        if (!carrierLookupPrices) {
            setLoading(true);
            getCarrierLookupCreditSettingsApi()
                .then((response)=>{
                    if (response.data && response.data.status === "success") {
                        if (response.data && response.data.data) {
                            updateMultipleData({ carrierLookupPrices: response.data.data });
                        }
                    }
                }).catch((err)=>{
                updateMultipleData({ carrierLookupPrices: null });
            }).finally(()=>{
                setLoading(false);
            });
        }
    }, []);

    const getLookupPrice = (lookupType) => {
        try{
            if (carrierLookupPrices){
                let price = carrierLookupPrices.find((carrierLookupPrice)=>(carrierLookupPrice.type === LOOKUP_CREDIT_TYPE_MAP[lookupType]));
                if (price){
                    return cleanNumber(price.value);
                }
            }
            return "0.00";
        }catch (err){
            return "0.00";
        }
    };

    const onLookupTypeChange = (lookupType) => {
        let spSelectedLookupType = [...selectedLookupType];
        if (selectedLookupType.includes(lookupType)){
            let index = selectedLookupType.indexOf(lookupType);
            spSelectedLookupType.splice(index,1);
            setSelectedLookupType(spSelectedLookupType);
        }else {
            spSelectedLookupType.push(lookupType);
            setSelectedLookupType(spSelectedLookupType);
        }
    };

    const onSubmit = async () => {
        if (!selectedLookupType[0]){
            window.showNotification("ERROR", "Please select a lookup type");
            return;
        }

        setSubmitting(true);

        try{

            const payload = {
                filterType: "SEGMENT",
                actions: selectedLookupType,
                cleanContactIds: selectedFileContactIds,
                pageSource: tab
            };

            if (filter.validNumbers || filter.invalidNumbers) {
                let numberStatus = [];

                if (filter.validNumbers){
                    numberStatus.push("VALID");
                }

                if (filter.invalidNumbers){
                    numberStatus.push("INVALID");
                }

                payload.numberStatus = numberStatus;
                payload.filterType = "SEGMENT";
            }

            if (filter.validEmails || filter.invalidEmails) {
                let emailStatus = [];

                if (filter.validEmails){
                    emailStatus.push("VALID");
                }

                if (filter.invalidEmails){
                    emailStatus.push("INVALID");
                }

                payload.emailStatus = emailStatus;
                payload.filterType = "SEGMENT";
            }

            if (filter.dncOn || filter.dncOff) {
                let dncStatus = [];

                if (filter.dncOn){
                    dncStatus.push("DO_NOT_CALL");
                }

                if (filter.dncOff){
                    dncStatus.push("VERIFIED_OK");
                }

                payload.dncStatus = dncStatus;
                payload.filterType = "SEGMENT";
            }

            if (filter.skipTrace){
                payload.skipTraceStatus = ["TRACED"];
                payload.filterType = "SEGMENT";
            }

            if (filter.landline || filter.mobile){
                let numberType = [];

                if (filter.landline){
                    numberType.push("landline");
                }

                if (filter.mobile){
                    numberType.push("mobile");
                }

                payload.numberType = numberType;
                payload.filterType = "SEGMENT";
            }

            const res  = await cleanContactsActionsV1(payload);
            if (res && res.success){
                window.showNotification("success", res.message);
            }else {
                window.showNotification("error", "Unable to validate. Please contact with support");
            }

            setSubmitting(false);
            onClose();
            setSelectedLookupType([]);
        }catch (err){
            console.log(err)
            setSubmitting(false);
            window.showNotification("error", "Unable to validate. Please contact with support");
        }
    };

    return (
        <Modal
            open={isOpen}
            onClose={() => {

            }}
            aria-labelledby="enhance-modal-title"
            aria-describedby="enhance-modal-description"
            className={`${Styles.modalWrapper} global-xs-modal`}
        >
            <Paper className={Styles.modalInner}>
                <Box component="div" className={Styles.headerWrapper}>
                    <Box component="div">
                        <EnhancedDataIcon/>
                    </Box>
                    <Typography className={Styles.headerSubTitle}>
                        Having accurate, up-to-date data is key to a successful outreach campaign.
                        <br/>Validate and append your data with a few clicks below.
                    </Typography>
                    <Box className={Styles.headerClose} onClick={onClose}>
                        <CloseIcon />
                    </Box>
                </Box>
                <Box component="div" className={Styles.paddingWrp}>
                    <Box component="div" className={Styles.modalBody} style={{ position: "relative" }}>
                        {
                            loading &&
                            <Box component="div" className={Styles.modalBackdrop}>
                                <CircularProgress/>
                            </Box>
                        }

                        <Box component="div" style={{ display: "flex", gap: "60px" }}>
                            <Box component="div" style={{ marginTop: "18px" }}>
                                <Typography variant="h6" style={{ fontWeight: 600 }}>Make a selection:</Typography>
                                <Typography variant="caption">(Choose all that apply)</Typography>
                            </Box>

                            <Box component="div">
                                <FormControl>
                                    {
                                        Utils.getAccountData("carrierLookup") &&
                                        <Fragment>
                                            <FormControlLabel
                                                checked={selectedLookupType.includes(LOOKUP_TYPE.EMAIL_LOOKUP)}
                                                name={LOOKUP_TYPE.EMAIL_LOOKUP}
                                                onChange={()=>{onLookupTypeChange(LOOKUP_TYPE.EMAIL_LOOKUP)}}
                                                control={<Checkbox color="primary" />}
                                                label={`Validate Each Email Address ($${getLookupPrice(LOOKUP_TYPE.EMAIL_LOOKUP)})`}
                                            />
                                            <FormControlLabel
                                                checked={selectedLookupType.includes(LOOKUP_TYPE.CARRIER_LOOKUP)}
                                                name={LOOKUP_TYPE.CARRIER_LOOKUP}
                                                onChange={()=>{onLookupTypeChange(LOOKUP_TYPE.CARRIER_LOOKUP)}}
                                                control={<Checkbox color="primary" />}
                                                label={`Validate Each Phone Number ($${getLookupPrice(LOOKUP_TYPE.CARRIER_LOOKUP)})`}
                                            />
                                        </Fragment>
                                    }
                                    {
                                        Utils.getAccountData("dnc") &&
                                        <FormControlLabel
                                            checked={selectedLookupType.includes(LOOKUP_TYPE.DNC_LOOKUP)}
                                            name={LOOKUP_TYPE.DNC_LOOKUP}
                                            onChange={()=>{onLookupTypeChange(LOOKUP_TYPE.DNC_LOOKUP)}}
                                            control={<Checkbox color="primary" />}
                                            label={`Check Each Do-Not-Call(DNC) status ($${getLookupPrice(LOOKUP_TYPE.DNC_LOOKUP)})`}
                                        />
                                    }
                                </FormControl>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={Styles.modalFooter}>
                        <LoadingButton
                            size="large"
                            onClick={onSubmit}
                            className={`${classes.root} ${Styles.submitButton}`}
                            variant="contained"
                            disabled={submitting}
                            loading={submitting}
                            loadingText={"Submitting..."}
                            text={"Submit"}
                        />
                    </Box>
                </Box>
            </Paper>
        </Modal>
    );
}
const mapStateToProps = state => {
    return {
        carrierLookupPrices: state.contactReducer.carrierLookupPrices,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(EnhancedData);