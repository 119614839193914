import React from "react";
import { Box, Divider, Typography, makeStyles, styled } from "@material-ui/core";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";

const customTitleStyles = makeStyles((theme) => ({
  titleStyle: {
    fontSize: 16,
    fontWeight: 600,
    color: "var(--dark_blue)",
  },
}));

const CustomTitleV2Wrapper = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,

  "& .titleStyle": {
    fontSize: 14,
    fontWeight: 600,
    color: "var(--dark_blue)",
  }
}));

const CustomTitleV2 = ({ title = "Default Title", sx, className, children }) => {
  const { flexCenterBetween, flexCenter } = LeftSideV2Styles();
  const { titleStyle } = customTitleStyles();
  return (
    <CustomTitleV2Wrapper className={className} style={sx}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box className={flexCenterBetween} sx={{ flexWrap: "wrap", paddingRight: "10px" }}>
          <Typography variant='body2' className={'titleStyle'}>
            {title}
          </Typography>
        </Box>

        <Box flex={1}>
          <Divider />
        </Box>
        <Box>{children}</Box>
      </Box>
    </CustomTitleV2Wrapper>
  );
};

export default CustomTitleV2;
