import React, { Fragment, useContext } from "react";
import Styles from "./FileUploader.module.css";
import CsvParse from "./lib";
import { DataCleanContext } from "./StateProvider";
import ActionTypes from "./ActionTypes";
import { TAB_CLEAN_LIST } from "./Constants";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
// import { Link } from "@material-ui/core";
import { CoreButton } from "./enhanceData/commons/CoreButton";

const FileUploader = ({ onSelectFile, center = false }) => {
  const { state, dispatch } = useContext(DataCleanContext);

  const DataUploaded = (data, file) => {
    let validateData = [];
    let checkedData = [];
    if (state.data.length > 0) {
      let dataLength = state.data[0].length;
      for (let i = 0; i < dataLength; i++) {
        validateData["id" + i] = false;
        validateData["skipId" + i] = false;
        checkedData[i] = {};
      }
    }

    dispatch({
      type: ActionTypes.UPDATE_STATE,
      payload: {
        activeTab: TAB_CLEAN_LIST.UPLOAD_FILE,
        data: data,
        file: file,
        validateData: validateData,
        checkedData: checkedData,
        total: data.length,
      },
    });
    onSelectFile(true);
  };

  const isInvalid = () => {
    const selections = state.carrierInfos;
    return (
      selections.emailVerificationType === null &&
      selections.phoneVerificationType === null &&
      selections.doNotCall === null &&
      selections.skipTrace === null
    );
  };

  return (
    <Fragment>
      <CsvParse
        onDataUploaded={DataUploaded}
        onError={() => {}}
        render={(onChange) => (
          <div className={center ? `${Styles.wrapper} ${Styles.center}` : Styles.wrapper}>
            <div
              className={Styles.fileTray}
              onDragOver={(event) => {
                event.preventDefault();
              }}
              onDrop={(event) => {
                event.preventDefault();
                onChange({
                  target: {
                    files: event.dataTransfer.files,
                  },
                });
              }}
              onClick={(e) => {
                const fileInput = document.querySelector('input[type="file"]');
                if (isInvalid()) {
                  window.showNotification("error", "Please select at least one option.");
                  return;
                }
                if (fileInput && e.target.tagName === "DIV") {
                  fileInput.click();
                }
              }}
            >
              <CloudUploadIcon color={"primary"} style={{ fontSize: 50 }} />
              <Typography variant={"subtitle1"} style={{ fontSize: 16, color: "#000080" }}>
                <b>Drag and drop</b> your files here or <b>click here</b> to import your list
              </Typography>
              <Typography variant={"body2"} style={{ color: "#000080" }}>
                (CSV and TXT files are supported)
              </Typography>
              <CoreButton
                variant={"contained"}
                className={Styles.uploadButton}
                backgroundColor={"#7ab5fcc4"}
                size={"large"}
                onClick={(e) => {
                  if (isInvalid()) {
                    window.showNotification("error", "Please select at least one option.");
                    e.preventDefault();
                  }
                }}
              >
                Choose CSV file <input type='file' accept='.csv' onChange={onChange} />
              </CoreButton>
            </div>
            <Typography variant='body2' style={{ textAlign: "center", marginTop: "10px" }}>
              Note: Make sure your file doesn't have a header before uploading
            </Typography>
            {/* <Link variant='body2' href=''>
              See sample .csv file
            </Link> */}
          </div>
        )}
      />
    </Fragment>
  );
};

export default FileUploader;
