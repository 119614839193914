import React, { useEffect, useReducer} from 'react';
import GlobalModal from '../../globals/Modal/GlobalModal';
import { getDialingList, addContactsToAutoDialingList } from '../../../api/autoDialerApi';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ListSkeleton from "../../common/Skeletons/ListSkeleton";
import Api from "../../../constants/Api";
import './modal_add_to_auto_dialer.css';
import {useHistory} from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';

const EachDialingList = (props) => {
    return (
        <li>
            <label>
                <input onChange={props.selectDialingList} type="radio" className="styled-checkbox filled-in" name="example_5" value={props.data.id}/>
                <span>{props.data.title}</span>
                <span/>
            </label>
        </li>
    )
};
const ModalAddToAutoDialer = props => {
    const history = useHistory();
    let searchTimeout = null;
    let isScroll = false;

    const reducerFunction = (state,data)=>{

        switch(data.type){

            case 'updateState':
                return {...state,...data.payload}
            default:
                return data.payload;
        }
    }
    const [stateInfo, dispatchStateInfo] = useReducer(reducerFunction,
        {
            autoDialingLists: [],
            submitEnable: false,
            loading: true,
            selectedDialingListId: null,
            buttonText: 'Add Contacts',
            searchKey: '',
            nextPageUrl: null,
        })


    useEffect(()=>{
        if (!stateInfo.autoDialingLists.length) {
            fetchAutoDialingList()
        }
    },[])

    useEffect(()=>{
        dispatchStateInfo({type: 'updateState', payload: {loading: true}});
        isScroll = false;
        fetchAutoDialingList();
    },[stateInfo.searchKey])

    const fetchAutoDialingList = () => {
        let url = stateInfo.nextPageUrl ?? Api.getDialingList;

        getDialingList({searchKey: stateInfo.searchKey, perPage: 20}, url)
            .then(response => {
                dispatchStateInfo({type: 'updateState', payload: {loading: false, 
                    autoDialingLists: isScroll ? [...stateInfo.autoDialingLists, ...response.data.data] : response.data.data,
                    nextPageUrl: response.data.next_page_url}});
            })
            .catch(error => {
                dispatchStateInfo({type: 'updateState', payload: {loading: false}})
                window.showNotification('ERROR', 'Oops! Something went wrong. Please try again');
            })
    }

    const handleRadio = (e) => {
        dispatchStateInfo({type: 'updateState', payload:{
            selectedDialingListId: e.target.value,
            submitEnable: true
        }});
    };

    const submitAddToAutoDialerForm = () => {

            let contactWithoutNumber = [];
            let updatedContactList = [...props.contactIds] ;
            let eligibleIds = []
            props.contactIds.forEach((contactId, index)=>{
                let contact = props.contactList.find(contact => contact.id === contactId);
                if (!contact.number || contact.number=== '') {
                    contactWithoutNumber.push(contactId)
                    delete(updatedContactList[index]);
                }
            })

            if (contactWithoutNumber.length > 0) {
                confirmAlert({
                    title: 'Attention Required!!',
                    message: contactWithoutNumber.length+' contacts don\'t have number and number is required!!',
                    buttons: [
                        {
                            label: 'continue anyway',
                            onClick: () => {

                                addContactsInList(props.contactIds)
                            }
                        },
                        {
                            label: 'remve uneligible numbers and add in list',
                            onClick: () => {
                                eligibleIds = updatedContactList.filter(item => {

                                    return item ?? false;
                                })
                                addContactsInList(eligibleIds)

                            }
                        }
                    ]
                })
            }else {
                addContactsInList(props.contactIds)

            }

    }

    const addContactsInList=(contactIds)=>{

        if (stateInfo.submitEnable) {
            addContactsToAutoDialingList({listId: stateInfo.selectedDialingListId, contactIds: contactIds})
                .then(response => {
                    if (response.data.status) {
                        props.onClose();
                        window.showNotification('SUCCESS', response.data.message);
                        history.push('/auto-dialer');
                    } else {
                        window.showNotification('ERROR', response.data.message);
                    }
                })
                .catch(error => {
                    console.error(error);
                    window.showNotification('ERROR', 'Oops! Something went wrong. Please try again');
                });
        }
    }

    const renderDialingList = () => {
        if (stateInfo.autoDialingLists.length) {
            return stateInfo.autoDialingLists.map((data, index)=> {
                return (<EachDialingList selectDialingList={handleRadio} data={data} key={index}/>)
            })
        } else {
            return <p>No list found in the system!</p>
        }
    }

    const changeSearchKey = e => {
        if (searchTimeout) {
            window.clearTimeout(searchTimeout)
        }
        searchTimeout = window.setTimeout(() => {
            dispatchStateInfo({type : 'updateState', payload : { searchKey: e.target.value, nextPageUrl: null}})
        }, 700);
    }

    const scrollDialingList = e => {
        if(stateInfo.nextPageUrl && Math.round(e.target.scrollTop + e.target.clientHeight, 10) >= Math.round(e.target.scrollHeight, 10)) {
            isScroll = true;
            fetchAutoDialingList();
        }
    }

    return (
        <GlobalModal {...props}
            title={"Add Contacts To Auto Dialing List"}
            buttonText={stateInfo.buttonText}
            buttonIcon={<FileCopyIcon/>}
            onSubmit={submitAddToAutoDialerForm}
            hideFooter={false}
            modalClass={'manage-tag-modal modal-assign-campaign  activity__modal_overflow update' }
            previousButton={false}
            submitButtonDisable={(stateInfo.loading || !stateInfo.submitEnable) ? true : false}
            >

            <div className="contact-to-campaign contact-to-dialing-list">
                <div className="campaign-search-list">
                    <p className='text-danger'>Contacts which has no number will not be added to Dialing list</p>
                    <div className="campaign-search form">
                        <div className="form-group m-0">
                            <div className="m-input-icon m-input-icon--right">
                                <input onChange={(e) => changeSearchKey(e)} type="text" className="form-control m-input customFormControlField alt" placeholder="Select Or Search Dialing List"/>
                                <span className="m-input-icon__icon m-input-icon__icon--right">
                                <span>
                                    <i className="flaticon-search-1"/>
                                </span>
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="upload-single-item-main2 upload-single-item-main">
                        <ul className="awesome__scroll_bar" onScroll={(e) => scrollDialingList(e)}>
                            {stateInfo.loading ?
                                <ListSkeleton number={10} width='100%'/>
                                :
                                renderDialingList()
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </GlobalModal>
    )
}

export default ModalAddToAutoDialer;
