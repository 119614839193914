const mapData = (data) => {
    const rowsArr = [];

    const maxRow = 3;
    data.slice(0, maxRow)?.forEach((rowData, rowIndex) => {
        if (rowIndex >= maxRow) {
            return;
        }
        if (rowIndex === 0) {
            rowData?.forEach((eachData, dataIndex) => {
                rowsArr.push({
                    index: dataIndex,
                    fields: [eachData],
                });
            });
        } else {
            rowData.forEach((eachData, dataIndex) => {
                if (rowsArr[dataIndex] !== undefined && rowsArr[dataIndex] != null) {
                    rowsArr[dataIndex].fields.push(eachData);
                }
            });
        }
    });
    const mappedData = getMappedData(rowsArr);

    return {
        mappedRows: mappedData.mappedRows,
        unmappedRows: mappedData.unmappedRows,
        selectedRows: mappedData.selectedRows,
        submitData: mappedData.submitData,
    };
};

const getMappedData = (allRows) => {
    const mappedRows = [];
    const unmappedRows = [];
    const submitData = [];
    const selectedRows = {};

    allRows.forEach((row) => {
        let has = false;
        for (const data of row.fields) {
            if (isPhoneNumber(data) && !Object.values(selectedRows).includes('number')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'number';
                has = true;
                break;
            } else if (isEmail(data) && !Object.values(selectedRows).includes('email')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'email';
                has = true;
                break;
            } else if (isZip(data) && !Object.values(selectedRows).includes('zip')) {
                mappedRows.push(row);
                selectedRows[row.index] = 'zip';
                has = true;
                break;
            }
        }
        if (!has) {
            unmappedRows.push(row);
            submitData[row.index] = {
                map: row.index,
                data: null,
            };
        } else {
            submitData[row.index] = {
                map: row.index,
                data: selectedRows[row.index],
            };
        }
    });

    return {
        mappedRows: mappedRows,
        unmappedRows: unmappedRows,
        selectedRows: selectedRows,
        submitData: submitData,
    };
};

const isPhoneNumber = (phone) => {
    return phone.match(/^\+?[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/);
};

const isEmail = (email) => {
    const re = /^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    return re.test(String(email).toLowerCase());
};

const isZip = (zip) => {
    return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip);
};

const mapCSVImportHelper = {
    mapData,
};

export default mapCSVImportHelper;