import React from 'react';
import {Box, CircularProgress, Typography} from '@material-ui/core';

const Loading = ({ loaderText=null }) => {
    return (
        <Box style={{
            height: '221.5px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            <CircularProgress />
            {
                loaderText &&
                <Typography
                    style={{ marginTop: '80px' }}
                    component={'p'}
                >
                    {loaderText}
                </Typography>
            }
        </Box>
    );
}

export default Loading;