import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/rootReducer";
import createSagaMiddleware from 'redux-saga';
import rootMiddleware from "../middlewares/rootMiddleware";
import { watchImportContactSaga } from "../reducers/importContact/importContactSaga";
import {watchImportSpreadsheetContactSaga} from "../reducers/importSpreadsheetContact/importSpreadsheetContactSaga";
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    storeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootMiddleware);
sagaMiddleware.run(watchImportContactSaga);
sagaMiddleware.run(watchImportSpreadsheetContactSaga);

export default store;
