import { Box, Checkbox, Chip, FormControlLabel, TableCell, TableRow } from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import Utils from "../../../../helpers/Utils";
import Styles from "../FileReportDetails.module.css";
import {
    getDncStatusIcon,
    getEmailValidationStatusIcon,
    getLandlineMobileStatus, getNumberValidationStatusIcon,
    getSkipTraceStatusLabelAndColor
} from "../FileReportDetailsSingle";
const userTimezone = Utils.getAccountData('userTimezoneRaw');

const NonAddressItemRegular = ({ contact, index, onSelect, isSelected, collectIds }) => {
    useEffect(() => {
        if (contact) {
            collectIds(contact.id);
        }
    }, [contact]); // eslint-disable-line react-hooks/exhaustive-deps

    const firstKey = Object.keys(contact)[0];
    let enhanceCreatedAt = 0;
    if (contact[firstKey] && contact[firstKey]['enhance_data_search_clean_contact_history'] && contact[firstKey]['enhance_data_search_clean_contact_history']['created_at']) {
        enhanceCreatedAt = contact[firstKey]['enhance_data_search_clean_contact_history']['created_at'];
        enhanceCreatedAt = window.globalTimezoneConversionToDifferentTimezone(enhanceCreatedAt, 'UTC', userTimezone, 'M-D-Y', false);
    }

    return (
        <Fragment key={index}>
            <TableRow className="stt-address-row">
                <TableCell className="stt-address-cell">
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                />
                            }
                            checked={isSelected(contact.id)}
                            onChange={(event, checked) => {
                                onSelect(contact.id)
                            }}
                        />
                        <Box>
                            {contact.first_name} {contact.last_name}
                        </Box>
                    </Box>
                </TableCell>
                <TableCell align="left">{contact.email}</TableCell>
                <TableCell align="center">
                    {getEmailValidationStatusIcon(contact.email_valid_status)}
                </TableCell>
                <TableCell align="left">{contact.number}</TableCell>
                <TableCell align="center">
                    {getNumberValidationStatusIcon(contact.number_valid_status)}
                    {/*/>*/}
                </TableCell>
                <TableCell align="center">
                    <Chip
                        label={getLandlineMobileStatus(contact.number_state)}
                        className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                        size="small"
                    />
                </TableCell>
                <TableCell align="center">
                    {getDncStatusIcon(contact.dnc_status)}
                </TableCell>
                <TableCell align="center" className="stt-address-cell">
                    {
                        enhanceCreatedAt !== 0 &&
                        <Chip
                            size={"small"}
                            className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                            color='primary'
                            style={{
                                backgroundColor: getSkipTraceStatusLabelAndColor("TRACED")[1],
                            }}
                            label={enhanceCreatedAt !== 0 ? enhanceCreatedAt : ''}
                        />
                    }
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

export default NonAddressItemRegular;