import React, { useState } from 'react'
import { connect } from "react-redux";
import { MenuItem, Popover } from '@material-ui/core'
import { IconList } from '../../globals/IconList';
import Helper from '../../globals/Helper';
import Utils from '../rightSide/Helpers/Utils';
import BootstrapTooltip from '../../globals/BootstrapTooltip';
import { getUserCampaignRegistrationStatusApi } from "../../../api/contactApi";
import dncNoCall from "../../common/dncNoCall";


const ContactListOptions = (props) => {

    const [mailOptionVisible, setMailOptionVisible] = useState(false);
    //Action
    const [anchorEl, setAnchorEl] = React.useState(null);


    const handleClose = () => {
        setMailOptionVisible(false)
        setAnchorEl(null);
    };

    const getContactEmailData = (contact) => {
        if (contact.email !== undefined && contact.email !== null) {
            return contact.email
        }
        return ""
    }

    const selectedAction = (event, selectedActions, contactDetails) => {

        if (event) {
            event.preventDefault()
        }
        if (props.setShowDateRange) {
            props.setShowDateRange(false);
        }

        switch (selectedActions) {

            case 'sms':
                if (Utils.getAccountData("campaignRegistrationAvailable") === "TRUE") {
                    if (window.sendCommunication !== undefined) {
                        window.sendCommunication({
                            open: true,
                            onClose: () => console.log(false),
                            id: contactDetails.id,
                            from: "contact-list",
                            fetchContactDetails: false,
                            contactInfo: contactDetails
                        }, 'sms')
                    }
                } else {
                    getUserCampaignRegistrationStatus(contactDetails);
                }
                break;
            case 'voicemail':
                if (Utils.getAccountData("carrierLookup") && contactDetails && contactDetails.number) {
                    if (contactDetails.contact_additional_informations && contactDetails.contact_additional_informations.dnc_status === "DO_NOT_CALL") {
                        dncNoCall({
                            takeRisk: () => {
                                if (window.sendCommunication !== undefined) {
                                    window.sendCommunication({
                                        open: true,
                                        onClose: () => console.log(false),
                                        id: contactDetails.id,
                                        from: "contact-list",
                                        fetchContactDetails: false
                                    }, 'voice')
                                }
                            },
                            cancelButtonText: "Don't Send"
                        })
                    } else {
                        if (window.sendCommunication !== undefined) {
                            window.sendCommunication({
                                open: true,
                                onClose: () => console.log(false),
                                id: contactDetails.id,
                                from: "contact-list",
                                fetchContactDetails: false
                            }, 'voice')
                        }
                    }
                } else {
                    if (window.sendCommunication !== undefined) {
                        window.sendCommunication({
                            open: true,
                            onClose: () => console.log(false),
                            id: contactDetails.id,
                            from: "contact-list",
                            fetchContactDetails: false
                        }, 'voice')
                    }
                }
                break;

            case 'email':
                if (window.sendCommunication !== undefined) {
                    window.sendCommunication({
                        open: true,
                        onClose: () => console.log(false),
                        id: contactDetails.id,
                        from: "contact-list",
                        primaryEmail: getContactEmailData(props.row),
                        fetchContactDetails: false
                    }, 'email')
                }
                break;

            case 'allinfo':
                window.handleGlobalContactDetailsModal({
                    open: true,
                    from: 'contact-list',
                    title: () => {
                        let title = "Contact details";
                        let data = props.row
                        if (data !== undefined && data !== null) {
                            if ((data.first_name !== null && data.first_name !== "") && (data.last_name !== null && data.last_name !== "")) {
                                title = data.first_name + ' ' + data.last_name
                            }
                            else if (data.first_name !== null && data.first_name !== "") {
                                title = data.first_name
                            }
                            else if (data.last_name !== null && data.last_name !== "") {
                                title = data.last_name
                            }
                            else if (data.email !== null && data.email !== "") {
                                title = data.email
                            }
                            else if (data.number !== null && data.number !== "") {
                                title = data.number
                            }
                        }
                        return title;
                    },
                    id: props.row.id,
                })
                break;

            case 'activity':

                window.openGlobalActivityModal({ subUserList: props.subUserList, contactDetails: contactDetails })
                break;

            case 'call':
                if (Utils.getAccountData("carrierLookup") && contactDetails && contactDetails.number) {
                    if (contactDetails.contact_additional_informations && contactDetails.contact_additional_informations.dnc_status === "DO_NOT_CALL") {
                        dncNoCall({
                            takeRisk: () => {
                                props.openDialer(contactDetails, props.index);
                            },
                            cancelButtonText: "Don't Call"
                        })
                    } else {
                        props.openDialer(contactDetails, props.index);
                    }
                } else {
                    props.openDialer(contactDetails, props.index);
                }
                break;

            case 'video-email':
                if (window.sendCommunication !== undefined) {
                    window.sendCommunication({
                        open: true,
                        contactId: contactDetails.id,
                        contactDetails: contactDetails,
                        from: 'contact-details',
                        responseCallback: () => console.log('video email send')
                    }, 'videoEmail')
                }

                break;
            case 'direct-mail':
                setMailOptionVisible(true)

                break;


            default:
                return null;
        }
    }

    const sendSMSModal = (contactDetails) => {
        if (window.sendCommunication !== undefined) {
            window.sendCommunication({
                open: true,
                onClose: () => console.log(false),
                id: contactDetails.id,
                from: "contact-list",
                fetchContactDetails: false,
                contactInfo: contactDetails
            }, 'sms')
        }
    }

    const getUserCampaignRegistrationStatus = (contactDetails) => {

        const CANADIAN_USERS_STRING = process.env.REACT_APP_CANADIAN_USER;

        const parentUser = Utils.getAccountData('parentId');

        if (!CANADIAN_USERS_STRING.includes(parentUser)) {
            try {
                getUserCampaignRegistrationStatusApi()
                    .then((res) => {
                        res = res.data;
                        if (res.success) {
                            if (res.campaignRegistrationStatus === "TRUE") {
                                sendSMSModal(contactDetails);
                            } else {
                                window.globalSmsRestriction(true, {
                                    callback: (res) => {
                                        if (res.sendWithRisk) {
                                            sendSMSModal(contactDetails);
                                        }
                                    }
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        sendSMSModal(contactDetails);
                        console.log(err);
                    });
            } catch (err) {
                sendSMSModal(contactDetails);
                console.log(err);
            }
        } else {
            sendSMSModal(contactDetails);
        }
    }

    const handleNewPostcard = (type = 'postcard') => {
        setMailOptionVisible(false)
        window.sendGlobalDirectMailV2({
            contactId: props.row.id,
            from: '/contacts',
            card_type: type,
            for: 'contact-list' //direct-mail-send, campaign-setting, calendar-activity
        })
    }

    return (
        <React.Fragment>

            <td className="option-information">
                <ul>
                    <li>
                        <BootstrapTooltip arrow placement="top" title="Details">

                            <a href="#!" onClick={(e) => selectedAction(e, 'allinfo', props.row)}>
                                {IconList.contactDetailsIcon}
                            </a></BootstrapTooltip></li>
                    {!Helper.isEmpty(props.row.number) &&
                        <li>
                            <BootstrapTooltip arrow placement="top" title="Text">

                                <a href="#!" onClick={(e) => selectedAction(e, 'sms', props.row)}>
                                    {IconList.messageIcon}
                                </a></BootstrapTooltip></li>
                    }
                    {!Helper.isEmpty(props.row.number) &&

                        <li>
                            <BootstrapTooltip arrow placement="top" title="Call">

                                <a href="#!" onClick={(e) => selectedAction(e, 'call', props.row)}>
                                    {IconList.callIconInList}
                                </a></BootstrapTooltip></li>
                    }
                    {!Helper.isEmpty(props.row.number) &&
                        <li>
                            <BootstrapTooltip arrow placement="top" title="Voicemail">

                                <a href="#!" onClick={(e) => selectedAction(e, 'voicemail', props.row)}>
                                    {IconList.speakerIcon}
                                </a></BootstrapTooltip></li>
                    }
                    {!Helper.isEmpty(props.row.email) &&

                        <li>
                            <BootstrapTooltip arrow placement="top" title="Email">

                                <a href="#!" onClick={(e) => selectedAction(e, 'email', props.row)}>
                                    {IconList.emailIconInListForSingle}</a></BootstrapTooltip></li>
                    }

                    {!Helper.isEmpty(props.row.email) &&

                        <li><BootstrapTooltip arrow placement="top" title="Video Email">
                            <a href="#!" onClick={(e) => selectedAction(e, 'video-email', props.row)}>
                                {IconList.videoIcon}</a></BootstrapTooltip></li>
                    }
                    {!Helper.isEmpty(props.row.address) && !Helper.isEmpty(props.row.state) && (props.thanksIoActive || props.zenDirectActive) &&

                        <li><BootstrapTooltip arrow placement="top" title="Direct Mail">
                            <a href="#!" onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                selectedAction(event, 'direct-mail', props.row)
                            }}>
                                {IconList.directMailiconInContactDetails}

                            </a></BootstrapTooltip>
                            {mailOptionVisible &&
                                <Popover
                                    open={mailOptionVisible}
                                    anchorEl={anchorEl}
                                    onClose={handleClose}

                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    {props.thanksIoActive &&
                                        <>
                                            <MenuItem className="dropdownhelper-menuitem-class" onClick={() => handleNewPostcard('postcard')}
                                                style={{
                                                    paddingTop: 6,
                                                    paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                                }}>
                                                <span>
                                                    {IconList.postcardIcon}
                                                </span>
                                                &nbsp;      Post Card

                                            </MenuItem>
                                            {/* <MenuItem className="dropdownhelper-menuitem-class" onClick={() => selectActiveMailType(GREETING_CARD)}
                                        style={{
                                            paddingTop: 6,
                                            paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                        }}>

                                        <span>
                                            {IconList.greetingcardIcon}

                                        </span>
                                        &nbsp;    Greeting Card

                                    </MenuItem> */}
                                            {/* {(Utils.getAccountData('userId') === 1 || Utils.getAccountData('userId') === 2) && */}
                                            <MenuItem className="dropdownhelper-menuitem-class" onClick={() => handleNewPostcard('notecard')}
                                                style={{
                                                    paddingTop: 6,
                                                    paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                                }}>

                                                <span>
                                                    {IconList.greetingcardIcon}

                                                </span>
                                                &nbsp;  Note Card

                                            </MenuItem>
                                            {/* } */}
                                            <MenuItem className="dropdownhelper-menuitem-class" onClick={() => handleNewPostcard('letter')}
                                                style={{
                                                    paddingTop: 6,
                                                    paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                                }}>

                                                <span>
                                                    {IconList.lettericon}

                                                </span>
                                                &nbsp;Letter</MenuItem>
                                        </>
                                    }
                                    {props.zenDirectActive &&
                                        <MenuItem className="dropdownhelper-menuitem-class" onClick={() => handleNewPostcard('zendirect_gift')}
                                            style={{
                                                paddingTop: 6,
                                                paddingBottom: 6, paddingLeft: 16, paddingRight: 16, justifyContent: 'unset'
                                            }}>

                                            <span>
                                                {IconList.giftIcon}

                                            </span>
                                            &nbsp; Gift
                                        </MenuItem>
                                    }
                                </Popover>
                            }
                        </li>
                    }

                    <li>
                        <BootstrapTooltip arrow placement="top" title="Activity">
                            <a href="#!" onClick={(e) => selectedAction(e, 'activity', props.row)}>
                                {IconList.activityIconInList}
                            </a></BootstrapTooltip>

                    </li>
                </ul>

            </td>
        </React.Fragment>
    )
}

const mapStateToProps = state => {
    return {
        subUserList: state.contactReducer.subUserList,

    };
};

const mapDispatchToProps = dispatch => {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactListOptions);