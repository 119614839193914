import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Table, TableBody, TableCell, TableHead, TableRow,  } from '@material-ui/core';


const DataExportSkeleton = () => {
    const rows = Array.from(new Array(10));

    return (
        <>
        <div style={{display:'flex',justifyContent:'space-between', alignItems:'center',marginBottom:'20px'}}>
            <Skeleton width={"150px"} height={"80px"} />
            <Skeleton width={"150px"} height={"80px"} />
        </div>
        <Table>
        <TableHead>
            <TableRow>
                <TableCell align="center"><Skeleton width="100%" /></TableCell>
                <TableCell align="center"><Skeleton width="100%" /></TableCell>
                <TableCell align="center"><Skeleton width="100%" /></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((_, index) => (
                <TableRow key={index}>
                    <TableCell align="center"><Skeleton width="100%" /></TableCell>
                    <TableCell align="center"><Skeleton width="100%" /></TableCell>
                    <TableCell align="center"><Skeleton width="100%" /></TableCell>
                </TableRow>
            ))}
        </TableBody>
    </Table>
        </>
    );
}

export default DataExportSkeleton;
