import React, { useState, useEffect } from 'react';
import { fetchContactFiles, removeFile } from '../../../api/contactApi.js';
import File from "./File.js";
import ModalFileUploadForm from './ModalFileUploadForm';
import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

const FileList = (props) => {

    let count = 0;

    const [showModal, setShowModal] = useState(false)
    const [files, setFiles] = useState(null)
    const [isDeleting, setIsDeleting] = useState(false)

    // useEffect(() => {
    //     if (props.data != null) {
    //         setFiles(props.data['files'])
    //     }
    // }, [])  // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (props.data != null) {
    //         setFiles(props.data['files'])
    //     }
    // }, [props.data])

    useEffect(()=>{

        fetchContactFiles({id:  props.contactId})
        .then(res=>{
            setFiles(res.data.data)

        })
        .catch(e=>{
            console.log('file data fetch problem')
        })
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    const modalToggle = () => {
        setShowModal(!showModal)
    }

    const loadAgainAllFiles = (file) => {
        if (files != null) {
            let allfiles = [...files];
            allfiles.push(file);
            setFiles(allfiles)
            setShowModal(false)
        } else {
            let allfiles = [];
            allfiles[0] = file;
            setFiles(allfiles)
            setShowModal(false)
        }

    }

    const removeAndLoadAgainAllFiles = (index) => {

        if (files != null) {
            let allfiles = [...files];

            allfiles.splice(index, 1);

            setFiles(allfiles)
        }

    }

    const deleteFile = (fileId, contactId, file, index) => {

        if(!isDeleting){
            setIsDeleting(true)

            removeFile({
                id: fileId,
                contact_id: contactId,
                file: file
            }).then(res => {
    
                setIsDeleting(false)
                if (res.data.status === 'success') {
                    window.showNotification("SUCCESS", "File successfully removed")
                    removeAndLoadAgainAllFiles(index);
                }
    
            });
        }else{
            window.showNotification('ERROR','Already in progress')
        }
        
    }

    // render() {

    const fileShow = () => {
        if (files != null && Array.isArray(files)) {
            return files.map((data, index) => {
                if (data !== undefined && data != null) {
                    count = 0;
                    count++;
                    return <File deleteFile={deleteFile} key={data.id} data={data} index={index} contactId={props.contactId}/>;
                }
                else
                    return null

            })
        }

    }


    return (
        <React.Fragment>

            <div id="files_tab" className="files_tab_content col s12 awesome__scroll_bar">
                <div  className="notItemsWrap">
                    <div style={{display:'flex',flexDirection:'column'}} className="notItemsFound">
                        <Button className="accent--bg--color globalAddNoteButton" variant="contained" color="primary" onClick={modalToggle}><AddIcon /> Add File</Button>
                        <p>No File Available</p>
                    </div>
                </div>
                {files!=null && fileShow()}
            </div>

            <ModalFileUploadForm
                count={count}
                title={"Contact file upload form"}
                isOpen={showModal}
                url={'/user/contact/update/email'}
                callBack={loadAgainAllFiles}
                defaultParams={{ contact_id: props.contactId }}
                modalToggle={modalToggle}
            />

        </React.Fragment>
    );
    // }
}

export default FileList;
