import React, {useState} from "react";
import {Box, makeStyles, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {BasicInput, LeftSideV2Styles} from "../LeftSideV2Styles";
import EditImportantDatesModal from "./EditImportantDatesModal";
import CustomTitle from "../common/customTitle/CustomTitle";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const ImportantDates = (props) => {
  const [open, setOpen] = useState(false);

  const { contact } = props || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderAdditionalDates = () => {
    if (contact.contact_additional_informations && contact.contact_additional_informations.important_dates){
      let importantDates = JSON.parse(contact.contact_additional_informations.important_dates);
      return importantDates.map(item => (
          <Box className={icons.datewidth} sx={{ flex: 1 }}>
            <Typography variant='body2'>{item.title}</Typography>
            <BasicInput fullWidth type='date' value={item.value} readOnly />
          </Box>
      ));
    }
  }

  const { titleIcon, flexCenter } = LeftSideV2Styles();
  const icons = useStyles();
  return (
    <Box className={`${icons.hoverDiv} ${icons.hoverDate}`}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle handleOpen={handleOpen} title={"IMPORTANT DATES"} iconHide={icons.icon} />
      </Box>

      <Box className={flexCenter} sx={{ flexWrap: "wrap", gap: 12 }}>
        {contact?.birth_date && (
          <Box className={icons.datewidth} sx={{ flex: 1 }}>
            <Typography variant='body2'>Birth Date</Typography>
            <BasicInput fullWidth type='date' value={contact.birth_date} readOnly />
          </Box>
        )}

        {contact?.anniversary_date && (
          <Box className={icons.datewidth} sx={{ flex: 1 }}>
            <Typography variant='body2'>Anniversary Date</Typography>
            <BasicInput fullWidth type='date' value={contact.anniversary_date} readOnly />
          </Box>
        )}

        {renderAdditionalDates()}
      </Box>
      <EditImportantDatesModal open={open} onClose={handleClose} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};
export default connect(mapStateToProps)(ImportantDates);
