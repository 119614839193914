import React, { useEffect, useState } from "react";
import { Divider } from "@material-ui/core";
import Verify from "../../Verify";
import FileUpload from "../../fileUpload";
import { TAB_CLEAN_LIST, TABS } from "../../Constants";
import FileReport from "../../report/FileReport";
import FileReportDetails from "../../report/FileReportDetails";
import FileReportDetailsSingle from "../../report/FileReportDetailsSingle";
import Styles from "../../DataClean.module.css";
import FileUploadHeader from "./FileUploadHeader";
import { changeQueryParams } from "../../../../helpers/changeQueryParams";
import { getPageIndexByParams, getTabPath } from "../../../../helpers/tabPaths";
import NonAddressReportDetails from "../../report/nonAddress/NonAddressReportDetails";
import NonAddressFileReportDetails from "../../report/nonAddress/NonAddressFileReportDetails";
import Utils from "../../../../helpers/Utils";

const EmailPhoneVerifyTabContent = () => {
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [activeTab, setActiveTab] = useState(TAB_CLEAN_LIST.VERIFY);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get("page");
    setActiveTab(TABS[getPageIndexByParams(param)].code[0]);
  }, []);

  const handleChangeTab = (newValue) => {
    changeQueryParams(
      getTabPath(
        1,
        TABS.findIndex((eachTab) => eachTab.code.includes(newValue))
      )
    );
    setActiveTab(newValue);
  };

  const GetTab = () => {
    switch (activeTab) {
      case TAB_CLEAN_LIST.VERIFY:
        return <Verify onSelectFile={setIsFileSelected} />;
      case TAB_CLEAN_LIST.REPORTS:
        return <FileReport fileSource='CARRIER' onChangeTab={handleChangeTab} />;
      case TAB_CLEAN_LIST.REPORTS_DETAILS:
          return (
              <NonAddressFileReportDetails
                  onChangeTab={handleChangeTab}
                  fileSource="CARRIER"
                  filter={[
                      "valid-emails",
                      "invalid-emails",
                      "valid-numbers",
                      "invalid-numbers",
                      "dnc-on",
                      "dnc-off",
                      "landline",
                      "mobile",
                      "skip-trace",
                  ]}
              />
          );

        // return (
        //     Utils.getAccountData("userId") === 1 ?
        //         <NonAddressFileReportDetails
        //             onChangeTab={handleChangeTab}
        //             fileSource="CARRIER"
        //             filter={[
        //                 "valid-emails",
        //                 "invalid-emails",
        //                 "valid-numbers",
        //                 "invalid-numbers",
        //                 "dnc-on",
        //                 "dnc-off",
        //                 "skip-trace",
        //                 "landline",
        //                 "mobile"
        //             ]}
        //         /> :
        //       <FileReportDetails
        //           onChangeTab={handleChangeTab}
        //           filter={[
        //               "valid-emails",
        //               "invalid-emails",
        //               "valid-numbers",
        //               "invalid-numbers",
        //               "dnc-on",
        //               "dnc-off",
        //               "skip-trace",
        //               "landline",
        //               "mobile",
        //           ]}
        //           tab="CARRIER"
        //       />
        // );
      case TAB_CLEAN_LIST.REPORTS_SINGLE:

          return (
              <NonAddressReportDetails
                  fileSource="CARRIER"
                  filter={[
                      "valid-emails",
                      "invalid-emails",
                      "valid-numbers",
                      "invalid-numbers",
                      "dnc-on",
                      "dnc-off",
                      "landline",
                      "mobile",
                      "skip-trace",
                  ]}
              />
          );

        // return (
        //     Utils.getAccountData("userId") === 1 ?
        //         <NonAddressReportDetails
        //             fileSource="CARRIER"
        //             filter={[
        //                 "valid-emails",
        //                 "invalid-emails",
        //                 "valid-numbers",
        //                 "invalid-numbers",
        //                 "dnc-on",
        //                 "dnc-off",
        //                 "skip-trace",
        //                 "landline",
        //                 "mobile"
        //             ]}
        //         /> :
        //         <FileReportDetailsSingle
        //             filter={[
        //                 "valid-emails",
        //                 "invalid-emails",
        //                 "valid-numbers",
        //                 "invalid-numbers",
        //                 "dnc-on",
        //                 "dnc-off",
        //                 "skip-trace",
        //                 "landline",
        //                 "mobile",
        //             ]}
        //             fileSource='CARRIER'
        //             tab="CARRIER"
        //         />
        // );
      default:
        return <div>Invalid Tab. Please reload the page</div>;
    }
  };

  return (
    <div className={Styles.pageWrapper}>
      <div className={Styles.pageHeader}>
        <div className={Styles.pageHeaderLeft}>
          <div className={Styles.pageTab}>
            {isFileSelected ? (
              <FileUploadHeader onBack={() => setIsFileSelected(false)} />
            ) : (
              TABS.map((tab, index) => (
                <div
                  key={index}
                  id={'email_phone_verification_'+tab.label.replace(/ /g, "_")}
                  className={`${Styles.pageTabItem} ${tab.code.includes(activeTab) ? Styles.activeTab : ""}`}
                  onClick={() => {
                    if (activeTab === TAB_CLEAN_LIST.REPORTS_DETAILS && tab.code[0] === TAB_CLEAN_LIST.REPORTS) {
                      handleChangeTab(TAB_CLEAN_LIST.REPORTS_DETAILS);
                    } else {
                      handleChangeTab(tab.code[0]);
                    }
                  }}
                >
                  {tab.label}
                </div>
              ))
            )}
          </div>
          <Divider light />
        </div>
      </div>
      <div className={Styles.pageBody}>
        {isFileSelected ? <FileUpload onChangeTab={handleChangeTab} onSelectFile={setIsFileSelected} /> : <GetTab />}
      </div>
    </div>
  );
};

export default EmailPhoneVerifyTabContent;
