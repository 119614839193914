import React, { useState } from "react"
import GlobalModal from "../../../globals/Modal/GlobalModal"
import { Save, SearchOutlined } from "@material-ui/icons"
import "./modal_assign_to_tag.css"
import { getUserTagListNew } from "../../../../api/contactApi"
import useDelayCallback from "../../../../hooks/useDelayCallback"
import { icons } from "./icon"
import { cleanContactsActionsApi } from "../../../../api/cleanDataApi"
import DynamicSkeleton from "../../../common/DynamicSkeleton"

const EachTag = (props) => {
  const checkSelect = (id) => {
    return !!props.selectedTags.includes(id)
  }

  const renderSelectBox = (item) => {
    const flag = checkSelect(item.id)

    return (
      <div className='custom-checkbox-wrapper'>
        <span
          className='custom-checkbox-icon'
          onClick={() => props.selectTag(item.id)}
          key={`id_${item.id};`}
        >
          {flag ? icons.check : icons.unCheck}
        </span>
        <span>{props.data.name}</span>
      </div>
    )
  }

  return <li>{renderSelectBox(props.data)}</li>
}

const ModalAssignToTagNew = (props) => {
  const [tagInfo, setTagInfo] = useState({
    error: false,
    success: false,
    errorMsg: "",
    successMsg: "",
    allTags: [],
    allTagsToShow: [],
    storedTagList: [],
    submittingFile: false,
    submitEnable: false,
    selectedTags: [],
    submittingContact: false,
    createNew: false,
    searchKey: null,
    new: false,
    contactTags: [],
    newTagName: "",
    saveButtonText: "Assign Tag",
  })

  const [isLoading, setIsLoading] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(50)
  const [hasMore, setHasMore] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)
  const [search, setSearch] = useState("")
  const [list, setList] = useState([])

  useDelayCallback(() => {
    let perPageCountCal = 50
    if (perPage === 50) {
      perPageCountCal = Math.round((document.body.clientHeight - 300) / 40) + 5
      setPerPage(perPageCountCal)
    } else {
      perPageCountCal = perPage
    }
    getAllTagsForDropdown(perPageCountCal)
  }, [search])

  const getAllTagsForDropdown = (page = perPage) => {
    setIsLoading(true)
    getUserTagListNew({
      per_page: page,
      current_page: currentPage,
      search: search,
    })
      .then((response) => {
        let res = response.data
        if (currentPage === 1) {
          if (res.length === 0) {
            setTagInfo({
              ...tagInfo,
              createNew: true,
              newTagName: search,
            })
          }
          setList(res)
        } else {
          let all_data = [...list]
          all_data = [...all_data, ...res]
          setTagInfo({
            ...tagInfo,
            createNew: false,
            newTagName: "",
          })
          setList(all_data)
        }

        if (res.length === page) {
          setCurrentPage(parseInt(currentPage + 1))
          setHasMore(true)
        } else {
          setHasMore(false)
        }
        setLoadingMore(false)
        setIsLoading(false)
      })
      .catch((error) => {
        setLoadingMore(false)
        setIsLoading(false)
      })
  }

  const handleOnScrollLoad = (e) => {
    if (hasMore && !loadingMore) {
      if (
        Math.round(e.target.scrollTop + e.target.clientHeight, 10) >=
        Math.round(e.target.scrollHeight, 10)
      ) {
        setLoadingMore(true)
        getAllTagsForDropdown(perPage)
      }
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (tagInfo.selectedTags.length === 0) {
      window.showNotification("error", "Please select a tag")
      return
    }

    if (tagInfo.submittingContact) {
      return false
    }

    if (!tagInfo.createNew && tagInfo.selectedTags.length === 0) {
      window.showNotification("WARNING", "Please select any tag", 5000)
      return false
    }

    const payload = {
      cleanContactIds: props.selectedFileContactIds,
      filterType: props.selectedFileContactIds[0] ? "SEGMENT" : "ALL",
      action: "ASSIGN_TAG",
      tagIds: tagInfo.selectedTags,
      pageSource: props.tab
    }

    if (props.fileId){
      payload.fileId = props.fileId;
    }

    if (props.filter){
      if (props.filter.validNumbers || props.filter.invalidNumbers) {
        let numberStatus = [];

        if (props.filter.validNumbers){
          numberStatus.push("VALID");
        }

        if (props.filter.invalidNumbers){
          numberStatus.push("INVALID");
        }

        payload.numberStatus = numberStatus;
        payload.filterType = "SEGMENT";
      }

      if (props.filter.validEmails || props.filter.invalidEmails) {
        let emailStatus = [];

        if (props.filter.validEmails){
          emailStatus.push("VALID");
        }

        if (props.filter.invalidEmails){
          emailStatus.push("INVALID");
        }

        payload.emailStatus = emailStatus;
        payload.filterType = "SEGMENT";
      }

      if (props.filter.dncOn || props.filter.dncOff) {
        let dncStatus = [];

        if (props.filter.dncOn){
          dncStatus.push("DO_NOT_CALL");
        }

        if (props.filter.dncOff){
          dncStatus.push("VERIFIED_OK");
        }

        payload.dncStatus = dncStatus;
        payload.filterType = "SEGMENT";
      }

      if (props.filter.skipTrace){
        payload.skipTraceStatus = ["TRACED"];
        payload.filterType = "SEGMENT";
      }

      if (props.filter.landline || props.filter.mobile){
        let numberType = [];

        if (props.filter.landline){
          numberType.push("landline");
        }

        if (props.filter.mobile){
          numberType.push("mobile");
        }

        payload.numberType = numberType;
        payload.filterType = "SEGMENT";
      }
    }

    setIsLoading(true)
    cleanContactsActionsApi(payload)
      .then((res) => {
        if (res && res.success) {
          window.showNotification("success", res.message)
          props.onClose()
        } else {
          window.showNotification(
            "error",
            "Something went wrong try again later"
          )
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleSelectCheckbox = (value) => {
    let allTags = tagInfo.selectedTags
    if (allTags.includes(value)) {
      allTags.splice(allTags.indexOf(value), 1)
    } else {
      allTags.push(value)
    }

    setTagInfo({
      ...tagInfo,
      selectedTags: allTags,
      submitEnable: allTags.length > 0,
    })
  }

  const changeSearch = (e) => {
    let searchKey = e.target.value.toUpperCase().trim()
    setCurrentPage(1)
    setSearch(searchKey)
    setTagInfo({
      ...tagInfo,
      createNew: false,
    })
  }

  const runningList = () => {
    if (list.length  === 0) {
      return <p style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}> No Tags Available </p>;
    }
    let runList = []
    list.forEach((data, index) => {
      runList.push(
        <EachTag
          selectedTags={tagInfo.selectedTags}
          selectTag={handleSelectCheckbox}
          data={data}
          key={index}
        />
      )
    })

    return runList
  }

  return (
    <GlobalModal
      {...props}
      title='Assign Tag'
      buttonText='Add'
      buttonIcon={<Save />}
      onSubmit={handleSubmit}
      hideFooter={tagInfo.createNew ? false : false}
      modalClass='manage-tag-modal modal-assign-campaign update'
      className='manage-tag-modal modal-assign-campaign create-new-list update scroll_v2 global-medium-modal'
    >
    {isLoading ? <DynamicSkeleton/> : (
    
      <div className='row'>
        <div className='col-md-12'>
          <div className='campaign-search-list'>
            <div
              className='campaign-search form'
              style={{ paddingLeft: "10px" }}
            >
              <div className='form-group m-0'>
                <div className='m-input-icon m-input-icon--right assign__tag_search_wrapper'>
                  <input
                    onChange={changeSearch}
                    type='text'
                    className='form-control m-input customFormControlField'
                    placeholder='Type and search'
                  />
                  <SearchOutlined className='assign__searchIcon' />
                  <span className='m-input-icon__icon m-input-icon__icon--right'>
                    <span>
                      <i className='flaticon-search-1' />
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className='campaign-search-results'>
              <div
                className='upload-single-item-main2 upload-single-item-main'
                onScroll={handleOnScrollLoad}
              >
                <ul className='awesome__scroll_bar'>{runningList()}</ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
    </GlobalModal>
  )
}
export default ModalAssignToTagNew
