import React, {Fragment, useEffect, useState} from "react";
import {Box, Checkbox, Chip, FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import {
    getDncStatusIcon,
    getDncStatusLabelAndColor, getEmailValidationStatusIcon,
    getEmailValidationStatusLabelAndColor, getLandlineMobileStatus, getNumberValidationStatusIcon,
    getNumberValidationStatusLabelAndColor,
    getSkipTraceStatusLabelAndColor
} from "../FileReportDetailsSingle";
import Styles from "../FileReportDetails.module.css";
import TimezoneConversation from '../../../globals/TimezoneConversion';
import Utils from "../../../../helpers/Utils";
const userTimezone = Utils.getAccountData('userTimezoneRaw');

const AddressGroupedComplexItem = ({ address, contacts, index, isSelected, onSelect, selectAddressGroup, deSelectAddressGroup, collectIds, allSelected, resetSelect, setResetSelect }) => {
    const [subContactIds, setSubContactIds] = useState([]);
    const [selectedSubContactIds, setSelectedSubContactIds] = useState([]);
    const [isGroupSelected, setGroupSelected] = useState(false);

    let formattedAddress = address.replace(/,/g, ", ").replace(/\|/g, ", ");

    const firstKey = Object.keys(contacts)[0];
    let enhanceCreatedAt = 0;
    let traceResultFound = true;
    if(contacts[firstKey]['status'] === 'NOT_FOUND')
    {
        traceResultFound = false;
    }

    if (contacts[firstKey] && contacts[firstKey]['enhance_data_search_clean_contact_history'] && contacts[firstKey]['enhance_data_search_clean_contact_history']['created_at']) {
        enhanceCreatedAt = contacts[firstKey]['enhance_data_search_clean_contact_history']['created_at'];
        enhanceCreatedAt = TimezoneConversation(enhanceCreatedAt, 'UTC', userTimezone, 'M-D-Y');
    }

    useEffect(() => {
        if (contacts){
            let tempSubContactIds = [];
            Object.entries(contacts).forEach(([id, iContact])=>{
                tempSubContactIds.push(parseInt(id));
                collectIds(id);
                if (iContact && iContact.additional_contacts){
                    iContact.additional_contacts.forEach((additionalContact)=>{
                        tempSubContactIds.push(additionalContact.id);
                        collectIds(additionalContact.id);
                    });
                }
            });

            setSubContactIds(tempSubContactIds);
        }
    }, [contacts]);

    useEffect(()=>{
        if (allSelected){
            setGroupSelected(true);
            if (selectedSubContactIds[0]){
                setSelectedSubContactIds([]);
            }
        }else if (!allSelected){
            setGroupSelected(false);
            if (selectedSubContactIds[0]){
                setSelectedSubContactIds([]);
            }
        }
    },[allSelected]);

    useEffect(()=>{
        if (resetSelect){
            setGroupSelected(false);
            setSelectedSubContactIds([]);
            setResetSelect(false);
        }
    },[resetSelect]);

    const handleInternalIds = (id) => {
        onSelect(id);
        if (selectedSubContactIds.includes(id)) {
            if (selectedSubContactIds.length < subContactIds.length){
                setGroupSelected(false);
            }
            setSelectedSubContactIds(
                selectedSubContactIds.filter(
                    (selectedFileContactId) => selectedFileContactId !== id
                )
            );
        } else {
            if ((selectedSubContactIds.length + 1) === subContactIds.length){
                setGroupSelected(true);
            }
            setSelectedSubContactIds([...selectedSubContactIds, id]);
        }
    };

    return (
        <Fragment>
            <TableRow key={index} className={"stt-address-row"}>
                <TableCell>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                    indeterminate={((selectedSubContactIds.length > 0) && (selectedSubContactIds.length < subContactIds.length))}
                                />
                            }
                            checked={isGroupSelected}
                            onChange={(event, checked)=>{
                                if (checked){
                                    selectAddressGroup(subContactIds);
                                    setSelectedSubContactIds(subContactIds);
                                    setGroupSelected(true);
                                }else {
                                    deSelectAddressGroup(subContactIds);
                                    setSelectedSubContactIds([]);
                                    setGroupSelected(false);
                                }
                            }}
                        />
                        <b>{formattedAddress}</b>
                    </Box>
                </TableCell>
                <TableCell colSpan={7} style={{textAlign:"center"}}><b>{!traceResultFound ? "No available data found" : ""}</b></TableCell>
                <TableCell style={{ textAlign: "center" }}>
                    <Chip
                        size={"small"}
                        className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                        style={{
                            backgroundColor: getSkipTraceStatusLabelAndColor("TRACED")[1],
                        }}
                        label={enhanceCreatedAt !== 0 ? enhanceCreatedAt : ''}
                    />
                </TableCell>
            </TableRow>

            {
                Object.entries(contacts).map(([id, contact]) => {
                    if (contact.status !== "NOT_FOUND") {
                        return (
                            <SkipTraceComplexInnerItem
                                contact={contact}
                                index={id}
                                isSelected={isSelected}
                                onSelect={handleInternalIds}
                            />
                        );
                    }
                })
            }
        </Fragment>
    );
};

export default AddressGroupedComplexItem;

const SkipTraceComplexInnerItem = ({ contact, index, onSelect, isSelected }) => {
    return (
        <Fragment>
            <TableRow key={index} className={"stt-address-details-row top-contact"}>
                <TableCell></TableCell>
                <TableCell className={Styles.backgroundColorWhite}>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size={"small"}
                                    color={"primary"}
                                />
                            }
                            checked={isSelected(contact.id)}
                            onChange={()=>{
                                onSelect(contact.id)
                            }}
                        />
                        <b>{contact.first_name} {contact.last_name}</b>
                    </Box>
                </TableCell>
                <TableCell className={Styles.backgroundColorWhite} >{contact.email}</TableCell>
                <TableCell className={`${Styles.textAlignCenter} ${Styles.backgroundColorWhite}`}>
                    {getEmailValidationStatusIcon(contact.email_valid_status)}
                    {/*<Chip*/}
                    {/*    label={getEmailValidationStatusLabelAndColor(contact.email_valid_status)[0]}*/}
                    {/*    color={"primary"}*/}
                    {/*    size={"small"}*/}
                    {/*    style={{*/}
                    {/*        backgroundColor: getEmailValidationStatusLabelAndColor(contact.email_valid_status)[1],*/}
                    {/*    }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell className={Styles.backgroundColorWhite} >{contact.number}</TableCell>
                <TableCell className={`${Styles.textAlignCenter} ${Styles.backgroundColorWhite}`}>
                    {getNumberValidationStatusIcon(contact.number_valid_status)}
                    {/*<Chip*/}
                    {/*    label={getNumberValidationStatusLabelAndColor(contact.number_valid_status)[0]}*/}
                    {/*    color={"primary"}*/}
                    {/*    size={"small"}*/}
                    {/*    style={{*/}
                    {/*        backgroundColor: getNumberValidationStatusLabelAndColor(*/}
                    {/*            contact.number_valid_status*/}
                    {/*        )[1],*/}
                    {/*    }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell className={`${Styles.textAlignCenter} ${Styles.backgroundColorWhite}`}>
                    <Chip
                        label={getLandlineMobileStatus(contact.number_state)}
                        className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                        size={"small"}
                    />
                </TableCell>
                <TableCell className={`${Styles.textAlignCenter} ${Styles.backgroundColorWhite}`}>
                    {getDncStatusIcon(contact.dnc_status)}
                    {/*<Chip*/}
                    {/*    label={getDncStatusLabelAndColor(contact.dnc_status)[0]}*/}
                    {/*    color={"primary"}*/}
                    {/*    size={"small"}*/}
                    {/*    style={{*/}
                    {/*        backgroundColor: getDncStatusLabelAndColor(contact.dnc_status)[1],*/}
                    {/*    }}*/}
                    {/*/>*/}
                </TableCell>
                <TableCell className={`${Styles.backgroundColorWhite}`}></TableCell>
            </TableRow>
            {
                contact.additional_contacts &&
                contact.additional_contacts.map((additionalContact, iindex)=>(
                    <SkipTraceComplexInnerItem
                        contact={additionalContact}
                        index={iindex}
                        isSelected={isSelected}
                        onSelect={onSelect}
                    />
                ))
            }
        </Fragment>
    );
};