import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Contact from '../components/contact/Contact';
import DataExport from '../components/DataExport';
import Contactlist from '../components/contact/contactList/Contactlist';
import ImportContact from '../components/importContacts/ImportContact';
import DripBatchReport from '../components/dripBatch';
import DripBatchContactList from '../components/dripBatch/DripBatchContactList';
import ImportContactCampaign from '../components/importContacts/ImportContactCampaign';
import HealthCheck from '../components/HealthCheck';
import DialingList from "../components/AutoDialer/DialingList";
import InactiveContactList from '../components/inactiveContactList';
import { ApmRoute } from '@elastic/apm-rum-react'
import UnsubscribeContact from '../components/unsubscribedContactList';
import ImportContactPoweDialer from '../components/importContacts/ImportContactPowerDialer';
// import DataClean from "../components/dataClean";
import EnhanceDataHomePage from "../components/dataClean/enhanceData/EnhanceDataHomePage";
import ImportContactBulkEmailCampaign from "../components/importContacts/ImportContactBulkEmailCampaign";
import ImportContactsV2 from '../components/importContactsV2/Index';

class Routes extends Component {

    render() {
        return (
            <Router>
                <Switch>
                    {/* <Route exact path="/"><Redirect to="/contacts" /></Route> */}
                    {/*<BaseLayoutRoute exact path={'/contacts/:id'} component={Contact}/>*/}
                    {/* <BaseLayoutRoute exact path="/"><Redirect to={"/contacts"} /></BaseLayoutRoute> */}

                    <ApmRoute exact path={'/contacts'} component={Contactlist} />
                    <ApmRoute exact path="/contacts/clean-list" component={EnhanceDataHomePage}/>
                    <ApmRoute path={'/contacts/:id'} component={Contact} />
                    <ApmRoute path={'/data-export/csv-file'} component={DataExport} />
                    <ApmRoute exact path={'/contacts-import'} component={ImportContact} />
                    <ApmRoute exact path={'/import-contacts'} component={ImportContactsV2} />
                    <ApmRoute exact path={'/campaign/import/:campaignId'} component={ImportContactCampaign} />
                    <ApmRoute exact path={'/bulk-email/campaign/import/:campaignUid'} component={ImportContactBulkEmailCampaign} />


                    {/* drip batch reports */}
                    <ApmRoute path={'/batches'} component={DripBatchReport} />
                    <ApmRoute path={'/batch-contacts/:id?/:title?'} component={DripBatchContactList} />
                    <ApmRoute path="/contact/health-check" component={HealthCheck} />

                    {/* auto dialer */}
                    <ApmRoute path="/auto-dialer" component={DialingList} />

                    {/* inactive contact list */}
                    <ApmRoute path="/inactive-contact-list" component={InactiveContactList} />
                    {/* unsubscribed contact list */}
                    <ApmRoute path="/unsubscribe-contact-list" component={UnsubscribeContact} />


                    <ApmRoute exact path={'/power-dialer/import/:powerDialerId'} component={ImportContactPoweDialer} />
                </Switch>
            </Router>
        );
    }
}

export default Routes;