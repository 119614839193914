import {createSlice} from '@reduxjs/toolkit';
import {
    IMPORT_CONTACT_SPREADSHEET_ADDRESS,
    IMPORT_CONTACT_SPREADSHEET_DETAILS,
    IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT, IMPORT_CONTACT_V2_RELATED_CONTACT,
} from '../../constants/CoreConstants';

const initialMappingFields = [
    {
        label: "Details",
        options: IMPORT_CONTACT_SPREADSHEET_DETAILS
    },
    {
        label: "Address",
        options: IMPORT_CONTACT_SPREADSHEET_ADDRESS
    },
    {
        label: "Related Contacts",
        options: IMPORT_CONTACT_V2_RELATED_CONTACT
    }
];

const initialDetails = {
    leadSourceId: "",
    pipelineId: "",
    stageId: "",
    tagIds: [""],
    campaignId: "",
    email: "",
    spreadsheetLink: "",
    spreadSheetName: "",
    worksheet: "",
    workSheetInfo: null,
    ignoreFirstRow: true,
    userConsent:true,
    spreadSheetId:''
};

const initialAccordion = {
    isPipelineExpand: false,
    isCampaignExpand: false,
};

const initialState = {
    activeTab: IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT,
    completedSteps: [],

    mappingFields: initialMappingFields,
    mappedFields: {},
    mappedRows: [],
    unmappedRows: [],

    error: null,

    loadingInitialData: false,
    leadSources: [],
    tags: [],

    accordion: initialAccordion,

    loadingPipelines: false,
    pipelines: [],
    loadingStages: false,
    stages: [],

    loadingCampaigns: false,
    campaigns: [],

    loadingWorksheets: false,
    worksheets: [],

    details: initialDetails,

    importFrom: "",
    spreadSheetFilesDetails:{},
};

const importSpreadsheetContactSlice = createSlice({
    name: 'importSpreadsheetContact',
    initialState,
    reducers: {
        loadInitialDataStart: (state, _action) => {
            state.loadingInitialData = true;
        },
        loadInitialDataSuccess: (state, action) => {
            state.loadingInitialData = false;
            if (action.payload.leadSources){
                state.leadSources = action.payload.leadSources;
                if (action.payload.leadSources[0]){
                    state.details.leadSourceId = action.payload.leadSources[0].id;
                }
            }

            if (action.payload.tags){
                state.tags = action.payload.tags;
                state.details.tagIds = [""];
            }

            if (action.payload.customFields){
                state.mappingFields = [ ...state.mappingFields, { label: "Custom Fields", options: action.payload.customFields } ];
            }
        },
        loadInitialDataFail: (state) => {
            state.loadingInitialData = false;
        },
        loadPipelineDataStart: (state, _action) => {
            state.loadingPipelines = true;
            state.details.pipelineId = "";
        },
        loadPipelineDataSuccess: (state, action) => {
            state.loadingPipelines = false;
            state.pipelines = action.payload;
            state.stages = [];
        },
        loadPipelineDataFail: (state) => {
            state.loadingPipelines = false;
        },
        loadStageDataStart: (state) => {
            state.stages = [];
            state.loadingStages = true;
            state.details.stageId = "";
        },
        loadStageDataSuccess: (state, action) => {
            state.loadingStages = false;
            state.stages = action.payload;
        },
        loadStageDataFail: (state) => {
            state.loadingStages = false;
        },
        loadCampaignDataStart: (state, _action) => {
            state.loadingCampaigns = true;
            state.details.campaignId = "";
        },
        loadCampaignDataSuccess: (state, action) => {
            state.loadingCampaigns = false;
            state.campaigns = action.payload;
        },
        loadCampaignDataFail: (state) => {
            state.loadingCampaigns = false;
        },
        updateActiveStepAndCompletedStep: (state, action) => {
            state.activeTab = action.payload.activeTab;
            state.completedSteps = action.payload.completedSteps;
        },
        updateMappedFields: (state, action) => {
            if (action.payload.mappedFields) state.mappedFields = action.payload.mappedFields;
            if (action.payload.mappedRows) state.mappedRows = action.payload.mappedRows;
            if (action.payload.unmappedRows) state.unmappedRows = action.payload.unmappedRows;
        },
        updateAccordion: (state, action) => {
            state.accordion = { ...state.accordion, ...action.payload };
        },
        updateDetails: (state, action) => {
            state.details = { ...state.details, ...action.payload };
        },
        resetToInitial: (state) => {
            state.activeTab = IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT;
            state.completedSteps = [];
            state.mappingFields = initialMappingFields;
            state.mappedFields = {};
            state.mappedRows = [];
            state.unmappedRows = [];
            state.details = initialDetails;
            state.importFrom = "";
        },
        updateTags : (state, action) => {
            state.tags = [action.payload, ...state.tags]
            state.details.tagIds = state.details.tagIds[0] ? [action.payload.id, ...state.details.tagIds] : [action.payload.id]
        },
        updateImportFrom: (state, action) => {
            state.importFrom = action.payload;
        },
        loadWorksheetsStart: (state, _action) => {
            state.loadingWorksheets = true;
        },
        loadWorksheetsSuccess: (state, action) => {
            state.loadingWorksheets = false;
            state.worksheets = action.payload.sheets.map((sheet)=>({ sheetId: sheet?.properties?.sheetId, title: sheet?.properties?.title }));
            state.details.spreadSheetName = action.payload.properties?.title;
        },
        loadWorksheetsFail: (state) => {
          state.loadingWorksheets = false;
        },
        loadSpreadSheetFileDetails: (state, action) => {
        state.spreadSheetFilesDetails = action.payload
        }
    },
});

export const {
    loadInitialDataStart,
    loadInitialDataSuccess,
    loadInitialDataFail,
    updateActiveStepAndCompletedStep,
    updateMappedFields,
    loadPipelineDataStart,
    loadPipelineDataSuccess,
    loadPipelineDataFail,
    loadStageDataStart,
    loadStageDataSuccess,
    loadStageDataFail,
    loadCampaignDataStart,
    loadCampaignDataSuccess,
    loadCampaignDataFail,
    updateDetails,
    updateAccordion,
    resetToInitial,
    updateTags,
    updateImportFrom,
    loadWorksheetsStart,
    loadWorksheetsSuccess,
    loadWorksheetsFail,
    loadSpreadSheetFileDetails,
} = importSpreadsheetContactSlice.actions;

export default importSpreadsheetContactSlice.reducer;
