import React from "react";
import Styles from "./style.module.css";

const PricingAlertContent = ({ title = "", description = "", onSubmit = () => {}, onClose = () => {} }) => {
  return (
    <>
      <div className={`${Styles.bodyWrp} `}>
        <span className={Styles.bodyTitle}>{title}</span>
        <span className={Styles.descriptionText}>{description}</span>
      </div>
      <div className={Styles.footerWrp}>
        <span onClick={onSubmit} className={`${Styles.button} ${Styles.submitButton}`}>
          Validate
        </span>
        <span onClick={onClose} className={`${Styles.button} ${Styles.cancelButton}`}>
          Cancel
        </span>
      </div>
    </>
  );
};

export default PricingAlertContent;
