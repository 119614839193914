import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";

export const BasicTabs = styled(Tabs)`
  //background: #1976d2;
  //color: #fff;
  & .Mui-selected {
    color: #007aff;
  }
  & .MuiTabs-indicator {
    background: #007aff;
  }
  & .MuiTab-root{
    min-width: max-content;
  }
`;
