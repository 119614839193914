import React, { useState, useEffect } from "react";
import { getRunningCampaignListNotInCurrentCampaign, moveToCampaign } from "../redux/contactApi";
import { Grid, InputAdornment } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Add, Search } from "@material-ui/icons";
import GlobalModal from "../Helpers/Modal/GlobalModal";
import Helper from "../Helpers/Helper";
import { BasicInput } from "../../leftSideV2/LeftSideV2Styles";
import AddCampaignFormSkeleton from "./AddCampaignFormSkeleton";
const ModalAddCampaignForm = (props) => {
  const [allCampaign, setAllCampaign] = useState([]);
  const [runningCampaign, setRunningCampaign] = useState([]);
  const [nonRunningCampaign, setNonRunningCampaign] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [submitingContact, setSubmitingContact] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  // const [runningCampaign,setRunningCampaign] = useState([]);
  // const [runningCampaign,setRunningCampaign] = useState([]);

  // componentWillReceiveProps(previous) {
  //     this.setState({
  //         isOpen: previous.isOpen
  //     });
  // }
  var selectedContactName = "";
  useEffect(() => {
    loadRunningCampaign();
    fetchContactInfo();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedCampaign !== "" && !submitingContact) {
      setSubmitingContact(true);

      var isSubscribe = false;
      var camapignId = false;

      // if(selectedCampaign !== ''){
      isSubscribe = true;
      // }

      //console.log(this.props.selectedCampaign.length);

      // for(let i = 0; i < this.props.selectedCampaign.length; i++){
      //     var subscribeData = this.props.selectedCampaign[i];
      //     if(subscribeData.subscription.length == 0){
      //         isSubscribe = true;
      //         camapignId = subscribeData.campaign_id;
      //         break;
      //     }

      //     if(subscribeData.subscription.length > 0){
      //         if(subscribeData.subscription[0].is_unsubscribed == 0){
      //             isSubscribe = true;
      //             camapignId = subscribeData.campaign_id
      //             break;
      //         }
      //     }
      // }

      var params = {
        contact_id: props.contact_id,
        move_campaign_id: selectedCampaign,
        isSubscribe: isSubscribe ? 1 : 0,
        campaign_id: camapignId,
      };

      if (camapignId) {
        params = Object.assign(params, {
          campaign_id: camapignId,
        });
      }

      // this.setState({
      //     submitingContact : true
      // });

      moveToCampaign(params).then((res) => {
        // this.setState({
        //     submitingContact : false
        // });

        setSubmitingContact(false);
        if (res.data.status === "error") {
          setError(true);
          setErrorMsg(res.data.html);

          setTimeout(() => {
            setError(false);
            setErrorMsg("");
          }, 5000);
        } else if (res.data.status === "validation-error") {
          //console.log(res.data.html);
        } else {
          window.showNotification("SUCCESS", "Contact will add in Campaign soon");
          props.modalToggle();
          props.callBack();
        }
      });
    } else if (submitingContact) {
      window.showNotificaton("ERROR", "submitting Information");
    } else {
      window.showNotification("ERROR", "No Campaign selected");
    }
  };

  const fetchContactInfo = () => {
    let contact = props.people;

    if (contact !== undefined && contact != null) {
      selectedContactName = Helper.getContactName(contact);
    }
  };

  const loadRunningCampaign = async () => {
    setLoading(true);
    try {
      await getRunningCampaignListNotInCurrentCampaign({
        contact_id: props.contact_id,
      }).then((res) => {
        setAllCampaign(res.data.campaigns);
        setRunningCampaign(res.data.campaigns);
        setNonRunningCampaign(res.data.nonEligible);
        setLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  // toggleVideo = () => {
  //     this.setState({
  //         isVideoOpen: !this.state.isVideoOpen
  //     })
  // }

  const handleRadio = (campaignId) => {
    //    this.setState({
    //         selectedCampaign : e.target.value,
    //         submitEnable : true
    //     })
    setSelectedCampaign(campaignId);
  };

  const changeSearch = (e) => {
    let searchKey = e.target.value.toUpperCase();
    const campaignData = allCampaign.filter((data) => {
      if (data.title.toUpperCase().includes(searchKey)) {
        return data;
      }
      return null;
    });
    // this.setState({
    setRunningCampaign(campaignData);
    // })
  };

  return (
    <React.Fragment>
      <GlobalModal
        title='Add Campaigns'
        onClose={props.modalToggle}
        onSubmit={handleSubmit}
        open={props.isOpen}
        buttonText={"Assign Campaign"}
        buttonIcon={<Add />}
        className={
          "addd-campaigns-modal upload-deal-modal manage-tag-modal-wrapper activity__modal_overflow update global-medium-modal"
        }
        hideFooter={loading || submitingContact ? true : false}
      >
        {/* <Wrapper className={"manage-tag-modal create-collaborate-modal"}> */}
        <Grid container className={"add-campaings-main-area"}>
          {error && (
            <Grid item xs={12}>
              <div className='form-group m-form__group'>
                <div className='alert alert-danger alert-dismissible fade show' role='alert'>
                  {errorMsg}
                </div>
              </div>
            </Grid>
          )}
          {loading || submitingContact ? (
           

            <AddCampaignFormSkeleton/>
          ) : (
            <Grid className='space20pxLeftRight customGridWrapper' item xs={12}>
              {
                <Grid container>
                  <Grid item xs={12} className='campaign-list-to-assign-contact'>
                    <div className='campain-heading'>
                      <h4>You can add {selectedContactName} to the following campaigns:</h4>
                    </div>
                    <div style={{marrginBottom:'20px !important'}}>
                      <form action=''>
                          <BasicInput style={{height:'45px !important',position:'relative'}} fullWidth type="search" name="" 
                          placeholder="Type to search" onChange={changeSearch} 
                          endAdornment={
                              <InputAdornment position="end" style={{position:'absolute',right:'12px', top:'50%', transform:'translateY(-50%)'}}>
                              <Search style={{ color: "#656565" , marginRight:"13px"}} />
                              </InputAdornment>
                          } />
                      </form>
                    </div>
                    <div className='campaigns-single-item-main3 upload-single-item-main2 upload-single-item-main'>
                      <ul className='awesome__scroll_bar campaign-add-radio-list'>
                        {runningCampaign.map((data, index) => (
                          <li>
                            <FormControlLabel
                              value={data.id}
                              onClick={() => handleRadio(data.id)}
                              control={<Radio checked={data.id === selectedCampaign ? true : false} />}
                              label={data.title}
                            />
                            <span>
                              {Helper.isEmpty(data.virtual_number)
                                ? "No Virtual Number Available"
                                : data.virtual_number}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Grid>
                </Grid>
              }
            </Grid>
          )}
        </Grid>
        {/* </Wrapper> */}
      </GlobalModal>
    </React.Fragment>
  );
};

export default ModalAddCampaignForm;
