import React, { useEffect, useState } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import { deleteImportContactFiles, getImportedFileLists } from "../../api/contactApi";
import Loading from "../common/Loading";
import CustomConfirmAlert from "../custom-alert/CustomConfirmAlert";
import FileListTableBody from "./FileListTableBody";
import ReportLog from "./ReportLog";

const FileList = (props) => {
  const [reportView, setReportView] = useState(false);
  const [data, setData] = useState(false);
  const [rows, setRows] = useState([]);
  const [reportId, setReportId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    loadContact();
  }, [trigger]);

  const loadContact = async () => {
    if (loading) {
      return;
    }

    setLoading(true);

    const payLoad = {
      page: page + 1,
      limit: rowsPerPage,
    };

    try {
      const res = await getImportedFileLists(payLoad);
      if (res.success && res.data && res.data[0]) {
        setRows(res.data);
        setData(true);
        if (page === 0) {
          setTotal(res.total_count);
        }
      } else {
        setRows([]);
        setData(false);
        setTotal(0);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (row) => {
    
    try {
      const resp = await deleteImportContactFiles({fileListId:row.id});
      if (resp.success) {
        window.showNotification("SUCCESS", row.name_original + " File Successfully Deleted..");
        loadContact();
      } else {
        window.showNotification("ERROR", resp.message);
      }
    } catch (error) {
      window.showNotification("ERROR", "Something Went Wrong, Please Try Again");
    }
  }


  const deleteFiles = (row) => {
    

    CustomConfirmAlert({
      handleConfirm: () => {
        handleDelete(row);
      },
      callConfirmLast: true,
      title: "Confirm to Delete",
      description: "Are you sure to delete this file and it's associated contacts ?",
      type_word: "DELETE FILE",
      icon: "question",
      closeButtonText: "Cancel",
      confirmButtonText: "Delete",
    });
  };

  const showReportView = (id) => {
    setReportId(id);
    setReportView(true);
  };

  const hideReportView = () => {
    setReportView(false);
  };

  return (
    <>
      {
        reportView ?
        <ReportLog
             prevStepList={ hideReportView }
             fileListId={ reportId }
         /> :
        <FileListTableBody
          rows={rows}
          deleteFiles={(id) => deleteFiles(id)}
          showReportView={showReportView}
          onRefresh={loadContact}
          loading={loading}
          dataFlag={data}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          total={total}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      }

      {!data && (
        <div className='text-center w-100 m--margin-100'>
          <Loading />
        </div>
      )}
    </>
  );
};

export default FileList;
