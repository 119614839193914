import React from "react";
import {confirmAlert} from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Typography from "@material-ui/core/Typography";
import {Divider, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {LOOKUP_TYPE_CARRIER_LOOKUP} from "../../../constants/CoreConstants";
import Styles from "./ValidationResponse.module.css";

const ValidationResponse = (props) => {
    const renderValidationData = () => {
        const view = [];
        if (props.response){
            try{
                let parsed = JSON.parse(props.response);
                const dataArray = Object.entries(parsed);
                for (let i=0; i<dataArray.length;i++){
                    const result = dataArray[i][0].replace(/([A-Z])/g, " $1");
                    const title = result.charAt(0).toUpperCase() + result.slice(1);

                    view.push(
                        <TableRow>
                            <TableCell style={{fontWeight:600}}>{title}</TableCell>
                            <TableCell>{JSON.stringify(dataArray[i][1])}</TableCell>
                        </TableRow>
                    );
                }
            }catch (err){

            }
        }

        return view;
    };

    const renderContact = () => {
        let view = "";

        if (props.validationData){
            view = props.validationData.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP ? props.validationData.contact.number : props.validationData.contact.email;
        }

        return view;
    };

    return (
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={Styles.mainWrp}>
                        <Typography variant={"h6"} style={{padding:'20px'}}>Validation Result</Typography>
                        <Divider light />

                        <div className={Styles.bodyWrp}>
                        <Paper variant={"outlined"} style={{borderBottom:'none'}}>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableBody>
                                        {renderValidationData()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        </div>
                        <Divider light/>
                        <div className={Styles.footerWrp}>
                            <button className={Styles.closeButton} onClick={onClose}>Close</button>
                        </div>
                    </div>
                );
            },
            closeOnClickOutside: props.closeOnClickOutside,
            overlayClassName: Styles.overlay
        })
    )
}
export default ValidationResponse;