export const FILE_IMPORT_STEP = {
  STEP_IMPORT_MAPPING: 1,
  STEP_UPLOAD: 2,
  STEP_SUCCESS: 3,
};

export const TAB_CLEAN_LIST = {
  VERIFY: "VERIFY",
  REPORTS: "REPORTS",
  REPORTS_SINGLE: "REPORTS_SINGLE",
  REPORTS_DETAILS: "REPORTS_DETAILS",
  UPLOAD_FILE: "UPLOAD_FILE",
};

export const TABS = [
  {
    code: [TAB_CLEAN_LIST.VERIFY],
    label: "Verify",
  },
  {
    code: [TAB_CLEAN_LIST.REPORTS, TAB_CLEAN_LIST.REPORTS_DETAILS],
    label: "Reports (File)",
  },
  {
    code: [TAB_CLEAN_LIST.REPORTS_SINGLE],
    label: "Results",
  },
];

export const STATUS = {
  ACTIVE: "ACTIVE",
  QUEUED: "QUEUED",
  PROCESSING: "PROCESSING",
  COMPLETE: "COMPLETE",
  FAILED: "FAILED",
};
