import React, { useEffect, useState } from "react";
import EachCustomField from "./EachCustomField";
import { Box, Paper } from "@material-ui/core";
import CustomStyles from "../common/Styles/Style.module.css";

const AdditionalInfoList = (props) => {
  const [customFields, setCustomFields] = useState([]);
  useEffect(() => {
    window
      .globalCustomFieldWithPersonalized({
        get_custom_data: true,
        get_personalized_data: false,
        field_name: ["*"],
        for_drop_down: false,
        drop_down_field: {
          value_field: "id",
          title_field: "title",
        },
      })
      .then((res) => {
        let customFieldDatas = res.data.custom_field;
        setCustomFields(customFieldDatas);
      });
  }, [props.contactCustomFields]);

  const fields = () => {
    let arrayData = [];
    customFields.map((data, index) =>
      arrayData.push(<EachCustomField data={data} key={index} value={props.contactCustomFields} />)
    );

    return arrayData;
  };

  const isValueExist = props?.contactCustomFields.some(item => item.value);

  return (
    <>
      {customFields.length > 0 && isValueExist && (
        <Paper variant='outlined' elevation={0}>
          <Box sx={{ maxHeight: "500px" }} className={CustomStyles.fantasticScrollbar} p={1}>
            {fields()}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default AdditionalInfoList;
