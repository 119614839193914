import React, { useEffect, useState } from 'react';
import Body from './FilePath/Body';
import ReportLog from './ReportLog';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { deleteImportFiles, getFileLists } from '../../api/contactApi';
import Loading from '../common/Loading';
import CustomConfirmAlert from '../custom-alert/CustomConfirmAlert';

const FileList = props => {

    const [reportView, setReportView] = useState(false);
    const [data, setData] = useState(false);
    const [rows, setRows] = useState([]);
    const [reportId, setReportId] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadContact();
    },[]);

    const loadContact = () => {
      if(loading){
        return
      }
      setLoading(true);
      getFileLists({}).then(res => {
        let data = res.data.data.list;
        setRows(data);
        setData(true);
        setLoading(false)
      }).catch(err => {setLoading(false)});
    }

    const deleteFiles = (row) => {
        const id = row.id;

        CustomConfirmAlert({
            handleConfirm: () => {
                deleteImportFiles({ id: id }).then(res => {
                    if (res.data.success) {
                        window.showNotification("SUCCESS", row.name_original + ' File Successfully Deleted..');
                        loadContact();
                    }
                });
            },
            callConfirmLast: true,
            title: 'Confirm to Delete',
            description: 'Are you sure to delete this file and it\'s associated contacts ?',
            type_word: 'DELETE FILE',
            icon: 'question',
            closeButtonText: 'Cancel',
            confirmButtonText: 'Delete'
        });
    };

    const showReportView = (id) => {
        setReportId(id);
        setReportView(true);
    }

    const hideReportView = () => {
        setReportView(false);
    }

    return (
        <>
        {
            reportView ? 
            <ReportLog 
                 prevStepList={ hideReportView }
                 fileListId={ reportId }
             /> :
             <Body
                 rows={ rows }
                 deleteFiles={ (id) => deleteFiles(id)}
                 prevStepList={ props.prevStepList }
                 showReportView = {showReportView}
                 onRefresh={loadContact}
                 loading={loading}
                 dataFlag={data}
             />
        }

        {
            !data && 
             <div className="text-center w-100 m--margin-100">
                 <Loading/>
             </div>
        }
        </>
     );

}

export default FileList
