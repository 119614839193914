import React from 'react';
import { InputBase, MenuItem, Select, makeStyles, withStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
    "&.Mui-selected": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
  }
}));

const BasicInputSelect = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    backgroundColor: "white !important",
    position: "relative",
    fontSize: "13px",
    margin: "0 !important",

    padding: " 9px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),

    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
    },
  },
}))(InputBase);

const BasicSelect = ({ options, defaultText = 'Select', name = 'default', onChange, multiple = false, value = '', ...rest }) => {

  const { menuItem } = useStyles();
  const handleSelectChange = (event) => {
    onChange(event);
  };

  return (
    <Select
      value={value}
      multiple={multiple}
      fullWidth
      onChange={handleSelectChange}
      name={name}
      displayEmpty
      input={<BasicInputSelect />}
      inputProps={{ "aria-label": "Without label" }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        PaperProps: {
          style: {
            maxHeight: '280px'
          },
        },
        getContentAnchorEl: null,
      }}
      {...rest}
    >
      <MenuItem className={`${menuItem} dropdownhelper-menuitem-class`} value="" disabled>
        {defaultText}
      </MenuItem>
      {options.map((option, index) => (
        <MenuItem className={`${menuItem} dropdownhelper-menuitem-class`} key={index} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default BasicSelect;
