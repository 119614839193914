import { Box, Button, Paper, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { updateMultipleData } from "../../../../actions/contactAction";
import { LeftSideV2Styles } from "../LeftSideV2Styles";
import SkipTrace from "../common/SkipTrace";
import CustomTitle from "../common/customTitle/CustomTitle";
import Styles from "../common/customTitle/customTitle.module.css";
import { getState } from "../helper/utils";
import EditAddressModal from "./EditAddressModal";
import {
  CheckCircle as CheckCircleIcon
} from "@material-ui/icons";
import Utils from "../../../../helpers/Utils";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const Address = (props) => {
  const { contact } = props;

  const [open, setOpen] = useState(false);
  const [openSkipTrace, setOpenSkipTrace] = useState(false);

  const { flexCenterBetween, textDarkBlue, warningButtonRounded, heighFit } = LeftSideV2Styles();

  const icons = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const lastSkipTraceDate = (fDate) => {
    try {
      if (window.globalTimezoneConversionToDifferentTimezone && fDate) {
        fDate = window.globalTimezoneConversionToDifferentTimezone(fDate, 'UTC', '', 'MM-DD-YY');

        return fDate;
      }

      return "Traced";
    } catch (err) {
      return "Traced";
    }
  }

  const addressContent = [
    contact?.city,
    getState(contact?.state) || contact?.state,
    contact?.zip,
    /* getState(contact?.state) || */
    // contact?.country,
  ]
    .filter(Boolean)
    .join(", ");

  let content = null;

  if (addressContent || contact?.address)
    content = (
      <Paper variant='outlined' elevation={0} style={{ padding: "15px 6px 15px 8px", width: "100%" }}>
        <Box className={flexCenterBetween} sx={{ alignItems: "normal !important", display: "flex" }}>
          <Box sx={{ height: "auto !important" }}>
            {contact?.address && (
              <Typography variant='body1' className={`${textDarkBlue} ${heighFit}`}>
                {contact?.address}
              </Typography>
            )}

            <Typography variant='body1' className={`${textDarkBlue} ${heighFit}`}>
              {addressContent}
            </Typography>
          </Box>
          {Utils.getAccountData('skipTrace') &&
            <Box sx={{ height: "auto !important" }}>
              {props.contact.contact_additional_informations &&
                props.contact.contact_additional_informations.skip_trace_status === "TRACED" ? (
                <Button
                  className={`${Styles.buttonStyle} ${Styles.tracedButton}`}
                  variant='contained'
                  color='primary'
                  size='small'
                  disableElevation
                  onClick={() => { }}
                  startIcon={<CheckCircleIcon />}
                >
                  {lastSkipTraceDate(props.contact.contact_additional_informations.skip_trace_date)}
                </Button>
              ) : (
                <Button
                  className={`${Styles.buttonStyle} skipTraceButton`}
                  variant='contained'
                  color='primary'
                  size='small'
                  disableElevation
                  onClick={() => {
                    setOpenSkipTrace(true);
                  }}
                >
                  SkipTrace
                </Button>
              )}
            </Box>
          }
        </Box>
      </Paper>
    );

  return (
    <Box className={icons.hoverDiv}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle handleOpen={handleOpen} title={"ADDRESS"} iconHide={icons.icon} />
      </Box>

      <Box sx={{ display: "flex" }}>{content}</Box>
      {open && <EditAddressModal open={open} onClose={handleClose} />}
      {openSkipTrace && (
        <SkipTrace
          isOpen={openSkipTrace}
          onClose={() => {
            setOpenSkipTrace(false);
          }}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
