import React from 'react';
import socketIOClient from "socket.io-client";
import If from 'if-else-react';
import { contactImport } from '../../api/contactApi';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import Utils from '../../helpers/Utils';
import CsvUploadLoader from './CsvUploadLoader';

class ProcessLoader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            validationChecked: false,
            newCustomsData: [],
            file:null,
            total : 0,
            current : 0,
            progressBarOpen : false,
            success : 0,
            failed : 0,
            isExistFailed : 0,
            totalUpload : 0,
            validateFailed : 0,
            fileStatus:''
        }

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.loadSocket = this.loadSocket.bind(this);
    }

    fileStatusChange = (data) =>
    {
        this.setState({
            isExistFailed : data.isExistFailed,
            totalUpload : data.totalUpload,
            validateFailed : data.validateFailed,
            current: parseInt(data.isExistFailed) + parseInt(data.totalUpload) +  parseInt(data.validateFailed),
            fileStatus: data.message,
        },() => {
            if(data.message=== "success"){
                this.props.handleChange("isExistFailed", this.state.isExistFailed);
                this.props.handleChange("totalUpload", this.state.totalUpload);
                this.props.handleChange("validateFailed", this.state.validateFailed);
                this.props.nextStep()
            }

        });
    }

    loadSocket = () =>
    {
            const socket = socketIOClient(this.props.nodeServer);
            socket.emit('subscribe-for-upload', this.props.userId);
            socket.on('upload-notification', data => this.fileStatusChange(data.params));

            this.setState({ progressBarOpen : true });
            this.onFormSubmit().then((response)=>{
                this.setState({
                    fileStatus: "File upload finished. Please wait while we process leads from file"
                });
                if(response.data.success) {
                    Utils.showNotification(response.data.message, 'SUCCESS');
                } else {
                    Utils.showNotification(response.data.message, 'ERROR');
                }                    
                this.props.refreshCSVData();
                this.props.setStepShowList();
            }).catch((err) => {
                console.log(err)
            });

    }

    onFormSubmit = () =>
    {
        const {file, newCustomsData,newTags, oldTags, existing_contact_rule,do_create_deal,pipeline_id,stage_id,batch_form,source_id, additional_infos } = this.props.state;
        const formData = new FormData();
        formData.append('file',file);
        formData.append('existing_contact_rule', existing_contact_rule);
        formData.append('do_create_deal', do_create_deal);
        formData.append('pipeline_id', pipeline_id == 0 ? null : pipeline_id);
        formData.append('stage_id', stage_id == 0 ? null : stage_id);
        formData.append('source_id',source_id);
        if (additional_infos){
            if (this.props.bulkEmailCampaignId){
                additional_infos.campaignId = this.props.bulkEmailCampaignId;
            }
            formData.append('additional_infos',JSON.stringify(additional_infos));
        }

        if (!additional_infos && this.props.bulkEmailCampaignId){
            formData.append('additional_infos',JSON.stringify({ campaignId: this.props.bulkEmailCampaignId }));
        }

        if (this.props.bulkEmailCampaignTitle){
            formData.append('extra_data', JSON.stringify({ campaignTitle: this.props.bulkEmailCampaignTitle }));
        }

        if (typeof this.props.campaignId !== 'undefined' ) {
            if(Object.keys(batch_form).length !== 0){
                batch_form['campaign_id'] = this.props.campaignId;
            }
            formData.append('campaignId',this.props.campaignId);
            formData.append('fromCampaign','yes');
        }

        if (typeof this.props.powerDialerId !== 'undefined' ) {
            if(Object.keys(batch_form).length !== 0){
                batch_form['power_dialer_list_id'] = this.props.powerDialerId;
            }
            formData.append('powerDialerListId',this.props.powerDialerId);
            formData.append('fromPowerDialer','yes');
        }

        formData.append('batch_form',JSON.stringify(batch_form));
        var data = this.props.state.submitData;
        for (const [key, value] of Object.entries(data)) {
            formData.append(value.map, value.data);
        }

        if(oldTags != undefined){
            for (var i = 0; i < oldTags.length; i++) {
                formData.append('oldTags' + '[' + i + ']'+'[id]', oldTags[i]['id']);
                formData.append('oldTags' + '[' + i + ']'+'[name]', oldTags[i]['name']);
            }
        }

        if(newTags != undefined){
            formData.append('newTags', newTags);
        }

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        return  contactImport(formData,config);
    }

    componentDidMount(){
        this.loadSocket();
    }

    render()
    {
        return (
            <React.Fragment>
            <div style={{
                width: '90vw',
                height: '70vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: '#f7f9fc',
            }}>
                <div>
                    <CsvUploadLoader/>
                </div>
            </div>
        </React.Fragment>
        );
    }
}


export default ProcessLoader
