import { Button, makeStyles } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateImportContactOptionTab } from "../../../reducers/importContact/importContactSlice";
import { FILE_IMPORT_TAB } from "../../../constants/CoreConstants";
import ImportGoogleSheetTable from "./ImportGoogleSheetTable";
import { BackIcon, RefreshIcon } from "../../../constants/IconList";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    height: "100%",
    "& .MuiButton-contained": {
      color: "#FFF",
      backgroundColor: "#3c7ef3 !important",
      "&:focus, &:hover": {
        backgroundColor: "#3c7ef3 !important",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e0e0e0 !important",
        color: "var(--dark_blue) !important",
      },
    },
    "& .MuiButton-outlined": {
      color: "#3c7ef3 !important",
      borderColor: "#3c7ef3 !important",
      "&:focus, &:hover": {
        color: "#3c7ef3 !important",
        backgroundColor: "transparent !important",
      },
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
    borderBottom: "1px solid lightgray",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },
  headerIcon: {
    height: 50,
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    margin: theme.spacing(0, 0, 0.625, 0),
  },
  subtitle: {
    color: "var(--dark_blue)",
    margin: 0,
  },
  helpSection: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },

  refreshButton: {
    color: "var(--light_blue)",
    textTransform: "capitalize",
    borderColor: "var(--light_blue)",
    marginRight: "12px",
    "&:focus,&:hover": {
      background: "transparent",
    },
  },
  backButton: {
    backgroundColor: "var(--light_blue)",
    color: "#fff",
    textTransform: "capitalize",
    marginRight: "12px",
    "&:focus,&:hover": {
      backgroundColor: "var(--light_blue)",
    },
  },
}));

const ImportGoogleSheet = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);

  const handleBack = () => {
    dispatch(updateImportContactOptionTab({ importContactOptionTab: FILE_IMPORT_TAB.TAB_DEFAULT }));
  };

  const handleRefresh = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/upload_4052950.svg`}
            alt=''
            className={classes.headerIcon}
          />
          <div className={classes.headerText}>
            <h5 className={classes.title}>Import From Google Sheets</h5>
            <p className={classes.subtitle}>Add contact using google sheets</p>
          </div>
        </div>

        <div className={classes.helpSection}>
          <Button onClick={handleBack} variant='contained' className={classes.backButton} startIcon={<BackIcon />}>
            Back
          </Button>
          <Button
            onClick={handleRefresh}
            variant='outlined'
            className={classes.refreshButton}
            startIcon={<RefreshIcon />}
          >
            Refresh
          </Button>
        </div>
      </div>
      <ImportGoogleSheetTable refresh={refresh} />
    </div>
  );
};

export default ImportGoogleSheet;
