import React from "react";
import {
    InputBase,
    MenuItem,
    Select,
    makeStyles,
    withStyles
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    menuItem: {
        "&:hover": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
        "&.Mui-selected": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
    },
    menuItemLineThrough: {
        "&.MuiListItem-root": {
            textDecoration: "line-through",
            color: "#ee1b54",
        },
    },
    deleted: {
        fontSize: "9px"
    }
}));

const BasicInputSelect = withStyles((theme) => ({
    root: {
        "label + &": {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        backgroundColor: "white !important",
        position: "relative",
        color: "#3C7EF3 !important",
        fontSize: "14px !important",
        margin: "0 !important",
        textAlign: "start !important",
        width: '230px',
        padding: " 9px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),

        "&:focus": {
            borderRadius: "4px !important",
            boxShadow: "unset !important",
            backgroundColor: "#fff !important"
        },

    },
}))(InputBase);

const CustomDropdown = ({ options, mapping = { label: "title", value: "id" }, defaultText = "Select", name = "default", onChange, value = "", disabled = false, selectedOption }) => {
    const styles = useStyles();

    const handleSelectChange = (event) => {
        onChange(event);

        if (selectedOption) {
            const selected = options.find((option) => (option[mapping.value] === event.target.value));
            if (selected) {
                selectedOption(selected);
            }
        }
    };

    const menuItems = () => {

        return options.map((option, index) => {
            return (
                <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} key={index} value={option[mapping.value]}>
                    {option[mapping.label]}
                </MenuItem>
            )
        }
        )
    }

    return (
        <Select

            defaultValue={value}
            value={value}
            onChange={handleSelectChange}
            name={name}
            displayEmpty
            disabled={disabled}
            input={<BasicInputSelect selectedOption={selectedOption} />}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    style: {
                        maxHeight: "280px"
                    },
                },
                getContentAnchorEl: null,
            }}
        >
            <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} value="" disabled>
                {defaultText}
            </MenuItem>
            {menuItems()}

        </Select>
    );
};

export default CustomDropdown;