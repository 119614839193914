import { Link } from "react-router-dom";
import Helper from "../../globals/Helper";
import { IconList } from "../../globals/IconList";
import Utils from "../rightSide/Helpers/Utils";
import ColumnFilter from "./ColumnFilter";
import ContactListOptions from "./ContactListOptions";
import ShowSpecificContactCampaigns from "./ShowSpecificContactCampaigns";
import ShowSpecificContactTags from "./tags/ShowSpecificContactTags";
import ShowSpecificContactPipelines from "./pipelines/ShowSpecificContactPipelines";
import { Button } from "@material-ui/core";

const userTimezoneRaw = Utils.getAccountData('userTimezoneRaw');


const thanksIoActive = (Utils.getAccountData('customMenu')['63'] && Utils.getAccountData('customMenu')['63']
    && Utils.getAccountData('customMenu')['63'] !== null && Utils.getAccountData('customMenu')['63'] !== "") ? true : false

const zenDirectActive = (Utils.getAccountData('customMenu')['61'] && Utils.getAccountData('customMenu')['61']
    && Utils.getAccountData('customMenu')['61'] !== null && Utils.getAccountData('customMenu')['61'] !== "") ? true : false

const WithoutQueryCampaignColumns = (
    addColumnInOmitList, queryType1, submitColumnFilterData, /*props.*/contactListSelectedColumnFilterData, updateTable,
    /*props.*/contactListSelectedOmitList, setShowDateRange, subUserList, pipelineList, handleRoute, openStageChangeModal,
    /*props.*/contactListStageListForPipeline, updateStageListForColumnFilter, sourceList, tagList, /*props.*/contactListColumnFilterMultipleSelectedData,
    /*props.*/contactListColumnFilterMultiSelectedDatalabel, queryType2, queryType3, queryType4, datatableRerender,
    openDialer, updateSorting, queryType5, queryType6, getUserTagListNew, userListInObject, contactSourceInObject
) => [
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Name & Email <span className="hide-eye-icon-contactist"
                onClick={() => addColumnInOmitList('name_email')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType1]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType1[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Number & Email'}
                    filterName={'first_name'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                />
            </span>
            </div>,
            selector: row => row['first_name'],
            // sortable: true,
            omit: contactListSelectedOmitList.includes('name_email') ? true : false,
            cell: (row, index) => {
                let name = ''
                let number = ''
                let email = ''
                let companyName = ''
                if (!Helper.isEmpty(row.first_name) && !Helper.isEmpty(row.last_name)) {
                    name = row.first_name + ' ' + row.last_name
                }
                else if (!Helper.isEmpty(row.first_name)) {
                    name = row.first_name
                }
                else if (!Helper.isEmpty(row.last_name)) {
                    name = row.last_name
                }
                if (!Helper.isEmpty(row.number)) {
                    number = Utils.formatPhoneNumber(row.number)
                }
                if (!Helper.isEmpty(row.email)) {
                    email = row.email
                }
                if (!Helper.isEmpty(row.company_name)) {
                    companyName = row.company_name
                }
                return (
                    <td className="valign-wrapper">
                        {/* <div onClick={()=>goToContactDetails(row.id, index)} className="waves-effect has-sidenav" data-target="marketing-slide-out"> */}
                        {/* <Link to={`/contacts/${row.id}`} className="waves-effect has-sidenav" data-target="marketing-slide-out"> */}
                        <Link
                            to={{
                                pathname: `/contacts/${row.id}`, search: `?currentIndex=${index}`
                            }}
                            className="waves-effect has-sidenav" data-target="marketing-slide-out">

                            <ul className="v2__name_email">
                                <li className="v2__list_item">
                                    {IconList.nameIconInList}
                                    <span className="information-name truncate">{name}</span></li>

                                <li className="v2__list_item">
                                    {IconList.numberIconInList}
                                    <span className="information-name">{number}</span></li>

                                <li className="v2__list_item">
                                    {IconList.EmailIconInList}
                                    <span className="information-name truncate">{email}</span></li>


                                <li className="v2__list_item">
                                    {IconList.businessIconInList}
                                    <span className="information-name truncate">{companyName}</span></li>
                            </ul>
                        </Link>
                    </td>
                )
            },
            minWidth: '250px', maxWidth: '250px',
            /*
            please do not remove those code
            cell: row => <button row={row} onClick={(event) => {
                console.log(event)
            }} >hello</button>,
            allowOverflow: true,
            button: true,
            width: '56px',
            grow: 2, //do not know this props
            ignoreRowClick: true
            right: true,
            center: true
            */
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Name  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('name')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType1]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType1[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Number & Email'}
                    filterName={'first_name'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                />
            </span>
            </div>,
            selector: row => row['name'],
            // sortable: true,
            omit: contactListSelectedOmitList.includes('name') ? true : false,
            cell: (row, index) => {
                let name = ''
                if (!Helper.isEmpty(row.first_name) && !Helper.isEmpty(row.last_name)) {
                    name = row.first_name + ' ' + row.last_name
                }
                else if (!Helper.isEmpty(row.first_name)) {
                    name = row.first_name
                }
                else if (!Helper.isEmpty(row.last_name)) {
                    name = row.last_name
                }
                return (
                    <td className="valign-wrapper">
                        <Link to={{
                            pathname: `/contacts/${row.id}`, search: `?currentIndex=${index}`
                        }}

                            className="waves-effect has-sidenav" data-target="marketing-slide-out">

                            <ul className="v2__name_email">
                                <li className="v2__list_item">
                                    {IconList.nameIconInList}
                                    <span className="information-name">{name}</span></li>
                            </ul>
                        </Link>
                    </td>
                )
            },
            minWidth: '250px', maxWidth: '250px',
            /*
            please do not remove those code
            cell: row => <button row={row} onClick={(event) => {
                console.log(event)
            }} >hello</button>,
            allowOverflow: true,
            button: true,
            width: '56px',
            grow: 2, //do not know this props
            ignoreRowClick: true
            right: true,
            center: true
            */
        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Option <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('option')}>{IconList.hideEyeIcon}</span></span>
            ,
            // selector: row => row['email'],
            sortable: false,
            minWidth: '310px', maxWidth: '310px',
            omit: contactListSelectedOmitList.includes('option') ? true : false,

            cell: (row, index) => {

                return (
                    <ContactListOptions row={row} setShowDateRange={setShowDateRange}
                        thanksIoActive={thanksIoActive} zenDirectActive={zenDirectActive}
                        openDialer={openDialer} index={index} />
                )
            },

        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Owner <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('owner')}>{IconList.hideEyeIcon}</span>

                {(subUserList !== undefined && subUserList != null) &&
                    <ColumnFilter
                        inputArray={['select']}
                        placeHolders={['select owner']}
                        datalist={[]}
                        emptyValueShow={[true]}
                        emptyValueTitle={['Select Owner']}
                        subUserList={subUserList}
                        defaultValue={[]}
                        mapping={["full_name"]}
                        buttonAvailable={true}
                        buttonText={'Filter Owner'}
                        filterName={'user'}
                        onSubmit={submitColumnFilterData}
                        columnFilterData={contactListSelectedColumnFilterData}
                        updateTable={updateTable}

                    />
                }

            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('owner') ? true : false,

            cell: row => {
                // let ownerName = (row.user !== undefined && row.user != null) ? Helper.isEmpty(row.user.full_name) ? '' : row.user.full_name : ''
                let ownerName = (userListInObject && userListInObject[row.user_id]) ? userListInObject[row.user_id] : ''
                return (
                    ownerName
                )
            }

        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Pipelines <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('pipelines')}>{IconList.hideEyeIcon}</span>
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('pipelines') ? true : false,
            cell: (row, index) => <ShowSpecificContactPipelines row={row} index={index} pipelineList={pipelineList} openStageChangeModal={openStageChangeModal} willShow={"pipeline"} />
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Stages <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('stages')}>{IconList.hideEyeIcon}</span>

                {(pipelineList !== undefined && pipelineList != null) &&
                    <ColumnFilter
                        inputArray={['select', 'select']}
                        datalist={[pipelineList, contactListStageListForPipeline]}
                        emptyValueShow={[true, true]}
                        emptyValueTitle={['Select Pipeline', 'Select Stage']}
                        pipelineList={pipelineList}
                        stageListForPipeline={contactListStageListForPipeline}
                        defaultValue={[]}
                        mapping={["title", 'stage']}
                        buttonAvailable={true}
                        buttonText={'Filter Stage'}
                        filterName={'stage'}
                        onSubmit={submitColumnFilterData}
                        columnFilterData={contactListSelectedColumnFilterData}
                        updateStageListForColumnFilter={updateStageListForColumnFilter}
                        updateTable={updateTable}

                    />
                }

            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('stages') ? true : false,
            cell: (row, index) => <ShowSpecificContactPipelines row={row} index={index} pipelineList={pipelineList} openStageChangeModal={openStageChangeModal} willShow={"stage"} />
        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Campaigns <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('campaigns')}>{IconList.hideEyeIcon}</span></span>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('campaigns') ? true : false,

            cell: row => <ShowSpecificContactCampaigns row={row} isShowPausedCampaigns={true} />

        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Active Campaigns <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('active_campaign')}>{IconList.hideEyeIcon}</span></span>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('active_campaign') ? true : false,

            cell: row => <ShowSpecificContactCampaigns row={row} isShowPausedCampaigns={false} />
        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>SkipTrace <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('SkipTrace')}>{IconList.hideEyeIcon}</span></span>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: (contactListSelectedOmitList.includes('SkipTrace') || !Utils.getAccountData('skipTrace')) ? true : false,

            cell: row => {
                // let ownerName = (row.user !== undefined && row.user != null) ? Helper.isEmpty(row.user.full_name) ? '' : row.user.full_name : ''
                let text;
                if (row.contact_additional_informations && row.contact_additional_informations.skip_trace_status === 'TRACED') {
                    if (row.contact_additional_informations.skip_trace_date) {
                        try {
                            text = window.globalTimezoneConversionToDifferentTimezone(
                                row.contact_additional_informations.skip_trace_date,
                                'UTC',
                                '',
                                'from_now'
                            );
                        } catch ($e) {
                            text = 'Traced'

                        }
                    } else {
                        text = 'Traced'
                    }
                }
                if (text) {
                    return (
                        <td className="valign-wrapper">
                            <Button
                                class="accent--bg--text--color"
                                variant="contained"
                                style={{ padding: "8px 10px", borderRadius: "5px", border: "solid 0px" }}
                            >
                                {text}
                            </Button>
                        </td >
                    )
                } else {
                    return null;
                }

            }
        },

        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}> Zip Code
                <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('zip')}>{IconList.hideEyeIcon}</span>
                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'value', '']}
                    datalist={[queryType5]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType5[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter By zip'}
                    filterName={'zip'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                />
                {/* <span className="toggle__icon__email contact-table-column-filter-icon" onClick={()=>updateSorting('zip')}>{IconList.sorting}</span> */}
            </span>
            </div>,
            selector: row => row['zip'],
            // sortable: true,
            omit: contactListSelectedOmitList.includes('zip') ? true : false,
            cell: (row, index) => {

                return (
                    <td className="valign-wrapper">
                        {row.zip ?
                            <div
                                className="waves-effect has-sidenav" data-target="marketing-slide-out">

                                <ul className="v2__name_email">
                                    <li className="v2__list_item">
                                        <span className="information-name">{row.zip}</span></li>
                                </ul>
                            </div>
                            :
                            <></>
                        }
                    </td>
                )
            },
            minWidth: '250px', maxWidth: '250px',
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Age
                <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('age')}>{IconList.hideEyeIcon}</span>
                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'value', '']}
                    datalist={[queryType6]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType6[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter By age'}
                    filterName={'birth_date'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}
                />
            </span>
            </div>,
            selector: row => row['age'],
            // sortable: true,
            omit: contactListSelectedOmitList.includes('age') ? true : false,
            cell: (row, index) => {

                let birthInfo = ''
                if (row.birth_date && row.birth_date.trim() !== '') {
                    birthInfo = window.globalTimezoneConversionToDifferentTimezone(row.birth_date, 'UTC', '', 'from_now');

                    birthInfo = birthInfo.replace('ago', 'old');
                }
                return (
                    <td className="valign-wrapper">
                        {birthInfo !== '' ?
                            <div
                                className="waves-effect has-sidenav" data-target="marketing-slide-out">

                                <ul className="v2__name_email">
                                    <li className="v2__list_item">
                                        <span className="information-name">{birthInfo}</span></li>
                                </ul>
                            </div>
                            :
                            <></>
                        }
                    </td>
                )
            },
            minWidth: '250px', maxWidth: '250px',
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Address  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('address')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType1]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType1[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Address'}
                    filterName={'address'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('address') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.address)) {
                    return (
                        row.address
                    )
                } else
                    return null
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>city
                <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('city')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType5]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType5[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter by city'}
                    filterName={'city'}
                    onSubmit={submitColumnFilterData}
                    // active={false}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('city') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.city)) {
                    return (
                        row.city
                    )
                } else
                    return null
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>state  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('state')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType5]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType5[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter by state'}
                    filterName={'state'}
                    onSubmit={submitColumnFilterData}
                    // active={false}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('state') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.state)) {
                    return (
                        row.state
                    )
                } else
                    return null
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>country  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('country')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType5]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType5[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter by country'}
                    filterName={'country'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('country') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.country)) {
                    return (
                        row.country
                    )
                } else
                    return null
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Source  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('source')}>{IconList.hideEyeIcon}</span>

                {(sourceList !== undefined && sourceList != null) &&
                    <ColumnFilter
                        inputArray={['select']}
                        placeHolders={['select Source']}
                        datalist={[]}
                        emptyValueShow={[true]}
                        emptyValueTitle={['Select Source']}
                        sourceList={sourceList}
                        defaultValue={[]}
                        mapping={["source_title"]}
                        buttonAvailable={true}
                        buttonText={'Filter Source'}
                        filterName={'source'}
                        onSubmit={submitColumnFilterData}
                        columnFilterData={contactListSelectedColumnFilterData}
                        updateTable={updateTable}

                    />
                }

            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('source') ? true : false,

            cell: row => {

                // let sourceTitle = (row.contact_source_type !== undefined && row.contact_source_type != null) ? Helper.isEmpty(row.contact_source_type.source_title) ? '' : row.contact_source_type.source_title : ''
                let sourceTitle = (row.source && contactSourceInObject[row.source]) ? contactSourceInObject[row.source] : ''
                return (
                    sourceTitle
                )
            }

        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Tags  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('tags')}>{IconList.hideEyeIcon}</span>

                {tagList &&
                    <ColumnFilter
                        inputArray={['multiSelect', 'multiSelect']}
                        placeHolders={['import', 'Export']}
                        datalist={[tagList, tagList]}
                        emptyValueShow={[true, true]}
                        emptyValueTitle={['Include', 'Exclude']}
                        tagList={tagList}
                        defaultValue={[]}
                        mapping={["name", 'name']}
                        buttonAvailable={true}
                        buttonText={'Filter Tag'}
                        filterName={'tags'}
                        onSubmit={submitColumnFilterData}
                        columnFilterData={contactListSelectedColumnFilterData}
                        columnFilterMultipleSelectedData={contactListColumnFilterMultipleSelectedData}
                        columnFilterMultiSelectedDatalabel={contactListColumnFilterMultiSelectedDatalabel}
                        updateTable={updateTable}
                        needToLoadViaScroll={true}
                        fetchUrlForScroll={getUserTagListNew}
                    />
                }

            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('tags') ? true : false,
            cell: (row, index) => <ShowSpecificContactTags row={row} datatableRerender={datatableRerender} index={index} />
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Created  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('created_at')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Created'}
                    filterName={'created_at'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('created_at') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.created_at)) {
                    return (
                        window.globalTimezoneConversionToDifferentTimezone(row.created_at, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')
                    )
                }
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Updated  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('updated_at')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Updated'}
                    filterName={'updated_at'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('updated_at') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.updated_at)) {
                    return (
                        window.globalTimezoneConversionToDifferentTimezone(row.updated_at, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')
                    )
                }
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Deal value/Price  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('price')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType2]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType2[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Price'}
                    filterName={'deal_value'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('price') ? true : false,
            cell: row => {

                if (!Helper.isEmpty(row.deal_value)) {
                    return (
                        row.deal_value
                    )
                } else
                    return null
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Activity <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_activity')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Activity'}
                    filterName={'last_activity'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_activity') ? true : false,
            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_activity !== undefined && row.contact_activity.last_activity != null) &&

                                <li>{Helper.limitMessage(row.contact_activity.last_activity, 75)}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Upcoming Task <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('upcoming_task')}>{IconList.hideEyeIcon}</span></span>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('upcoming_task') ? true : false,
            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.next_task !== undefined && row.contact_activity.next_task != null) &&

                                <li>{row.contact_activity.next_task}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Contact <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_communication')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Contact'}
                    filterName={'last_communication'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_communication') ? true : false,
            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_communication !== undefined && row.contact_activity.last_communication != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_communication, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Email Sent  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_email_sent')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Email Sent'}
                    filterName={'last_email_sent'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_email_sent') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_email_sent !== undefined && row.contact_activity.last_email_sent != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_email_sent, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Email Received <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_email_received')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Email Received'}
                    filterName={'last_email_received'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_email_received') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_email_received !== undefined && row.contact_activity.last_email_received != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_email_received, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Emails Sent <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('email_sent')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType2]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType2[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Emails Sent'}
                    filterName={'emails_sent'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('email_sent') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.emails_sent !== undefined && row.contact_activity.emails_sent != null) &&

                                <li>{row.contact_activity.emails_sent}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Emails Received  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('email_received')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType2]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType2[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Emails Received'}
                    filterName={'emails_received'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('email_received') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.emails_received !== undefined && row.contact_activity.emails_received != null) &&

                                <li>{row.contact_activity.emails_received}</li>
                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Call Made <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_call_made')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Calls Made'}
                    filterName={'last_call_made'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_call_made') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_call_made !== undefined && row.contact_activity.last_call_made != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_call_made, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Call Received <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_call_received')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Calls Received'}
                    filterName={'last_call_received'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_call_received') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_call_received !== undefined && row.contact_activity.last_call_received != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_call_received, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Calls Made  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('calls_made')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType2]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType2[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Calls Made'}
                    filterName={'calls_made'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('calls_made') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.calls_made !== undefined && row.contact_activity.calls_made != null) &&

                                <li>{row.contact_activity.calls_made}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Calls Received <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('calls_received')}>{IconList.hideEyeIcon}</span></span>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('calls_received') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.calls_received !== undefined && row.contact_activity.calls_received != null) &&

                                <li>{row.contact_activity.calls_received}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Text Sent <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_text_sent')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Text Sent'}
                    filterName={'last_text_sent'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_text_sent') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_text_sent !== undefined && row.contact_activity.last_text_sent != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_text_sent, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Last Text Received <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('last_text_received')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input', 'select']}
                    placeHolders={['', '', '']}
                    datalist={[queryType3, '', queryType4]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType3[0], '', queryType4[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Last Text Received'}
                    filterName={'last_text_received'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number', '']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('last_text_received') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.last_text_received !== undefined && row.contact_activity.last_text_received != null) &&

                                <li>{window.globalTimezoneConversionToDifferentTimezone(row.contact_activity.last_text_received, 'UTC', userTimezoneRaw, 'MM/DD/YYYY hh:mm a')}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Texts Sent  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('texts_sent')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType2]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType2[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Texts Sent'}
                    filterName={'texts_sent'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('texts_sent') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.texts_sent !== undefined && row.contact_activity.texts_sent != null) &&

                                <li>{row.contact_activity.texts_sent}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        {
            name: <div className="pop__hover__email"><span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Texts Received  <span className="hide-eye-icon-contactist" onClick={() => addColumnInOmitList('texts_received')}>{IconList.hideEyeIcon}</span>

                <ColumnFilter
                    inputArray={['select', 'input']}
                    placeHolders={['', 'keyword', '']}
                    datalist={[queryType2]}
                    emptyValueShow={[false, false]}
                    emptyValueTitle={['Select query']}
                    mapping={['']}
                    defaultValue={[queryType2[0]]}
                    buttonAvailable={true}
                    buttonText={'Filter Texts Received'}
                    filterName={'texts_received'}
                    onSubmit={submitColumnFilterData}
                    columnFilterData={contactListSelectedColumnFilterData}
                    updateTable={updateTable}
                    inputTypeSecurity={['', 'number']}

                />
            </span>
            </div>,
            // selector: row => Utils.formatPhoneNumber(row['number']),
            sortable: false,
            minWidth: '250px', maxWidth: '250px',
            omit: contactListSelectedOmitList.includes('texts_received') ? true : false,

            cell: row => {
                return (
                    <td className="Campaigns-content">
                        <ul>
                            {(row.contact_activity !== undefined && row.contact_activity != null &&
                                row.contact_activity.texts_received !== undefined && row.contact_activity.texts_received != null) &&

                                <li>{row.contact_activity.texts_received}</li>

                            }
                        </ul>
                    </td>)
            }
        },
        // {
        //     name: <span style={{ fontWeight: 'bold', color: '#546376', fontSize: '16px', lineHeight: '22px' }}>Form</span>,
        //     // selector: row => Utils.formatPhoneNumber(row['number']),
        //     sortable: false,
        //     minWidth: '250px',maxWidth: '250px',
        //     omit: contactListSelectedOmitList.includes('form') ? true : false,
        //     cell: row => {

        //         if (!Helper.isEmpty(row.form)) {
        //             return (
        //                 row.form
        //             )
        //         } else
        //             return null
        //     }
        // }
    ];

export default WithoutQueryCampaignColumns;