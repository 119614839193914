import React, { Fragment, useContext } from "react";
import StepProgressBar from "./StepProgressBar";
import MapContact from "./mapContact/MapContact";
import { FILE_IMPORT_STEP } from "../Constants";
import UploadStep from "./UploadStep";
import { DataCleanContext } from "../StateProvider";
import CompleteStep from "./CompleteStep";
import "./CustomOverwrite.css";

const FileUpload = ({ onChangeTab, onSelectFile }) => {
  const { state } = useContext(DataCleanContext);
  const getFileUploadStep = () => {
    switch (state.activeStep) {
      case FILE_IMPORT_STEP.STEP_IMPORT_MAPPING:
        return <MapContact onChangeTab={onChangeTab} onSelectFile={onSelectFile}/>;
      case FILE_IMPORT_STEP.STEP_UPLOAD:
        return (
          <UploadStep onChangeTab={onChangeTab} onSelectFile={onSelectFile} />
        );
      case FILE_IMPORT_STEP.STEP_SUCCESS:
        setTimeout(() => {
          window.globalImportEnhancePopupData(true, {
            requestModule: "CONTACT",
            callback: (res) => {
            },
          });
        }, 6000);
        return <CompleteStep />;
      default:
        return <div>No Step</div>;
    }
  };

  return (
    <Fragment>
      <StepProgressBar currentStep={state.activeStep} />
      {getFileUploadStep()}
    </Fragment>
  );
};

export default FileUpload;
