import React, {Fragment, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {BasicInput} from "../../contact/leftSideV2/LeftSideV2Styles";
import {useDispatch, useSelector} from "react-redux";
import {
    selectImportSpreadsheetContactState
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import {updateDetails} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import Utils from "../../../helpers/Utils";
import importSpreadsheetContactService
    from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
import Loading from "./Loading";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    header: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: theme.spacing(2),
    },
    headerTitle: {
        fontWeight: 600,
    },
    buttonGroup: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1.5),
    },
    scrollContainer: {
        maxHeight: "calc(100vh - 299px)",
        overflow: "auto",
    },
    contentWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
    },
    section: {
        padding: theme.spacing(4),
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
    },
    sectionTitle: {
        marginBottom: "10px",
        textAlign: "center"
    },
    connectionPolicy: {
        marginTop: "30px"
    }
}));

const Connect = ({ moveToNextStep }) => {
    const dispatch = useDispatch();
    const {
        details: {
            email,
            campaignId
        }
    } = useSelector(selectImportSpreadsheetContactState);
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const classes = useStyles();

    const handleMoveNext = async () => {
        try{
            if (!email){
                window.showNotification("error", "Email address is required");
                return;
            }

            if (!Utils.validateEmail(email)){
                window.showNotification("error", "Invalid email address");
            }

            let pState = `common:${Utils.getAccountData("userId")}`;

            if (campaignId){
                pState += `:${campaignId}`;
            }

            let state = window.btoa(pState);

            setSubmitting(true);
            const resp = await importSpreadsheetContactService.googleAuthUrl({ email: email, state: state });

            if (resp?.success && resp?.data){
                if (resp?.data?.permission === false){
                    setIsLoading(true);
                    window.showNotification("success", "Please wait while redirecting...");
                    setTimeout(()=>{
                        window.open(resp.data.url, "_self");
                    },2000);
                }else if (resp?.data?.permission === true){
                    moveToNextStep();
                }
            }else {
                window.showNotification("error", "Failed to generate auth url");
            }
        }catch (err){
            console.log(err);
            setIsLoading(false);
            window.showNotification("error", "Failed to generate auth url");
        }finally {
            setSubmitting(false);
        }
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h5" color="textPrimary" className={classes.headerTitle}>
                    Connect
                </Typography>

                <div className={classes.buttonGroup}>
                    <Button disabled={submitting} variant="contained" color="primary" disableElevation onClick={handleMoveNext}>
                        Next
                    </Button>
                </div>
            </div>

            <div className={classes.contentWrapper}>
                <div className={classes.section}>
                    {
                        isLoading ?
                            <Loading loaderText={'Please wait while redirecting...'}/> :
                            <Fragment>
                                <Typography variant="h6" className={classes.sectionTitle}>
                                    Provide Your Google Email Address
                                </Typography>

                                <BasicInput
                                    fullWidth
                                    name="email"
                                    placeholder="ex. example@gmail.com"
                                    value={email}
                                    onChange={(event) => {
                                        dispatch(updateDetails({email: event.target.value}))
                                    }}
                                />

                                <Typography variant="subtitle2" className={classes.connectionPolicy}>
                                    <b>CRMConnection</b> use and transfer to any other app of information received from
                                    Google APIs will adhere to <br/> <a
                                    href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                                    target="_blank">Google API Services User Data Policy</a>, including the Limited Use
                                    requirements.
                                </Typography>
                            </Fragment>
                    }
                </div>
            </div>
        </div>
    )
};

export default Connect;