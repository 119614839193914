import React, { useState, useEffect, Fragment } from 'react';
import moment from 'moment';
import { updateSingleCustomField } from '../../../../api/contactApi';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import { DropdownHelper } from '../../../globals/DropdownHelper'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
    KeyboardDateTimePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import { formatDateWithoutZone } from '../../../globals/TimezoneConversion';
import { Link } from 'react-router-dom';
// export const WithCustomField = (data,field, formType) => {

export const WithCustomField = (props) => {

    //  constructor(props) {
    //      super(props);

    //      this.userValue = '';
    //      this.title = props.data.title;
    //      let customValue = [];
    //      if(props.value.length > 0){
    //          customValue = props.value.filter(value => props.data.id == value.user_custom_field_id)
    //      }

    //      if(customValue.length > 0){
    //          this.userValue = customValue[0].value;
    //      }

    //      this.state = {
    //          isEdit: false,
    //          value: this.userValue
    //      }
    //  }
    const [isEdit, setIsEdit] = useState(false)
    const [value, setValue] = useState('')
    const [previousValue, setPreviousValue] = useState('')

    useEffect(() => {

        try {
            if (props.value !== undefined && props.value != null && Array.isArray(props.value)) {
                for (let j = 0; j < props.value.length; j++) {
                    if (props.data.id == props.value[j].user_custom_field_id) {
                        setPreviousValue(props.value[j].value)
                        let values = props.value[j].value.replace(/null,/g, '').replace(/,null/g, '')
                        setValue(values)
                    }
                }
            }
        } catch (e) {
            console.log(e)
        }

    }, [])


    const onSubmit = (e) => {
        e.preventDefault();
        let tempVal = value;
        if (props.data.type_id === 11) {
            tempVal = moment(new Date(value)).format("YYYY-MM-D HH:mm:ss");
        }

        updateSingleCustomField({
            custom_field_id: props.data.id,
            value: tempVal,
            contact_id: props.contact_id
        }).then(res => {
            if (res.data.status === 'success') {
                window.showNotification('SUCCESSS', res.data.html);
                setIsEdit(false)
                //  this.userValue = this.state.value;
                //  this.setState({
                //      isEdit : false
                //  })
                setPreviousValue(value)
            } else if (res.data.status === 'error') {
                window.showNotification('ERROR', res.data.html);
            }
        });

    };

    const onChange = (e) => {
        setValue(e.target.value)

    };

    const onDropdownChange = (value) => {
        setValue(value)
    }

    const onDateChange = (day, inputString) => {


        try {
            if (moment(inputString).isValid()) {
                setValue(moment(new Date(inputString)).format("YYYY-MM-D"))
            } else {
                setValue('')
            }
        }
        catch (e) {
            console.log(e)
        }

        // try{
        // setValue( moment(new Date(inputString.toLocaleString())).format("YYYY-MM-D"))
        // }
        // catch(e){
        //     console.log(e)
        // }

    };

    const onDatetimeChange = (day, inputString) => {
        try {
            if (moment(inputString).isValid()) {
                setValue(moment(new Date(inputString)).format("MM-D-YYYY HH:mm:ss"))
            } else {
                setValue('')
            }
        }
        catch (e) {
            console.log(e)
        }
    };

    const onCheckBoxChange = (e) => {

        let check = e.target.checked;

        let data = e.target.value;
        let newValue = e.target.value;

        if (check) {
            if (value === "")
                data = newValue;
            else
                data = value + "," + newValue;
        } else {
            if (value.indexOf(newValue) == 0) {
                if (value.indexOf(newValue + ',') == 0)
                    data = value.replace(newValue + ',', "");
                else
                    data = value.replace(newValue, "");
            } else {
                data = value.replace(',' + newValue, "");
            }
        }


        setValue(data)

    };

    return (

        <Fragment>
            <div className="___more_info__each_info___item">
                <div className="___more_info__content w-100">
                    <div className="___more_info__title">{props.data.title}</div>

                    {
                        !isEdit ? (
                            <React.Fragment>
                                {/* <input classN="text-field text-gray" type="text" disabled readOnly value={this.state.value}/> */}
                                <div className={`custom-field-contact ___more_info__value }`}>
                                    {value === '' ?
                                        <span className="font-style-italic">No {props.data.title} added</span>
                                        :
                                        <span>{props.formType === 'date' ? formatDateWithoutZone(value, 'MM/DD/YYYY') :
                                            props.formType !== 'website' ? value :
                                                <Link to={{ pathname: `//${value.replace(/^https?:\/\//, '')}` }}
                                                    target="_blank">{value}</Link>}</span>

                                    }
                                    <span className="small-round-icon">
                                        <i className="material-icons" onClick={() => setIsEdit(!isEdit)}>
                                            edit
                                        </i>
                                    </span>
                                </div>

                            </React.Fragment>
                        ) : (
                            <CustomForm
                                defaultValues={props.data}
                                formType={props.formType}
                                onSubmit={onSubmit}
                                onChange={onChange}
                                onCheckBoxChange={onCheckBoxChange}
                                onDateChange={onDateChange}
                                onDatetimeChange={onDatetimeChange}
                                onClick={() => { setIsEdit(!isEdit); setValue(previousValue) }}
                                max_length={props.data.max_length}
                                userValue={value}
                                title={props.data.title}
                                onDropdownChange={onDropdownChange}
                            />
                        )
                    }

                </div>
            </div>

        </Fragment>
    );


}
const CustomForm = (props) => {

    const DateField = (props) => {

        let defaultDate = (props.defaultValue !== undefined && props.defaultValue != null && props.defaultValue !== '') ?
            props.defaultValue : null

        return (
            // <DayPickerInput
            //     name="startDate"
            //     value={props.defaultValue}
            //     selected={props.defaultValue}
            //     onDayChange={props.onDateChange}
            //     className="form-control"
            //     />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">

                    <KeyboardDatePicker
                        // margin="normal"
                        // id="date-picker-dialog"
                        // label="Date picker dialog"
                        format="MM/dd/yyyy"
                        value={defaultDate}
                        onChange={(date, input) => props.onDateChange(date, input)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        className="add_activity_text_field radius-5 white"
                        maxDateMessage=""
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        )
    }

    const DatetimeField = (props) => {

        let defaultDate = (props.defaultValue !== undefined && props.defaultValue != null && props.defaultValue !== '') ?
            props.defaultValue : null

        return (
            // <DayPickerInput
            //     name="startDate"
            //     value={props.defaultValue}
            //     selected={props.defaultValue}
            //     onDayChange={props.onDateChange}
            //     className="form-control"
            //     />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justifyContent="space-around">

                    <KeyboardDateTimePicker
                        // margin="normal"
                        // id="date-picker-dialog"
                        // label="Date picker dialog"
                        format="MM-dd-yyyy HH:mm:ss"
                        value={defaultDate}
                        onChange={(date, input) => props.onDatetimeChange(date, input)}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        className="add_activity_text_field radius-5 white"
                        maxDateMessage=""
                    />
                </Grid>
            </MuiPickersUtilsProvider>
        )
    }

    const NumberField = (props) => {

        let defaultValue = (typeof props.defaultValue != 'undefined') ? props.defaultValue : 0
        return (
            <input onChange={props.onChange} value={defaultValue} type="number" name="total_amount" maxLength={props.max_length} />
        )
    }


    const RadioField = (props) => {
        let content = props.defaultValues.map((data, index) => {
            let checked = (data == props.selected) ? "checked" : null
            return (
                <li key={index}>
                    <FormControlLabel
                        control={<Radio checked={checked} onChange={props.onChange} name="example_2" value={data} />}
                        label={data}
                    />
                </li>
            )
        });


        return (
            <div className="dropdown-wrapper open">

                <div className="dropdown-content-wrapper">
                    <div className="dropdown-content-inner contact-custom-field-checkbox">
                        <ul>
                            {content}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }

    const CheckBoxField = (props) => {

        let userValues = [];
        if (props.userValues !== undefined && props.userValues != null & props.userValues !== '') {

            userValues = props.userValues.split(',');;

        }
        let content = props.defaultValues.map((data, index) => {
            var checked = null;
            checked = (userValues.indexOf(data) > -1) ? "checked" : null

            return (
                <li key={index}>
                    <FormControlLabel
                        control={<Checkbox checked={checked} onChange={props.onCheckBoxChange} name="example_2" value={data} />}
                        label={data}
                    />
                </li>
            )
        });


        return (
            <div className="dropdown-wrapper open">

                <div className="dropdown-content-wrapper">
                    <div className="dropdown-content-inner contact-custom-field-checkbox">
                        <ul>
                            {content}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
    const SelectBoxField = () => {



        return (
            <DropdownHelper
                datalist={props.defaultValues.default_values}
                noneFieldRequired={true}
                noneFieldTitle={`Select ${props.title}`}
                className=""
                mapping={null}
                menuItemClassName=""
                updateSelectedData={props.onDropdownChange}
                selectedValue={props.userValue}
                updateDatatable={null}
                dropdownHeader={'campaignChange'}
            />
        )
    }

    var input = null;
    if (props.formType === 'date') {
        input = (<DateField onDateChange={props.onDateChange} defaultValue={props.userValue} />)
    } else if (props.formType === 'radio') {
        input = (<RadioField onChange={props.onChange} selected={props.userValue}
            defaultValues={props.defaultValues.default_values} />)
    } else if (props.formType === 'number') {
        // let value = (typeof props.defaultValue != 'undefined') ? props.defaultValue : ''
        // input = (<NumberField onChange={props.onChange} defaultValue={props.userValue}/>)
        input = <input className="details-custom-text-field" onChange={props.onChange} value={props.userValue !== undefined ? props.userValue : ''} type="number" name="total_amount" maxLength={props.max_length} />
    } else if (props.formType === 'checkbox') {
        input = (<CheckBoxField onCheckBoxChange={props.onCheckBoxChange} defaultValues={props.defaultValues.default_values} userValues={props.userValue} />)
    } else if (props.formType === 'select') {
        input = (<SelectBoxField />)
    } else if (props.formType === 'datetime') {
        input = (<DatetimeField onDatetimeChange={props.onDatetimeChange} defaultValue={props.userValue} />)
    } else {
        // input = (<TextField onChange={props.onChange} defaultValue={props.userValue}/>)
        // input = <input className="details-custom-text-field" onChange={props.onChange} value={props.userValue} type="text" name="total_amount" maxLength={props.max_length}/>

        input = <textarea rows={4} className="details-custom-text-area-field" onChange={props.onChange} value={props.userValue} type="text" name="total_amount" maxLength={props.max_length} />

    }
    return (
        // <form onSubmit={props.onSubmit} className="form">
        <div className="d-flex align-items-center contact-details-single-custom-fields">
            <div className="field w-100 custom-field-input-contact pr-2">
                {input}
            </div>
            <div className="action d-flex">
                <CheckCircleOutlineIcon className='cursor-pointer' onClick={props.onSubmit} style={{ color: 'green' }} />
                <HighlightOffIcon className='cursor-pointer' onClick={props.onClick} style={{ color: 'red' }} />
            </div>
        </div>
        // </form>
    )
}
