import React from "react";
import { Box, styled } from "@material-ui/core";
import Styles from "../../DataClean.module.css";
import { CoreButton } from "../commons/CoreButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

const Stack = styled(Box)({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const FileUploadHeader = ({
  onBack = () => {},
  title = "Import CSV file for data cleaning",
}) => {
  return (
    <Stack>
      <h2 className={Styles.importTabHeader}>{title}</h2>
      <CoreButton
        variant={"contained"}
        color={"primary"}
        backgroundColor={"#6070ed"}
        startIcon={<ArrowBackIcon />}
        onClick={onBack}
      >
        Back
      </CoreButton>
    </Stack>
  );
};

export default FileUploadHeader;
