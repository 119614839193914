import React from "react"
import SkipTracingResult from "./SkipTracingResult"

const Result = ({
  result = [],
  onClickCampaignButton,
  onClickDialerButton,
}) => {
  return (
    <SkipTracingResult
      result={result}
      onClickCampaignButton={onClickCampaignButton}
      onClickDialerButton={onClickDialerButton}
    />
  )
}

export default Result
