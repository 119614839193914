import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
    loadCampaignDataFail, loadCampaignDataStart,
    loadCampaignDataSuccess,
    loadInitialDataFail,
    loadInitialDataStart,
    loadInitialDataSuccess,
    loadPipelineDataFail,
    loadPipelineDataStart,
    loadPipelineDataSuccess,
    loadStageDataFail,
    loadStageDataStart,
    loadStageDataSuccess,
    loadWorksheetsFail,
    loadWorksheetsStart,
    loadWorksheetsSuccess,
} from "./importSpreadsheetContactSlice";
import importSpreadsheetContactService from "./importSpreadsheetContactService";

function* loadInitialData(action) {
    try {
        const [response, customFieldResponse] = yield all([
            call(importSpreadsheetContactService.loadInitialData, action.payload),
            call(importSpreadsheetContactService.loadCustomFieldData)
        ]);

        if (response.success && customFieldResponse.success) {
            const combinedData = {
                ...response.data,
                customFields: customFieldResponse.data,
            };

            yield put(loadInitialDataSuccess(combinedData));
        } else {
            yield put(loadInitialDataFail());
        }
    } catch (err) {
        yield put(loadInitialDataFail());
    }
}


function* loadPipelineData(action) {
    try{
        const response = yield call(importSpreadsheetContactService.loadPipelineData, action.payload);

        if (response.success){
            yield put(loadPipelineDataSuccess(response.data));
        }else {
            yield put(loadPipelineDataFail());
        }
    }catch (err){
        yield put(loadPipelineDataFail());
    }
}

function* loadStageData(action) {
    try{
        const response = yield call(importSpreadsheetContactService.loadStageData, action.payload);

        if (response.success){
            yield put(loadStageDataSuccess(response.data));
        }else {
            yield put(loadStageDataFail());
        }
    }catch (err){
        yield put(loadStageDataFail());
    }
}

function* loadWorksheetsData(action) {
    try{
        const refreshResponse = yield call(importSpreadsheetContactService.processRefreshToken, action.payload);

        if (refreshResponse?.success){
            const response = yield call(importSpreadsheetContactService.googleWorksheetInfo, action.payload);

            if (response.success){
                yield put(loadWorksheetsSuccess(response.data));
            }else {
                yield put(loadWorksheetsFail());
            }
        }else {
            yield put(loadWorksheetsFail());
        }
    }catch (err){
        yield put(loadWorksheetsFail());
    }
}

function* loadCampaignData(action) {
    try{
        const response = yield call(importSpreadsheetContactService.loadCampaignData, action.payload);

        if (response.success){
            yield put(loadCampaignDataSuccess(response.data));
        }else {
            yield put(loadCampaignDataFail());
        }
    }catch (err){
        yield put(loadCampaignDataFail());
    }
}

export function* watchImportSpreadsheetContactSaga() {
    yield takeLatest(loadInitialDataStart.type, loadInitialData);
    yield takeLatest(loadPipelineDataStart.type, loadPipelineData);
    yield takeLatest(loadStageDataStart.type, loadStageData);
    yield takeLatest(loadCampaignDataStart.type, loadCampaignData);
    yield takeLatest(loadWorksheetsStart.type, loadWorksheetsData);
}