import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import Loading from '../../../common/Loading';
import { Chip } from "@material-ui/core";
import { deleteContactTag } from "../../../../api/contactApi";
import { getContactTagList, getUserTagList } from "../../../../actions/contactAction";
import { confirmAlert } from "react-confirm-alert";
import ModalAssignToTagNew from '../../contactList/ModalAssignToTagNew';

const TagsComponent = (props) => {

    const [componentLoading, setComponentLoading] = useState(true)
    const [openAssignTagModal, setOpenAssignTagModal] = useState(false)

    useEffect(() => {
        getTagList()
    }, []) // eslint-disable-line

    useEffect(() => {
        setComponentLoading(true)
        getTagList()

    }, [props.contact?.id])

    const getTagList = () => {
        props.getContactTagList({
            contactId: props.contact.id
        });

        setComponentLoading(false)
    }
    const deleteTag = (tag) => {
        confirmAlert({
            title: 'Are you sure ?',
            message: 'Do you want to remove this tag?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        deleteContactTag({
                            contact_id: props.contact.id,
                            tag_id: tag
                        }).then(res => {
                            props.getContactTagList({
                                contactId: props.contact.id
                            });
                        });
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    const successCallBack = (update = false, newTag = false) => {

        setOpenAssignTagModal(false)
        getTagList();

        if (newTag) {
            props.fetchUserTagList({})
        }
    };

    return (
        <React.Fragment>
            {componentLoading ?
                (<Loading />) : (
                    <div id="tags" className="col s12">
                        <div className="bg-gray-light p-3">
                            <div className="tags_header py-2 d-flex justify-content-between align-items-center">
                                <h6>All Tags</h6>
                                <span className="circle d-flex justify-content-center align-items-center" onClick={() => setOpenAssignTagModal(!openAssignTagModal)}>
                                    <i className="material-icons text-light-blue">add</i>
                                </span>
                            </div>
                            <div className="tag_items d-flex">
                                {
                                    props.contactTags && props.contactTags.map(tag => (
                                        <Chip
                                            className="m-1 p-2"
                                            size="small"
                                            label={tag.tag.name}
                                            key={tag.id}
                                            onDelete={() => deleteTag(tag.tag.id)}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                        {openAssignTagModal &&
                            <ModalAssignToTagNew
                                open={openAssignTagModal}
                                contact_id={[props.contact.id]}
                                title={"Add Tag"}
                                callBack={successCallBack}
                                onClose={() => setOpenAssignTagModal(false)}
                            // contactTags={props.contactTags}
                            />
                        }
                    </div>
                )
            }
        </React.Fragment>
    );

}

const mapStateToProps = state => {
    return {
        contact: state.contactReducer.contact,
        contactTags: state.tagReducer.tags,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getContactTagList: (params) => dispatch(getContactTagList(params)),
        fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(TagsComponent);