import React from "react";
import { InputBase, MenuItem, Select, makeStyles, withStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuItem: {
    "&:hover": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
    "&.Mui-selected": {
      backgroundColor: "#cbd4ff",
      color: "#133159",
    },
  },
  menuItemLineThrough: {
    "&.MuiListItem-root": {
      textDecoration: "line-through",
      color: "#ee1b54",
    },
  },
  deleted: {
    fontSize: "9px"
  }
}));

const BasicInputSelect = withStyles((theme) => ({
  root: {

  },
  input: {
    backgroundColor: "white !important",
    position: "relative",
    fontSize: "15px !important",
    margin: "0 !important",
    padding: "9px  !important",
    borderRadius: "4px !important",
    border: "1px solid #ced4da !important",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      boxShadow: "unset !important",
      backgroundColor: "#fff !important"
    },
    "&::placeholder": {
      color: "#082852 !important",
    },

  },
}))(InputBase);

const BasicSelect = ({ options, mapping = { label: "title", value: "id" }, defaultText = "Select", name = "default", onChange, value = "", disabled = false, selectedOption, deletedMapping, className = null, emptyable=false, fullWidth=true, multiple = false }) => {
  const styles = useStyles();
  const placeholderColor = "rgb(8 40 82 / 44%)";
  const activeColor = "#36454F";
  const handleSelectChange = (event) => {
    onChange(event);

    if (selectedOption) {
      const selected = options.find((option) => (option[mapping.value] === event.target.value));
      if (selected) {
        selectedOption(selected);
      }
    }
  };

  const menuItems = () => {
    return options?.map((option, index) => {
          if (mapping) {
            if (deletedMapping && option[deletedMapping.field] === deletedMapping.value) {

              if (value === option[mapping.value]) {
                return (
                    <MenuItem disabled={(deletedMapping && option[deletedMapping.field] === deletedMapping.value)} className={`${styles.menuItem} dropdownhelper-menuitem-class ${(deletedMapping && option[deletedMapping.field] === deletedMapping.value) ? styles.menuItemLineThrough : ""}`} key={index} value={option[mapping.value]}>
                      {option[mapping.label]}
                      {(deletedMapping && option[deletedMapping.field] === deletedMapping.value) && (<span className={styles.deleted}>(deleted)</span>)}
                    </MenuItem>
                )
              } else {
                return null;
              }

            } else {
              return (
                  <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} key={index} value={option[mapping.value]}>
                    {option[mapping.label]}
                  </MenuItem>
              )
            }
          } else {
            return (
                <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} key={index} value={option}>
                  {option}
                </MenuItem>
            )
          }
        }
    )
  }

  return (
      <Select
          fullWidth={fullWidth}
          defaultValue={value}
          value={value}
          onChange={handleSelectChange}
          name={name}
          displayEmpty
          disabled={disabled}
          input={<BasicInputSelect
              style={{ color: `${(multiple ? value[0] === "" : !value) ? placeholderColor : activeColor}` }}
              selectedOption={selectedOption} />}
          inputProps={{ "aria-label": "Without label" }}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            PaperProps: {
              style: {
                maxHeight: "280px"
              },
            },
            getContentAnchorEl: null,
          }}
          className={className ?? ''}
          multiple={multiple}
      >
        <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} value="" disabled={!emptyable}>
          {defaultText}
        </MenuItem>
        {menuItems()}

      </Select>
  );
};

export default BasicSelect;