import React, {Fragment, useEffect, useState} from "react";
import {Box, Checkbox, Chip, FormControlLabel, TableCell, TableRow} from "@material-ui/core";
import {
    getDncStatusIcon,
    getDncStatusLabelAndColor, getEmailValidationStatusIcon,
    getEmailValidationStatusLabelAndColor, getLandlineMobileStatus, getNumberValidationStatusIcon,
    getNumberValidationStatusLabelAndColor, getSkipTraceStatusLabelAndColor
} from "../FileReportDetailsSingle";
import Styles from "../FileReportDetails.module.css";

const AddressGroupedItemRegular = ({ contact, index, onSelect, isSelected, collectIds  }) => {
    const [fullAddress, setFullAddress] = useState("");

    useEffect(() => {
        if (contact){
            collectIds(contact.id);

            let tempFullAddress = contact.address;

            if (contact.city){
                tempFullAddress += ", ";
                tempFullAddress += contact.city;
            }

            if (contact.state){
                tempFullAddress += ", ";
                tempFullAddress += contact.state;
            }

            if (contact.zip){
                tempFullAddress += " ";
                tempFullAddress += contact.zip;
            }

            setFullAddress(tempFullAddress);
        }
    }, [contact]);

    return (
        <Fragment key={index}>
            <TableRow className="stt-address-row">
                <TableCell className="stt-address-cell">
                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="small"
                                    color="primary"
                                />
                            }
                            checked={isSelected(contact.id)}
                            onChange={(event, checked)=>{
                                onSelect(contact.id)
                            }}
                        />
                        <Box>
                            <b>{fullAddress}</b>
                        </Box>
                    </Box>
                </TableCell>
                {contact.status === 'NOT_FOUND' ? (
                        <TableCell colSpan={8}>
                            <h5 style={{textAlign:'center'}}>No available data found</h5>
                        </TableCell>
                    ) :
                    <>
                        <TableCell align="left">
                            <Box style={{ display: "flex", alignItems: "center" }}>
                                <b>{contact.first_name} {contact.last_name}</b>
                            </Box>
                        </TableCell>
                        <TableCell align="left">{contact.email}</TableCell>
                        <TableCell align="center">
                            {getEmailValidationStatusIcon(contact.email_valid_status)}
                            {/*<Chip*/}
                            {/*    label={getEmailValidationStatusLabelAndColor(contact.email_valid_status)[0]}*/}
                            {/*    color="primary"*/}
                            {/*    size="small"*/}
                            {/*    style={{ backgroundColor: getEmailValidationStatusLabelAndColor(contact.email_valid_status)[1] }}*/}
                            {/*/>*/}
                        </TableCell>
                        <TableCell align="left">{contact.number}</TableCell>
                        <TableCell align="center">
                            {getNumberValidationStatusIcon(contact.number_valid_status)}
                            {/*<Chip*/}
                            {/*    label={getNumberValidationStatusLabelAndColor(contact.number_valid_status)[0]}*/}
                            {/*    color="primary"*/}
                            {/*    size="small"*/}
                            {/*    style={{ backgroundColor: getNumberValidationStatusLabelAndColor(contact.number_valid_status)[1] }}*/}
                            {/*/>*/}
                        </TableCell>
                        <TableCell align="center">
                            <Chip
                                label={getLandlineMobileStatus(contact.number_state)}
                                className={`${Styles.custom_data_enhance_blue_btn} ${Styles.custom_btn_min_width_115_px}`}
                                size="small"
                            />
                        </TableCell>
                        <TableCell align="center">
                            {getDncStatusIcon(contact.dnc_status)}
                            {/*<Chip*/}
                            {/*    label={getDncStatusLabelAndColor(contact.dnc_status)[0]}*/}
                            {/*    color="primary"*/}
                            {/*    size="small"*/}
                            {/*    style={{ backgroundColor: getDncStatusLabelAndColor(contact.dnc_status)[1] }}*/}
                            {/*/>*/}
                        </TableCell>
                        <TableCell align="center" className="stt-address-cell">
                            {/*<Chip*/}
                            {/*    size="small"*/}
                            {/*    variant="default"*/}
                            {/*    color="primary"*/}
                            {/*    style={{ backgroundColor: getSkipTraceStatusLabelAndColor(contact.skip_trace_status)[1] }}*/}
                            {/*    label={getSkipTraceStatusLabelAndColor(contact.skip_trace_status)[0]}*/}
                            {/*/>*/}
                        </TableCell>
                    </>

                }

            </TableRow>
        </Fragment>
    );
};

export default AddressGroupedItemRegular;