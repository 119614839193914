import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Box, CircularProgress } from "@material-ui/core";
import PricingAlertContent from "./PricingAlertContent";
import PricingAlertExistingContent from "./PricingAlertExistingContent";
import { cleanDataPriceValidationApi } from "../../../api/cleanDataApi";
import Styles from "./style.module.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { getAddressLines } from "../../../helpers/getAddressLines";
import { LOOKUP_TYPE_SKIP_TRACING_LOOKUP } from "../../../constants/CoreConstants";

const PricingConfirmAlert = ({
  title = "",
  description = "",
  width = "560px",
  onClose = () => {},
  onSubmit = () => {},
  checkPayload = {},
  prices = {},
  closeOnClickOutside = true,
}) => {
  const confirmAlertOnClose = onClose;
  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <AlertHandler
          title={title}
          description={description}
          width={width}
          checkPayload={checkPayload}
          prices={prices}
          onClose={() => {
            confirmAlertOnClose();
            onClose();
          }}
          onSubmit={() => {
            onSubmit();
            onClose();
          }}
        />
      );
    },
    closeOnClickOutside: closeOnClickOutside,
    overlayClassName: Styles.overlay,
  });
};

const AlertHandler = ({ title, description, width, onClose, onSubmit, checkPayload, prices }) => {
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const payload = { ...checkPayload };
      if (payload.action.includes(LOOKUP_TYPE_SKIP_TRACING_LOOKUP)) {
        const addressLines = await getAddressLines(checkPayload.address);
        payload.address = addressLines.addressLine1 + "," + addressLines.addressLine2;
      }
      cleanDataPriceValidationApi(payload).then((res) => {
        const response = res.data.data;
        if (response.error_number_validation || response.error_email_validation || response.error_dnc_check) {
          setResponseData(response);
        }
        setLoading(false);
      });
    };

    fetchData();
  }, [checkPayload]);

  return (
    <div className={Styles.mainWrp} style={{ width: width }}>
      {loading ? (
        <Box className={Styles.loading}>
          <CircularProgress size={"small"} className={Styles.loadingProgress} />
          <p>Loading...</p>
        </Box>
      ) : responseData ? (
        <PricingAlertExistingContent
          prices={prices}
          checkPayload={checkPayload}
          responseData={responseData}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <PricingAlertContent title={title} description={description} onSubmit={onSubmit} onClose={onClose} />
      )}
    </div>
  );
};

export default PricingConfirmAlert;
