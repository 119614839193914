import React from "react";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";
import { Button } from "@material-ui/core";

const PrimaryRoundedButton = ({
  size = "small",
  startIcon,
  fullWidth = false,
  disableElevation = true,
  onClick,
  children,
}) => {
  const { primaryButtonRounded } = LeftSideV2Styles();

  return (
    <Button
      variant='contained'
      color='primary'
      size={size}
      className={primaryButtonRounded}
      startIcon={startIcon}
      fullWidth={fullWidth}
      disableElevation={disableElevation}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PrimaryRoundedButton;
