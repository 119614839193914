import React from "react";
import { Avatar, Box, Paper } from "@material-ui/core";
import { ContactsStyles } from "../ContactsStyles";
import ContactNavigation from "./ContactNavigation";

const contactHeaderWrapper = {
  display: "flex",
  alignItems: "center",
  paddingX: 1,
  paddingY: 1,
};

const ContactHeader = ({ goToContactDetails }) => {
  const { contactAvatar } = ContactsStyles();

  return (
    <Paper variant="outlined" elevation={0}>
      <Box sx={contactHeaderWrapper}>
        <Avatar className={contactAvatar}>
          <i className='material-icons'>person</i>
        </Avatar>

        <ContactNavigation goToContactDetails={goToContactDetails} />
      </Box>
    </Paper>
  );
};

export default ContactHeader;
