import React, { useState, useEffect} from 'react';
import {connect} from 'react-redux'
import moment from 'moment';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import GlobalModal from '../../../globals/Modal/GlobalModal';
import If from 'if-else-react'
import DateTimeSetting from '../../../globals/DateTimeSetting';
import { Button } from '@material-ui/core';
import { fetchCampaignList } from '../../../../actions/contactAction';
import NewLoader from '../../../common/NewLoader';
import {addContactsInCampaign, addEnhanceContactsInCampaign} from '../../../../api/contactApi';
import './assign_to_campaign_new.css'
import Utils from '../../../../helpers/Utils';
import DynamicSkeleton from '../../../common/DynamicSkeleton';

const ErroMsg = (props) => {
    return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
            {props.msg}
        </div>
    )
}

let assign_campaign_search_interval = null;

const EachCampaign = (props) => {
    return (
        <li>
            <label>
                <input onChange={props.selectCampaign} type="radio" className="styled-checkbox filled-in" name="example_5" value={JSON.stringify(props.data)}/>
                <span>{props.data.title}</span>
                <span/>
            </label>
        </li>
    )
};

const TYPE_MINUTE = 1
const TYPE_HOUR = 2
const TYPE_DAY = 3

const QUANTITY_MINUTE_MAX = 20;
const QUANTITY_HOUR_MAX = 200;
const QUANTITY_DAY_MAX = 1000;

const process_type = [
    {id: 1, value: TYPE_MINUTE, label: 'Minute'},
    {id: 1, value: TYPE_HOUR, label: 'Hour'},
    {id: 1, value: TYPE_DAY, label: 'Day'}
]

const ModalAssignToCampaign=(props)=> {
    let userTimeNow = window.globalTimezoneConversionToDifferentTimezone ? window.globalTimezoneConversionToDifferentTimezone(new Date()) : new Date(moment.tz(Utils.getAccountData('userTimezoneRaw')));
    const [runningCampaign, setRunningCampaign] = useState([])
    const [submitEnable, setSubmitEnable] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedCampaign, setSelectedCampaign] = useState(false)
    const [enableProcess, setEnableProcess] = useState(false)
    const [step, setStep] = useState(0)
    const [date, setDate] = useState(userTimeNow)
    const [time, setTime] = useState(userTimeNow)
    const [batchQuantity, setBatchQuantity] = useState(1)
    const [repeatAfterDays, setRepeatAfterDays] = useState(1)
    const [sendOn, setSendOn] = useState({'Mon':true,'Tue':true,'Wed':true,'Thu':true,'Fri':true,'Sat':true,'Sun':true})
    const [processAllAtOnce, setProcessAllAtOnce] = useState(1)
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    const [buttonText, setButtonText] = useState('Next')
    const [showLoader, setShowLoader] = useState(false)

    const [currentPage, setCurrentPage] = useState(0);
    const [hasMore, setHaMore] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false);
    const [searchText, setSearchText] = useState(false);

    /* for new drip process change */
    const [processType, setProcessType] = useState(TYPE_MINUTE)

    /* for quantity limit */
    const [maxQuantity, setMaxQuantity] = useState(QUANTITY_MINUTE_MAX)

    useEffect(()=>{
        // if (props.storedCampaignList == null) {
        //     props.fetchStoreCampaignList({}, (callback) => {

        //         setRunningCampaign(callback.data)
        //     })
        // } else {
        //     setRunningCampaign(props.storedCampaignList)
        // }

        getCampaignData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchText])

    const getCampaignData = (page = currentPage) => {
        setLoading(true);
        props.fetchStoreCampaignList({page: page, from: 'contact_list', searchText: searchText}, (callback) => {
            setLoading(false);
            if(page === 0){
                setRunningCampaign(callback.data)
            }else{
                let data = [...runningCampaign, ...callback.data]
                setRunningCampaign(data)
            }
            if(callback.data.length >= 20){
                setHaMore(true)
                setCurrentPage(page + 1)
            }
            else{
                setHaMore(false)
            }
            setLoadingMore(false)
        })
    }

    const handleRadio = (e) => {

        setSelectedCampaign(e.target.value)
        setSubmitEnable(true)
    };

    const changeSearch = (e) => {
        if(assign_campaign_search_interval == null){
            assign_campaign_search_interval = setTimeout(() => {
                setCurrentPage(0)
                setSearchText(e.target.value.trim())
                clearTimeout(assign_campaign_search_interval)
            }, 500);
        }else{
            clearTimeout(assign_campaign_search_interval)
            assign_campaign_search_interval = setTimeout(() => {
                setCurrentPage(0)
                setSearchText(e.target.value.trim())
                clearTimeout(assign_campaign_search_interval)
            }, 500);
        }

        // let searchKey = e.target.value.toUpperCase();

        // const campaignData = props.storedCampaignList.filter((data)=>{
        //     if (data.title.toUpperCase().includes(searchKey)) {
        //         return data;
        //     }
        // });
        // setRunningCampaign(campaignData)
    };

    const changeStep = () => {
        if(step === 0){

            setEnableProcess(true)
            setShowLoader(true)
            setStep(1)
            setButtonText('Start')
        }
        if(step === 1){
            //Setting up the batch setup
            if(processAllAtOnce === "0"){
                submitBatchForm();
            }else{
                submitcampaignForm();
            }
        }
    };

    const prevStep = () => {
        if(step == 1){
            setEnableProcess(false)
            setShowLoader(false)
            setStep(0)
            setButtonText('Next')
        }
    }

    const submitBatchForm = () => {
        if(!validateBatchForm()){
            return;
        }
        let {id} = JSON.parse(selectedCampaign)

        let batchForm  = {
            'start_from' : moment(date).format('YYYY-MM-DD') + ' ' +  moment(time).format("HH:mm"),
            'quantity' : batchQuantity,
            'repeat_after_days' : repeatAfterDays,
            'send_on' : sendOn,
            'campaign_id' : id,
            'batch_process_type': processType
        }
        submitAddToCampaign(
            {
                contact_ids : props.contactIds,
                campaignId : id,
                batchForm : batchForm,
                contactType: 'single-contact'
            }
        )

        setLoading(false)
    }

    const validateBatchForm = () => {
        if (isNaN(batchQuantity)) {
            Utils.showNotification("Must be a valid quantity","ERROR");
            return false;
        } else if (batchQuantity <= 0) {
            Utils.showNotification("Quantity must be greater than 0","ERROR");
            return false;
        }

        // if (isNaN(repeatAfterDays)) {
        //     Utils.showNotification("Must be a valid repeat after days value","ERROR");
        //     return false;
        // } else if (repeatAfterDays <= 0 || repeatAfterDays > 100) {
        //     Utils.showNotification("Repeat after days must be between 1 to 100","ERROR");
        //     return false;
        // }

        if(!Object.values(sendOn).includes(true)) {
            Utils.showNotification("Please select minimum one send on day.","ERROR");
            return false;
        }

        return true;
    }

    const submitcampaignForm = () => {
        setLoading(true)
        setSubmitEnable(false)
        let tabId = Math.floor((Math.random() * 100) + 1);
        let {id} = JSON.parse(selectedCampaign)
        submitAddToCampaign({
            contact_ids : props.contactIds,
            campaignId : id,
            tabId : tabId,
        })

    }

    const handleRadioButtonChange = (e) => {
        if(e.target.checked){
            setProcessAllAtOnce(e.target.value)
        }
    }

    const timeSetup = (value, action) => {
        if(action ==='date'){
            setDate(moment(value).format("MM/DD/YYYY"))
        }else{
            setTime(value)
        }
    }

    const getSelectDays = () => {
        return Object.keys(sendOn).map((item,i) =>
            (<Button
                className={"mr-1"}
                variant="contained"
                color={sendOn[item] ? "primary" : "default"} key={i}
                onClick={() => setSendOn(Object.assign({}, sendOn, {[item]: !sendOn[item]})) }
            >{item}</Button>));
    }

    const submitAddToCampaign = async (params) => {

        const payload = {
            cleanContactIds: props.selectedFileContactIds,
            filterType: props.selectedFileContactIds[0] ? "SEGMENT" : "ALL",
            action: "ADD_TO_CAMPAIGN",
            campaignIds: [params.campaignId],
            pageSource: props.tab
        };

        if (params.batchForm){
            payload.batchForm = params.batchForm;
        }

        if (props.fileId){
            payload.fileId = props.fileId;
        }

        if (props.filter){
            if (props.filter.validNumbers || props.filter.invalidNumbers) {
                let numberStatus = [];

                if (props.filter.validNumbers){
                    numberStatus.push("VALID");
                }

                if (props.filter.invalidNumbers){
                    numberStatus.push("INVALID");
                }

                payload.numberStatus = numberStatus;
                payload.filterType = "SEGMENT";
            }

            if (props.filter.validEmails || props.filter.invalidEmails) {
                let emailStatus = [];

                if (props.filter.validEmails){
                    emailStatus.push("VALID");
                }

                if (props.filter.invalidEmails){
                    emailStatus.push("INVALID");
                }

                payload.emailStatus = emailStatus;
                payload.filterType = "SEGMENT";
            }

            if (props.filter.dncOn || props.filter.dncOff) {
                let dncStatus = [];

                if (props.filter.dncOn){
                    dncStatus.push("DO_NOT_CALL");
                }

                if (props.filter.dncOff){
                    dncStatus.push("VERIFIED_OK");
                }

                payload.dncStatus = dncStatus;
                payload.filterType = "SEGMENT";
            }

            if (props.filter.skipTrace){
                payload.skipTraceStatus = ["TRACED"];
                payload.filterType = "SEGMENT";
            }

            if (props.filter.landline || props.filter.mobile){
                let numberType = [];

                if (props.filter.landline){
                    numberType.push("landline");
                }

                if (props.filter.mobile){
                    numberType.push("mobile");
                }

                payload.numberType = numberType;
                payload.filterType = "SEGMENT";
            }
        }

        payload.enhanceRequest = true;
        await addContactsInCampaign(payload)
            .then(response => {
                if (response.data.status === 'success') {
                    props.onClose();
                    window.showNotification('SUCCESS',response.data.html === 'Success' ? 'Contacts is assigning to campaign' : response.data.html)
                } else {
                    window.showNotification('ERROR','Something went wrong')
                }

            })
    }

    const handleScroll = (e) => {
        if(
            hasMore &&
            !loadingMore &&
            Math.round(e.target.scrollTop + e.target.clientHeight) >= Math.round(e.target.scrollHeight)
        ) {
            setLoadingMore(true)
            getCampaignData()
        }
    }

    /* for drip new changes */
    const getProcessTypes = () => {
        let view = [];
        process_type.forEach((item, index) => {
            view.push(
                <option value={item.value} key={index}>{item.label}</option>
            )
        })
        return view;
    }

    const handleProcessTypeChange = (e) => {
        let value = e.target.value;
        if(value == TYPE_HOUR){
            if(repeatAfterDays > 23){
                setRepeatAfterDays(23)
            }
            if(batchQuantity > QUANTITY_HOUR_MAX){
                setBatchQuantity(QUANTITY_HOUR_MAX)
            }
            setMaxQuantity(QUANTITY_HOUR_MAX)
        }
        else if(value == TYPE_DAY){
            if(repeatAfterDays > 365){
                setRepeatAfterDays(365)
            }
            if(batchQuantity > QUANTITY_DAY_MAX){
                setBatchQuantity(QUANTITY_DAY_MAX)
            }
            setMaxQuantity(QUANTITY_DAY_MAX)
        }
        else{
            if(repeatAfterDays > 59){
                setRepeatAfterDays(59)
            }
            if(batchQuantity > QUANTITY_MINUTE_MAX){
                setBatchQuantity(QUANTITY_MINUTE_MAX)
            }
            setMaxQuantity(QUANTITY_MINUTE_MAX)
        }
        setProcessType(value)
    }

    const handleRepeatTimeChange = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setRepeatAfterDays("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(processType === TYPE_MINUTE){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 59){
                    setRepeatAfterDays(59)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            else if(processType === TYPE_HOUR){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 23){
                    setRepeatAfterDays(23)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            else if(processType === TYPE_DAY){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 365){
                    setRepeatAfterDays(365)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
        }
    }

    const onFocusOutTime = (e) => {
        if(e.target.value === ""){
            setRepeatAfterDays(1)
        }
    }

    const handleBatchQuantity = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setBatchQuantity("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(value > maxQuantity){
                setBatchQuantity(maxQuantity)
            }
            else{
                setBatchQuantity(value)
            }
        }
    }

    let {title} = JSON.parse(selectedCampaign);

    return (
      <GlobalModal
        {...props}
        title={"Add To Campaign"}
        buttonText={buttonText}
        buttonIcon={<ArrowForward />}
        onSubmit={changeStep}
        hideFooter={false}
        modalClass={"manage-tag-modal modal-assign-campaign update"}
        className={"manage-tag-modal modal-assign-campaign update scroll_v2 global-medium-modal"}
        previousButton={step == 1 && !loading ? true : false}
        previousButtonText={"Previous"}
        previousButtonIcon={<ArrowBack />}
        previousButtonOnSubmit={prevStep}
        submitButtonDisable={loading || !submitEnable ? true : false}
      >
        {loading ? (
          <DynamicSkeleton />
        ) : (
          <div className='row'>
            <div className='col-md-12 contact-to-campaign'>
              {step === 0 && (
                <div className='campaign-search-list'>
                  <div className='campaign-search form'>
                    <div className='form-group m-0'>
                      <div className='m-input-icon m-input-icon--right'>
                        <input
                          onChange={changeSearch}
                          type='text'
                          className='form-control m-input customFormControlField alt'
                          placeholder='Select Or Search campaign'
                        />
                        <span className='m-input-icon__icon m-input-icon__icon--right'>
                          <span>
                            <i className='flaticon-search-1' />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='upload-single-item-main2 upload-single-item-main'>
                    <ul className='awesome__scroll_bar' onScroll={handleScroll}>
                      {runningCampaign.map((data, index) => {
                        if (data.status === 3 || data.status === 5) {
                          return <EachCampaign selectCampaign={handleRadio} data={data} key={index} />;
                        } else {
                          return null;
                        }
                      })}
                    </ul>
                  </div>
                </div>
              )}
              <If condition={step === 1}>
                <p>
                  <span>
                    <b>{props.selectedFileContactIds.length} </b>
                  </span>
                  contacts is going to add in campaign
                  <span>
                    {" "}
                    <b>{title}</b>
                  </span>
                </p>
                {error && (
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-group m-form__group'>
                        <div className='col-md-12'>
                          <div className='form-group m-form__group'>
                            <ErroMsg msg={errorMsg} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className='import-tag-head pb-3'>
                  <div className='import-tag-text'>
                    <div className='m-radio-list pt-3'>
                      <div className='m-form__group form-group'>
                        <p>
                          <b>Process Mode</b>
                        </p>
                        <div className='m-radio-list'>
                          <label className='m-radio m-radio--state-success success__radio__assign__campaign'>
                            <input
                              type='radio'
                              key={1}
                              checked={processAllAtOnce == 1}
                              name='process_all_at_once'
                              value='1'
                              onChange={(e) => handleRadioButtonChange(e)}
                            />
                            Process all at once
                            <span></span>
                          </label>
                          <label className='m-radio m-radio--state-success success__radio__assign__campaign'>
                            <input
                              type='radio'
                              key={0}
                              checked={processAllAtOnce == 0}
                              name='process_all_at_once'
                              value='0'
                              onChange={(e) => handleRadioButtonChange(e)}
                            />
                            Process in drip mode
                            <span></span>
                          </label>
                        </div>
                      </div>

                      {processAllAtOnce == 0 && (
                            <div className='form-group m-form__group row'>
                              <div className='col-md-8'>
                                <p htmlFor='start_on' className='form-control-label'>
                                  <b>
                                    Start On <span className='text-danger'>*</span>
                                  </b>
                                </p>
                                <div>
                                  <DateTimeSetting timeSettingValue={timeSetup} time={time} date={date} />
                                </div>
                            </div>

                            <div className='contact__assign__modal__content_vv2' style={{ paddingTop: "10px" }}>
                                <div className='text-left contact__assign__modal__content_vv2__single'>
                                  <span
                                    className='text-dark-blue m-0 text-left mr-3 common__label__v3'
                                    style={{ fontSize: "14px" }}
                                  >
                                    Process Type
                                  </span>
                                  <select
                                    name='process_type'
                                    className='d-block'
                                    value={processType}
                                    onChange={handleProcessTypeChange}
                                    style={{height:"54px",padding:"15px",borderRadius:"5px"}}
                                  >
                                    {getProcessTypes()}
                                  </select>
                                </div>
    
                                <div className=' contact__assign__modal__content_vv2__single'>
                                  <span
                                    className='text-dark-blue m-0 text-left mr-3 common__label__v3'
                                    style={{ fontSize: "14px" }}
                                  >
                                    Batch Quantity
                                  </span>{" "}
                                  <span className='text-danger'>*</span>
                                  <div>
                                    <input
                                      className='form-control'
                                      id='batch_quantity'
                                      name='batch_quantity'
                                      value={batchQuantity}
                                      onChange={handleBatchQuantity}
                                      style={{ padding: "25px",borderRadius:"5px"}}
                                    />
                                  </div>
                                  <span>
                                    <small>Max quantity limit: {maxQuantity}</small>
                                  </span>
                                </div>
    
                                <div className='text-left contact__assign__modal__content_vv2__single'>
                                  <span
                                    className='text-dark-blue m-0 text-left mr-3 common__label__v3'
                                    style={{ fontSize: "14px" }}
                                  >
                                    Repeat After (
                                    {processType === TYPE_MINUTE ? "Minutes" : processType === TYPE_HOUR ? "Hours" : "Days"}
                                    )<span className='text-danger'>*</span>
                                  </span>
                                  <input
                                    className='form-control global__form_control'
                                    id='repeat_after_days'
                                    name='repeat_after_days'
                                    value={repeatAfterDays}
                                    onChange={handleRepeatTimeChange}
                                    onBlur={onFocusOutTime}
                                    style={{ padding: "25px",borderRadius:"5px"}}
                                    />
                                </div>
                            </div>
                            <div className='col-md-8'>
                                <p className='form-control-label'>
                                  <b>Assign on campaign</b> <span className='text-danger'>*</span>
                                </p>
                            <div>
                              <div className='contact__assign__modal__get__day__wr'>{getSelectDays()}</div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </If>
            </div>
          </div>
        )}
      </GlobalModal>
    );

}

const mapStateToProps = state => {
    return {
        storedCampaignList: state.contactReducer.campaignList
    };
};

const mapDispatchToProps = dispatch => {
    return {
        fetchStoreCampaignList: (params, callback) => dispatch(fetchCampaignList(params,callback))
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(React.memo(ModalAssignToCampaign));
