import { Box, Button, Dialog, Divider, Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectImportSpreadsheetContactState } from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import { createTag } from "../../../api/contactApi";
import { updateTags } from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import { BasicInput } from "../../contact/leftSideV2/LeftSideV2Styles";
import { ErrorMessage } from "../../contact/middleSide/Products/ProductStyles";

const useStyles = makeStyles((theme) => ({
  titleHeader: {
    fontSize: "20px",
    color: "#133159",
  },
  closeIconBox: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF264A",
    cursor: "pointer",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "16px",
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FF264A",
    margin: 0,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    color: "#133159",
    marginTop: "12px",
    marginBottom: "8px",
  },
  closeButton: {
    color: "#000 !important",
    width: "100px !important",
    border: "1px solid #ccc !important",
    fontWeight: 600,
    marginRight: "8px",
    textTransform: "capitalize !important",
    backgroundColor: "#fff !important",
    "&:focus,&:hover": {
      background: "#fff",
    },
  },
  saveButton: {
    color: (props) => (props.isSubmit ? "#000" : "#FFF"),
    width: "100px !important",
    fontWeight: 600,
    backgroundColor: (props) => (props.isSubmit ? "#EDEDED" : "#3C7EF3"),
    "&:focus,&:hover": {
      background: "#3C7EF3",
    },
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: "8px",
  },
}));

const ListOrTagCreateModal = ({ open, onClose, heading, labelText, isForTag = false }) => {
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles({ isSubmitting });
  const dispatch = useDispatch();
  const {
    details: {
      leadOwnerId,
    },
} = useSelector(selectImportSpreadsheetContactState);

  const handleSubmit = async () => {
    if (!title) {
      setError("Title is required");
      return;
    }
      setIsSubmitting(true);
      const payload = {
        title: title,
        userId:leadOwnerId
      }
      try {
        const response = await createTag(payload);
        if (response.success) {
          dispatch(updateTags(response.data));
          window.showNotification("SUCCESS", response.message);
        } else {
          window.showNotification("ERROR", response.message);
        }
      } catch (error) {
        window.showNotification("ERROR", "Something went wrong. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    
    
    onClose();
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "650px",
        },
      }}
      open={open}
      onClose={onClose}
    >
      <Box minWidth={"450px"} minHeight={"250px"} sx={{ "& .MuiTypography-root": { height: "unset !important" } }}>
        <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} padding={2}>
          <Typography variant='body1' style={{ marginBottom: 4 }} className={classes.titleHeader}>
            {heading}
          </Typography>
        </Box>
        <Divider />

        <Box className={classes.cardWrapper}>
          <Grid md={12}>
            <Typography variant='body1' className={classes.fieldLabel}>
              {labelText} <span style={{ color: "red" }}>*</span>
            </Typography>
            <BasicInput
              fullWidth
              name='title'
              placeholder='Enter a title '
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                setError("");
              }}
            />
          </Grid>
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Box>

        <Box padding={2} display={"flex"} justifyContent={"end"}>
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button variant='outlined' onClick={onClose} className={classes.CloseButton}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={classes.saveButton}
              variant='contained'
              disableElevation
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ListOrTagCreateModal;
