import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled, withStyles, Button,
} from "@material-ui/core";
import { CoreButton } from "../commons/CoreButton";
import DncForm from "./DncForm";
import {getDncStatusIcon} from "../../report/FileReportDetailsSingle";
const ActionButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#00ff91',
    color: 'black',
    '&:hover': {
      backgroundColor: '#80ffbf',
    },
    '&:active': {
      backgroundColor: '#00cc68',
    },
  },
}))(Button);
const ActiveBlueButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#336afc',
    color: 'white',
    '&:hover': {
      backgroundColor: '#336afc',
    },
    '&:active': {
      backgroundColor: '#336afc',
    },
  },
}))(Button);

const StyledTable = styled(Table)`
  & td,
  & th {
    padding: 8px !important;
  }
`;

const DncResult = ({
  data=[],
  onClickCampaignButton,
  onClickDialerButton,
  onBackClickHandler,
  singleDncSearchHandler,
  multipleDncSearchHandler,
}) => {
  return (
    <>
      <DncForm
        singleDncSearchHandler={singleDncSearchHandler}
        multipleDncSearchHandler={multipleDncSearchHandler}
        onBackClickHandler={onBackClickHandler}
      />
      <Box p={2}>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <Typography variant='h6'>Results:
        </Typography>
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, color:"84ee94"}}>
          {getDncStatusIcon('VERIFIED_OK')}
          <span style={{ paddingRight:"10px", color: "#181f48"}}># IS NOT ON DNC LIST </span>
          {getDncStatusIcon('DO_NOT_CALL')}
          <span style={{ color: "#181f48"}}># IS ON DNC LIST</span>
        </Box>
        </Box>
        <Paper variant={"outlined"}>
          <TableContainer style={{ maxHeight: "400px", borderBottom: "none" }}>
            <StyledTable>
              <TableHead >
                <TableRow>
                  <TableCell className={"enhance_data_dnc_result_header"}>Phone Number</TableCell>
                  <TableCell className={"enhance_data_dnc_result_header"}>DNC Status</TableCell>
                  <TableCell className={"enhance_data_dnc_result_header"}>Description</TableCell>
                  <TableCell style={{textAlign:"center"}} className={"enhance_data_dnc_result_header"}>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((result) => (
                  <TableRow>
                    <TableCell>{result.number}</TableCell>
                    <TableCell style={{paddingLeft:"40px"}}>{getDncStatusIcon(result.dnc)}</TableCell>
                    <TableCell>{result.dnc.replace(/_/g, ' ')}</TableCell>
                    <TableCell>
                      <Box style={{ display: "flex", gap: "8px", justifyContent: "center" }}>
                        <ActionButton
                          variant={"contained"}
                          size={"small"}
                          color={"primary"}
                          onClick={onClickCampaignButton}
                        >
                          Add to Campaign
                        </ActionButton>
                        <ActiveBlueButton
                            variant={"contained"}
                            size={"small"}
                            color={"primary"}
                            onClick={onClickDialerButton}
                        >
                          Add to Power dialer
                        </ActiveBlueButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default DncResult;
