import React, { useContext } from "react"
import { DataCleanContext } from "./StateProvider"
import { Divider } from "@material-ui/core"
import VerifySingleSearch from "./VerifySingleSearch"
import VerifyBulkSearch from "./VerifyBulkSearch"

const Verify = ({ onSelectFile }) => {
  const { state } = useContext(DataCleanContext)

  return (
    <div>
      <VerifySingleSearch state={state} />
      <Divider light />
      <VerifyBulkSearch onSelectFile={onSelectFile} />
    </div>
  )
}

export default Verify
