export const showErrorMessage = (error, field) => {
  if (error) {
    if (error[field]) {
      return error[field].message;
    }
  }

  return "";
};

export const isError = (error, field) => {
  if (error) {
    if (error[field]) return true;
  }

  return false;
};
