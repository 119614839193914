//These functions are used only in clean data pages.
const parentPaths = ["all", "verify", "dnc", "skiptracing"];
const childTabs = ["all", "imports", "results"];

export const getTabPath = (parentTab = 0, childTab = 0) => {
  let paramsArray = [];
  if (parentTab !== 0) {
    paramsArray.push({ key: "tab", value: parentPaths[parentTab] });
  }
  if (childTab !== 0) {
    paramsArray.push({ key: "page", value: childTabs[childTab] });
  }
  return paramsArray;
};

export const getTabIndexByParams = (param) => {
  const index = parentPaths.findIndex((el) => el === param);
  return index === -1 ? 0 : index;
};

export const getPageIndexByParams = (param) => {
  const index = childTabs.findIndex((el) => el === param);
  return index === -1 ? 0 : index;
};
