import React from "react";
import { BasicButton } from "./ProductStyles";
import { Typography } from "@material-ui/core";
import {
    Add as AddIcon
} from "@material-ui/icons";
import { EMPTY_SCREEN_IMG } from "../../../../constants/CoreConstants";

const EmptyProduct = ({ setOpenProductModal }) => {

    return(
        <div style={{display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center',marginTop:'12px'}}>
        <div>
          <img
            height={300}
            src={EMPTY_SCREEN_IMG}
            alt="Empty data"
          />
        </div>
        <div style={{display:'flex',flexDirection:'column', alignItems:'center',justifyContent:'center'}}>
          <Typography variant="h5">No Products Available</Typography>
          <Typography variant="body1" style={{paddingTop:'10px', paddingBottom:'10px'}}>
            Click The Button Below To Get Started
          </Typography>
          <BasicButton
           startIcon={<AddIcon/>}
            onClick={()=>{ setOpenProductModal(true); }} 
           >
           New Product/Service
           </BasicButton>
        </div>
      </div>
    );
};

export default EmptyProduct;