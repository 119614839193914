import React, { useState, useEffect, useContext } from "react";
import {
  activateContactApi,
  getInactiveContactApi,
} from "../../api/contactApi";
import "./index.css";
import { HistoryProvider } from "../../App";
import { confirmAlert } from "react-confirm-alert";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "@material-ui/core/Button";
import DynamicSkeleton from "../common/DynamicSkeleton";

const paginationIcon = {
  previous: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 10 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.50171 0.248609C8.79377 0.248039 9.07682 0.349756 9.30171 0.536108C9.42828 0.641045 9.53291 0.769922 9.6096 0.915356C9.68629 1.06079 9.73353 1.21992 9.74863 1.38364C9.76372 1.54737 9.74637 1.71246 9.69757 1.86946C9.64876 2.02646 9.56946 2.1723 9.46421 2.29861L3.86421 8.99861L9.26421 15.7111C9.36804 15.839 9.44558 15.9861 9.49237 16.144C9.53916 16.3019 9.55427 16.4676 9.53685 16.6313C9.51943 16.7951 9.46981 16.9539 9.39084 17.0984C9.31187 17.2429 9.20511 17.3705 9.07671 17.4736C8.94737 17.5874 8.79592 17.6732 8.63184 17.7257C8.46776 17.7782 8.2946 17.7962 8.12324 17.7786C7.95187 17.761 7.78599 17.7082 7.63602 17.6234C7.48604 17.5387 7.3552 17.4238 7.25171 17.2861L1.21421 9.78611C1.03035 9.56244 0.929847 9.28189 0.929847 8.99236C0.929847 8.70283 1.03035 8.42227 1.21421 8.19861L7.46421 0.69861C7.5896 0.547338 7.7489 0.427757 7.92916 0.349571C8.10942 0.271387 8.30558 0.236795 8.50171 0.248609Z"
        fill="white"
      />
    </svg>
  ),
  backwardSlash: (
    <svg
      width="21"
      height="37"
      viewBox="0 0 21 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="19.7058"
        y1="0.470588"
        x2="0.88231"
        y2="35.7647"
        stroke="#3C7EF3"
        strokeWidth="2"
      />
    </svg>
  ),
  next: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 9 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.32251 17.7514C1.03045 17.752 0.747402 17.6502 0.522512 17.4639C0.395939 17.359 0.291312 17.2301 0.214622 17.0846C0.137932 16.9392 0.0906866 16.7801 0.0755918 16.6164C0.0604969 16.4526 0.0778495 16.2875 0.126655 16.1305C0.175461 15.9735 0.25476 15.8277 0.360012 15.7014L5.96001 9.00139L0.560013 2.28889C0.456181 2.16103 0.378641 2.01391 0.331851 1.85599C0.285062 1.69806 0.269944 1.53245 0.287368 1.36866C0.304792 1.20488 0.354413 1.04615 0.433381 0.901604C0.512348 0.757058 0.619104 0.629544 0.747513 0.52639C0.876845 0.412595 1.0283 0.32676 1.19238 0.274274C1.35646 0.221788 1.52961 0.203783 1.70098 0.221389C1.87235 0.238995 2.03822 0.291833 2.1882 0.376585C2.33818 0.461337 2.46902 0.576175 2.57251 0.713891L8.61001 8.21389C8.79387 8.43756 8.89437 8.71811 8.89437 9.00764C8.89437 9.29717 8.79387 9.57773 8.61001 9.80139L2.36001 17.3014C2.23462 17.4527 2.07532 17.5722 1.89506 17.6504C1.7148 17.7286 1.51865 17.7632 1.32251 17.7514Z"
        fill="white"
      />
    </svg>
  ),
};

const InactiveContact = (props) => {
  const history = useContext(HistoryProvider);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [inactiveContactList, setInactiveContactList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activating, setActivating] = useState(false);

  useEffect(() => {
    loadData();
  }, [currentPage, perPage, total]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    if (newPage === null) {
      return;
    }
    setCurrentPage(newPage - 1);
  };

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
  };

  const loadData = () => {
    setLoading(true);
    getInactiveContactApi({
      currentPage: currentPage,
      perPage: perPage,
    })
      .then((response) => {
        setInactiveContactList(response.data.data.data);
        setTotal(response.data.data.total);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const renderPagination = () => {
    let previous_page = null,
      next_page = null;
    let totalPages = Math.ceil(total / perPage);
    let _currentPage = currentPage + 1;
    if (_currentPage > 1) {
      previous_page = _currentPage - 1;
    }

    if (_currentPage !== totalPages) {
      next_page = _currentPage + 1;
    }

    return (
      <div className="pagination_bar">
        <ul>
          <li>
            <a
              href="#!"
              onClick={(event) => handleChangePage(event, previous_page)}
              style={
                previous_page == null
                  ? {
                      cursor: "not-allowed",
                      backgroundColor: "#9daac1",
                      borderColor: "#9daac1",
                    }
                  : {}
              }
            >
              {paginationIcon.previous}
            </a>
          </li>
          <li>
            <a>
              <span>{_currentPage}</span>
            </a>
          </li>
          <li>
            <a className="d-flex align-items-center">
              <span>{paginationIcon.backwardSlash}</span>
              {totalPages}
            </a>
          </li>
          <li>
            <a
              href="#!"
              onClick={(event) => handleChangePage(event, next_page)}
              style={
                next_page == null
                  ? {
                      cursor: "not-allowed",
                      backgroundColor: "#9daac1",
                      borderColor: "#9daac1",
                    }
                  : {}
              }
            >
              {paginationIcon.next}
            </a>
          </li>
          <li style={{ color: "#9daac1", marginLeft: 20 }}>
            Total Outcome : {total}
          </li>
        </ul>
      </div>
    );
  };

  const renderData = () => {
    if (loading) {
      return (
        <tr className="inactive-contact-list-file-table-loading">
          <td colspan="4"> 
            <DynamicSkeleton/>
          </td>
        </tr>
      );
    }

    if (inactiveContactList.length === 0) {
      return (
        <tr className="inactive-contact-list-file-table-empty">
          <td colspan="4">No inactive contact found!</td>
        </tr>
      );
    }

    let view = [];
    inactiveContactList.map((item, index) => {
      let full_name = "";
      if (item !== undefined && item !== null && item !== "") {
        let first_name = item.first_name,
          last_name = item.last_name;
        full_name = first_name.concat(" ", last_name);
      }
      let email = "";
      if (item !== undefined && item !== null && item !== "") {
        email = item.email;
      }
      let number = "";
      if (item !== undefined && item !== null && item !== "") {
        number = item.number;
      }
      view.push(
        <tr>
          <td width="26%">{full_name}</td>
          <td className="inactive_contact_field_email">{email}</td>
          <td width="24%">{number}</td>
          <td width="20%">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => confirmMakeContactActive(item, index)}
            >
              Make it active
            </Button>
          </td>
        </tr>
      );
    });
    return view;
  };

  const confirmMakeContactActive = (item, index) => {
    if (activating) {
      window.showNotification("WARNING", "Please wait contact is activating.");
      return;
    }
    confirmAlert({
      title: "Make contact active !",
      message: "Do you want to make this contact active?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleMakeContactActive(item, index),
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const handleMakeContactActive = (item, index) => {
    setActivating(true);
    activateContactApi({
      contact_id: item.id,
    })
      .then((res) => {
        let response = res.data;
        if (response.status === "success" && response.data === 1) {
          let oldData = [...inactiveContactList];
          oldData.splice(index, 1); // remove from inactive list
          setInactiveContactList(oldData);
          setTotal(total - 1);
        } else {
          window.showNotification("ERROR", "Something went wrong!");
        }
        setActivating(false);
      })
      .catch((error) => {
        setActivating(false);
        console.log("Contact activation failed because ", error);
      });
  };

  const renderBreadcrumb = () => {
    return (
      <div className="inactive-contact-list-bradcumb-container">
        <ul className="inactive-contact-list-bradcumb-list">
          <li
            className="inactive-contact-list-bradcumb-each"
            onClick={handleBradcumbClick}
          >
            Contacts
          </li>

          <li className="inactive-contact-list-bradcumb-each">
            <NavigateNextIcon fontSize="small" />
          </li>
          <li className="inactive-contact-list-bradcumb-each">
            Inactive contact info
          </li>
        </ul>
      </div>
    );
  };

  const handleBradcumbClick = () => {
    history.push("/contacts");
  };

  return (
    <React.Fragment>
      <div className="inactive-contact-lists">
        <div className="inactive-contact-list-heading-alt">
          <div className="inactive-contact-list-heading-bread__alt">
            <h3>Inactive contact info</h3>
            {renderBreadcrumb()}
          </div>
          {history.location.state !== undefined &&
            history.location.state.from === "contactPage" && (
              <div
                className="accent--bg--text--color inactive-contact-list-back-button"
                onClick={(e) => history.push("/contacts")}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z"></path>
                </svg>
                <span>Back</span>
              </div>
            )}
        </div>
        <div className="row">
          <div className="dropdown">
            <div className="dropdown-row">
              <div className="input__field__show__pagination">
                <select
                  style={{ display: "block" }}
                  value={perPage}
                  onChange={handlePerPageChange}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="30">30</option>
                  <option value="100">100</option>
                </select>
              </div>
              <span>Show</span>
            </div>
          </div>
        </div>
        <div>
          <table className="inactive__contact__list__table">
            <tr>
              <th>Full name</th>
              <th>Email</th>
              <th>Number</th>
              <th>Action</th>
            </tr>
            {renderData()}
          </table>
        </div>
        <div className="pagination_position">
          {inactiveContactList.length > 0 && renderPagination()}
        </div>
      </div>
    </React.Fragment>
  );
};
export default InactiveContact;
