import React from "react";
import { Typography } from "@material-ui/core";
import { LocationOn } from "@material-ui/icons";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const GooglePlaceInput = ({ value, onChange, disabled = false, error, helperText }) => (
  <div>
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_MAP_KEY}
      autocompletionRequest={{
        componentRestrictions: {
          country: ["us", "ca"],
        },
      }}
      selectProps={{
        isDisabled: disabled,
        value,
        onChange,
        placeholder: "Search Location",
        components: {
          DropdownIndicator: () => <LocationOn color='error' />,
        },
        styles: {
          valueContainer: (provided) => ({
            ...provided,
            padding: "0 8px",
          }),
          control: (provided) => ({
            ...provided,
            borderColor: "#e5e8ef",
          }),
          placeholder: (provided) => ({
            ...provided,
            color: "#808080ba",
          }),
          input: (provided) => ({
            ...provided,
            width: "100%",
            padding: 0,
            margin: 0,
          }),
          indicatorsContainer: (provided) => ({
            ...provided,
            paddingRight: "8px",
          }),
          indicatorSeparator: (provided) => ({
            ...provided,
            width: 0,
          }),
        },
      }}
    />
    {error && (
      <Typography variant='subtitle1' color='error'>
        {helperText}
      </Typography>
    )}
  </div>
);

export default GooglePlaceInput;
