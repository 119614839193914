import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    CircularProgress, withStyles
} from "@material-ui/core";

const styles = theme => ({
    button: {
        margin: theme.spacing.unit,
    },
    loader: {
        left: "4px",
        color: "#fff"
    }
});

const LoadingButton = (props) => {
    const { classes, loading, text, loadingText, ...other } = props;

    if (loading) {
        return (
            <Button className={classes.button} {...other}>
                <CircularProgress className={classes.loader} size={26} /> {loadingText}
            </Button>
        );
    } else {
        return (
            <Button className={classes.button} {...other}>
                {text}
            </Button>
        );
    }
}

LoadingButton.defaultProps = {
    loading: false,
    text: "Submit",
    loadingText: "Submitting..."
};

LoadingButton.propTypes = {
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    text: PropTypes.string,
    loadingText: PropTypes.string
};

export default withStyles(styles)(LoadingButton);