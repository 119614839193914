import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { Backdrop, CircularProgress, Divider, makeStyles } from "@material-ui/core";
import { BasicTabs } from "../commons/BasicTabs";
import TabPanel from "../commons/TabPanel";
import FileReport from "../../report/FileReport";
import Result from "./Result";
import DncForm from "./DncForm";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import { carrierLookupCleanDataApi, carrierLookupCleanDataBulkApi } from "../../../../api/cleanDataApi";
import {
  CONTACT_VALIDATION_TYPE,
  LOOKUP_TYPE_BY_PAYLOAD_CONST,
  LOOKUP_TYPE_DNC_LOOKUP,
} from "../../../../constants/CoreConstants";
import { DataCleanContext } from "../../StateProvider";
import numberToDollar from "../../../../helpers/numberToDollar";
import ModalAssignToCampaign from "../../report/actions/ModalAssignToCampaign";
import ModalAssignToPowerDialer from "../../report/actions/ModalAssignToPowerDialer";
import FileUploader from "../../FileUploader";
import FileUploadHeader from "../emailPhoneVerification/FileUploadHeader";
import FileUpload from "../../fileUpload";
import { changeQueryParams } from "../../../../helpers/changeQueryParams";
import { getPageIndexByParams, getTabPath } from "../../../../helpers/tabPaths";
import PricingConfirmAlert from "../../../common/new-alert/PricingConfirmAlert";
import NonAddressFileReportDetails from "../../report/nonAddress/NonAddressFileReportDetails";
import NonAddressReportDetails from "../../report/nonAddress/NonAddressReportDetails";
import {StyledTab} from "../EnhanceDataStyles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const DncScrubbing = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { state } = useContext(DataCleanContext);

  const [openCampaignDrawer, setOpenCampaignDrawer] = useState(false);
  const [openPowerDialerDrawer, setOpenPowerDialerDrawer] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [viewDetailsReport, setViewDetailsReport] = useState(false);

  const [result, setResult] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get("page");
    setTabIndex(getPageIndexByParams(param));
  }, []);

  const handleSelectFile = (selected) => {
    setIsFileSelected(selected);
  };

  const handleTabChange = (event, newValue, redirect='') => {
    changeQueryParams(getTabPath(2, newValue), redirect);
    setTabIndex(newValue);
  };

  const handleFinishUpload = () => {
    setIsFileSelected(false);
    handleTabChange(null, 1, 'auto');
  };

  const price = state.carrierLookupPricesObject[LOOKUP_TYPE_BY_PAYLOAD_CONST[LOOKUP_TYPE_DNC_LOOKUP]];

  const singleDncSearchHandler = (value) => {
    PricingConfirmAlert({
      checkPayload: {
        action: [LOOKUP_TYPE_DNC_LOOKUP],
        number: value.phone,
      },
      onSubmit: async () => {
        setLoading(true);
        try {
          const response = await carrierLookupCleanDataApi({
            lookupType: [LOOKUP_TYPE_DNC_LOOKUP],
            requestModule: "CLEAN_DATA",
            contact: {
              number: value.phone,
            },
          });

          const error = Object.values(response.error);

          if (error.length > 0) {
            const errors = Object.values(error)[0];
            window.showNotification("error", errors);
            setLoading(false);
            return;
          }

          setResult([
            {
              number: response.data[0].number,
              dnc: response.data[0]["dnc-state"],
              contactId: response.data[0]["clean_contact_id"],
            },
          ]);
        } catch (error) {
          console.log(error.message);
        }
        setLoading(false);
      },
      title: `Total Cost ${numberToDollar(price)}`,
      description: "These credits will be deducted from your balance",
    });
  };

  const multipleDncSearchHandler = (value) => {
    let validationData = {};
    const phonePattern = /^(\+1|1)?\d{10}$/;
    const phones = value.phone.split("\n");
    let isAllPhonesValid = true;

    if (phones.length === 0) {
      window.showNotification("error", "Phone numbers are required");

      return;
    }

    phones.forEach((phone) => {
      if (phonePattern.test(phone) === false) isAllPhonesValid = false;
    });

    if (isAllPhonesValid === false) {
      window.showNotification("error", "Invalid phone numbers");
      return;
    }

    let contacts = value.phone.split("\n").map((number) => ({ number: number }));

    validationData = {
      lookupType: LOOKUP_TYPE_DNC_LOOKUP,
      contacts: contacts,
      categoryName: CONTACT_VALIDATION_TYPE.CARRIER,
    };

    NewConfirmAlert({
      onSubmit: async () => {
        setLoading(true);
        try {
          const response = await carrierLookupCleanDataBulkApi(validationData);

          if (response.success) {
            if (response.status) {
              window.showNotification(
                "success",
                "Your request has been received. Click 'View Past Search Results' to check the outcomes."
              );
              handleTabChange(null, 2, 'processing');
            } else {
              window.showNotification("error", "Failed to validate");
            }
          } else {
            window.showNotification("error", response.message ?? "Failed to validate");
          }
        } catch (error) {
          console.log(error.message);
          window.showNotification("error", error.message ?? "Failed to validate");
        }
        setLoading(false);
      },
      title: `Verifying each number will cost: ${price ? numberToDollar(price) : 0}`,
      description: "These credits will be deducted from your balance",
      cancelText: "Cancel",
      submitText: "Validate",
      width: "480px",
    });
  };

  const onBackClickHandler = () => {
    setResult([]);
  };

  return (
    <Box>
      <Box p={2}>
        <Typography variant='h5'>Do-Not-Call (DNC) Scrubbing</Typography>
        <Typography variant='body2'>Scrub your numbers against the DNC and known litigators list.</Typography>
      </Box>
      <Divider light />
      {isFileSelected ? (
        <>
          <FileUploadHeader onBack={() => setIsFileSelected(false)} />
          <Divider style={{ marginBottom: "20px" }} light />
          <FileUpload onSelectFile={handleFinishUpload} />
        </>
      ) : (
        <>
          <BasicTabs value={tabIndex} onChange={handleTabChange}>
            <StyledTab value={0} label='New Search' />
            <StyledTab value={1} label='View Past Imports' />
            <StyledTab value={2} label='Results' />
          </BasicTabs>
          <Divider light />

          <Box mt={1}>
            <TabPanel value={tabIndex} index={0}>
              {/* NOTE we hide DncForm component from here if result have data. Because in result component we use DncForm component */}
              {result.length === 0 && (
                <>
                  <DncForm
                    singleDncSearchHandler={singleDncSearchHandler}
                    multipleDncSearchHandler={multipleDncSearchHandler}
                  />
                  <Box p={2}>
                    <Typography style={{ marginBottom: "10px" }} variant='body1'>Upload a list of contacts</Typography>
                    <FileUploader onSelectFile={handleSelectFile} />
                  </Box>
                </>
              )}

              <Result
                result={result}
                singleDncSearchHandler={singleDncSearchHandler}
                multipleDncSearchHandler={multipleDncSearchHandler}
                onBackClickHandler={onBackClickHandler}
                onClickCampaignButton={() => {
                  setOpenCampaignDrawer(true);
                }}
                onClickDialerButton={() => {
                  setOpenPowerDialerDrawer(true);
                }}
              />
            </TabPanel>

            <TabPanel value={tabIndex} index={1}>

              {viewDetailsReport ? (
                  <NonAddressFileReportDetails
                      onChangeTab={() => setViewDetailsReport(false)}
                      fileSource="DNC"
                      filter={[
                        "valid-emails",
                        "invalid-emails",
                        "valid-numbers",
                        "invalid-numbers",
                        "dnc-on",
                        "dnc-off",
                        "landline",
                        "mobile",
                        "skip-trace",
                      ]}
                  />
              ) : (
                  <FileReport fileSource='DNC' onChangeTab={() => setViewDetailsReport(true)} />
              )}

              {/*{viewDetailsReport ? (*/}
              {/*    Utils.getAccountData("userId") === 1 ?*/}
              {/*    <NonAddressFileReportDetails*/}
              {/*        onChangeTab={() => setViewDetailsReport(false)}*/}
              {/*        fileSource="DNC"*/}
              {/*        filter={[*/}
              {/*          "valid-emails",*/}
              {/*          "invalid-emails",*/}
              {/*          "valid-numbers",*/}
              {/*          "invalid-numbers",*/}
              {/*          "dnc-on",*/}
              {/*          "dnc-off",*/}
              {/*          "skip-trace",*/}
              {/*          "landline",*/}
              {/*          "mobile"*/}
              {/*        ]}*/}
              {/*    /> :*/}
              {/*    <FileReportDetails*/}
              {/*      onChangeTab={() => setViewDetailsReport(false)}*/}
              {/*      filter={[*/}
              {/*        "valid-emails",*/}
              {/*        "invalid-emails",*/}
              {/*        "valid-numbers",*/}
              {/*        "invalid-numbers",*/}
              {/*        "dnc-on",*/}
              {/*        "dnc-off",*/}
              {/*        "skip-trace",*/}
              {/*        "landline",*/}
              {/*        "mobile",*/}
              {/*      ]}*/}
              {/*      tab="DNC"*/}
              {/*    />*/}
              {/*) : (*/}
              {/*  <FileReport fileSource='DNC' onChangeTab={() => setViewDetailsReport(true)} />*/}
              {/*)}*/}
            </TabPanel>

            <TabPanel value={tabIndex} index={2}>

              <NonAddressReportDetails
                  fileSource="DNC"
                  filter={[
                    "valid-emails",
                    "invalid-emails",
                    "valid-numbers",
                    "invalid-numbers",
                    "dnc-on",
                    "dnc-off",
                    "landline",
                    "mobile",
                    "skip-trace",
                  ]}
              />

              {/*{*/}
              {/*  Utils.getAccountData("userId") === 1 ?*/}
              {/*      <NonAddressReportDetails*/}
              {/*          fileSource="DNC"*/}
              {/*          filter={[*/}
              {/*            "valid-emails",*/}
              {/*            "invalid-emails",*/}
              {/*            "valid-numbers",*/}
              {/*            "invalid-numbers",*/}
              {/*            "dnc-on",*/}
              {/*            "dnc-off",*/}
              {/*            "skip-trace",*/}
              {/*            "landline",*/}
              {/*            "mobile"*/}
              {/*          ]}*/}
              {/*      /> :*/}
              {/*      <FileReportDetailsSingle*/}
              {/*          filter={[*/}
              {/*            "valid-emails",*/}
              {/*            "invalid-emails",*/}
              {/*            "valid-numbers",*/}
              {/*            "invalid-numbers",*/}
              {/*            "dnc-on",*/}
              {/*            "dnc-off",*/}
              {/*            "skip-trace",*/}
              {/*            "landline",*/}
              {/*            "mobile",*/}
              {/*          ]}*/}
              {/*          fileSource='DNC'*/}
              {/*          tab="DNC"*/}
              {/*      />*/}
              {/*}*/}
            </TabPanel>
          </Box>
        </>
      )}
      {result.length > 0 && (
        <>
          <ModalAssignToCampaign
            open={openCampaignDrawer}
            onClose={() => setOpenCampaignDrawer(false)}
            selectedFileContactIds={result[0].contactId ? [result[0].contactId] : []}
          />
          <ModalAssignToPowerDialer
            open={openPowerDialerDrawer}
            onClose={() => setOpenPowerDialerDrawer(false)}
            selectedFileContactIds={result[0].contactId ? [result[0].contactId] : []}
          />
        </>
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </Box>
  );
};

export default DncScrubbing;
