import React, { useState } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import {LeftSideV2Styles} from "../LeftSideV2Styles";
import EditImportantLinksModal from "./EditImportantLinksModal";
import CustomTitle from "../common/customTitle/CustomTitle";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const ImportantLinks = (props) => {
  const [open, setOpen] = useState(false);

  const { contact } = props || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderAdditionalLinks = () => {
    if (contact.contact_additional_informations && contact.contact_additional_informations.important_links){
      let importantDates = JSON.parse(contact.contact_additional_informations.important_links);
      return importantDates.map(item => (
          <Box className={icons.datewidth} sx={{ flex: 1, flexDirection: "row", display: "flex", gap: "10px" }}>
            <Typography variant='body2'>{item.title}:</Typography>
            <Typography variant='body2' style={{ width: "260px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <BootstrapTooltip arrow positon={'top'} title={item.value}>
                <a href={item.value} target='_blank' rel="noreferrer">
                  <b style={{color: "#316aff"}}> {item.value}</b>
                </a>
              </BootstrapTooltip>
            </Typography>
          </Box>
      ));
    }
  }

  const {titleIcon, flexCenter} = LeftSideV2Styles();
  const icons = useStyles();
  return (
    <Box className={icons.hoverDiv}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle handleOpen={handleOpen} title={"IMPORTANT LINKS"} iconHide={icons.icon} />
      </Box>

      <Box
        className={flexCenter}
        sx={{
          gap: 10,
          width: "100%",
          display: "-webkit-box",
          WebkitLineClamp: 2,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textWrap: "wrap",
        }}
      >
        {contact?.url && (
          <Box sx={{ flex: 1, flexDirection: "row", display: "flex", gap: "10px" }}>
            <Typography variant='body2'>Contact Url:</Typography>
            <Typography variant='body2' style={{ width: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              <BootstrapTooltip arrow positon={'top'} title={contact.url}>
                <a href={contact.url} target='_blank' rel="noreferrer">
                  <b style={{ color: "#316aff" }}> {contact.url}</b>
                </a>
              </BootstrapTooltip>
            </Typography>
          </Box>
        )}
        {renderAdditionalLinks()}
      </Box>
      <EditImportantLinksModal open={open} onClose={handleClose} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};
export default connect(mapStateToProps)(ImportantLinks);
