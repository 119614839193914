import React from 'react';
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import { connect} from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { pauseCampaign, runCampaign, unsubscribeCampaign } from '../../../../api/contactApi';
import Utils from '../Helpers/Utils';
import { campaignPause, campaignStart } from '../redux/contactAction';
import Helper from '../Helpers/Helper';
import CustomMenuPype from '../../../common/custom-menu/CustomMenuPype';
import Styles from './new-style.module.css'
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";

const pauseContactArr = [2, 4, 5, 7, 8, 9, 10];
const runningCampaignOptions = [{ id: 'pause', name: 'pause' }, { id: 'unsubscribe', name: 'unsubscribe' }]
const pauseCampaignOptions = [{ id: 'start', name: 'start' }, { id: 'unsubscribe', name: 'unsubscribe' }]
const completeCampaignOptions = [{ id: 'unsubscribe', name: 'unsubscribe' }]

const Campaign = (props) => {

    const campaignIsPaused = (campaignId) => {
        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {
            confirmAlert({
                title: 'Campaign is paused',
                message: 'Run the campaign to add contact',
                buttons: [
                    {
                        label: 'ok',
                        onClick: () => {
                            let data = {
                                campaignId : props.data?.campaign?.id,
                                campaign_id : props.data?.campaign?.id,
                                prevent_previous_followup: 0,
                                sent_immediate_after_resume: 1
                            }
                            data = {...data}
                            props.campaignStart(data, (response) => {
                                if (response.data.status === "error"){
                                    window.showNotification('ERROR',response.data.html);
                                } else if(response.data.status === "success"){
                                    window.showNotification('SUCCESS','Campaign will start in a while')
                                }
                            })
                        }
                    },
                    {
                        label: 'cancel',
                        onClick: () => {
                            return;
                        }
                    }
                ]
            });
        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.')
        }
    }

    const toStart = () => {

        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {
            NewConfirmAlert({
                onSubmit : () => {
                    runCampaign({
                        contact_id: props.contact_id,
                        campaign_id: props.data?.campaign?.id
                    }).then(res => {
                        if(res.data){
                            window.showNotification('SUCCESS',"Contact will run in a while")
                        }else{
                            window.showNotification('ERROR',"Something went wrong")

                        }
                    });
                },
                title: 'Please confirm your selection below:',
                description: '',
                cancelText: 'Cancel',
                submitText: 'Run Campaign',
                width: '480px'
            });
        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.' )
        }

    }

    const toPause = () => {

        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {

            NewConfirmAlert({
                onSubmit : () => {
                    pauseCampaign({
                        contact_id: props.contact_id,
                        campaign_id: props.data?.campaign?.id
                    }).then(res => {
                        if(res.data){
                            window.showNotification('SUCCESS',"Contact will pause in a while")
                        }
                        else{
                            window.showNotification('ERROR',"Something went wrong")
                        }
                    });
                },
                title: 'Please confirm your selection below:',
                description: '',
                cancelText: 'Cancel',
                submitText: 'Pause Campaign',
                width: '480px'
            });
        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.')
        }
    }

    const toUnsub = () => {

        if (Utils.getAccountData('userId') === parseInt(props.data?.campaign?.user_id)) {
            NewConfirmAlert({
                onSubmit : () => {
                    unsubscribeCampaign({
                        contact_id: props.contact_id,
                        campaign_id: props.data?.campaign?.id
                    }).then(res => {
                        if(res.data){
                            window.showNotification('SUCCESS',"Contact will unsubscribe in a while")

                        }else{
                            window.showNotification('ERROR',"Something went wrong")
                        }
                    });
                },
                title: 'Please confirm your selection below:',
                description: '',
                cancelText: 'Cancel',
                submitText: 'Unsubscribe',
                width: '480px'
            });

        } else {
            window.showNotification("ERROR",'You are not allow to do this as you are not owner of this campaign.')
        }
    }

    const onSubmit = (action) => {
        if (action === 'start') {
            toStart()
        } else if (action === 'pause') {
            toPause()
        } else if (action === 'unsubscribe') {
            toUnsub()
        }
    }

    let isRunning = false;
    let unsubscribe = false;
    let isPaused = false;
    let isComplete = false;
    let msg = '';

    if ((props.data?.subscription[0] && props.data?.subscription[0]?.is_unsubscribed === 1)) {
        isRunning = false;
        unsubscribe = true;
        msg = 'Unsubscribed';
    } else {
        if (props.data?.campaign != null) {
            if (props.data?.campaign?.status === 3) {


                if (props.data?.status === 1) {
                    isRunning = true;
                    msg = 'Running';
                } else if (pauseContactArr.includes(props.data?.status)) {

                    isRunning = false;
                    isPaused = true;
                    msg = 'Paused';
                } else if (props.data?.status === 6) {

                    isRunning = false;
                    isPaused = false;
                    isComplete= true
                    msg = 'Complete';
                }

            } else {
                isRunning = false;
                msg = 'Campaign Paused';
            }
        } else {
            isRunning = false;
            msg = 'Campaign Paused';
        }

    }

    const renderTriggerItemLabel = (flag='') => {
        if(flag==='isRunning'){
            return(
                <div className={Styles.runningLabelWrp}>
                    <i className={`material-icons ${Styles.leftIcon}`}>pause_arrow</i>
                    <span className={Styles.text}>{msg}</span>
                    <i className={`material-icons ${Styles.rightIcon}`}>expand_more</i>
                </div>
            );
        }
        else if(flag==='isPaused'){
            return(
                <div className={Styles.runningLabelWrp}>
                    <i className={`material-icons ${Styles.leftIcon}`}>play_arrow</i>
                    <span className={Styles.text}>{msg}</span>
                    <i className={`material-icons ${Styles.rightIcon}`}>expand_more</i>
                </div>
            );
        }
        else if(flag==='stop'){
            return(
                <div className={Styles.runningLabelWrp}>
                    <i className={`material-icons ${Styles.leftIcon}`}>stop</i>
                    <span className={Styles.text}>{msg}</span>
                    <i className={`material-icons ${Styles.rightIcon}`}>expand_more</i>
                </div>
            );
        }
        return ''
    }

    return (
        <div class="d-flex justify-content-between align-items-center v2_single__contact_campaign_item" >
            <a href={`/campaign/details/${props.data?.campaign?.id}`} >
                <h6 class="text-gray cursor-pointer">{Helper.reduceTextLength(props.data?.campaign?.title,20)}</h6>
            </a>

            {isRunning && (                
                <CustomMenuPype
                    popup={{
                        wrapperClass: Styles.popupWrapper,
                        placement: "left",
                        list: runningCampaignOptions,
                        renderList:(item, index, clickCallback) =>{
                            return (
                                <div className={`${Styles.itemWrp}`} key={index} onClick={clickCallback}>
                                    <span className={Styles.itemTitle}>{item.name}</span>
                                </div>
                            );
                        },
                        onClick: (item) => onSubmit(item.id),
                        height: "auto",
                        width: "auto",
                        listStyle: {
                            justifyContent: "left",
                        },
                    }}
                    label={{
                        renderCustom: renderTriggerItemLabel('isRunning'),
                    }}
                />

            )}
            {isPaused && (
                <CustomMenuPype
                    popup={{
                        wrapperClass: Styles.popupWrapper,
                        placement: "left",
                        list: pauseCampaignOptions,
                        renderList:(item, index, clickCallback) =>{
                            return (
                                <div className={`${Styles.itemWrp}`} key={index} onClick={clickCallback}>
                                    <span className={Styles.itemTitle}>{item.name}</span>
                                </div>
                            );
                        },
                        onClick: (item) => onSubmit(item.id),
                        height: "auto",
                        width: "auto",
                        listStyle: {
                            justifyContent: "left",
                        },
                    }}
                    label={{
                        renderCustom: renderTriggerItemLabel('isPaused'),
                    }}
                />
            )}
            {!isRunning && isComplete && !isPaused && (
                <CustomMenuPype
                    popup={{
                        wrapperClass: Styles.popupWrapper,
                        placement: "left",
                        list: completeCampaignOptions,
                        renderList:(item, index, clickCallback) =>{
                            return (
                                <div className={`${Styles.itemWrp}`} key={index} onClick={clickCallback}>
                                    <span className={Styles.itemTitle}>{item.name}</span>
                                </div>
                            );
                        },
                        onClick: (item) => onSubmit(item.id),
                        height: "auto",
                        width: "auto",
                        listStyle: {
                            justifyContent: "left",
                        },
                    }}
                    label={{
                        renderCustom: renderTriggerItemLabel('stop'),
                    }}
                />
            )}
            {!isRunning && !unsubscribe && !isPaused && !isComplete &&(
                <div class="running_btn_campaigns d-flex align-items-center bg-green px-2" onClick={() => campaignIsPaused(props.data?.campaign_id)} >
                    <i class="material-icons white-text">
                        play_arrow
                    </i>
                    <span class="white-text mx-2">{msg}</span>
                    <i class="material-icons white-text">
                        expand_more
                    </i>
                </div>
            )}
            {!isRunning && unsubscribe && !isPaused && (
                <div class="running_btn_campaigns d-flex align-items-center bg-green px-2">
                    <span class="white-text mx-2">{msg}</span>
                </div>
            )}
        </div>
    );
}

const mapStateToProps = state => {
    return {

    };
};
const mapDispatchToProps = dispatch => {
    return {
        campaignStart: (params, callback) => dispatch(campaignStart(params, callback)),
        campaignPause: (params, callback) => dispatch(campaignPause(params, callback))
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(Campaign);
