import React,{ useEffect} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import Utils, {getAccountData} from '../../helpers/Utils';
import { Grid } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DateTimeSetting=(props)=>{

    const userTimeZone = Utils.getAccountData('userTimezone');

    return (
        <React.Fragment>
            <div className="upload__form__start__wrapper d-flex form-group justify-content-center my-3 w-100">
                <div className='upload__form__start__flex upload__form__start__flex__1st pr-2 d-flex align-items-center'>
                    <div className=' '>
                        <p className="text-dark-blue m-0 text-left mr-3 common__label__v3">Date</p>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">

                            <KeyboardDatePicker
                                // margin="normal"
                                // id="date-picker-dialog"
                                // label="Date picker dialog"
                                format="MM/dd/yyyy"
                                value={props.date}
                                onChange={(date)=>props.timeSettingValue(date, 'date')}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                className="modal_bordered_text_field add_activity_text_field radius-5 white" 
                            />
                            </Grid>
                            </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className="upload__form__start__flex upload__form__start__flex__1st pl-2 d-flex align-items-center">
                    <div className={"pr-2 timepicker-wrapper"}>
                        <p className="text-dark-blue m-0 text-left mr-3 common__label__v3">Time</p>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                                <KeyboardTimePicker
                                    format="HH:mm"
                                    value={props.time}
                                    onChange={(date) => props.timeSettingValue(date,'time')}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    className="modal_bordered_text_field radius-5 white"
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <div className=''style={{paddingTop:"27px"}}> {userTimeZone}</div>
            </div>
            
        </React.Fragment>
    );
    
}
export default DateTimeSetting;