import React, {useContext, useEffect, useState} from "react";
import {ArrowBack, KeyboardArrowDown} from "@material-ui/icons";
import {
    Checkbox,
    TableBody,
    TableCell,
    TableContainer,
    Box,
    TableHead,
    TableRow,
    Table,
    MenuItem,
    FormControl,
    Select,
    Typography,
    CircularProgress, FormControlLabel, Button, withStyles,
} from "@material-ui/core";

import {
    BootstrapInput,
    isNumber,
    validateFilterAction,
} from "../FileReportDetailsSingle";
import Pagination from "../Pagination";
import Styles from "../FileReportDetails.module.css";
import { IconList } from "../../../globals/IconList";
import ModalAssignToTag from "../actions/ModalAssignToTag";
import ModalAssignToList from "../actions/ModalAssignToList";
import ModalExportContact from "../actions/ModalExportContact";
import AddressGroupedTableWrapper from "./AddressGroupedTableWrapper";
import { CoreButton } from "../../enhanceData/commons/CoreButton";
import ModalListForStageAdd from "../actions/ModalListForStageAdd";
import ModalAssignToCampaign from "../actions/ModalAssignToCampaign";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import ModalAssignToPowerDialer from "../actions/ModalAssignToPowerDialer";
import { cleanContactsActionsApi, cleanDataFilesDetailsApi } from "../../../../api/cleanDataApi";
import GlobalDropdown, { GlobalDropDownButton, GlobalDropDownItem } from "../../../globals/Dropdown/GlobalDropdown";
import {DataCleanContext} from "../../StateProvider";
import {STATUS, TAB_CLEAN_LIST} from "../../Constants";
import {renderSearchType} from "../FileReport";
import numberToDollar from "../../../../helpers/numberToDollar";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import AddressGroupedComplexItem from "./AddressGroupedComplexItem";
import AddressGroupedItemRegular from "./AddressGroupedItemRegular";
import EnhanceDataAlert from "../actions/enhanceDataAlert";
import {deleteCleanContacts} from "../../../../api/contactApi";
import DynamicSkeleton from "../../../common/DynamicSkeleton";

const AddressGroupedFileReportDetails = ({ filter, fileSource = "ALL", onChangeTab }) => {
    const { state } = useContext(DataCleanContext);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(25);
    const [loading, setLoading] = useState(false);
    const [fileContacts, setFileContacts] = useState(null);
    const [filterBy, setFilterBy] = useState({
        invalidNumbers: false,
        validNumbers: false,
        invalidEmails: false,
        validEmails: false,
        dncOn: false,
        dncOff: false,
        skipTrace: false,
        landline: false,
        mobile: false,
    });
    const [selectedFileContactIds, setSelectedFileContactIds] = useState([]);
    const [showListModal, setShowListModal] = useState(false);
    const [showAddToCampaignModal, setShowAddToCampaignModal] = useState(false);
    const [showAddToPowerDialerModal, setShowAddToPowerDialerModal] = useState(false);
    const [showTagModal, setShowTagModal] = useState(false);
    const [openAddStageToContactModal, setOpenAddStageToContactModal] = useState(false);
    const [openExportFileModal, setOpenExportFileModal] = useState(false);
    const [exportAll, setExportAll] = useState(false);
    const [currentPageIds, setCurrentPageIds] = useState([]);
    const [openEnhanceDataAlert, setOpenEnhanceDataAlert] = useState(false);
    const [resetSelect, setResetSelect] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [isActiveActionBtn, setIsActiveActionBtn] = useState(false);
    const ActionButton = withStyles((theme) => ({
        root: {
            backgroundColor: '#00ff91',
            color: 'black',
            '&:hover': {
                backgroundColor: '#80ffbf',
            },
            '&:active': {
                backgroundColor: '#00cc68',
            },
        },
    }))(Button);

    const handleActiveTab = (tabValue) => {
        if (onChangeTab) {
            onChangeTab(tabValue);
        }
    };

    const renderFileUrl = (file) => {
        if (file && file.aws3_info) {
            if (file.aws3_info && file.aws3_info === "\\") {
                return "";
            } else {
                let aws3Info = file.aws3_info.replace(/\\/g, "").replace(/&quot;/g, '"');
                try {
                    aws3Info = JSON.parse(aws3Info).fileUrl;
                } catch (error) {
                    console.log(error);
                }
                return aws3Info;
            }
        }
        return "";
    };

    const handleCurrentPageIds = (id) => {
        if (!currentPageIds.includes(parseInt(id))){
            setCurrentPageIds((prevState)=>([ ...prevState, parseInt(id) ]));
        }
    };

    const filterArray = filter.map((value) => {
        const result = validateFilterAction.find((filterValue) => filterValue.props === value);
        if (!result) throw Error("invalid filterBy type passed to component");
        return result;
    });

    const fetchFileDetails = () => {
        setLoading(true);

        cleanDataFilesDetailsApi({
            page: page,
            limit: limit,
            totalPage: totalPage,
            cleanDataRequestId: state.cleanDataRequestId,
            filterBy: Object.values(filterBy).every((value) => value === false) ? null : filterBy,
            fileSource,
        })
            .then((res) => {
                res = res.data;
                if (res.success) {
                    setCurrentPageIds([]);
                    setFileContacts(res.data);
                    setTotalPage(res.totalPage);
                    setTotalCount(res.totalCount);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleFilterChange = (name, value) => {
        setFilterBy({ ...filterBy, [name]: value });
    };

    const handleAddAllContactToCRM = () => {
        NewConfirmAlert({
            onSubmit: () => {
                const payload = {
                    cleanContactIds: [],
                    filterType: "ALL",
                    action: "ADD_TO_CRM",
                };

                if (state.cleanDataRequestId){
                    payload.fileId = state.cleanDataRequestId;
                }

                if (filterBy) {
                    if (filterBy.invalidNumbers) {
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validNumbers) {
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filterBy.invalidEmails) {
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validEmails) {
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload)
                    .then((res) => {
                        if (res && res.success) {
                            window.showNotification("success", res.message);
                        } else {
                            window.showNotification("error", "Something went wrong try again later");
                        }
                    })
                    .finally(() => {});
            },
            title: "Are your sure?",
            description: "You are about to add all contacts to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px",
        });
    };

    const handleAddSelectedContactToCRM = () => {
        if (selectedFileContactIds.length === 0) {
            window.showNotification("ERROR", "Check at least one contact");
            return;
        }
        NewConfirmAlert({
            onSubmit: () => {
                const payload = {
                    cleanContactIds: selectedFileContactIds,
                    filterType: "SEGMENT",
                    action: "ADD_TO_CRM",
                };

                if (state.cleanDataRequestId){
                    payload.fileId = state.cleanDataRequestId;
                }

                if (filterBy) {
                    if (filterBy.invalidNumbers) {
                        payload.numberStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validNumbers) {
                        payload.numberStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }

                    if (filterBy.invalidEmails) {
                        payload.emailStatus = "INVALID";
                        payload.filterType = "SEGMENT";
                    } else if (filterBy.validEmails) {
                        payload.emailStatus = "VALID";
                        payload.filterType = "SEGMENT";
                    }
                }
                cleanContactsActionsApi(payload)
                    .then((res) => {
                        if (res && res.success) {
                            window.showNotification("success", res.message);
                        } else {
                            window.showNotification("error", "Something went wrong try again later");
                        }
                    })
                    .finally(() => {});
            },
            title: "Are your sure?",
            description: "You are about to add selected contacts to CRM",
            cancelText: "Cancel",
            submitText: "Add",
            width: "480px",
        });
    };

    const handleDeleteSelectedContactToCRM = () => {
        if (selectedFileContactIds.length === 0) {
            window.showNotification("ERROR", "Check at least one contact");
            return;
        }
        NewConfirmAlert({
            onSubmit: async () => {
                try{
                    const res = await deleteCleanContacts({
                        page: page,
                        limit: limit,
                        totalPage: totalPage,
                        cleanDataRequestId: state.cleanDataRequestId,
                        filterBy: Object.values(filterBy).every((value) => value === false) ? null : filterBy,
                        fileSource: fileSource,
                        deletedContactIds: selectedFileContactIds
                    });

                    if (res && res.success){
                        let updatedCurrentIds = currentPageIds.filter(element => !selectedFileContactIds.includes(element));
                        setCurrentPageIds(updatedCurrentIds);
                        setFileContacts(res.data);
                        setTotalPage(res.totalPage);
                        setSelectedFileContactIds([]);
                        window.showNotification("success", "The selected contacts have been deleted");
                    }else {
                        window.showNotification("error", "Something went wrong try again later");
                    }
                }catch (err){
                    console.log(err);
                    window.showNotification("error", "Something went wrong try again later");
                }
            },
            title: "Are your sure?",
            description: "You are about to delete selected contacts",
            cancelText: "Cancel",
            submitText: "Yes",
            width: "480px",
        });
    };

    const handleSelectContact = (id) => {
        if (selectedFileContactIds.includes(id)) {
            setSelectedFileContactIds(selectedFileContactIds.filter((selectedFileContactId) => selectedFileContactId !== id));
        } else {
            setSelectedFileContactIds([...selectedFileContactIds, id]);
        }
    };

    const isSelected = (id) => {
        return selectedFileContactIds.includes(id);
    };

    const selectAddressGroup = (ids) => {
        setSelectedFileContactIds((prevState)=>([...prevState, ...ids]));
    };

    const deSelectAddressGroup = (ids) => {
        setSelectedFileContactIds((prevState)=>(prevState.filter((id)=>(!ids.includes(id)))));
    };

    const getTotalRecord = (file) => {
        if (!file.file_statistics) {
            return 0;
        }

        try {
            return JSON.parse(file.file_statistics).total_record;
        } catch (e) {
            return 0;
        }
    };

    const getChipColor = (file) => {
        switch (file.status) {
            case STATUS.ACTIVE:
                return Styles.active;
            case STATUS.PROCESSING:
                return Styles.processing;
            case STATUS.QUEUED:
                return Styles.queued;
            case STATUS.COMPLETE:
                return Styles.complete;
            case STATUS.FAILED:
                return Styles.failed;
            default:
                return Styles.default;
        }
    };

    useEffect(() => {
        if (page !== "") {
            fetchFileDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, limit, filterBy]);

    const handleClick = () => {
        setIsActiveActionBtn(!isActiveActionBtn);
    };

    const handleBlur = () => {
        setIsActiveActionBtn(false);
    };

    return (
        <div className={Styles.pageWrapper}>
            <div className={Styles.pageHeader}>
                <div style={{display: "grid"}}>
                    <Typography variant={"h6"}>File Validation Details</Typography>
                    <Typography variant={"caption"}>
                        Check the status of contacts of the files you've uploaded for verifications
                    </Typography>
                </div>
                <div className={Styles.pageHeaderAction}>
                    <Button
                        className={Styles.backButton}
                        startIcon={<ArrowBack/>}
                        variant='contained'
                        color='primary'
                        onClick={() => {
                            handleActiveTab(TAB_CLEAN_LIST.REPORTS);
                        }}
                    >
                        Back
                    </Button>
                </div>
            </div>

            <div className={Styles.pageBody}>
                <table>
                    <thead className={Styles.tableHead}>
                        <tr>
                            <th style={{width: "30%"}}>File Name</th>
                            <th className={Styles.textAlignCenter}>Date Uploaded</th>
                            <th className={Styles.textAlignCenter}>Total Records</th>
                            <th className={Styles.textAlignCenter}>Total Cost</th>
                            <th className={Styles.textAlignCenter}>Status</th>
                            <th className={Styles.textAlignCenter}>Failed Reason</th>
                            <th className={Styles.textAlignCenter}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td style={{width: "30%"}}>
                            <ul>
                                <ul>
                                    <li className={Styles.fileTitle}>{state.cleanDataRequest.file_name_original}</li>
                                    <li className={Styles.validationType}>
                                        <span className={Styles.validationTypeLabel}>Search type:</span>{" "}
                                        {renderSearchType(state.cleanDataRequest)}
                                    </li>
                                </ul>
                            </ul>
                        </td>
                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                            {window.globalTimezoneConversionToDifferentTimezone(state.cleanDataRequest.created_at)}
                        </td>
                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                            {getTotalRecord(state.cleanDataRequest)}
                        </td>
                        <td className={`${Styles.fontBold} ${Styles.textAlignCenter}`}>
                            {numberToDollar(state.cleanDataRequest.total_cost)}
                        </td>
                        <td className={Styles.textAlignCenter}>
                            <span className={`${Styles.statusChip} ${getChipColor(state.cleanDataRequest)}`}>
                              {state.cleanDataRequest.status}
                            </span>
                        </td>
                        <td className={Styles.textAlignCenter}>
                            {state.cleanDataRequest.failed_reason && (
                                <BootstrapTooltip arrow title={<span>{state.cleanDataRequest.failed_reason}</span>}>
                                    <div className={`${Styles.truncate} ${Styles.failedReason}`}>
                                        {state.cleanDataRequest.failed_reason}
                                    </div>
                                </BootstrapTooltip>
                            )}
                        </td>
                        <td>
                            <div className={Styles.fileActions}>
                                  <span
                                      className={`${Styles.viewDetails} ${Styles.cursorPointer}`}
                                      onClick={() => {
                                          handleActiveTab(TAB_CLEAN_LIST.REPORTS);
                                      }}
                                  >
                                    Hide Details
                                  </span>
                                {renderFileUrl(state.cleanDataRequest) !== "" ? (
                                    <CoreButton
                                        target='_blank'
                                        href={renderFileUrl(state.cleanDataRequest)}
                                        backgroundColor={"#316AFF"}
                                        color={"primary"}
                                        variant={"contained"}
                                    >
                                        Download
                                    </CoreButton>
                                ) : (
                                    <div/>
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={7}>
                            <div className={Styles.detailsPane}>
                                <div className={Styles.detailsPaneHeader}>
                                    <div className={Styles.detailsPaneHeaderLeft} style={{flexDirection: "column"}}>
                                        <div className={Styles.filterWrapper}>
                                            <Typography variant={"subtitle1"}>Filter Results: </Typography>
                                            {filterArray.map((value, index) => {
                                                return (
                                                    <div key={index} className={Styles.dFlexAlignCenter}>
                                                        <Checkbox
                                                            size={"small"}
                                                            checked={filterBy[value.key]}
                                                            onClick={() => {
                                                                setPage(1);
                                                                handleFilterChange(value.key, !filterBy[value.key]);
                                                            }}
                                                            color='primary'
                                                        />
                                                        <Typography variant={"body2"}>{value.title}</Typography>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className={Styles.detailsPaneHeaderRight} style={{ alignItems: "end" }}>
                                        <div>
                                            <GlobalDropdown className='add__action_dropdown' placement='bottomLeft'>
                                                <GlobalDropDownButton>
                                                    <ActionButton variant='contained' endIcon={<KeyboardArrowDown />}
                                                                  onClick={handleClick}
                                                                  onBlur={handleBlur}
                                                                  className={isActiveActionBtn ? 'active' : ''}>
                                                        Actions
                                                    </ActionButton>
                                                    {/*<CoreButton variant='contained' color='primary'*/}
                                                    {/*            endIcon={<KeyboardArrowDown/>}>*/}
                                                    {/*    Actions*/}
                                                    {/*</CoreButton>*/}
                                                </GlobalDropDownButton>
                                                <GlobalDropDownItem
                                                    onClick={()=>{
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setOpenEnhanceDataAlert(true)
                                                    }}
                                                    title='Enhance Data'
                                                    onClickHide={true}
                                                    icon={IconList.addToContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={handleAddAllContactToCRM}
                                                    title='Add All Contact to CRM'
                                                    onClickHide={true}
                                                    icon={IconList.addToContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={handleAddSelectedContactToCRM}
                                                    title='Add Selected Contact to CRM'
                                                    onClickHide={true}
                                                    icon={IconList.addToContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setShowListModal(true);
                                                    }}
                                                    title='Add To List'
                                                    onClickHide={true}
                                                    icon={IconList.addToListSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setShowAddToCampaignModal(true);
                                                    }}
                                                    title='Add To Campaign'
                                                    onClickHide={true}
                                                    icon={IconList.addToContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setShowAddToPowerDialerModal(true);
                                                    }}
                                                    title='Add To Power Dialer'
                                                    onClickHide={true}
                                                    icon={IconList.addToContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setShowTagModal(true);
                                                    }}
                                                    title='Assign Tag'
                                                    onClickHide={true}
                                                    icon={IconList.addTagSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setOpenAddStageToContactModal(true);
                                                    }}
                                                    title='Add to Pipeline Stage'
                                                    onClickHide={true}
                                                    icon={IconList.addToPipelineSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        setExportAll(true);
                                                        setOpenExportFileModal(true);
                                                    }}
                                                    title='Export All Contacts'
                                                    onClickHide={true}
                                                    icon={IconList.exportContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={() => {
                                                        if (selectedFileContactIds.length === 0) {
                                                            window.showNotification("ERROR", "Check at least one contact");
                                                            return;
                                                        }
                                                        setExportAll(false);
                                                        setOpenExportFileModal(true);
                                                    }}
                                                    title='Export Selected Contacts'
                                                    onClickHide={true}
                                                    icon={IconList.exportContactSVG}
                                                />
                                                <GlobalDropDownItem
                                                    onClick={handleDeleteSelectedContactToCRM}
                                                    title='Delete Selected Contacts'
                                                    onClickHide={true}
                                                    icon={IconList.deleteSelectedContactSVG}
                                                />
                                            </GlobalDropdown>
                                        </div>
                                    </div>
                                </div>
                                <div className={Styles.detailsPaneBody}>
                                    <AddressGroupedTableWrapper variant={"outlined"}>
                                        <TableContainer>
                                            <Table stickyHeader className={"skip-tracing-table"}>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            <Box style={{display: "flex", alignItems: "center"}}>
                                                                <FormControlLabel
                                                                    control={
                                                                        <Checkbox
                                                                            size="small"
                                                                            style={{color: "#fff"}}
                                                                            indeterminate={((selectedFileContactIds.length > 0) && (currentPageIds.length > selectedFileContactIds.length))}
                                                                        />
                                                                    }
                                                                    checked={((currentPageIds.length) > 0 && (currentPageIds.length === selectedFileContactIds.length))}
                                                                    onChange={(event, checked) => {
                                                                        if (checked) {
                                                                            setSelectedFileContactIds(currentPageIds);
                                                                        } else {
                                                                            setSelectedFileContactIds([]);
                                                                        }
                                                                    }}
                                                                />
                                                                Address
                                                            </Box>
                                                        </TableCell>
                                                        <TableCell>Name</TableCell>
                                                        <TableCell>Email</TableCell>
                                                        <TableCell className={Styles.textAlignCenter}>Email Status</TableCell>
                                                        <TableCell>Number</TableCell>
                                                        <TableCell className={Styles.textAlignCenter}>Number Status</TableCell>
                                                        <TableCell className={Styles.textAlignCenter}>Landline/Mobile</TableCell>
                                                        <TableCell className={Styles.textAlignCenter}>DNC Status</TableCell>
                                                        <TableCell className={Styles.textAlignCenter}>SkipTrace Status</TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {loading ? (
                                                        <TableRow>
                                                            <TableCell colSpan={9}>

                                                                <DynamicSkeleton count={10}/>
 
                                                            </TableCell>
                                                        </TableRow>
                                                    ) : (totalPage > 0 ?
                                                            Object.entries(fileContacts).map(([key, contacts], index)=>(
                                                                Object.values(contacts)[0]['id'] ?
                                                                    <AddressGroupedItemRegular
                                                                        contact={Object.values(contacts)[0]}
                                                                        index={index}
                                                                        isSelected={isSelected}
                                                                        onSelect={handleSelectContact}
                                                                        collectIds={handleCurrentPageIds}
                                                                    /> :
                                                                    <AddressGroupedComplexItem
                                                                        address={Object.keys(contacts)[0]}
                                                                        contacts={Object.values(contacts)[0]}
                                                                        index={index}
                                                                        isSelected={isSelected}
                                                                        onSelect={handleSelectContact}
                                                                        selectAddressGroup={selectAddressGroup}
                                                                        deSelectAddressGroup={deSelectAddressGroup}
                                                                        collectIds={handleCurrentPageIds}
                                                                        allSelected={((currentPageIds.length) > 0 && (currentPageIds.length === selectedFileContactIds.length))}
                                                                        resetSelect={resetSelect}
                                                                        setResetSelect={setResetSelect}
                                                                    />
                                                            )) :
                                                            <TableRow>
                                                                <TableCell colSpan={9}>
                                                                    <div className={Styles.loaderWrapper}>
                                                                        <h5 className={Styles.marginTop}>No contact
                                                                            found!</h5>
                                                                    </div>
                                                                </TableCell>
                                                            </TableRow>
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </AddressGroupedTableWrapper>

                                    <Box display='flex' justifyContent='space-between' alignItems='center' width={"60%"}>
                                        {totalCount ? <Typography variant="body1" style={{ fontSize: "14px", fontWeight: 600, color: "#64587e" }}>Showing <strong>{currentPageIds.length}</strong> of <strong>{totalCount}</strong> contacts</Typography> : null}
                                        <Box display='flex' alignItems='center'>
                                            {
                                                totalPage ?
                                                    <FormControl className={Styles.formControl}>
                                                        <Select
                                                            style={{width: "200px"}}
                                                            name='perPage'
                                                            value={limit}
                                                            displayEmpty
                                                            onChange={(e) => {
                                                                setPage(1);
                                                                setSelectedFileContactIds([]);
                                                                setLimit(e.target.value);
                                                            }}
                                                            input={<BootstrapInput/>}
                                                            inputProps={{"aria-label": "Without label"}}
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "left",
                                                                },
                                                                getContentAnchorEl: null,
                                                            }}
                                                        >
                                                            <MenuItem className='dropdownhelper-menuitem-class' value={""} disabled>
                                                                # Results
                                                            </MenuItem>
                                                            <MenuItem className='dropdownhelper-menuitem-class' value={25}>
                                                                25 Results
                                                            </MenuItem>
                                                            <MenuItem className='dropdownhelper-menuitem-class' value={50}>
                                                                50 Results
                                                            </MenuItem>
                                                            <MenuItem className='dropdownhelper-menuitem-class' value={100}>
                                                                100 Results
                                                            </MenuItem>
                                                            <MenuItem className='dropdownhelper-menuitem-class' value={250}>
                                                                250 Results
                                                            </MenuItem>
                                                            <MenuItem className='dropdownhelper-menuitem-class' value={500}>
                                                                500 Results
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl> :
                                                    <div/>
                                            }

                                            <Pagination
                                                page={page}
                                                totalPage={totalPage}
                                                callback={(uPage) => {
                                                    setPage(uPage);
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>

            {showListModal && (
                <ModalAssignToList
                    open={showListModal}
                    selectedFileContactIds={selectedFileContactIds}
                    onClose={() => setShowListModal(false)}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {showAddToCampaignModal && (
                <ModalAssignToCampaign
                    open={showAddToCampaignModal}
                    onClose={() => setShowAddToCampaignModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {showAddToPowerDialerModal && (
                <ModalAssignToPowerDialer
                    open={showAddToPowerDialerModal}
                    onClose={() => setShowAddToPowerDialerModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {showTagModal && (
                <ModalAssignToTag
                    open={showTagModal}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    onClose={() => setShowTagModal(false)}
                    tab={fileSource}
                />
            )}

            {openAddStageToContactModal && (
                <ModalListForStageAdd
                    open={openAddStageToContactModal}
                    onClose={() => setOpenAddStageToContactModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    filterBy={filterBy}
                    tab={fileSource}
                />
            )}

            {openExportFileModal &&
                <ModalExportContact
                    exportAll={exportAll}
                    filter={filterBy}
                    openExportFileModal={openExportFileModal}
                    onClose={() => setOpenExportFileModal(false)}
                    selectedFileContactIds={selectedFileContactIds}
                    tab={fileSource}
                />
            }

            {
                openEnhanceDataAlert &&
                <EnhanceDataAlert
                    filter={filterBy}
                    isOpen={openEnhanceDataAlert}
                    onClose={()=>{setOpenEnhanceDataAlert(false)}}
                    selectedFileContactIds={selectedFileContactIds}
                    tab={fileSource}
                />
            }
        </div>
    );
};

export default AddressGroupedFileReportDetails;