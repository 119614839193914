import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Divider, IconButton, styled } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CoreButton } from "./CoreButton";
import BasicModal from "./BasicModal";

const ModalHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "16px 24px",
});

const ModalContent = styled(Box)({
  height: "calc(100% - 131px)",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  padding: "16px 24px",
});

const ModalFooter = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "16px 24px",
  gap: "16px",
});

const ModalWithHeader = ({
  open,
  onClose,
  onSubmit,
  header,
  children,
  buttonText,
  saving = false
}) => {
  return (
    <BasicModal open={open} onClose={onClose}>
      <Box width={500} className={"de_root_wrapper"} style={{ height: "100%" }}>
        <ModalHeader>
          <Typography variant={"h6"}>{header}</Typography>
          <IconButton size={"small"} color={"secondary"} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <Divider light />

        <ModalContent>{children}</ModalContent>
        <Divider light />
        <ModalFooter>
          <CoreButton
            variant={"contained"}
            color={"primary"}
            onClick={onSubmit}
            disabled={saving}
          >
            {buttonText}
          </CoreButton>
          <CoreButton
            variant={"contained"}
            color={"secondary"}
            backgroundColor={"#f50057"}
            onClick={onClose}
          >
            Close
          </CoreButton>
        </ModalFooter>
      </Box>
    </BasicModal>
  );
};

export default ModalWithHeader;
