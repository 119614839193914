import React, { useState } from "react";
import { MenuItem, MenuList, Paper, Popover, styled } from "@material-ui/core";
import { UnlinkContactRelationship } from "../../../../api/contactApi";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import AddOrEditRelatedContactsModal from "./AddOrEditRelatedContactsModal";

const Wrapper = styled(Paper)(({ theme }) => ({
  borderRadius: "6px",
  "& .actionList": {
    paddingInline: 8,
    "& .MuiListItem-root": {
      color: "var(--dark_blue)",
      borderRadius: "6px",
    },
  },
}));

const RelatedContactActionPopover = ({ id, open, popover, onClose, contact, setRelatedContacts }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleEdit = () => {
    setModalOpen(true);
  };

  const handleUnlinkRelationship = () => {
    NewConfirmAlert({
      onSubmit: async () => {
        let response = await UnlinkContactRelationship({
          contactRelationshipId: contact.contactRelationshipId,
        });

        if (response.success) {
          setRelatedContacts((prevState) =>
            prevState.filter((item) => item.contactRelationshipId !== contact.contactRelationshipId)
          );
          window.showNotification("success", response.message);
          onClose();
        }
      },
      title: "Confirm",
      description: "Are you sure to unlink this contact?",
      cancelText: "No",
      submitText: "Yes",
      width: "480px",
    });
  };
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={popover}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Wrapper elevation={3}>
        <MenuList className='actionList'>
          <MenuItem onClick={handleEdit}>Edit Contact</MenuItem>
          <MenuItem onClick={handleUnlinkRelationship}>Unlink Contact</MenuItem>
        </MenuList>
      </Wrapper>

      <AddOrEditRelatedContactsModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          onClose();
        }}
        edit={contact}
        setRelatedContacts={setRelatedContacts}
      />
    </Popover>
  );
};

export default RelatedContactActionPopover;
