import React from "react";
import { Backdrop, Button, Fade, makeStyles, Modal, Typography } from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

const modalStyles = makeStyles((theme) => ({
  wrapperMain: {
    marginLeft: "10px",
    "& .openButton": {
      fontWeight: "600 !important",
      minWidth: "fit-content !important",
      textTransform: "capitalize",
      "&:hover, &:focus": {
        backgroundColor: "var(--accent-color) !important",
        color: "white !important",
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: 770,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "8px",
    boxShadow: theme.shadows[5],
  },
  modalHeader: {
    padding: "16px",
    color: "#fff",
    borderTopRightRadius: "8px",
    borderTopLeftRadius: "8px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d3d3d3",

    "& .title": {
      height: "unset !important",
      color: "white",
      fontWeight: 600,
      fontSize: 16,
    },
  },
  modalBody: {
    padding: "16px",
    paddingLeft: "30px",
  },
  modalFooter: {
    borderEndEndRadius: "8px",
    borderEndStartRadius: "8px",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "8px",
    borderTop: "1px solid #d3d3d3",
    paddingRight: "16px",
    height: "70px",
  },
  BackButton: {
    color: "#fff !important",
    width: "100px !important",
    fontWeight: 600,
    textTransform: "capitalize !important",
    backgroundColor: "#3C7EF3 !important",
  },
  typographyStyle: {
    height: "unset !important",
    color: "#133159",
    fontWeight: 500,
    fontSize: "15px",
    marginBottom: "20px",
  },
  optionsBox: {
    marginBottom: "20px",
    marginLeft: "32px",
  },
  listItem: {
    height: "32px",
    display: "flex",
    gap: "16px",
  },
  listIcon: {
    fontSize: "14px",
    color: "#133159",
    marginTop: "4px",
  },
}));

const ProcessModeHelpModal = ({ open, onClose }) => {
  const classes = modalStyles();

  return (
    <div className={classes.wrapperMain}>
      <Modal
        open={open}
        onClose={onClose}
        className={classes.modal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <div style={{ width: "50px", height: "50px" }}>
                <img
                  src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/drip_mode_icon.png`}
                  alt='logo'
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div style={{ marginLeft: "16px" }}>
                <Typography
                  style={{
                    height: "unset !important",
                    color: "#133159",
                    fontWeight: 600,
                  }}
                  variant='h5'
                >
                  Drip Mode vs. All At Once
                </Typography>
                <Typography
                  style={{
                    height: "unset !important",
                    color: "#133159",
                    fontWeight: 500,
                    fontSize: "15px",
                  }}
                  variant='h5'
                >
                  Should you choose drip mode or all at once?
                </Typography>
              </div>
            </div>

            <div className={classes.modalBody}>
              <div>
                <Typography
                  style={{
                    height: "unset !important",
                    color: "#133159",
                    fontWeight: 600,
                    marginBottom: "20px",
                  }}
                  variant='h5'
                >
                  Which option should you choose?
                </Typography>
                <Typography style={{ height: "unset !important" }} className={classes.typographyStyle}>
                  When uploading a list of contacts to a campaign, you'll be given the following 2 options:
                </Typography>
                <div className={classes.optionsBox}>
                  <div className={classes.listItem}>
                    <FiberManualRecord className={classes.listIcon} />
                    <Typography  style={{ height: "unset !important" }} className={classes.typographyStyle}>Process all at once</Typography>
                  </div>
                  <div className={classes.listItem}>
                    <FiberManualRecord className={classes.listIcon} />
                    <Typography  style={{ height: "unset !important" }} className={classes.typographyStyle}>Process in drip mode</Typography>
                  </div>
                </div>

                <Typography className={classes.typographyStyle}>
                  If you select "all at once," all contacts on your list will immediately be added to your campaign. If
                  you have a small list of 10-20 contacts this option is fine. Anything larger than that you'll likely
                  want to select drip mode.
                </Typography>

                <Typography  style={{ height: "unset !important" }} className={classes.typographyStyle}>
                  If you select, "drip mode," we will slowly add contacts to your campaign over a set interval. Drip
                  mode is the preferred option because it is a safer way to market. Phone companies and email providers
                  don't like to see a ton of messages going out all at once. This looks like spam to them. While not
                  fool-proof, the drip mode option will help you stay under the spam radar.
                </Typography>
              </div>
            </div>
            <div className={classes.modalFooter}>
              <Button onClick={onClose} className={classes.BackButton}>
                Back
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ProcessModeHelpModal;
