import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import React, {useRef, useState} from "react";
import { UploadIcon } from "../contact/Icons";
import {useDispatch} from "react-redux";
import {
  updateMappedFields,
  uploadCsvFileStart
} from "../../reducers/importContact/importContactSlice";
import Papa from "papaparse";
import mapCSVImportHelper from "./mapCSVImportHelper";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  uploadPaper: {
    borderRadius: theme.spacing(1),
    height: 150,
    cursor: "pointer",
    backgroundImage: `repeating-linear-gradient(0deg, #3c7ef3, #3c7ef3 10px, transparent 10px, transparent 15px),
                     repeating-linear-gradient(90deg, #3c7ef3, #3c7ef3 10px, transparent 10px, transparent 15px),
                     repeating-linear-gradient(180deg, #3c7ef3, #3c7ef3 10px, transparent 10px, transparent 15px),
                     repeating-linear-gradient(270deg, #3c7ef3, #3c7ef3 10px, transparent 10px, transparent 15px)`,
    borderImage: (props) =>
      `repeating-linear-gradient(0deg, #3c7ef3, #3c7ef3 10px, transparent 10px, transparent 20px)`,
    backgroundPosition: "0 0, 0 0, 100% 0, 0 100%",
    backgroundSize: "1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px",
    backgroundRepeat: "no-repeat",
    opacity: (props) => (props.isDragging ? 0.8 : 1),
    transition: theme.transitions.create(["opacity"], {
      duration: theme.transitions.duration.shorter,
    }),
  },
  dropContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
  },
  uploadText: {
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
  fileInput: {
    display: "none",
  },
  helperText: {
    marginTop: theme.spacing(0.5),
  },
}));

const Upload = ({ moveToNextStep }) => {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const classes = useStyles({ isDragging });
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsDragging(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    handleFileUpload(droppedFiles);
  };

  const handleFileUpload = (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);

    if (files[0].type !== 'text/csv'){
      window.showNotification("error", "Only csv file is allowed");
      return;
    }

    dispatch(uploadCsvFileStart(formData));

    Papa.parse(files[0], {
      skipEmptyLines: true,
      complete: (result) => {
        if (result.errors && result.errors[0] && !result.errors.every((error) => (error.code === 'UndetectableDelimiter'))){
          window.showNotification("error", "Invalid csv file");
          if (inputRef && inputRef.current){
            inputRef.current.value = "";
          }
          return;
        }
        const { mappedRows, unmappedRows, selectedRows } = mapCSVImportHelper.mapData(result.data);

        dispatch(updateMappedFields({
          mappedRows: mappedRows,
          unmappedRows: unmappedRows,
          mappedFields: selectedRows,
          alreadyMappedFields: Object.values(selectedRows)
        }));
        moveToNextStep();
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography style={{ height: "unset !important" }} variant='h5' color='textPrimary' className={classes.title}>
          Upload
        </Typography>
      </div>

      <Paper
        elevation={0}
        variant='outlined'
        className={classes.uploadPaper}
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        {isDragging ? (
          <div className={classes.dropContainer}>
            <UploadIcon />
            <Typography style={{ height: "unset !important" }} color='primary' variant='body2' className={classes.uploadText}>
              Drop the file here
            </Typography>
          </div>
        ) : (
          <div className={classes.dropContainer}>
            <UploadIcon />
            <input
              ref={inputRef}
              type='file'
              accept='.csv'
              className={classes.fileInput}
              onChange={(event) => {
                handleFileUpload(event.target.files);
              }}
            />
            <Typography style={{ height: "unset !important" }} color='primary' variant='body2' className={classes.uploadText}>
            Click here or drag & drop files to upload
            </Typography>
            <Typography style={{ height: "unset !important" }} variant='caption' className={classes.helperText}>
            Only .csv files are accepted - Maximum 20,000 leads
            </Typography>
          </div>
        )}
      </Paper>
    </div>
  );
};

export default Upload;
