import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Dialog,
  DialogContent,
  makeStyles,
} from "@material-ui/core";
import { BasicInput, LeftSideV2Styles } from "../LeftSideV2Styles";
import CustomModal from "../common/CustomModal/CustomModal";
import StarIcon from "@material-ui/icons/Star";
import PrimaryRoundedButton from "../common/button/PrimaryRoundedButton";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  createAdditionalContactApi,
  deleteAdditionalContactNumberApi,
  deleteContactNumberApi,
  makeDefaultAdditionalContactNumberApi,
  updateAdditionalContactApi,
  updateContactDetailsV2,
} from "../../../../api/contactApi";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import Utils from "../../../../helpers/Utils";
import CarrierLookup from "../common/carrierLookup/CarrierLookup";
import AddIcon from "@material-ui/icons/Add";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { PHONE_STATE, PHONE_TYPES } from "../helper/coreConstant";
import {
  isDeleteAble,
  setNumberType
} from "../helper/utils";
import { fetchContactAdditionalContacts, updateMultipleData } from "../../../../actions/contactAction";
import { connect } from "react-redux";
import { LOOKUP_TYPE_CARRIER_LOOKUP, LOOKUP_TYPE_DNC_LOOKUP } from "../../../../constants/CoreConstants";
import { LOOKUP_STATUS } from "../common/carrierLookup/Constants";
import PhoneIcon from "@material-ui/icons/Phone";

const useStyles = makeStyles({
  dialogContentWidth: {
    width: "640px",
  },
  errorMessage: {
    position: "absolute",
    bottom: "-12px",
  },
});

const EditPhoneNumberModal = ({
  open,
  onClose,
  contact,
  contactAdditionalContacts,
  updateMultipleData,
  fetchContactAdditionalContacts,
}) => {
  const { primaryButton, closeButton, flexColumn, alignJustifyCenter, secondaryButton, titleIcon, flexCenter } =
    LeftSideV2Styles();
  const [numbers, setNumbers] = useState([]);

  useEffect(() => {
    let tempNumbers = [];

    if (contact.number) {
      tempNumbers.push({
        isDefault: true,
        number: contact.number,
        phoneType: setNumberType(contact?.contact_additional_informations?.number_state),
        edited: false,
        backup: contact.number,
        error: "",
      });
    }
    if (contactAdditionalContacts && contactAdditionalContacts[0]) {
      for (let i = 0; i < contactAdditionalContacts.length; i++) {
        if (contactAdditionalContacts[i].number) {
          tempNumbers.push({
            isDefault: false,
            number: contactAdditionalContacts[i].number,
            phoneType: setNumberType(contactAdditionalContacts[i].number_state),
            backup: contactAdditionalContacts[i].number,
            id: contactAdditionalContacts[i].id,
            ogIndex: i,
            edited: false,
            error: "",
            number_validation_infos: contactAdditionalContacts[i].number_validation_infos,
            number_validation_status: contactAdditionalContacts[i].number_validation_status,
            dnc_validation_infos: contactAdditionalContacts[i].dnc_validation_infos,
            dnc_status: contactAdditionalContacts[i].dnc_status,
          });
        }
      }
    }

    setNumbers(tempNumbers);
  }, [contact, contactAdditionalContacts]);

  const onChangeField = (field, value, index) => {
    setNumbers((prevNumbers) => {
      const data = [...prevNumbers];

      if (field === "isDefault" && (!data[1] || value)) {
        data.forEach((item) => (item.isDefault = false));
      }

      data[index][field] = value;
      data[index].edited = data[index].backup !== value;
      data[index].error = "";

      return data;
    });
  };

  const onDeleteNumber = (index) => {
    if (!contact.number) {
      let data = [...numbers];
      data.splice(index, 1);

      setNumbers(data);
      return;
    }

    NewConfirmAlert({
      onSubmit: async () => {
        let response = await deleteContactNumberApi({
          contactId: contact.id,
        });

        if (response.success) {
          let data = [...numbers];
          data.splice(index, 1);

          setNumbers(data);
          let tmpContact = { ...contact };

          tmpContact.number = "";
          updateMultipleData({ contact: tmpContact });
        }
      },
      title: "Confirm",
      description: "Are you sure to delete this phone number?",
      cancelText: "No",
      submitText: "Yes",
      width: "480px",
    });
  };

  const onDeleteAdditionalNumber = (number, index) => {
    if (!number.id) {
      let data = [...numbers];
      data.splice(index, 1);

      setNumbers(data);
      return;
    }

    NewConfirmAlert({
      onSubmit: async () => {
        let response = await deleteAdditionalContactNumberApi({
          additionalContactId: number.id,
        });

        if (response.success) {
          let data = [...numbers];
          data.splice(index, 1);

          setNumbers(data);
          let tempAddi = [...contactAdditionalContacts];
          if (tempAddi[number.ogIndex]["email"]) {
            tempAddi[number.ogIndex]["number"] = null;
          } else {
            tempAddi.splice(number.ogIndex, 1);
          }
          updateMultipleData({ contactAdditionalContacts: tempAddi });
        }
      },
      title: "Confirm",
      description: "Are you sure to delete this phone number?",
      cancelText: "No",
      submitText: "Yes",
      width: "480px",
    });
  };

  const appendNumber = () => {
    let object = {
      isDefault: false,
      number: "",
      phoneType: PHONE_STATE.unknown,
      edited: false,
    };

    let data = [...numbers, object];

    setNumbers(data);
  };

  const onCloseEdit = (index) => {
    setNumbers((prevNumbers) => {
      const data = [...prevNumbers];

      data[index].number = data[index].backup;
      data[index].edited = false;
      data[index].error = "";

      return data;
    });
  };

  const makeDefault = (item, index) => {
    NewConfirmAlert({
      onSubmit: async () => {
        let response = await makeDefaultAdditionalContactNumberApi({
          additionalContactId: item.id,
        });

        if (response.success) {
          onChangeField("isDefault", true, index);
          defaultProcess(item, index);
          fetchContactAdditionalContacts({
            page_no: 1,
            per_page: 100,
            contact_id: contact.id,
          });
        }
      },
      title: "Confirm",
      description: "Would you like to make this the primary number for the contact?",
      cancelText: "No",
      submitText: "Yes",
      width: "480px",
    });
  };

  const onSaveDefaultNumber = async (number, index) => {
    let response = await updateContactDetailsV2({
      contactId: contact.id,
      params: {
        number: number.number,
        additional_informations: {
          number_state: number.phoneType,
        },
      },
    });
    if (response.success) {
      resetProcess({ number: number.number, number_state: number.phoneType, isDefault: true });
    } else if (!response.success && response.status === 403) {
      setNumbers((prevNumbers) => {
        const newData = [...prevNumbers];
        if (response.data && response.data.data && response.data.data.number) {
          newData[index].error = response.data.data.number[0];
        }
        return newData;
      });
    }
  };

  const onCreateAdditionalNumber = async (number, index) => {
    let response = await createAdditionalContactApi({
      contact_id: contact.id,
      number: number.number,
      number_state: number.phoneType,
      first_name: "---",
      last_name: "---",
      contact_relation: "OTHERS",
      label: "---",
    });

    if (response && response.data) {
      let data = response.data;
      if (data && data.status === "success") {
        let tempAddi = [...contactAdditionalContacts];
        tempAddi.push({
          id: data.data.id,
          contact_id: contact.id,
          number: number.number,
          number_state: number.phoneType,
          first_name: "---",
          last_name: "---",
          contact_relation: "OTHERS",
          label: "---",
        });
        updateMultipleData({ contactAdditionalContacts: tempAddi });
      } else if (data && data.status === "validation-error") {
        setNumbers((prevNumbers) => {
          const newData = [...prevNumbers];
          if (data.html && data.html.number) {
            newData[index].error = data.html.number[0];
          }
          return newData;
        });
      }
    }
  };

  const onUpdateAdditionalNumber = async (number, index) => {
    let response = await updateAdditionalContactApi({
      id: number.id,
      contact_id: contact.id,
      number: number.number,
      number_state: number.phoneType,
      first_name: "---",
      last_name: "---",
      contact_relation: "OTHERS",
      label: "---",
    });

    if (response && response.data) {
      let data = response.data;
      if (data && data.status === "success") {
        let tempAddi = [...contactAdditionalContacts];
        tempAddi[number.ogIndex].number = number.number;
        tempAddi[number.ogIndex].number_state = number.phoneType;

        if (number.backup !== number.number) {
          tempAddi[number.ogIndex].number_validation_infos = null;
          tempAddi[number.ogIndex].number_validation_status = LOOKUP_STATUS.NOT_VERIFIED;
          tempAddi[number.ogIndex].dnc_validation_infos = null;
          tempAddi[number.ogIndex].dnc_status = LOOKUP_STATUS.CHECK_DNC;
        }

        updateMultipleData({ contactAdditionalContacts: tempAddi });
      } else if (data && data.status === "validation-error") {
        setNumbers((prevNumbers) => {
          const newData = [...prevNumbers];
          if (data.html && data.html.number) {
            newData[index].error = data.html.number[0];
          }
          return newData;
        });
      }
    }
  };

  const handleSave = async (number, index) => {
    if (number.number !== undefined && number.number !== null && number.number.trim() === "") {
      setNumbers((prevNumbers) => {
        const data = [...prevNumbers];
        data[index].error = "Number is required!";
        return data;
      });
      return;
    }

    if (!Utils.validateMobileNumber(number.number)) {
      setNumbers((prevNumbers) => {
        const data = [...prevNumbers];
        data[index].error = "Invalid number!";
        return data;
      });
      return;
    }

    if (number.isDefault || !contact.number) {
      await onSaveDefaultNumber(number, index);
    } else {
      if (number.id) {
        await onUpdateAdditionalNumber(number, index);
      } else {
        await onCreateAdditionalNumber(number, index);
      }
    }
  };

  const defaultProcess = (item) => {
    let tmpContact = { ...contact };
    if (item.number) {
      tmpContact.number = item.number;

      if (!tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations = {};
      }

      tmpContact.contact_additional_informations.number_validation_status =
        item.number_validation_status || "NOT_VERIFIED";
      tmpContact.contact_additional_informations.number_validation_infos = item.number_validation_infos || null;

      tmpContact.contact_additional_informations.dnc_status = item.dnc_status || "NOT_VERIFIED";
      tmpContact.contact_additional_informations.dnc_validation_infos = item.dnc_validation_infos || null;

      tmpContact.contact_additional_informations.number_state = item.phoneType || "unknown";
    }
    updateMultipleData({ contact: tmpContact });
  };

  const resetProcess = (payload = null) => {
    let tmpContact = { ...contact };
    let backupNumber = tmpContact.number;

    if (payload && payload.number) {
      tmpContact.number = payload.number;
    }

    if (payload && payload.isDefault) {
      if (!tmpContact.contact_additional_informations) {
        tmpContact.contact_additional_informations = {};
      }

      if (tmpContact.contact_additional_informations) {
        if (backupNumber !== payload.number) {
          tmpContact.contact_additional_informations.number_validation_status = "NOT_VERIFIED";
          tmpContact.contact_additional_informations.number_validation_infos = null;

          tmpContact.contact_additional_informations.dnc_status = "NOT_VERIFIED";
          tmpContact.contact_additional_informations.dnc_validation_infos = null;
        }

        if (payload && payload.number_state) {
          tmpContact.contact_additional_informations.number_state = payload.number_state;
        }
      }
    }

    updateMultipleData({ contact: tmpContact });
  };
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      maxWidth={1200}
    >
      <CustomModal icon={<PhoneIcon />} title={"Update Phone Number"} open={open} onClose={onClose} />
      <DialogContent className={classes.dialogContentWidth}>
        <DialogContentText id='alert-dialog-description'>
          {numbers[0] && (
            <Box className={flexCenter} sx={{ justifyContent: "flex-end", mt: 2 }}>
              <PrimaryRoundedButton startIcon={<AddIcon />} onClick={appendNumber}>
                Add New
              </PrimaryRoundedButton>
            </Box>
          )}
          <Box className={flexColumn} sx={{ gap: 16 }} py={3}>
            {numbers[0] ? (
              numbers.map((number, index) => (
                <Grid container spacing={1} className={flexCenter} style={{ position: "relative" }}>
                  <Grid item md={3}>
                    <BasicInput
                        fullWidth
                        placeholder='Enter Number'
                        value={number.number}
                        onChange={(event) => onChangeField("number", event.target.value, index)}
                    />
                    {number.error && <FormHelperText className={classes.errorMessage}>{number.error}</FormHelperText>}
                  </Grid>
                  <Grid item md={3}>
                    <FormControl fullWidth sx={{ marginLeft: 2 }}>
                      <Select
                        labelId='phone-type-label'
                        id='phone-type'
                        value={number.phoneType}
                        displayEmpty
                        onChange={(event) => onChangeField("phoneType", event.target.value, index)}
                        input={<BasicInput />}
                        inputProps={{ "aria-label": "Without label" }}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {PHONE_TYPES.map((phoneType) => (
                          <MenuItem className='dropdownhelper-menuitem-class' value={phoneType.value}>
                            {phoneType.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {number.edited && (
                    <Grid item className={flexCenter}>
                      <Button
                        className={secondaryButton}
                        variant='contained'
                        color='secondary'
                        size='small'
                        disableElevation
                        style={{ borderRadius: 25, paddingBlock: 1, marginLeft: 5 }}
                        onClick={handleSave.bind(this, number, index)}
                      >
                        Save
                      </Button>
                      <Button
                        className={closeButton}
                        variant='contained'
                        color='secondary'
                        size='small'
                        disableElevation
                        style={{ borderRadius: 25, paddingBlock: 1, marginLeft: 5 }}
                        onClick={onCloseEdit.bind(this, index)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  )}
                  {!number.edited && Utils.getAccountData("carrierLookup") && number.number && (
                    <Grid item>
                      <Box sx={{ display: "flex" }}>
                        <CarrierLookup
                          lookupType={LOOKUP_TYPE_CARRIER_LOOKUP}
                          validator={true}
                          additional={number.isDefault ? null : number}
                        />
                      </Box>
                    </Grid>
                  )}
                  {!number.edited && Utils.getAccountData("dnc") && number.number && (
                    <Grid item>
                      <Box sx={{ display: "flex" }}>
                        <CarrierLookup
                          lookupType={LOOKUP_TYPE_DNC_LOOKUP}
                          validator={true}
                          additional={number.isDefault ? null : number}
                        />
                      </Box>
                    </Grid>
                  )}

                  <Grid item>
                    <Box>
                      {!number.edited && (number.id || number.isDefault) && (
                        <BootstrapTooltip arrow title={number.isDefault ? "Primary" : "Secondary"}>
                          <IconButton
                            size='small'
                            className={titleIcon}
                            onClick={() => {
                              if (!number.isDefault && number.number) {
                                makeDefault(number, index);
                              }
                            }}
                          >
                            {number.isDefault ? (
                              <StarIcon style={{ color: "#faaf00" }} fontSize='large' />
                            ) : (
                              <StarIcon color='action' fontSize='large' />
                            )}
                          </IconButton>
                        </BootstrapTooltip>
                      )}

                      {!number.edited && (number.isDefault ? isDeleteAble(contact, "number") : true) && (
                        <IconButton
                          size='small'
                          className={titleIcon}
                          onClick={() => {
                            if (number.isDefault && !isDeleteAble(contact, "number")){
                              window.showNotification("error", "No default email available for this contact.");
                              return;
                            }

                            if (number.isDefault){
                              onDeleteNumber(index);
                            }else {
                              onDeleteAdditionalNumber(number, index);
                            }
                          }}
                        >
                          <DeleteForeverIcon color='error' />
                        </IconButton>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              ))
            ) : (
              <Box className={flexCenter} sx={{ flexDirection: "column", justifyContent: "center", mt: 2 }}>
                <Typography variant='body1' color='textSecondary' style={{ marginBottom: "15px" }}>
                  <i>Phone Number Not Found</i>
                </Typography>
                <PrimaryRoundedButton startIcon={<AddIcon />} onClick={appendNumber}>
                  Add New
                </PrimaryRoundedButton>
              </Box>
            )}
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
    contactAdditionalContacts: state.contactReducer.contactAdditionalContacts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    fetchContactAdditionalContacts: (params) => dispatch(fetchContactAdditionalContacts(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPhoneNumberModal);
