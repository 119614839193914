import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";
import LeadType from "./LeadType";
import LeadOwner from "./LeadOwner";
import { Skeleton } from "@material-ui/lab";
import Styles from "./leadstatus.module.css";

export const LeadStatus = ({ contactDetails }) => {
  const { alignJustifyCenter } = LeftSideV2Styles();
  const [loading, setLoading] = useState(false);
  return (
    <Box className={Styles.leadstatus} sx={{ gap: "10px", my: 2 }}>
      <LeadOwner loading={loading} setLoading={setLoading}  contactDetails={contactDetails} />
      <LeadType loading={loading} contactDetails={contactDetails} />
    </Box>
  );
};
