import { Box } from "@material-ui/core";
import React from "react";
import ContactActions from "./ContactActions";
import ContactTitle from "./ContactTitle";

import { LeadStatus } from "../LeadStatus/LeadStatus";
import Conversation from "./Conversation";

const ContactBody = ({ handleOpen, contactDetails }) => {
  return (
    <Box
      sx={{
        paddingX: 1,
        paddingY: 1,
        border: "1px solid",
        mt: 2,
        borderRadius: "8px",
        backgroundColor: " #181F48",
        color: "#FFFFFF",
        position: 'relative', 
        
      }}
    >
      <ContactTitle handleOpen={handleOpen} />
      <LeadStatus contactDetails={contactDetails} />
      <Conversation />
      <ContactActions />
    </Box>
  );
};

export default ContactBody;
