import React from "react";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";

const STATUS_COLORS = {
  ACTIVE: "#28a745",
  INACTIVE: "#6c757d",
  DISCONNECTED: "#fd7e14",
  TRASH: "#dc3545",
  FAILED: "#b21f2d",
  NOT_PROCESSED: "#e0e0e0",
  QUEUE: "#007bff",
  PROCESSING: "#17a2b8",
  PROCESSED: "#28a745",
};

const useStyles = makeStyles(() => ({
  chip: (props) => ({
    backgroundColor: props.color,
    color: "#fff",
  }),
}));

const StatusChip = ({ status }) => {
  const color = STATUS_COLORS[status] || "#000";
  const classes = useStyles({ color });

  return <Chip size="small" label={status} className={classes.chip} />;
};

export default StatusChip;
