import { Paper, Tab, Tabs } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  cleanDataFileImportApi,
  getCustomFields,
} from "../../../../api/contactApi";
import { IconList } from "../../../globals/IconList";
import {
  TabPanel,
  useImportListPaperStyle,
  useImportListTabsStyle,
  useImportListTabStyle,
} from "./ImportContactStyles";
import MapContactHelper from "./MapContactHelper.js";
import MappedTable from "./MappedTable";
import ActionNeededTable from "./ActionNeededTable";
import "./mapContact.css";
import { DataCleanContext } from "../../StateProvider";
import ActionTypes from "../../ActionTypes";
import { FILE_IMPORT_STEP, TAB_CLEAN_LIST } from "../../Constants";

const formatFileSize = (bytes, decimalPoint) => {
  if (bytes === 0) return "0 Bytes";
  let k = 1000;
  let dm = decimalPoint || 2;
  let sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const MapContact = ({ onChangeTab, onSelectFile }) => {
  const { state, dispatch } = useContext(DataCleanContext);
  const [value, setValue] = useState(0);
  const [mappedRows, setMappedRows] = useState([]);
  const [actionNeededRows, setActionNeededRows] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});
  const [submitData, setSubmitData] = useState({});

  const tabClasses = useImportListTabStyle();
  const tabsClasses = useImportListTabsStyle();
  const paperClasses = useImportListPaperStyle();

  useEffect(() => {
    getCustomFields({}).then((res) => {
      let customFields = [];
      res.data.data.forEach((row) => {
        customFields.push({
          label: row.title,
          value: "customFields_" + row.id,
        });
      });
      setSelectOptions(MapContactHelper.getSelectOptions(customFields));
    });
  }, []);

  useEffect(() => {
    if (state.data && state.data.length > 0) {
      let mappedData = MapContactHelper.mapData(state.data);
      setSelectedFields(mappedData.selectedRows);
      setTimeout(() => {
        setMappedRows(mappedData.mappedRows);
      }, 1000);
      setActionNeededRows(mappedData.actionNeededRows);
      setSubmitData(mappedData.submitData);
    }
  }, [state.data]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.UPDATE_STATE,
      payload: {
        submitData: submitData,
      },
    });
  }, [submitData]);

  const handleActionNeededOptionChange = (e, index) => {
    const actionNeedArr = [...actionNeededRows];
    const actionNeedValue = actionNeedArr[index];
    actionNeedArr.splice(index, 1);
    setActionNeededRows(actionNeedArr);
    setMappedRows([...mappedRows, actionNeedValue]);
    setSelectedFields({ ...selectedFields, [actionNeedValue.index]: e });

    let splitData = e.value.split("_");
    if (splitData[0] === "customFields") {
      setSubmitData({
        ...submitData,
        [actionNeedValue.index]: {
          map: "custom[" + actionNeedValue.index + "]",
          data: e.value.replace("customFields_", ""),
        },
      });
    } else {
      setSubmitData({
        ...submitData,
        [actionNeedValue.index]: {
          map: "data[" + actionNeedValue.index + "]",
          data: e.value,
        },
      });
    }
  };

  const handleMappedOptionChange = (e, index) => {
    if (e.value === "") {
      const mappedRowsArr = [...mappedRows];
      const mappedValue = mappedRowsArr[index];
      mappedRowsArr.splice(index, 1);
      setMappedRows(mappedRowsArr);
      setActionNeededRows([...actionNeededRows, mappedValue]);

      const selectedFieldsObj = { ...selectedFields };
      delete selectedFieldsObj[mappedValue.index];
      setSelectedFields(selectedFieldsObj);
      setSubmitData({
        ...submitData,
        [mappedValue.index]: {
          map: "data[" + mappedValue.index + "]",
          data: "",
        },
      });
    } else {
      setSelectedFields({ ...selectedFields, [mappedRows[index].index]: e });

      let splitData = e.value.split("_");
      if (splitData[0] === "customFields") {
        setSubmitData({
          ...submitData,
          [mappedRows[index].index]: {
            map: "custom[" + mappedRows[index].index + "]",
            data: e.value.replace("customFields_", ""),
          },
        });
      } else {
        setSubmitData({
          ...submitData,
          [mappedRows[index].index]: {
            map: "data[" + mappedRows[index].index + "]",
            data: e.value.replace("customFields_", ""),
          },
        });
      }
    }
  };

  const showViewResultPopUp = () => {
    window.globalImportEnhancePopupData(true, {
      requestModule: "CONTACT",
      callback: (res) => {
      },
    });
  }

  const onSubmit = () => {
    const formData = new FormData();

    const data = state.submitData;
    for (const [key, value] of Object.entries(data)) {
      formData.append(value.map, value.data);
    }

    formData.append("carrier_infos", JSON.stringify(state.carrierInfos));
    formData.append("file", state.file);

    dispatch({
      type: ActionTypes.UPDATE_STATE,
      payload: {
        activeStep: FILE_IMPORT_STEP.STEP_SUCCESS,
      },
    });

    cleanDataFileImportApi(formData)
      .then((res) => {
        res = res.data;
        if (res && res.success) {
          dispatch({
            type: ActionTypes.UPDATE_STATE,
            payload: {
              activeStep: FILE_IMPORT_STEP.STEP_IMPORT_MAPPING,
            },
          });

          // let currentURL = window.location.href;
          // if (currentURL.includes("clean-list?tab=verify")) {
          //   setTimeout(() => {
          //     showViewResultPopUp();
          //   }, 5000);
          // }

          if (onChangeTab) {
            onChangeTab(TAB_CLEAN_LIST.REPORTS);
          }
          if (onSelectFile) {
            onSelectFile(false);
          }
        } else {
          window.showNotification("error", res.message);
          dispatch({
            type: ActionTypes.UPDATE_STATE,
            payload: {
              activeStep: FILE_IMPORT_STEP.STEP_IMPORT_MAPPING,
            },
          });
        }
      })
      .catch((err) => {
        window.showNotification(
          "error",
          "Something went wrong try again later!"
        );
        dispatch({
          type: ActionTypes.UPDATE_STATE,
          payload: {
            activeStep: FILE_IMPORT_STEP.STEP_IMPORT_MAPPING,
          },
        });
      });
  };

  return (
    <div className="file_upload_section_wrapper white">
      <div className="uploaded_file_details_section">
        <div className="uploaded_file_details_content d-flex justify-content-start">
          <div className="uploaded_file_details d-flex align-items-center">
            <div className="uploaded_file_icon justify-content-start">
              <svg
                width="20"
                height="20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)" fill="#133159">
                  <path d="M17.083 20h-10A2.086 2.086 0 0 1 5 17.917v-12.5c0-1.15.935-2.083 2.083-2.083h10c1.149 0 2.084.934 2.084 2.083v12.5c0 1.149-.935 2.083-2.084 2.083zm-10-15.833c-.689 0-1.25.56-1.25 1.25v12.5c0 .689.561 1.25 1.25 1.25h10c.69 0 1.25-.561 1.25-1.25v-12.5c0-.69-.56-1.25-1.25-1.25h-10z" />
                  <path d="M3.75 17.5h-.834a2.086 2.086 0 0 1-2.083-2.083V2.083C.833.934 1.768 0 2.916 0h10C14.065 0 15 .934 15 2.083a.417.417 0 0 1-.834 0c0-.689-.56-1.25-1.25-1.25h-10c-.689 0-1.25.561-1.25 1.25v13.334c0 .689.561 1.25 1.25 1.25h.834a.417.417 0 0 1 0 .833zM15.416 14.167H8.75a.417.417 0 0 1 0-.833h6.666a.417.417 0 0 1 0 .833zM15.416 17.5H8.75a.417.417 0 0 1 0-.834h6.666a.417.417 0 0 1 0 .834z" />
                  <path d="M15.416 10.833H8.75a.417.417 0 0 1 0-.833h6.666a.417.417 0 0 1 0 .833zM15.416 7.5H8.75a.417.417 0 0 1 0-.833h6.666a.417.417 0 0 1 0 .833z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p>
              File Name : <span>{state.file.name}</span>
            </p>
          </div>
          <div className="uploaded_file_details d-flex align-items-center justify-content-start">
            <div className="uploaded_file_icon">
              <svg
                width="20"
                height="20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#a)" fill="#133159">
                  <path d="M10 3.478a3.047 3.047 0 0 0-3.043 3.044A3.047 3.047 0 0 0 10 9.565a3.047 3.047 0 0 0 3.043-3.043A3.047 3.047 0 0 0 10 3.478zm0 5.217a2.177 2.177 0 0 1-2.174-2.173c0-1.199.976-2.174 2.174-2.174s2.174.975 2.174 2.174A2.177 2.177 0 0 1 10 8.695zm6.087-1.738a2.177 2.177 0 0 0-2.174 2.173c0 1.199.976 2.174 2.174 2.174a2.177 2.177 0 0 0 2.174-2.174 2.177 2.177 0 0 0-2.174-2.173zm0 3.478a1.305 1.305 0 1 1 .002-2.61 1.305 1.305 0 0 1-.002 2.61zM3.975 6.957c-1.2 0-2.174.975-2.174 2.173 0 1.199.975 2.174 2.174 2.174A2.177 2.177 0 0 0 6.149 9.13a2.176 2.176 0 0 0-2.174-2.173zm0 3.478a1.306 1.306 0 0 1 0-2.609 1.306 1.306 0 0 1 0 2.609zm6.025 0a5.659 5.659 0 0 0-5.652 5.652.435.435 0 0 0 .87 0A4.788 4.788 0 0 1 10 11.304a4.788 4.788 0 0 1 4.782 4.783.435.435 0 0 0 .87 0A5.659 5.659 0 0 0 10 10.435z" />
                  <path d="M16.087 12.174c-.716 0-1.417.196-2.027.565a.435.435 0 0 0 .451.743 3.047 3.047 0 0 1 4.62 2.604.435.435 0 0 0 .869 0 3.918 3.918 0 0 0-3.913-3.912zm-10.147.564A3.915 3.915 0 0 0 0 16.087a.435.435 0 0 0 .87 0 3.047 3.047 0 0 1 4.618-2.605.435.435 0 0 0 .451-.744z" />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h20v20H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p>
              Leads: <span>{state.total}</span>
            </p>
          </div>
          <div className="uploaded_file_details d-flex align-items-center justify-content-start">
            <div className="uploaded_file_icon">
              <svg
                width="20"
                height="20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.383 3.895H11.17L9.733 2.193a.248.248 0 0 0-.19-.088H3.726c-.893 0-1.617.724-1.617 1.617v.472h-.493C.725 4.194.001 4.917 0 5.81v10.468c0 .893.724 1.617 1.617 1.617h14.656c.893 0 1.617-.724 1.617-1.617v-.472h.493c.893 0 1.617-.724 1.617-1.618V5.512c0-.893-.724-1.617-1.617-1.617zm-.994 11.424v.97a1.12 1.12 0 0 1-1.119 1.118H1.617A1.12 1.12 0 0 1 .5 16.288V5.82a1.12 1.12 0 0 1 1.118-1.119h5.715l1.436 1.702a.248.248 0 0 0 .191.088h7.328a1.12 1.12 0 0 1 1.102 1.11v7.718zm2.11-1.118a1.12 1.12 0 0 1-1.118 1.118h-.493V7.602c0-.894-.724-1.618-1.618-1.618H9.06L7.623 4.283a.248.248 0 0 0-.19-.089H2.61v-.472a1.12 1.12 0 0 1 1.118-1.118h5.707l1.436 1.701a.248.248 0 0 0 .191.089h7.328a1.12 1.12 0 0 1 1.118 1.118l-.01 8.689z"
                  fill="#133159"
                />
              </svg>
            </div>
            <p>
              Size: <span>{formatFileSize(state.file.size)}</span>
            </p>
          </div>
        </div>
        <p className="campaign-warn m-0"></p>
      </div>
      <Paper square classes={paperClasses}>
        <Tabs
          value={value}
          classes={tabsClasses}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, value) => setValue(value)}
          aria-label="disabled tabs example"
          className="contact__import_tab"
        >
          <Tab
            classes={tabClasses}
            icon={IconList.contactImportTab1}
            label={`Mapped (${mappedRows.length})`}
          />
          <Tab
            classes={tabClasses}
            icon={IconList.contactImportTab2}
            label={`Action Needed (${actionNeededRows.length})`}
          />
        </Tabs>
      </Paper>
      <div>
        <TabPanel value={value} index={0}>
          <div id="tab1">
            <MappedTable
              rows={mappedRows}
              selectOptions={selectOptions}
              onOptionChange={handleMappedOptionChange}
              selectedFields={selectedFields}
            />
            <UploadAndNext onSubmit={onSubmit} />
          </div>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <div id="tab2">
            <ActionNeededTable
              rows={actionNeededRows}
              selectOptions={selectOptions}
              onOptionChange={handleActionNeededOptionChange}
            />
            <UploadAndNext onSubmit={onSubmit} />
          </div>
        </TabPanel>
      </div>
    </div>
  );
};

const UploadAndNext = ({ onSubmit }) => {
  return (
    <div
      className="d-flex justify-content-center content_duel_buttons mb-5"
      style={{ color: "#fff" }}
    >
      <div>
        <a
          onClick={(e) => {
            e.preventDefault();
            onSubmit(e);
          }}
          href="#!"
          className="main_content_container_button white-text d-flex justify-content-center align-items-center back_next_buttons accent--bg--text--color"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="main_content_container_button_icon"
          >
            <path d="M20 10C20 4.4774 15.5226 0 10 0C4.4774 0 0 4.4774 0 10C0 15.5226 4.4774 20 10 20C15.5226 20 20 15.5226 20 10ZM9.77644 14.3923C9.70471 14.3212 9.64769 14.2366 9.60865 14.1434C9.56961 14.0502 9.54931 13.9502 9.54891 13.8492C9.54851 13.7482 9.56802 13.6481 9.60631 13.5546C9.64461 13.4611 9.70095 13.376 9.77212 13.3043L12.2875 10.7692H5.86538C5.66137 10.7692 5.46571 10.6882 5.32146 10.5439C5.1772 10.3997 5.09615 10.204 5.09615 10C5.09615 9.79599 5.1772 9.60033 5.32146 9.45607C5.46571 9.31181 5.66137 9.23077 5.86538 9.23077H12.2875L9.77212 6.69567C9.70096 6.62389 9.64464 6.53879 9.60638 6.44524C9.56811 6.35169 9.54864 6.25152 9.54909 6.15045C9.54954 6.04937 9.56989 5.94938 9.60898 5.85617C9.64807 5.76296 9.70514 5.67837 9.77692 5.60721C9.84871 5.53606 9.9338 5.47974 10.0274 5.44147C10.1209 5.40321 10.2211 5.38374 10.3221 5.38419C10.4232 5.38463 10.5232 5.40498 10.6164 5.44407C10.7096 5.48317 10.7942 5.54023 10.8654 5.61202L14.6822 9.45817C14.8252 9.60226 14.9054 9.79702 14.9054 10C14.9054 10.203 14.8252 10.3977 14.6822 10.5418L10.8654 14.388C10.7942 14.4599 10.7096 14.517 10.6163 14.5561C10.523 14.5952 10.423 14.6156 10.3218 14.616C10.2207 14.6164 10.1204 14.5968 10.0268 14.5585C9.93327 14.5201 9.84818 14.4636 9.77644 14.3923Z" />
          </svg>
          <span>Upload</span>
        </a>
      </div>
    </div>
  );
};

export default MapContact;
