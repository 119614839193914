import React, { Fragment, useEffect, useState } from "react";
import EmptyPolicies from "./EmptyPolicies";
import AddPolicyModal from "./AddPolicyModal";
import AddNewPolicyTypeModal from "./AddNewPolicyTypeModal";
import { Add as AddIcon } from "@material-ui/icons";
import {
  BasicButton,
  InsuranceCardContainer,
  InsuranceWrapper,
  LoadingComponent,
  NewInsurancePolicy,
} from "./PolicyStyle";
import EachInsurance from "./EachInsurance";
import { CircularProgress } from "@material-ui/core";

import { connect } from "react-redux";
import { fetchContactPolicies, fetchPolicyCompanyAgentData } from "../../rightSide/redux/contactAction";

const Policies = (props) => {
  const [openPolicyModal, setOpenPolicyModal] = useState(false);
  const [openAddNewPolicyTypeModal, setOpenAddNewPolicyTypeModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [policyId, setPolicyId] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState("");

  useEffect(() => {
    fetchContactPolicyData();
  }, []);

  const generateObject = () => {
    const createObjectFromData = (data) => {
      return (data || []).reduce((acc, curr) => {
        acc[curr.value] = curr.label;
        return acc;
      }, {});
    };

    const policyObject = createObjectFromData(props.contactPolicyCompanyAgents.policyTypes);
    const companyObject = createObjectFromData(props.contactPolicyCompanyAgents.insuranceCompanies);
    const agentObject = createObjectFromData(props.contactPolicyCompanyAgents.agents);

    return {
      policyType: policyObject,
      company: companyObject,
      agent: agentObject,
    };
  };

  const fetchContactPolicyData = () => {
    props.getPolicies({ contact_id: props.contact.id });
    setLoading(false);
  };
  const handleOpenPolicyModal = () => {
    setPolicyId(null)
    setOpenPolicyModal(true);
  };
  
  const handleCloseModal=(isFetch)=>{
   
    if(isFetch){
      fetchContactPolicyData()
      setOpenPolicyModal(false)
      setSelectedPolicy('')
    }
    else{
      setOpenPolicyModal(false)
      setSelectedPolicy('')
    }
    
  }

  const handlePolicyTypeModal=()=>{
    setOpenAddNewPolicyTypeModal(false)
  }

  return (
    <div>
      <InsuranceWrapper>
        <Fragment>
          {loading && (
            <LoadingComponent>
              <CircularProgress />
            </LoadingComponent>
          )}

          {props.contactPolicies[0] && (
            <NewInsurancePolicy>
              <BasicButton startIcon={<AddIcon />} onClick={handleOpenPolicyModal}>
                New Policy
              </BasicButton>
            </NewInsurancePolicy>
          )}
          <InsuranceCardContainer className={"awesome__scroll_bar"}>
            {!loading && props.contactPolicies[0] ? (
              <>
                {props?.contactPolicies?.map((item, i) => (
                  <EachInsurance
                    key={i}
                    dataObject={generateObject()}
                    item={item}
                    fetchContactPolicyData={fetchContactPolicyData}
                    setOpenPolicyModal={setOpenPolicyModal}
                    setPolicyId={setPolicyId}
                  />
                ))}
              </>
            ) : (
              <>{!loading && <EmptyPolicies setOpenPolicyModal={setOpenPolicyModal} />}</>
            )}
          </InsuranceCardContainer>
        </Fragment>
      </InsuranceWrapper>

      {openPolicyModal && (
        <AddPolicyModal
          open={openPolicyModal}
          contact={props.contact}
          policies={props.contactPolicyCompanyAgents.policyTypes}
          companies={props.contactPolicyCompanyAgents.insuranceCompanies}
          agents={props.contactPolicyCompanyAgents.agents}
          setOpenAddNewPolicyTypeModal={setOpenAddNewPolicyTypeModal}
          handleCloseModal={handleCloseModal}
          policyId={policyId}
          policyData={props.contactPolicies}
          selectedPolicy={selectedPolicy}
          setSelectedPolicy={setSelectedPolicy}
          onSaveSucces={()=>{props.getPolicies({ contact_id: props.contact.id });}}
        />
      )}
      {openAddNewPolicyTypeModal && (
        <AddNewPolicyTypeModal
          open={openAddNewPolicyTypeModal}
          setSelectedPolicy={setSelectedPolicy}
          onClose={handlePolicyTypeModal}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
    contactPolicies: state.contactReducer.contactPolicies,
    contactPolicyCompanyAgents: state.contactReducer.contactPolicyCompanyAgentData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPolicies: (params) => dispatch(fetchContactPolicies(params)),
    getPolicyCompanyAgentData:(params) => dispatch(fetchPolicyCompanyAgentData(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Policies);
