import { takeLatest, call, put, all } from 'redux-saga/effects';

import {
    loadCampaignDataFail,
    loadCampaignDataStart,
    loadCampaignDataSuccess,
    loadInitialDataFail,
    loadInitialDataStart,
    loadInitialDataSuccess,
    loadPipelineDataFail,
    loadPipelineDataStart,
    loadPipelineDataSuccess,
    loadStageDataFail,
    loadStageDataStart,
    loadStageDataSuccess,
    uploadCsvFileFail,
    uploadCsvFileStart,
    uploadCsvFileSuccess
} from "./importContactSlice";
import importContactService from "./importContactService";

function* uploadCsvFile(action) {
    try{
        const response = yield call(importContactService.uploadCsvFile, action.payload);

        if (response.success) {
            yield put(uploadCsvFileSuccess(response.data));
        } else {
            yield put(uploadCsvFileFail());
        }
    } catch (err) {
        yield put(uploadCsvFileFail());
    }
}

function* loadInitialData(action) {
    try {
        const [response, customFieldResponse] = yield all([
            call(importContactService.loadInitialData, action.payload),
            call(importContactService.loadCustomFieldData)
        ]);

        if (response.success && customFieldResponse.success) {
            const combinedData = {
                ...response.data,
                customFields: customFieldResponse.data,
            };

            yield put(loadInitialDataSuccess(combinedData));
        } else {
            yield put(loadInitialDataFail());
        }
    } catch (err) {
        yield put(loadInitialDataFail());
    }
}


function* loadPipelineData(action) {
    try{
        const response = yield call(importContactService.loadPipelineData, action.payload);

        if (response.success){
            yield put(loadPipelineDataSuccess(response.data));
        }else {
            yield put(loadPipelineDataFail());
        }
    }catch (err){
        yield put(loadPipelineDataFail());
    }
}

function* loadStageData(action) {
    try{
        const response = yield call(importContactService.loadStageData, action.payload);

        if (response.success){
            yield put(loadStageDataSuccess(response.data));
        }else {
            yield put(loadStageDataFail());
        }
    }catch (err){
        yield put(loadStageDataFail());
    }
}

function* loadCampaignData(action) {
    try{
        const response = yield call(importContactService.loadCampaignData, action.payload);

        if (response.success){
            yield put(loadCampaignDataSuccess(response.data));
        }else {
            yield put(loadCampaignDataFail());
        }
    }catch (err){
        yield put(loadCampaignDataFail());
    }
}

export function* watchImportContactSaga() {
    yield takeLatest(uploadCsvFileStart.type, uploadCsvFile);
    yield takeLatest(loadInitialDataStart.type, loadInitialData);
    yield takeLatest(loadPipelineDataStart.type, loadPipelineData);
    yield takeLatest(loadStageDataStart.type, loadStageData);
    yield takeLatest(loadCampaignDataStart.type, loadCampaignData);
}