import { makeStyles } from "@material-ui/core";

const modalUseStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus-visible": {
      outline: "none"
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid #000",
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(0.5),
    width: '98vw',
    height: '98vh',
    "&:focus-visible": {
      outline: "none"
    }
  },
  modalHeader: {
    Color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2, 3),
  },
}));

export default modalUseStyles;
