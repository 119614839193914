import React from 'react';

const ProductSkeleton = () => {
  const styles = {
    skeletonContainer: {
      display: 'flex',
      gap: '15px',
      paddingTop: '10px',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      marginBottom: '10px',
    },
    skeletonBox: {
      backgroundColor: '#e0e0e0',
      borderRadius: '6px',
      width: '300px',
      height: '400px',
      animation: 'pulse 1.5s infinite ease-in-out',
     
    },
    keyframes: `
      @keyframes pulse {
        0% {
          background-color: #e0e0e0;
        }
        50% {
          background-color: #f5f5f5;
        }
        100% {
          background-color: #e0e0e0;
        }
      }
    `,
  };

  return (
    <>
      <style>{styles.keyframes}</style>
      <div style={styles.skeletonContainer}>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
      </div>
      <div style={styles.skeletonContainer}>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
      </div>
      <div style={styles.skeletonContainer}>
        <div style={styles.skeletonBox}></div>
        <div style={styles.skeletonBox}></div>
      </div>
    </>
  );
};

export default ProductSkeleton;
