import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";
import {sendRequest} from "./rootApi";

export const createUserProduct = (body) => {
  return HttpRequest.post(Api.createUserProduct, body);
};

export const getContactSoldProducts = (id) => {
  return HttpRequest.get(`${Api.getContactSoldProducts}/${id}`);
};

export const userProductStatus = async (payload) => {
  return HttpRequest.put(`${Api.userProductStatus}/${payload.id}`, payload);
};

export const changeDealStatus = async (payload) => {
  return sendRequest("post", Api.changeDealStatus, payload);
}

export const getPolicyCompanyInsuranceData = async () => {
  return HttpRequest.get(`${Api.getPolicyApi}`);
}

export const createUserPolicy = async (payload) => {
  return HttpRequest.post(`${Api.createUserPolicyApi}`,payload);
}
export const getContactPolicy = async (payload) => {
  return HttpRequest.get(`${Api.getContactPolicyApi}`,payload);
}
export const getContactPolicyCategory = async () => {
  return HttpRequest.get(`${Api.getContactPolicyCategoryApi}`);
}

export const addContactPolicy = async (payload) => {
  return HttpRequest.post(`${Api.addContactPolicyApi}`,payload);
}

export const deleteContactPolicy = async (payload) => {
  return HttpRequest.delete(`${Api.deleteContactPolicyApi}`,payload);
}

export const updateContactPolicy = async (payload) => {
  return HttpRequest.put(`${Api.updateContactPolicyApi}`,payload);
}

export const updateContactPolicyStatus = async (payload) => {
  return HttpRequest.put(`${Api.updateContactPolicyStatusApi}`,payload);
}

export const dealWinAfterPolicyCreate = (payload) => {
  return HttpRequest.put(Api.dealWinAfterPolicyCreateApi, payload);
};

export const addContactProduct = async (payload) => {
  return HttpRequest.post(`${Api.contactProductApi}`,payload);
}

export const getContactProduct = async (payload) => {
  return HttpRequest.get(`${Api.contactProductApi}`,payload);
}

