import React from 'react';
import { FormControlLabel, Grid, InputAdornment} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Search } from '@material-ui/icons';

const AddCampaignFormSkeleton = () => {
    return (
        <Grid className='space20pxLeftRight customGridWrapper' item xs={12}>
      <Grid container>
        <Grid item xs={12} className='campaign-list-to-assign-contact'>
          <div style={{ marginBottom: '20px' }}>
            <form action=''>
              <Skeleton variant="rect" width="100%" height={45} />
            </form>
          </div>
          <div className='campaigns-single-item-main3 upload-single-item-main2 upload-single-item-main'>
            <ul className='awesome__scroll_bar campaign-add-radio-list'>
              {[...Array(7)].map((_, index) => (
               <li>
                 <div style={{display:'flex' , gap:'10px'}}>
                    <Skeleton variant="circle" width={40} height={40} />
                    <div>
                    <Skeleton variant="text" width={400} height={24} />
                    <Skeleton variant="text" width={300} height={24} />
                    </div>
                </div>
               </li>
              ))}
            </ul>
          </div>
        </Grid>
      </Grid>
    </Grid>
    );
};

export default AddCampaignFormSkeleton;
