import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import LeadStatus from "./LeadStatus/LeadStatus";
import Address from "./address/Address";
import Email from "./email/Email";
import ImportantDates from "./importantDates/ImportantDates";
import PhoneNumbers from "./phoneNumbers/PhoneNumber";
import Tags from "./tags/Tags";
import { getFromLocalStorage, saveToLocalStorage } from "./helper/utils";
import { LeftSideV2Styles } from "./LeftSideV2Styles";
import {connect} from "react-redux";
import ImportantLinks from "./importantLinks/ImportantLinks";
import RelatedContacts from "./relatedContacts/RelatedContacts";
import Utils from "../../../helpers/Utils";
import {AGENCY_BUSINESS_TYPE} from "../../../constants/CoreConstants";

const getItemStyle = (isDragging, draggableStyle) => ({
  "& .lsv2DraggableItem": {
    borderRadius: 4,
    userSelect: "none",
    boxSizing: "border-box",
    background: isDragging ? "#fbfbfb" : "transparent",
    border: isDragging ? "1px solid rgba(0, 0, 0, 0.12)" : "",
    padding: isDragging ? 10 : "",
  },

  // styles we need to apply on draggable
  ...draggableStyle,
});

const DraggableSection = ({ contact }) => {
  const initialState = [
    { id: 1, component: <Address /> },
    { id: 2, component: <Email /> },
    { id: 3, component: <PhoneNumbers /> },
    { id: 4, component: <Tags /> },
    { id: 5, component: <AdditionalInfo /> },
    { id: 6, component: <ImportantDates /> },
    { id: 7, component: <ImportantLinks /> },
    { id: 8, component: <LeadStatus /> },
    { id: 9, component: <RelatedContacts /> }
  ];


  const [items, setItems] = useState(initialState);

  const { customScrollBar, customScrollBarThin } = LeftSideV2Styles();

  const localOrder = getFromLocalStorage("contactLeftSideDragOrder");

  useEffect(() => {

    const defaultState = initialState.map((iState)=>(iState.id));

    if (localOrder && defaultState.length === localOrder.length) {
      const rearrangedState = localOrder.map((id) => initialState.find((item) => item.id === id));
      setItems(rearrangedState);
    }else if (localOrder && defaultState.length > localOrder.length) {
      const tempLocalOrder = [...localOrder];

      for (let i = 0; i < defaultState.length; i++) {
        let element = defaultState[i];
        if (!tempLocalOrder.includes(element)) {
          tempLocalOrder.push(element);
        }
      }
      const rearrangedState = tempLocalOrder.map((id) => initialState.find((item) => item.id === id));
      setItems(rearrangedState);
      saveToLocalStorage("contactLeftSideDragOrder", tempLocalOrder);
    }else {
      saveToLocalStorage("contactLeftSideDragOrder", defaultState);
    }
  }, [contact]);

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newBox = Array.from(items);
    const [draggedItem] = newBox.splice(result.source.index, 1);
    newBox.splice(result.destination.index, 0, draggedItem);
    setItems(newBox);

    const reorderedArray = newBox.map((item) => item.id);
    saveToLocalStorage("contactLeftSideDragOrder", reorderedArray);
  }

  // Place this code in <Box /> to add scroll
  // className={`${customScrollBar} ${customScrollBarThin}`} sx={{ maxHeight: "calc(100vh - 430px)", paddingRight: 5, paddingBottom: 3 }}
  return (
    <Box >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='boxes'>
          {(provided) => (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 25 }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map(({ id, component }, index) => (
                <Draggable key={id} draggableId={id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      sx={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div className='lsv2DraggableItem'>{component}</div>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};
export default connect(mapStateToProps)(DraggableSection);