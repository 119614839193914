import React, {useEffect, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  Typography,
  makeStyles,
  IconButton
} from "@material-ui/core";
import { BasicInput, LeftSideV2Styles } from "../LeftSideV2Styles";
import CustomModal from "../common/CustomModal/CustomModal";
import {updateContactDetailsApi, updateImportantLinks} from "../../../../api/contactApi";
import { connect } from "react-redux";
import { setContactDetails } from "../../../../actions/contactAction";
import Utils from "../../../../helpers/Utils";
import LinkIcon from "@material-ui/icons/Link";
import PrimaryRoundedButton from "../common/button/PrimaryRoundedButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles=makeStyles({
  dialogContentWidth:{
    width:'760px'
  }
})



const EditImportantLinksModal = ({ open, onClose, contact, setContactDetails }) => {
  const [importantUrls, setImportantUrls] = useState({
    url: contact.url,
  });
  const [cachedImportantUrls, setCachedImportantUrls] = useState({
    url: contact.url,
  });
  const [additionalLinks, setAdditionalLinks] = useState([]);
  const [additionalLinksErrors, setAdditionalLinksErrors] = useState([]);
  const [cachedAdditionalLinks, setCachedAdditionalLinks] = useState([]);
  const [errors, setErrors] = useState({
    url: "",
  });
  const [submitUpdating, setSubmitUpdating] = useState(false);
  const { flexCenter, alignJustifyCenter, primaryButton, closeButton, flexColumn, titleIconHoverV2 } = LeftSideV2Styles();


  useEffect(() => {
    if (contact.contact_additional_informations && contact.contact_additional_informations.important_links){
      let importantDates = JSON.parse(contact.contact_additional_informations.important_links);
      setAdditionalLinks(importantDates);
      setCachedAdditionalLinks(importantDates);
    }
  }, [contact.contact_additional_informations]);

  const addInputField = () => {
    const tempAdditionalLinks = [...additionalLinks];
    let newObj = {
      type: "CUSTOM",
      title: "",
      value: ""
    };
    tempAdditionalLinks.push(newObj);
    setAdditionalLinks(tempAdditionalLinks);
  };

  const removeInputField = (index) => {
    const tempAdditionalLinks = [...additionalLinks];
    tempAdditionalLinks.splice(index, 1);
    setAdditionalLinks(tempAdditionalLinks);
  };

  const handleChangeInputField = (key, value, index) => {
    const tempAdditionalLinks = [...additionalLinks];
    tempAdditionalLinks[index][key] = value;
    setAdditionalLinks(tempAdditionalLinks);

    if (additionalLinksErrors[index] && additionalLinksErrors[index][key]){
      const tempAdditionalLinksErrors = [...additionalLinksErrors];
      tempAdditionalLinksErrors[index][key] = "";
      setAdditionalLinksErrors(tempAdditionalLinksErrors);
    }
  };

  const handleLinks = (name, value) => {
    setImportantUrls({
      ...importantUrls,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validateFields = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate url
    if (importantUrls.url && !Utils.validURL(importantUrls.url)) {
      newErrors.url = "Invalid url";
      isValid = false;
    } else {
      newErrors.url = "";
    }

    setErrors(newErrors);

    let additionalErrors = [];

    additionalLinks.forEach((additionalLink)=>{
      let err = {
        title: "",
        value: ""
      };
      if (additionalLink.title !== null && additionalLink.title !== undefined && additionalLink.title.trim() === ""){
        err.title = "Link title is required";
        isValid = false;
      }

      if (additionalLink.value !== null && additionalLink.value !== undefined && additionalLink.value.trim() === ""){
        err.value = "Link url is required";
        isValid = false;
      }

      if (!Utils.validURL(additionalLink.value)){
        err.value = "Invalid link url";
        isValid = false;
      }

      additionalErrors.push(err);
    });
    setAdditionalLinksErrors(additionalErrors);
    return isValid;
  };

  const handleSubmit = async () => {
    if (submitUpdating || !validateFields()) return;

    setSubmitUpdating(true);

    let data = [];

    if ((cachedImportantUrls.url !== importantUrls.url) || (cachedImportantUrls.url && cachedImportantUrls.url === '')){
      data.push({
        type: 'DEFAULT',
        title: 'url',
        value: importantUrls.url
      });
    }

    if (additionalLinks[0]){
      data = [...data, ...additionalLinks.map((additionalLink)=>({ ...additionalLink, type: "CUSTOM" }))];
    }

    if (!data[0] && !cachedAdditionalLinks[0]){
      window.showNotification("SUCCESS", "Nothing to update");
      onClose();
      setSubmitUpdating(false);
      return;
    }

    // Prepare the contact to be sent to the API
    const formData = {
      contactId: contact?.id,
      data,
    };

    try {
      const res = await updateImportantLinks(formData);

      if (res.success) {
        window.showNotification("SUCCESS", "Important dates updated successfully");
        let oldValue = { ...contact };

        if ((cachedImportantUrls.url !== importantUrls.url) || (cachedImportantUrls.url && cachedImportantUrls.url === '')){
          setCachedImportantUrls((prevState)=>({ ...prevState, url: importantUrls.url }));
          oldValue.url = importantUrls.url;
        }

        if (!oldValue.contact_additional_informations){
          oldValue.contact_additional_informations = {};
        }

        if (oldValue.contact_additional_informations){
          oldValue.contact_additional_informations.important_links = JSON.stringify(additionalLinks);
        }

        setContactDetails(oldValue);

        onClose();
      }
    } catch (error) {
      console.error("API Error:", error);
      if (window.showNotification !== undefined) window.showNotification("ERROR", "Something went wrong!");
    } finally {
      setSubmitUpdating(false);
    }
  };
const classes= useStyles()
  return (
    <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby='alert-dialog-title'
    aria-describedby='alert-dialog-description'
    maxWidth={1200}
  >
    <CustomModal icon={<LinkIcon />} title={"Important Links"} open={open} onClose={onClose}/>
    <DialogContent className={classes.dialogContentWidth}>
      <Box minHeight={170}>
        <DialogContentText id='alert-dialog-description'>
          <Box className={flexCenter} sx={{ justifyContent: "space-between", mt: 2 }}>
            <Typography variant='body1' color='textPrimary'>Add important links that you wish to associate with the contact below:</Typography>
            <PrimaryRoundedButton startIcon={<AddIcon />} onClick={addInputField}>
              Add New
            </PrimaryRoundedButton>
          </Box>
          <Box className={flexCenter} sx={{ gap: 10, flexWrap: "wrap", marginTop: "6px" }}>
            <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
              <Typography variant='body1' color='textPrimary'>
                Link URL
              </Typography>
              <BasicInput
                fullWidth
                type='text'
                value={importantUrls?.url}
                name='url'
                placeholder='Enter url'
                onChange={(e) => {
                  handleLinks(e.target.name, e.target.value);
                }}
              />
              {errors.url && (
                <Typography variant='body2' color='error'>
                  {errors.url}
                </Typography>
              )}
            </Box>
          </Box>
          {
            additionalLinks.map((additionalLink, index) => (
                <Box className={flexCenter} sx={{ gap: 10, flexWrap: "wrap", marginTop: "10px", position: "relative" }} key={index}>
                  <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
                    <Typography variant='body1' color='textPrimary'>
                      Link Title
                    </Typography>
                    <BasicInput
                        fullWidth
                        type='text'
                        value={additionalLink.title}
                        placeholder={'Add a title for your link'}
                        onChange={(event)=>{ handleChangeInputField('title', event.target.value, index) }}
                    />
                    {
                      (additionalLinksErrors[index] && additionalLinksErrors[index].title) &&
                        <span style={{ fontSize: "11px", color: "#dc4a4a" }}>{additionalLinksErrors[index].title}</span>
                    }
                  </Box>

                  <Box className={flexColumn} sx={{ gap: 5, flex: 1 }}>
                    <Typography variant='body1' color='textPrimary'>
                      Link URL
                    </Typography>
                    <BasicInput
                        fullWidth
                        type='text'
                        value={additionalLink.value}
                        placeholder={'Enter the link url here'}
                        onChange={(event)=>{ handleChangeInputField('value', event.target.value, index) }}
                    />
                    {
                        (additionalLinksErrors[index] && additionalLinksErrors[index].value) &&
                        <span style={{ fontSize: "11px", color: "#dc4a4a" }}>{additionalLinksErrors[index].value}</span>
                    }
                  </Box>
                  <IconButton
                      size='small'
                      className={titleIconHoverV2}
                      onClick={() => {
                        removeInputField(index);
                      }}
                  >
                    <DeleteForeverIcon color='error' />
                  </IconButton>
                </Box>
            ))
          }
        </DialogContentText>

        <Box mt={7}>
          <Divider light />

          <Box className={alignJustifyCenter} sx={{ gap: "10px" }} mt={2} pb={1}>
            <Button
              variant='contained'
              color='primary'
              disableElevation
              className={primaryButton}
              onClick={handleSubmit}
              disabled={submitUpdating}
            >
              Save
            </Button>
            <Button
              className={closeButton}
              variant='contained'
              color='secondary'
              disableElevation
              onClick={() => onClose(importantUrls)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
      </DialogContent>
      </Dialog>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditImportantLinksModal);
