import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography
} from "@material-ui/core";
import BasicSelect from "../../common/BasicSelect";
import SwitchC from "../../common/subComponents/Switch";
import { CustomScrollbar } from "../CustomScrollbar";
import {useDispatch, useSelector} from "react-redux";
import {selectImportSpreadsheetContactState} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import {
  loadPipelineDataStart,
  loadStageDataStart,
  loadCampaignDataStart,
  updateDetails,
  updateAccordion,
  loadSpreadSheetFileDetails
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import AddIcon from "@material-ui/icons/Add";
import Utils from "../../../helpers/Utils";
import importSpreadsheetContactService
  from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
import {extractFileId} from "./Configuration";
import ListOrTagCreateModal from "./ListOrTagCreateModal";
import {IMPORT_FROM_CAMPAIGN_REGEX} from "../../../constants/CoreConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  headerTitle: {
    fontWeight: 600,
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  scrollContainer: {
    maxHeight: "calc(100vh - 299px)",
    overflow: "auto",
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  section: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  sectionHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "&.pipelineHeader_xOsa": {
      marginBottom: ({ isPipelineExpand }) => isPipelineExpand ? theme.spacing(1) : "0",
    },
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: 600,
    lineHeight: "18px",
    "&.pipelineTitle_xOsa": {
      marginBottom: ({ isPipelineExpand }) => isPipelineExpand ? theme.spacing(1) : "0",
    },
  },
  fieldLabel: {
    fontSize: 14,
    marginBottom: theme.spacing(0.25),
  },
  radioGroup: {
    flexDirection: "row",
    marginBottom: theme.spacing(1),
  },
  weekdaysGroup: {
    flexDirection: "row",
    marginBottom: theme.spacing(1),
    "& .MuiFormControlLabel-root": {
      marginRight: theme.spacing(2),
    },
  },
  confirmationSection: {
    paddingInline: theme.spacing(1.5),
  },
  addNewButton: {
    color: "#346FEF",
    '&.MuiButton-textSizeSmall': {
      padding: '0px 5px !important',
    },
    "&:focus,&:hover": {
      background: "transparent",
    },
  },
}));

export const reformatMapping = (input) => {
  const output = {};

  for (const [key, value] of Object.entries(input)) {
    if (!value.startsWith("custom_")){
      if (!output[value] && value !== "email" && value !== "number" && value !== "number_type" && value !== "number_dnc") {
        output[value] = isNaN(parseInt(key)) ? key : parseInt(key);
      } else if (Array.isArray(output[value])) {
        output[value].push(parseInt(key));
      } else {
        output[value] = [parseInt(key)];
      }
    }
  }

  return output;
};

export const reformatCustomMapping = (input) => {
  const output = {};

  for (const [key, value] of Object.entries(input)) {
    const numericKey = parseInt(key);
    if (value.startsWith("custom_")) {
      output[value.split("_")[1]] = numericKey;
    }
  }

  return output;
};

const Details = ({  moveToPreviousStep,moveToNextStep }) => {
  const dispatch = useDispatch();
  const {
    leadSources,
    tags,
    pipelines,
    stages,
    campaigns,
    mappedFields,
    accordion: {
      isPipelineExpand,
      isCampaignExpand
    },
    details: {
      pipelineId,
      stageId,
      tagIds,
      leadSourceId,
      campaignId,
      userConsent,
      ignoreFirstRow,
      workSheetInfo,
      email,
      spreadsheetLink,
      spreadSheetName,
    },
    importFrom
  } = useSelector(selectImportSpreadsheetContactState);
  const classes = useStyles({ isPipelineExpand });
  const [uploading,setUploading] = useState(false);
  const [openTagCreateModal, setOpenTagCreateModal] = useState(false);

  const handlePipelineExpand = (status) => {
    if (status){
      dispatch(loadPipelineDataStart({ userId: Utils.getAccountData("userId") }));
    }

    dispatch(updateAccordion({ isPipelineExpand: status }));
  };

  const handleCampaignExpand = (status) => {
    if (status){
      dispatch(loadCampaignDataStart({ userId: Utils.getAccountData("userId") }));
    }
    dispatch(updateAccordion({ isCampaignExpand: status }));
  };

  const handleUpdateFormData = (event) => {
    let name = event.target.name;
    let value = event.target.value;

    if (name === "tagIds"){
      value = value.filter(Boolean);
    }

    if (name === "pipelineId"){
      dispatch(loadStageDataStart({ pipelineId: value }));
    }

    if (name === "userConsent"){
      value = !!event.target.checked;
    }

    dispatch(updateDetails({ [name]: value }));
  };


  const handleSubmit = async () => {
    try {
      if (!userConsent) {
        window.showNotification("error", "User consent is required.");
        return;
      }

      if (isPipelineExpand) {
        if (!stageId) {
          window.showNotification("error", "Stage is required for pipeline expansion.");
          return;
        }
      }

      let payload = {
        email: email,
        providerFileId: extractFileId(spreadsheetLink),
        providerFileName: spreadSheetName,
        providerFileUrl: spreadsheetLink,
        worksheetInfo: workSheetInfo,
        mapping: reformatMapping(mappedFields),
        customMapping: reformatCustomMapping(mappedFields),
      };

      let additionalInfos = { user_consent: userConsent, ignore_first_row: ignoreFirstRow };

      if (isPipelineExpand && pipelineId && stageId){
        additionalInfos.pipline_id = pipelineId;
        additionalInfos.stage_id = stageId;
      }

      if (campaignId && (isCampaignExpand || importFrom.match(IMPORT_FROM_CAMPAIGN_REGEX))){
        payload.campaignId = campaignId;
      }

      if (leadSourceId){
        additionalInfos.source_id = leadSourceId;
      }

      if (tagIds && tagIds[0] !== ""){
        additionalInfos.tag_ids = tagIds;
      }

      payload.additionalInfos = additionalInfos;

      setUploading(true);
      let resp = await importSpreadsheetContactService.userConnectedFiles(payload);

      if (resp && resp.success){
        window.showNotification("success", "Spreadsheet file uploaded successfully");
        dispatch(loadSpreadSheetFileDetails(resp.data));
        moveToNextStep();
      }else {
        window.showNotification("error", "Spreadsheet file failed to upload");
      }
    } catch (err) {
      console.error("Error during form submission:", err);
    }finally {
      setUploading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography  style={{ height: "unset !important" }} variant="h5" color="textPrimary" className={classes.headerTitle}>
          Details
        </Typography>

        <div className={classes.buttonGroup}>
          <Button variant="outlined" disableElevation onClick={moveToPreviousStep}>
            Back
          </Button>
          <Button variant="contained" color="primary" disabled={uploading} disableElevation onClick={handleSubmit}>
            {!uploading ? "Upload" : "Uploading..."}
          </Button>
        </div>
      </div>

      <CustomScrollbar className={classes.scrollContainer}>
        <div className={classes.contentWrapper}>
          {/* Contacts Management Section */}
          <div className={classes.section}>
            <Typography  style={{ height: "unset !important" }} variant="h6" className={classes.sectionTitle}>
              Contacts Management
            </Typography>

            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography  style={{ height: "unset !important" }} variant="body1" className={classes.fieldLabel}>
                  Lead Source
                </Typography>
                <BasicSelect
                  fullWidth
                  name="leadSourceId"
                  defaultText="Select a Lead Source"
                  options={leadSources}
                  mapping={{ label: "title", value: "id" }}
                  value={leadSourceId}
                  onChange={handleUpdateFormData}
                />
              </Grid>
              <Grid item xs={12} md={6}>
               <div style={{display:'flex',justifyContent:'space-between'}}>
               <Typography  style={{ height: "unset !important" }} variant="body1" className={classes.fieldLabel}>
                  Tags
                </Typography>
                <Button
                size="small"
                startIcon={<AddIcon />}
                style={{ }}
                className={classes.addNewButton}
                 onClick={() => setOpenTagCreateModal(true)}
              >
                Add New
              </Button>
              {
                openTagCreateModal && (
                  <ListOrTagCreateModal
                  open={openTagCreateModal}
                  onClose={()=> setOpenTagCreateModal(false)}
                  heading={"Add New Tag"}
                  labelText={"Tag Title"}
                  isForTag={true}
                  />
                )
              }
               </div>
                <BasicSelect
                  fullWidth
                  multiple={true}
                  name="tagIds"
                  defaultText="Select Tags"
                  options={tags}
                  mapping={{ label: "title", value: "id" }}
                  value={tagIds}
                  onChange={handleUpdateFormData}
                />
              </Grid>
            </Grid>
          </div>

          {/* Deals Pipeline Section */}
          <div className={classes.section}>
            <div className={`${classes.sectionHeader} pipelineHeader_xOsa`}>
              <Typography variant="h6" className={`${classes.sectionTitle} pipelineTitle_xOsa`}>
                Add contacts to a deals pipelines?
              </Typography>
              <SwitchC offText="" onText="" status={isPipelineExpand} onChange={handlePipelineExpand} brandColor="#006DF5" />
            </div>

            {
              isPipelineExpand &&
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Pipeline
                    </Typography>
                    <BasicSelect
                        fullWidth
                        name="pipelineId"
                        defaultText="Select a Pipeline"
                        options={pipelines}
                        mapping={{ label: "title", value: "id" }}
                        value={pipelineId}
                        onChange={handleUpdateFormData}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant="body1" className={classes.fieldLabel}>
                      Stage
                    </Typography>
                    <BasicSelect
                        fullWidth
                        name="stageId"
                        defaultText="Select a Stage"
                        options={stages}
                        mapping={{ label: "title", value: "id" }}
                        value={stageId}
                        onChange={handleUpdateFormData}
                    />
                  </Grid>
                </Grid>
            }
          </div>

          {/* Campaign Section */}

          {
              !importFrom.match(IMPORT_FROM_CAMPAIGN_REGEX) &&
              <div className={classes.section}>
                <div className={`${classes.sectionHeader} campaignHeader_xMkd`}>
                  <Typography variant="h6" className={`${classes.sectionTitle} campaignTitle_xMkd`}>
                    Add contacts to a campaign?
                  </Typography>
                  <SwitchC offText="" onText="" status={isCampaignExpand} onChange={handleCampaignExpand}
                           brandColor="#006DF5"/>
                </div>

                {
                    isCampaignExpand &&
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.fieldLabel}>
                          Campaign
                        </Typography>
                        <BasicSelect
                            fullWidth
                            name="campaignId"
                            defaultText="Select a Campaign"
                            options={campaigns}
                            mapping={{label: "title", value: "id"}}
                            value={campaignId}
                            onChange={handleUpdateFormData}
                        />
                      </Grid>
                    </Grid>
                }
              </div>
          }

          {/* Confirmation Section */}
          <div className={classes.confirmationSection}>
            <FormControlLabel
                value="userConsent"
                name="userConsent"
                checked={userConsent}
                onChange={handleUpdateFormData}
                control={<Checkbox size="small" color="primary"/>}
                label="I Confirm that all contacts in this import have consented to receive messages from us. I've had communications with them within the last year, and this list is not from a third party"
            />
          </div>
        </div>
      </CustomScrollbar>
    </div>
  );
};

export default Details;
