import React, { useState } from "react";
import { useForm } from "react-hook-form";
import TextField from "@material-ui/core/TextField";
import { cleanContactsActionsApi } from "../../../../api/cleanDataApi";
import ModalWithHeader from "../../enhanceData/commons/ModalWithHeader";
import { isError, showErrorMessage } from "../../../../helpers/FormErrors";

const ModalExportContact = ({
  exportAll,
  openExportFileModal,
  onClose,
  selectedFileContactIds = [],
  filter = {},
    tab
}) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    fileName: "",
  });

  const [saving, setSaving] = useState(false);

  const submitHandler = (value) => {
    const payload = {
      filterType: exportAll ? "ALL" : "SEGMENT",
      action: "EXPORT_CONTACT",
      cleanContactIds: selectedFileContactIds,
      listIds: [],
      campaignIds: [],
      tagIds: [],
      pipelineStageIds: [],
      fileName: value.fileName,
      fileType: "CSV", // CSV, XLS, XLSX,
      pageSource: tab
    };

    if (filter.validNumbers || filter.invalidNumbers) {
      let numberStatus = [];

      if (filter.validNumbers){
        numberStatus.push("VALID");
      }

      if (filter.invalidNumbers){
        numberStatus.push("INVALID");
      }

      payload.numberStatus = numberStatus;
      payload.filterType = "SEGMENT";
    }

    if (filter.validEmails || filter.invalidEmails) {
      let emailStatus = [];

      if (filter.validEmails){
        emailStatus.push("VALID");
      }

      if (filter.invalidEmails){
        emailStatus.push("INVALID");
      }

      payload.emailStatus = emailStatus;
      payload.filterType = "SEGMENT";
    }

    if (filter.dncOn || filter.dncOff) {
      let dncStatus = [];

      if (filter.dncOn){
        dncStatus.push("DO_NOT_CALL");
      }

      if (filter.dncOff){
        dncStatus.push("VERIFIED_OK");
      }

      payload.dncStatus = dncStatus;
      payload.filterType = "SEGMENT";
    }

    if (filter.skipTrace){
      payload.skipTraceStatus = ["TRACED"];
      payload.filterType = "SEGMENT";
    }

    if (filter.landline || filter.mobile){
      let numberType = [];

      if (filter.landline){
        numberType.push("landline");
      }

      if (filter.mobile){
        numberType.push("mobile");
      }

      payload.numberType = numberType;
      payload.filterType = "SEGMENT";
    }

    setSaving(true);
    cleanContactsActionsApi(payload)
      .then((res) => {
        if (res && res.success) {
          setSaving(false);
          onClose();
          window.globalDownloadPopupData(true);
          // window.showNotification("success", "Request accepted. Please check file status on exported files list page.");
        } else {
          window.showNotification("error", "Something went wrong try again later");
        }
      })
      .finally(() => {
        reset({
          fileName: "",
        });
      });
  };

  return (
    <ModalWithHeader
      header={"Export File"}
      open={openExportFileModal}
      title='Export File'
      onSubmit={handleSubmit(submitHandler)}
      onClose={onClose}
      buttonText={!saving ? "Export" : "Exporting..."}
      saving={saving}
    >
      <TextField
        variant={"outlined"}
        {...register("fileName", {
          required: {
            value: true,
            message: "File name is required",
          },
        })}
        type='text'
        placeholder='File Name'
        error={isError(errors, "fileName")}
        helperText={showErrorMessage(errors, "fileName")}
      />
    </ModalWithHeader>
  );
};

export default ModalExportContact;
