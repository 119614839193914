import React, {
    useEffect,
    useState
} from "react";
import {
    Checkbox,
    Dialog,
    Divider,
    FormControlLabel,
    Typography,
} from "@material-ui/core";
import {
    BasicButton,
    BasicInput,
    BasicTrButton,
    ErrorMessage,
    ModalBody,
    ModalBodyWrapper,
    ModalContactChip,
    ModalFoot,
    ModalFormGroup,
    ModalFormGroupSectionHead,
    ModalFormGroupWrapper,
    ModalFormInput,
    ModalFormLabel,
    ModalHead,
    ModalTitle, useStyles
} from "./ProductStyles";
import { addContactProduct } from "../../../../api/dealApi";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";
import { AttachMoney } from "@material-ui/icons";
import BasicSelect from "../../../common/BasicSelect";
import AddIcon from "@material-ui/icons/Add";
import AddProductModal from "./AddProductModal";
import {useDispatch, useSelector} from "react-redux";
import {getUserProductActiveListSuccess} from "../../../../actions/contactAction";

const AddContactProductModal = ({ contact, open, onClose, appendNewContactProduct }) => {
    const {userProductActiveList} = useSelector((state)=>(state.contactReducer));
    const dispatch = useDispatch();
    const classes = useStyles();
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        unit_cost: "",
        unit_price: "",
        billing_frequency: "",
        close_date: ""
    });
    const [formDataError, setFormDataError] = useState({
        title: "",
    });
    const [submitting, setSubmitting] = useState(false);
    const [createDealAfterAddProduct, setCreateDealAfterAddProduct] = useState(true);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProductDetails, setSelectedProductDetails] = useState(null);
    const [createModal, setCreateModal] = useState(false);
    const [productOptions,setProductOptions] = useState();

    useEffect(()=>{
        const options = userProductActiveList.map((user) => ({ value: user.id, label: user.title }));
        setProductOptions(options);
    },[userProductActiveList]);

    const handleAddProduct = (newProduct) => {
        const newOption = { value: newProduct.id, label: newProduct.title };
         setProductOptions((prevState) => ([ newOption, ...prevState ]));
         let oldList = [newProduct, ...userProductActiveList];
         dispatch(getUserProductActiveListSuccess(oldList));
         handleFormData(newProduct.id, oldList);
    }

    const handleOnClose = () => {
        onClose();
        resetFormData();
    };

    const resetFormData = () => {
        setFormData({
            title: "",
            description: "",
            unit_cost: "",
            unit_price: "",
            billing_frequency: "",
            close_date: ""
        });
        setFormDataError({
            title: "",
        });
    };

    const validateFormData = () => {
        let inValid = false;
        const errors = {
            title: "",
        }
      
        if (formData.title.trim() === "") {
            inValid = true;

            errors.title = "Please select a product";
        }


        if (inValid) {
            setFormDataError(errors);
        }

        return inValid;
    };
    

    const handleSubmitFormData = async () => {
        try {
            if (validateFormData()) {
                return;
            }

            setSubmitting(true);

            if (createDealAfterAddProduct) {
                window.globalAddDeal(true, {
                    callback: async (data) => {
                        if (data && data?.contact_stage_id) {
                            let contactStage = data.contact_stage;
                            let contactStageProducts = data.contact_stage_products;

                            contactStageProducts.forEach((contactStageProduct) => {
                                appendNewContactProduct({
                                    userProductTitle: contactStageProduct.title,
                                    userProductUnitCost: parseFloat(contactStageProduct.unit_cost),
                                    userProductUnitPrice: parseFloat(contactStageProduct.unit_price),
                                    dealTitle: contactStage.title,
                                    dealProductCreatedBy: contactStage.created_by,
                                    dealClosedById: contactStage.created_by,
                                    dealProductValue: parseFloat(contactStageProduct.unit_price),
                                    dealCreatedDate: contactStage.created_at,
                                    dealClosedDate: contactStage.created_at,
                                    pipelineTitle: data?.pipelineTitle,
                                    stageTitle: data?.stageTitle,
                                    pipelineId: data.pipelineId,
                                    stageId: data.stageId
                                });
                            });
                            // setCreateDealAfterAddProduct(false);
                        }
                    },
                    data: {
                        contact_id: contact.id,
                        user_contact_stage_products: [
                            {
                                user_product_id: selectedProductDetails.id,
                                value: selectedProductDetails.unit_price,
                            },
                        ],
                    },
                    edit: false,
                    mark_win: true,
                    user_product_id: selectedProductDetails.id,
                    value: selectedProductDetails.unit_price,
                });
            }
            else {

                const response = await addContactProduct({
                    contact_id:contact.id,
                    user_product_id: selectedProductDetails.id,
                    price: selectedProductDetails.unit_price,
                })
                if(response && response.success){
                    appendNewContactProduct({
                        userProductTitle: selectedProductDetails.title,
                        userProductUnitCost: parseFloat(selectedProductDetails.unit_cost),
                        userProductUnitPrice: parseFloat(selectedProductDetails.unit_price),
                        dealTitle: selectedProductDetails.title,
                        dealProductCreatedBy: response.data.created_by,
                        dealClosedById: response.data.created_by,
                        dealProductValue: parseFloat(selectedProductDetails.unit_price),
                        dealCreatedDate: null,
                        dealClosedDate: null,
                        pipelineTitle: null,
                        stageTitle: null,
                    });
                }

            }

            handleOnClose();
        } catch (err) {
            console.log(err)
        } finally {
            setSubmitting(false);
        }
    };

    const handleCreateProductModal = () => {
        setCreateModal(true);
    };


    const handleCreateModalClose = () => {
        setCreateModal(false);
    };

    const handleSelectProduct = (event) => {
        if(formDataError){
            setFormDataError({
                title: "",
            })
        }
        setSelectedProduct(event.target.value);
        handleFormData(event.target.value);
    }

    const contactLabel = () => {
        if (contact) {
            let label = "";

            if (contact.first_name) {
                label += contact.first_name;
            }

            if (contact.last_name) {
                if (label) {
                    label += " ";
                }
                label += contact.last_name;
            }

            if (!label) {
                label = contact.email;
            }

            if (!label) {
                label = contact.number;
            }

            return label;
        }

        return null;
    }

    const handleFormData = (id, list = userProductActiveList) => {
        const selected = list.find((item)=>(item.id === id));

        if (selected) {
            setSelectedProductDetails(selected);
            let oldFormData = { ...formData };
            oldFormData.title = selected.title || "";
            oldFormData.description = selected.description || "";
            oldFormData.unit_cost = selected.unit_cost || 0;
            oldFormData.unit_price = selected.unit_price || 0;
            oldFormData.billing_frequency = selected.billing_frequency || "";
            setFormData(oldFormData);
            setSelectedProduct(id);
        }
    };

    return (
        <Dialog open={open} onClose={handleOnClose}>
            <ModalBodyWrapper>
                <ModalHead>
                    <ModalTitle>
                        Add a New Product Or Service
                    </ModalTitle>
                    <Divider style={{ width: "165px", backgroundColor: "#316aff" }} />
                    <ModalContactChip>
                        <BootstrapTooltip placement={"top"} arrow title={contactLabel()}>
                            <span>{contactLabel()}</span>
                        </BootstrapTooltip>
                    </ModalContactChip>
                </ModalHead>

                <ModalBody>
                    <ModalFormGroup>
                        <ModalFormLabel>
                            Name of Product / Service
                        </ModalFormLabel>
                        <div style={{ display: 'flex', gap: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
                            <ModalFormInput style={{ width: '75%' }}>
                                <BasicSelect
                                    value={selectedProduct}
                                    defaultText="Select Product"
                                    options={productOptions}
                                    onChange={handleSelectProduct}
                                />
                                {
                                    formDataError.title &&
                                    <ErrorMessage>{formDataError.title}</ErrorMessage>
                                }
                            </ModalFormInput>
                            <BasicButton
                                className={classes.addNewButton}
                                startIcon={<AddIcon />}
                                onClick={handleCreateProductModal}
                            >
                                Add New
                            </BasicButton>
                        </div>
                    </ModalFormGroup>
                    {
                        createModal && (
                            <AddProductModal
                                contact={contact}
                                appendNewContactProduct={appendNewContactProduct}
                                open={createModal}
                                onClose={handleCreateModalClose}
                                handleAddProducts = {handleAddProduct}
                            />
                        )
                    }

                    <ModalFormGroupSectionHead>
                        <ModalFormLabel style={{ color: "#ced4da" }}>
                            Product / Service Information
                        </ModalFormLabel>
                        <Divider style={{
                            width: "330px",
                            backgroundColor: "#316aff",
                        }}
                        />
                    </ModalFormGroupSectionHead>

                    <ModalFormGroup style={{ marginTop: "16px" }}>
                        <ModalFormLabel style={{ color: '#ced4da' }}>
                            Description
                        </ModalFormLabel>
                        <ModalFormInput>
                            <BasicInput
                                style={{ color: '#ced4da !important' }}
                                fullWidth
                                multiline
                                name={"description"}
                                value={formData.description}
                                required
                                disabled
                            />
                        </ModalFormInput>
                    </ModalFormGroup>

                    <ModalFormGroupWrapper>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Unit Cost
                            </ModalFormLabel>
                            <ModalFormInput style={{ position: 'relative' }}>
                                <BasicInput
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999, className: "pricePadding" }}
                                    name={"unit_cost"}
                                    value={formData.unit_cost}
                                    disabled
                                />
                                <AttachMoney fontSize="small" style={{ position: 'absolute', left: '6px', top: '8px', color: '#ced4da' }} />
                            </ModalFormInput>
                        </ModalFormGroup>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Unit Price
                            </ModalFormLabel>
                            <ModalFormInput style={{ position: 'relative' }}>
                                <BasicInput
                                    type="text"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999, className: "pricePadding", }}
                                    name={"unit_price"}
                                    value={formData.unit_price}
                                    disabled
                                />
                                <AttachMoney fontSize="small" style={{ position: 'absolute', left: '6px', top: '8px', color: '#ced4da' }} />
                            </ModalFormInput>
                        </ModalFormGroup>
                    </ModalFormGroupWrapper>

                    <ModalFormGroupWrapper>
                        <ModalFormGroup style={{ flex: 1 }}>
                            <ModalFormLabel style={{ color: '#ced4da' }}>
                                Billing Frequency
                            </ModalFormLabel>
                            <ModalFormInput>
                                <BasicInput
                                    type="number"
                                    fullWidth
                                    variant="outlined"
                                    inputProps={{ min: 0, max: 999999 }}
                                    name={"billing_frequency"}
                                    value={formData.billing_frequency}
                                    // onChange={handleChangeFormData}
                                    disabled
                                />
                            </ModalFormInput>
                        </ModalFormGroup>
                    </ModalFormGroupWrapper>
                    <ModalFormGroup style={{ marginTop: '24px' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                color="black"
                                    size={'small'}
                                    checked={createDealAfterAddProduct}
                                    // onChange={() => { setCreateDealAfterAddProduct((prev)=>!prev) }}
                                />
                            }
                            style={{ color: '#0e1b3d', fontSize: '16px !important', fontWeight: '600' }}
                            label='Include this manual entry in sales reporting and goal calculations'
                        />
                        <Typography style={{ fontSize: '12px', paddingLeft: '28px', color: '#0e1b3d', fontWeight: '400' }}>
                        For accurate reporting you must associate this entry with a deal
                        </Typography>
                    </ModalFormGroup>
                </ModalBody>

                <ModalFoot>
                    <BasicTrButton style={{ marginRight: "10px" }} onClick={handleOnClose}>
                        Cancel
                    </BasicTrButton>
                    <BasicButton disabled={submitting} onClick={handleSubmitFormData}>
                        { createDealAfterAddProduct ? 'Save & Continue' : (submitting ? 'Saving...' : 'Save') }
                    </BasicButton>
                </ModalFoot>
            </ModalBodyWrapper>
        </Dialog>
    );
};

export default AddContactProductModal;