import React, { useState, useEffect } from "react";
import { getImportedInvalidContactsLogs } from "../../api/contactApi";
import { Table, TableHead, TableBody, TableRow, TableCell, TablePagination, TableContainer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import ReportLogSkeleton from "./ReportLogSkeleton";
import EmptyReportLog from "./EmptyReportLog";

const HeaderRight = () => {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div className="icon_import_report" style={{ width: "70px", height: "70px" }}>
          <img
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/1.0.0/computer_error_icon.png`}
            alt="import"
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h6 style={{ fontWeight: 600, color: "#163159" }}>Import Errors</h6>
          <p style={{ color: "#163159" }}>The following contacts were not imported successfully.</p>
        </div>
      </div>
    </div>
  );
};

const HeaderBody = (props) => {
  return (
    <div className='main_content_topbar'>
      <div className='main_content_topbar_content d-flex justify-content-between align-items-center'>
        <HeaderRight />
        <div>
          <a
            href='javascript:void(0)'
            onClick={props.onClick}
            class='main_content_container_button white-text d-flex justify-content-center align-items-center import_file_list_back_button'
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              class='main_content_container_button_icon'
            >
              <path
                d='M0 10C0 4.4774 4.4774 0 10 0C15.5226 0 20 4.4774 20 10C20 15.5226 15.5226 20 10 20C4.4774 20 0 15.5226 0 10ZM10.2236 14.3923C10.2953 14.3212 10.3523 14.2366 10.3913 14.1434C10.4304 14.0502 10.4507 13.9502 10.4511 13.8492C10.4515 13.7482 10.432 13.6481 10.3937 13.5546C10.3554 13.4611 10.299 13.376 10.2279 13.3043L7.7125 10.7692H14.1346C14.3386 10.7692 14.5343 10.6882 14.6785 10.5439C14.8228 10.3997 14.9038 10.204 14.9038 10C14.9038 9.79599 14.8228 9.60033 14.6785 9.45607C14.5343 9.31181 14.3386 9.23077 14.1346 9.23077H7.7125L10.2279 6.69567C10.299 6.62389 10.3554 6.53879 10.3936 6.44524C10.4319 6.35169 10.4514 6.25152 10.4509 6.15045C10.4505 6.04937 10.4301 5.94938 10.391 5.85617C10.3519 5.76296 10.2949 5.67837 10.2231 5.60721C10.1513 5.53606 10.0662 5.47974 9.97264 5.44147C9.8791 5.40321 9.77892 5.38374 9.67785 5.38419C9.57678 5.38463 9.47678 5.40498 9.38357 5.44407C9.29037 5.48317 9.20577 5.54023 9.13462 5.61202L5.31779 9.45817C5.17481 9.60226 5.09458 9.79702 5.09458 10C5.09458 10.203 5.17481 10.3977 5.31779 10.5418L9.13462 14.388C9.20578 14.4599 9.29042 14.517 9.3837 14.5561C9.47697 14.5952 9.57704 14.6156 9.67819 14.616C9.77934 14.6164 9.87957 14.5968 9.97315 14.5585C10.0667 14.5201 10.1518 14.4636 10.2236 14.3923Z'
                fill='white'
              />
            </svg>
            <span>Back</span>
          </a>
        </div>
      </div>
    </div>
  );
};

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

const ExpandibleTableRow = (props) => {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const rowVal = (v) => {
    return v ? v : "";
  };

  return (
    <>
      <TableRow className={`report__table_log ${classes.root}`}>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component='th' scope='row'>
          {row.invalid_cause}
        </TableCell>
        <TableCell>
          {(row.first_name === null ? "" : row.first_name) + " " + (row.last_name === null ? "" : row.last_name)}
        </TableCell>
        <TableCell>{row.email}</TableCell>
        <TableCell>{row.number}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box margin={1}>
              <Typography style={{ height: "unset !important" }} variant='h6' gutterBottom component='div' className='fullDetails__title'>
                Full Details
              </Typography>
              <div className='report__log_table_content'>
                <p>
                  <span>{`First Name :`}</span> {`${rowVal(row.first_name)}`}
                </p>
                <p>
                  <span>{`Last Name : `}</span> {`${rowVal(row.last_name)}`}
                </p>
                <p>
                  <span>{`Address : `}</span> {`${rowVal(row.address)}`}
                </p>
                <p>
                  <span>{`Company Name : `}</span> {`${rowVal(row.company_name)}`}
                </p>
                <p>
                  <span>{`City : `}</span> {`${rowVal(row.city)}`}
                </p>
                <p>
                  <span>{`State : `}</span> {`${rowVal(row.state)}`}
                </p>
                <p>
                  <span>{`Zip : `}</span> {`${rowVal(row.zip)}`}
                </p>
                <p>
                  <span>{`Country : `}</span> {`${rowVal(row.country)}`}
                </p>
                <p>
                  <span>{`Deal Value : `}</span> {`${rowVal(row.deal_value)}`}
                </p>
                <p>
                  <span>{`Birth Date : `}</span> {`${rowVal(row.birth_date)}`}
                </p>
                <p>
                  <span>{`Anniversary Date : `}</span> {`${rowVal(row.anniversary_date)}`}
                </p>
                <p>
                  <span>{`Url : `}</span> {`${rowVal(row.url)}`}
                </p>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 650,
    overflowX: "hidden",
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "6px",
      backgroundColor: "#2c3e50",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#346fef",
      borderRadius: "6px",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "end",
    marginRight: "40px",
    "& .MuiTablePagination-actions": {
      display: "flex",
    },
  },
  reasonErrorCell: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#e3e1e1",
    color: "#133159",
  },
  fullNameCell: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#e3e1e1",
    color: "#133159",
  },
  emailCell: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#e3e1e1",
    color: "#133159",
  },
  numberCell: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#e3e1e1",
    color: "#133159",
  },
  dropDowncell: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#e3e1e1",
  },
});
const ReportLog = (props) => {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  const [trigger, setTrigger] = useState(0);

  useEffect(() => {
    loadContact();
  }, [trigger]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTrigger(trigger + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
    setTrigger(trigger + 1);
  };
  const loadContact = async () => {
    setLoading(true);
    const payload = {
      fileListId: props.fileListId,
      page: page + 1,
      limit: rowsPerPage,
    };
    try {
      const res = await getImportedInvalidContactsLogs(payload);
      if (res.success && res.data && res.data[0]) {
        setRows(res.data);
        if (page === 0) {
          setTotal(res.total_count);
        }
      } else {
        setRows([]);
        setTotal(0);
      }
    } catch (error) {
      console.error("Failed to load contacts:", error);
    } finally {
      setLoading(false);
    }
  };

  const prevStepList = (e) => {
    e.preventDefault();
    props.prevStepList();
  };

  const renderTableHead = () => (
    <TableRow style={{ background: "red" }}>
      <TableCell className={classes.dropDowncell}></TableCell>
      <TableCell className={classes.reasonErrorCell}>Reason for Error</TableCell>
      <TableCell className={classes.fullNameCell}>Full Name</TableCell>
      <TableCell className={classes.emailCell}>Email</TableCell>
      <TableCell className={classes.numberCell}>Number</TableCell>
    </TableRow>
  );

  const renderTableBody = () => {
    return rows.map((row, index) => {
      return <ExpandibleTableRow row={row} key={index} />;
    });
  };

  return (
    <>
      <div className='import_content_container main_content_container_import_list'>
        <div className='main_content_inner_container white'>
          <HeaderBody onClick={prevStepList} />

          <div className={classes.root}>
            {loading ? (
              <ReportLogSkeleton />
            ) : (rows && rows[0]) ? (
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table collapsible table'>
                  <TableHead>{renderTableHead()}</TableHead>
                  <TableBody>{renderTableBody()}</TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyReportLog />
            )}

            <TablePagination
              className={classes.pagination}
              component='div'
              count={total}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[10, 25, 50, 100]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportLog;
