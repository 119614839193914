import { Grid, makeStyles, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import React, { useState } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import {
  InsuraceCardBody,
  InsuraceCardFooter,
  InsuraceFlexBox,
  InsuraceStatusForActive,
  InsuraceStatusForInActive,
  InsuraceTitle,
  InsuranceCard,
  InsuranceCardWrapper,
  InsuranceHeader,
  InsuranceHeaderFlexBox,
  InsuranceIconContainer,
} from "./PolicyStyle";
import EditPolicyPopOver from "./EditPolicyPopOver";
import { STATUS } from "../../../dataClean/Constants";
import BootstrapTooltip from "../../../globals/BootstrapTooltip";

const useStyle = makeStyles({
  textStyle: {
    maxHeight: "2.5em", 
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
    fontWeight: 600,
  },
});

const EachInsurance = ({ item, dataObject, fetchContactPolicyData, setOpenPolicyModal, setPolicyId }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpenPopOver = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  const formatUrl = (url) => {
    if((url.startsWith('http://')) || (url.startsWith('https://')))
    {
      return url;
    } else {
      return `https://${url}`;
    }
  };
  

  const openEditPolicyPopOver = Boolean(anchorEl);
  const id = openEditPolicyPopOver ? "simple-popover" : undefined;
  const classes=useStyle()
  return (
    <InsuranceCard>
      <InsuranceCardWrapper>
        <InsuranceHeader>
          <InsuraceFlexBox>
            <InsuraceTitle>{dataObject?.policyType[item?.policy_id]}</InsuraceTitle>
            <InsuranceHeaderFlexBox>
              {item.status === STATUS.ACTIVE ? (
                <InsuraceStatusForActive>ACTIVE</InsuraceStatusForActive>
              ) : (
                <InsuraceStatusForInActive>INACTIVE</InsuraceStatusForInActive>
              )}
              <InsuranceIconContainer aria-describedby={id} onClick={handleOpenPopOver}>
                <MoreVertIcon />
              </InsuranceIconContainer>
            </InsuranceHeaderFlexBox>
          </InsuraceFlexBox>
          <InsuraceFlexBox>
            <Typography variant='body1' style={{ fontWeight: 300 }}>
              Policy # &nbsp;: &nbsp;{item.policy_number || ""}
            </Typography>
            <Typography variant='body1'>Full Premium&nbsp;:&nbsp; ${item.price}</Typography>
          </InsuraceFlexBox>
        </InsuranceHeader>
        <InsuraceCardBody>
          <Grid container spacing={1}>
            <Grid item md={6} lg={6} xl={3}>
              <Typography style={{ color: "#929da9", height: "24px" }}>EFFECTIVE DATE</Typography>
              <Typography style={{ fontWeight: 600 }}>{item.effective_date}</Typography>
            </Grid>
            <Grid item md={6} lg={6} xl={3}>
              <Typography style={{ color: "#929da9", height: "24px" }}>EXPIRATION DATE</Typography>
              <Typography style={{ fontWeight: 600 }}>{item.expiration_date}</Typography>
            </Grid>
            <Grid item md={6} lg={6} xl={3}>
              <Typography style={{ color: "#929da9", height: "24px" }}>INSURANCE COMPANY</Typography>
              <BootstrapTooltip title={dataObject?.company[item?.insurance_company_id]} arrow placement='top'>
                <Typography className={classes.textStyle}>{dataObject?.company[item?.insurance_company_id]}</Typography>
              </BootstrapTooltip>
            </Grid>
            {item.policy_link && (
              <Grid md={6} lg={6} xl={3}>
                <a href={formatUrl(item.policy_link)} target='_blank' rel='noopener noreferrer'>
                  <LinkIcon style={{ rotate: "135deg", fontSize: "35px" }} />
                </a>
              </Grid>
            )}
          </Grid>
        </InsuraceCardBody>
        <InsuraceCardFooter>
          <InsuraceFlexBox>
            <Typography style={{ fontWeight: 600, color: "#929da9" }}>AGENT</Typography>
            <Typography style={{ fontWeight: 600 }}>{dataObject?.agent[item?.agent_id]}</Typography>
          </InsuraceFlexBox>
        </InsuraceCardFooter>
      </InsuranceCardWrapper>

      {openEditPolicyPopOver && (
        <EditPolicyPopOver
          open={openEditPolicyPopOver}
          id={id}
          setPolicyId={setPolicyId}
          setOpenPolicyModal={setOpenPolicyModal}
          fetchContactPolicyData={fetchContactPolicyData}
          item={item}
          handleClose={handleClosePopOver}
          anchorEl={anchorEl}
        />
      )}
    </InsuranceCard>
  );
};

export default EachInsurance;
