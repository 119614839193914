import React from 'react';
import Upload from './Upload';
import Wrapper from './Wrapper';
import UserAndTag from './UserAndTag';
import Success from './Success';
import ProcessLoader from './ProcessLoader';
import FileList from './FileList';
import Upgrade from './Upgrade';
import ContactImportRule from "./ContactImportRule";
import DealCreate from "./DealCreate";
import ProcessMode from "./ProcessMode";
import AddSource from './AddSource';
import { getContactLimit } from '../../api/contactApi';
import './import-contact.css';
import {FILE_IMPORT_STEP} from '../../constants/CoreConstants';
import Utils from '../../helpers/Utils';
import ContactValidation from "./ContactValidation";
import Validation from "./Validation";

class ImportContact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: FILE_IMPORT_STEP.STEP_UPLOAD,
            contactLimit : null,
            currentContact : null,
            existing_contact_rule : 1,
            isLoading:false,
            data: [],
            file:null,
            total:0,
            do_create_deal:0,
            pipeline_id:0,
            stage_id:0,
            pipelines: [],
            stages: [],
            sources:[],
            source_id : null,
            maxUpload : null,
            submitData : {},
            oldTags: [],
            additional_infos: null
        };

        this.nextStep = this.nextStep.bind(this);
        this.nextStepList = this.nextStepList.bind(this);
        this.prevStepList = this.prevStepList.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    addToSessionStorage = (text="main") => {
      sessionStorage.setItem("contacts-import", text);
    }
    getSessionData = () => {
      return sessionStorage.getItem("contacts-import");
    }

    setInitialState = () => {
        this.state.sources.forEach(source => {
            //2 Means bulk
            if(source.const_value == 2) {
                this.setState({
                    source_id : source.id
                });
            }
            
        })
        return {
            existing_contact_rule : 1,
            data: [],
            file:null,
            do_create_deal:0,
            pipeline_id:0,
            stage_id:0,
        };
    };

    refreshCSVData = () => {
        this.setState(this.setInitialState());
    }

    nextStep = () => {
        const { step } = this.state;
        let nextStep = step + 1;
        if(!Utils.getAccountData('carrierLookup') && step === FILE_IMPORT_STEP.STEP_IMPORT_RULE){
            nextStep++;
        }
        if (step == FILE_IMPORT_STEP.STEP_DEAL_CREATE) {
            nextStep = FILE_IMPORT_STEP.STEP_ADD_SOURCE;
        } else if (step == FILE_IMPORT_STEP.STEP_ADD_SOURCE) {
            nextStep = FILE_IMPORT_STEP.STEP_ADD_TAG;
        }
        this.setState({
            step : nextStep
        })
        this.addToSessionStorage()
    };

    upgradeStep = () => {
        this.setState({
            step : FILE_IMPORT_STEP.STEP_UPGRADE
        })
    };

    prevStep = () => {
        const { step } = this.state;
        let prevStep = step - 1;
        if(!Utils.getAccountData('carrierLookup') && step === FILE_IMPORT_STEP.STEP_DEAL_CREATE){
            prevStep--;
        }
        if (step == FILE_IMPORT_STEP.STEP_ADD_TAG) {
            prevStep = FILE_IMPORT_STEP.STEP_ADD_SOURCE;
        } else if (step == FILE_IMPORT_STEP.STEP_ADD_SOURCE) {
            prevStep = FILE_IMPORT_STEP.STEP_DEAL_CREATE;
        }
        this.setState({
            step : prevStep
        })
    }

    nextStepList = () => {
        this.setState({
            step : FILE_IMPORT_STEP.STEP_FILE_LIST
        })
        this.addToSessionStorage("list")
    };

    prevStepList = () => {
        this.setState({
            step : FILE_IMPORT_STEP.STEP_UPLOAD
        },()=>this.loadData());
        this.addToSessionStorage()
    };

    removeStateKey = (newState) => {
        Object.keys(newState).forEach(k => {
            if( k != "step" ){
                delete newState[k];
            }
        });
    };

    handleChange (key , data) {
        this.setState({ [key] : data })
    }
    componentDidMount (){

        document.title = `Contacts Import | ${Utils.getAccountData('AuthUserAgencyName')}`;
        if (window.setActiveSidebar) {
            window.setActiveSidebar('contact');
        }
        try{
          const viewScreen = this.getSessionData()
          if(viewScreen !== undefined && viewScreen !== null && viewScreen === 'list'){
            this.setState({
              step : FILE_IMPORT_STEP.STEP_FILE_LIST
            })
          }
          else{
            this.setState({
              step : FILE_IMPORT_STEP.STEP_UPLOAD
            })
          }
        }
        catch(error){}
        this.loadData();
    };

    loadData = () => {
        this.setState({
            isLoading: true
        });
        getContactLimit().then(res => {
            let data = res.data.data;
            if(data.contactLimit){
                this.setState({
                    contactLimit : data.contactLimit,
                    currentContact: data.currentContact,
                    pipelines: data.pipelines,
                    stages: data.stages,
                    sources : data.sources,
                    maxUpload : data.maxUpload,
                    isLoading:false,
                });

                data.sources.forEach(source => {
                    //2 Means bulk
                    if(source.const_value == 2) {
                        this.setState({
                            source_id : source.id
                        });
                    }
                    
                })
            }

        });
    };

    setStepShowList = () => {
        const { step } = this.state;
        this.setState({
            step : FILE_IMPORT_STEP.STEP_FILE_LIST
        },()=>this.loadData());
    }

    render() {
        const { step } = this.state;
        switch(step) {
            case FILE_IMPORT_STEP.STEP_UPLOAD:
                return <Upload
                    contactLimit={this.state.contactLimit}
                    currentContact={this.state.currentContact}
                    upgradeStep={this.upgradeStep}
                    nextStep={this.nextStep}
                    nextStepList={this.nextStepList}
                    handleChange = {this.handleChange}

                />
            case FILE_IMPORT_STEP.STEP_WRAPPER:
                return <Wrapper
                    contactLimit={this.state.contactLimit}
                    currentContact={this.state.currentContact}
                    upgradeStep={this.upgradeStep}
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                    maxFileSize={this.state.maxUpload}
                />
            case FILE_IMPORT_STEP.STEP_IMPORT_RULE:
                return <ContactImportRule
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_VALIDATION:
                return <Validation
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_DEAL_CREATE:
                return <DealCreate
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_PROCESS_MODE:
                return <ProcessMode
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_ADD_SOURCE:
                return <AddSource
                    prevStep={this.prevStep}
                    state = { this.state }
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_ADD_TAG:
                return <UserAndTag
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                />
            case FILE_IMPORT_STEP.STEP_PROCESS_LOADER:
                return <ProcessLoader
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                    userId = { this.props.userId}
                    nodeServer = { this.props.nodeServer}
                    refreshCSVData = { this.refreshCSVData}
                    setStepShowList={this.setStepShowList}
                />
            case FILE_IMPORT_STEP.STEP_SUCCESS:
                return <Success
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                />
            case FILE_IMPORT_STEP.STEP_FILE_LIST:
                return <FileList
                    nextStepList={this.nextStepList}
                    prevStepList={this.prevStepList}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_UPGRADE:
                return <Upgrade
                    handleChange = {this.handleChange}
                />
        }
    }
}

export default ImportContact;
