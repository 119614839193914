import React, { useEffect, useMemo, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { getContactTagList, getUserTagList } from "../../../../actions/contactAction";
import ModalAssignToTagNew from "../../contactList/ModalAssignToTagNew";
import TagsList from "./TagsList";

import CustomTitle from "../common/customTitle/CustomTitle";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const Tags = (props) => {
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    getTagList();
  }, [props.contact?.id]);

  const handleOpen = () => {
    setOpenModal((prevState) => !prevState);
  };

  const getTagList = () => {
    props.getContactTagList({
      contactId: props.contact.id,
    });
  };

  const successCallBack = (update = false, newTag = false) => {
    setOpenModal(false);
    getTagList();

    // if (newTag) {
    //   props.fetchUserTagList({});
    // }
  };
  const icons = useStyles();

  const alreadySelectedTags = useMemo(() => {
    return props.contactTags ? props.contactTags.map((tag) => tag.tag.id) : [];
  }, [props.contactTags]);

  return (
    <Box className={icons.hoverDiv}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle handleOpen={handleOpen} title={"TAGS"} iconHide={icons.icon} />
      </Box>

      <TagsList />
      {openModal &&
        <ModalAssignToTagNew
          open={openModal}
          contact_id={[props.contact.id]}
          title={"Add Tag"}
          callBack={successCallBack}
          onClose={handleOpen}
          // contactTags={props.contactTags}
          ignoreTags={alreadySelectedTags}
        />
      }
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
    contactTags: state.tagReducer.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactTagList: (params) => dispatch(getContactTagList(params)),
    fetchUserTagList: (params, callback) => dispatch(getUserTagList(params, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Tags);
