import React from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";
import { CoreButton } from "../commons/CoreButton";

const StyledTable = styled(Table)`
  & td,
  & th {
    padding: 8px !important;
  }
`;

const SkipTracingResult = ({
  result = [],
  onClickCampaignButton,
  onClickDialerButton,
}) => {
  const newData = result.filter((value) => value.request_data === undefined);
  return (
    <>
      <Box p={2}>
        <Typography variant="h6">Results:</Typography>
        <Paper variant={"outlined"}>
          <TableContainer style={{ maxHeight: "400px" }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Mobile/Landline</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Owner Occupied?</TableCell>
                  <TableCell>DNC</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {newData.map((skip, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {(
                        (skip.firstName || "") +
                        " " +
                        (skip.lastName || "")
                      ).trim() || "-"}
                    </TableCell>
                    <TableCell>{skip.number}</TableCell>
                    <TableCell>{skip.emailState}</TableCell>
                    <TableCell>{skip.email}</TableCell>
                    <TableCell>{skip.ownerOccupied}</TableCell>
                    <TableCell>{skip.dnc}</TableCell>
                    <TableCell>
                      <Box style={{ display: "flex", gap: "8px" }}>
                        <CoreButton
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() =>
                            onClickCampaignButton(skip.cleanContactId)
                          }
                        >
                          Add to Campaign
                        </CoreButton>
                        <CoreButton
                          variant="contained"
                          size="small"
                          color="secondary"
                          backgroundColor="#c51162"
                          onClick={() =>
                            onClickDialerButton(skip.cleanContactId)
                          }
                        >
                          Add to Power dialer
                        </CoreButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {newData.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={7} align={"center"}>
                      No Data Found!
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </StyledTable>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default SkipTracingResult;
