import React, {useEffect, useState} from "react";
import { Backdrop, Fade, Modal, Step, StepButton, Stepper } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import modalUseStyles from "../contact/leftSideV2/common/modal/modalUseStyles";
import Details from "./Details";
import { importContactsV2Style } from "./importContactsV2Style";
import Mapping from "./Mapping";
import Summary from "./Summary";
import Upload from "./Upload";
import { IMPORT_CONTACT_STEP_DETAILS, IMPORT_CONTACT_STEP_MAPPING, IMPORT_CONTACT_STEP_SUMMARY, IMPORT_CONTACT_STEP_UPLOAD, IMPORT_CONTACT_V2_STEPS } from "../../constants/CoreConstants";
import { useDispatch, useSelector } from "react-redux";
import { selectImportContactState } from "../../reducers/importContact/importContactSelector";
import {loadInitialDataStart, updateActiveStepAndCompletedStep} from "../../reducers/importContact/importContactSlice";
import HowItWorksModal from "./HowItWorksModal";

const ImportCsvModal = ({ open, onClose }) => {
  const {
    activeTab,
    completedSteps,
    details: {
      leadOwnerId
    }
  } = useSelector(selectImportContactState)
  const dispatch = useDispatch();
  const classes = importContactsV2Style();
  const modalClasses = modalUseStyles();
  const [openHowItsWorksModal, setOpenHowItsWorksModal] = useState(false);

  useEffect(() => {
    dispatch(loadInitialDataStart({ userId: leadOwnerId }));
  }, []);

  const moveToNextStep = (currentStep, nextStep) => {
    if (currentStep === IMPORT_CONTACT_STEP_DETAILS) {
      dispatch(updateActiveStepAndCompletedStep({ activeTab: nextStep, completedSteps: [...completedSteps, currentStep, nextStep] }))

    } else {
      dispatch(updateActiveStepAndCompletedStep({ activeTab: nextStep, completedSteps: [...completedSteps, currentStep] }))
    }
  }

  const moveToPreviousStep = (previousStep) => {

    const tmpCompleted = (prev) => {
      let arr = [...prev];
      let i = arr.indexOf(previousStep)
      if (i >= 0) delete (arr.splice(i, 1));
      return arr
    };
    dispatch(updateActiveStepAndCompletedStep({ activeTab: previousStep, completedSteps: tmpCompleted(completedSteps) }))
  }

  const renderContent = () => {
    switch (activeTab) {
      case IMPORT_CONTACT_STEP_UPLOAD:

        return <Upload
          moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_UPLOAD, IMPORT_CONTACT_STEP_MAPPING)}
        />;

      case IMPORT_CONTACT_STEP_MAPPING:

        return <Mapping
          moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_MAPPING, IMPORT_CONTACT_STEP_DETAILS)}
          moveToPreviousStep={() => moveToPreviousStep(IMPORT_CONTACT_STEP_UPLOAD)}
        />;

      case IMPORT_CONTACT_STEP_DETAILS:

        return <Details
          moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_DETAILS, IMPORT_CONTACT_STEP_SUMMARY)}
          moveToPreviousStep={() => moveToPreviousStep(IMPORT_CONTACT_STEP_MAPPING)}
        />;

      case IMPORT_CONTACT_STEP_SUMMARY:

        return <Summary
          onCloseModal={onClose}
        />;

      default:
        return <Upload
          moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_UPLOAD, IMPORT_CONTACT_STEP_MAPPING)}
        />;
    }
  };

  return (
    <Modal
      aria-labelledby='transition-modal-title'
      aria-describedby='transition-modal-description'
      className={modalClasses.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={modalClasses.paper}>
          <div
            className={classes.csvModalWrapper}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#949db21f",
              padding: "16px",
              borderRadius: "8px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16 }}>
              <div style={{ display: "flex", alignItems: "center", gap: 10, cursor: "pointer" }} onClick={()=>{onClose(true)}}>
                <ArrowBackIcon />
                <h6 style={{ fontWeight: 600, margin: 0 }}>Import Contacts</h6>
              </div>

              <div className={classes.helpBox} onClick={() => setOpenHowItsWorksModal(true)}>
                <p>How it works?</p>
              </div>
            </div>

            <div style={{ backgroundColor: "#fff", borderRadius: 4, height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 16,
                }}
              >
                <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                  <h4 style={{ fontSize: 18, margin: 0, fontWeight:600 }}>Import From CSV File</h4>
                  <p style={{ fontSize: 14, fontWeight: 300, margin: 0 }}>Add contacts to your CRM by uploading a csv file.</p>
                </div>
              </div>

              <div
                style={{
                  // padding: "0px 200px 40px",
                  margin: 8,
                  padding: 16,
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: 8,
                  height: "calc(100vh - 195px)",
                }}
              >
                <div style={{ display: "flex", alignItems: "flex-start", padding: "0px 100px 40px", gap: 8 }}>
                  <Stepper className={classes.stepperActive} orientation='vertical' style={{ width: 250 }}>
                    {IMPORT_CONTACT_V2_STEPS.map((step) => (
                      <Step key={step.label} completed={completedSteps.includes(step.value)} active={activeTab === step.value}>
                        <StepButton sx={{ "&:focus": { background: "transparent" } }}>{step.label}</StepButton>
                      </Step>
                    ))}
                  </Stepper>

                  <div style={{ width: "100%" }}>{renderContent()}</div>
                </div>
              </div>
            </div>
          </div>
          {openHowItsWorksModal && (
              <HowItWorksModal open={openHowItsWorksModal} onClose={() => setOpenHowItsWorksModal(false)} />
          )}
        </div>
      </Fade>
    </Modal>
  );
};

export default ImportCsvModal;
