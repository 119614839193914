import React, { useEffect, useReducer } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    menuPaper: {
        maxHeight: 396,
    },

  }));
const DropdownWithScroll = (props) => {

    const reducerFunction = (state,data)=>{

        switch(data.type){

            case 'updateDataList':
                return [...state,...data.payload]
                break;
            default:
                return data.payload;
        }
    }
    const classes = useStyles();
    const [datalist, dispatchDataList] = useReducer(reducerFunction, []);
    const [scrolling, dispatchScrolling] = useReducer(reducerFunction, false);
    const [perPage, dispatchPerPage] = useReducer(reducerFunction, 20);
    const [pageNo, dispatchPageNo] = useReducer(reducerFunction, 1);
    const [lastPage, dispatchLastPage] = useReducer(reducerFunction, -1)

    useEffect(() => {
        getMoreData(1)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getMoreData= (getPage)=>{
        if(props.fetchApi !== undefined ){
            let params = {}
            if(props.fetchApi.initialParams){
                if(props.dropdownHeader === 'tagChange'){
                    params['payload'] = props.fetchApi.initialParams
                }else{
                    params = props.fetchApi.initialParams;
                }
            }
            params[props.fetchApi.perPage] = perPage
            params[props.fetchApi.pageNo] = getPage
            if(props.fetchApi.columns && Array.isArray(props.fetchApi.columns)){
                params['columns'] = props.fetchApi.columns
            }
            props.fetchApi.action(params)
            .then((response)=>{
                if(lastPage === -1){
                    if(props.dropdownHeader === 'tagChange'){
                        dispatchLastPage({'type':'changeLastPage', 'payload': response.data.last_page})

                    }else{
                        dispatchLastPage({'type':'changeLastPage', 'payload': response.data.data.last_page})

                    }
                }
                if(props.dropdownHeader === 'tagChange'){
                    dispatchDataList({'type':'updateDataList','payload': response.data.data})

                }else{
                    dispatchDataList({'type':'updateDataList','payload': response.data.data.data})

                }
                dispatchPageNo({'type':'changeCurrentPage', 'payload': getPage})

                dispatchScrolling({'type':'updateScrolling', 'payload': false})

                // if(scrolling){
                //     dispatchScrolling({'type':'updateScrolling', 'payload': false})

                // }

                if(props.updateStore){
                    props.updateMultipleData(response.data.data)
                }

            });
        }
    }

    const updateSelectedData = async (event) => {
        await props.updateSelectedData(event.target.value);
        if(props.updateDatatable !== undefined && props.updateDatatable != null && props.dropdownHeader!== undefined && props.dropdownHeader != null){
            props.updateDatatable(props.dropdownHeader ,event.target.value);

        }
    }

    const scrollCallback = (e)=>{
        
        if(!scrolling && pageNo < lastPage &&
            Math.round(e.target.scrollHeight, 10) - Math.round(e.target.scrollTop + e.target.clientHeight, 20) < 300 ){
                dispatchScrolling({type:'updateScrolling', payload: true})

            getMoreData(pageNo+1)

        }

    }

    return (
        <React.Fragment>
            <div className={(props.parentDivClassName !== undefined && props.parentDivClassName != null) ?"droupdown__helper "+props.parentDivClassName : "droupdown__helper"}>
                <FormControl className="second__step__area__select second__step__area__select__100 assign__stage_select_wrapper contact-list-dropdowns">
                    <Select
                        // className="contact__middle__inbox__select__innner"
                        value={props.selectedValue} 
                        onChange={updateSelectedData}
                        className={(props.className !== undefined && props.className != null && props.className !== "") ? `${props.className} ` : ''}
                        displayEmpty
                        inputProps={{ 'aria-label': (props.ariaLabel !== undefined && props.ariaLabel != null) ? props.ariaLabel : 'Without label' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                            },
                            transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                            },
                            getContentAnchorEl: null,
                            classes: { paper: classes.menuPaper },
                            PaperProps: {
                                onScroll: (e)=> scrollCallback(e)
                              }
                        }}
                    >
                        {props.noneFieldRequired !== undefined && props.noneFieldRequired != null && props.noneFieldRequired &&
                            <MenuItem value="" index="" disabled
                            className={'dropdownhelper-menuitem-class'}>
                                <em>{props.noneFieldTitle ? props.noneFieldTitle : 'None'}</em>
                            </MenuItem>
                        }

                        {Array.isArray(datalist) && props.mapping!=null && props.dropdownHeader!== "stageChange" && props.dropdownHeader!== "tagChange" &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data.id} key={`dropdownHelper${index}${data.id}`}
                                    className={`dropdownhelper-menuitem-class ${props.menuItemClassName ? props.menuItemClassName : ''}`}
                                >{data[props.mapping.title]}</MenuItem>
                            ))
                        }

                        {Array.isArray(datalist) && props.mapping!=null && props.dropdownHeader=== "stageChange" &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data.id} key={`dropdownHelper${index}${data.id}`} 
                                    className={`dropdownhelper-menuitem-class ${props.menuItemClassName ? props.menuItemClassName : ''}`}
                                > 
                                    {data[props.mapping.title]}
                                    &nbsp;- &nbsp;
                                     <small><code>{props.pipelineListInObject && props.pipelineListInObject[data[props.mapping.pipeline_id]] && 
                                    props.pipelineListInObject[data[props.mapping.pipeline_id]]}</code></small>

                                    
                                </MenuItem>
                            ))
                        }

                        {Array.isArray(datalist) && props.mapping != null && props.dropdownHeader === "tagChange" &&
                        datalist.map((data, index) => {
                            // const user = props.menuSubTitleData.find(user => user.id === data.user_id);
                            return (
                                <MenuItem
                                    value={data.id} key={`dropdownHelper${index}${data.id}`}
                                    className={`dropdownhelper-menuitem-class ${props.menuItemClassName ? props.menuItemClassName : ''}`}
                                >
                                    {data[props.mapping.title]}
                                    {/*&nbsp;- &nbsp;*/}
                                    {/*<small><code>{user ? user.full_name : 'Unknown User'}</code></small>*/}
                                </MenuItem>
                            );
                        })
                        }


                        {Array.isArray(datalist) && props.mapping ==null &&
                            datalist.map((data, index) => (
                                <MenuItem
                                    value={data} key={`dropdownHelper${index}`}
                                    className={`dropdownhelper-menuitem-class d-flex ${props.menuItemClassName ? props.menuItemClassName : ''}`}
                                >{data}</MenuItem>

                            ))
                        }

                    </Select>
                </FormControl>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = state => {
    return {
        pipelineListInObject: state.contactReducer.pipelineListInObject
        
    };
};


export default React.memo(connect(mapStateToProps)(DropdownWithScroll));