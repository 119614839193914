import React from "react";
import { Box, Divider, IconButton, Typography, makeStyles } from "@material-ui/core";
import DraggableIcon from "../common/icons/DraggableIcon";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SelectField from "../../leftSide/SelectField";
import { LeftSideV2Styles } from "../LeftSideV2Styles";
import EditIcon from "../common/icons/EditIcon";
const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,

        width: "23px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },

  titleStyle: {
    fontSize: 14,
    fontWeight: 600,
    color: "var(--dark_blue)",
    whiteSpace: "nowrap",
  },
});

const LeadStatus = (props) => {
  const history = useHistory();
  const icons = useStyles();
  const { flexCenterBetween, flexCenter, titleIcon } = LeftSideV2Styles();
  const { titleStyle } = useStyles();
  return (
    <Box className={icons.hoverDiv}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box className={flexCenterBetween} sx={{ flexWrap: "wrap", paddingRight: "10px" }}>
            <Typography variant='body2' className={titleStyle}>
              LEAD STATUS{" "}
            </Typography>
          </Box>

          <Box flex={1}>
            <Divider />
          </Box>
          <Box
            className={icons.icon}
            sx={{
              height: "20px !important",
              transition: "all .35s",
              display: "flex",
              justifyContent: "end",
              overflow: "hidden",
            }}
          >
            <IconButton className={`${titleIcon}`} size='small'>
              <DraggableIcon fill='currentColor' />
            </IconButton>
            {/* <IconButton className={titleIcon} size='small'>
              <EditIcon />
            </IconButton> */}
          </Box>
        </Box>
      </Box>

      <Box sx={{ width: "100%" }}>
        <SelectField
          // label={"Lead Status"}
          name={"status"}
          value={props.contact && props.contact.status}
          redirect={() => {
            history.push("/contacts");
          }}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};

export default connect(mapStateToProps)(LeadStatus);
