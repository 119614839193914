import {
    LOOKUP_TYPE_CARRIER_LOOKUP,
    LOOKUP_TYPE_DNC_LOOKUP,
    LOOKUP_TYPE_EMAIL_LOOKUP
} from "../../../../../constants/CoreConstants";

export const STATUS_CHECK = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "email_validation_status",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "number_validation_status",
    [LOOKUP_TYPE_DNC_LOOKUP]: "dnc_status"
};

export const INFO_CHECK = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "email_validation_infos",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "number_validation_infos",
    [LOOKUP_TYPE_DNC_LOOKUP]: "dnc_validation_infos"
};

export const STATE_CHECK = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "email_state",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "number_state"
};

export const LOOKUP_CATEGORY = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "verify-email",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "carrier",
    [LOOKUP_TYPE_DNC_LOOKUP]: "dnc"
};

export const CREDIT_SETTING_TYPE = {
    CALLER_NAME: 63,
    [LOOKUP_TYPE_CARRIER_LOOKUP]: 64,
    [LOOKUP_TYPE_EMAIL_LOOKUP]: 65,
    [LOOKUP_TYPE_DNC_LOOKUP]: 66
};

export const LOOKUP_STATUS = {
    INVALID: "INVALID",
    VALID: "VALID",
    NOT_VERIFIED: "NOT_VERIFIED",
    CHECK_DNC: "CHECK_DNC",
    DO_NOT_CALL: "DO_NOT_CALL",
    VERIFIED_OK: "VERIFIED_OK",
    VERIFYING: "VERIFYING"
};

export const EMAIL_STATE = {
    DELIVERABLE: "deliverable",
    UNDELIVERABLE: "undeliverable",
    RISKY: "risky",
    UNKNOWN: "unknown"
};

export const BUTTON_TEXT = {
    NOT_VERIFIED: "Validate",
    VERIFYING: "Validating...",
    CHECK_DNC: "Check Dnc",
    INVALID: "Validate",
    VALID: "Validate",
    DO_NOT_CALL: "Check Dnc",
    VERIFIED_OK: "Check Dnc"
};

export const LOOKUP_LABEL = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "Email Validation",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "Number Validation",
    [LOOKUP_TYPE_DNC_LOOKUP]: "DNC Status Check"
};

export const LOOKUP_BUTTON_TEXT = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "Validate Email",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "Validate Number",
    [LOOKUP_TYPE_DNC_LOOKUP]: "Check DNC Status"
};

export const LOOKUP_FIELD_VALUE_CHECK = {
    [LOOKUP_TYPE_EMAIL_LOOKUP]: "email",
    [LOOKUP_TYPE_CARRIER_LOOKUP]: "number",
    [LOOKUP_TYPE_DNC_LOOKUP]: "number"
};