import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { selectImportSpreadsheetContactState } from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import StatusChip from "../ImportGoogleSheet/StatusChip";
import { TableRowWrapper } from "../ImportGoogleSheet/EachTableItem";
import { LinkIcon } from "../../../constants/IconList";
import { updateImportContactOptionTab } from "../../../reducers/importContact/importContactSlice";
import { FILE_IMPORT_TAB } from "../../../constants/CoreConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: theme.spacing(2),
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: "var( --deep_dark_blue)",
    padding: "10px",
  },
  buttonGroup: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.5),
  },
  addNewButton: {
    color: "#346FEF",
    "&.MuiButton-textSizeSmall": {
      padding: "0px 5px !important",
    },
    "&:focus,&:hover": {
      background: "transparent",
    },
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 24,
  },
}));

const Summary = ({ onClose }) => {
  const dispatch = useDispatch();
  const { spreadSheetFilesDetails } = useSelector(selectImportSpreadsheetContactState);
  const classes = useStyles();
  const handleLinkOpen = (link) => {
    window.open(link, "_blank");
  };

  const worksheetName = () => {
    if (spreadSheetFilesDetails.worksheet_info) {
      const parsedData = JSON.parse(spreadSheetFilesDetails.worksheet_info);
      return parsedData.title;
    } else {
      return "";
    }
  };

  const handleViewPastClick = (tab) => {
    onClose();
    dispatch(updateImportContactOptionTab({ importContactOptionTab: tab }));
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <img
            src={`${process.env.REACT_APP_CDN_LINK}assets/images/releases/importV2/1.0.0/upload_4052950.svg`}
            alt=''
            height={40}
          />

          <Typography variant={"h5"} color='textPrimary' style={{ fontWeight: 600 }}>
            Your file has been uploaded. Here are the results!
          </Typography>
        </div>
      </div>
      <div className={classes.body}>
        <TableContainer component={"span"}>
          <Table className={classes.table} aria-label='simple table'>
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableHeader}>SHEET NAME</TableCell>
                <TableCell className={classes.tableHeader}>WORKSHEET NAME</TableCell>
                <TableCell className={classes.tableHeader}>TOTAL CONTACT UPLOADED</TableCell>
                <TableCell className={classes.tableHeader}>STATUS</TableCell>
                <TableCell className={classes.tableHeader}>PROCESSING STATUS</TableCell>
                <TableCell className={classes.tableHeader}>LAST PROCESSED DATE</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRowWrapper>
                <TableCell>
                  <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                    <div className='titleWrap'>{spreadSheetFilesDetails.provider_file_name || ""}</div>
                    <div onClick={() => handleLinkOpen(spreadSheetFilesDetails.provider_file_url)}>
                      <LinkIcon />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <div className='titleWrap'> {worksheetName()}</div>
                </TableCell>
                <TableCell>
                  <div className='titleWrap'>{spreadSheetFilesDetails.total_contact_uploaded || 0}</div>
                </TableCell>
                <TableCell>
                  <div className='titleWrap'>
                    <StatusChip status={"ACTIVE"} />
                  </div>
                </TableCell>
                <TableCell>
                  <StatusChip status={"QUEUE"} />
                </TableCell>
                <TableCell>
                  <div className='titleWrap'>{spreadSheetFilesDetails.last_processed_date}</div>
                </TableCell>
              </TableRowWrapper>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.footer}>
        <Button
          color='primary'
          variant={"outlined"}
          disableElevation
          onClick={() => handleViewPastClick(FILE_IMPORT_TAB.TAB_GOOGLE_SHEET)}
        >
          View Past Imports
        </Button>
        <Button variant='contained' color='primary' disableElevation onClick={()=>{onClose(true)}} style={{ minWidth: 120 }}>
          Exit
        </Button>
      </div>
    </div>
  );
};

export default Summary;
