import React, {useCallback, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import StepProgressBar from '../StepProgressBar';
import {
    CONTACT_VALIDATION_TYPE,
    FILE_IMPORT_STEP,
    LOOKUP_TYPE_CARRIER_LOOKUP, LOOKUP_TYPE_DNC_LOOKUP,
    LOOKUP_TYPE_EMAIL_LOOKUP, LOOKUP_TYPE_SKIP_TRACING_LOOKUP, SEARCH_CATEGORY
} from '../../../constants/CoreConstants';
import BottomButtons from './../BottomButtons';
import {updateMultipleData} from "../../../actions/contactAction";
import {getCarrierLookupCreditSettingsApi} from "../../../api/contactApi";
import "./ValidationStyle.css";
import Utils from '../../../helpers/Utils';

const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}

const validationActionConst = {
    UPLOAD_ALL: "UPLOAD_ALL",
    SKIP_INVALID: "SKIP_INVALID"
};


const App = (props) => {
    const [verifyEmail,setVerifyEmail] = useState(false);
    const [carrier,setCarrier] = useState(false);
    const [callerName,setCallerName] = useState(false);
    const [dncStatus,setDncStatus] = useState(false);
    const [skipTracingPersonSearch,setSkipTracingPersonSearch] = useState(false);
    const [skipTracingCurrentOwner,setSkipTracingCurrentOwner] = useState(false);
    const [skipTracingCurrentResident,setSkipTracingCurrentResident] = useState(false);
    const [validationAction, setValidationAction] = useState("");
    const creditSettingType = {"CALLER_NAME" : 63, "CARRIER" : 64, "VERIFY_EMAIL":65, "DNC": 66, "SKIP_TRACING_PERSON_SEARCH": 67};

    useEffect(()=>{
        setVerifyEmail(props.state?.additional_infos?.lookupType.includes(LOOKUP_TYPE_EMAIL_LOOKUP));
        setCarrier(props.state?.additional_infos?.lookupType.includes(LOOKUP_TYPE_CARRIER_LOOKUP));
        setDncStatus(props.state?.additional_infos?.lookupType.includes(LOOKUP_TYPE_DNC_LOOKUP));
        setSkipTracingPersonSearch(props.state?.additional_infos?.lookupType.includes(LOOKUP_TYPE_SKIP_TRACING_LOOKUP));
        setSkipTracingCurrentOwner(props.state?.additional_infos?.searchCategory.includes(SEARCH_CATEGORY.HOUSE_CURRENT_OWNER));
        setSkipTracingCurrentResident(props.state?.additional_infos?.searchCategory.includes(SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT));
        setValidationAction(props.state?.additional_infos?.validation_action);
    },[]);

    const saveAndContinue = useCallback((e) => {
        e.preventDefault()
        if ((carrier || callerName || verifyEmail || dncStatus || skipTracingPersonSearch) && !validationAction){
            window.showNotification("error", "Please select a validation action");
            return;
        }

        if (skipTracingPersonSearch && !skipTracingCurrentOwner && !skipTracingCurrentResident){
            window.showNotification("error", "Please select a skip trace search type");
            return;
        }

        let payload = {
            validation_action: validationAction,
            lookupType :[],
            searchCategory :[],
        };

        if (carrier){
            payload.lookupType.push(LOOKUP_TYPE_CARRIER_LOOKUP);
        }

        if (verifyEmail){
            payload.lookupType.push(LOOKUP_TYPE_EMAIL_LOOKUP);
        }

        if (dncStatus){
            payload.lookupType.push(LOOKUP_TYPE_DNC_LOOKUP);
        }

        if (skipTracingPersonSearch){
            payload.lookupType.push(LOOKUP_TYPE_SKIP_TRACING_LOOKUP);

            if (skipTracingCurrentOwner){
                payload.searchCategory.push(SEARCH_CATEGORY.HOUSE_CURRENT_OWNER)
            }

            if (skipTracingCurrentResident){
                payload.searchCategory.push(SEARCH_CATEGORY.HOUSE_CURRENT_RESIDENT)
            }
        }

        props.handleChange("additional_infos", payload);
        props.nextStep()
    },[verifyEmail, carrier, callerName, dncStatus, skipTracingPersonSearch, validationAction, skipTracingCurrentOwner, skipTracingCurrentResident]);

    const getValidationPrice = (validationType) => {
        let price;
        if(props.carrierLookupPrices){
            price = props.carrierLookupPrices.find(({ type }) => type === creditSettingType[validationType]);
            if(price){
                price = price.value;
            }
        }else if(!props.carrierLookupPrices){
            getCarrierLookupCreditSettingsApi()
                .then((response) => {
                    if (response.data && response.data.status === 'success') {
                        if (response.data && response.data.data) {
                            props.updateMultipleData({ carrierLookupPrices: response.data.data});
                            price = response.data.data;
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return price;
    }

    const prevStep = useCallback((e) => {
        e.preventDefault();
        props.prevStep();
    },[]);

    return (
        <>
            <div className="import_content_container">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight />
                        </div>
                    </div>

                    <div className="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        <StepProgressBar fromCampaign={props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_VALIDATION}/>
                    </div>

                    <div className="content_divider"><div className="content_divider_inner"></div></div>

                    <div className="main_section_title_section">
                        <h6 className="center-align color_blue">
                            Validate and Enhance Your Data
                        </h6>
                        <p className="center-align color_dark_blue">
                            (optional, but recommended)
                        </p>
                        <p className="validation__sub_message center-align color_charcoal mt-2">
                            We offer a variety of ways to validate and enhance your data. <br/>
                            It is highly recommended that you clean up your list to improve your deliverability and contact rate!
                        </p>
                    </div>

                    <div className="divider main_content_main_section_divider"></div>

                    <div className="duplicate_rule_content_section">
                        <div className="duplicate_rule_content">
                            <p className="duplicate_rule_content_title center-align validation__select_label">Select your desired options below (leave unchecked to skip):</p>
                            <form action="#" className="duplicate_rule_form d-flex justify-content-center">
                                <div className="validation__types">
                                   { Utils.getAccountData("skipTrace") && 
                                    ( <p className={`options validation__skip_tracing_check ${skipTracingPersonSearch ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" onChange={()=>{
                                                setSkipTracingPersonSearch(!skipTracingPersonSearch)
                                                setSkipTracingCurrentOwner(!skipTracingPersonSearch);
                                                if (skipTracingPersonSearch && !verifyEmail && !carrier && !dncStatus){
                                                    setValidationAction("");
                                                }
                                            }} checked={skipTracingPersonSearch} className="filled-in"/>
                                            <span>
                                                <p className="validation__type">Skiptracing</p> <p className="validation__price">(${getValidationPrice("SKIP_TRACING_PERSON_SEARCH")})</p>
                                                <p className="validation__message">
                                                    Skiptracing is the process of tracking down hard to find contact info. <br/>
                                                    Simply provide an address and we'll find info on the current owner or resident based on your query. <br/>
                                                    We'll track down all known phone numbers and email addresses associated with the owner(s) or resident(s).
                                                </p>
                                            </span>
                                        </label>
                                    </p> )
                                    }
                                    {
                                        skipTracingPersonSearch &&
                                        <div className="d-flex validation__skip_tracing">
                                            <p className={`options ${skipTracingCurrentOwner ? 'duplicate_rule_checked' : ''}`}>
                                                <label>
                                                    <input type="checkbox" onChange={()=>{
                                                        setSkipTracingCurrentOwner(!skipTracingCurrentOwner)
                                                        if (skipTracingCurrentOwner && !verifyEmail && !carrier && !dncStatus){
                                                            setValidationAction("");
                                                        }
                                                    }} checked={skipTracingCurrentOwner} className="filled-in"/>
                                                    <span>
                                                    <p className="validation__type">Find current owner</p>
                                                </span>
                                                </label>
                                            </p>
                                            <p className={`options validation__margin_left ${skipTracingCurrentResident ? 'duplicate_rule_checked' : ''}`}>
                                                <label>
                                                    <input type="checkbox" onChange={()=>{
                                                        setSkipTracingCurrentResident(!skipTracingCurrentResident)
                                                        if (skipTracingCurrentResident && !verifyEmail && !carrier && !dncStatus){
                                                            setValidationAction("");
                                                        }
                                                    }} checked={skipTracingCurrentResident} className="filled-in"/>
                                                    <span>
                                                    <p className="validation__type">Find current resident</p>
                                                </span>
                                                </label>
                                            </p>
                                        </div>
                                    }
                                    { Utils.getAccountData("dnc") &&  (
                                        <p className={`options ${dncStatus ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" onChange={()=>{
                                                setDncStatus(!dncStatus)
                                                if (dncStatus && !verifyEmail && !carrier && !skipTracingPersonSearch){
                                                    setValidationAction("");
                                                }
                                            }} checked={dncStatus} className="filled-in"/>
                                            <span>
                                                <p className="validation__type">Check Do-Not-Call (DNC) Status</p> <p className="validation__price">(${getValidationPrice("DNC")})</p>
                                                <p className="validation__message">
                                                    Reduce the risk of legal nightmares associated with DNC violations. <br/>
                                                    We'll scrub your contact data against the National DNC Registry as well as a "Litigation Firewall."
                                                </p>
                                            </span>
                                        </label>
                                    </p>)}
                                    
                                    { Utils.getAccountData("carrierLookup") &&  (
                                        <p className={`options ${carrier ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" onChange={()=>{
                                                setCarrier(!carrier)
                                                if (carrier && !verifyEmail && !dncStatus && !skipTracingPersonSearch){
                                                    setValidationAction("");
                                                }
                                            }} checked={carrier} className="filled-in"/>
                                            <span>
                                                <p className="validation__type">Validate Phone Numbers</p> <p className="validation__price">(${getValidationPrice("CARRIER")})</p>
                                                <p className="validation__message">
                                                    Phone number validation allows you to identify the owner of a phone number, <br/>
                                                    whether or not it is valid, and the type of number (landline or mobile).
                                                </p>
                                            </span>
                                        </label>
                                        </p> )}
                                    {/*<p className={`options ${callerName ? 'duplicate_rule_checked' : ''}`}>*/}
                                    {/*    <label>*/}
                                    {/*        <input type="checkbox" onChange={()=>{ setCallerName(!callerName) }} checked={callerName} className="filled-in"/>*/}
                                    {/*        <span>Caller-Name verification: ${getValidationPrice("CALLER_NAME")} per number. Check the box to proceed.</span>*/}
                                    {/*    </label>*/}
                                    {/*</p>*/}
                                   {   Utils.getAccountData("carrierLookup") &&  ( 
                                    <p className={`options ${verifyEmail ? 'duplicate_rule_checked' : ''}`}>
                                        <label>
                                            <input type="checkbox" onChange={()=>{
                                                setVerifyEmail(!verifyEmail)
                                                if (verifyEmail && !carrier && !dncStatus && !skipTracingPersonSearch){
                                                    setValidationAction("");
                                                }
                                            }} checked={verifyEmail} className="filled-in"/>
                                            <span>
                                                <p className="validation__type">Validate Email Addresses</p> <p className="validation__price">(${getValidationPrice("VERIFY_EMAIL")})</p>
                                                <p className="validation__message">
                                                    Email validation is used to determine if an email address is valid and able to receive a message. <br/>
                                                    Prevent hard bounces and identify invalid and fake email addresses.
                                                </p>
                                            </span>
                                        </label>
                                    </p>
                                )}
                                </div>
                            </form>
                        </div>

                        {
                            (verifyEmail || carrier || dncStatus || skipTracingPersonSearch) &&
                            <div className="duplicate_rule_content">
                                <p className="duplicate_rule_content_title center-align validation__select_label">What action should take after validation:</p>
                                <form action="#" className="duplicate_rule_form d-flex justify-content-center">
                                    <div className="validation__types">
                                        <p className={`options ${(validationAction === validationActionConst.SKIP_INVALID ) ? 'duplicate_rule_checked' : ''}`}>
                                            <label>
                                                <input type="checkbox" checked={(validationAction === validationActionConst.SKIP_INVALID )} onChange={()=>{ setValidationAction(validationActionConst.SKIP_INVALID) }} className="filled-in" />
                                                <span>
                                                    <p className="validation__type">
                                                        Continue uploading my contacts, but remove the ones with invalid data
                                                    </p>
                                                </span>
                                            </label>
                                        </p>
                                        <p className={`options ${(validationAction === validationActionConst.UPLOAD_ALL ) ? 'duplicate_rule_checked' : ''}`}>
                                            <label>
                                                <input type="checkbox" onChange={()=>{ setValidationAction(validationActionConst.UPLOAD_ALL) }} checked={(validationAction === validationActionConst.UPLOAD_ALL )} className="filled-in"/>
                                                <span>
                                                    <p className="validation__type">
                                                        Upload all contacts
                                                    </p>
                                                </span>
                                            </label>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        }

                        <BottomButtons
                            onBackClick={prevStep}
                            onContinueClick={saveAndContinue}
                            continueButtonText={'Save & Continue'}/>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => {
    return {
        carrierLookupPrices: state.contactReducer.carrierLookupPrices,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(App);