import React, { useEffect, useState } from 'react';
import { checkContactUnsubscribedApi } from './redux/contactApi';
import './unsubscribed.css'

const Unsubscribed = (props) => {

    const [isUnsubscribed, setIsUnsubscribed] = useState(false);

    useEffect(() => {
        checkContactUnsubscribedApi({contact_id: props.contact_id}).then(res => {
            let response = res.data;
            console.log(response)
            if(response.status){
                setIsUnsubscribed(true)
            }else{
                setIsUnsubscribed(false)
            }

        }).catch(error => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if(!isUnsubscribed){
        return null;
    }

    return (
        <div className='contact___details___unsubscribed__details'>
            {isUnsubscribed && 
                <>
                    <div className='unsub__title'>UNSUBSCRIBE NOTICE:</div>
                    <div className='unsub__hints'>
                        <span>
                            This contact has opted out of receiving future messages.
                        </span>
                    </div>
                </>
            }
        </div>
    );
}
export default Unsubscribed;
