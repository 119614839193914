import { Badge, makeStyles } from "@material-ui/core";

export const importContactsV2Style = makeStyles((theme) => ({
  container: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    height: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2.5),
    borderBottom: "1px solid lightgray",
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },
  headerIcon: {
    height: 50,
  },
  headerText: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: 600,
    margin: theme.spacing(0, 0, 0.625, 0),
  },
  subtitle: {
    color: "gray",
    margin: 0,
  },
  helpSection: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1.25),
  },
  helpBox: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ededed",
    color: "#000",
    padding: theme.spacing(0.625, 1.25),
    borderRadius: "5px",
    gap: theme.spacing(0.625),
    maxHeight: 36,
    cursor: "pointer",
  },
  content: {
    height: "calc(100% - 91px)",
    display: "flex",
    alignItems: "center",
    paddingInline: "14%",
  },
  card: {
    padding: theme.spacing(2.5),
    border: "1px solid lightgray",
    borderRadius: "5px",
  },
  cardTitle: {
    fontSize: 22,
    fontWeight: 600,
  },
  cardDescription: {
    color: "grey",
    fontWeight: 300,
  },
  notes: {
    fontSize: 18,
    fontWeight: 600,
  },
  notesList: {
    paddingLeft: theme.spacing(2.25),
    "& li": {
      listStyleType: "disc !important",
    },
  },

  csvModalWrapper: {
    "& .MuiButton-contained": {
      minWidth:'86px',
      backgroundColor: "#3c7ef3 !important",
      "&:focus, &:hover": {
        backgroundColor: "#3c7ef3 !important",
      },
    },
    "& .MuiButton-outlined": {
      minWidth:'86px',
      color: "#A0A0A0 !important",
      borderColor: "#ccc !important",
      backgroundColor: "#E0E0E0 !important",
      "&:focus, &:hover": {
        color: "#A0A0A0 !important",
        backgroundColor: "#E0E0E0 !important",
      },
    },
    "&:focus-visible": {
      outline: "none"
    }
  },

  tabBadge: {
    "& .MuiBadge-badge": { position: "relative", transform: "none" },
  },

  stepperActive: {
    "& .MuiStepIcon-active": {
      color: "#3E3FF0", 
    },
    "& .MuiStepIcon-completed": {
      color: "#3E3FF0",
    }
  },
}));

export function TabLabel(props) {
  const classes = importContactsV2Style();
  const { title, value, isSelected } = props;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <span>{title}</span>
      <Badge color={isSelected ? "primary" : "default"} badgeContent={value} className={classes.tabBadge} />
    </div>
  );
}
