import { Paper, styled } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const AddressGroupedTableWrapper = styled(Paper)({
    border: "none",
    "& .skip-tracing-table": {
        "& th": {
            background: blue[900],
            color: "#fff",
            "&:first-of-type": {
                borderRadius: "8px 0 0 8px",
            },
            "&:last-of-type": {
                borderRadius: "0 8px 8px 0",
            },
        },
        "& td, & th": {
            padding: "2px 8px",
            borderRadius: 0,
        },
        "& .stt-address-row": {
            background: "#3c7ef347",
            "& td:first-of-type": {
                borderRadius: "8px 0 0 8px",
            },
            "& td:last-of-type": {
                borderRadius: "0 8px 8px 0",
            },
        },
        "& .stt-address-details-row": {
            "& .MuiTableCell-root": { borderBottom: "none" },
            "& td:not(:first-of-type)": {
                borderBottom: "1px solid #d3d3d3",
            },
            "& td:last-of-type": {
                borderRight: "1px solid #d3d3d3",
                borderRadius: "0 8px 8px 0",
            },
            "& td:nth-of-type(2)": {
                borderLeft: "1px solid #d3d3d3",
                borderRadius: "8px 0 0 8px",
            },
            "&.top-contact": {
                "& td:not(:first-of-type)": {
                    backgroundColor: "#e5e5e5",
                    borderTop: "1px solid #d3d3d3",
                },
            },
        },
    },
});

export default AddressGroupedTableWrapper;