import React, {useState} from "react";
import {
    InputBase,
    MenuItem,
    Select,
    makeStyles,
    withStyles,
    Typography, ListSubheader
} from "@material-ui/core";
import BasicInput from "../contact/middleSide/custom/custom/BasicInput";

const useStyles = makeStyles((theme) => ({
    menuDropdown: {
        "& .MuiListSubheader-root": {
            paddingTop: "12px",
            backgroundColor: "#fff",
        },
        "& .MuiMenuItem-root": {
            padding: "10px 20px",
        },
        "& .MuiList-root": {
            padding: 0,
        },
    },
    menuItem: {
        "&:hover": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
        "&.Mui-selected": {
            backgroundColor: "#cbd4ff",
            color: "#133159",
        },
    },
    menuItemLineThrough: {
        "&.MuiListItem-root": {
            textDecoration: "line-through",
            color: "#ee1b54",
        },
    },
    deleted: {
        fontSize: "9px"
    },
    subMenuHeader: {
        padding: "16px 16px 4px 16px",
        fontSize: "11px",
        textTransform: "uppercase",
        color: "#2d8688"
    }
}));

const BasicInputSelect = withStyles((theme) => ({
    root: {

    },
    input: {
        backgroundColor: "white !important",
        position: "relative",
        fontSize: "15px !important",
        margin: "0 !important",
        padding: " 10px  !important",
        borderRadius: "4px !important",
        border: "1px solid #ced4da !important",
        transition: theme.transitions.create(["border-color", "box-shadow"]),
        "&:focus": {
            borderRadius: 4,
            boxShadow: "unset !important",
            backgroundColor: "#fff !important"
        },
        "&::placeholder": {
            color: "#082852 !important",
        },

    },
}))(InputBase);

const FieldMapper = ({ options, onChange, value = "", disabled = false, selectedOption }) => {
    const styles = useStyles();
    const placeholderColor = "rgb(8 40 82 / 44%)";
    const activeColor = "#36454F";
    const [search, setSearch] = useState("");

    const handleSelectChange = (event) => {
        onChange(event);

        if (selectedOption) {
            const selected = options.find((option) => (option.value === event.target.value));
            if (selected) {
                selectedOption(selected);
            }
        }
    };

    const filter = (data, searchLabel) => {
        if (searchLabel === "") {
            return data;
        }

        const regex = new RegExp(searchLabel, "i");

        const results = [];

        data.forEach(dItem => {
            const matchingOptions = dItem.options.filter(option => regex.test(option.label));
            if (matchingOptions.length > 0) {
                results.push({
                    label: dItem.label,
                    options: matchingOptions
                });
            }
        });

        return results;
    }


    const menuItems = () => {
        let searchedOptions = filter(options, search);

        return searchedOptions.map((option, index) => {
            let menuItems = [];

            menuItems.push(<Typography key={`label-${index}`} style={{ height: "unset !important" }} className={styles.subMenuHeader}>{option.label}</Typography>);
            menuItems.push(
                option.options.map((subOption, subIndex) => {
                    return (
                        <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} key={`sub-${subIndex}`} value={subOption.value}>
                            {subOption.label}
                        </MenuItem>
                    );
                })
            );

            return menuItems;
        });
    };

    return (
        <Select
            fullWidth={true}
            defaultValue={value}
            value={value}
            onChange={handleSelectChange}
            displayEmpty
            disabled={disabled}
            onClose={()=>{ setTimeout(()=>{setSearch("");}, 100) }}
            input={<BasicInputSelect style={{ color: `${!value ? placeholderColor : activeColor}` }} selectedOption={selectedOption} />}
            inputProps={{ "aria-label": "Without label" }}
            MenuProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
                PaperProps: {
                    style: {
                        maxHeight: 300,
                        scrollbarWidth: "thin",
                        scrollbarColor: "#888 transparent",
                    },
                    className: styles.menuDropdown
                },
                getContentAnchorEl: null,
            }}
        >
            <ListSubheader
                onClick={(event) => {
                    event.stopPropagation();
                }}
                onKeyDown={(event) => {
                    event.stopPropagation();
                }}
            >
                <BasicInput
                    size="small"
                    placeholder="Search..."
                    fullWidth
                    value={search}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    onChange={(event)=>{
                        setSearch(event.target.value);
                    }}
                />
            </ListSubheader>
            <MenuItem className={`${styles.menuItem} dropdownhelper-menuitem-class`} value="">Don't import</MenuItem>
            {menuItems()}
        </Select>
    );
};

export default FieldMapper;