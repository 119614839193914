import { Button } from "@material-ui/core";
import styled from "styled-components";

export const CoreButton = styled(Button)`
  box-shadow: none !important;
  min-width: unset;
  width: ${(props) => props.width || "auto"} !important;
  background-color: ${(props) => `${props.backgroundColor}` || "#303f9f"} ;
  &.MuiButton-contained:focus {
    background-color: ${(props) =>
      props.backgroundColor || "#303f9f"} !important;
  },
    text-decoration: none !important;
`;
