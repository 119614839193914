import { useEffect, useState } from "react";
import { getAllFrameContactsApi } from "../../../api/dripBatchApi";
import GlobalModal from "../../contact/rightSide/Helpers/Modal/GlobalModal";
import {Link} from "react-router-dom";

let sleepTimerDripReport = null;

const FrameWiseContactReport = (props) => {

    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [perPage, setPerPage] = useState(20)
    const [data, setData] = useState([])
    const [hasMore, setHasMore] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const STATUS_EXISTS = 4;

    useEffect(() => {
        if(props.frame != null){
            sleepTimerDripReport = setTimeout(() => {
                clearTimeout(sleepTimerDripReport)
                getBatchFramesData()
            }, 500);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getBatchFramesData = () => {
        getAllFrameContactsApi({
            batch_id: props.frame.batch_id,
            frame_id: props.frame.id,
            current_page: currentPage,
            per_page: perPage
        }).then(res => {
            let response = res.data;
            if(response.status === "success"){
                setData([...data, ...response.html])
                if(response.html.length >= perPage){
                    setCurrentPage(currentPage + 1)
                    setHasMore(true)
                }
                else{
                    setHasMore(false)
                }
                setIsLoadingMore(false)
                setIsLoading(false)
            }
        }).catch(error => {
            setIsLoading(false)
            console.log(error)
        })
    }

    const renderTableBody = () => {
        if(isLoading){
            return(
                <tr>
                    <td colSpan={5} className="empty__table__container">
                        <span>Please wait...</span>
                    </td>
                </tr>
            )
        }
        if(!isLoading && data.length === 0){
            return(
                <tr>
                    <td colSpan={5} className="empty__table__container">
                        <span>No data found !</span>
                    </td>
                </tr>
            )
        }
        let view = [];
        data.forEach((item, index) => {
            view.push(
                <tr key={index}>
                    <td className="contact___name">
                        <span><Link to={`/contacts/${item.contact_id}`} >{item.first_name} {item.last_name}</Link></span>
                    </td>
                    <td className="">
                        <span><Link to={`/contacts/${item.contact_id}`} >{item.email}</Link></span>
                    </td>
                    <td className="">
                        <span><Link to={`/contacts/${item.contact_id}`} >{item.number}</Link></span>
                    </td>
                    <td className="">
                        <span>{window.globalTimezoneConversionToDifferentTimezone(item.added_to_batch, 'UTC',"",'MM/DD/YYYY hh:mm a')}</span>
                    </td>
                    <td className="">
                        {item.batch_contact_status === STATUS_EXISTS ?
                            <span>Already in campaign</span>
                        :
                            <span>{window.globalTimezoneConversionToDifferentTimezone(item.assigned_time, 'UTC',"",'MM/DD/YYYY hh:mm a')}</span>
                        }
                    </td>
                </tr>
            )
        })
        return view;
    }

    const handleOnScroll = (e) => {
        if(!isLoading && hasMore && !isLoadingMore){
            if(Math.round((e.target.scrollTop + e.target.clientHeight), 10) >= Math.round(e.target.scrollHeight, 10)){
                setIsLoadingMore(true)
                getBatchFramesData()
            }
        }
    }

    return(
        <GlobalModal 
            open={props.isOpen}
            onClose={props.onClose}
            title={`Frame wise drip contact report`}
            className={"frame__wise__drip__report global-medium-modal"}
            hideFooter={true}
        >
            <div className="drip__frame__contact__table" onScroll={handleOnScroll}
            >
                <table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Number</th>
                            <th>Added to batch</th>
                            <th>Assigned time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableBody()}
                        {isLoadingMore && <tr><td colSpan={5} className="empty__table__container">Loading more...</td></tr>}
                    </tbody>
                </table>
            </div>
        </GlobalModal>
    )
}
export default FrameWiseContactReport;