import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";

const FileListSkeleton = () => {
  const rows = Array.from(new Array(6));

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align='center'>
            <Skeleton width='100%' />
          </TableCell>
          <TableCell align='center'>
            <Skeleton width='100%' />
          </TableCell>
          <TableCell align='center'>
            <Skeleton width='100%' />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((_, index) => (
          <TableRow key={index}>
            <TableCell align='center'>
              <Skeleton width='100%' />
            </TableCell>
            <TableCell align='center'>
              <Skeleton width='100%' />
            </TableCell>
            <TableCell align='center'>
              <Skeleton width='100%' />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FileListSkeleton;
