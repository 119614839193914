import React, {useEffect, useState, useRef} from 'react'
import { connect } from 'react-redux'
import { icons } from '../icon';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import EditIcon from '@material-ui/icons/Edit';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Input from '@material-ui/core/Input';
import { submitDripBatchTitleEdit, _dripBatchReportReload, _dripBatchReportTitleEditModal, _dripReportShowNotification } from '../../../actions/dripBatchAction';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import BasicSelect from '../../contact/middleSide/custom/custom/BasicSelect';
import BasicInput from '../../contact/middleSide/custom/custom/BasicInput';

const mapStateToProps = state => ({
    dripBatchReducer : state.dripBatchReducer
});

const mapDispatchToProps = dispatch => ({
    submitDripBatchTitleEdit: (params, callback) => dispatch(submitDripBatchTitleEdit(params, callback)),
    _dripBatchReportReload: (params) => dispatch(_dripBatchReportReload(params)),
    _dripBatchReportTitleEditModal: (params) => dispatch(_dripBatchReportTitleEditModal(params)),
    _dripReportShowNotification: (params) => dispatch(_dripReportShowNotification(params)),
})

const TYPE_MINUTE = 1
const TYPE_HOUR = 2
const TYPE_DAY = 3

const QUANTITY_MINUTE_MAX = 20;
const QUANTITY_HOUR_MAX = 200;
const QUANTITY_DAY_MAX = 1000;

const process_type = [
    {id: 1, value: TYPE_MINUTE, label: 'Minute'},
    {id: 1, value: TYPE_HOUR, label: 'Hour'},
    {id: 1, value: TYPE_DAY, label: 'Day'}
]

const EditTitleModalApp = props => {
    const titleField = useRef(null)
    const quantityFieldRef = useRef(null)
    const {show_edit_modal, edit_data} = props.dripBatchReducer
    const [submit, setSubmit] = useState(false)
    const [title, setTitle] = useState('')

    const [processType, setProcessType] = useState(TYPE_MINUTE)
    const [repeatAfterDays,setRepeatAfterDays] = useState(1);
    const [batchQuantity,setBatchQuantity] = useState(1);
    const [sendOn,setSendOn] = useState({});

    /* for quantity limit */
    const [maxQuantity, setMaxQuantity] = useState(QUANTITY_MINUTE_MAX)

    useEffect(() => {
        if(edit_data != null){
            setTitle(edit_data.title != null ? edit_data.title : ('Batch_'+edit_data.id))
            setRepeatAfterDays(parseInt(edit_data.repeat_after_days))
            setProcessType(parseInt(edit_data.type))
            setBatchQuantity(edit_data.quantity)
            try{
                let data = edit_data.send_on.replace(/\\/g, '')
                data = JSON.parse(data)
                setSendOn(data)
            }catch(error){}
        }
    }, [])

    const hanldeModal = () => {
		props._dripBatchReportTitleEditModal({modalShowFlag : false, editData: null})
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(title.trim() === ''){
            props._dripReportShowNotification({
				flag: true,
                text: 'Title is required',
                type: 'warning'
			})
            titleField.current.focus()
            return
        }
        if(batchQuantity === ""){
            props._dripReportShowNotification({
				flag: true,
                text: 'Batch quantity is required',
                type: 'warning'
			})
            quantityFieldRef.current.focus()
            return
        }

        if(JSON.stringify(sendOn) === '{}'){
            props._dripReportShowNotification({
				flag: true,
                text: 'No day selected from send on option !',
                type: 'warning'
			})
            return
        }
        else{
            let hasDay = false;
            for (const property in sendOn) {
                if(sendOn[property] ){
                    hasDay = true;
                    break;
                }
            }
            if(!hasDay){
                props._dripReportShowNotification({
                    flag: true,
                    text: 'No day selected from send on option !',
                    type: 'warning'
                })
                return
            }
        }

        confirmAlert({ 
            title: 'Are you sure to change?',
            message: 'Do you want to update batch information?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        setSubmit(true)
                        props.submitDripBatchTitleEdit({
                            id: edit_data.id,
                            title: title,
                            batch_process_type: processType,
                            quantity: batchQuantity,
                            repeat_after_days: repeatAfterDays,
                            campaign_title: edit_data.campaign_title,
                            campaign_id: edit_data.campaign_id,
                            from: 'ContactDrip_ReportEdit',
                            previous_quantity: edit_data.quantity, 
                            previous_type: edit_data.type,
                            previous_day: edit_data.repeat_after_days,
                            send_on : sendOn
                        }, (res) => {
                            setSubmit(false)
                            if(res.status === "validation-error"){
                                props._dripReportShowNotification({
                                    flag: true,
                                    text: res['html'],
                                    type: 'error'
                                })
                                titleField.current.focus()
                            }
                            else if(res.status === "success"){
                                props._dripReportShowNotification({
                                    flag: true,
                                    text: res['html'],
                                    type: 'success'
                                })
                                hanldeModal()
                                props._dripBatchReportReload(true)
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return;
                    }
                }
            ]
        })
    }

    // /* drip process new */
    // const getProcessTypes = () => {
    //     let view = [];
    //     process_type.forEach((item, index) => {
    //         view.push(
    //             <option value={item.value} key={index}>{item.label}</option>
    //         )
    //     })
    //     return view;
    // }

    const handleProcessTypeChange = (e) => {
        let value = e.target.value;
        if(value == TYPE_HOUR){
            if(repeatAfterDays > 23){
                setRepeatAfterDays(23)
            }
            if(batchQuantity > QUANTITY_HOUR_MAX){
                setBatchQuantity(QUANTITY_HOUR_MAX)
            }
            setMaxQuantity(QUANTITY_HOUR_MAX)
        }
        else if(value == TYPE_DAY){
            if(repeatAfterDays > 365){
                setRepeatAfterDays(365)
            }
            if(batchQuantity > QUANTITY_DAY_MAX){
                setBatchQuantity(QUANTITY_DAY_MAX)
            }
            setMaxQuantity(QUANTITY_DAY_MAX)
        }else{
            if(repeatAfterDays > 59){
                setRepeatAfterDays(59)
            }
            if(batchQuantity > QUANTITY_MINUTE_MAX){
                setBatchQuantity(QUANTITY_MINUTE_MAX)
            }
            setMaxQuantity(QUANTITY_MINUTE_MAX)
        }
        setProcessType(parseInt(value))
    }

    const handleRepeatTimeChange = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setRepeatAfterDays("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }

            // eslint-disable-next-line eqeqeq
            if(processType == TYPE_MINUTE){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 59){
                    setRepeatAfterDays(59)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            // eslint-disable-next-line eqeqeq
            else if(processType == TYPE_HOUR){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 23){
                    setRepeatAfterDays(23)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
            // eslint-disable-next-line eqeqeq
            else if(processType == TYPE_DAY){
                if(value < 1){
                    setRepeatAfterDays(1)
                }
                else if(value > 365){
                    setRepeatAfterDays(365)
                }
                else{
                    setRepeatAfterDays(value)
                }
            }
        }
    }

    const onFocusOutTime = (e) => {
        if(e.target.value === ""){
            setRepeatAfterDays(1)
        }
    }
    
    const getSelectDays = (data) => {
		try{
			let view = [];
			let index = 1;
			for (const property in data) {
				view.push(
					<span 
                        key={index} 
                        className={`each__send__on__day ${data[property] ? 'send__on__active' : 'send__on__inactive'}`}
                        onClick={() => onClickDay(property)}
                    >
                        {property}
                    </span>
				)
				index++;
			}
			return view;
		}catch(error){
			return <span>No Day selected !</span>
		}
	}

    const onClickDay = (data) => {
        let old_data = {...sendOn};
        Object.keys(old_data).forEach((key, index) => {
            if(key == data){
                old_data[key] = !old_data[key];
            }
        });
        setSendOn(old_data)
    }

    const handleBatchQuantity = (e) => {
        let value = e.target.value;
        if(value.trim() === ""){
            setBatchQuantity("")
        }
        else{
            value = parseInt(value);
            if(isNaN(value)){
                value = 1
            }
            if(value > maxQuantity){
                setBatchQuantity(maxQuantity)
            }
            else{
                setBatchQuantity(value)
            }
        }
    }

    return(
        <div className="modal_details modal alt" style={{display: show_edit_modal ? 'block' : 'none'}}>
            <div className="top_content d-flex justify-content-between align-items-center p-4">
                <div className="sub_title">
                    <h2>{'Drip Batch'}</h2>
                </div>
                <div className="cross_button d-flex justify-content-center align-items-center modal-close" onClick={hanldeModal}>
                    {icons.cross}
                </div>
            </div>
            <div className="leadsrc_input_fileds">
                <div>
                        <label style={{fontSize:"14px"}}>Drip batch title</label>
                        
                        <BasicInput
                            style={{marginTop:"0px"}}
                            value={title} 
                            onChange={(event) => setTitle(event.target.value)} 
                            placeholder="Enter title" 
                            fullWidth
                            inputRef={titleField}                        
                        />
                </div>

                <div className="input-field drip_batch drip_batch__wrapper__v2 col s12">
                    <div className="col-md-6 text-left drip_batch__wrapper__v2__1">
                        <label htmlFor="batch_quantity" 
                        className="form-control-label" 
                        style={{fontSize:"14px"}}> Process Type </label>
                        
                        <BasicSelect
                            options={process_type}
                            mapping={{
                                label: "label",
                                value: "value"
                            }}
                            defaultText="Select Process Type"
                            value={processType}
                            onChange={handleProcessTypeChange}                                 
                        />
                    </div>

                    <div className="col-md-6 text-left drip_batch__wrapper__v2__2">
                        <label htmlFor="batch_quantity" 
                        className="form-control-label"
                        style={{fontSize:"14px"}}> 
                          Batch Quantity 
                        <span className="text-danger">*</span> 
                        </label>
                        
                        
                        <BasicInput
                            style={{marginTop:"0px"}}
                            value={batchQuantity} 
                            onChange={handleBatchQuantity} 
                            placeholder="Enter value" 
                            fullWidth
                            ref={quantityFieldRef}
                        />
                        <span><small>Max quantity limit: {maxQuantity}</small></span>
                    </div>

                    <div className="col-md-6 text-left drip_batch__wrapper__v2__3">
                        <label htmlFor="repeat_after_days" 
                        className="form-control-label"
                        style={{fontSize:"14px"}}> 
                        Repeat After ({processType === TYPE_MINUTE ? "Minutes" : (processType === TYPE_HOUR ? "Hours" : "Days")}) 
                        <span className="text-danger">*</span> </label>
                        
                        <BasicInput
                            style={{marginTop:"0px"}}
                            value={repeatAfterDays} 
                            onChange={handleRepeatTimeChange} 
                            placeholder="Enter value" 
                            fullWidth
                            onBlur={onFocusOutTime}
                        />
                        
                    </div>
                </div>

                <div className="input-field drip_batch drip__batch__modal__sendOn col s12">
                    <div className="form-control-label">
                        Assign on campaign <span className="text-danger"style={{fontSize:"14px"}}>*</span>
                    </div>
                    <div className="sendOnButtons">
                        {getSelectDays(sendOn)}
                    </div>
                </div>
            </div>
            <div className="modal__buttons d-flex justify-content-center align-content-center">
                <button type="submit" onClick={handleSubmit} className="add__group__btn">
                    {submit ? <MoreHorizIcon /> : <EditIcon fontSize="small" />}
                    <span className="mx-2">{'Update Batch'}</span>
                </button>
                <button className="modal-close modal__close__btn" onClick={hanldeModal}>	
                    {icons.crossWhiteBg}
                    <span className="mx-2">Close</span>
                </button>
            </div>
        </div>
    )
}
const EditTitleModal = connect(mapStateToProps, mapDispatchToProps)(EditTitleModalApp);
export default EditTitleModal;