import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { Box, makeStyles } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const useStyle = makeStyles({
  formLabel: {
    fontWeight: "600 !important",
    color: "#181f48",
    marginBottom: "0px !important",
  },
  addNewButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
    width: "135px",
  },
  checkBoxStyle: {
    color: "#0E1B3D",
    fontSize: "16px !important",
    fontWeight: "600",
  },
  addDealButton: {
    color: " white !important",
    padding: "7px 12px 8px !important",
    fontWeight: "600 !important",
    borderRadius: "5px !important",
    textTransform: "capitalize !important",
    backgroundColor: " #316AFF !important",
  },
  cancelButton: {
    color: " #316AFF !important",
  },
});

const HowItWorksModal = ({ open, onClose }) => {
  const classes = useStyle();
  const [loading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    if (!loading) return;
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [loading]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <Box minWidth={600} p={"16px"}>
          {loading ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <Skeleton width={"100%"} height={315} variant='rectangular' style={{ borderRadius: "8px" }} />
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              <iframe width="560" height="315" src="https://cdn.pixabay.com/video/2020/06/22/42890-432450896_large.mp4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
          )}

          <Box display={"flex"} justifyContent={"end"} gridGap={16} mt={2}>
            <Button variant='contained' className={classes.addDealButton} onClick={onClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default HowItWorksModal;
