import React from "react";

const EmptyInbox = () =>
    <div style={{height:'60%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'120px'}}>

        <img src="https://s3.us-east-1.amazonaws.com/pypepro/user/1/xgWzx1Us0i5UKVqdYEPCIYqK1Lcfe2BBPLpwQSFx.png" alt="" style={{width: '40%', maxWidth: '250px', margin: 'unset'}}/>
        <div className="d-flex">
            <h2 style={{fontSize: '30px', margin: 0}}>Hello there!<span style={{fontSize: '38px'}}>👋</span></h2>
        </div>
        
        <p style={{fontSize: '18px'}}>Ready to strike up a conversation with this contact?</p>
    </div>

export default EmptyInbox;