import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import Button from "@material-ui/core/Button";
import CarrierLookupStyles from "./CarrierLookupStyles.module.css";
import {
    BUTTON_TEXT,
    CREDIT_SETTING_TYPE,
    EMAIL_STATE,
    INFO_CHECK, LOOKUP_BUTTON_TEXT,
    LOOKUP_CATEGORY, LOOKUP_LABEL,
    LOOKUP_STATUS, STATE_CHECK,
    STATUS_CHECK
} from "./Constants";
import BootstrapTooltip from "../../../../globals/BootstrapTooltip";
import {DoNotCallIcon, InvalidIcon, ValidIcon, VerifiedOkIcon} from "./Icons";
import {
    LOOKUP_TYPE_CARRIER_LOOKUP,
    LOOKUP_TYPE_DNC_LOOKUP,
    LOOKUP_TYPE_EMAIL_LOOKUP
} from "../../../../../constants/CoreConstants";
import {updateMultipleData} from "../../../../../actions/contactAction";
import {setContactDetails} from "../../../../../actions/contactAction";
import NewConfirmAlert from "../../../../common/new-alert/NewConfirmAlert";
import {carrierLookupVerifyContactApi, getCarrierLookupCreditSettingsApi} from "../../../../../api/contactApi";
import Styles from "../customTitle/customTitle.module.css";
import {cleanNumber, padZero, toHuman, toRegular} from "../../helper/utils";

const CarrierLookup = (props) => {
    const [validationInfo, setValidationInfo] = useState({ status: LOOKUP_STATUS.NOT_VERIFIED, data: null });

    useEffect(() => {
        if (!props.additional){
            if (props.contact &&
                props.contact.contact_additional_informations &&
                props.contact.contact_additional_informations[STATUS_CHECK[props.lookupType]]
            ) {
                let infos = {
                    status: props.contact.contact_additional_informations[STATUS_CHECK[props.lookupType]],
                    data: null
                };

                if (props.lookupType && props.lookupType === LOOKUP_TYPE_DNC_LOOKUP && props.contact.contact_additional_informations[STATUS_CHECK[props.lookupType]] === LOOKUP_STATUS.NOT_VERIFIED){
                    infos.status = LOOKUP_STATUS.CHECK_DNC;
                }

                let data = props.contact.contact_additional_informations[INFO_CHECK[props.lookupType]];

                if (props.lookupType && props.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP && props.contact.contact_additional_informations[STATUS_CHECK[props.lookupType]] !== LOOKUP_STATUS.NOT_VERIFIED){
                    if (data && typeof data === "string"){
                        try {
                            data = JSON.parse(data);
                        }catch (err){
                            console.log(err)
                        }
                    }

                    if (data && typeof data === "object" && data.state === EMAIL_STATE.UNDELIVERABLE){
                        infos.status = LOOKUP_STATUS.INVALID;
                    }
                }

                if (props.contact.contact_additional_informations[INFO_CHECK[props.lookupType]]) {
                    if (data && typeof data === "string"){
                        try {
                            data = JSON.parse(data);
                        }catch (err){
                            console.log(err)
                        }
                    }

                    infos.data = data;
                }

                setValidationInfo(infos);
            } else {
                if (props.lookupType && props.lookupType === LOOKUP_TYPE_DNC_LOOKUP){
                    setValidationInfo({
                        status: LOOKUP_STATUS.CHECK_DNC,
                        data: null
                    });
                }else {
                    setValidationInfo({
                        status: LOOKUP_STATUS.NOT_VERIFIED,
                        data: null
                    });
                }
            }
        }else {
            if (props.additional &&
                props.additional &&
                props.additional[STATUS_CHECK[props.lookupType]]
            ) {
                let infos = {
                    status: props.additional[STATUS_CHECK[props.lookupType]],
                    data: null
                };

                if (props.lookupType && props.lookupType === LOOKUP_TYPE_DNC_LOOKUP && props.additional[STATUS_CHECK[props.lookupType]] === LOOKUP_STATUS.NOT_VERIFIED){
                    infos.status = LOOKUP_STATUS.CHECK_DNC;
                }
                let data = props.additional[INFO_CHECK[props.lookupType]];

                if (props.lookupType && (props.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP || props.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP) && props.additional[STATUS_CHECK[props.lookupType]] !== LOOKUP_STATUS.NOT_VERIFIED){
                    if (data && typeof data === "string"){
                        try {
                            data = JSON.parse(data);
                        }catch (err){

                        }
                    }

                    if (data && typeof data === "object" && data.state === EMAIL_STATE.UNDELIVERABLE){
                        infos.status = LOOKUP_STATUS.INVALID;
                    }
                }

                if (props.additional[INFO_CHECK[props.lookupType]]) {
                    infos.data = data;
                }
                setValidationInfo(infos);
            } else {
                if (props.lookupType && props.lookupType === LOOKUP_TYPE_DNC_LOOKUP){
                    setValidationInfo({
                        status: LOOKUP_STATUS.CHECK_DNC,
                        data: null
                    });
                }else {
                    setValidationInfo({
                        status: LOOKUP_STATUS.NOT_VERIFIED,
                        data: null
                    });
                }
            }
        }

    }, [props.contact, props.lookupType, props.additional]); // eslint-disable-line

    const initVerification = async () => {
        if (validationInfo.status !== LOOKUP_STATUS.VERIFYING) {
            if (!props.carrierLookupPrices) {
               try {
                   const response = await getCarrierLookupCreditSettingsApi()
                   if (response.data && response.data.status === "success") {
                       if (response.data && response.data.data) {
                           props.updateMultipleData({ carrierLookupPrices: response.data.data });
                           alertToVerify(response.data.data);
                       }
                   }else {
                       window.showNotification("ERROR", "Unable to initiate validation. Please contact with administrator");
                   }
               }catch (err){
                   window.showNotification("ERROR", "Unable to initiate validation. Please contact with administrator");
               }
            } else {
                alertToVerify(props.carrierLookupPrices);
            }
        } else {
            window.showNotification("ERROR", "Validating still in progress");
        }
    };

    const alertToVerify = (pricingInfo) => {
        let price;
        if (pricingInfo) {
            if (props.lookupType) {
                price = pricingInfo.find(({ type }) => type === CREDIT_SETTING_TYPE[props.lookupType]);
                if (price) {
                    price = price.value;
                }
            }
        }

        if (price) {
            let validationParams;
            if (!props.additional){
                if (props.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP) {
                    validationParams = {
                        lookupType: LOOKUP_TYPE_EMAIL_LOOKUP,
                        contact: {
                            email: props.contact.email,
                            id: props.contact.id,
                        },
                        categoryName: LOOKUP_CATEGORY[props.lookupType],
                    };
                } else if (props.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP) {
                    validationParams = {
                        lookupType: LOOKUP_TYPE_CARRIER_LOOKUP,
                        contact: {
                            number: props.contact.number,
                            id: props.contact.id,
                        },
                        categoryName: LOOKUP_CATEGORY[props.lookupType],
                    };
                } else if (props.lookupType === LOOKUP_TYPE_DNC_LOOKUP) {
                    validationParams = {
                        lookupType: LOOKUP_TYPE_DNC_LOOKUP,
                        contact: {
                            number: props.contact.number,
                            id: props.contact.id,
                        },
                        categoryName: LOOKUP_CATEGORY[props.lookupType],
                    };
                }
            }else {
                if (props.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP) {
                    validationParams = {
                        lookupType: LOOKUP_TYPE_EMAIL_LOOKUP,
                        contactAdditionalContacts: {
                            email: props.additional.email,
                            id: props.additional.id,
                        },
                        categoryName: LOOKUP_CATEGORY[props.lookupType],
                    };
                } else if (props.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP) {
                    validationParams = {
                        lookupType: LOOKUP_TYPE_CARRIER_LOOKUP,
                        contactAdditionalContacts: {
                            number: props.additional.number,
                            id: props.additional.id,
                        },
                        categoryName: LOOKUP_CATEGORY[props.lookupType],
                    };
                } else if (props.lookupType === LOOKUP_TYPE_DNC_LOOKUP) {
                    validationParams = {
                        lookupType: LOOKUP_TYPE_DNC_LOOKUP,
                        contactAdditionalContacts: {
                            number: props.additional.number,
                            id: props.additional.id,
                        },
                        categoryName: LOOKUP_CATEGORY[props.lookupType],
                    };
                }
            }

            NewConfirmAlert({
                onSubmit: async () => {
                    setValidationInfo({ status: LOOKUP_STATUS.VERIFYING, data: null });
                    try {
                        const response  = await carrierLookupVerifyContactApi(validationParams);
                        if (response) {
                            if (response.success) {
                                if (props.lookupType !== LOOKUP_TYPE_DNC_LOOKUP){
                                    if (response.status) {
                                        if (response.data && response.data.carrierResponse) {
                                            updateReducer(LOOKUP_STATUS.VALID, response.data.carrierResponse);
                                            setValidationInfo({ status: LOOKUP_STATUS.VALID, data: response.data.carrierResponse });
                                        } else {
                                            updateReducer(LOOKUP_STATUS.VALID, null);
                                            setValidationInfo({ status: LOOKUP_STATUS.VALID, data: null });
                                        }
                                    } else {
                                        updateReducer(LOOKUP_STATUS.INVALID, null);
                                        setValidationInfo({ status: LOOKUP_STATUS.INVALID, data: null });
                                    }
                                    window.showNotification("SUCCESS", "Validated successfully");
                                }else {
                                    if (response.status) {
                                        if (response.data && response.data.carrierResponse.dncStatus === "NO") {
                                            updateReducer(LOOKUP_STATUS.VERIFIED_OK, null);
                                            setValidationInfo({ status: LOOKUP_STATUS.VERIFIED_OK, data: null });
                                        } else {
                                            updateReducer(LOOKUP_STATUS.DO_NOT_CALL, null);
                                            setValidationInfo({ status: LOOKUP_STATUS.DO_NOT_CALL, data: null });
                                        }
                                        window.showNotification("SUCCESS", "Validated successfully");
                                    } else {
                                        updateReducer(LOOKUP_STATUS.NOT_VERIFIED, null);
                                        setValidationInfo({ status: LOOKUP_STATUS.NOT_VERIFIED, data: null });
                                        window.showNotification("ERROR", "Sorry!! Unable to validate");
                                    }
                                }
                            } else {
                                updateReducer(LOOKUP_STATUS.NOT_VERIFIED, null);
                                setValidationInfo({ status: LOOKUP_STATUS.NOT_VERIFIED, data: null });
                                window.showNotification("ERROR", response.message || "Sorry!! Unable to validate");
                            }
                        } else {
                            updateReducer(LOOKUP_STATUS.NOT_VERIFIED, null);
                            setValidationInfo({ status: LOOKUP_STATUS.NOT_VERIFIED, data: null });
                            window.showNotification("ERROR", "Sorry!! Unable to validate");
                        }
                    }catch (err){
                        console.log(err)
                        setValidationInfo({ status: LOOKUP_STATUS.NOT_VERIFIED, data: null });
                        window.showNotification("ERROR", "Sorry!! Unable to validate");
                    }
                },
                title: `${LOOKUP_LABEL[props.lookupType]} will cost: $${cleanNumber(price)}`,
                description: "These credits will be deducted from your balance.",
                cancelText: "Cancel",
                submitText: LOOKUP_BUTTON_TEXT[props.lookupType],
                width: "580px",
            });
        } else {
            window.showNotification("ERROR", "Please contact with system administrator");
        }
    };

    const updateReducer = (status, data) => {
        if (!props.additional){
            let tempContact = { ...props.contact };
            if (!tempContact.contact_additional_informations){
                tempContact.contact_additional_informations = {};
            }

            const currentDate = new Date();
            const formattedDate = `${currentDate.getFullYear()}-${padZero(currentDate.getMonth() + 1)}-${padZero(currentDate.getDate())} ${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;

            if (props.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP) {
                if (tempContact.contact_additional_informations) {
                    tempContact.contact_additional_informations.email_validation_status = status;
                    tempContact.contact_additional_informations.email_validation_infos = data;
                    if (status !== LOOKUP_STATUS.NOT_VERIFIED){
                        tempContact.contact_additional_informations.email_validation_date = formattedDate;
                    }
                }
            } else if (props.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP) {
                if (tempContact.contact_additional_informations) {
                    tempContact.contact_additional_informations.number_validation_status = status;

                    if (data && data.lineType){
                        tempContact.contact_additional_informations.number_state = data.lineType;
                    }

                    tempContact.contact_additional_informations.number_validation_infos = data;
                    if (status !== LOOKUP_STATUS.NOT_VERIFIED){
                        tempContact.contact_additional_informations.number_validation_date = formattedDate;
                    }
                }
            } else if (props.lookupType === LOOKUP_TYPE_DNC_LOOKUP) {
                if (tempContact.contact_additional_informations) {
                    tempContact.contact_additional_informations.dnc_status = status;
                    tempContact.contact_additional_informations.dnc_validation_infos = data;
                    if (status !== LOOKUP_STATUS.NOT_VERIFIED){
                        tempContact.contact_additional_informations.dnc_validation_date = formattedDate;
                    }
                }
            }
            props.setContactDetails(tempContact);
        }else {
            let tempAddi = [...props.contactAdditionalContacts];
            tempAddi[props.additional.ogIndex][INFO_CHECK[props.lookupType]] = data;
            tempAddi[props.additional.ogIndex][STATUS_CHECK[props.lookupType]] = status;

            if (data && data.lineType){
                tempAddi[props.additional.ogIndex][STATE_CHECK[props.lookupType]] = data.lineType;
            }

            props.updateMultipleData({ contactAdditionalContacts: tempAddi });
        }
    };

    const renderStatus = () => {
        switch (validationInfo.status) {
            case LOOKUP_STATUS.VALID:
                return props.lookupType === LOOKUP_TYPE_EMAIL_LOOKUP ?
                    ((
                        validationInfo.data &&
                        typeof validationInfo.data === "object" &&
                            validationInfo.data.state !== EMAIL_STATE.UNDELIVERABLE
                        ) ?
                        <div className={CarrierLookupStyles.dataTooltipHeader}>
                            <span>Status:</span>
                            <span className={`${CarrierLookupStyles.statusChip} ${CarrierLookupStyles.statusChipValid}`}>
                                Valid
                            </span>
                        </div> :
                        <div className={CarrierLookupStyles.dataTooltipHeader}>
                            <span>Status:</span>
                            <span className={`${CarrierLookupStyles.statusChip} ${CarrierLookupStyles.statusChipInvalid}`}>
                                Invalid
                            </span>
                        </div>
                    ) : (
                        <div className={CarrierLookupStyles.dataTooltipHeader}>
                            <span>Status:</span>
                            <span className={`${CarrierLookupStyles.statusChip} ${CarrierLookupStyles.statusChipValid}`}>
                                Valid
                            </span>
                        </div>
                    );
            case LOOKUP_STATUS.INVALID:
                return (
                    <div className={CarrierLookupStyles.dataTooltipHeader}>
                        <span>Status:</span>
                        <span className={`${CarrierLookupStyles.statusChip} ${CarrierLookupStyles.statusChipInvalid}`}>
                            Invalid
                        </span>
                    </div>
                );
            default:
                return <div/>;
        }
    };

    const renderData = () => {
        if (validationInfo.data && typeof validationInfo.data === "object") {
            try {
                return (
                    <div className={CarrierLookupStyles.dataTooltipContent}>
                        {renderStatus()}
                        {
                            Object
                                .entries(validationInfo.data)
                                .map(([key, value]) => (
                                    value ?
                                <div className={CarrierLookupStyles.dataElement}>
                                    <span className={CarrierLookupStyles.dataElementKey}>{toRegular(key)}:</span>
                                    <span className={CarrierLookupStyles.dataElementValue}>{toHuman(value)}</span>
                                </div> : <div/>))
                        }
                    </div>
                );
            } catch (e) {
                return "No Data";
            }

        }else {
            return "No Data";
        }
    };

    const renderView = () => {
        switch (validationInfo.status) {
            case LOOKUP_STATUS.VALID:
                return (
                    <div className={CarrierLookupStyles.contentWrapper}>
                        <BootstrapTooltip arrow placement="top-center" title={renderData()}>
                            <div className={CarrierLookupStyles.statusIconWrapper}>
                                <ValidIcon/>
                            </div>
                        </BootstrapTooltip>
                    </div>
                );
            case LOOKUP_STATUS.INVALID:
                return (
                    <div className={CarrierLookupStyles.contentWrapper}>
                        <BootstrapTooltip arrow placement="top-center" title={renderData()}>
                            <div className={CarrierLookupStyles.statusIconWrapper}>
                                <InvalidIcon/>
                            </div>
                        </BootstrapTooltip>
                    </div>
                );
            case LOOKUP_STATUS.DO_NOT_CALL:
                return (
                    <div className={CarrierLookupStyles.contentWrapper}>
                        <BootstrapTooltip arrow placement="top-center" title={"Do Not Call"}>
                            <div className={CarrierLookupStyles.statusIconWrapper}>
                                <DoNotCallIcon/>
                            </div>
                        </BootstrapTooltip>
                    </div>
                );
            case LOOKUP_STATUS.VERIFIED_OK:
                return (
                    <div className={CarrierLookupStyles.contentWrapper}>
                        <BootstrapTooltip arrow placement="top-center" title={"Verified Ok"}>
                            <div className={CarrierLookupStyles.statusIconWrapper}>
                                <VerifiedOkIcon/>
                            </div>
                        </BootstrapTooltip>
                    </div>
                );
            default:
                return null;
        }
    };

    if (props.validator){
        return (
            <div className={CarrierLookupStyles.contentWrapper}>
                {renderView()}
                <Button
                    className={`${Styles.buttonStyle} ${(props.lookupType === LOOKUP_TYPE_DNC_LOOKUP ? Styles.checkButton : Styles.validButton)}`}
                    variant="contained"
                    color="primary"
                    size="small"
                    disableElevation
                    onClick={initVerification}
                >
                    {BUTTON_TEXT[validationInfo.status]}
                </Button>
            </div>
        );
    }

    return renderView();
}

const mapStateToProps = state => {
    return {
        carrierLookupPrices: state.contactReducer.carrierLookupPrices,
        contact: state.contactReducer.contact,
        contactAdditionalContacts: state.contactReducer.contactAdditionalContacts
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateMultipleData: (params) => dispatch(updateMultipleData(params)),
        setContactDetails: (params) => dispatch(setContactDetails(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CarrierLookup);