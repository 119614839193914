import React, {Component} from 'react';
import {connect} from "react-redux";
import ShareContactModal from './ShareContactModal';
import Helper from '../../globals/Helper';
import Utils from "../../../helpers/Utils";
import {EnhancedDataButtonBackground} from "../Icons";
import BootstrapTooltip from "../../globals/BootstrapTooltip";
import {setContactDetails} from "../../../actions/contactAction";

const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
};

class AvatarComponet extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            isOpen: false,
            firstNameInvalidError : false,
            firstNameLengthError : false,
            lastNameInvalidError : false,
            lastNameLengthError : false,
            showShareContactModal: false,
            dncStatus: "NOT_VERIFIED"
        };   
    }

    componentDidMount() {
        let contacts = this.props.contacts
        if(contacts.contact_additional_informations && contacts.contact_additional_informations.dnc_status){
            this.setState({dncStatus: contacts.contact_additional_informations.dnc_status})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        let contacts = this.props.contacts;
        if(contacts.contact_additional_informations && contacts.contact_additional_informations.dnc_status
            && contacts.contact_additional_informations.dnc_status !== this.state.dncStatus){
                this.setState({dncStatus: contacts.contact_additional_informations.dnc_status})
        }
    }


    render() {
        const {contacts}=this.props;
        return (
            <React.Fragment>
                <div className="user__profile__wr position-relative">
                    <div className="user__profile__avater">
                        <span className="user__profile__h2">{Helper.getIconTextFromContact(contacts)}</span>
                        <span className="edit_share_icon edit_share_icon-alt">
                            <i className="material-icons text-light-blue" style={{cursor: 'pointer'}} onClick = {() => {
                                this.setState({
                                    showShareContactModal : true
                                })
                            }}>
                                share
                            </i>
                        </span>
                    </div>
                    {
                        (Utils.getAccountData("carrierLookup") || Utils.getAccountData("dnc") || Utils.getAccountData("skipTrace")) &&
                        <BootstrapTooltip arrow title="Click here for data enhancement">
                        <span className="enhanceDataButton" onClick={()=>{
                            window.globalEnhancedData(true, {
                                id: this.props.contacts.id,
                                email: this.props.contacts.email,
                                number: this.props.contacts.number,
                                address: this.props.contacts.address,
                                city: this.props.contacts.city,
                                state: this.props.contacts.state,
                                zip: this.props.contacts.zip,
                                contact_additional_informations: this.props.contacts.contact_additional_informations,
                                requestModule: "CONTACT",
                                callback: (res) => {
                                    let tempContact = { ...this.props.contacts };

                                    const [isValid, inValid, doNotCall, verifiedOk] = ["VALID", "INVALID", "DO_NOT_CALL", "VERIFIED_OK"]
                                    const [requestDone, requestFailed] = ["REQUEST_DONE", "REQUEST_FAILED"]

                                    let cleanDataInfos = null;

                                    if (res.data && Array.isArray(res.data)){
                                        cleanDataInfos = res.data.find((validationInfo)=>(validationInfo.source === "CONTACT"));
                                    }

                                    const currentDate = new Date();
                                    const formattedDate = `${currentDate.getFullYear()}-${padZero(currentDate.getMonth() + 1)}-${padZero(currentDate.getDate())} ${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(currentDate.getSeconds())}`;

                                    if (cleanDataInfos){
                                        if (!tempContact.contact_additional_informations){
                                            tempContact.contact_additional_informations = {};
                                        }

                                        if (res["email-lookup-status"] === requestDone && cleanDataInfos["email-validation-status"]) {
                                            tempContact.contact_additional_informations.email_validation_status = cleanDataInfos["email-validation-status"];
                                            tempContact.contact_additional_informations.email_validation_infos = { state: cleanDataInfos["email-state"] };
                                            tempContact.contact_additional_informations.email_validation_date = formattedDate;
                                        }

                                        if (res["carrier-lookup-status"] === requestDone && cleanDataInfos["number-validation-status"]) {
                                            tempContact.contact_additional_informations.number_validation_status = cleanDataInfos["number-validation-status"];
                                            tempContact.contact_additional_informations.number_validation_infos = { state: cleanDataInfos["number-state"] };
                                            tempContact.contact_additional_informations.number_validation_date = formattedDate;
                                        }

                                        if (res["dnc-lookup-status"] === requestDone && cleanDataInfos["dnc-state"]) {
                                            tempContact.contact_additional_informations.dnc_status = cleanDataInfos["dnc-state"];
                                            tempContact.contact_additional_informations.dnc_validation_date = formattedDate;
                                        }

                                        if (res["skip-trace-lookup-status"] === requestDone) {
                                            tempContact.contact_additional_informations.skip_trace_date = formattedDate;
                                        }
                                    }

                                    this.props.setContactDetails(tempContact);
                                }
                            });
                        }} >
                            <EnhancedDataButtonBackground />
                        </span>
                        </BootstrapTooltip>
                    }
                </div>
                
                <div className="user_profile_name d-flex justify-content-center align-items-center fullNameUser">
                    {contacts!=null &&
                    <h5 className="overwrite-h5">{!Helper.isEmpty(contacts.first_name) ? contacts.first_name  + ' ' : ''}{!Helper.isEmpty(contacts.last_name) ? contacts.last_name : ''}</h5>
                    }
                    {Utils.getAccountData("carrierLookup") && this.state.dncStatus === "DO_NOT_CALL" &&

                        <div className="dncStatusChip">DNC</div>
                    }
                </div>
                {this.state.showShareContactModal &&
                    <ShareContactModal
                        title={'Share contact information'}
                        isOpen={this.state.showShareContactModal}
                        open={this.state.showShareContactModal}
                        contact_id={contacts.id}
                        closeModal = {() => {
                            this.setState({
                                showShareContactModal : false
                            })
                        }}
                        onClose={()=>this.setState({
                            showShareContactModal : false
                        })}
                        showNotificationStart={this.props.showNotificationStart}
                    />
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        contacts: state.contactReducer.contact,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setContactDetails: (params) => dispatch(setContactDetails(params))
    };
}
export default connect(mapStateToProps, mapDispatchToProps) (AvatarComponet);