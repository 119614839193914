const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumSignificantDigits: 3,
});

const numberToDollar = (number) => {
  if (number !== undefined && number !== null) {
    if (typeof number === "string") return formatter.format(+number);
    return formatter.format(number);
  }
};

export default numberToDollar;
