import axios from "axios";
import {getCookie} from "../helpers/Cookie";

const apiKey = getCookie(process.env.REACT_APP_ACCESS_TOKEN);

const responseBody = (response) => {
    return response.data;
};

const errorResponseBody = (error) => {
    if (error.response) {
        return error.response;
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log("Error: axios ", error.message);
    }
};

const HttpRequest = {
    get: (url = "", params = {}) =>
        axios
            .get(url, {
                params: params,
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),

    post: (url = "", body = {}) =>
        axios
            .post(url, body, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),

    put: (url = "", body = {}) =>
        axios
            .put(url, body, {
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),

    delete: (url = "", params = {}) =>
        axios
            .delete(url, {
                params: params,
                headers: {
                    Authorization: `Bearer ${apiKey}`,
                },
            })
            .then(responseBody)
            .catch(errorResponseBody),
    multipart: (url = "", formData) =>
        axios
            .post(url, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${apiKey}`,
                },
            })
             .then(responseBody)
             .catch(errorResponseBody),
};

export default HttpRequest;