import { Box } from "@material-ui/core";
import React from "react";
import DraggableSection from "./DraggableSection";
import Contacts from "./contacts/Contacts";
import {LeftSideSkeleton} from "./common/LeftSideSkeleton";
import DeleteLead from "./DeleteLead/DeleteLead";

const LeftSideV2 = (props) => {
  const { goToContactDetails, loadingData, contactDetails } = props || {};


  let content = <LeftSideSkeleton/>;

  if (!loadingData){
      content = (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 25, pb: 3 }}>
              <Contacts goToContactDetails={goToContactDetails} contactDetails={contactDetails} />
              <DraggableSection />
              {/* <ViewAllInfos/> */}
              <DeleteLead />
          </Box>
      );
  }

  return (
    <div className='col s12 m12 l3 xl3 contact_details white'>
        {content}
    </div>
  );
};

export default LeftSideV2;
