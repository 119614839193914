import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { LOOKUP_TYPE_CARRIER_LOOKUP } from "../../constants/CoreConstants";
import Typography from "@material-ui/core/Typography";
import {Divider, Table, TableBody, TableCell, TableContainer, TableRow} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Styles from "./ValidationResponse.module.css";

const ValidationResponse = (props) => {
  const renderValidationData = () => {
    const view = [];
    if (
      props.response &&
      props.response.data &&
      typeof props.response.data === "object" &&
      props.response.data.carrierResponse &&
      typeof props.response.data.carrierResponse === "object"
    ) {
      const dataArray = Object.entries(props.response.data.carrierResponse);
      for (let i = 0; i < dataArray.length; i++) {
        const result = dataArray[i][0].replace(/([A-Z])/g, " $1");
        const title = result.charAt(0).toUpperCase() + result.slice(1);

        view.push(
            <TableRow>
              <TableCell style={{fontWeight:600}}>{title}</TableCell>
              <TableCell>{dataArray[i][1]}</TableCell>
            </TableRow>
        );
      }
    }

    return view;
  };

  const renderContact = () => {
    let view = "";

    if (props.validationData) {
      view =
        props.validationData.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP
          ? props.validationData.contact.number
          : props.validationData.contact.email;
    }

    return view;
  };

  const renderMessage = () => {
    let view = "";

    if (
      (props.response && props.response.success) ||
      (props.response.message &&
        props.response.message.includes("Invalid number :"))
    ) {
      if (props.validationData.lookupType === LOOKUP_TYPE_CARRIER_LOOKUP) {
        if (props.response.status) {
          view = (
            <div className={Styles.resultValid}>
              is a <span className={Styles.validAlert}>valid</span> phone number
            </div>
          );
        } else {
          view = (
            <div className={Styles.resultInvalid}>
              is <span className={Styles.invalidAlert}>not a valid</span> phone
              number
            </div>
          );
        }
      } else {
        if (props.response.status) {
          view = (
            <div className={Styles.resultValid}>
              is a <span className={Styles.validAlert}>valid</span> email
              address
            </div>
          );
        } else {
          view = (
            <div className={Styles.resultInvalid}>
              is <span className={Styles.invalidAlert}>not a valid</span> email
              address
            </div>
          );
        }
      }
    }

    return view;
  };

  return confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className={Styles.mainWrp}>
            <Typography variant={"h6"} style={{padding:'20px'}}>Validation Result</Typography>
            <Divider light />

            <div className={Styles.bodyWrp}>
              <div className={Styles.phoneValidatorResult}>
                <div className={Styles.phoneValidatorResultTitle}>
                  <div className={Styles.phoneValidatorResultContact}>
                    {renderContact()}
                  </div>
                  {renderMessage()}
                </div>
              </div>
              <Divider light/>
              <Paper variant={"outlined"} style={{borderBottom:'none'}}>
                <TableContainer>
                  <Table stickyHeader>
                    <TableBody>
                      {renderValidationData()}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>

          <div className={Styles.footerWrp}>
            <button className={Styles.closeButton} onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      );
    },
    closeOnClickOutside: props.closeOnClickOutside,
    overlayClassName: Styles.overlay,
  });
};
export default ValidationResponse;
