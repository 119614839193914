import { Box, Button, Dialog, Divider, Typography, makeStyles } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { ErrorMessage } from "../contact/middleSide/Products/ProductStyles";
import { Skeleton } from "@material-ui/lab";
import { assignToCampaign, } from "../../api/contactApi";
import BasicSelect from "../contact/middleSide/custom/custom/BasicSelect";
import { useDispatch, useSelector } from "react-redux";
import { selectImportContactState } from "../../reducers/importContact/importContactSelector";
import { loadCampaignDataStart } from "../../reducers/importContact/importContactSlice";

const useStyles = makeStyles({
  titleHeader: {
    fontSize: "20px",
    color: "white",
  },
  closeIconBox: {
    width: "25px",
    height: "25px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#FF264A",
    cursor: "pointer",
  },
  cardWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "16px",
  },
  descriptionText: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "24px",
    color: "#FF264A",
    margin: 0,
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: "24px",
    color: "#133159",
    marginTop: "12px",
    marginBottom: "8px",
  },
  closeButton: {
    color: "#000 !important",
    width: "100px !important",
    border: "1px solid #ccc !important",
    fontWeight: 600,
    marginRight: "8px",
    textTransform: "capitalize !important",
    backgroundColor: "#fff !important",
    "&:focus,&:hover": {
      background: "#fff",
    },
  },
  saveButton: {
    color: (props) => (props.isSubmit ? "#000" : "#FFF"),
    width: "100px !important",
    fontWeight: 600,
    backgroundColor: (props) => (props.isSubmit ? "#EDEDED" : "#3C7EF3"),
    "&:focus,&:hover": {
      background: "#3C7EF3",
    },
  },
});

const AssignToCampaignModal = ({ open, onClose, fileId }) => {
  const { campaigns, loadingCampaigns } = useSelector(selectImportContactState);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const classes = useStyles({ isSubmitting });
  const dispatch = useDispatch();
  
  useEffect(()=>{
    if(campaigns && !campaigns[0]) {
      dispatch(loadCampaignDataStart());
    }
  },[]);

  const handleSubmit = async () => {
    if (!selectedCampaign) {
      setError("Select a campaign");
      return;
    }
    const payload = {
      campaignId: selectedCampaign,
      fileListId: fileId,
    };
    setIsSubmitting(true);
    try {
      const resp = await assignToCampaign(payload);
      if (resp.success) {
        window.showNotification("SUCCESS", resp.message);
      } else {
        window.showNotification("ERROR", resp.message);
      }
    } catch (error) {
      window.showNotification("ERROR", "Something Went Wrong, Please Try Again");
    } finally {
      setIsSubmitting(false);
      onClose();
    }
  };

  return (
    <Dialog
      PaperProps={{
        style: {
          minWidth: "650px",
        },
      }}
      open={open}
      onClose={() => {}}
    >
      <Box minWidth={"650px"} minHeight={300} sx={{ "& .MuiTypography-root": { height: "unset !important" } }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          padding={2}
          style={{ backgroundColor: "#346FEF" }}
        >
          <Typography variant='body1' style={{ marginBottom: 4, height: "unset !important" }} className={classes.titleHeader}>
            Assign To Campaign
          </Typography>

          <Box onClick={onClose} className={classes.closeIconBox}>
            <Close style={{ color: "white" }} />
          </Box>
        </Box>

        { loadingCampaigns ? (
          <Box style={{ padding: "16px" }}>
            <Skeleton variant='text' width='100%' height={60} />
            <Box>
              <Skeleton variant='rect' width='100%' height={50} style={{ marginTop: "4px" }} />
            </Box>
            <Skeleton variant='text' width='60%' height={30} style={{ marginTop: "16px" }} />
            <Skeleton variant='rect' width='100%' height={40} style={{ marginTop: "4px" }} />
          </Box>
        ) : (
          <Box className={classes.cardWrapper}>
            <Box>
              <p className={classes.descriptionText}>
                <b style={{ textDecoration: "underline" }}>Note:</b> If you have already added your file by drip
                batch mode we suggest you not to add your contact file from this page. Adding a contact file to the
                campaign from this page means that contact will be added immediately to the campaign and all the actions
                of the campaign will be executed for those contact according to campaign settings.
              </p>
            </Box>
            <Box>
              <Typography style={{ height: "unset !important" }} className={classes.label}>
                Select Campaign <span style={{ color: "red" }}>*</span>
              </Typography>
              <Box>
                <BasicSelect
                  fullWidth
                  name='campaign'
                  defaultText='Select a Campaign'
                  value={selectedCampaign}
                  options={campaigns}
                  onChange={(e) => {
                    setSelectedCampaign(e.target.value);
                    setError("");
                  }}
                  mapping={{ label: "title", value: "id" }}
                />
              </Box>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </Box>
          </Box>
        )}

        <Box padding={2} display={"flex"} justifyContent={"end"}>
          <Box display={"flex"} gridGap={8} sx={{ fontWeight: 600 }}>
            <Button variant='outlined' onClick={onClose} className={classes.CloseButton}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              className={classes.saveButton}
              variant='contained'
              disableElevation
              disabled={isSubmitting}
            >
              {isSubmitting ? "Saving..." : "Save"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AssignToCampaignModal;
