import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import EnhanceHomeTab from "./enhanceHome/EnhanceHomeTab";
import EmailPhoneVerifyTab from "./emailPhoneVerification/EmailPhoneVerifyTab";
import DncScrubbing from "./doNotCallSrcubing/DncScrubbing";
import SkipTracing from "./skipTracing/SkipTracing";
import { BasicTabs } from "./commons/BasicTabs";
import { Divider } from "@material-ui/core";
import { DataCleanContext, DataCleanProvider } from "../StateProvider";
import { getCarrierLookupCreditSettingsApi } from "../../../api/contactApi";
import ActionType from "../ActionTypes";
import { CoreButton } from "./commons/CoreButton";
import GlobalModal from "../../globals/Modal/GlobalModal";
import ExportedFiles from "../ExportedFiles";
import { CARRIER_INFO_TYPES } from "../../../constants/CoreConstants";
import { changeQueryParams } from "../../../helpers/changeQueryParams";
import { getTabIndexByParams, getTabPath } from "../../../helpers/tabPaths";
import "./EnhanceDataStyles.css";
import Utils from "../../../helpers/Utils";
import {StyledTab} from "./EnhanceDataStyles";

const EnhanceDataHomePageApp = () => {
  const { state, dispatch } = useContext(DataCleanContext);
  const [value, setValue] = React.useState(0);
  const [openExportDrawer, setOpenExportDrawer] = React.useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("tab");

  useEffect(() => {
    if (window.setActiveSidebar) {
      window.setActiveSidebar("clean-data");
    }
  }, []);

  useEffect(() => {
    setValue(getTabIndexByParams(param));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: ActionType.RESET_FILE_UPLOAD_STATE,
    });

    dispatch({
      type: ActionType.UPDATE_STATE,
      payload: {
        carrierInfos: {
          emailVerificationType: null,
          phoneVerificationType: null,
          doNotCall: value === 2 ? CARRIER_INFO_TYPES.doNotCall : null,
          skipTrace: value === 3 ? CARRIER_INFO_TYPES.skipTraceCurrentOwner : null,
        },
      },
    });
  }, [value, dispatch]);

  useEffect(() => {
    if (!state.carrierLookupPrices) {
      getCarrierLookupCreditSettingsApi()
        .then((response) => {
          if (response.data && response.data.status === "success") {
            if (response.data && response.data.data) {
              let lookUpPrice = {};
              response.data.data.forEach((eachData) => {
                lookUpPrice[eachData.type] = eachData.value;
              });
              dispatch({
                type: ActionType.UPDATE_STATE,
                payload: {
                  carrierLookupPrices: response.data.data,
                  carrierLookupPricesObject: lookUpPrice,
                },
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const handleChange = (event, newValue) => {
    changeQueryParams(getTabPath(newValue));
    setValue(newValue);
  };

  return (
    <div className={"de_root_wrapper"}>
      <Paper elevation={0} style={{ padding: "16px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <BasicTabs value={value} onChange={handleChange}>
            <StyledTab label='Home' value={0} />
            {state.enabledServices.carrierLookup && <StyledTab label='Email & Phone Verification' value={1} />}
            {state.enabledServices.dnc && <StyledTab label='Do-Not-Call(DNC) Scrub' value={2} />}
            {state.enabledServices.skipTrace && <StyledTab label='SkipTracing' value={3} />}
          </BasicTabs>
          <CoreButton
            variant={"contained"}
            color={"#000000"}
            backgroundColor={"#00ff91"}
            style={{ marginLeft: "auto" }}
            onClick={() => setOpenExportDrawer((open) => !open)}
          >
            Exported files
          </CoreButton>
        </Box>
        <Divider light />

        {value === 0 && <EnhanceHomeTab />}
        {value === 1 && <EmailPhoneVerifyTab />}
        {value === 2 && <DncScrubbing />}
        {value === 3 && <SkipTracing />}
      </Paper>

      <GlobalModal
        open={openExportDrawer}
        onClose={() => setOpenExportDrawer(false)}
        title={"Exported Files"}
        hideFooter={true}
      >
        <ExportedFiles />
      </GlobalModal>
    </div>
  );
};

const EnhanceDataHomePage = () => {
  useEffect(() => {
    document.title = `Contacts | ${Utils.getAccountData("AuthUserAgencyName")}`;
  }, []);
  
  return (
    <DataCleanProvider>
      <EnhanceDataHomePageApp />
    </DataCleanProvider>
  );
};

export default EnhanceDataHomePage;
