import React, { useEffect, useState } from "react";
import { Button, styled, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getContactRelationshipList } from "../../../../api/contactApi";
import CustomTitleV2 from "../common/customTitleV2/CustomTitleV2";
import AddOrEditRelatedContactsModal from "./AddOrEditRelatedContactsModal";
import EachRelatedItem from "./EachRelatedItem";
import { Skeleton } from "@material-ui/lab";

const RelatedContactsWrapper = styled("div")(({ theme }) => ({
  "& .relatedContactHeader": {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginBottom: 8,

    "& .linkButton": {
      textTransform: "inherit",
      color: "#316aff",
      "&:focus": { backgroundColor: "transparent !important" },
    },
  },
  "& .list": {
    display: "flex",
    flexDirection: "column",
    gap: 12,

    "& .item": {
      padding: 8,
      borderRadius: 6,

      "&:hover": {
        backgroundColor: "rgba(60,126,243,.1)",
      },

      "& .itemContent": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .itemText": {
        display: "flex",
        flexDirection: "column",
        gap: 1,
      },

      "& .iconButton": {
        backgroundColor: "white !important",
        borderRadius: 8,
        border: "1px solid lightGray",
        "&:focus": { backgroundColor: "white !important" },
      },

      "& .actionListHover": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 8,
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
        marginTop: 8,
        paddingTop: 16,
        paddingBottom: 8,

        "& .actionItem": {
          backgroundColor: "#316aff",
          "& svg": {
            fill: "white",
            color: "white",
            height: 30,
            width: 30,
            "& path": {
              fill: "white",
            },
          },
        },
      },
    },
  },
  "& .loadingSkeleton": {
    display: "flex",
    flexDirection: "column",
    gap: 12,

    "& .item": {
      borderRadius: 6,
    },
  },
}));

const RelatedContacts = () => {
  const [relatedContacts, setRelatedContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    fetchContactRelationship();
  }, []);

  const contact = useSelector((state) => state.contactReducer.contact);

  const fetchContactRelationship = async () => {
    setIsLoading(true);
    try {
      const response = await getContactRelationshipList({ contactId: contact.id });
      if (response && response.success && response.data[0]) {
        setRelatedContacts(response.data);
      } else {
        setRelatedContacts([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderRelatedContacts = () => {
    if (isLoading) {
      return (
        <div className='loadingSkeleton'>
          <Skeleton className='item' variant='rect' width={"100%"} height={55} />
          <Skeleton className='item' variant='rect' width={"100%"} height={55} />
          <Skeleton className='item' variant='rect' width={"100%"} height={55} />
        </div>
      );
    } else if (relatedContacts && relatedContacts[0] && !isLoading) {
      return relatedContacts.map((item) => <EachRelatedItem key={item.contactRelationshipId} item={item} setRelatedContacts={setRelatedContacts} />);
    } else if (!relatedContacts[0] && !isLoading) {
      return <Typography variant='body2'>No related contacts found</Typography>;
    }
  };
  return (
    <RelatedContactsWrapper>
      <div className='relatedContactHeader'>
        <CustomTitleV2 title={"RELATED CONTACTS"} sx={{ flex: 1 }} />

        <Button variant='text' color='primary' className='linkButton' onClick={() => setOpenModal(true)}>
          Link a Contact
        </Button>
      </div>

      <div className='list'>{renderRelatedContacts()}</div>

      {openModal && <AddOrEditRelatedContactsModal open={openModal} onClose={() => setOpenModal(false)} setRelatedContacts={setRelatedContacts} />}
    </RelatedContactsWrapper>
  );
};

export default RelatedContacts;
