import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EachTableItem from "./EachTableItem";
import { CustomScrollbar } from "../CustomScrollbar";
import ImportedGoogleSheetTableSkeleton from "./ImportedGoogleSheetTableSkeleton";
import DynamicEmptyPage from "../../common/DynamicEmptyPage";
import importSpreadsheetContactService from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: {
    background: "#fafafa",
    zIndex: 3,
    position: "sticky",
    top: 0,
  },
  tableHeader: {
    fontSize: "16px",
    fontWeight: 600,
    whiteSpace: "nowrap",
    color: "var( --deep_dark_blue)",
    padding: "10px",
  },
  tableBodyHeight: {
    height: `calc(100vh - 300px) !important`,
    overflowY: "auto",
    marginTop: "16px",
  },
  topbarNewProductButton: {
    fontWeight: 600,
    fontSize: "16px",
    background: "#346fef",
    color: "white",
    "&:focus,&:hover": {
      background: "#346fef",
    },
  },
  resyncButton: {
    backgroundColor: "var(--light_blue)",
    color: "#fff",
    textTransform: "capitalize",
    marginRight: "12px",
    "&:focus,&:hover": {
      backgroundColor: "var(--light_blue)",
    },
  },
  pagination: {
    backgroundColor: "#FFFFFF",
    height: "52px",
    marginBottom: "20px",
    "& .MuiTablePagination-actions": {
      display: "flex",
      "& button": {
        padding: "2px",
        "&:focus,&:hover": {
          background: "transparent",
        },
      },
    },
  },
});

const ImportGoogleSheetTable = ({ refresh }) => {
  const classes = useStyles();
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [total, setTotal] = useState(0);
  const [trigger, setTrigger] = useState(1);

  useEffect(() => {
    getImportedFileLists();
  }, [trigger, refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const getImportedFileLists = async () => {
    setLoading(true);
    try {
      const resp = await importSpreadsheetContactService.getUserConnectFiles({ page: page + 1, limit: rowsPerPage });
      if (resp.success && resp.data && resp.data[0]) {
        setdata(resp.data);
        if (page === 0) {
          setTotal(resp.total_count);
        }
        window.showNotification("SUCCESS", resp.message);
      } else {
        setdata([]);
        window.showNotification("ERROR", resp.message);
      }
    } catch (error) {
      window.showNotification("ERROR", "Something went wrong! Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTrigger(trigger + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setTrigger(trigger + 1);
  };

  return (
    <div>
      {loading ? (
        <ImportedGoogleSheetTableSkeleton />
      ) : !data[0] ? (
        <DynamicEmptyPage title="You haven't uploaded any google sheet yet!" />
      ) : (
        <div>
          <CustomScrollbar className={`${classes.tableBodyHeight}`}>
            <TableContainer component={"span"}>
              <Table className={classes.table} aria-label='simple table'>
                <TableHead className={classes.tableHead}>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>SHEET NAME</TableCell>
                    <TableCell className={classes.tableHeader}>WORKSHEET NAME</TableCell>
                    <TableCell className={classes.tableHeader}>TOTAL CONTACT UPLOADED</TableCell>
                    <TableCell className={classes.tableHeader}>STATUS</TableCell>
                    <TableCell className={classes.tableHeader}>PROCESSING STATUS</TableCell>
                    <TableCell className={classes.tableHeader}>LAST PROCESSED DATE</TableCell>

                    <TableCell className={classes.tableHeader}>ACTIONS</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <EachTableItem key={item.id} item={item} trigger={trigger} setTrigger={setTrigger} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CustomScrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className={classes.pagination}
          />
        </div>
      )}
    </div>
  );
};

export default ImportGoogleSheetTable;
