import React, {useContext, useState} from "react";
import {Box, Checkbox, FormControl, FormControlLabel, FormHelperText, Radio, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useForm, Controller } from "react-hook-form";
import { CoreButton } from "../commons/CoreButton";
import GooglePlaceInput from "../commons/GooglePlaceInput";
import { isError, showErrorMessage } from "../../../../helpers/FormErrors";
import { EachInputBox, Stack } from "../../CommonCss";
import {AGENCY_CARRIER_LOOKUP_CREDIT_TYPE, CARRIER_INFO_TYPES} from "../../../../constants/CoreConstants";
import numberToDollar from "../../../../helpers/numberToDollar";
import {DataCleanContext} from "../../StateProvider";

const SkipTracingForm = ({ submitHandler, hasBack, onBack }) => {
  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    values: {
      address: "",
      find: "owner",
      checkDNC: false,
    },
  });
  const [address, setAddress] = useState();
    const { state } = useContext(DataCleanContext);

  return (
    <Box mb={2} p={2} component='form' onSubmit={handleSubmit(submitHandler)}>
      <Stack>
        <EachInputBox style={{ minWidth: "45%" }}>
          <Typography variant='body1'>Search a single address</Typography>
          <Box
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "flex-start",
              marginBottom: "16px",
                marginTop: "10px"
            }}
          >
            <Box style={{flex:1}}>
              <Controller
                name={"address"}
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Address is required",
                  },
                }}
                render={({ field }) => (
                  <GooglePlaceInput
                    value={address}
                    onChange={(event) => {
                      setAddress(event);
                      if (event.value?.place_id) {
                        setValue("address", event.value.place_id);
                      }
                    }}
                    error={isError(errors, "address")}
                    helperText={showErrorMessage(errors, "address")}
                  />
                )}
              />
              <FormHelperText>
                **Please provide an address with a house number, road number, city, and state.
              </FormHelperText>
            </Box>

            <CoreButton type='submit' variant={"contained"} color={"primary"} style={{ marginTop: "4px" }}>
              Submit
            </CoreButton>
          </Box>
        </EachInputBox>
        {hasBack && (
          <CoreButton
            variant={"contained"}
            color={"primary"}
            backgroundColor={"#6070ed"}
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "auto", marginTop: "4px" }}
            onClick={onBack}
          >
            Back
          </CoreButton>
        )}
      </Stack>

      <FormControl style={{ flexDirection: "row" }}>
        <Controller
          name='find'
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              value='owner'
              checked={field.value === "owner"}
              control={<Radio color={"primary"} />}
              label='Find current owner'
            />
          )}
        />

        <Controller
          name='find'
          control={control}
          render={({ field }) => (
            <FormControlLabel
              {...field}
              value='resident'
              checked={field.value === "resident"}
              control={<Radio color={"primary"} />}
              label='Find current resident'
            />
          )}
        />

        {/*<FormControlLabel*/}
        {/*  control={<Checkbox color={"primary"} {...register("checkDNC")} />}*/}
        {/*  label='Check DNC'*/}
        {/*/>*/}
      </FormControl>
        <Box style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Box component="div" style={{ marginRight: 20 }}>
                <Typography variant="h6" style={{ fontWeight: 600 }}>Additional options:</Typography>
            </Box>
            <Box sx={{ flexDirection: "row", gap: 10 }}>
                {
                    state.enabledServices.dnc &&
                    <Controller
                        control={control}
                        name="checkDNC"
                        render={({ field }) => (
                            <FormControlLabel
                                {...field}
                                onChange={(event) => {
                                    field.onChange(event);
                                }}
                                value={CARRIER_INFO_TYPES.doNotCall}
                                control={<Checkbox color="primary" />}
                                label={`Check DNC Status (${numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.DNC]) ?? "x.xx"})`}
                            />
                        )}
                    />
                }

                {
                    state.enabledServices.carrierLookup &&
                    <Controller
                        control={control}
                        name="checkPhoneNumber"
                        render={({ field }) => (
                            <FormControlLabel
                                {...field}
                                onChange={(event) => {
                                    field.onChange(event);
                                }}
                                value={CARRIER_INFO_TYPES.phoneVerificationType}
                                control={<Checkbox color="primary" />}
                                label={`Validate Phone Numbers (${numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.CARRIER]) ?? "x.xx"})`}
                            />
                        )}
                    />
                }

                {
                    state.enabledServices.carrierLookup &&
                    <Controller
                        control={control}
                        name="checkEmailAddress"
                        render={({ field }) => (
                            <FormControlLabel
                                {...field}
                                onChange={(event) => {
                                    field.onChange(event);
                                }}
                                value={CARRIER_INFO_TYPES.emailVerificationType}
                                control={<Checkbox color="primary" />}
                                label={`Validate Email Address (${numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.VERIFY_EMAIL]) ?? "x.xx"})`}
                            />
                        )}
                    />
                }
            </Box>
        </Box>
    </Box>
  );
};

export default SkipTracingForm;
