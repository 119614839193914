import { Button, TableCell, TableRow, styled } from "@material-ui/core";
import React, { useState } from "react";
import StatusChip from "./StatusChip";
import { LinkIcon, RefreshIcon } from "../../../constants/IconList";
import ImportSpreadsheetModal from "./ImportSpreadSheetModal";
import mapCSVImportHelper from "../mapCSVImportHelper";
import { useDispatch } from "react-redux";
import {
  updateDetails,
  updateMappedFields,
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import importSpreadsheetContactService from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactService";

export const TableRowWrapper = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  color: theme.palette.text.primary,

  "& .titleWrap": {
    display: "flex",
    alignItems: "center",
    gap: 8,
    color: "var( --dark_blue)",
    fontSize: "15px",
  },

  "& .folderWrap": {
    display: "flex",
    flexWrap: "wrap",
    gap: 4,
    marginTop: 2,

    "& .folderItem": {
      display: "flex",
      alignItems: "center",
      gap: 8,
      // border: "1px solid #D0D5DD",
      borderRadius: "4px",
      padding: "2px 4px",
      marginRight: 10,

      "& .folderName": {
        fontSize: "12px !important",
        color: "#475467",
        lineHeight: 1,
      },
    },
  },

  "& .iconFavorite": {
    fontSize: 16,
    color: "gray",
    cursor: "pointer",
    "&.active": {
      color: "#f79009",
    },
  },

  "& .MuiTableCell-root": {
    fontSize: "15px",
    fontWeight: 500,
    padding: "10px",
  },

  "& .MuiIconButton-root": {
    "&:focus": {
      background: "transparent",
    },
  },
}));

const EachTableItem = ({ item, trigger, setTrigger }) => {
  const [openImportSpreadsheetModal, setOpenImportSpreadsheetModal] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const dispatch = useDispatch();

  const handleLinkOpen = (link) => {
    window.open(link, "_blank");
  };

  const worksheetName = () => {
    if (item.worksheet_info) {
      const parsedData = JSON.parse(item.worksheet_info);
      return parsedData.title;
    } else {
      return "";
    }
  };

  const getMappedFields = () => {
    const transformed = {};

    if (item.mapping) {
      const parsedData = JSON.parse(item.mapping);

      Object.entries(parsedData).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          value.forEach((pin) => {
            transformed[pin] = key;
          });
        } else {
          transformed[value] = key;
        }
      });
    }

    if (item.custom_mapping) {
      const parsedData = JSON.parse(item.custom_mapping);
      Object.entries(parsedData).forEach(([key, value]) => {
        transformed[value] = `custom_${key}`;
      });
    }

    return transformed;
  };

  const handleResync = async () => {
    setSyncing(true);
    try {
      const mappedField = getMappedFields();

      const reFreshTokenResponse = await importSpreadsheetContactService.processRefreshToken({ email: item.email });
      if (reFreshTokenResponse.success) {
        const resyncResponse = await importSpreadsheetContactService.readSpreadsheet({
          email: item.email,
          spreadsheetId: item.provider_file_id,
          worksheetName: worksheetName(),
        });

        if (resyncResponse.success) {
          const { mappedRows, unmappedRows } = mapCSVImportHelper.mapData(resyncResponse.data);

          let mappedData = [...mappedRows];
          const mappedFieldsSet = new Set(Object.keys(mappedField).map(Number));

          const unmappedData = unmappedRows.filter((unmappedRow) => {
            if (mappedFieldsSet.has(unmappedRow.index)) {
              mappedData.push(unmappedRow);
              return false;
            }
            return true;
          });

          dispatch(
            updateMappedFields({
              mappedRows: mappedData,
              unmappedRows: unmappedData,
              mappedFields: mappedField,
            })
          );
          setOpenImportSpreadsheetModal(true);
          const additionalInfo = JSON.parse(item.additional_infos);

          dispatch(
            updateDetails({
              leadSourceId: additionalInfo.source_id ? additionalInfo.source_id : "",
              pipelineId: additionalInfo.pipeline_id ? additionalInfo.pipeline_id : "",
              stageId: additionalInfo.stage_id ? additionalInfo.stage_id : "",
              tagIds: additionalInfo.tag_ids?.[0] ? additionalInfo.tag_ids : [""],
              ignoreFirstRow: additionalInfo.ignore_first_row,
              userConsent: additionalInfo.user_consent,
              spreadSheetName: item.provider_file_name ? item.provider_file_name : "",
              spreadSheetId: item.id,
            })
          );
        } else {
          window.showNotification("error", resyncResponse.message);
        }
      } else {
        window.showNotification("error", reFreshTokenResponse.message);
      }
    } catch (error) {
      window.showNotification("error", "Something went wrong! Please try again.");
    } finally {
      setSyncing(false);
    }
  };

  return (
    <TableRowWrapper>
      <TableCell>
        <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
          <div className='titleWrap'>{item.provider_file_name || ""}</div>
          <div onClick={() => handleLinkOpen(item.provider_file_url)}>
            <LinkIcon />
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className='titleWrap'> {worksheetName()}</div>
      </TableCell>
      <TableCell>
        <div className='titleWrap'>{item.total_contact_uploaded || 0}</div>
      </TableCell>
      <TableCell>
        <div className='titleWrap'>
          <StatusChip status={item.status} />
        </div>
      </TableCell>
      <TableCell>
        <StatusChip status={item.processing_status} />
      </TableCell>
      <TableCell>
        <div className='titleWrap'>{item.last_processed_date}</div>
      </TableCell>
      <TableCell>
        <Button
          onClick={handleResync}
          disableElevation
          disabled={syncing}
          style={{ minWidth: "112px", textTransform: "capitalize" }}
          variant='contained'
          startIcon={<RefreshIcon fillColor='#FFF' />}
        >
          {syncing ? "Syncing" : "Resync"}
        </Button>
        {openImportSpreadsheetModal && (
          <ImportSpreadsheetModal
            open={openImportSpreadsheetModal}
            onClose={() => setOpenImportSpreadsheetModal(false)}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        )}
      </TableCell>
    </TableRowWrapper>
  );
};

export default EachTableItem;
