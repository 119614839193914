import {PHONE_STATE, US_STATE_LIST} from "./coreConstant";
import {LOOKUP_FIELD_VALUE_CHECK, LOOKUP_STATUS, STATUS_CHECK} from "../common/carrierLookup/Constants";

// Function to store data in local storage
export function saveToLocalStorage(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

// Function to retrieve data from local storage
export function getFromLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
}

export const getState = (value) => US_STATE_LIST.find((state) => state.value === value)?.label;

export const getStateObj = (value) => US_STATE_LIST.find((state) => state.value === value);

export const toLowerCase = (value) => {
  try{
    return value.toLowerCase();
  }catch (e){
    return value;
  }
}

export const setNumberType = (value) => {
  try{
    let list = [PHONE_STATE.mobile, PHONE_STATE.landline, PHONE_STATE.voip, PHONE_STATE.unknown];

    if (value && typeof value === "string"){
      value = value.toLowerCase();
    }

    if (value && list.includes(value)){
      return value
    }

    return PHONE_STATE.unknown;
  }catch (er){
    return PHONE_STATE.unknown;
  }
}

export function formatPhoneNumber(phoneNumberString) {
  if (!phoneNumberString){
    return 'N/A';
  }

  if (phoneNumberString.length < 11){
    phoneNumberString = '1' + phoneNumberString;
  }
  return phoneNumberString.replace(/\D+/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
}

export const showValidator = (lookupType, contact, additionalContacts) => {
  try {
    if (
        contact &&
        (
            !contact.contact_additional_informations ||
            contact.contact_additional_informations[STATUS_CHECK[lookupType]] === LOOKUP_STATUS.NOT_VERIFIED
        )
    ) {
      return true;
    }

    if (additionalContacts){
      for (let i = 0;i<additionalContacts.length;i++){
        if (
            additionalContacts[i][LOOKUP_FIELD_VALUE_CHECK[lookupType]] &&
            (
              additionalContacts[i][STATUS_CHECK[lookupType]] === null ||
              additionalContacts[i][STATUS_CHECK[lookupType]] === undefined ||
              additionalContacts[i][STATUS_CHECK[lookupType]] === LOOKUP_STATUS.NOT_VERIFIED
            )
        ){
          return true;
        }
      }
    }

    return false;
  } catch (err) {
    console.log("Err? ", err);
    return true;
  }
};


export const cleanNumber = (number) => {
  try{
    if(isNaN(number)){
      return "x.xx";
    }

    if (typeof number === "string") {
      number = parseFloat(number);
    }

    const parts = number.toString().split(".");
    if (parts.length === 1) {
      return number.toFixed(2).replace(/\.0*$/, "") + ".00";
    }
    const decimalPart = parts[1].length < 2 ? parts[1] + "0" : parts[1];
    return `${parts[0]}.${decimalPart}`;
  }catch(err){
    return "x.xx";
  }
}

export const toRegular = (text) => {
  try{
    const modText = text.replace(/([A-Z])/g, " $1");
    return modText.charAt(0).toUpperCase() + modText.slice(1);
  }catch (err){
    return text;
  }
}

export const padZero = (value) => {
  return value < 10 ? `0${value}` : value;
}

export const toHuman = (text) => {
  if (typeof text === "boolean"){
    return text === true ? "Yes" : "No";
  }

  return text;
}

export const isDeleteAble = (object, which) => {
  if (which === "email"){
    return !!object["number"];
  }

  if (which === "number"){
    return !!object["email"];
  }
};