import React, {useState} from "react";
import {Button} from "@material-ui/core";
import {LeftSideV2Styles} from "../LeftSideV2Styles";
import {removeBulkContact} from "../../../../api/contactApi";
import {connect} from "react-redux";
import NewConfirmAlert from "../../../common/new-alert/NewConfirmAlert";
import {useHistory} from "react-router-dom";

const DeleteLead = (props) => {
    const { closeButton } = LeftSideV2Styles();
    const history = useHistory();
    const [deleting, setDeleting] = useState(false);

    const removeBulkContacts = async () => {

        NewConfirmAlert({
            title: 'Confirm to delete',
            description: 'Are you sure to delete contact?',
            cancelText: "Cancel",
            submitText: "Yes, Delete!",
            onSubmit: async () => {
                setDeleting(true);
                try{
                    let response = await removeBulkContact({ 'ids': [props.contact.id] });
                    if (response.data.success) {
                        window.showNotification('SUCCESS', 'Contact deleted successfully')
                        history.push("/contacts");
                    } else {
                        window.showNotification('ERROR', 'Something went wrong')
                    }
                }catch (err){
                    window.showNotification('ERROR', 'Something went wrong')
                }
                setDeleting(false);
            }
        })

    }

    return(
        <Button className={closeButton} variant="contained" onClick={removeBulkContacts} disabled={deleting} fullWidth>{ deleting ? 'Deleting Contact...' : 'Delete Contact' }</Button>
    );
}


const mapStateToProps = (state) => {
    return {
        contact: state.contactReducer.contact,
    };
};

export default connect(mapStateToProps)(DeleteLead);