import React, { useEffect, useState } from "react";
import { campaignFileAssign, getAllCampaignWithVN } from "../../../api/contactApi.js";
import GlobalModal from "../../globals/Modal/GlobalModal.js";
import If from "if-else-react";
import { Add } from "@material-ui/icons";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import AddFileListSkeleton from "./AddFileListSkeleton.js";
import BasicInput from "../../../components/contact/middleSide/custom/custom/BasicInput";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";

const EachCampaign = (props) => {
  return (
    <li
      className='addToFile__li'
      style={{ background: "white", marginBottom: "10px", borderRadius: "5px", paddingLeft: "10px" }}
    >
      <FormControl component='fieldset'>
        <RadioGroup aria-label='dataTitle' name='dataTitle' value={props.selectedCampaign}>
          <FormControlLabel
            value={props.data.id}
            control={<Radio />}
            onChange={props.selectCampaign}
            label={props.data.title}
          />
          <small style={{ marginLeft: "24px", marginBottom: "10px" }}>{props.data.virtual_number}</small>
        </RadioGroup>
      </FormControl>
    </li>
  );
};

const ModalAddFileToCampaignForm = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(false);
  const [selectedTag, setSelectedTag] = useState(false);
  const [submitingContact, setSubmitingContact] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [allCampaign, setAllCampaign] = useState([]);

  useEffect(() => {
    loadRunningCampaign();
  }, []);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const showErrorMsg = (msg) => {
    setError(true);
    setErrorMsg(msg);

    setTimeout(() => {
      setError(false);
      setErrorMsg("");
    }, 5000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (submitingContact) {
      return;
    }

    if (selectedCampaign === false) {
      showErrorMsg("Please select a campaign");
      return;
    }

    var params = {
      file_id: props.file_id,
      tag_id: selectedTag,
      campaign_id: selectedCampaign,
    };

    setSubmitingContact(true);

    campaignFileAssign(params).then((res) => {
      setSubmitingContact(false);

      if (res.data.status === "error") {
        showErrorMsg(res.data.message);
        window.showNotification("WARNING", "Validation error: " + res.data.message);
      } else {
        window.showNotification("SUCCESS", "Tag assigned successfully!");
        toggle();
      }
    });
  };

  const loadRunningCampaign = () => {
    setLoading(true);
    getAllCampaignWithVN({}).then((res) => {
      setAllCampaign(res.data.data.campaigns);
      setCampaigns(res.data.data.campaigns);
      setLoading(false);
    });
  };

  const toggle = () => {
    if (props.modalToggle) {
      props.modalToggle();
    }
    setIsOpen(!isOpen);
  };

  const handleRadio = (campaignId, tagId) => {
    setSelectedCampaign(campaignId);
    setSelectedTag(tagId);
  };

  const changeSearch = (e) => {
    var searchKey = e.target.value.toUpperCase();
    const campainData = allCampaign.filter((data) => {
      if (data.title.toUpperCase().indexOf(searchKey) > -1) {
        return data;
      }
    });
    setCampaigns(campainData);
  };

  let runningList = campaigns.map((data, index) => {
    return (
      <EachCampaign
        selectedCampaign={selectedCampaign}
        selectCampaign={() => handleRadio(data.id, data.tag_id)}
        data={data}
        key={index}
      />
    );
  });

  return (
    <GlobalModal
      open={isOpen}
      title={props.title}
      onClose={toggle}
      onSubmit={handleSubmit}
      buttonText={"Add to campaign"}
      buttonIcon={<Add />}
    >
      <If condition={loading}>
        <AddFileListSkeleton />
      </If>

      <If condition={!loading}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='form-group m-form__group'>
              <div className='col-md-12'>
                <div className='form-group m-form__group'>
                  <p className='text-red m-0' style={{ textAlign: "justify" }}>
                    <b style={{ textDecoration: "underline" }}>Note:</b> If you have already added your file by drip
                    batch mode we suggest you not to add your contact file from this page. Adding a contact file to the
                    campaign from this page means that contact will be added immediately to the campaign and all the
                    actions of the campaign will be executed for those contact according to campaign settings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <If condition={error}>
          <div className='row'>
            <div className='col-md-12'>
              <div className='form-group m-form__group'>
                <div className='col-md-12'>
                  <div className='form-group m-form__group'>
                    <p className='text-red m-0'>{errorMsg}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </If>
        <div className='pb-2'>You can add file to the following campaigns:</div>
        <div>
          {/* <input
                onChange={changeSearch}
                type='text'
                className='form-control m-input customFormControl'
                placeholder='Type and search'
              /> */}
          <BasicInput
            onChange={changeSearch}
            placeholder='Type and search'
            fullWidth
            endAdornment={
              <InputAdornment position='end'>
                <SearchIcon style={{ color: "#656565", marginRight: "10px" }} />
              </InputAdornment>
            }
          />
          {/* <span className='m-input-icon__icon m-input-icon__icon--right'>
                <span>
                  <i className='flaticon-search-1'></i>
                </span>
              </span> */}
        </div>
        <div>
          <ul className='m-nav'>{runningList}</ul>
        </div>
      </If>
    </GlobalModal>
  );
};

export default ModalAddFileToCampaignForm;
