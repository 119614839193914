import React from "react";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useForm } from "react-hook-form";
import { CoreButton } from "../commons/CoreButton";
import { isError, showErrorMessage } from "../../../../helpers/FormErrors";
import { EachInputBox, Stack, StyledInput } from "../../CommonCss";

const DncForm = ({ singleDncSearchHandler, multipleDncSearchHandler, onBackClickHandler }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      phone: "",
    },
  });

  const multipleDncSearch = useForm({ values: { phone: "" } });

  return (
    <Box mb={2} p={2}>
      <Stack mb={3}>
        <EachInputBox style={{ width: "40%", minWidth: "200px" }}>
          <Typography variant='body1'>Check a single phone number</Typography>
          <Box
            component='form'
            onSubmit={handleSubmit(singleDncSearchHandler)}
            style={{
              display: "flex",
              alignItems: "flex-start",
              gap: "10px",
              marginBottom: "16px",
              marginTop: "10px"
            }}
          >
            <StyledInput
              fullWidth
              variant={"outlined"}
              placeholder={"Enter a phone number here..."}
              {...register("phone", {
                required: {
                  value: true,
                  message: "Phone number is required",
                },
                pattern: {
                  value: /^(\+1|1)?\d{10}$/,
                  message: "Invalid phone number",
                },
              })}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              error={isError(errors, "phone")}
              helperText={showErrorMessage(errors, "phone")}
            />
            <CoreButton type='submit' variant={"contained"} color={"primary"} style={{ marginTop: "2px" }}>
              Submit
            </CoreButton>
          </Box>
        </EachInputBox>

        {onBackClickHandler && (
          <CoreButton
            onClick={onBackClickHandler}
            variant='contained'
            color='primary'
            backgroundColor='#6070ed'
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "auto" }}
          >
            Back
          </CoreButton>
        )}
      </Stack>

      <EachInputBox style={{ width: "40%", minWidth: "200px" }}>
        <Typography variant='body1'>Check multiple phone numbers</Typography>
        <Box
          component='form'
          onSubmit={multipleDncSearch.handleSubmit(multipleDncSearchHandler)}
          style={{
            display: "flex",
            alignItems: "flex-end",
            gap: "10px",
            marginBottom: "16px",
              marginTop: "10px"
          }}
        >
          <StyledInput
            multiline
            fullWidth
            variant={"outlined"}
            size='small'
            minRows={3}
            maxRows={4}
            placeholder={`Copy/Paste your phone numbers here.\n1 phone number per line.`}
            {...multipleDncSearch.register("phone", {
              required: {
                value: true,
                message: "Phone numbers are required",
              },
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            error={isError(multipleDncSearch.errors, "phone")}
            helperText={showErrorMessage(multipleDncSearch.errors, "phone")}
          />
          <CoreButton type='submit' variant={"contained"} color={"primary"} style={{ marginTop: "2px" }}>
            Submit
          </CoreButton>
        </Box>
      </EachInputBox>
    </Box>
  );
};

export default DncForm;
