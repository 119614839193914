import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  Typography,
} from "@material-ui/core";
import { CoreButton } from "../commons/CoreButton";
import GooglePlaceInput from "../commons/GooglePlaceInput";
import { useForm, Controller } from "react-hook-form";
import { AGENCY_CARRIER_LOOKUP_CREDIT_TYPE } from "../../../../constants/CoreConstants";
import { isError, showErrorMessage } from "../../../../helpers/FormErrors";
import { EachInputBox, StyledInput } from "../../CommonCss";
import numberToDollar from "../../../../helpers/numberToDollar";

const SingleSearch = ({ state, submitHandler }) => {
  const [address, setAddress] = useState(null);
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
    clearErrors,
    setValue,
  } = useForm({
    values: {
      checkEmailAddress: false,
      checkPhoneNumber: false,
      checkDNC: false,
      skipTrace: false,
      find: "owner",
      email: "",
      phone: "",
      address: "",
    },
  });

  watch(["skipTrace", "checkEmailAddress", "checkPhoneNumber"]);

  const handleAddress = (addressObject) => {
    clearErrors("address");
    setValue("address", addressObject.place_id);
  };

  return (
    <Box component='form' p={2} onSubmit={handleSubmit(submitHandler)}>
      <Box>
        <Typography variant='h6'>What would you like to do?</Typography>
        <Typography variant='caption'>(Select all that apply)</Typography>
      </Box>
      <FormControl>
          {
              state.enabledServices.carrierLookup &&
              <Controller
                  control={control}
                  name='checkEmailAddress'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          onChange={(event) => {
                              field.onChange(event);
                              clearErrors("email");
                          }}
                          control={<Checkbox color={"primary"} />}
                          label={`Validate Email Address (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.VERIFY_EMAIL]) ??
                              "x.xx"
                          })`}
                      />
                  )}
              />
          }

          {
              state.enabledServices.carrierLookup &&
              <Controller
                  control={control}
                  name='checkPhoneNumber'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          onChange={(event) => {
                              field.onChange(event);
                              clearErrors("phone");
                          }}
                          control={<Checkbox color={"primary"} />}
                          label={`Validate Phone Number (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.CARRIER]) ?? "x.xx"
                          })`}
                      />
                  )}
              />
          }

          {
              state.enabledServices.dnc &&
              <Controller
                  control={control}
                  name='checkDNC'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          onChange={(event) => {
                              field.onChange(event);
                              clearErrors("phone");
                          }}
                          control={<Checkbox color={"primary"} />}
                          label={`Check Do-Not-Call(DNC) status (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.DNC]) ?? "x.xx"
                          })`}
                      />
                  )}
              />
          }

          {
              state.enabledServices.skipTrace &&
              <Controller
                  control={control}
                  name='skipTrace'
                  render={({ field }) => (
                      <FormControlLabel
                          {...field}
                          onChange={(event) => {
                              field.onChange(event);
                              clearErrors("address");
                          }}
                          control={<Checkbox color={"primary"} />}
                          label={`Skip Trace (${
                              numberToDollar(state.carrierLookupPricesObject[AGENCY_CARRIER_LOOKUP_CREDIT_TYPE.SKIP_TRACING]) ??
                              "x.xx"
                          })`}
                      />
                  )}
              />
          }
      </FormControl>
      {getValues("skipTrace") && (
        <Box px={4}>
          <FormControl style={{ flexDirection: "row" }}>
            <Controller
              control={control}
              name='find'
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  value='owner'
                  checked={field.value === "owner"}
                  control={<Radio color='primary' />}
                  label='Find current owner'
                />
              )}
            />
            <Controller
              control={control}
              name='find'
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  value='resident'
                  checked={field.value === "resident"}
                  control={<Radio color='primary' />}
                  label='Find current resident'
                />
              )}
            />
          </FormControl>
        </Box>
      )}

      <EachInputBox mt={2}>
        <Box mb={1}>
          <Typography variant='body1'>Single Search</Typography>
          <Typography variant='caption'>Complete the fields below and click submit</Typography>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledInput
                placeholder={"Email address"}
                variant='outlined'
                fullWidth
                {...register("email", {
                  required: {
                    value: getValues("checkEmailAddress"),
                    message: "Email is required",
                  },
                  pattern: {
                    // eslint-disable-next-line no-useless-escape
                    value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                    message: "Invalid Email",
                  },
                  disabled: !getValues("checkEmailAddress"),
                })}
                error={isError(errors, "email")}
                helperText={showErrorMessage(errors, "email")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledInput
                placeholder={"Phone Number"}
                variant='outlined'
                fullWidth
                type='tel'
                {...register("phone", {
                  required: {
                    value: getValues("checkPhoneNumber") || getValues("checkDNC"),
                    message: "Phone number is required",
                  },
                  pattern: {
                    value: /^(\+1|1)?\d{10}$/,
                    message: "Invalid Phone Number",
                  },
                  disabled: getValues("checkPhoneNumber") === false && getValues("checkDNC") === false,
                })}
                error={isError(errors, "phone")}
                helperText={showErrorMessage(errors, "phone")}
              />
            </Grid>
            <Grid item xs={12}>
              <div>
                <Controller
                  name='address'
                  control={control}
                  rules={{
                    required: {
                      value: getValues("skipTrace"),
                      message: "Address is required",
                    },
                  }}
                  render={({ field }) => {
                    return (
                      <GooglePlaceInput
                        value={address}
                        disabled={!getValues("skipTrace")}
                        onChange={(event) => {
                          setAddress(event);
                          handleAddress(event.value);
                        }}
                        error={isError(errors, "address")}
                        helperText={showErrorMessage(errors, "address")}
                      />
                    );
                  }}
                />
                <FormHelperText>
                  **Please provide an address with a house number, road number, city, and state.
                </FormHelperText>
              </div>
            </Grid>
            <Grid item xs={12}>
              <CoreButton type='submit' variant={"contained"} color={"primary"} style={{ float: "right" }}>
                Submit
              </CoreButton>
            </Grid>
          </Grid>
        </Box>
      </EachInputBox>
    </Box>
  );
};

export default SingleSearch;
