import React from 'react';
import { Grid} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const CollaboratorSkeleton = () => {
    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <div style={{ marginRight: '16px', paddingLeft: '22px' }}>
                        <Skeleton variant="rounded" width="100%" height={45} />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className="campaigns-single-item-main44 upload-single-item-main2 upload-single-item-main">
                        <ul className="awesome__scroll_bar">
                            {[...Array(10)].map((_, index) => (
                                <Skeleton key={index} variant="rounded" width="100%" height={40} style={{ marginBottom: '20px' }} />
                            ))}
                        </ul>
                    </div>
                </Grid>
            </Grid>
        </form>
    );
};

export default CollaboratorSkeleton;
