import React, { useState, useEffect } from "react";
import { Box, Button, IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import ShareIcon from "@material-ui/icons/Share";
import ShareContactModal from "../../../leftSide/ShareContactModal";
import EditIcon from "../../common/icons/EditIcon";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";
import { ContactsStyles } from "../ContactsStyles";
import { connect } from "react-redux";
import { setContactDetails } from "../../../../../actions/contactAction";
import Utils from "../../../../../helpers/Utils";
import ContactCompany from "./ContactCompany";

const useStyle = makeStyles({
  titleStyle: {
    whiteSpace: "nowrap",
    fontSize: "25px",
    fontWeight: 600,
  },
  customTooltip: {
    fontSize: "16px"
  },
});

const ContactTitle = ({ handleOpen, contacts, setContactDetails }) => {
  const [state, setState] = useState({
    isOpen: false,
    firstNameInvalidError: false,
    firstNameLengthError: false,
    lastNameInvalidError: false,
    lastNameLengthError: false,
    showShareContactModal: false,
    dncStatus: "NOT_VERIFIED",
  });
  const classes = useStyle();

  const {
    flexCenterBetween,
    flexCenter,
    secondaryButton,
    textDarkBlue,
    textWhite,
    alignJustifyCenter,
    alignJustifyCenterV2,
  } = LeftSideV2Styles();
  const { shareIcon, editIcon } = ContactsStyles();

  const padZero = (value) => {
    return value < 10 ? `0${value}` : value;
  };

  useEffect(() => {
    const updateDncStatus = () => {
      const dncStatus = contacts?.contact_additional_informations?.dnc_status || "NOT_VERIFIED";

      setState((prevState) => ({
        ...prevState,
        dncStatus,
      }));
    };

    updateDncStatus();
  }, [contacts]);

  const openShareContactModal = () => {
    setState((prevState) => ({
      ...prevState,
      showShareContactModal: true,
    }));
  };

  const closeShareContactModal = () => {
    setState((prevState) => ({
      ...prevState,
      showShareContactModal: false,
    }));
  };

  // const downloadPopup = () => {
  //   window.globalDownloadPopupData(true, {
  //     requestModule: "CONTACT",
  //     callback: (res) => {
  //     },
  //   });
  // };

  // const importPopup = () => {
  //   window.globalImportEnhancePopupData(true, {
  //     requestModule: "CONTACT",
  //     callback: (res) => {
  //     },
  //   });
  // };

  const enhanceData = () => {
    window.globalEnhancedData(true, {
      id: contacts.id,
      email: contacts.email,
      number: contacts.number,
      address: contacts.address,
      city: contacts.city,
      state: contacts.state,
      zip: contacts.zip,
      contact_additional_informations: contacts.contact_additional_informations,
      requestModule: "CONTACT",
      callback: (res) => {
        let tempContact = { ...contacts };

        const [isValid, inValid, doNotCall, verifiedOk] = ["VALID", "INVALID", "DO_NOT_CALL", "VERIFIED_OK"];
        const [requestDone, requestFailed] = ["REQUEST_DONE", "REQUEST_FAILED"];

        let cleanDataInfos = null;

        if (res.data && Array.isArray(res.data)) {
          cleanDataInfos = res.data.find((validationInfo) => validationInfo.source === "CONTACT");
        }

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${padZero(currentDate.getMonth() + 1)}-${padZero(
          currentDate.getDate()
        )} ${padZero(currentDate.getHours())}:${padZero(currentDate.getMinutes())}:${padZero(
          currentDate.getSeconds()
        )}`;

        if (cleanDataInfos) {
          if (!tempContact.contact_additional_informations) {
            tempContact.contact_additional_informations = {};
          }

          if (res["email-lookup-status"] === requestDone && cleanDataInfos["email-validation-status"]) {
            tempContact.contact_additional_informations.email_validation_status =
              cleanDataInfos["email-validation-status"];

            if (res["email-validation-infos"]) {
              tempContact.contact_additional_informations.email_validation_infos = res["email-validation-infos"];
            } else {
              tempContact.contact_additional_informations.email_validation_infos = {
                state: cleanDataInfos["email-state"],
              };
            }

            tempContact.contact_additional_informations.email_validation_date = formattedDate;
          }

          if (res["carrier-lookup-status"] === requestDone && cleanDataInfos["number-validation-status"]) {
            tempContact.contact_additional_informations.number_validation_status =
              cleanDataInfos["number-validation-status"];

            if (res["number-validation-infos"]) {
              tempContact.contact_additional_informations.number_validation_infos = res["number-validation-infos"];
            } else {
              tempContact.contact_additional_informations.number_validation_infos = {
                state: cleanDataInfos["number-state"],
              };
            }

            tempContact.contact_additional_informations.number_state = cleanDataInfos["number-state"];
            tempContact.contact_additional_informations.number_validation_date = formattedDate;
          }

          if (res["dnc-lookup-status"] === requestDone && cleanDataInfos["dnc-state"]) {
            tempContact.contact_additional_informations.dnc_status = cleanDataInfos["dnc-state"];
            tempContact.contact_additional_informations.dnc_validation_date = formattedDate;
          }

          if (res["skip-trace-lookup-status"] === requestDone) {
            tempContact.contact_additional_informations.skip_trace_date = formattedDate;
            tempContact.contact_additional_informations.skip_trace_status = "TRACED";
          }
        }

        setContactDetails(tempContact);
      },
    });
  };

  const firstName = (contacts?.first_name || "").trim();
  const lastName = (contacts?.last_name || "").trim();

  const userName = `${firstName} ${lastName}`.trim() || "N/A";
  return (
    <Box>
      <Box>
        <Box className={alignJustifyCenter} sx={{ gap: 5, whiteSpace: "no-wrap" }}>
          <Tooltip
            className={classes.customTooltip}
            title={`${contacts?.first_name ?? ""} ${contacts?.last_name ?? ""}`}
          >
            <Typography variant='h4' className={`${textWhite}`}>
              <div className={classes.titleStyle}>{userName ? userName : "No contact name"}</div>
            </Typography>
          </Tooltip>
          {Utils.getAccountData("carrierLookup") && state.dncStatus === "DO_NOT_CALL" && (
            <Box
              sx={{
                backgroundColor: "#F30000",
                color: "#fff",
                fontSize: "10px",
                padding: "2px 10px",
                borderRadius: "10px",
              }}
            >
              DNC
            </Box>
          )}
        </Box>

        <Box
          sx={{ height: "auto !important" }}
          className={`${alignJustifyCenter} contactTitleAction`}
          gridGap={1}
          ml={"auto"}
          position={"absolute"}
          top={10}
          right={10}
        >
          <IconButton className={editIcon} size='small' onClick={openShareContactModal}>
            <ShareIcon style={{ color: "white", fontSize: 16 }} fill='white' />
          </IconButton>
          <IconButton onClick={handleOpen} className={editIcon} size='small'>
            <EditIcon fill='white' />
          </IconButton>
        </Box>
      </Box>

      <Box className={alignJustifyCenter} pt={0.5}>
        <ContactCompany />
      </Box>

      <Box className={alignJustifyCenter} sx={{ gridGap: 5, pt: 1 }}>
        {(Utils.getAccountData("carrierLookup") ||
          Utils.getAccountData("dnc") ||
          Utils.getAccountData("skipTrace")) && (
          <Button
            className={secondaryButton}
            variant='contained'
            size='small'
            disableElevation
            style={{ borderRadius: 25, paddingBlock: 2, backgroundColor: "#00ff91", color: "#000000" }}
            onClick={enhanceData}
          >
            Enhance Data
          </Button>
        )}
      </Box>

      {/*<Box className={alignJustifyCenter} sx={{ gridGap: 5, pt: 1 }}>*/}
      {/*  {(Utils.getAccountData("carrierLookup") ||*/}
      {/*      Utils.getAccountData("dnc") ||*/}
      {/*      Utils.getAccountData("skipTrace")) && (*/}
      {/*      <Button*/}
      {/*          className={secondaryButton}*/}
      {/*          variant='contained'*/}
      {/*          size='small'*/}
      {/*          disableElevation*/}
      {/*          style={{ borderRadius: 25, paddingBlock: 2, backgroundColor: "#00ff91", color: "#000000" }}*/}
      {/*          onClick={importPopup}*/}
      {/*      >*/}
      {/*        Enhance 2222*/}
      {/*      </Button>*/}
      {/*  )}*/}
      {/*</Box>*/}

      {state.showShareContactModal && (
        <ShareContactModal
          title={"Share contact information"}
          isOpen={state.showShareContactModal}
          open={state.showShareContactModal}
          contact_id={contacts?.id}
          closeModal={closeShareContactModal}
          onClose={closeShareContactModal}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.contactReducer.contact,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setContactDetails: (params) => dispatch(setContactDetails(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactTitle);
