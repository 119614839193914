import React, { useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { Backdrop, CircularProgress, Divider, makeStyles } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { BasicTabs } from "../commons/BasicTabs";
import FileReport from "../../report/FileReport";
import TabPanel from "../commons/TabPanel";
import { DataCleanContext } from "../../StateProvider";
import SingleSearch from "./SingleSearch";
import BulkSearch from "./BulkSearch";
import { carrierLookupCleanDataApi } from "../../../../api/cleanDataApi";
import {
  LOOKUP_TYPE_BY_PAYLOAD_CONST,
  LOOKUP_TYPE_CARRIER_LOOKUP,
  LOOKUP_TYPE_DNC_LOOKUP,
  LOOKUP_TYPE_EMAIL_LOOKUP,
  LOOKUP_TYPE_SKIP_TRACING_LOOKUP,
} from "../../../../constants/CoreConstants";
import numberToDollar from "../../../../helpers/numberToDollar";
import Result from "./Result";
import { getAddressLines } from "../../../../helpers/getAddressLines";
import FileUploadHeader from "../emailPhoneVerification/FileUploadHeader";
import FileUpload from "../../fileUpload";
import ActionType from "../../ActionTypes";
import { changeQueryParams } from "../../../../helpers/changeQueryParams";
import { getPageIndexByParams, getTabPath } from "../../../../helpers/tabPaths";
import { Stack } from "../../CommonCss";
import PricingConfirmAlert from "../../../common/new-alert/PricingConfirmAlert";
import AddressGroupedReportDetails from "../../report/addressGrouped/AddressGroupedReportDetails";
import AddressGroupedFileReportDetails from "../../report/addressGrouped/AddressGroupedFileReportDetails";
import {StyledTab} from "../EnhanceDataStyles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

const EnhanceHomeTab = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(DataCleanContext);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [viewDetailsReport, setViewDetailsReport] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const param = searchParams.get("page");
    setTabIndex(getPageIndexByParams(param));
  }, []);

  const handleTabChange = (event, newValue) => {
    changeQueryParams(getTabPath(0, newValue));
    setTabIndex(newValue);
  };

  const submitHandler = (value) => {
    if (
      value.checkDNC === false &&
      value.checkEmailAddress === false &&
      value.checkPhoneNumber === false &&
      value.skipTrace === false
    ) {
      window.showNotification("error", "Please check at-least one option");
      return;
    }

    const lookupArray = [];
    const searchCategoryArray = [];
    if (value.checkEmailAddress) {
      lookupArray.push(LOOKUP_TYPE_EMAIL_LOOKUP);
    }
    if (value.checkPhoneNumber) {
      lookupArray.push(LOOKUP_TYPE_CARRIER_LOOKUP);
    }
    if (value.checkDNC) {
      lookupArray.push(LOOKUP_TYPE_DNC_LOOKUP);
    }
    if (value.skipTrace) {
      lookupArray.push(LOOKUP_TYPE_SKIP_TRACING_LOOKUP);

      if (value.find === "owner") {
        searchCategoryArray.push("HOUSE_CURRENT_OWNER");
      } else if (value.find === "resident") {
        searchCategoryArray.push("HOUSE_CURRENT_RESIDENT");
      }
    }

    const generatedPayload = {
      lookupType: lookupArray,
      requestModule: "CLEAN_DATA",
      searchCategory: searchCategoryArray,
      contact: {
        number: value.phone,
        email: value.email,
      },
    };

    let price = 0;
    lookupArray.forEach((eachData) => {
      price += parseFloat(state.carrierLookupPricesObject[LOOKUP_TYPE_BY_PAYLOAD_CONST[eachData]] ?? 0);
    });

    PricingConfirmAlert({
      onSubmit: async () => {
        setLoading(true);
        try {
          const addressLines = await getAddressLines(value.address);
          generatedPayload.contact = {
            ...generatedPayload.contact,
            ...addressLines,
          };
          const response = await carrierLookupCleanDataApi(generatedPayload);
          const error = Object.values(response.error);

          if (error.length > 0) {
            const errors = Object.values(error)[0];
            window.showNotification("error", errors);
            setLoading(false);
            return;
          }
          setData(response.data);
        } catch (error) {
          setLoading(false);
          const errors = Object.values(error)[0];
          window.showNotification("error", errors);
        }
        setLoading(false);
      },
      title: `Total Cost ${numberToDollar(price)}`,
      description: "These credits will be deducted from your balance",
      checkPayload: {
        action: generatedPayload.lookupType,
        address: value.address,
        ...generatedPayload.contact,
      },
      prices: state.carrierLookupPricesObject,
    });
  };

  const handleCloseFileUpload = () => {
    setIsFileSelected(false);
    setTabIndex(1);
    resetBulkOptions();
  };

  const handleBack = () => {
    setIsFileSelected(false);
    resetBulkOptions();
  };

  const resetBulkOptions = () => {
    dispatch({
      type: ActionType.RESET_FILE_UPLOAD_STATE,
    });
  };

  return (
    <>
      <Stack p={2}>
        <Box>
          <Typography variant='h5'>Enhance Your Data</Typography>
          <Typography variant='body2'>
            Having accurate, up-to-date data is key to a successful outreach campaign, We make it easy to validate
            numbers and emails, check DNC status, and even skip trace contact records
          </Typography>
        </Box>
        <Box className={"de-img-box"}>
          <img src={"https://d24gujj67p1uc1.cloudfront.net/asset/images/releases/1.0.0/enhance-sh.png"} alt={"robot"} />
        </Box>
      </Stack>
      <Divider light />
      {isFileSelected ? (
        <>
          <FileUploadHeader onBack={handleBack} />
          <Divider style={{ marginBottom: "20px" }} light />
          <FileUpload onSelectFile={handleCloseFileUpload} />
        </>
      ) : (
        <>
          <BasicTabs value={tabIndex} onChange={handleTabChange}>
            <StyledTab value={0} label='New Search - Single' />
            <StyledTab value={1} label='View Past Imports' />
            <StyledTab value={2} label='Results' />
          </BasicTabs>
          <Divider light />

          <TabPanel value={tabIndex} index={0}>
            <>
              <SingleSearch state={state} submitHandler={submitHandler} />
              {data && (
                <Box px={2}>
                  <Result data={data} />
                </Box>
              )}
              <Divider light />
              <BulkSearch state={state} onSelectFile={setIsFileSelected} dispatch={dispatch} />
            </>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>

            {/*in-case of fire add comment to this section ## current-section-start*/}

              {
                viewDetailsReport ?
                    <AddressGroupedFileReportDetails
                        onChangeTab={() => setViewDetailsReport(false)}
                        fileSource="ALL"
                        filter={[
                          "valid-emails",
                          "invalid-emails",
                          "valid-numbers",
                          "invalid-numbers",
                          "dnc-on",
                          "dnc-off",
                          "landline",
                          "mobile",
                          "skip-trace",
                        ]}
                    /> : <FileReport onChangeTab={() => setViewDetailsReport(true)} />

              }

            {/*in-case of fire add comment to this section ## current-section-end*/}



            {/*in-case of fire remove comment from this section ## backup-section-start*/}

              {/*{viewDetailsReport ? (*/}
              {/*    Utils.getAccountData("userId") === 1 ?*/}
              {/*        <AddressGroupedFileReportDetails*/}
              {/*            onChangeTab={() => setViewDetailsReport(false)}*/}
              {/*            fileSource="ALL"*/}
              {/*            filter={[*/}
              {/*              "valid-emails",*/}
              {/*              "invalid-emails",*/}
              {/*              "valid-numbers",*/}
              {/*              "invalid-numbers",*/}
              {/*              "dnc-on",*/}
              {/*              "dnc-off",*/}
              {/*              "skip-trace",*/}
              {/*              "landline",*/}
              {/*              "mobile"*/}
              {/*            ]}*/}
              {/*        /> :*/}
              {/*        <FileReportDetails*/}
              {/*            filter={[*/}
              {/*              "valid-emails",*/}
              {/*              "invalid-emails",*/}
              {/*              "valid-numbers",*/}
              {/*              "invalid-numbers",*/}
              {/*              "dnc-on",*/}
              {/*              "dnc-off",*/}
              {/*              "skip-trace",*/}
              {/*              "landline",*/}
              {/*              "mobile",*/}
              {/*            ]}*/}
              {/*            onChangeTab={() => setViewDetailsReport(false)}*/}
              {/*            tab="ALL"*/}
              {/*        />*/}
              {/*) : (*/}
              {/*    <FileReport onChangeTab={() => setViewDetailsReport(true)} />*/}
              {/*)}*/}

            {/*in-case of fire remove comment from this section ## backup-section-end*/}

          </TabPanel>

          <TabPanel value={tabIndex} index={2}>

            {/*in-case of fire add comment to this section ## current-section-start*/}

              <AddressGroupedReportDetails
                  fileSource="ALL"
                  filter={[
                    "valid-emails",
                    "invalid-emails",
                    "valid-numbers",
                    "invalid-numbers",
                    "dnc-on",
                    "dnc-off",
                    "landline",
                    "mobile",
                    "skip-trace",
                  ]}
              />

            {/*in-case of fire add comment to this section ## current-section-end*/}


            {/*in-case of fire remove comment from this section ## backup-section-start*/}

              {/*{*/}
              {/*  Utils.getAccountData("userId") === 1 ?*/}
              {/*      <AddressGroupedReportDetails*/}
              {/*          fileSource="ALL"*/}
              {/*          filter={[*/}
              {/*            "valid-emails",*/}
              {/*            "invalid-emails",*/}
              {/*            "valid-numbers",*/}
              {/*            "invalid-numbers",*/}
              {/*            "dnc-on",*/}
              {/*            "dnc-off",*/}
              {/*            "skip-trace",*/}
              {/*            "landline",*/}
              {/*            "mobile"*/}
              {/*          ]}*/}
              {/*      /> :*/}
              {/*      <FileReportDetailsSingle*/}
              {/*          filter={[*/}
              {/*            "valid-emails",*/}
              {/*            "invalid-emails",*/}
              {/*            "valid-numbers",*/}
              {/*            "invalid-numbers",*/}
              {/*            "dnc-on",*/}
              {/*            "dnc-off",*/}
              {/*            "skip-trace",*/}
              {/*            "landline",*/}
              {/*            "mobile",*/}
              {/*          ]}*/}
              {/*          tab="ALL"*/}
              {/*      />*/}
              {/*}*/}

            {/*in-case of fire remove comment from this section ## backup-section-end*/}
          </TabPanel>
        </>
      )}

      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default EnhanceHomeTab;
