import React from "react";
import Chip from "@material-ui/core/Chip";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import BootstrapTooltip from "../carrierLookup/BootstrapTooltip";
import { WarningRounded } from "@material-ui/icons";

const ExportedFileStatus = ({ status, errorText = "" }) => {
  const theme = useTheme();

  const EXPORT_STATUS = {
    PROCESSING: { label: "Processing", color: theme.palette.info.main },
    PROCESSED: { label: "Processed", color: theme.palette.success.main },
    INVALID: { label: "Invalid", color: theme.palette.error.main },
    QUEUED: { label: "Queued", color: theme.palette.warning.main },
  };

  return (
    <Box style={{ display: "flex", alignItems: "center", gap: "4px" }}>
      <Chip
        size={"small"}
        variant={"outlined"}
        label={EXPORT_STATUS[status].label}
        style={{
          color: EXPORT_STATUS[status].color,
          borderColor: EXPORT_STATUS[status].color,
        }}
      />
      {status === "INVALID" && (
        <BootstrapTooltip title={errorText} arrow placement="top">
          <WarningRounded
            fontSize={"small"}
            style={{ color: EXPORT_STATUS[status].color }}
          />
        </BootstrapTooltip>
      )}
    </Box>
  );
};

export default ExportedFileStatus;
