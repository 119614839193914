const CoreConstants = {
    TRUE: 1,
    FALSE: 0,

    //inbox thread
    inboxThread: {
        ACTIVE: 1,
        INACTIVE: 0,

        CONTENT_TYPE_SMS: 1,
        CONTENT_TYPE_MMS: 2,
        CONTENT_TYPE_EMAIL: 4,
        CONTENT_TYPE_CALL: 5,
        CONTENT_TYPE_VOICE_MAIL: 3,
        INOUT_TYPE_IN: 1,
        INOUT_TYPE_OUT: 2,


        READ: 1,
        UNREAD: 0,

        STATUS_FAIL: 0,
        STATUS_SUCCESS: 1
    },

    VideoEmail: {
        VIDEO_STATUS_WELCOME: 0,
        VIDEO_STATUS_WEBCAM: 1,
        VIDEO_STATUS_WARNING: 2,
        VIDEO_STATUS_PREVIEW: 3,

        MESSAGE_TEMPLATE_VIDEO_EMAIL: 4,
    },

    MESSAGE_TEMPLATE: {
        EMAIL: 1,
        SMS: 2,
        VOICE_MAIL: 3,
        VIDEO_EMAIL: 4,
        MMS: 5,
        POST_CARD: 6,
        GREETING_CARD: 7,
        GIFT_CARD: 8,
        LETTER: 9,
    },

    Timeline: {
        CONTENT_TYPE_SMS: 1,
        CONTENT_TYPE_MMS: 2,
        CONTENT_TYPE_VOICE: 3,
        CONTENT_TYPE_EMAIL: 4,
        CONTENT_TYPE_CALL: 5,
        CONTENT_TYPE_ACTIVITY: 6,
        CONTENT_TYPE_OUTGOING_CALL: 7,
        CONTENT_TYPE_WEBHOOK: 9,
        CONTENT_TYPE_STAGE: 10,
        CONTENT_TYPE_APPOINTMENT: 12,
        CONTENT_TYPE_CALL_BRIGDE: 13,
        CONTENT_TYPE_VIDEO_EMAIL: 14,
        CONTENT_TYPE_CHAT: 15,
        CONTENT_TYPE_NOTE: 16,
        CONTENT_TYPE_GENERAL: 20,
        CONTENT_TYPE_POST_CARD: 21,
        CONTENT_TYPE_GREETINGS_CARD: 22,
        CONTENT_TYPE_GIFT: 23,
        CONTENT_TYPE_LETTER: 24,
        CONTENT_TYPE_CALL_RECORD: 25,
        CONTENT_TYPE_FORM_RESPONSE: 26,
        CONTENT_TYPE_SMART_FORM_RESPONSE: 30,
        CONTENT_TYPE_SCHEDULE_CONVERSATION: 'scheduled',


        INOUT_TYPE_IN: 1,
        INOUT_TYPE_OUT: 2,

        READ: 1,
        UNREAD: 0,

        STATUS_FAIL: 2,
        STATUS_SUCCESS: 1,
        STATUS_PENDING: 0,
        STATUS_SENT: 3,
        STATUS_UNDELIVERED: 4,
        STATUS_ACCEPTED: 5,
        STATUS_DELETED: 6,

        SENT_IMMEDIATLY: 1,
        SENT_SCHEDULE: 2,


        CONTENT_TYPES: {
            0: 'All Conversation',
            'scheduled': 'scheduled',
            1: 'SMS',
            2: 'MMS',
            3: 'Voice',
            4: 'Email',
            5: 'Call',
            6: 'Activity',
            7: 'Outgoing Call',
            9: 'Webhook',
            10: 'Stage',
            12: 'Appointment',
            13: 'Call Bridge',
            14: 'Video Email',
            15: 'Chat',
            16: 'Note',
            20: 'General',
            25: 'Call Record',
            21: 'Postcard',
            22: 'Greeting Card',
            23: 'Gift',
            24: 'Letter',
            26: 'Form Response',
        }
    },
    FORM_BUILDER_V3_COMPONENT_TYPE: {
        THEME: 1,
        SUBMIT_BUTTON: 2,
        HEADING: 3,
        FULL_NAME: 4,
        EMAIL: 5,
        PHONE: 6,
        ADDRESS: 7,
        COMPANY: 8,
        BIRTHDAY: 9,
        NOTE: 10,
        SHORT_ANSWER: 11,
        LONG_ANSWER: 12,
        PHONE_INPUT: 13,
        NUMBER: 14,
        LINK: 15,
        DATE: 16,
        TIME: 17,
        YES_NO: 18,
        RADIO: 19,
        CHECKBOX: 20,
        DROPDOWN: 21,
        FILE_UPLOAD: 22,
        HIDDEN_FIELD: 23,
        PARAGRAPH: 24,
        IMAGE: 25,
        VIDEO: 26,
        AUDIO: 27,
        EMBED: 28,
        PROGRESSBAR: 29
    }

}

export const ActivityType = {
    CALL: '1',
    APOINTMENT: '2',
    TASK: '3',
    DEADLINE: '4',
    EMAIL: '5',
    FOLLOWUP: '6',
    OTHERS: '7'

}


export const CustomFieldType = {
    TEXT: 1,
    MULTILINE_TEXT: 2,
    NUMERIC: 3,
    DATE: 4,
    CHECKBOX: 5,
    SELECT: 6,
    RADIO: 7,
    PHONE: 8,
    ZIP_CODE: 9,
    WEBSITE: 10
}


export const userTimezoneRaw = 'America/Denver';
export const userTimezone = 'Mountain Time';

export default CoreConstants;



//File import constants 
// DO NOT REMOVE ANY ROWS - THERE ARE SOME DEPENDENCIES -- CHECK USAGES IF DELETION NEED
export const IMPORT_CONTACT_CONTACT_DETAILS = [
    {
        value: 'first_name',
        label: 'First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'last_name',
        label: 'Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    { value: 'company_name', label: 'Company Name' },
    { value: 'deal_value', label: 'Price' },
    { value: 'url', label: 'Url' },
    { value: 'tags', label: 'Tags' },
    { value: 'notes', label: 'Notes' },
    { value: 'birth_date', label: 'Birth Date' },
    { value: 'anniversary_date', label: 'Anniversary Date' },
];

export const IMPORT_CONTACT_CONTACT_ADDRESS = [
    { value: 'address', label: 'Street Address' },
    { value: 'city', label: 'City' },
    { value: 'state', label: 'State' },
    {
        value: 'zip',
        label: 'Zip Code',
        guidelines: "We accept US and international ZIP and postal codes. US ZIP codes may include a 4-digit extension as long as they are separated by a hyphen.The extension is not required and will not further improve match rate.",
        examples: [
            '99950',
            '56586',
            '54865'
        ]
    },
    {
        value: 'country',
        label: 'Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    }
];

export const IMPORT_CONTACT_COMMUNICATION = [
    {
        value: 'email',
        label: 'Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'number',
        label: 'Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
];

export const contactAddCustoms = [
    { value: 'customForFields_TEXT', label: 'Add Text Fields' },
    { value: 'customForFields_DATE', label: 'Add Date Fields' },
    { value: 'customForFields_NUMERIC', label: 'Add Number Fields' }
];


export const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

export const FILE_IMPORT_STEP = {
    STEP_UPLOAD: 1,
    STEP_WRAPPER: 2,
    STEP_IMPORT_RULE: 3,
    STEP_VALIDATION: 4,
    STEP_DEAL_CREATE: 5,
    STEP_PROCESS_MODE: 6,
    STEP_ADD_SOURCE: 7,
    STEP_ADD_TAG: 8,
    STEP_PROCESS_LOADER: 9,
    STEP_SUCCESS: 10,
    STEP_FILE_LIST: 11,
    STEP_UPGRADE: 12,
}

export const AUTO_DIALER = {
    STATUS: {
        NOT_STARTED: 0,
        RUNNING: 1,
        PAUSED: 2,
        COMPLETED: 3
    }
}

export const CAMPAIGN_STATUS = {
    ACTIVE_CAMPAIGN: 3
}

export const leadPermission = {
    REQUEST_TYPE_ADD_COLLABORATOR: 1,
    REQUEST_TYPE_CHANGE_OWNER: 2
}
const VALIDATION_STATUS_NOT_VERIFIED = 'NOT_VERIFIED';
const VALIDATION_STATUS_VALID = 'VALID';
const VALIDATION_STATUS_INVALID = 'INVALID';
const VALIDATION_STATUS_VERIFYING = 'VERIFYING';

const NUMBER_VALIDATION_TYPE_CALLER_NAME = 'CALLER_NAME';
const NUMBER_VALIDATION_TYPE_CARRIER = 'CARRIER';
const NUMBER_VALIDATION_TYPE_DO_NOT_CALL = 'DO_NOT_CALL';
const EMAIL_VALIDATION_TYPE_CALLER_NAME = 'VERIFY_EMAIL';
const SKIP_TRACING_TYPE_PERSON_SEARCH = 'SKIP_TRACING_PERSON_SEARCH';

export const LOOKUP_PROVIDER_TYPE_NUMBER = 1;
export const LOOKUP_PROVIDER_TYPE_EMAIL = 2;

export const LOOKUP_TYPE_EMAIL_LOOKUP = "EMAIL_LOOKUP";
export const LOOKUP_TYPE_CARRIER_LOOKUP = "CARRIER_LOOKUP";
export const LOOKUP_TYPE_DNC_LOOKUP = "DNC_LOOKUP";
export const LOOKUP_TYPE_CARRIER_DNC_LOOKUP = "CARRIER_DNC_LOOKUP";
export const LOOKUP_TYPE_CARRIER_EMAIL_LOOKUP = "CARRIER_EMAIL_LOOKUP";
export const LOOKUP_TYPE_EMAIL_DNC_LOOKUP = "EMAIL_DNC_LOOKUP";
export const LOOKUP_TYPE_SKIP_TRACING_LOOKUP = "SKIP_TRACING_LOOKUP";

const LOOKUP_CALLER_NAME = 63;
const LOOKUP_CARRIER = 64;
const LOOKUP_VERIFY_EMAIL = 65;
const LOOKUP_DNC = 66;
const LOOKUP_SKIP_TRACING = 67

export const CONTACT_VALIDATION_STATUS = {
    NOT_VERIFIED: VALIDATION_STATUS_NOT_VERIFIED,
    VALID: VALIDATION_STATUS_VALID,
    INVALID: VALIDATION_STATUS_INVALID,
    VERIFYING: VALIDATION_STATUS_VERIFYING
};

export const CONTACT_VALIDATION_TYPE = {
    CALLER_NAME: NUMBER_VALIDATION_TYPE_CALLER_NAME,
    CARRIER: NUMBER_VALIDATION_TYPE_CARRIER,
    VERIFY_EMAIL: EMAIL_VALIDATION_TYPE_CALLER_NAME,
    DO_NOT_CALL: NUMBER_VALIDATION_TYPE_DO_NOT_CALL,
    SKIP_TRACING_TYPE_PERSON_SEARCH: SKIP_TRACING_TYPE_PERSON_SEARCH
};

export const LOOKUP_TYPE_BY_PAYLOAD_CONST = {
    [LOOKUP_TYPE_CARRIER_LOOKUP]: LOOKUP_CARRIER,
    [LOOKUP_TYPE_EMAIL_LOOKUP]: LOOKUP_VERIFY_EMAIL,
    [LOOKUP_TYPE_DNC_LOOKUP]: LOOKUP_DNC,
    [LOOKUP_TYPE_SKIP_TRACING_LOOKUP]: LOOKUP_SKIP_TRACING,
}

export const AGENCY_CARRIER_LOOKUP_CREDIT_TYPE = {
    CALLER_NAME: LOOKUP_CALLER_NAME,
    CARRIER: LOOKUP_CARRIER,
    VERIFY_EMAIL: LOOKUP_VERIFY_EMAIL,
    DNC: LOOKUP_DNC,
    SKIP_TRACING: LOOKUP_SKIP_TRACING
};

export const CARRIER_INFO_TYPES = {
    emailVerificationType: "VERIFY_EMAIL",
    phoneVerificationType: "CARRIER",
    doNotCall: "DO_NOT_CALL",
    skipTraceCurrentOwner: "SKIP_TRACING_LOOKUP_CURRENT_OWNER",
    skipTraceCurrentResident: "SKIP_TRACING_LOOKUP_CURRENT_RESIDENT"
}


export const LOOKUP_TYPE = {
    CARRIER_LOOKUP: "CARRIER_LOOKUP",
    EMAIL_LOOKUP: "EMAIL_LOOKUP",
    DNC_LOOKUP: "DNC_LOOKUP",
    SKIP_TRACING_LOOKUP: "SKIP_TRACING_LOOKUP"
};

export const SEARCH_CATEGORY = {
    HOUSE_CURRENT_RESIDENT: "HOUSE_CURRENT_RESIDENT",
    HOUSE_CURRENT_OWNER: "HOUSE_CURRENT_OWNER"
};

export const ACTION = {
    ADD_NEW_CONTACT: "ADD_NEW_CONTACT",
    ADD_UNDER_CONTACT: "ADD_UNDER_CONTACT",
    NO_ACTION: "NO_ACTION"
};

export const REQUEST_MODULE = {
    CONTACT: "CONTACT",
    CLEAN_DATA: "CLEAN_DATA"
};

export const CONTACT_PRODUCTS = {
    PRODUCT_STATUS: {
        TRASH: "TRASH",
        ACTIVE: "ACTIVE"
    },
}

export const RESPONSE_STATUS = {
    SUCCESS: "success",
    ERROR: "error"
};

export const CONTACT_STAGE = {
    WIN: 2
}

export const EMPTY_SCREEN_IMG = "https://d24gujj67p1uc1.cloudfront.net/assets/bulk-emails/images/releases/1.0.0/Empty%20Campaign.png"

export const DEAL_STATUS = {
    WON: 2,
    LOST: 3
}

export const AGENCY_BUSINESS_TYPE = {
    INSURANCE: "INSURANCE",
    REGULAR: "REGULAR"
};

export const IMPORT_CONTACT_STEP_UPLOAD = 'UPLOAD';
export const IMPORT_CONTACT_STEP_MAPPING = 'MAPPING';
export const IMPORT_CONTACT_STEP_DETAILS = 'DETAIL';
export const IMPORT_CONTACT_STEP_SUMMARY = 'SUMMARY';

export const IMPORT_CONTACT_MAPPING_ALL = 0;
export const IMPORT_CONTACT_MAPPING_MAPPED = 1;
export const IMPORT_CONTACT_MAPPING_UNMAPPED = 2;

export const IMPORT_CONTACT_V2_STEPS = [
    {
        value: IMPORT_CONTACT_STEP_UPLOAD,
        label: "Upload",
    },
    {
        value: IMPORT_CONTACT_STEP_MAPPING,
        label: "Mapping",
    },
    {
        value: IMPORT_CONTACT_STEP_DETAILS,

        label: "Details",
    },
    {
        value: IMPORT_CONTACT_STEP_SUMMARY,
        label: "Summary",
    },
];

export const IMPORT_CONTACT_V2_CONTACT_DETAILS = [
    {
        value: 'first_name',
        label: 'First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'last_name',
        label: 'Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    { value: 'company_name', label: 'Company Name' },
    { value: 'deal_value', label: 'Price' },
    { value: 'lead_type', label: 'Lead Type' },
    { value: 'url', label: 'Url' },
    { value: 'tags', label: 'Tags' },
    { value: 'notes', label: 'Notes' },
    { value: 'birth_date', label: 'Birth Date' },
    { value: 'anniversary_date', label: 'Anniversary Date' },
];

export const IMPORT_CONTACT_V2_CONTACT_ADDRESS = [
    { value: 'address', label: 'Street Address' },
    { value: 'city', label: 'City' },
    { value: 'state', label: 'State' },
    {
        value: 'zip',
        label: 'Zip Code',
        guidelines: "We accept US and international ZIP and postal codes. US ZIP codes may include a 4-digit extension as long as they are separated by a hyphen.The extension is not required and will not further improve match rate.",
        examples: [
            '99950',
            '56586',
            '54865'
        ]
    },
    {
        value: 'country',
        label: 'Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    }
];

export const IMPORT_CONTACT_V2_COMMUNICATION_PHONE = [
    {
        value: 'number',
        label: 'Primary Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'number1',
        label: 'Phone2',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'number2',
        label: 'Phone3',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'number3',
        label: 'Phone4',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'number4',
        label: 'Phone5',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
];

export const IMPORT_CONTACT_V2_COMMUNICATION_PHONE_TYPE = [
    {
        value: 'number_type',
        label: 'Primary Phone Type',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'mobile',
            'voip',
            'landline',
            'unknown'
        ]
    },
    {
        value: 'number1_type',
        label: 'Phone2 Type',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'mobile',
            'voip',
            'landline',
            'unknown'
        ]
    },
    {
        value: 'number2_type',
        label: 'Phone3 Type',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'mobile',
            'voip',
            'landline',
            'unknown'
        ]
    },
    {
        value: 'number3_type',
        label: 'Phone4 Type',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'mobile',
            'voip',
            'landline',
            'unknown'
        ]
    },
    {
        value: 'number4_type',
        label: 'Phone5 Type',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'mobile',
            'voip',
            'landline',
            'unknown'
        ]
    },
];

export const IMPORT_CONTACT_V2_COMMUNICATION_PHONE_DNC = [
    {
        value: 'number_dnc',
        label: 'Primary Phone DNC',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'YES',
            'NO',
            '1',
            '0'
        ]
    },
    {
        value: 'number1_dnc',
        label: 'Phone2 DNC',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'YES',
            'NO',
            '1',
            '0'
        ]
    },
    {
        value: 'number2_dnc',
        label: 'Phone3 DNC',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'YES',
            'NO',
            '1',
            '0'
        ]
    },
    {
        value: 'number3_dnc',
        label: 'Phone4 DNC',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'YES',
            'NO',
            '1',
            '0'
        ]
    },
    {
        value: 'number4_dnc',
        label: 'Phone5 DNC',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            'YES',
            'NO',
            '1',
            '0'
        ]
    },
];

export const IMPORT_CONTACT_V2_COMMUNICATION_EMAIL = [
    {
        value: 'email',
        label: 'Primary Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email1',
        label: 'Email2',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email2',
        label: 'Email3',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email3',
        label: 'Email4',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'email4',
        label: 'Email5',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    }
];

export const IMPORT_CONTACT_V2_RELATED_CONTACT = [
    {
        value: 'related_contact_1_first_name',
        label: 'Related Contact First Name',
        guidelines: "We accept first name and first name initial, with or without accents. Initials ca be provided with or without a period.",
        examples: [
            'John',
            'F,',
            'Emily'
        ]
    },
    {
        value: 'related_contact_1_last_name',
        label: 'Related Contact Last Name',
        guidelines: "We accept full last names with or without accents.",
        examples: [
            'Smith',
            'Doe',
            'Anderson',
            'Ronaldo'
        ]
    },
    {
        value: 'related_contact_1_email',
        label: 'Related Contact Email',
        guidelines: "All universal email formats are accepted.",
        examples: [
            'john@example.com',
            'sara@example.com',
            'riyad@example.com'
        ]
    },
    {
        value: 'related_contact_1_phone',
        label: 'Related Contact Phone',
        guidelines: "It's not necessary to include the country code.",
        examples: [
            '+12184299295',
            '12028838977',
            '212-856-7890',
            '+1-212-456-7890'
        ]
    },
    {
        value: 'related_contact_1_address',
        label: 'Related Contact Address',
    },
    {
        value: 'related_contact_1_city',
        label: 'Related Contact City'
    },
    {
        value: 'related_contact_1_state',
        label: 'Related Contact State'
    },
    {
        value: 'related_contact_1_country',
        label: 'Related Contact Country',
        guidelines: "Countries can be provided as ISO two or three letter country code even if they're all from the same country.",
        examples: [
            'US',
            'USA'
        ]
    },
    {
        value: 'related_contact_1_relation',
        label: 'Related Contact Relationship'
    }
];

export const FILE_IMPORT_TAB = {
    TAB_DEFAULT: 'TAB_DEFAULT',
    TAB_FILE_LIST: 'TAB_FILE_LIST',
    TAB_GOOGLE_SHEET: 'TAB_GOOGLE_SHEET',
};

export const CONTACT_IMPORT_RULES = [
    {
        label: 'Add New and Update Existing Contact Records',
        value: 2
    },
    {
        label: 'Add New Contact Records',
        value: 3
    },
    {
        label: 'Update Existing Contact Records',
        value: 4
    }
]

export const IMPORT_FROM_CAMPAIGN_REGEX = /\/campaign\/details\/(\d+)/;

export const IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT = 'CONNECT';
export const IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING = 'MAPPING';
export const IMPORT_CONTACT_STEP_SPREADSHEET_DETAILS = 'DETAIL';
export const IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE = 'CONFIGURE';
export const IMPORT_CONTACT_STEP_SPREADSHEET_SUMMARY = 'SUMMARY';

export const IMPORT_CONTACT_SPREADSHEET_STEPS = [
    {
        value: IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT,
        label: "Connect",
    },
    {
        value: IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE,
        label: "Configure",
    },
    {
        value: IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING,

        label: "Mapping",
    },
    {
        value: IMPORT_CONTACT_STEP_SPREADSHEET_DETAILS,
        label: "Details",
    },
    {
        value: IMPORT_CONTACT_STEP_SPREADSHEET_SUMMARY,
        label: "Summary",
    }
];

export const IMPORT_CONTACT_SPREADSHEET_DETAILS = [
    {
        value: "first_name",
        label: "First Name",

    },
    {
        value: "last_name",
        label: "Last Name",
    },
    {
        value: "number",
        label: "Phone",
    },
    {
        value: "number_type",
        label: "Phone Type"
    },
    {
        value: "number_dnc",
        label: "Phone DNC"
    },
    {
        value: "email",
        label: "Email",
    },
    {
        value: "url",
        label: "URL",
    },
    {
        value: "company_name",
        label: "Company Name",
    },
    {
        value: "birth_date",
        label: "Birth Date",
    },
    {
        value: "anniversary_date",
        label: "Anniversary Date",
    },
    {
        value: "lead_type",
        label: "Lead Type"
    },
];

export const IMPORT_CONTACT_SPREADSHEET_ADDRESS = [
    {
        value: "address",
        label: "Address",
    },
    {
        value: "city",
        label: "City",
    },
    {
        value: "state",
        label: "State",
    },
    {
        value: "zip",
        label: "Zip",
    },
    {
        value: "country",
        label: "Country",
    },
];

export const PROCESS_MODE_VALUE = {
    ONCE: "once",
    DRIP:'drip'
}
export const  processModeOptions = [
    {
      value: PROCESS_MODE_VALUE.ONCE,
      label: "Process all at once"
    },
    {
      value: PROCESS_MODE_VALUE.DRIP,
      label: "Process in drip mode"
    }
  ]