import React from 'react';
import Upload from './Upload';
import moment from 'moment';
import Wrapper from './Wrapper';
import UserAndTag from './UserAndTag';
import Success from './Success';
import ProcessLoader from './ProcessLoader';
import FileList from './FileList';
import Upgrade from './Upgrade';
import ContactImportRule from "./ContactImportRule";
import DealCreate from "./DealCreate";
import ProcessMode from "./ProcessMode";
import AddSource from './AddSource';
import { getContactLimit } from '../../api/contactApi';
import './import-contact.css';
import {FILE_IMPORT_STEP} from '../../constants/CoreConstants';
import Utils from "../../helpers/Utils";
import ContactValidation from "./ContactValidation";
import Validation from "./Validation";

let userTimeNow = window.globalTimezoneConversionToDifferentTimezone ? window.globalTimezoneConversionToDifferentTimezone(new Date()) : new Date(moment.tz(Utils.getAccountData('userTimezoneRaw')));
class ImportContactCampaign extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: FILE_IMPORT_STEP.STEP_UPLOAD,
            contactLimit : null,
            currentContact : null,
            existing_contact_rule : 1,
            isLoading:false,
            data: [],
            file:null,
            total:0,
            do_create_deal:0,
            pipeline_id:0,
            stage_id:0,
            pipelines: [],
            stages: [],
            sources:[],
            source_id : null,
            maxUpload : null,
            campaignId : props.match.params.campaignId,
            batch_mode: 1,
            batch_start_on : null,
            batch_quantity : 1,
            batch_repeat_after : 1,
            batch_send_on : {'Mon':true,'Tue':true,'Wed':true,'Thu':true,'Fri':true,'Sat':true,'Sun':true},
            batch_form : {},
            batch_time : userTimeNow,
            batch_date : userTimeNow,
            submitData : {},
            additional_infos: null
        };

        this.nextStep = this.nextStep.bind(this);
        this.nextStepList = this.nextStepList.bind(this);
        this.prevStepList = this.prevStepList.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    setInitialState = () => {
        this.state.sources.forEach(source => {
            //2 Means bulk
            if(source.const_value == 2) {
                this.setState({
                    source_id : source.id
                });
            }
            
        })
        return {
            existing_contact_rule : 1,
            data: [],
            file:null,
            do_create_deal:0,
            pipeline_id:0,
            stage_id:0,
            batch_mode: 1,
            batch_start_on : null,
            batch_quantity : 1,
            batch_repeat_after : 1,
            batch_send_on : {'Mon':true,'Tue':true,'Wed':true,'Thu':true,'Fri':true,'Sat':true,'Sun':true},
            batch_form : {},
            batch_time : null,
            batch_date : null,
        };
    };

    refreshCSVData = () => {
        this.setState(this.setInitialState());
    }

    nextStep = () => {

        if (!Utils.getAccountData('carrierLookup') && this.state.step == FILE_IMPORT_STEP.STEP_IMPORT_RULE){
            this.setState(prevState=>{
                return {...prevState, step: prevState.step + 1}
            })
        }
        
        this.setState(prevState=>{
            if (prevState.step === FILE_IMPORT_STEP.STEP_DEAL_CREATE) {
                return {...prevState, step:FILE_IMPORT_STEP.STEP_PROCESS_MODE};
            } else if (prevState.step === FILE_IMPORT_STEP.STEP_PROCESS_MODE) {
                return {...prevState, step: FILE_IMPORT_STEP.STEP_ADD_SOURCE};
            } else if(prevState.step === FILE_IMPORT_STEP.STEP_ADD_SOURCE){
                return {...prevState, step: FILE_IMPORT_STEP.STEP_ADD_TAG};
            }else{
                return {...prevState, step: prevState.step+1}
            }

        })
        
        // this.setState({
        //     step : nextStep
        // })
    };

    upgradeStep = () => {
        this.setState({
            step : 9
        })
    };

    prevStep = () => {
        // const { step } = this.state;
        // let prevStep = step - 1;

        if (!Utils.getAccountData('carrierLookup') && this.state.step == FILE_IMPORT_STEP.STEP_DEAL_CREATE){
            this.setState(prevState=>{
                return {...prevState, step: prevState.step - 1}
            })
        }

        this.setState(prevState=>{
            if (prevState.step === FILE_IMPORT_STEP.STEP_PROCESS_MODE) {
                return {...prevState, step:FILE_IMPORT_STEP.STEP_DEAL_CREATE};
            } else if (prevState.step === FILE_IMPORT_STEP.STEP_ADD_TAG) {
                return {...prevState, step: FILE_IMPORT_STEP.STEP_ADD_SOURCE};
            } else if(prevState.step === FILE_IMPORT_STEP.STEP_ADD_SOURCE){
                return {...prevState, step: FILE_IMPORT_STEP.STEP_PROCESS_MODE};
            }else{
                return {...prevState, step: prevState.step-1}
            }

        })
        // if (step == FILE_IMPORT_STEP.STEP_PROCESS_MODE) {
        //     prevStep = FILE_IMPORT_STEP.STEP_DEAL_CREATE;
        // } else if (step == FILE_IMPORT_STEP.STEP_ADD_TAG) {
        //     prevStep = FILE_IMPORT_STEP.STEP_ADD_SOURCE;
        // } else if (step == FILE_IMPORT_STEP.STEP_ADD_SOURCE) {
        //     prevStep = FILE_IMPORT_STEP.STEP_PROCESS_MODE;
        // }
        // this.setState({
        //     step : prevStep
        // })
    }

    nextStepList = () => {
        this.setState({
            step : FILE_IMPORT_STEP.STEP_FILE_LIST
        })
    };

    prevStepList = () => {
        this.setState({
            step : FILE_IMPORT_STEP.STEP_UPLOAD
        },()=>this.loadData());
    };

    removeStateKey = (newState) => {
        Object.keys(newState).forEach(k => {
            if( k != "step" ){
                delete newState[k];
            }
        });
    };

    handleChange (key , data) {
        this.setState({ [key] : data })
    }
    componentDidMount (){
        this.loadData();
    };

    loadData = () => {
        this.setState({
            isLoading: true
        });
        getContactLimit().then(res => {
            let data = res.data.data;
            if(data.contactLimit){
                this.setState({
                    contactLimit : data.contactLimit,
                    currentContact: data.currentContact,
                    pipelines: data.pipelines,
                    stages: data.stages,
                    sources : data.sources,
                    maxUpload : data.maxUpload,
                    isLoading:false,
                });

                data.sources.forEach(source => {
                    //2 Means bulk
                    if(source.const_value == 2) {
                        this.setState({
                            source_id : source.id
                        });
                    }
                    
                })
            }

        });
    };

    setStepShowList = () => {
        const { step } = this.state;
        this.setState({
            step : FILE_IMPORT_STEP.STEP_FILE_LIST
        },()=>this.loadData());
    }

    render() {
        const { step } = this.state;
        switch(step) {
            case FILE_IMPORT_STEP.STEP_UPLOAD:
                return <Upload
                    contactLimit={this.state.contactLimit}
                    currentContact={this.state.currentContact}
                    upgradeStep={this.upgradeStep}
                    nextStep={this.nextStep}
                    nextStepList={this.nextStepList}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_WRAPPER:
                return <Wrapper
                    contactLimit={this.state.contactLimit}
                    currentContact={this.state.currentContact}
                    upgradeStep={this.upgradeStep}
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                    maxFileSize={this.state.maxUpload}
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_IMPORT_RULE:
                return <ContactImportRule
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_VALIDATION:
                return <Validation
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                />
            case FILE_IMPORT_STEP.STEP_DEAL_CREATE:
                return <DealCreate
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_PROCESS_MODE:
                return <ProcessMode
                    state = { this.state }
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_ADD_SOURCE:
                return <AddSource
                    prevStep={this.prevStep}
                    state = { this.state }
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_ADD_TAG:
                return <UserAndTag
                    nextStep={this.nextStep}
                    prevStep={this.prevStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_PROCESS_LOADER:
                return <ProcessLoader
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                    userId = { this.props.userId}
                    nodeServer = { this.props.nodeServer}
                    refreshCSVData = { this.refreshCSVData}
                    setStepShowList={this.setStepShowList}
                    campaignId = { this.state.campaignId}
                    fromCampaign={true}
                />
            case FILE_IMPORT_STEP.STEP_SUCCESS:
                return <Success
                    nextStep={this.nextStep}
                    handleChange = {this.handleChange}
                    state = { this.state }
                />

            case FILE_IMPORT_STEP.STEP_FILE_LIST:
                return <FileList
                    nextStepList={this.nextStepList}
                    prevStepList={this.prevStepList}
                    handleChange = {this.handleChange}
                />

            case FILE_IMPORT_STEP.STEP_UPGRADE:
                return <Upgrade
                    handleChange = {this.handleChange}
                />
            default :
                return null;
            
        }
    }
}

export default ImportContactCampaign;
