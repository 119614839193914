import {Backdrop, Fade, Modal, Step, StepButton, Stepper} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
    IMPORT_CONTACT_SPREADSHEET_STEPS,
    IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE,
    IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT,
    IMPORT_CONTACT_STEP_SPREADSHEET_DETAILS,
    IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING,
    IMPORT_CONTACT_STEP_SPREADSHEET_SUMMARY
} from "../../../constants/CoreConstants";
import React, {useEffect} from "react";
import {importContactsV2Style} from "../importContactsV2Style";
import modalUseStyles from "../../contact/leftSideV2/common/modal/modalUseStyles";
import {useDispatch, useSelector} from "react-redux";
import {selectImportSpreadsheetContactState} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSelector";
import Connect from "./Connect";
import Configuration from "./Configuration";
import {
    loadInitialDataStart, resetToInitial,
    updateActiveStepAndCompletedStep
} from "../../../reducers/importSpreadsheetContact/importSpreadsheetContactSlice";
import Mapping from "./Mapping";
import Details from "./Details";
import Utils from "../../../helpers/Utils";
import Summary from "./Summary";

const ImportSpreadsheetModal = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const {
        activeTab,
        completedSteps
    } = useSelector(selectImportSpreadsheetContactState)
    const classes = importContactsV2Style();
    const modalClasses = modalUseStyles();

    useEffect(() => {
        dispatch(loadInitialDataStart({ userId: Utils.getAccountData("userId") }));
    }, []);

    const moveToNextStep = (currentStep, nextStep) => {
        dispatch(updateActiveStepAndCompletedStep({ activeTab: nextStep, completedSteps: [...completedSteps, currentStep] }))
    }

    const moveToPreviousStep = (previousStep) => {

        const tmpCompleted = (prev) => {
            let arr = [...prev];
            let i = arr.indexOf(previousStep)
            if (i >= 0) delete (arr.splice(i, 1));
            return arr
        };
        dispatch(updateActiveStepAndCompletedStep({ activeTab: previousStep, completedSteps: tmpCompleted(completedSteps) }))
    }

    const handleOnClose = (redirect) => {
        onClose(redirect);
        dispatch(resetToInitial());
    };

    const renderContent = () => {
        switch (activeTab) {
            case IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT:

                return <Connect
                    moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT, IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE)}
                />;

            case IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE:

                return <Configuration
                    moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE, IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING)}
                    moveToPreviousStep={() => moveToPreviousStep(IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT)}
                />;
            case IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING:

                return <Mapping
                    moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING, IMPORT_CONTACT_STEP_SPREADSHEET_DETAILS)}
                    moveToPreviousStep={() => moveToPreviousStep(IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE)}
                />;
            case IMPORT_CONTACT_STEP_SPREADSHEET_DETAILS:
                return <Details
                    moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_SPREADSHEET_DETAILS, IMPORT_CONTACT_STEP_SPREADSHEET_SUMMARY)}
                    moveToPreviousStep={() => moveToPreviousStep(IMPORT_CONTACT_STEP_SPREADSHEET_MAPPING)}
                />;
            case IMPORT_CONTACT_STEP_SPREADSHEET_SUMMARY:
                return <Summary  
                    onClose={handleOnClose}
                />;
                default:  
                return <Connect
                moveToNextStep={() => moveToNextStep(IMPORT_CONTACT_STEP_SPREADSHEET_CONNECT, IMPORT_CONTACT_STEP_SPREADSHEET_CONFIGURE)}
            />;
        }
    };

    return(
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            className={modalClasses.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={modalClasses.paper}>
                    <div
                        className={classes.csvModalWrapper}
                        style={{
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: "#949db21f",
                            padding: "16px",
                            borderRadius: "8px",
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: 16 }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 10, cursor: "pointer" }} onClick={()=>{onClose(true)}}>
                                <ArrowBackIcon />
                                <h6 style={{ fontWeight: 600, margin: 0 }}>Import From Google Sheets</h6>
                            </div>

                            <div className={classes.helpBox}>
                                <p>How it works?</p>
                            </div>
                        </div>

                        <div style={{ backgroundColor: "#fff", borderRadius: 4, height: "100%" }}>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    padding: 16,
                                }}
                            >
                                <div style={{ display: "flex", flexDirection: "column", gap: 4 }}>
                                    <h4 style={{ fontSize: 18, margin: 0 }}>Import From Google Sheets</h4>
                                    <p style={{ fontSize: 14, fontWeight: 300, margin: 0 }}>Add your contact using google sheets</p>
                                </div>
                            </div>

                            <div
                                style={{
                                    // padding: "0px 200px 40px",
                                    margin: 8,
                                    padding: 16,
                                    border: "1px solid rgba(0, 0, 0, 0.12)",
                                    borderRadius: 8,
                                    height: "calc(100vh - 195px)",
                                }}
                            >
                                <div style={{ display: "flex", alignItems: "flex-start", padding: "0px 100px 40px", gap: 8 }}>
                                    <Stepper orientation='vertical' style={{ width: 250 }}>
                                        {IMPORT_CONTACT_SPREADSHEET_STEPS.map((step) => (
                                            <Step key={step.label} completed={completedSteps.includes(step.value)} active={activeTab === step.value}>
                                                <StepButton sx={{ "&:focus": { background: "transparent" } }}>{step.label}</StepButton>
                                            </Step>
                                        ))}
                                    </Stepper>

                                    <div style={{ width: "100%" }}>
                                        {renderContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
}

export default ImportSpreadsheetModal;