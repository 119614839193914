import {styled, TextField} from "@material-ui/core";
import Box from "@material-ui/core/Box";

export const EachInputBox = styled(Box)({
    padding: '16px',
    background: '#3c7ef347',
    borderRadius: '8px',
    maxWidth: 1200
})


export const StyledInput = styled(TextField)({
    '& .MuiOutlinedInput-root': {
        background: 'white',

        '& input::placeholder': {
            fontSize: '1rem',
        },
        '& textarea::placeholder': {
            fontSize: '1rem',
        },
    },
});

export const EnhanceSearchStyledInput = styled(TextField)({
    width: 320,
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#0379fc !important',
    },
    '& .MuiOutlinedInput-root': {
        background: 'white',

        '& input::placeholder': {
            fontSize: '1rem',
        },
        '& textarea::placeholder': {
            fontSize: '1rem',
        },
    },
});

export const Stack = styled(Box)({
   display:'flex',
    alignItems:'center',
    justifyContent:'space-between',
    "& .de-img-box":{
       height:80,
        objectFit:'contained',
        '& img':{
           height:'100%'
        }
    }
})