import React, { useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { LeftSideV2Styles } from "../../LeftSideV2Styles";
import { connect } from "react-redux";

const ContactCompany = (props) => {
  const { textDarkBlue } = LeftSideV2Styles();
  const [contact, setContact] = useState(props.contact);

  useEffect(() => {
    setContact(props.contact);
  }, [props.contact]);

  if (!contact) {
    return null;
  }

  return (
    <Box>
      <Typography variant='body1' color='white'>
        {contact.company_name !== undefined && contact.company_name !== null ? contact.company_name : ""}
      </Typography>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    contact: state.contactReducer.contact,
  };
};

export default connect(mapStateToProps)(ContactCompany);
