import React, { Fragment, useEffect, useState } from "react";
import {
    BasicButton,
    ContactProducts,
    TabHeader,
    TabWrapper
} from "./ProductStyles";
import ContactProductCard from "./ContactProductCard";
import { getContactProduct } from "../../../../api/dealApi";
import EmptyProduct from "./EmptyProduct";
import AddIcon from "@material-ui/icons/Add";
import ProductSkeleton from "./ProductSkeleton";
import AddContactProductModal from "./AddContactProductModal";
import { useSelector } from "react-redux";

const Products = (props) => {
    const { userProductActiveList } = useSelector((state) => (state.contactReducer));
    const [contactProducts, setContactProducts] = useState([]);
    const [openProductModal, setOpenProductModal] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);

    useEffect(() => {
        if (props.contact?.id) {
            loadData();
        }
    }, [props.contact?.id]);


    const loadData = async () => {
        setLoadingContent(true);
        await handleGetContactSoldProducts();
        setLoadingContent(false);
    };

    const handleGetContactSoldProducts = async () => {
        try {
            const response = await getContactProduct({ contact_id: props.contact.id })

            if (response && response.success) {
                setContactProducts(response.data);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const findSubUserById = (id, field = null) => {
        try {
            const subUser = props.subUserList.find((subUser) => (subUser.id === id));

            if (field) {
                if (subUser) {
                    return subUser[field];
                }

                return null;
            }

            return subUser;
        } catch (err) {
            return null;
        }
    };

    const appendNewContactProduct = (newContactProduct) => {
        setContactProducts((prevState) => ([newContactProduct, ...prevState]));
    };

    const getProductDetails = (id, key) => {
        if (id) {
            const productDetails = userProductActiveList.find((product) => (product.id === id));

            if (productDetails && productDetails[key]) {
                if (key === "unit_price" || key === "unit_cost") {
                    return parseFloat(productDetails[key]);
                }
                return productDetails[key];
            }
        }
        return null;
    };

    return (
        <TabWrapper className={"awesome__scroll_bar"}>
            {
                loadingContent ?
                    <ProductSkeleton /> :
                    <Fragment>
                        {
                            contactProducts[0] &&
                            <TabHeader>
                                <BasicButton
                                    startIcon={<AddIcon />}
                                    onClick={() => { setOpenProductModal(true); }}
                                >
                                    New Product/Service
                                </BasicButton>
                            </TabHeader>
                        }
                        {
                            contactProducts[0] ?
                                <ContactProducts>
                                    {
                                        contactProducts.map((contactProduct) => (
                                            <ContactProductCard
                                                findSubUserById={findSubUserById}
                                                contactProduct={contactProduct}
                                                getProductDetails={getProductDetails}
                                            />
                                        ))
                                    }
                                </ContactProducts> :
                                <EmptyProduct
                                    setOpenProductModal={setOpenProductModal}
                                />
                        }
                    </Fragment>
            }

            {openProductModal &&
                <AddContactProductModal
                    contact={props.contact}
                    appendNewContactProduct={appendNewContactProduct}
                    open={openProductModal}
                    onClose={() => { setOpenProductModal(false); }}
                />
            }
        </TabWrapper>
    );
}

export default Products;