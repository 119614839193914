import React from "react";
import DncResult from "./DncResult";

const Result = ({
  result,
  singleDncSearchHandler,
  multipleDncSearchHandler,
  onClickCampaignButton,
  onClickDialerButton,
  onBackClickHandler,
}) => {
  let content = <></>;
  if (result.length > 0)
    content = (
      <DncResult
        data={result}
        singleDncSearchHandler={singleDncSearchHandler}
        multipleDncSearchHandler={multipleDncSearchHandler}
        onClickCampaignButton={onClickCampaignButton}
        onClickDialerButton={onClickDialerButton}
        onBackClickHandler={onBackClickHandler}
      />
    );
  return content;
};

export default Result;
