import { sendRequest, sendRequestBackEnd } from "./rootApi";
import Api from "../constants/Api";
import HttpRequest from "./HttpRequest";

export const carrierLookupCleanDataSingleApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.carrierLookupCleanDataSingle, payload);
}
export const carrierLookupCleanDataBulkApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.carrierLookupCleanDataBulk, payload);
}

export const cleanContactsActionsApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.cleanContactsActions, payload);
}

export const cleanContactsExportedFilesApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.cleanDataExportedFiles, payload);
}

export const cleanDataFileListApi = async (payload) => {
    return sendRequest("post", Api.cleanDataFileList, payload);
}

export const cleanDataFilesDetailsApi = async (payload) => {
    return sendRequest("post", Api.cleanDataFilesDetails, payload);
}

export const carrierLookupCleanDataApi = async (payload) =>{
    return sendRequestBackEnd("post", Api.carrierLookupCleanData, payload);
}

export const updateNumberDncStatusApi = async (payload) => {
    return HttpRequest.post(Api.updateNumberDncStatus, payload);
}

export const getContactLogsApi = async (payload) => {
    return HttpRequest.get(Api.getContactLogs, payload);
}

export const cleanDataPriceValidationApi = async (payload) => {
    return sendRequest("post", Api.cleanDataPriceValidation, payload);
}

export const cleanContactsActionsV1 = async (payload) => {
    return HttpRequest.post(Api.cleanContactsActionsV1, payload);
};