import React, { useEffect, useState } from 'react';
import StepProgressBar from './StepProgressBar';
import { FILE_IMPORT_STEP } from '../../constants/CoreConstants';
import BottomButtons from './BottomButtons';

const HeaderRight = () =>{
    return (
        <div>
            <h6>Import <small>Upload files</small></h6>
        </div>
    );
}


const AddSource = (props) => {

    const [source,setSource] = useState(0);

    useEffect(() => {
        setSource(props.state.id)
    },[props.state.id]);

    const saveAndContinue = (e) => {
        e.preventDefault();
        props.handleChange("source_id", source);
        props.nextStep()
    }

    const prevStep = (e) => {
        e.preventDefault()
        props.handleChange("source_id", source);
        props.prevStep()
    }

    const getSources = () => {
        if(props.state.sources.length > 0){
            return props.state.sources.map((source,index)=>{
                return <option value={source.id} key={index}>{source.source_title}</option>
            })
        }
    }

    return (
        <>
            <div className="import_content_container">
                <div className="main_content_inner_container white">
                    <div className="main_content_topbar">
                        <div className="main_content_topbar_content d-flex justify-content-between align-items-center">
                            <HeaderRight />
                        </div>
                    </div>

                    <div class="divider main_content_container_divider"></div>

                    <div className="main_content_main_section">
                        <StepProgressBar fromCampaign={props.fromCampaign} currentStep={FILE_IMPORT_STEP.STEP_ADD_SOURCE}/>
                    </div>

                    <div class="content_divider"><div class="content_divider_inner"></div></div>

                    <div class="main_section_title_section new_deal_title">
                        <div>
                            <h6 class="center-align">Select contact source</h6>
                        </div>
                        <div>
                            <p class="center-align">You can manage contact source (by default BULK)</p>
                        </div>
                    </div>

                    <div class="divider main_content_main_section_divider"></div>


                    
                    <div className="add_source_content_section">
                        <div class="add_source_content_box">
                            <div class="d-flex justify-content-center align-items-center">
                                <p>Select Contact Source</p>
                                <div class="input-field">
                                    <select name="source" className="d-block" value={source} onChange={(e) => {setSource(e.target.value)}}>
                                    <option value={0} disabled>Select A Source</option>
                                        {getSources()}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <BottomButtons 
                                onBackClick={prevStep} 
                                onContinueClick={saveAndContinue}
                                continueButtonText={'Save & Continue'}/>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddSource;
