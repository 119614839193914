import React, { useState } from "react";
import { Box } from "@material-ui/core";
import ContactHeader from "./contactHeader/ContactHeader";
import ContactBody from "./contactBody/ContactBody";
import EditContactsModal from "./EditContactsModal";

const Contacts = ({ goToContactDetails, contact, updateMultipleData, contactDetails }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box pt={1.5}>
      <ContactHeader goToContactDetails={goToContactDetails} />
      <ContactBody contactDetails={contactDetails} handleOpen={handleOpen} />

      {open && <EditContactsModal open={open} onClose={handleClose} />}
    </Box>
  );
};

export default Contacts;
