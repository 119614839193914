import React, { useState, useEffect } from "react"
import { Save } from "@material-ui/icons";
import GlobalModal from "../../globals/Modal/GlobalModal";
import { assignBulkStage, contactMoveStage} from "../../../api/contactApi";
import BasicSelect from "../middleSide/custom/custom/BasicSelect";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";

const ModalListForStageAdd = (props) => {

    const [selectedPipelineId, setSelectedPipelineId] = useState('')
    const [selectedStageId, setSelectedStageId] = useState('')
    const [submittingData, setSubmittingData] = useState(false)
    const [stageInfo, setStageInfo] = useState(null)

    useEffect(()=>{
        if(props.stageChangeInfo != null){
            updateStageInfo(props.stageChangeInfo.stageInfo)
            if(props.stageChangeInfo.stageInfo.stage.pipeline_id !== undefined && props.stageChangeInfo.stageInfo.stage.pipeline_id != null){
                setSelectedPipelineId(props.stageChangeInfo.stageInfo.stage.pipeline_id)
                props.updatePipelineAction(props.stageChangeInfo.stageInfo.stage.pipeline_id)

                setSelectedStageId(props.stageChangeInfo.stageInfo.stage.id)
                setStageInfo(props.stageChangeInfo.stageInfo)
            }
        }
    },[])
    
    const updateStageInfo =()=>{
 
    }

    const updatePipeline = (pipelineId) => {
        setSelectedPipelineId(pipelineId)
        if (setSelectedStageId !== '') {
            setSelectedStageId('')
        }
        props.updatePipelineAction(pipelineId)
    }

    const updateStage = (stageId) => {
        setSelectedStageId(stageId)
    }

    const submitContactInStage = async () => {

        if(selectedStageId === ''){
            window.showNotification('ERROR',"Please select stage")

        }
        else if(stageInfo != null ){
            setSubmittingData(true)
            try {
                await contactMoveStage({ destination_stage: `stage-${selectedStageId}`, source_stage: `stage-${stageInfo.stage.id}` ,
                order: 0,stage_id : props.stageChangeInfo.stageInfo.id})
                    .then(response => {
                        let rowData = props.stageChangeInfo.row;

                        props.setStageChangeInfo(null)
                        rowData.stage[props.stageChangeInfo.stageIndex].stage = response.data.stage_info

                        setSubmittingData(false)
                        props.datatableRerender(rowData, props.stageChangeInfo.index)
                        props.onClose();
                    })
            } catch (e) {
                console.log(e)
                window.showNotification('ERROR', 'Something went wrong!')
                setSubmittingData(false)

            } 
        }else if (selectedStageId !== '') {
            setSubmittingData(true)
            try {
                await assignBulkStage({ stageId: selectedStageId, contactIds: props.contactIds })
                    .then(response => {
                        if (response.data.success) {
                            window.showNotification('SUCCESS', response.data.html)
                            props.onClose()
                        }
                        else {
                            window.showNotification('ERROR', response.data.html)
                            setSubmittingData(false)
                        }
                    })
            } catch (e) {
                console.log(e)
                window.showNotification('ERROR', 'Something went wrong!')
                setSubmittingData(false)

            } 

        } else {
            window.showNotification('ERROR',"Please select stage")
        }
    }
const SkeletonLoader = () => {
    return (
        <Box>
            <Skeleton variant="rect" height={50} style={{marginBottom:'12px'}} />
            <Skeleton variant="rect" height={50} style={{ display: 'block' }} />
            <Box style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px',marginTop:'20px'}}>
                <Skeleton variant="rect" height={50} width={100}/>
                <Skeleton variant="rect" height={50} width={100}/>
            </Box>
        </Box>
    );
};



    return (
        <GlobalModal {...props}
            title={"Assign Stage"}
            buttonText={stageInfo != null ? "Update Stage" : "Assign Stage"}
            buttonIcon={<Save />}
            onSubmit={()=>submitContactInStage()}
            hideFooter={submittingData ? true : false}
            className={'manage-tag-modal update create-new-list global-medium-modal global-xs-modal'}>


          { submittingData ? <SkeletonLoader/> :
          (<div style={{display:"flex",flexDirection:'column',gap:'20px'}}>
           <BasicSelect
            fullWidth
            options={props.pipelineList}
            value={selectedPipelineId}
            onChange={(e)=>updatePipeline(e.target.value)}
            mapping={{ label: "title", value: "id" }}
            defaultText="Select Pipeline"
            />
            {selectedPipelineId !== '' &&
                <BasicSelect
                fullWidth
                options={props.stageListForAction}
                value={selectedStageId}
                onChange={(e)=>updateStage(e.target.value)}
                mapping={{ label: "stage", value: "id" }}
                defaultText="Select Stage"

                />}
           </div>)}



        </GlobalModal>
    )
}

export default ModalListForStageAdd