export const changeQueryParams = (params = [], redirect='') => {
  try {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      (params.length ? "?" : "") +
      params
        .map((eachParam) => {
          return eachParam.key + "=" + eachParam.value;
        })
        .join("&");

    if(redirect !== '') {
        newurl += '&redirect='+redirect;
    }

    window.history.pushState({ path: newurl }, "", newurl);
  } catch (e) {
    console.log(e);
  }
};
