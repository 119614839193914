import React,{useState} from "react"
import TextField from '@material-ui/core/TextField';
import { Save } from "@material-ui/icons";
import GlobalModal from "../../globals/Modal/GlobalModal";
import { saveContactList } from "../../../api/contactApi";
import NewLoader from "../../common/NewLoader";
import CircularProgress from '@material-ui/core/CircularProgress';
import ModalCreateListSkeleton from "./ModalCreateListSkeleton";

const defaultColumnFilterData = [
    {
        "filter":
            {},
        "sortField": "id",
        "sortOrder": "desc"

    }
];
    const ModalCreateList=(props)=> {
        // const classes = useStyles();

    const [listName, setListName] = useState('')
    const [listDescription, setListDescription] = useState('')
        const [submittingData, setSubmittingData] = useState(false)
    
    const startLoader=()=>{
        
            let modalWidth  = document.querySelector('.create-new-list').offsetWidth;
            let modalHeight  = document.querySelector('.create-new-list').offsetHeight;
    
            document.querySelector(".create-new-list").style.width = modalWidth+'px';
            document.querySelector(".create-new-list").style.height = modalHeight+'px';
    
            setSubmittingData(true)
        }

    const saveNewList=()=>{

        let params= {
            title:listName, 
            description: listDescription,
            contactIds : props.selectedContactIds,
            operation : JSON.stringify(props.operation),
            columnFilterData: JSON.stringify(props.columnFilterData),
            type: 'new'
        }

        if(listName !== ''){
            startLoader()

            try{
                saveContactList(params)
                .then(response=>{
                    // console.log(response.data.status)
                    if(response.data.status === 'success'){
                        window.showNotification('SUCCESS','Create List successfully') 
                    
                        props.onClose()
                        props.callback(response.data.data)
                        setSubmittingData(false)

                    }
                    else if(response.data.status ===  'validation-error'){
                        window.showNotification('ERROR',response.data.message.title[0]) 
                        setSubmittingData(false)
                    }
                    else{
                        window.showNotification('ERROR','Something went wrong') 
                        setSubmittingData(false)

                    }
                })
            }
            catch(e){
                window.showNotification('ERROR','Something went wrong') 
                setSubmittingData(false)
            }
            
        }else{
            setSubmittingData(false)
            window.showNotification('ERROR','No List Name') 
        }
    }

    const messageShow=()=>{

        if (props.selectedContactIds !== undefined && props.selectedContactIds != null && props.selectedContactIds.length >0){

            return <div className="create-list-message">
                <span>***Selected Contacts will add in this List.</span>
            </div>
        }
        else if(JSON.stringify(props.operation) !== JSON.stringify(defaultColumnFilterData)){

            return <div className="create-list-message">
                <span>***Your Filters will store in this list. Contacts may vary according to the filter</span>
            </div>
        }else{

        }
        
    }


    return (
        <GlobalModal {...props} 
            title={"Create New List"}
            buttonText={"Save"}
            buttonIcon={<Save/>}
            onSubmit={saveNewList}
            hideFooter={ submittingData ? true: false}
            className={'manage-tag-modal update create-new-list global-small-modal global-xs-modal '}>
                {submittingData && <ModalCreateListSkeleton/>}
                {!submittingData && 
                <div className="cm__textField_wrapper d-flex align-items-center justify-content-between flex-column">
                    <TextField id="outlined-basic" placeholder="List Name" variant="outlined" value={listName} onChange={(event)=>setListName(event.target.value)}/>
                    <TextField multiline rows="6" id="outlined-basic" placeholder="List Description" variant="outlined" value={listDescription} onChange={(event)=> setListDescription(event.target.value)}/>
                </div>}
                <p>{messageShow() }</p>
        </GlobalModal>
    )
}

export default ModalCreateList