const CoreConstants = {
    TRUE: 1,
    FALSE: 0,

    //inbox thread
    inboxThread: {
        ACTIVE: 1,
        INACTIVE: 0,

        CONTENT_TYPE_SMS: 1,
        CONTENT_TYPE_MMS: 2,
        CONTENT_TYPE_EMAIL: 4,
        INOUT_TYPE_IN: 1,
        INOUT_TYPE_OUT: 2,


        READ: 1,
        UNREAD: 0,

        STATUS_FAIL: 0,
        STATUS_SUCCESS: 1
    },

    VideoEmail: {
        VIDEO_STATUS_WELCOME: 0,
        VIDEO_STATUS_WEBCAM: 1,
        VIDEO_STATUS_WARNING: 2,
        VIDEO_STATUS_PREVIEW: 3,

        MESSAGE_TEMPLATE_VIDEO_EMAIL: 4,
    },


    Timeline: {
        CONTENT_TYPE_SMS: 1,
        CONTENT_TYPE_MMS: 2,
        CONTENT_TYPE_VOICE: 3,
        CONTENT_TYPE_EMAIL: 4,
        CONTENT_TYPE_CALL: 5,
        CONTENT_TYPE_ACTIVITY: 6,
        CONTENT_TYPE_OUTGOING_CALL: 7,
        CONTENT_TYPE_WEBHOOK: 9,
        CONTENT_TYPE_STAGE: 10,
        CONTENT_TYPE_APPOINTMENT: 12,
        CONTENT_TYPE_CALL_BRIGDE: 13,
        CONTENT_TYPE_VIDEO_EMAIL: 14,
        CONTENT_TYPE_CHAT: 15,
        CONTENT_TYPE_NOTE: 16,
        CONTENT_TYPE_GENERAL: 20,
        CONTENT_TYPE_POST_CARD: 21,
        CONTENT_TYPE_GREETINGS_CARD: 22,
        CONTENT_TYPE_GIFT: 23,
        CONTENT_TYPE_LETTER: 24,
        CONTENT_TYPE_CALL_RECORD: 25,


        INOUT_TYPE_IN: 1,
        INOUT_TYPE_OUT: 2,

        READ: 1,
        UNREAD: 0,

        STATUS_FAIL: 2,
        STATUS_SUCCESS: 1,
        STATUS_PENDING: 0,
        STATUS_SENT: 3,
        STATUS_UNDELIVERED: 4,
        STATUS_ACCEPTED: 5,
        STATUS_DELETED: 6,

        SENT_IMMEDIATLY: 1,
        SENT_SCHEDULE: 2,


        CONTENT_TYPES: {
            0: 'All Conversation',
            1: 'SMS',
            2: 'MMS',
            3: 'Voice',
            4: 'Email',
            5: 'Call',
            6: 'Activity',
            7: 'Outgoing Call',
            9: 'Webhook',
            10: 'Stage',
            12: 'Appointment',
            13: 'Call Bridge',
            14: 'Video Email',
            15: 'Chat',
            16: 'Note',
            20: 'General',
            25: 'Call Record',
            21: 'Postcard',
            22: 'Greeting Card',
            23: 'Gift',
            24: 'Letter',
        }
    },
    FORM_BUILDER_V3_COMPONENT_TYPE: {
        THEME: 1,
        SUBMIT_BUTTON: 2,
        HEADING: 3,
        FULL_NAME: 4,
        EMAIL: 5,
        PHONE: 6,
        ADDRESS: 7,
        COMPANY: 8,
        BIRTHDAY: 9,
        NOTE: 10,
        SHORT_ANSWER: 11,
        LONG_ANSWER: 12,
        PHONE_INPUT: 13,
        NUMBER: 14,
        LINK: 15,
        DATE: 16,
        TIME: 17,
        YES_NO: 18,
        RADIO: 19,
        CHECKBOX: 20,
        DROPDOWN: 21,
        FILE_UPLOAD: 22,
        HIDDEN_FIELD: 23,
        PARAGRAPH: 24,
        IMAGE: 25,
        VIDEO: 26,
        AUDIO: 27,
        EMBED: 28,
        PROGRESSBAR: 29
    }
}

export const ActivityType = {
    CALL: '1',
    APOINTMENT: '2',
    TASK: '3',
    DEADLINE: '4',
    EMAIL: '5',
    FOLLOWUP: '6',
    OTHERS: '7'

}


export const CustomFieldType = {
    TEXT: 1,
    MULTILINE_TEXT: 2,
    NUMERIC: 3,
    DATE: 4,
    CHECKBOX: 5,
    SELECT: 6,
    RADIO: 7,
    PHONE: 8,
    ZIP_CODE: 9,
    WEBSITE: 10
}


export const userTimezoneRaw = 'America/Denver';
export const userTimezone = 'Mountain Time';

export default CoreConstants;



//File import constants 
// DO NOT REMOVE ANY ROWS - THERE ARE SOME DEPENDENCIES -- CHECK USAGES
export const contactDetails = [
    { value: 'first_name', label: 'First Name' },
    { value: 'last_name', label: 'Last Name' },
    { value: 'company_name', label: 'Company Name' },
    { value: 'deal_value', label: 'Price' },
    { value: 'url', label: 'Url' },
    { value: 'tags', label: 'Tags' },
    { value: 'notes', label: 'Notes' },
    { value: 'birth_date', label: 'Birth Date' },
    { value: 'anniversary_date', label: 'Anniversary Date' },
];

export const contactAddress = [
    { value: 'country', label: 'Country' },
    { value: 'city', label: 'city' },
    { value: 'state', label: 'State' },
    { value: 'zip', label: 'Zip Code' },
    { value: 'address', label: 'Address' },
];

export const contactInformation = [
    { value: 'email', label: 'Email' },
    { value: 'number', label: 'Phone' },
];

export const contactAddCustoms = [
    { value: 'customForFields_TEXT', label: 'Add Text Fields' },
    { value: 'customForFields_DATE', label: 'Add Date Fields' },
    { value: 'customForFields_NUMERIC', label: 'Add Number Fields' }
];


export const groupStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
};

export const groupBadgeStyles = {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center',
};

export const FILE_IMPORT_STEP = {
    STEP_UPLOAD: 1,
    STEP_WRAPPER: 2,
    STEP_IMPORT_RULE: 3,
    STEP_DEAL_CREATE: 4,
    STEP_PROCESS_MODE: 5,
    STEP_ADD_SOURCE: 6,
    STEP_ADD_TAG: 7,
    STEP_PROCESS_LOADER: 8,
    STEP_SUCCESS: 9,
    STEP_FILE_LIST: 10,
    STEP_UPGRADE: 11,
}


