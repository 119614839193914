import React from "react"
import { NavigateNext } from "@material-ui/icons";
import GlobalModal from "../../globals/Modal/GlobalModal";
import { BasicInput } from "../leftSideV2/LeftSideV2Styles";

    const InsertFileName=(props)=> {
        // const classes = useStyles();

    let exportFileName = ''

    const handleSubmit=()=>{

        if(exportFileName !== ''){
            props.callback(exportFileName)
        }else{
            window.showNotification('ERROR','Please insert File Name')
        }
    }
    return (
        <GlobalModal {...props} 
            title={"File Name"}
            buttonText={"Save"}
            buttonIcon={<NavigateNext/>}
            onSubmit={()=> handleSubmit()}
            className={'manage-tag-modal update create-new-list global-small-modal global-xs-modal '}>
                
                <div className="cm__textField_wrapper d-flex align-items-center justify-content-between flex-column">
                    <p>Please provide a name for your exported File </p>
                    <BasicInput
                    fullWidth
                    placeholder="Please provide a name for your exported File" 
                    variant="outlined" 
                    onChange={(event)=>exportFileName =event.target.value}/> 
                </div>
                {/* } */}
        </GlobalModal>
    )
}

export default InsertFileName